import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FullScreenModal from '../../shared/modal/FullScreenModal';
import matik_logo_black from '../../../images/matik_logo_black.svg';
import EditorVisualCard from './EditorVisualCard';
import EditorCodeCard from './EditorCodeCard';
import moment from 'moment';
import teams from '../../../lib/teams';
import withUserContext from '../../shared/WithUserContext';
import PageLoader from 'components/shared/PageLoader';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import API from 'lib/api';

const EmailEditTypeModal = (props) => {
  const [isCreating, setIsCreating] = useState();

  const { createFast: createTemplate } = useTemplateMutator();

  const onEmailEditTypeClick = (editType) => {
    if (props.userContext.user && !teams.isTeamsUser(props.userContext.user.enterprise.plan_id)) {
      setIsCreating(true);
      const templateData = {
        name: 'Email Template ' + moment(),
        source_type: 'email',
        source: {
          subject: '',
          recipients: { bcc: [], cc: [], dynamicRecipients: '' },
          from: { name: '', fromEmail: 'no-reply@matik.io', replyEmail: '' },
          editor_type: editType,
        },
      };
      // "null" file triggers a multipart/form-data post, so we need it.
      createTemplate(templateData, null)
        .then((templateData) => props.onTemplateAdd(templateData))
        .catch(API.defaultError)
        .finally(() => setIsCreating(false));
    } else {
      const demoUrl = teams.buildRequestDemoUrl(
        props.userContext.user,
        'email_narrative',
        props.userContext.user.enterprise.trial_days_remaining,
      );
      window.open(demoUrl, '_blank');
    }
  };

  const headerImage = <img src={matik_logo_black} alt="matik logo" style={{ maxWidth: '100px' }} />;

  return (
    <FullScreenModal
      show={props.show}
      onClose={props.onClose}
      showDefaultFooter={false}
      headerCenter={headerImage}
      onBackClick={props.onEmailEditTypeBackClick}
    >
      <PageLoader title="Creating Email Template" isActive={isCreating} />
      <div className="add-template-body">
        <h3>Select your editing experience</h3>
        <div className="add-template-cards">
          <EditorVisualCard onEditorVisualClick={() => onEmailEditTypeClick('visual')} user={props.userContext.user} />
          <EditorCodeCard onEditorCodeClick={() => onEmailEditTypeClick('code')} />
        </div>
      </div>
    </FullScreenModal>
  );
};

EmailEditTypeModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  history: PropTypes.object,
  onPresentationNarrativeClick: PropTypes.func,
  userContext: PropTypes.object,
  onEmailEditTypeBackClick: PropTypes.func,
};

export default withUserContext(EmailEditTypeModal);
