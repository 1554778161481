import React from 'react';
import PropTypes from 'prop-types';
import ListItemCondition from './ListItemCondition';
import AssetItem from 'components/lib/AssetItem';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

const AssetItemCondition = ({ condition, targetCount, isFiltering, onFilteringChange, onClick }) => {
  if (!condition) {
    return null;
  }
  const handleFilteringClick = () => {
    onFilteringChange(!isFiltering);
  };
  return (
    <AssetItem className="group">
      <ListItemCondition
        condition={condition}
        metadata={targetCount > 0 ? targetCount : null}
        showIndicator
        showSubtitle
        onClick={onClick}
      />
      {onFilteringChange && (
        <div className={`h-7 items-center ${isFiltering ? 'flex' : 'hidden group-hover:flex'}`}>
          <Button category="tertiary" size="small" width="square" onClick={handleFilteringClick}>
            <Icon name="eye" theme="filled" size={20} />
          </Button>
        </div>
      )}
    </AssetItem>
  );
};
AssetItemCondition.propTypes = {
  targetCount: PropTypes.number,
  isFiltering: PropTypes.bool,
  onFilteringChange: PropTypes.func,
  onClick: PropTypes.func,
  condition: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    icon_color: PropTypes.string,
  }),
};

export default AssetItemCondition;
