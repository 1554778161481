import React from 'react';
import PropTypes from 'prop-types';
import IconPill from './IconPill';

const CardHeader = ({
  size,
  title,
  subLine,
  rightInstance,
  showLeftInstance = true,
  pillLuminosity,
  pillColor,
  pillTheme,
  iconName,
  iconColor,
  iconTheme,
}) => {
  const sizeToIconPillSize = { s: 'l', m: 'xl' };
  return (
    <div className="!p-6 w-full bg-white border-b border-grey-300 justify-start items-center gap-4 inline-flex">
      <div className="grow shrink basis-0 h-10 justify-start items-center gap-4 flex">
        {showLeftInstance && (
          <div className="justify-start items-center gap-2.5 flex">
            <IconPill
              color={pillColor}
              iconColor={iconColor}
              iconName={iconName}
              iconTheme={iconTheme}
              luminosity={pillLuminosity}
              size={sizeToIconPillSize[size]}
              theme={pillTheme}
            />
          </div>
        )}
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="text-matik-black text-sm font-semibold">{title}</div>
          {!!subLine && <div className="text-grey-600 text-sm font-normal">{subLine}</div>}
        </div>
        {!!rightInstance && <div className="justify-end items-center gap-2.5 flex">{rightInstance}</div>}
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  size: PropTypes.oneOf(['m', 's']),
  title: PropTypes.string,
  subLine: PropTypes.string,
  rightInstance: PropTypes.element,
  showLeftInstance: PropTypes.bool,
  pillLuminosity: IconPill.propTypes.luminosity,
  pillColor: IconPill.propTypes.color,
  pillTheme: IconPill.propTypes.theme,
  iconName: IconPill.propTypes.iconName,
  iconColor: IconPill.propTypes.iconColor,
  iconTheme: IconPill.propTypes.iconTheme,
};

export default CardHeader;
