import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element, useEditor, useNode } from '@craftjs/core';
import ColumnContainer from './ColumnContainer';
import Column from './Column';
import { forEach } from 'lodash';
import { HtmlBuilderApplyColumnRatio } from '../HtmlBuilderApplyColumnRatio';
import Indicator from './Indicator';

function ColumnModule({ children, style, isNewNode, ...rest }) {
  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((state, query) => {
    let isActive = false;
    let isHover = false;

    // Check if ColumnModule is selected
    const isCurrentActive = state.events.selected.has(id);

    // Check if any descendant is selected
    const isAnyDescendantSelected = (nodeId, depth = 0) => {
      // A depth of 3 will be enough to check if any part of the ColumnModule structure is selected
      if (depth > 3) {
        return false;
      }

      if (state.events.selected.has(nodeId)) {
        return true;
      }

      const node = query.node(nodeId);
      for (const descendantId of node.descendants()) {
        if (isAnyDescendantSelected(descendantId, depth + 1)) {
          return true;
        }
      }
      return false;
    };

    // Check if any descendant is hovered
    const isAnyDescendantHovered = (nodeId, depth = 0) => {
      // A depth of 3 will be enough to check if any part of the ColumnModule structure is hovered
      if (depth > 3) {
        return false;
      }

      if (state.events.hovered.has(nodeId)) {
        return true;
      }

      const node = query.node(nodeId);
      for (const descendantId of node.descendants()) {
        if (isAnyDescendantHovered(descendantId, depth + 1)) {
          return true;
        }
      }
      return false;
    };

    isActive = isAnyDescendantSelected(id) || isCurrentActive;
    isHover = isAnyDescendantHovered(id) || isCurrentActive;

    return {
      isActive,
      isHover,
    };
  });

  const {
    actions: { setProp },
    connectors: { connect, drag },
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
    hasSelectedNode: state.events.selected,
  }));

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  const columnStyle = {
    background: '#FFFFFF',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
  };

  return (
    <div className="is-relative is-transparent" ref={(dom) => connect(dom)} {...rest}>
      {(isActive || isHover) && (
        <Indicator borderWidth="0px" borderRadius="4px" tabText="Columns" isActive={isActive} nodeId={id} drag={drag} />
      )}
      <Element is={ColumnContainer} id={'ColumnContainer'} style={style} canvas>
        {children}
        <Element id={'Column1'} style={columnStyle} is={Column} canvas />
        <Element id={'Column2'} style={columnStyle} is={Column} canvas />
        <Element id={'Column3'} style={columnStyle} hidden={true} is={Column} canvas />
        <Element id={'Column4'} style={columnStyle} hidden={true} is={Column} canvas />
      </Element>
    </div>
  );
}

ColumnModule.craft = {
  props: {
    style: {
      background: '#FFFFFF',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      height: '100%',
      justifyContent: 'space-between',
      paddingBottom: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '5px',
    },
    buttonId: 1,
    isNewNode: true,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => true,
    canMoveOut: () => true,
  },
  related: {
    settings: ColumnModuleSettings,
  },
};

ColumnModule.propTypes = {
  buttonId: PropTypes.number,
  children: PropTypes.object,
  style: PropTypes.object,
  isNewNode: PropTypes.bool,
};

function ColumnModuleSettings() {
  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const { columnContainerNode, childNodeList } = useEditor((state, query) => {
    let columnContainerNode = [];
    let childNodeList = [];

    const columnContainerNodeList = query.node(id).descendants();
    columnContainerNode = state.nodes[columnContainerNodeList[0]];
    const childNodes = query.node(columnContainerNodeList[0]).descendants();
    forEach(childNodes, (childNode) => {
      childNodeList.push(state.nodes[childNode]);
    });

    return {
      childNodeList,
      columnContainerNode,
    };
  });

  return (
    <>
      <p className="modules-header">Configure Column Layout</p>
      <div className="module-settings-container">
        <HtmlBuilderApplyColumnRatio columnModuleId={id} />
      </div>
      <div className="module-settings-container">
        {columnContainerNode && (
          <React.Fragment>
            <p className="modules-header">Column Module Settings</p>
            {React.createElement(columnContainerNode.related?.settings)}
          </React.Fragment>
        )}
      </div>
      {childNodeList.map(
        (childNode, index) =>
          childNode && <React.Fragment key={index}>{React.createElement(childNode.related?.settings)}</React.Fragment>,
      )}
    </>
  );
}

export default ColumnModule;
