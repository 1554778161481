import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import SSHTunnelForm from './SSHTunnelForm';

class DatabricksForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSSHTunnel: !!(this.props.auth && this.props.auth['ssh_key']),
    };
  }

  render() {
    const hostColor = this.props.hostError ? 'danger' : null;
    return (
      <React.Fragment>
        <Form.Field>
          <Form.Help>
            To fill out the form, see the{' '}
            <a
              target="_blank"
              href="https://docs.databricks.com/workspace/workspace-details.html"
              rel="noreferrer noopener"
            >
              guide to getting this information from databricks
            </a>
          </Form.Help>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="server-host">Databricks Instance</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="The domain name of your databricks deployment"
              type="text"
              id="server-host"
              name="host"
              color={hostColor}
              onChange={this.props.onChange}
              value={this.props.host}
            />
          </Form.Control>
          {this.props.hostError && <Form.Help color="danger">{this.props.hostError}</Form.Help>}
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="db-database">Workspace ID</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Workspace ID from your databricks instance"
              type="text"
              name="database"
              onChange={this.props.onChange}
              value={this.props.database}
              id="db-database"
            />
          </Form.Control>
        </Form.Field>
        <SSHTunnelForm
          onAuthChange={this.props.onAuthChange}
          onChange={this.onSSHTunnelChange}
          showSSHTunnel={this.state.showSSHTunnel}
          auth={this.props.auth}
          selectedDataSource={this.props.selectedDataSource}
          clearSSHKey={this.props.clearSSHKey}
        />
      </React.Fragment>
    );
  }
  onSSHTunnelChange = (e) => {
    const value = e.target.checked;
    this.setState({
      [e.target.name]: value,
    });
    this.props.onSSHTunnelChange();
  };
}

DatabricksForm.propTypes = {
  auth: PropTypes.object,
  clearSSHKey: PropTypes.func,
  database: PropTypes.string,
  deleteDataSource: PropTypes.func,
  host: PropTypes.string,
  hostError: PropTypes.string,
  isLoading: PropTypes.bool,
  onAuthChange: PropTypes.func,
  onChange: PropTypes.func,
  onSSHTunnelChange: PropTypes.func,
  selectedDataSource: PropTypes.object,
  testDataSource: PropTypes.func,
  testIsLoading: PropTypes.bool,
  testResult: PropTypes.object,
};

export default DatabricksForm;
