import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Level } from 'react-bulma-components';
import Tag from '../../lib/Tag';

class PresentationsInsightTotal extends Component {
  render() {
    let percentColor = 'white';
    let percentComponent = '';
    if (this.props.percentChange > 0) {
      percentColor = 'green';
      percentComponent = '+';
    }

    return (
      <div>
        <Level className="mbs">
          <Level.Side align="left">
            <Level.Item>
              <Heading size={5}>{this.props.totalPresentations}</Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <Tag color={percentColor} label={`${percentComponent} ${this.props.percentChange}%`} />
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
    );
  }
}

PresentationsInsightTotal.propTypes = {
  totalPresentations: PropTypes.number,
  percentChange: PropTypes.number,
};

export default PresentationsInsightTotal;
