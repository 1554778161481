import React from 'react';
import { ReactComponent as ErrorPill } from '../../svg/error_pill.svg';
import { ReactComponent as ImageOff } from '../../svg/image_off.svg';

export default function DisconnectedTemplateSlide() {
  return (
    <div className="empty-slide">
      <ErrorPill className="error-pill" />
      <div className="center-message">
        <ImageOff />
        <p>Slide not found</p>
      </div>
    </div>
  );
}
