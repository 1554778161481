import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  Button,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  SimpleForm,
  required,
  Create,
  DateInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const getQueryVariables = (search) => {
  const ret = {};
  const query = search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    ret[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  return ret;
};

export const BillingShow = () => (
  <React.Fragment>
    <TextField source="id" />
    <TextField source="producer_count" />
    <TextField source="consumer_count" />
    <TextField source="plan_id" />
    <TextField source="subscription_status" />
  </React.Fragment>
);

export const BillingTitle = () => {
  return <span>Billing</span>;
};

export const PlanSelector = () => {
  return (
    <SelectInput
      source="plan_id"
      label="Plan"
      choices={[
        { id: 'matik_team', name: 'Team' },
        { id: 'matik_enterprise', name: 'Enterprise' },
      ]}
      validate={required('Plan is required')}
    />
  );
};

export const SubscriptionStatusSelector = () => {
  return (
    <SelectInput
      source="subscription_status"
      label="Subscription Status"
      choices={[
        { id: 'trialing', name: 'Trialing' },
        { id: 'active', name: 'Active' },
        { id: 'unpaid', name: 'Unpaid' },
        { id: 'canceled', name: 'Canceled' },
      ]}
      validate={required('Subscription status is required')}
    />
  );
};

export const BillingEdit = (props) => {
  const { enterprise_id: enterpriseIdString } = getQueryVariables(props.location.search);
  const enterprise_id = enterpriseIdString ? parseInt(enterpriseIdString, 10) : '';
  const redirect = enterprise_id ? `/enterprises/${enterprise_id}/show` : false;
  return (
    <Edit title={<BillingTitle />} {...props}>
      <SimpleForm defaultValue={{ enterprise_id }} redirect={redirect}>
        <TextInput source="id" disabled />
        <DateInput source="expires_on" />
        <TextInput source="producer_count" validate={required('Producer count is required')} />
        <TextInput source="consumer_count" validate={required('Consumer count is required')} />
        <PlanSelector />
        <SubscriptionStatusSelector />
        <ReferenceInput label="Enterprise" source="enterprise_id" reference="enterprises">
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

BillingEdit.propTypes = {
  location: PropTypes.object,
};

export const BillingCreate = (props) => {
  const { enterprise_id: enterpriseIdString } = getQueryVariables(props.location.search);
  const enterprise_id = enterpriseIdString ? parseInt(enterpriseIdString, 10) : '';
  const redirect = enterprise_id ? `/enterprises/${enterprise_id}/show` : false;
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect} defaultValue={{ enterprise_id }}>
        <DateInput source="expires_on" />
        <TextInput source="producer_count" validate={required('Producer count is required')} />
        <TextInput source="consumer_count" validate={required('Consumer count is required')} />
        <PlanSelector />
        <SubscriptionStatusSelector />
      </SimpleForm>
    </Create>
  );
};

BillingCreate.propTypes = {
  location: PropTypes.object,
};

const styles = { button: { marginTop: '1rem' } };
export const AddBillingButton = withStyles(styles)(({ record }) => {
  if (record.billing && Object.keys(record.billing).length > 0) {
    return '';
  }

  return (
    <Button
      component={Link}
      to={`/billings/create?enterprise_id=${record.id}`}
      label="Add billing"
      title="Add billing"
    />
  );
});
