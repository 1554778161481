import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import InputWithError from './InputWithError';
import Button from '../lib/Button';

const AuthTokenInput = (props) => {
  const dataSource = props.dataSource || {};
  const dynamicContent = props.dynamicContent || [];

  const testAccess = (e) => {
    // The data source might have multiple pieces of DC associated with it, but for testing we'll just pick one.
    if (dynamicContent.length > 0) {
      props.onTestAccess(e, dataSource, dynamicContent[0]);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      testAccess(e);
    }
  };

  return (
    <Form.Field className="phm" kind="group">
      <Form.Control className="m-0 param-in-form end-user-auth-token-input-control">
        <Form.Label>Access Token ({dataSource.name})</Form.Label>
        <Form.Help className="mbs has-text-grey">
          {dataSource.auth_info ? dataSource.auth_info.token_description : ''}
        </Form.Help>
        <InputWithError
          error={props.tokenError}
          success={props.tokenSuccess}
          className="end-user-auth-token-input"
          type="text"
          name="auth-token"
          onChange={(e) => props.onAuthTokenChange(e, dynamicContent)}
          onKeyDown={handleInputKeyDown}
          noHelp={true}
          isAuthTokenTest={true}
          required
        />
      </Form.Control>
      <Form.Control className="param-in-form end-user-test-token-button-container">
        <Button
          category="secondary"
          onClick={testAccess}
          status={props.tokenLoading ? 'loading' : dynamicContent.length === 0 ? 'disabled' : 'default'}
        >
          Test Access
        </Button>
      </Form.Control>
    </Form.Field>
  );
};

AuthTokenInput.propTypes = {
  dynamicContent: PropTypes.array,
  dataSource: PropTypes.object,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  onAuthTokenChange: PropTypes.func,
  onTestAccess: PropTypes.func,
  tokenLoading: PropTypes.bool,
};

export default AuthTokenInput;
