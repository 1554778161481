import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import ConnectedSearchBox from './ConnectedSearchBox';
import Filters from './Filters';
import { ReactComponent as Search } from '../../../svg/search_filled.svg';
import FilterContainer from './FilterContainer';

class HybridSearchBoxWithFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSearching && this.props.isSearching) {
      const updatedSearchState = Object.assign({}, this.props.searchState);
      updatedSearchState.query = this.props.filterVal;
      this.props.onSearchStateChange(updatedSearchState);
    }
  }

  render() {
    let body = '';
    if (this.props.isSearching) {
      body = (
        <ConnectedSearchBox
          filterPlaceholder={this.props.filterPlaceholder}
          toggleFilters={this.toggleFilters}
          showFilters={this.state.showFilters}
        />
      );
    } else {
      body = (
        <Form.Field className="mbn is-paddingless">
          <Form.Control className="has-icons-left has-icons-right clickable-icon">
            <FilterContainer toggleFilters={this.toggleFilters} />
            <Form.Input
              type="text"
              placeholder={this.props.filterPlaceholder}
              value={this.props.filterVal}
              onChange={(e) => this.onFilterChange(e.target.value)}
              aria-label={this.props.filterPlaceholder}
            />
            <span className="icon is-left has-text-grey-dark">
              <Search />
            </span>
          </Form.Control>
        </Form.Field>
      );
    }
    return (
      <React.Fragment>
        {body}
        <Filters
          attributes={this.props.searchAttributes}
          filterList={this.props.searchState.refinementList}
          show={this.state.showFilters}
          close={this.closeFilters}
          sidebar={false}
        />
      </React.Fragment>
    );
  }

  onFilterChange = (newFilterVal) => {
    this.props.onFilterChange(newFilterVal);
  };

  closeFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showFilters: false });
  };

  toggleFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showFilters: !this.state.showFilters });
  };
}

HybridSearchBoxWithFilter.propTypes = {
  filterPlaceholder: PropTypes.string,
  filterVal: PropTypes.string,
  isSearching: PropTypes.bool,
  onFilterChange: PropTypes.func,
  onSearchStateChange: PropTypes.func,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
};

export default HybridSearchBoxWithFilter;
