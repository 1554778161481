import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import API from '../../lib/api';
import { Auth } from '../../lib/auth';

const WithFetchUser = (WrappedComponent) => {
  return class Inner extends Component {
    constructor(props) {
      super(props);
      this.state = {
        user: null,
      };
    }

    componentDidMount() {
      API.get(
        '/users/me/',
        (response) => {
          const user = response.data;
          this.setState({ user: user });
          if (window.Appcues) {
            window.Appcues.identify(Auth.getId(), user);
          }
          Sentry.setUser({ id: user.id, email: user.email });
        },
        API.defaultError,
      );
    }

    render() {
      let LDContext = undefined;
      if (this.state.user) {
        LDContext = {
          kind: 'user',
          key: this.state.user.id,
          name: this.state.user.name,
          email: this.state.user.email,
          enterprise_id: this.state.user.enterprise_id,
          plan_id: this.state.user.enterprise.plan_id,
          subscription_status: this.state.user.enterprise.subscription_status,
        };
      }
      return (
        <LDProvider
          clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID} // eslint-disable-line no-undef
          context={LDContext}
          deferInitialization={true}
        >
          <WrappedComponent
            {...this.props}
            user={this.state.user}
            updateUser={this.updateUser}
            refreshOauthToken={this.refreshOauthToken}
          />
        </LDProvider>
      );
    }

    updateUser = (newUser) => {
      this.setState({ user: newUser });
    };

    refreshOauthToken = (callback) => {
      API.post(
        '/auth/refresh_google_access/',
        {},
        (response) => {
          const user = response.data;
          this.setState({ user: user }, callback);
        },
        API.defaultError,
      );
    };
  };
};

export default WithFetchUser;
