import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../lib/Button';
import Icon from 'components/lib/Icon';

class PdfDownloadButton extends Component {
  render() {
    return (
      <Button category="secondary" onClick={this.props.onClick} id="presentation-download-pdf-button">
        <Icon name="arrow_download" size={16} theme="regular" />
        <p className="ml-2 text-sm">{this.props.text}</p>
      </Button>
    );
  }
}

PdfDownloadButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default PdfDownloadButton;
