import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../lib/Icon';
import Button from '../lib/Button';

function OpenInNewTabIcon(props) {
  const { linkTo } = props;
  return (
    <Button
      category="tertiary"
      href={linkTo}
      target="_blank"
      rel="noreferrer"
      data-tooltip-content="Open in new tab"
      data-tooltip-id="matik-tooltip"
      size="small"
    >
      <Icon name="open" size={20} />
    </Button>
  );
}

OpenInNewTabIcon.propTypes = {
  linkTo: PropTypes.string,
};

export default OpenInNewTabIcon;
