import { isEqual, isDate as _isDate } from 'lodash';
import moment from 'moment';

const isDate = (val) => {
  return _isDate(val) || moment.isMoment(val);
};

const OperatorMap = {
  // prettier-ignore
  mapOperatorToFunc: (operator) => {
    const mapping = {
      '=': OperatorMap.eq,
      '!=': OperatorMap.ne,
      '>': OperatorMap.gt,
      '<': OperatorMap.lt,
      '>=': OperatorMap.gte,
      '<=': OperatorMap.lte,
      'contains': OperatorMap.contains,
      'does not contain': OperatorMap.doesNotContain,
      'in': OperatorMap.in,
      'not in': OperatorMap.notIn,
    };

    return mapping[operator];
  },

  eq: (inputValue, operand) => {
    if (isDate(inputValue)) {
      return moment(inputValue).isSame(operand);
    }
    return isEqual(inputValue, operand);
  },
  ne: (inputValue, operand) => {
    if (isDate(inputValue)) {
      return !moment(inputValue).isSame(operand);
    }
    return !isEqual(inputValue, operand);
  },
  gt: (inputValue, operand) => {
    if (isDate(inputValue)) {
      return moment(inputValue).isAfter(operand);
    }
    return inputValue > operand;
  },
  lt: (inputValue, operand) => {
    if (isDate(inputValue)) {
      return moment(inputValue).isBefore(operand);
    }
    return inputValue < operand;
  },
  gte: (inputValue, operand) => {
    if (isDate(inputValue)) {
      return moment(inputValue).isSameOrAfter(operand);
    }
    return inputValue >= operand;
  },
  lte: (inputValue, operand) => {
    if (isDate(inputValue)) {
      return moment(inputValue).isSameOrBefore(operand);
    }
    return inputValue <= operand;
  },
  contains: (inputValue, operand) => {
    // It's a little unclear how to interpret an operand that is a list of more than one item
    // Other places in the code assume it will only ever have one item, so we'll follow that lead here
    const scalarOperand = Array.isArray(operand) ? operand[0] : operand;
    return inputValue.indexOf(scalarOperand) >= 0;
  },
  doesNotContain: (inputValue, operand) => {
    return !OperatorMap.contains(inputValue, operand);
  },
  in: (inputValue, operand) => {
    return operand.indexOf(inputValue) >= 0;
  },
  notIn: (inputValue, operand) => {
    return !OperatorMap.in(inputValue, operand);
  },
};

export default OperatorMap;
