import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WithFilterListHeader from '../WithFilterListHeader';
import SortableEntityGrid from '../SortableEntityGrid';
import Constants from '../../Constants';
import { UserContext } from '../../UserContext';
import WithLoadingAndEmpty from '../WithLoadingAndEmpty';
import AccessModal from '../AccessModal';
import AccessManager from '../../../lib/AccessManager';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import useAccesses from 'lib/hooks/useAccess';

const GridWithFilterHeader = withRouter(WithLoadingAndEmpty(WithFilterListHeader(SortableEntityGrid)));

class TemplatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: {},
      showAccessModal: false,
      templateLibraryTemplates: null,
    };
  }

  render() {
    const isAdminView = this.props.match.path.indexOf('create') === -1;
    const templateBaseUrl = isAdminView ? '/templates/' : '/create/templates/';
    const noAccessTemplateCount = this.props?.allTemplatesCount - this.props?.templates?.length || 0;
    const showCreateATemplatePage = isAdminView && this.props.templates?.length > 0;
    return (
      <div className="end-user-templates-list">
        <GridWithFilterHeader
          entityType="template"
          entities={this.props.templates}
          accessesByEntityId={this.props.accessesByEntityId}
          filterPlaceholder="Find Template"
          newEntityFunc={isAdminView ? this.props.addNarrative : undefined}
          pagination={this.props.pagination}
          loading={this.props.isPaginating}
          fetchItems={this.fetchItems}
          buttonClass={isAdminView ? 'is-primary' : undefined}
          newEntityPhrase={isAdminView ? 'New Template' : undefined}
          templateBaseUrl={templateBaseUrl}
          displayFilterHeader={true}
          displaySortDropdown={true}
          showFavorites={false}
          showAccessModal={this.showAccessModal}
          showLibrary={isAdminView}
          searchState={this.props.searchState}
          onSearchStateChange={this.props.onSearchStateChange}
          searchAttributes={this.props.searchAttributes}
          isSearching={this.props.isSearching}
          isLoading={this.props.isLoading}
          elements={this.props.elements}
          emptyInfo={this.props.emptyInfo}
          isAdminView={isAdminView}
        />
        <AccessModal
          show={this.props.ui.modal?.name === 'accessModal'}
          accesses={
            new AccessManager(this.props.ui.modal?.content?.id, this.props.accessesByEntityId, this.context.user)
          }
          item={this.props.ui.modal?.content}
        />
        {showCreateATemplatePage && (
          <div>
            <div className="ptl mbs pll mtxxxl title is-4">Create a New Template</div>
            <div className="template-library-list">
              <SortableEntityGrid
                entitiesToRender={this.state.templateLibraryTemplates}
                entityType="template"
                isSearching={false}
                pagination={{ numberOfPages: 1 }}
                templateBaseUrl="/templates/"
                noAccessEntityCount={noAccessTemplateCount}
                showFavorites={false}
                isTemplateLibrary={true}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.onSearchParamsChange(offset, sort, Constants.PAGE_SIZE, null, true);
  };
}

TemplatesList.propTypes = {
  // Props from parent
  activeSidebarMenuItem: PropTypes.any,
  addNarrative: PropTypes.func,
  // For WithLoadingAndEmpty
  emptyInfo: PropTypes.object,
  elements: PropTypes.array,
  isLoading: PropTypes.bool,
  accessesByEntityId: PropTypes.object,
  // Drilled down from parent, derived from WithInstantSearch
  searchState: PropTypes.object,
  searchAttributes: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  isSearching: PropTypes.bool,
  isPaginating: PropTypes.bool,
  pagination: PropTypes.object,
  templates: PropTypes.array,
  // Templates redux dispatch
  onSearchParamsChange: PropTypes.func,
  // From withLDConsumer
  flags: PropTypes.object,
  // From withRouter
  match: PropTypes.object,
  ui: PropTypes.object,
  allTemplatesCount: PropTypes.number,
};

TemplatesList.contextType = UserContext;

function mapDispatchToProps(state, ownProps) {
  return Object.assign({}, mapUiDispatchToProps(state, ownProps));
}

const TemplatesListWrapper = (props) => {
  const { data: accessesByEntityId } = useAccesses('template');
  return <TemplatesList {...props} accessesByEntityId={accessesByEntityId} />;
};

export default connect(mapUiStateToProps, mapDispatchToProps)(withRouter(withLDConsumer()(TemplatesListWrapper)));
