import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import API from 'lib/api';
import Icon from 'components/lib/Icon';
import TextboxWithEmbeddedIcons from 'components/lib/TextboxWithEmbeddedIcons';
import { IconSelect } from 'components/shared/FormSelect';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';

function IntegrationFolderSelector({ currentFolder, onFolderSelect, disabledOptions, placeholder }) {
  const options = [
    {
      label: <span>Google Drive</span>,
      value: 'google',
      icon: <Icon name="google_drive_logo" />,
      disabled: disabledOptions.includes('google'),
    },
    {
      label: <span>OneDrive</span>,
      value: 'microsoft',
      icon: <Icon name="office_365_logo" />,
      disabled: disabledOptions.includes('microsoft'),
    },
  ];
  const [folderType, setFolderType] = useState(options.find((option) => !option.disabled));
  const [googleFolder, setGoogleFolder] = useState({});
  const [microsoftFolder, setMicrosoftFolder] = useState({});
  const microsoftRef = useRef(null);
  const googleRef = useRef(null);
  const current = currentFolder?.folder_name || placeholder;
  const onChangeFile = () => {
    API.track('open_edit_run_custom_folder', { folder_type: folderType.value });
    if (folderType.value == 'microsoft') {
      microsoftRef.current?.click();
    } else {
      googleRef.current?.click();
    }
  };
  const onChangeFileType = (result) => {
    if (result.value == 'microsoft') {
      onFolderSelect(microsoftFolder);
    } else {
      onFolderSelect(googleFolder);
    }
    setFolderType(result);
  };
  const onMicrosoftFolderSelect = (e) => {
    const result = e.items[0];
    const folder = {
      source_type: 'microsoft',
      folder_id: result?.id,
      drive_id: result?.parentReference?.driveId,
      folder_name: result?.name,
    };
    setMicrosoftFolder(folder);
    onFolderSelect(folder);
  };
  const onGoogleFolderSelect = (e) => {
    const result = e[0];
    const folder = {
      source_type: 'google',
      folder_id: result?.id,
      folder_name: result?.name,
    };
    setGoogleFolder(folder);
    onFolderSelect(folder);
  };

  return (
    <div className="flex w-full gap-x-3 mb-4">
      <div className="w-72">
        <IconSelect
          classNamePrefix="matik-select"
          isSearchable={false}
          options={options}
          value={folderType}
          onChange={onChangeFileType}
          isOptionDisabled={(option) => option.disabled}
          openMenuOnFocus={true}
        />
      </div>
      <TextboxWithEmbeddedIcons
        readOnly={true}
        textboxValue={current}
        rightIcon={<Icon name="chevron_right" size={16} theme="filled" />}
        rightIconClickHandler={onChangeFile}
      />
      <MicrosoftPicker
        className="hidden"
        onPicked={onMicrosoftFolderSelect}
        pickerMode="folders"
        buttonRef={microsoftRef}
      />
      <GoogleDrivePicker
        className="hidden"
        onPicked={onGoogleFolderSelect}
        view="folders"
        permissionsMessage="In order to set a custom folder, you will need to select allow in the authentication window."
        buttonRef={googleRef}
      />
    </div>
  );
}

IntegrationFolderSelector.propTypes = {
  currentFolder: PropTypes.object,
  onFolderSelect: PropTypes.func,
  disabledOptions: PropTypes.array,
  placeholder: PropTypes.string,
};

export default IntegrationFolderSelector;
