import React from 'react';
import PropTypes from 'prop-types';
import * as Logos from 'images/logos';

function Logo({ name, size }) {
  const LogoComponent = Logos[name];
  if (!LogoComponent) {
    // eslint-disable-next-line no-console
    console.warn('Could not find icon ', name);
    return null;
  }
  return <LogoComponent width={size} height={size} />;
}

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf([16, 20, 24, 28, 32, 48]),
};

export default Logo;
