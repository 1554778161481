import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from '../../lib/auth';

function Logout() {
  const history = useHistory();
  useEffect(() => {
    Auth.clearAuthToken();
    // We use push here because if we just reload the page, login will redirect to logout and we'll be in a loop
    history.push('/');
  }, []);
  return <></>;
}

export default Logout;
