import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Constants from '../../Constants';
import TrialExpirationTag from './TrialExpirationTag';
import PresentationsRemainingTag from './PresentationsRemainingTag';

class PlanInfoTag extends Component {
  render() {
    if (this.props.flags.enableSelfServeNavbarItems === false) {
      return <div />;
    }

    let planInfoComponent = <div />;
    if (this.props.user) {
      if (
        this.props.user.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.trialing &&
        this.props.user.enterprise.trial_days_remaining !== undefined
      ) {
        planInfoComponent = <TrialExpirationTag daysLeft={this.props.user.enterprise.trial_days_remaining} />;
      } else if (
        this.props.user.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.active &&
        this.props.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_team.tier_id &&
        this.props.user.enterprise.monthly_presentations_remaining !== undefined
      ) {
        planInfoComponent = (
          <PresentationsRemainingTag
            presentationsRemaining={this.props.user.enterprise.monthly_presentations_remaining}
          />
        );
      }
    }

    return <div className="trial-days-tag">{planInfoComponent}</div>;
  }
}

PlanInfoTag.propTypes = {
  flags: PropTypes.object,
  user: PropTypes.object,
};

export default withLDConsumer()(PlanInfoTag);
