import React from 'react';
import PropTypes from 'prop-types';
import { Form, Progress, Container, Level } from 'react-bulma-components';
import WithGooglePicker from 'components/shared/WithGooglePicker';
import { ReactComponent as Close } from 'svg/close.svg';
import presentation_icon from 'images/presentation_icon.svg';
import google_slides_icon from 'images/google_slides_icon.svg';
import ppt_icon from 'images/ppt_icon.svg';
import word_icon from 'images/microsoft_word_icon_20x20.svg';
import Constants from 'components/Constants';

const TemplateUpload = (props) => {
  const isDoc = props.sourceType == Constants.TEMPLATE_SOURCE_TYPES.WORD || false;
  const primaryActionText = isDoc ? 'Upload Word file' : 'Upload PowerPoint file';
  const uploadAccept = isDoc ? '.doc,.docx' : '.ppt,.pptx';
  return (
    <div>
      <Form.Field kind="addons">
        <Container className="choose-template-file">
          <img className="pbm choose-template-icon" src={presentation_icon} alt="Presentations" />
          <Form.Control className="has-text-centered">
            <input
              className="file-input ppt-upload"
              accept={uploadAccept}
              type="file"
              name="file"
              onChange={props.handleSelectFile}
            />
            <a className="template-file-picker" href="#upload">
              {primaryActionText}
            </a>
          </Form.Control>
          {!props.hideGoogle && (
            <React.Fragment>
              <span>or</span>
              <Form.Control className="has-text-centered">
                <a className="template-file-picker" onClick={props.onGoogleSlidesClick} href="#pickGoogle">
                  Choose Google Presentation
                </a>
              </Form.Control>
            </React.Fragment>
          )}
        </Container>
      </Form.Field>
      {renderUploadSection(props, isDoc)}
      {props.isLoading && (
        <div>
          <Progress max={100} value={props.percentCompleted} color="success" size="small" />
          <Form.Help>{props.processingStatus}</Form.Help>
        </div>
      )}
    </div>
  );
};

const renderUploadSection = (props, isDoc) => {
  let body = '';
  let icon = '';
  let fileName = '';

  if (props.selectedGooglePresentation) {
    icon = <img src={google_slides_icon} alt="Google Slides" />;
    fileName = props.selectedGooglePresentation.name;
  } else if (props.selectedFile) {
    icon = isDoc ? <img src={word_icon} alt="Word" /> : <img src={ppt_icon} alt="PowerPoint" />;
    fileName = props.selectedFile.name;
  }

  if (props.selectedGooglePresentation || props.selectedFile) {
    body = (
      <div>
        <Level className="template-file-upload">
          <Level.Side align="left">
            <Level.Item>{icon}</Level.Item>
            <Level.Item>
              <span className="file-name">{fileName}</span>
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <Close className="template-file-close" onClick={props.handleClearSelection} />
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
    );
  }

  return body;
};

TemplateUpload.propTypes = {
  handleSelectFile: PropTypes.func,
  isLoading: PropTypes.bool,
  onGoogleSlidesClick: PropTypes.func,
  percentCompleted: PropTypes.number,
  picker: PropTypes.object,
  processingStatus: PropTypes.string,
  selectedFile: PropTypes.object,
  selectedFileError: PropTypes.string,
  selectedGooglePresentation: PropTypes.object,
  handleClearSelection: PropTypes.func,
  hideGoogle: PropTypes.bool,
  sourceType: PropTypes.string,
};

export default WithGooglePicker(TemplateUpload);
