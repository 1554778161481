import React from 'react';
import PropTypes from 'prop-types';
import TestInputsModal from './TestInputsModal';
import TestConditionalContentInputsForm from './TestConditionalContentInputsForm';
import { useDispatch } from 'react-redux';
import { closeModal } from 'redux/ui/action';

function ButtonsTestInputsModal(props) {
  const { entityId, entityType, queryObj, testButtonText, setTestIsLoading, setTestResult, ui } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <TestInputsModal show={ui.modal?.name === 'buttonsTestInputsModal'} onClose={handleClose} title={testButtonText}>
      <TestConditionalContentInputsForm
        onCancel={handleClose}
        conditionalContent={queryObj}
        setTestIsLoading={setTestIsLoading}
        setTestResult={setTestResult}
        entityId={entityId}
        entityType={entityType}
        submitButtonText={testButtonText}
      />
    </TestInputsModal>
  );
}

ButtonsTestInputsModal.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string,
  queryObj: PropTypes.object,
  testButtonText: PropTypes.string,
  setTestIsLoading: PropTypes.func,
  setTestResult: PropTypes.func,
  ui: PropTypes.object,
};

export default ButtonsTestInputsModal;
