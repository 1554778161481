import React, { useState, useRef, useEffect } from 'react';
import { ColorPicker, useColor, toColor } from 'react-color-palette';
import PropTypes from 'prop-types';
import FormInput from './FormInput';
import Skeleton from 'react-loading-skeleton';

export const PickColor = ({ ancestorEl, hexColor, isLoading, onChange }) => {
  const defaultColor = '#000000';
  const [color, setColor] = useColor('hex', hexColor || defaultColor);
  const [showPicker, setShowPicker] = useState(false);
  const [direction, setDirection] = useState('up');
  const [inputValue, setInputValue] = useState(hexColor || defaultColor);
  const ref = useRef();

  const onClose = () => {
    setShowPicker(false);
  };

  const setPickerDirection = () => {
    if (ancestorEl) {
      const pickerRect = ref.current.getBoundingClientRect?.();
      const parentRect = ancestorEl.getBoundingClientRect?.();
      if (pickerRect && parentRect) {
        const spaceAbove = pickerRect.top - parentRect.top;
        const spaceBelow = parentRect.bottom - pickerRect.bottom;
        if (spaceBelow > spaceAbove) {
          setDirection('down');
        } else {
          setDirection('up');
        }
      }
    }
  };

  const onClick = () => {
    setPickerDirection();
    setShowPicker(!showPicker);
  };

  const onColorChange = (color) => {
    setColor(color);
    setInputValue(color.hex);
    onChange(color);
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    const updatedColor = setColorIfHexValid(e.target.value);
    if (updatedColor) {
      onChange(updatedColor);
    }
  };

  useEffect(() => {
    if (hexColor !== color.hex) {
      setInputValue(hexColor);
      setColorIfHexValid(hexColor || defaultColor);
    }
  }, [hexColor]);

  const setColorIfHexValid = (newHexColor) => {
    if (/^#(?:[0-9a-fA-F]{3}){1,2}$/i.test(newHexColor)) {
      const updatedColor = toColor('hex', newHexColor);
      setColor(updatedColor);
      return updatedColor;
    }
    return null;
  };

  useEffect(() => {
    const onKeyUp = (e) => {
      if (e.key === 'Enter' && showPicker) {
        onClose();
      }
    };

    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [showPicker, onClose]);

  return (
    <div ref={ref} className="color-picker-wrapper">
      {isLoading ? (
        <Skeleton width="150px" height="25px" />
      ) : (
        <div onClick={onClick} className={'color-picker-swatch'}>
          <div className={'color-picker-swatch-preview'} style={{ backgroundColor: color.hex }}></div>
          <FormInput type="text" inputClass="hex-input" value={inputValue} onChange={onInputChange} />
        </div>
      )}
      {showPicker ? (
        <>
          <div className={`color-picker-popover ${direction}`}>
            <ColorPicker width={320} height={150} color={color} onChange={onColorChange} hideRGB hideHSV light />
          </div>
          <div
            className="color-picker-backdrop"
            onClick={() => {
              onClose();
            }}
          />
        </>
      ) : null}
    </div>
  );
};

PickColor.propTypes = {
  ancestorEl: PropTypes.object,
  onChange: PropTypes.func,
  hexColor: PropTypes.string,
  isLoading: PropTypes.bool,
};
