import { bindActionCreators } from 'redux';
import {
  openModal,
  closeModal,
  openFullScreenModal,
  closeFullScreenModal,
  openSidepane,
  closeSidepane,
  openGoogleSheetDrawer,
  closeGoogleSheetDrawer,
  openEditableField,
  closeEditableField,
  onEscPress,
  toggleIsChanged,
  openUnsavedChangesModal,
} from './action';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      openModal,
      closeModal,
      openFullScreenModal,
      closeFullScreenModal,
      openSidepane,
      closeSidepane,
      openGoogleSheetDrawer,
      closeGoogleSheetDrawer,
      openEditableField,
      closeEditableField,
      onEscPress,
      toggleIsChanged,
      openUnsavedChangesModal,
    },
    dispatch,
  );
};

export { mapDispatchToProps };
