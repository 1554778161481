export function mapGroupStateToProps(state) {
  const { groups: groupsState } = state;

  const { isFetching, isFetchingMembers, groups } = groupsState;

  return {
    isFetching,
    isFetchingMembers,
    groups,
  };
}
