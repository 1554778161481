import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { throttle } from 'lodash';
import { useTemplatePdf } from 'lib/hooks/useTemplate';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.cMapUrl = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.288/cmaps/'; // include "/"
pdfjs.cMapPacked = true;

const DocumentView = ({ template, className }) => {
  const [pageCount, setPageCount] = useState(0);
  const [pdfWidth, setPdfWidth] = useState(500);
  const container = useRef();

  const { data: pdfUrl } = useTemplatePdf(template?.id);

  const handleWindowResize = throttle(() => {
    if (container.current) {
      let width = container.current.clientWidth;
      if (width < 200) {
        width = 200;
      }
      if (width > 800) {
        width = 800;
      }
      setPdfWidth(width);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  useEffect(() => {
    handleWindowResize();
  }, [container.current]);

  const onDocumentLoadSuccess = ({ numPages }) => setPageCount(numPages);

  if (!pdfUrl?.url) {
    return null;
  }

  return (
    <div ref={container} className={className}>
      <Document
        // note that react-pdf crashes when file={some url} -> file={null} -> file={some url} after the component is mounted
        // if we use the url as the key, any time it changes the component will be re-mounted and avoid that crash.
        key={pdfUrl.url || 'emptypdf'}
        file={pdfUrl.url}
        loading="Loading document..."
        error="Failed to load document. Refresh the Google Docs connection to load this document."
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <div className="is-flex is-flex-col is-vertical-centered gap-medium">
          {Array.from(new Array(pageCount)).map((v, i) => (
            <div className="has-light-gray-border background-white overflow-hidden" key={i}>
              <Page pageNumber={i + 1} width={pdfWidth} />
            </div>
          ))}
        </div>
      </Document>
    </div>
  );
};
DocumentView.propTypes = {
  template: PropTypes.object,
  className: PropTypes.string,
};

export default DocumentView;
