import React from 'react';
import PropTypes from 'prop-types';
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  Edit,
  List,
  TextField,
  TextInput,
  SimpleForm,
  EditButton,
  required,
  Create,
  FunctionField,
} from 'react-admin';
import roleNames from './RoleNames';

const RoleActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export const RoleList = (props) => (
  <List {...props} actions={<RoleActions />}>
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        label="name"
        render={(record) => {
          const customRoleName = roleNames.find((choice) => choice.name == record.name)?.label || record.name;
          return customRoleName;
        }}
      />
      ;
      <TextField source="description" />
      <EditButton />
    </Datagrid>
  </List>
);

RoleList.propTypes = {};

const RoleTitle = ({ record }) => {
  return <span>Role {record ? `"${record.name}"` : ''}</span>;
};

RoleTitle.propTypes = {
  record: PropTypes.any,
};

export const RoleEdit = (props) => (
  <Edit title={<RoleTitle />} {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={required('Name is required')} />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);

export const RoleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required('Name is required')} />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);
