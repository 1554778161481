import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';

class SnowflakeDatabaseForm extends Component {
  render() {
    const hostColor = this.props.hostError ? 'danger' : null;
    const warehouse_database = this.props.database ? this.props.database.split('.') : ['', ''];
    const warehouse = warehouse_database[0];
    const database = warehouse_database[1];
    return (
      <React.Fragment>
        <Form.Field>
          <Form.Label htmlFor="account-name">Account Name</Form.Label>
          <Form.Help>
            The account name of your snowflake instance, for more details
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.snowflake.net/manuals/user-guide/python-connector-api.html#usage-notes-for-the-account-parameter-for-the-connect-method"
            >
              {' '}
              click here.
            </a>
          </Form.Help>
          <Form.Control>
            <Form.Input
              placeholder={'Account name of your snowflake instance'}
              id="account-name"
              type="text"
              name="host"
              color={hostColor}
              onChange={this.props.onChange}
              value={this.props.host}
            />
          </Form.Control>
          {this.props.hostError && <Form.Help color="danger">{this.props.hostError}</Form.Help>}
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="warehouse">Warehouse</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Warehouse to connect to"
              type="text"
              id="warehouse"
              name="warehouse"
              onChange={this.onWarehouseDatabaseChange}
              value={warehouse}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="database">Database</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Database to connect to"
              type="text"
              id="database"
              name="database"
              onChange={this.onWarehouseDatabaseChange}
              value={database}
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  }

  onWarehouseDatabaseChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'database') {
      const warehouse = this.props.database.split('.')[0];
      const newDatabase = e.target.value;
      this.props.onChange({ target: { name: 'database', value: warehouse + '.' + newDatabase } });
    } else {
      const database = this.props.database.split('.')[1];
      const newWarehouse = e.target.value;
      this.props.onChange({ target: { name: 'database', value: newWarehouse + '.' + (database ? database : '') } });
    }
  };
}

SnowflakeDatabaseForm.propTypes = {
  host: PropTypes.string,
  hostError: PropTypes.string,
  database: PropTypes.string,
  testResult: PropTypes.object,
  testDataSource: PropTypes.func,
  testIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  selectedDataSource: PropTypes.object,
  deleteDataSource: PropTypes.func,
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
};

export default SnowflakeDatabaseForm;
