import React from 'react';
import PropTypes from 'prop-types';
import InputWithOptionalInputs from './InputWithOptionalInputs';

const ApiInputField = (props) => (
  <span className="input api-input-container">
    <span className="api-method">{props.method ? props.method : 'Get'}</span>
    {props.includeBaseUrl && <span className="base-url">{props.baseUrl ? props.baseUrl : ''}</span>}
    {props.allowInputs ? (
      <div className="api-input">
        <InputWithOptionalInputs
          onChange={props.onChangeWithInputs}
          inputs={props.inputs}
          value={props.apiVal}
          theme="minimal"
          isReadOnly={props.isReadOnly}
          isInputPopoverDisabled={props.isInputPopoverDisabled}
        />
      </div>
    ) : (
      <input
        className="api-input"
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        id={props.id}
        value={props.apiVal}
        onChange={props.onChange}
        disabled={props.isReadOnly}
      />
    )}
  </span>
);

ApiInputField.propTypes = {
  allowInputs: PropTypes.bool,
  apiVal: PropTypes.string,
  baseUrl: PropTypes.string,
  id: PropTypes.string,
  includeBaseUrl: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  method: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeWithInputs: PropTypes.func,
  inputs: PropTypes.object,
  placeholder: PropTypes.string,
  isInputPopoverDisabled: PropTypes.bool,
};

export default ApiInputField;
