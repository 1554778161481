import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HtmlEditor from './HtmlEditor';
import HtmlPreview from '../../shared/htmlBuilder/HtmlPreview';
import HtmlBuilderDroppable from '../../shared/htmlBuilder/HtmlBuilderDroppable';
import TabHeader from '../templates/TabHeader';
import { EyeIcon } from 'svg/eye';
import { ReactComponent as CodeIcon } from 'images/icons/code-20x20-regular.svg';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import success_check from 'images/success_check.svg';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import htmlUtils from 'lib/html.js';
import { isEmpty } from 'lodash';
import TestHtmlBuilderModal from 'components/shared/htmlBuilder/TestHtmlBuilderModal';

const SavingState = Object.freeze({
  NOT_SAVED: 'not saved',
  SAVING: 'saving',
  SAVED: 'saved,',
});

export const HtmlBuilderContext = React.createContext({
  testResult: {},
  setTestResult: () => {},
  setInputsInQueryString: () => {},
  isFetchingDc: false,
  setIsFetchingDc: () => {},
  setAllDynamicContent: () => {},
  allDynamicContent: {},
  onPreviewDcModal: () => {},
});

function EmailBodyHtml(props) {
  const [testResult, setTestResult] = useState({});
  const [isFetchingDc, setIsFetchingDc] = useState(false);
  const [allDynamicContent, setAllDynamicContent] = useState({});

  const [currentTab, setCurrentTab] = useState(props.currentTemplate?.source?.editor_type || 'code');
  const [editorHtml, setEditorHtml] = useState(props.emailHtml);
  const [saving, setSaving] = useState(SavingState.NOT_SAVED);
  const [timer, setTimer] = useState(null);

  const onPreviewDcModal = () => {
    props.openModal('testHtmlBuilderModal');
  };

  const onEmailPreview = (emailPreviewValues) => {
    const isComplete = Object.keys(allDynamicContent).every((key) => key in emailPreviewValues);

    let completedTestResults = {};
    if (isComplete) {
      Object.keys(emailPreviewValues).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(allDynamicContent, key)) {
          completedTestResults[key] = emailPreviewValues[key];
        }
      });
      setTestResult(completedTestResults);
    }
  };

  const onEmailPreviewError = (err) => {
    setIsFetchingDc(false);
    const testResult = { message: err, status: 'error' };
    setTestResult(testResult);
  };

  const updateHtml = (html) => {
    clearTimeout(timer);
    setSaving(SavingState.NOT_SAVED);
    setEditorHtml(html);

    setTimer(
      setTimeout(async () => {
        setSaving(SavingState.SAVING);
        const updatedTemplate = await htmlUtils.saveHtml(html, props.currentTemplate, props.subject);
        if (!isEmpty(updatedTemplate)) {
          props.onTemplateUpdateWithServerCall(updatedTemplate, false, false);
          setSaving(SavingState.SAVED);
        } else {
          setSaving(SavingState.NOT_SAVED);
        }
      }, 1000),
    );
  };

  const onTabClick = (e, tabName) => {
    e.preventDefault();
    setCurrentTab(tabName);
  };

  let tabs = [];

  if (
    !props.flags.enableEmailBuilder ||
    props.currentTemplate?.source?.editor_type === 'code' ||
    !props.currentTemplate?.source?.editor_type
  ) {
    tabs = [
      {
        icon: <CodeIcon />,
        label: 'Code',
        selected: currentTab === 'code',
        onClick: (e) => onTabClick(e, 'code'),
        className: 'email-tab',
      },
      {
        icon: <EyeIcon />,
        label: 'Preview',
        selected: currentTab === 'preview',
        onClick: (e) => onTabClick(e, 'preview'),
      },
    ];
  }

  let emailHtml = props.emailHtml;
  if (!isEmpty(props.emailPreviewValues)) {
    emailHtml = htmlUtils.replaceTagsWithValues(emailHtml, props.emailPreviewValues);
  } else if (props.jsonNodes) {
    emailHtml = htmlUtils.convertJsonNodesToHtmlString(props.jsonNodes);
  }

  let htmlView = <HtmlEditor emailHtml={editorHtml} updateHtml={updateHtml} canEdit={props.canEdit} />;
  if (currentTab === 'preview') {
    htmlView = (
      <HtmlPreview
        emailHtml={emailHtml}
        onTemplateTest={props.onTemplateTest}
        isPreviewLoading={props.isPreviewLoading}
      />
    );
  }
  if (props.flags.enableEmailBuilder && currentTab === 'visual') {
    htmlView = (
      <HtmlBuilderContext.Provider
        value={{
          testResult,
          setTestResult,
          isFetchingDc,
          setIsFetchingDc,
          setAllDynamicContent,
          allDynamicContent,
          onPreviewDcModal,
        }}
      >
        <HtmlBuilderDroppable
          onEditorSaveClick={props.onEditorSaveClick}
          jsonNodes={props.jsonNodes}
          currentTemplate={props.currentTemplate}
          isLoading={props.isLoading}
          updateTestResult={setTestResult}
          entityId={props.currentTemplate?.id}
          entityType="dynamic_content"
          setEmailIsLoading={(val) => setIsFetchingDc(val)}
        />
        <TestHtmlBuilderModal
          allDynamicContent={allDynamicContent}
          show={props.ui.modal?.name === 'testHtmlBuilderModal'}
          template={props.currentTemplate}
          onClose={props.closeModal}
          onEmailPreview={onEmailPreview}
          isTemplateTest={true}
          onEmailPreviewError={onEmailPreviewError}
          emailPreviewDynamicContent={true}
          isEmailVisualBuilder={true}
          setVisualBuilderFetching={setIsFetchingDc}
        />
      </HtmlBuilderContext.Provider>
    );
  }

  return (
    <div>
      {props.currentTemplate?.source?.editor_type === 'code' && (
        <div className="html-editor-header">
          <TabHeader tabs={tabs} />
          <AutoSaveDisplay saving={saving} />
        </div>
      )}
      {htmlView}
    </div>
  );
}

EmailBodyHtml.propTypes = {
  emailHtml: PropTypes.string,
  currentTemplate: PropTypes.object,
  subject: PropTypes.string,
  canEdit: PropTypes.bool,
  flags: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  isLoading: PropTypes.bool,
  jsonNodes: PropTypes.string,
  onEditorSaveClick: PropTypes.func,
  onTemplateTest: PropTypes.func,
  emailPreviewValues: PropTypes.object,
  isPreviewLoading: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(withLDConsumer()(EmailBodyHtml));

const AutoSaveDisplay = ({ saving }) => {
  let display;
  switch (saving) {
    case SavingState.SAVING:
      display = <em>saving...</em>;
      break;
    case SavingState.SAVED:
      display = (
        <>
          <img src={success_check}></img>
          <em>saved!</em>
        </>
      );
      break;
    default:
      display = <br />;
  }
  return <div className="auto-save-display">{display}</div>;
};

AutoSaveDisplay.propTypes = {
  saving: PropTypes.oneOf(Object.values(SavingState)),
};
