function mapApiInfoStateToProps(state, ownProps) {
  const { apiInfoByDataSourceId } = state;
  const dataSourceId = ownProps.dataSource.id;

  const {
    isFetching,
    lastUpdated,
    info: apiInfo,
    errors: apiErrors,
    reportCount,
    objectCount,
  } = apiInfoByDataSourceId && apiInfoByDataSourceId[dataSourceId]
    ? apiInfoByDataSourceId[dataSourceId]
    : { isFetching: false, info: {}, errors: {} };

  return {
    dataSourceId,
    isFetching,
    lastUpdated,
    apiInfo,
    apiErrors,
    reportCount,
    objectCount,
  };
}

export { mapApiInfoStateToProps };
