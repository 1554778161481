import './font.css';
import './main.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import SentryFullStory from '@sentry/fullstory';
import * as Fullstory from '@fullstory/browser';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import createAppStore from './createAppStore';
import AuthClient from './components/soup_dumpling/AuthClient';
import { dataProvider } from './components/soup_dumpling/SoupMain';
// import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const history = createBrowserHistory();
const store = createAppStore(AuthClient, dataProvider, history);
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  Fullstory.init({ orgId: '15NCRX' });
  Sentry.init({
    // eslint-disable-next-line no-undef
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // eslint-disable-next-line no-undef
    release: process.env.REACT_APP_CI_COMMIT_TAG,
    integrations: [
      new Integrations.BrowserTracing({ tracePropagationTargets: ['staging.matik.io', 'app.matik.io'] }),
      new SentryFullStory('matik-inc'),
    ],
    tracesSampleRate: 0.01,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always', // Ensures that we will always try to fetch, even if the lib thinks we're offline
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);
serviceWorkerRegistration.unregister();
// TODO (zak): Reregister to debug further
// serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
