import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Section } from 'react-bulma-components';
import { withRouter } from 'react-router-dom';
import DataSourceForm from './DataSourceForm';
import DataSourcesSidebar from './DataSourcesSidebar';
import utils from '../../../lib/utils';

class DataSourcesMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDataSource: null,
    };
  }

  componentDidMount() {
    this.setCurrentDataSource();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setCurrentDataSource();
    }
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const dataSourceType = urlParams.get('type');
    return (
      <div className="wrapper">
        <DataSourcesSidebar
          pagination={this.props.pagination}
          dataSources={this.props.dataSources}
          fetchItems={this.props.fetchItems}
          isPaginating={this.props.isPaginating}
          selectedDataSource={this.state.currentDataSource}
          searchAttributes={this.props.searchAttributes}
          searchState={this.props.searchState}
          isSearching={this.props.isSearching}
        />
        <div className="main pan">
          <Section className="is-paddingless">
            <DataSourceForm
              key={this.state.currentDataSource ? this.state.currentDataSource.id : 0}
              onDataSourceAdd={this.onDataSourceAdd}
              onDataSourceUpdate={this.onDataSourceUpdate}
              selectedDataSource={this.state.currentDataSource}
              deleteDataSource={this.props.deleteDataSource}
              initialDataSourceType={dataSourceType}
              entityType="template"
            />
          </Section>
        </div>
      </div>
    );
  }

  onDataSourceUpdate = (updatedDataSource) => {
    this.setState({ currentDataSource: updatedDataSource });
    this.props.onDataSourceUpdate(updatedDataSource);
  };

  onDataSourceAdd = (addedDataSource) => {
    this.setState({ currentDataSource: addedDataSource });
    this.props.onDataSourceAdd(addedDataSource);
  };

  setCurrentDataSource = () => {
    utils.getItemFromId(
      this.props.match.params.id,
      this.state.currentDataSource,
      this.props.dataSources,
      '/data_sources/',
      (currentDataSource) => this.setState({ currentDataSource }),
      () => this.props.history.replace('/data_sources/'),
      () => this.setState({ currentDataSource: null }),
    );
  };
}

DataSourcesMain.propTypes = {
  dataSources: PropTypes.array,
  deleteDataSource: PropTypes.func,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  history: PropTypes.object,
  onDataSourceAdd: PropTypes.func,
  onDataSourceSelect: PropTypes.func,
  onDataSourceUpdate: PropTypes.func,
  match: PropTypes.any,
  notification: PropTypes.string,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedDataSource: PropTypes.object,
};

export default withRouter(DataSourcesMain);
