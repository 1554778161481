import React, { Component } from 'react';
import { Auth } from '../../lib/auth';
import AuthPage from './AuthPage';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bulma-components';
import { MAlert } from '../shared/Alerts';
import API from '../../lib/api';
import matik_logo_black from '../../images/matik_logo_black.svg';
import { ReactComponent as WarningTriangle } from '../../svg/warning_triangle_solid.svg';
import { ReactComponent as Lock } from '../../svg/lock_closed.svg';
import Button from '../lib/Button';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      isPasswordInvalid: false,
      passwordErrorMsg: '',
      token: props.match.params.token,
      redirectToHome: false,
    };

    this.onPasswordUpdate = this.onPasswordUpdate.bind(this);
    this.onResetPasswordSubmit = this.onResetPasswordSubmit.bind(this);
    this.onResetSuccess = this.onResetSuccess.bind(this);
    this.onResetError = this.onResetError.bind(this);
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <AuthPage>
        <div className="auth-logo">
          <img src={matik_logo_black} alt="matik logo" height="40px" />
        </div>
        <Card className="auth-card">
          <form onSubmit={this.onResetPasswordSubmit} className="card-content">
            <h1 className="is-size-3 has-text-centered mbs">Reset Your Password</h1>
            <p className="has-text-centered mbl">Enter a new password for your account</p>
            <Form.Field>
              <Form.Control className="has-icons-left has-icons-right">
                <Form.Input
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onPasswordUpdate}
                />
                <span className="icon is-small is-left">
                  <Lock />
                </span>
                {this.state.isPasswordInvalid && (
                  <span className="icon is-small is-right">
                    <WarningTriangle />
                  </span>
                )}
              </Form.Control>
              {this.state.isPasswordInvalid && <p className="help is-danger">{this.state.passwordErrorMsg}</p>}
            </Form.Field>
            <Form.Field>
              <Form.Control>
                <Button type="submit" width="fullWidth">
                  Reset
                </Button>
              </Form.Control>
            </Form.Field>
          </form>
        </Card>
      </AuthPage>
    );
  }

  onPasswordUpdate(e) {
    this.setState({ password: e.target.value, isPasswordInvalid: false, passwordErrorMsg: '' });
  }

  onResetPasswordSubmit(e) {
    e.preventDefault();
    if (!this.state.password) {
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: 'Password is required' });
      return;
    }

    if (this.state.password.length < 8) {
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: 'Password should be longer than 8 characters' });
      return;
    }

    this.setState({ isPasswordInvalid: false });
    Auth.resetPassword(this.state.password, this.state.token, this.onResetSuccess, this.onResetError);
  }

  onResetSuccess(result) {
    MAlert('Password successfully reset', 'Success', 'success');
    Auth.storeAuthToken(result.data.access_token);
    API.track('successful_login');
    this.setState({ redirectToHome: true });
  }

  onResetError(err) {
    if (err.data) {
      let errorMessage = 'Something went wrong';
      if (err.data.status === 'error') {
        errorMessage = err.data.message;
      }
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: errorMessage });
    }
  }
}

ResetPassword.propTypes = {
  match: PropTypes.any,
};

export default ResetPassword;
