import API from './api';

const pdf = {
  downloadPdf: (presentation) => {
    API.requestWithDownload('get', `/presentations/${presentation.id}/download_pdf/`, 'blob', (response) => {
      API.track('pdf_download');
      const blob = response.data;
      const reader = new FileReader();
      reader.onload = (event) => {
        const a = document.createElement('a');
        a.href = event.target.result; // eslint-disable-line scanjs-rules/assign_to_href
        a.download = `${presentation.name}.pdf`;
        a.click();
      };
      reader.readAsDataURL(blob);
    });
  },

  downloadBulkPdf: (bulk_presentation_id) => {
    API.requestWithDownload('get', `/bulk_presentations/${bulk_presentation_id}/download_pdfs/`, 'blob', (response) => {
      API.track('bulk_pdf_download');
      const blob = response.data;
      const reader = new FileReader();
      reader.onload = (event) => {
        const a = document.createElement('a');
        a.href = event.target.result; // eslint-disable-line scanjs-rules/assign_to_href
        a.download = `${bulk_presentation_id}_presentations.zip`;
        a.click();
      };
      reader.readAsDataURL(blob);
    });
  },
};

export default pdf;
