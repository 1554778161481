import React, { useState } from 'react';
import WithUserlessLDProvider from './WithUserlessLDProvider';
import MatikAuthPlugTrial from './MatikAuthPlugTrial';
import matik_logo_black from '../../images/matik_logo_black.svg';
import SignUpFormTrial from './SignUpFormTrial';

function SignUpTrial() {
  const [companyName, setCompanyName] = useState('');

  return (
    <div className="signup-trial">
      <div className="signup-trial-left">
        <div className="signup-container">
          <div className="signup-container-header">
            <a className="signup-trial-logo" href="https://matik.io/">
              <img src={matik_logo_black} alt="matik logo" />
            </a>
            <span className="signup-trial-header">Template Builder</span>
          </div>
        </div>
        <div className="divider"></div>
        <div className="signup-container">
          <SignUpFormTrial setCompanyName={setCompanyName} />
        </div>
      </div>
      <div className="signup-trial-right">
        <MatikAuthPlugTrial companyName={companyName} />
      </div>
    </div>
  );
}

export default WithUserlessLDProvider(SignUpTrial);
