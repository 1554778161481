import { React, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';

import AccessManager from 'lib/AccessManager';
import dynamicContent from 'lib/dynamicContent';
import InputsList from 'lib/inputsList';

import AccessModal from 'components/shared/AccessModal';
import DocumentTemplateHeader from 'components/consumer/DocumentTemplateHeader';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import TestQueryHandlingInputsForm, {
  findTemplateContentTagsAndInputs,
} from 'components/consumer/TestQueryHandlingInputsForm';
import DataSourceLoggedIn from 'components/consumer/DataSourceLoggedIn';
import DocumentView from 'components/shared/DocumentView';

import { UserContext } from 'components/UserContext';
import { PresentationContext } from 'components/shared/presentations/PresentationContext';

import { useTemplateContent } from 'lib/hooks/useTemplate';

function DocumentPresentationCreator({
  template,
  onPresentationCreate,
  accessesByTemplateId,
  userDataSources,
  onTemplateUpdateWithServerCall,
  onUserDataSourceAdd,
  hideHeader,
}) {
  const userContext = useContext(UserContext);
  const presentationContext = useContext(PresentationContext);

  const location = useLocation();

  const ui = useSelector((state) => state.ui);

  const [inputValues, setInputValues] = useState({});

  // Seed input values from querystring
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let paramValues = {};
    for (let param of params.entries()) {
      paramValues[param[0]] = { value: param[1] };
    }
    if (!isEqual(paramValues, inputValues)) {
      setInputValues(paramValues);
    }
  }, []);

  // Seed input values and selected slides from presentation when regenerating.
  useEffect(() => {
    if (presentationContext?.regenerate && presentationContext?.presentationToRegenerate) {
      let contextInputValues = presentationContext.getInputValues();
      if (!isEqual(contextInputValues, inputValues)) {
        setInputValues(contextInputValues);
      }
    }
  }, [presentationContext?.regenerate, presentationContext?.presentationToRegenerate]);

  const { data: templateContent } = useTemplateContent(template?.id, template?.deleted);

  const allDynamicContent = {};
  if (templateContent) {
    dynamicContent.setAllDynamicContentByName(templateContent, allDynamicContent);
  }

  let orderedInputs;
  let bodyMatchingInputs = {};
  if (template) {
    const { inputs } = findTemplateContentTagsAndInputs(template, templateContent, [], allDynamicContent, { 1: true });
    bodyMatchingInputs = inputs;
    let inputsList = new InputsList(bodyMatchingInputs, template.params_order);
    orderedInputs = inputsList.getSortedList();
  }

  const handlePresentationCreate = (...args) => {
    onPresentationCreate(...args);
  };

  const accessObj = new AccessManager(template?.id, accessesByTemplateId, userContext.user);
  const canEdit = accessObj.can('edit');

  return (
    <>
      {template && (
        <>
          {!hideHeader && (
            <DocumentTemplateHeader
              template={template}
              orderedInputs={orderedInputs}
              onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
              canEdit={canEdit}
              accessesByTemplateId={accessesByTemplateId}
            />
          )}
          <div className="is-flex consumer-template-main">
            <div className="create-sidebar">
              <DataSourceLoggedIn
                template={template}
                userDataSources={userDataSources}
                onUserDataSourceAdd={onUserDataSourceAdd}
              >
                <TestQueryHandlingInputsForm
                  template={template}
                  allDynamicContent={allDynamicContent}
                  inputValues={inputValues}
                  onPresentationCreate={handlePresentationCreate}
                />
              </DataSourceLoggedIn>
            </div>
            <div className="create-templates template-slides document">
              <DocumentView className="mvxl mhl" template={template} />
            </div>
          </div>
          <AccessModal accesses={accessObj} item={template} show={ui.modal?.name === 'accessModal'} />
        </>
      )}
    </>
  );
}

DocumentPresentationCreator.propTypes = {
  template: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  onUserDataSourceAdd: PropTypes.func,
  userDataSources: PropTypes.object,
  match: PropTypes.any,
  accessesByTemplateId: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  hideHeader: PropTypes.bool,
};

export default WithLoadingAndEmpty(DocumentPresentationCreator);
