import {
  REQUEST_API_INFO,
  RECEIVE_API_INFO,
  INVALIDATE_API_INFO,
  REQUEST_REPORT_METADATA,
  RECEIVE_REPORT_METADATA,
  RECEIVE_REPORT_COUNT,
  RECEIVE_OBJECT_COUNT,
  IS_FETCHING,
  IS_NOT_FETCHING,
} from './actionTypes';

function apiInfo(
  state = {
    isFetching: true,
    didInvalidate: false,
    info: {},
    errors: {},
    reportCount: null,
    objectCount: null,
  },
  action,
) {
  switch (action.type) {
    case INVALIDATE_API_INFO:
      return Object.assign({}, state, { didInvalidate: true });
    case REQUEST_API_INFO:
      return Object.assign({}, state, { isFetching: true, didInvalidate: false });
    case RECEIVE_API_INFO:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        info: apiInfoInfo(state.info, action),
        errors: recordAPIError(state.errors, action),
        lastUpdated: action.receivedAt,
      });
    case REQUEST_REPORT_METADATA:
      return Object.assign({}, state, { isFetching: true, didInvalidate: false });
    case RECEIVE_REPORT_METADATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        info: addReportMetadataToInfo(state.info, action),
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_REPORT_COUNT:
      return Object.assign({}, state, {
        reportCount: action.count,
      });
    case RECEIVE_OBJECT_COUNT:
      return Object.assign({}, state, {
        objectCount: action.count,
      });
    case IS_FETCHING:
      return Object.assign({}, state, { isFetching: true, didInvalidate: false });
    case IS_NOT_FETCHING:
      return Object.assign({}, state, { isFetching: false, didInvalidate: false });
    default:
      return state;
  }
}

function addReportMetadataToInfo(state, action) {
  if (action.apiInfo) {
    return Object.assign({}, state, {
      ...state,
      reports: {
        ...state.reports,
        [action.reportName]: action.apiInfo[action.reportName],
      },
    });
  }
}

function apiInfoInfo(state, action) {
  if (!action.resourceId && !action.resourceName) {
    return Object.assign({}, state, action.apiInfo);
  }
  if (action.resourceName) {
    if (action.resourceId && action.resourceName !== 'reports' && action.resourceName !== 'objects') {
      return Object.assign({}, state, {
        [action.resourceName]: apiInfoByResourceName(state[action.resourceName], action),
      });
    } else {
      return Object.assign({}, state, {
        [action.resourceName]: action.apiInfo,
      });
    }
  }
}

function apiInfoByResourceName(state, action) {
  return Object.assign({}, state, {
    [action.resourceId]: action.apiInfo,
  });
}

function recordAPIError(errors, action) {
  let err = null;
  if (action.err) {
    if (action.err.response?.data) {
      err = action.err.response.data.message;
    } else {
      err = action.err;
    }
  }
  if (!action.resourceId && !action.resourceName) {
    return Object.assign({}, errors, err);
  } else if (!action.resourceId) {
    return Object.assign({}, errors, {
      [action.resourceName]: err,
    });
  } else {
    return Object.assign({}, errors, {
      [action.resourceName]: Object.assign({}, errors[action.resourceName], {
        [action.resourceId]: err,
      }),
    });
  }
}

function apiInfoByDataSourceId(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_API_INFO:
    case RECEIVE_API_INFO:
    case REQUEST_API_INFO:
    case RECEIVE_REPORT_COUNT:
    case RECEIVE_OBJECT_COUNT:
    case RECEIVE_REPORT_METADATA:
    case REQUEST_REPORT_METADATA:
    case IS_FETCHING:
    case IS_NOT_FETCHING:
      return Object.assign({}, state, {
        [action.dataSourceId]: apiInfo(state[action.dataSourceId], action),
      });
    default:
      return state;
  }
}

export { apiInfoByDataSourceId };
