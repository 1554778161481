import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';

function AthenaForm(props) {
  const output_location = props.auth ? props.auth['output_location'] : '';
  const workgroup = props.auth ? props.auth['workgroup'] : '';
  return (
    <React.Fragment>
      <Form.Field>
        <Form.Label htmlFor="host">Athena Data Source</Form.Label>
        <Form.Help>The name of the data source that Athena is querying</Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="Athena Catalog"
            type="text"
            name="host"
            onChange={props.onChange}
            value={props.host}
            id="host"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="database">Athena Database (Optional)</Form.Label>
        <Form.Help>The database that Athena is querying</Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="Athena Database"
            type="text"
            name="database"
            onChange={props.onChange}
            value={props.database}
            id="database"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="workgroup">Athena Workgroup (Optional)</Form.Label>
        <Form.Help>The workgroup that Athena is querying</Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="primary"
            type="text"
            name="workgroup"
            onChange={props.onAuthChange}
            value={workgroup}
            id="workgroup"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="output_location">Athena Output</Form.Label>
        <Form.Help>
          The output s3 bucket for queries. Should be of form s3://&lt;bucket_name&gt;/&lt;folder_name&gt;
        </Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="Athena Output Bucket"
            type="text"
            name="output_location"
            onChange={props.onAuthChange}
            value={output_location}
            id="output-location"
          />
        </Form.Control>
        {props.hostError && <Form.Help color="danger">{props.hostError}</Form.Help>}
      </Form.Field>
    </React.Fragment>
  );
}

AthenaForm.propTypes = {
  auth: PropTypes.object,
  database: PropTypes.string,
  deleteDataSource: PropTypes.func,
  host: PropTypes.string,
  isLoading: PropTypes.bool,
  onAuthChange: PropTypes.func,
  onChange: PropTypes.func,
  selectedDataSource: PropTypes.object,
  testDataSource: PropTypes.func,
  testIsLoading: PropTypes.bool,
  testResult: PropTypes.object,
  hostError: PropTypes.string,
};

export default AthenaForm;
