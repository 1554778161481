import React from 'react';
import { useEditor } from '@craftjs/core';

function findNodeByName(nodes, nodeName) {
  for (let key in nodes) {
    if (nodes[key].data.name === nodeName) {
      return nodes[key];
    }
  }
  return null;
}

function EmailContainerSettingsRender() {
  const { emailContainerNode } = useEditor((state, query) => {
    const currentNodes = query.getNodes();
    const emailContainerNode = findNodeByName(currentNodes, 'EmailContainer');

    return {
      emailContainerNode,
    };
  });

  return emailContainerNode ? (
    <>
      <p className="modules-header">Email Container Settings</p>
      <div>{React.createElement(emailContainerNode.related.settings)}</div>
    </>
  ) : null;
}

export default EmailContainerSettingsRender;
