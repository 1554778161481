import React, { Component } from 'react';
import API from '../../../lib/api';
import Button from '../../lib/Button';

class ManageBillingButton extends Component {
  render() {
    return <Button onClick={this.manageBillingClick}>Manage billing</Button>;
  }

  manageBillingClick = () => {
    API.post(
      '/stripe_billing/customer_portal/',
      {},
      (response) => {
        window.location.replace(response.data.redirect_url);
      },
      API.defaultError,
    );
  };
}

export default ManageBillingButton;
