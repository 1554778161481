import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { cloneDeep } from 'lodash';
import { ReactComponent as Plus } from '../../svg/plus.svg';
import trash_icon from '../../images/trash.svg';
import Button from '../lib/Button';

class Clause extends Component {
  render() {
    const existingClauses = this.props.queryObj.conditions[this.props.conditionIndex].clauses;
    const showPlusIcon =
      !existingClauses || this.props.clauseIndex === existingClauses.length - 1 || existingClauses.length === 0;
    return (
      <React.Fragment>
        <Form.Field className="pbm mhl !w-auto" kind="group">
          <div className="control" style={{ width: '40px' }}>
            <label className="label mts is-secondary-description">{this.props.clauseIndex <= 0 ? 'When' : 'And'}</label>
          </div>
          {this.props.children}
          {existingClauses && existingClauses.length !== 0 && (
            <Form.Control>
              <Button
                onClick={this.removeClause}
                category="secondary"
                size="small"
                data-tooltip-content="Remove Condition"
                data-tooltip-id="matik-tooltip"
                status={this.props.isReadOnly ? 'disabled' : 'default'}
              >
                <span className="icon">
                  <img src={trash_icon} alt="Delete" />
                </span>
              </Button>
            </Form.Control>
          )}
          <Form.Control>
            {showPlusIcon ? (
              <Button
                onClick={this.addClause}
                category="secondary"
                size="small"
                data-tooltip-content="Add Condition"
                data-tooltip-id="matik-tooltip"
                status={this.props.isReadOnly ? 'disabled' : 'default'}
              >
                <span className="icon">
                  <Plus />
                </span>
              </Button>
            ) : (
              <span className="button is-invisible px-3"></span>
            )}
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  }

  removeClause = (e) => {
    e.preventDefault();
    const updatedQueryObj = cloneDeep(this.props.queryObj);
    const updatedClauses = [];
    for (let i = 0; i < this.props.queryObj.conditions[this.props.conditionIndex].clauses.length; i++) {
      if (i !== this.props.clauseIndex) {
        updatedClauses.push(this.props.queryObj.conditions[this.props.conditionIndex].clauses[i]);
      }
    }
    updatedQueryObj.conditions[this.props.conditionIndex].clauses = updatedClauses;
    this.props.updateQuery(updatedQueryObj, true);
  };

  addClause = (e) => {
    e.preventDefault();
    const updatedQueryObj = cloneDeep(this.props.queryObj);
    updatedQueryObj.conditions[this.props.conditionIndex].clauses.push(this.props.generateEmptyClause());
    this.props.updateQuery(updatedQueryObj, true);
  };
}

Clause.propTypes = {
  children: PropTypes.any,
  clauseIndex: PropTypes.number,
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  conditionIndex: PropTypes.number,
  updateQuery: PropTypes.func,
  generateEmptyClause: PropTypes.func,
};

export default Clause;
