import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from './Icon';
import ButtonGroup from './ButtonGroup';
import IconPill from './IconPill';

function Banner({
  theme = 'default',
  orientation = 'horizontal',
  size = 'm',
  content,
  subContent,
  onClose,
  buttons,
  icon,
}) {
  let borderColor = 'grey-300';
  let backgroundColor = 'matik-white';

  switch (theme) {
    case 'success':
      borderColor = 'green-200';
      backgroundColor = 'green-100';
      icon =
        size === 's' ? (
          <div className="flex p-1 rounded-full text-matik-white bg-green-500">
            <Icon name="checkmark" theme="regular" size={12} />
          </div>
        ) : (
          <IconPill iconName="checkmark" iconTheme="filled" size="s" color="green" luminosity="medium" />
        );
      break;
    case 'error':
      borderColor = 'red-200';
      backgroundColor = 'red-100';
      icon =
        size === 's' ? (
          <div className="flex text-red-500">
            <Icon name="info" theme="filled" size={20} />
          </div>
        ) : (
          <IconPill iconName="info" iconTheme="filled" size="s" color="red" luminosity="medium" />
        );
      break;
    case 'warning':
      borderColor = 'yellow-200';
      backgroundColor = 'yellow-100';
      icon =
        size === 's' ? (
          <div className="flex text-yellow-500">
            <Icon name="warning" theme="filled" size={20} />
          </div>
        ) : (
          <IconPill iconName="warning" iconTheme="filled" size="s" color="yellow" luminosity="medium" />
        );
      break;
    case 'info':
      borderColor = 'blue-200';
      backgroundColor = 'blue-100';
      icon =
        size === 's' ? (
          <div className="flex text-blue-500">
            <Icon name="info" theme="filled" size={20} />
          </div>
        ) : (
          <IconPill iconName="info" iconTheme="filled" size="s" color="blue" luminosity="medium" />
        );
      break;
    case 'marketing':
      borderColor = 'blue-200';
      backgroundColor = 'matik-blue';
      break;
  }

  return (
    <div
      className={`flex px-4 py-3 border rounded gap-2.5 ${
        orientation !== 'vertical' ? 'items-center' : ''
      } border-${borderColor} bg-${backgroundColor}`}
    >
      <div>{icon}</div>
      <div className="flex flex-col gap-1 grow">
        <div className={`text-matik-${theme === 'marketing' ? 'white' : 'black'} leading-none`}>{content}</div>
        {subContent && <div className="text-grey-600 leading-tight">{subContent}</div>}
        {orientation === 'vertical' && buttons?.length > 0 && (
          <div className="pt-3">
            <ButtonGroup gap={3} align="left">
              {buttons}
            </ButtonGroup>
          </div>
        )}
      </div>
      <div>
        <ButtonGroup gap={3}>
          {orientation !== 'vertical' && buttons}
          {onClose && (
            <Button type="button" width="square" size="medium" category="tertiary" onClick={onClose}>
              <span className="text-grey-600 flex">
                <Icon size={16} name="dismiss" theme="filled" />
              </span>
            </Button>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
}
Banner.propTypes = {
  theme: PropTypes.oneOf(['success', 'error', 'warning', 'info', 'default', 'marketing']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  size: PropTypes.oneOf(['s', 'm']),
  content: PropTypes.node,
  subContent: PropTypes.node,
  onClose: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  /** Only used for 'default' and 'marketing' themes. */
  icon: PropTypes.node,
};

export default Banner;
