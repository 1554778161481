import { NOTIFY_ERROR, NOTIFY_SUCCESS } from './actionTypes';

export function mapNotificationStateToProps(state) {
  const { notifications } = state;

  const { [NOTIFY_SUCCESS]: success, [NOTIFY_ERROR]: failure } = notifications;

  return {
    successNotifications: success,
    failureNotifications: failure,
  };
}
