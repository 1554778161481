import React, { Component } from 'react';
import PropType from 'prop-types';
import { DateRangePickerInputController, DayPickerRangeController } from 'react-dates';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import { ReactComponent as InfoIcon } from '../../../svg/info_icon_blue.svg';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Box, Columns, Level, Form } from 'react-bulma-components';
import OutsideHandler from '../OutsideHandler';
import { Select } from '../FormSelect';
import RadioInput from '../RadioInput';
import CheckboxWithLabel from '../CheckboxWithLabel';
import RelativeDate from '../../../lib/RelativeDate';
import API from '../../../lib/api';
import Pluralize from 'pluralize';
import localeDateFormatterUtils from '../../../lib/localeDate';
import Constants from '../../../components/Constants';
import Icon from '../../lib/Icon';

const RELATIVE_OPTIONS = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const SUB = '-';

class DateRangeInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      dateError: '',
      dateFormat: Constants.DATE_FORMATS.usa,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      dateFormat: localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
        localeDateFormatterUtils.getUserLocale(),
      ),
    });
  }

  renderAbsolute(startDate, endDate) {
    const leftNav = (
      <div role="button" className="left-navigation-button">
        <Icon name="chevron_left" size={16} theme="regular" />
      </div>
    );
    const rightNav = (
      <div role="button" className="right-navigation-button">
        <ChevronRight />
      </div>
    );
    return (
      <DayPickerRangeController
        displayFormat={this.state.dateFormat}
        onDatesChange={({ startDate, endDate }) => this.onChange(startDate, endDate)}
        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
        focusedInput={this.state.focusedInput}
        startDate={startDate ? startDate : null}
        endDate={endDate ? endDate : null}
        navPrev={leftNav}
        navNext={rightNav}
        isOutsideRange={() => {}}
        hideKeyboardShortcutsPanel={true}
        renderCalendarInfo={this.renderDatePresets}
        numberOfMonths={2}
        disabled={this.props.isReadOnly}
      />
    );
  }

  renderRelative(startDate, endDate) {
    const { dateError } = this.state;
    const value = dateError ? '' : startDate.value;
    const units = startDate.units;
    const useFull = dateError ? false : endDate.mod !== null;
    const relativeTimeUnits = RELATIVE_OPTIONS.filter((option) => option.value === units)[0];

    let checkbox;
    if (units === RELATIVE_OPTIONS[0].value || units === RELATIVE_OPTIONS[1].value) {
      checkbox = null;
    } else {
      let label;
      if (units === RELATIVE_OPTIONS[2].value) {
        label = 'Use full month(s)';
      } else {
        label = 'Use full year(s)';
      }
      checkbox = (
        <Level className="mbn mtm">
          <CheckboxWithLabel
            id="use-full"
            label={label}
            checked={useFull}
            onChange={(e) => this.onUseFullToggle(e, startDate, endDate)}
          />
        </Level>
      );
    }

    return (
      <div className="pll prl pbl light-top-border">
        <Level className="mtm mbn">Last</Level>
        <Columns className="man">
          <Columns.Column className="pts prn pbn pln is-2">
            <Form.Input
              type="text"
              value={value}
              onChange={(e) => this.onRelativeChange(units, e.target.value, false, startDate, endDate)}
            />
          </Columns.Column>
          <Columns.Column className="pts prn pbn plm is-10">
            <Select
              classNamePrefix="matik-select"
              className="matik-select-container"
              options={RELATIVE_OPTIONS}
              value={relativeTimeUnits}
              onChange={(choice) => this.onRelativeChange(choice.value, value, true, startDate, endDate)}
              menuPortalTarget={null}
              menuPosition="absolute"
              isDisabled={this.props.isReadOnly}
            />
          </Columns.Column>
        </Columns>
        {dateError && <Form.Help color="danger">{dateError}</Form.Help>}
        {checkbox}
        {this.renderInfo(startDate, endDate)}
      </div>
    );
  }

  renderCaptionText(startDate, endDate) {
    startDate = startDate.dateValue();
    endDate = endDate.dateValue();
    const caption = 'If you generate this today, the date range of the data included would be';
    let dateCaption;
    const localizedStartDate = localeDateFormatterUtils.getDateStringFromMomentObject(startDate);
    const localizedEndDate = localeDateFormatterUtils.getDateStringFromMomentObject(endDate);
    dateCaption = `${localizedStartDate} to ${localizedEndDate}`;
    return (
      <React.Fragment>
        {caption} <b>{dateCaption}</b>
      </React.Fragment>
    );
  }

  renderInfo(startDate, endDate) {
    if (this.state.dateError) {
      return;
    }

    return (
      <React.Fragment>
        <Level className="mtm day-date-caption">
          <InfoIcon />
          <div className="caption">{this.renderCaptionText(startDate, endDate)}</div>
        </Level>
      </React.Fragment>
    );
  }

  renderFormHelp(startDate, endDate) {
    if (this.state.dateError) {
      return;
    }

    return <Form.Help className="caption">{this.renderCaptionText(startDate, endDate)}</Form.Help>;
  }

  renderRelativeCaption(startDate, endDate) {
    if (this.state.dateError) {
      return '';
    }
    const operator = startDate.operator === SUB ? 'Last ' : 'Next ';
    const value = startDate.value === 1 ? '' : `${startDate.value} `;
    const mod = endDate.mod !== null ? 'full calendar ' : '';
    const units = Pluralize(Pluralize.singular(startDate.units), startDate.value, false);
    return `${operator}${value}${mod}${units}`;
  }

  render() {
    const inputValue = this.props.inputValues[this.props.input.name].value;
    const isRelative = inputValue
      ? RelativeDate.isRelativeDate(inputValue[0]) && RelativeDate.isRelativeDate(inputValue[1])
      : false;
    const { focusedInput } = this.state;
    const { fetchedStartDate, fetchedEndDate } = this.fetchStartAndEndDates(isRelative);

    let inputField = null;
    if (isRelative) {
      inputField = (
        <Level className="mbn">
          <div className="date-input-field" onFocus={this.onFocusIn} ref={this.inputRef}>
            <Form.Input
              placeholder="Date Range"
              value={this.renderRelativeCaption(fetchedStartDate, fetchedEndDate)}
              onFocus={(e) => this.setState({ focusedInput: e.target.className })}
              readOnly
            />
          </div>
        </Level>
      );
    } else {
      inputField = (
        <div className="DateRangePicker DateRangePicker_1" onFocus={this.onFocusIn} ref={this.inputRef}>
          <DateRangePickerInputController
            displayFormat={this.state.dateFormat}
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            customArrowIcon={<ChevronRight />}
            startDate={fetchedStartDate ? fetchedStartDate : null}
            startDateId="startDate"
            isStartDateFocused={focusedInput === START_DATE}
            endDate={fetchedEndDate ? fetchedEndDate : null}
            endDateId="endDate"
            isEndDateFocused={focusedInput === END_DATE}
            onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            onDatesChange={({ startDate, endDate }) => this.onChange(startDate, endDate)}
            isOutsideRange={() => {}}
            disabled={this.props.isReadOnly}
          />
        </div>
      );
    }
    let popup = null;
    if (focusedInput) {
      const body = isRelative
        ? this.renderRelative(fetchedStartDate, fetchedEndDate)
        : this.renderAbsolute(fetchedStartDate, fetchedEndDate);

      const transform = `translate3d(${this.boundingRect.x}px, ${this.boundingRect.y}px, 0px)`;
      popup = (
        <OutsideHandler onFocusOut={this.onFocusOut}>
          <Box className="day-picker-popup is-paddingless" style={{ transform }}>
            {this.props.allowRelative && (
              <Level className="mbn pal light-bottom-border">
                <Level.Side align="left">
                  <Level.Item>
                    <RadioInput
                      value="absolute"
                      label="Absolute"
                      name="absoluteRelativeSelect"
                      checked={!isRelative}
                      onChange={() => this.onAbsoluteSelect(fetchedStartDate, fetchedEndDate)}
                      disabled={this.props.isReadOnly}
                    />
                  </Level.Item>
                  <Level.Item>
                    <RadioInput
                      value="relative"
                      label="Relative to generation date"
                      name="absoluteRelativeSelect"
                      checked={isRelative}
                      onChange={this.onRelativeSelect}
                      disabled={this.props.isReadOnly}
                    />
                  </Level.Item>
                </Level.Side>
              </Level>
            )}
            {body}
          </Box>
        </OutsideHandler>
      );
    }
    return (
      <React.Fragment>
        {inputField}
        {popup}
        {isRelative && this.renderFormHelp(fetchedStartDate, fetchedEndDate)}
      </React.Fragment>
    );
  }

  fetchStartAndEndDates = (isRelative) => {
    const inputValue = this.props.inputValues[this.props.input.name].value
      ? this.props.inputValues[this.props.input.name].value
      : [null, null];
    let fetchedStartDate = null;
    let fetchedEndDate = null;
    if (inputValue.length === 2) {
      if (isRelative) {
        fetchedStartDate = RelativeDate.createFromString(inputValue[0]);
        fetchedEndDate = RelativeDate.createFromString(inputValue[1]);
      } else if (this.state.dateError || inputValue[0] === null || inputValue[1] === null) {
        fetchedStartDate = inputValue[0];
        fetchedEndDate = inputValue[1];
        if (moment(inputValue[0]).isValid()) {
          fetchedStartDate = moment(inputValue[0]);
        }
        if (moment(inputValue[1]).isValid()) {
          fetchedEndDate = moment(inputValue[1]);
        }
      } else {
        if (moment(inputValue[0]).isValid()) {
          fetchedStartDate = moment(inputValue[0]);
        }
        if (moment(inputValue[1]).isValid()) {
          fetchedEndDate = moment(inputValue[1]);
        }
      }
    }
    return { fetchedStartDate, fetchedEndDate };
  };

  onUseFullToggle = (e, startDate, endDate) => {
    if (this.state.dateError) {
      return;
    }
    if (e.target.checked) {
      startDate.update(startDate.value, startDate.units, 1);
      if (startDate.value === 0) {
        endDate.update(endDate.value, endDate.units, -1);
      } else {
        endDate.update(1, endDate.units, -1);
      }
    } else {
      startDate.update(startDate.value, startDate.units);
      endDate.update(0, endDate.units);
    }
    this.onChange(startDate.relativeValue(), endDate.relativeValue());
  };

  onFocusIn = () => {
    this.boundingRect = this.inputRef.current.getBoundingClientRect();
    // 452 box height
    if (window.innerHeight < this.boundingRect.bottom + 456 && this.boundingRect.bottom - 456 > 0) {
      this.boundingRect.y -= 460;
    } else {
      this.boundingRect.y += this.boundingRect.bottom - this.boundingRect.top + 4;
    }
  };

  onFocusOut = () => {
    this.setState({ focusedInput: null });
  };

  onAbsoluteSelect = (fetchedStartDate, fetchedEndDate) => {
    this.setState({ dateError: '', focusedInput: 'startDate' });
    this.onChange(fetchedStartDate.dateValue(), fetchedEndDate.dateValue());
  };

  onRelativeSelect = () => {
    // reset to 1 day ago
    const startDate = new RelativeDate(1, 'days');
    const endDate = new RelativeDate(0, 'days');
    this.onChange(startDate.relativeValue(), endDate.relativeValue());
    this.setState({ dateError: '' });
    API.track('selected_relative_date_range');
  };

  onRelativeChange = (units, value, unitChange, startDate, endDate) => {
    let updatedStartDate = '';
    let updatedEndDate = '';
    let dateError = '';
    const timeValue = parseInt(value);
    if (timeValue >= 0) {
      if (endDate.mod !== null && !unitChange) {
        startDate.update(timeValue, units, 1);
        if (timeValue === 0) {
          endDate.update(timeValue, units, -1);
        } else {
          endDate.update(1, units, -1);
        }
      } else {
        startDate.update(timeValue, units);
        endDate.update(0, units);
      }
      if (startDate.dateValue().year() <= 0) {
        dateError = 'Please enter a valid value';
      }
      updatedStartDate = startDate.relativeValue();
      updatedEndDate = endDate.relativeValue();
    } else if (value === '') {
      dateError = 'Please enter a value';
    } else {
      dateError = 'Please enter a valid value';
    }
    this.onChange(updatedStartDate, updatedEndDate, dateError);
    this.setState({ dateError });
  };

  onChange = (startDate, endDate, dateError = '') => {
    const inputName = this.props.input.name;
    const updatedInputValue = {};
    if (startDate === null || endDate === null) {
      updatedInputValue.value = [startDate, endDate];
      dateError = `Please enter date in ${this.state.dateFormat} format`;
    } else if (startDate === '' || endDate === '') {
      // We are only updating the updatedInputValues when at least one of the dates is a valid moment object.
      // When the startDate and endDate are both empty strings, isRelative is true and there is a date error
      // where we want to preserve the value and units.
      if (moment.isMoment(startDate) || moment.isMoment(endDate)) {
        updatedInputValue.value = [startDate, endDate];
      }
      dateError = 'Please enter a value';
    } else {
      updatedInputValue.value = [startDate, endDate];
    }
    this.setState({ dateError });
    updatedInputValue.error = dateError;
    this.props.onChange(inputName, { [inputName]: updatedInputValue });
  };

  renderDatePresets = () => {
    const today = moment();
    const presets = [
      {
        text: 'Last Week',
        start: moment().subtract(1, 'week'),
        end: today,
      },
      {
        text: 'Last Month',
        start: moment().subtract(1, 'month'),
        end: today,
      },
      {
        text: 'Last Three Months',
        start: moment().subtract(3, 'month'),
        end: today,
      },
      {
        text: 'Last Six Months',
        start: moment().subtract(6, 'month'),
        end: today,
      },
      {
        text: 'Last Year',
        start: moment().subtract(1, 'year'),
        end: today,
      },
    ];

    const { startDate, endDate } = this.fetchStartAndEndDates();

    return (
      <div>
        {presets.map(({ text, start, end }) => {
          let className = 'button is-small is-secondary mls mbs';
          const isSelected = start.isSame(startDate, 'day') && end.isSame(endDate, 'day');
          if (isSelected) {
            className = className + ' is-active';
          }

          return (
            <button
              className={className}
              key={text}
              id={`preset-${text.replace(/ /g, '-').toLowerCase()}`}
              type="button"
              onClick={(e) => this.onPresetClick(e, start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  onPresetClick = (e, start, end) => {
    if (e) {
      e.stopPropagation();
    }
    this.onChange(start, end);
    // Close the date range picker.
    this.setState({ focusedInput: null });
  };
}

DateRangeInputField.propTypes = {
  allowRelative: PropType.bool,
  input: PropType.object,
  inputValues: PropType.object,
  isReadOnly: PropType.bool,
  inputColor: PropType.string,
  onChange: PropType.func,
  match: PropType.object,
};

export default withRouter(DateRangeInputField);
