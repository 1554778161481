import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Card, Level } from 'react-bulma-components';
import MatikAuthPlug from '../../auth/MatikAuthPlug';
import WithFetchUser from '../../shared/WithFetchUser';
import Constants from '../../Constants';
import ContactSalesLink from '../../shared/ContactSalesLink';
import { Auth } from '../../../lib/auth';
import matik_logo_black from '../../../images/matik_logo_black.svg';
import ManageBillingButton from './ManageBillingButton';

class AccountInactive extends Component {
  render() {
    if (this.props.user) {
      const subStatus = this.props.user.enterprise.subscription_status;
      if (
        subStatus === Constants.SUBSCRIPTION_STATUSES.active ||
        (subStatus === Constants.SUBSCRIPTION_STATUSES.trialing &&
          this.props.user.enterprise.trial_days_remaining > 0 &&
          this.props.user.enterprise.plan_id !== Constants.MATIK_TIERS.matik_chrome_extension.tier_id)
      ) {
        return <Redirect to={'/'} />;
      }
    }

    const { header, content } = this.fetchContent();
    const button = this.fetchButton();

    return (
      <div>
        <a className="signup-logo" href="https://matik.io/">
          <img src={matik_logo_black} alt="matik logo" height="40px" />
        </a>
        <Card className="signup-card">
          <Level>
            <Level.Item align="left">
              <div className="signup-form">
                <span className="signup-header">{header}</span>
                <div className="account-inactive-content">
                  <span>{content}</span>
                  <span className="ptl">
                    You can continue to let Matik save you time by upgrading your plan or contact our sales team so we
                    can find a unique solution to serve your needs.
                  </span>
                </div>
                {this.props.enterprise?.is_self_serve && <div className="pick-plan-button">{button}</div>}
                <ContactSalesLink className="contact-link" from="account_inactive" />
                <a href="#logout" className="logout-link" onClick={this.onLogoutClick}>
                  Logout
                </a>
              </div>
            </Level.Item>
            <Level.Item align="right">
              <MatikAuthPlug />
            </Level.Item>
          </Level>
        </Card>
      </div>
    );
  }

  fetchContent = () => {
    let header = '';
    let content = '';
    if (this.props.user) {
      const subStatus = this.props.user.enterprise.subscription_status;
      const subs = this.props.user.enterprise.plan_id;
      if (subStatus === Constants.SUBSCRIPTION_STATUSES.incomplete_expired) {
        header = 'Your free trial just ended 😔';
        content =
          'The free trial for your enterprise at Matik just ended, but all your Templates, Dynamic Content and Presentations are still safe.';
      } else if (
        subStatus === Constants.SUBSCRIPTION_STATUSES.past_due ||
        subStatus === Constants.SUBSCRIPTION_STATUSES.unpaid
      ) {
        header = 'Your account is past due';
        content =
          'All of your Templates, Dynamic Content and Presentations are still safe, but you need to update your payment method in order to reactivate.';
      } else if (subs === Constants.MATIK_TIERS.matik_chrome_extension.tier_id) {
        header = 'You Do not have access to Matik App';
        content = 'You do not have access to matik app.';
      } else {
        header = 'Your account is no longer active 😔';
        content = 'All of your Templates, Dynamic Content and Presentations are still safe.';
      }
    }

    return { header, content };
  };

  fetchButton = () => {
    let button = null;
    if (this.props.user) {
      const subStatus = this.props.user.enterprise.subscription_status;
      if (
        subStatus === Constants.SUBSCRIPTION_STATUSES.past_due ||
        subStatus === Constants.SUBSCRIPTION_STATUSES.unpaid
      ) {
        button = <ManageBillingButton />;
      } else {
        button = (
          <Link className="button is-primary" to="/prices">
            Pick a plan
          </Link>
        );
      }
    }

    return button;
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    Auth.logOut(() => window.location.reload());
  };
}

AccountInactive.propTypes = {
  flags: PropTypes.object,
  user: PropTypes.object,
  enterprise: PropTypes.object,
};

export default WithFetchUser(withLDConsumer()(AccountInactive));
