import { createConnector } from 'react-instantsearch-dom';

const connectSearchBoxWithFilters = createConnector({
  displayName: 'SearchBoxWithFilters',
  getProvidedProps(props, searchState) {
    const currentRefinement = searchState.query;
    const refinementList = searchState.refinementList;

    // Connect the underlying component with the `currentRefinement`
    return { currentRefinement, refinementList };
  },
  refine(props, searchState, nextRefinement) {
    return {
      // `searchState` represents the search state of *all* widgets. We need to extend it
      // instead of replacing it, otherwise other widgets will lose their respective state.
      ...searchState,
      query: nextRefinement,
    };
  },
  getSearchParameters(searchParameters, props, searchState) {
    return searchParameters.setQuery(searchState.query);
  },
  cleanUp(props, searchState) {
    return searchState;
  },
});

export default connectSearchBoxWithFilters;
