import React, { Component } from 'react';
import { isEqual, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTemplates } from 'lib/hooks/useTemplate';
import InsertTemplatePopupBody from '../shared/InsertTemplatePopupBody';

class InsertTemplateSlidePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLibrary: null,
    };
  }

  componentDidMount() {
    if (
      !this.state.selectedLibrary &&
      this.props.allTemplatesById &&
      Object.values(this.props.allTemplatesById).length > 0
    ) {
      const templatesWithMatchingSource = Object.values(this.props.allTemplatesById).filter(
        (template) => template.source_type === this.props.currentTemplate.source_type,
      );
      if (templatesWithMatchingSource.length) {
        this.setState({ selectedLibrary: templatesWithMatchingSource[0] });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.allTemplatesById, this.props.allTemplatesById)) {
      if (
        !this.state.selectedLibrary &&
        this.props.allTemplatesById &&
        Object.values(this.props.allTemplatesById).length > 0
      ) {
        const templatesWithMatchingSource = Object.values(this.props.allTemplatesById).filter(
          (template) => template.source_type === this.props.currentTemplate.source_type,
        );
        if (templatesWithMatchingSource.length) {
          this.setState({ selectedLibrary: templatesWithMatchingSource[0] });
        }
      } else if (
        this.state.selectedLibrary &&
        (!this.props.allTemplatesById || Object.values(this.props.allTemplatesById).length === 0)
      ) {
        this.setState({ selectedLibrary: null });
      }
    }
  }

  render() {
    // only slides from templates matching the current source_type ('google_slides' vs 'powerpoint') are insertable
    const insertableTemplatesById = pickBy(
      this.props.allTemplatesById,
      (template) => template.source_type === this.props.currentTemplate.source_type,
    );
    return (
      <InsertTemplatePopupBody
        currentTemplate={this.props.currentTemplate}
        isFetching={this.props.isAllTemplatesFetching}
        insertionIdx={this.props.insertionIdx}
        selectedTemplate={this.state.selectedLibrary}
        selectTemplate={this.selectLibrary}
        templatesById={insertableTemplatesById}
        title="Insert slide from template"
        insertLibrarySlidesIntoTemplate={this.props.insertLibrarySlidesIntoTemplate}
        endUserLibraryExists={Object.values(insertableTemplatesById).length > 1}
      />
    );
  }

  selectLibrary = (obj, action) => {
    if (action.action === 'select-option') {
      this.setState({ selectedLibrary: this.props.allTemplatesById[obj.value] });
    }
  };
}

InsertTemplateSlidePopup.propTypes = {
  allTemplatesById: PropTypes.object,
  isAllTemplatesFetching: PropTypes.bool,
  currentTemplate: PropTypes.object,
  insertionIdx: PropTypes.number,
  insertLibrarySlidesIntoTemplate: PropTypes.func,
};

const InsertTemplateSlidePopupWrapper = (props) => {
  const { elements: templates, isPending: isAllTemplatesFetching } = useTemplates(0, 999, null);

  // Not technically "templates" since these are search results which are minimal template data
  const allTemplatesById = {};
  if (templates) {
    templates.forEach((t) => (allTemplatesById[t.id] = t));
  }
  return (
    <InsertTemplateSlidePopup
      {...props}
      isAllTemplatesFetching={isAllTemplatesFetching}
      allTemplatesById={allTemplatesById}
    />
  );
};

export default InsertTemplateSlidePopupWrapper;
