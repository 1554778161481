import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NameComponent from './NameComponent';
import ModalDropDown from '../modal/ModalDropdown';
import Preview from '../Preview';
import { v4 as uuidv4 } from 'uuid';
import circle_plus_icon from '../../../images/circle_plus.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import API from '../../../lib/api';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import Constants from 'components/Constants';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

const PARAMETER_TYPE_TEXT = 'TEXT';
const PARAMETER_TYPE_SINGLE_SELECT = 'SINGLE_SELECT';

function NameTemplateModalBody({
  template,
  orderedInputs,
  setPrimaryButtonDisabled,
  setPrimaryButtonOnClick,
  onTemplateUpdateWithServerCall,
}) {
  setPrimaryButtonOnClick(() => saveTemplate);
  const [nameTemplate, setNameTemplate] = useState([]);
  const [initialNameTemplatePreview, setInitialNameTemplatePreview] = useState('');
  const dateFormatOptions = Constants.VALID_DATE_FORMATS.map((dateFormat) => ({
    label: dateFormat,
    value: dateFormat,
  }));

  const inputOptions = orderedInputs.map((inputOption) => ({
    label: inputOption.display_name ? inputOption.display_name : inputOption.name,
    value: inputOption.id,
  }));

  const COMPONENT_TYPES = {
    INPUT_NAME: {
      displayName: 'Input Name',
      previewName: 'inputName',
      description: 'Display an input name',
      enumName: 'INPUT_NAME',
      icon: 'input',
      parameters: {
        inputType: PARAMETER_TYPE_SINGLE_SELECT,
        options: inputOptions,
        parameterFieldName: 'parameterId',
      },
    },
    INPUT_VALUE: {
      displayName: 'Input Value',
      previewName: 'inputValue',
      description: 'Display an input value',
      enumName: 'INPUT_VALUE',
      icon: 'input',
      parameters: {
        inputType: PARAMETER_TYPE_SINGLE_SELECT,
        options: inputOptions,
        parameterFieldName: 'parameterId',
      },
    },
    PLAIN_TEXT: {
      displayName: 'Plain Text',
      previewName: 'plainText',
      description: 'Enter custom text',
      enumName: 'PLAIN_TEXT',
      icon: 'input_text',
      parameters: {
        inputType: PARAMETER_TYPE_TEXT,
        parameterFieldName: 'text',
      },
    },
    TEMPLATE_NAME: {
      displayName: 'Template Name',
      previewName: 'templateName',
      description: 'Display the template name',
      enumName: 'TEMPLATE_NAME',
      icon: 'template',
      parameters: null,
      preview: template.name,
    },
    TIME_STAMP: {
      displayName: 'Time Stamp',
      previewName: 'timeStamp',
      description: 'Display the generation time',
      enumName: 'TIME_STAMP',
      icon: 'timer',
      parameters: {
        inputType: PARAMETER_TYPE_SINGLE_SELECT,
        options: dateFormatOptions,
        parameterFieldName: 'text',
      },
    },
  };
  const DELIMITER = ' - ';

  const augmentNameTemplateWithIds = (nameTemplate) => {
    let newNameTemplate = nameTemplate.map((component) => Object.assign({}, component));
    for (let i = 0; i < newNameTemplate.length; i++) {
      newNameTemplate[i].id = uuidv4();
    }
    return newNameTemplate;
  };

  const getDefaultNameTemplate = () => {
    let defaultNameTemplate = [];
    defaultNameTemplate.push({
      id: uuidv4(),
      componentType: COMPONENT_TYPES.TEMPLATE_NAME.enumName,
      parameters: {},
    });
    if (orderedInputs.length > 0) {
      defaultNameTemplate.push({
        id: uuidv4(),
        componentType: COMPONENT_TYPES.INPUT_NAME.enumName,
        parameters: { parameterId: orderedInputs[0].id },
      });
      defaultNameTemplate.push({
        id: uuidv4(),
        componentType: COMPONENT_TYPES.INPUT_VALUE.enumName,
        parameters: { parameterId: orderedInputs[0].id },
      });
    }
    defaultNameTemplate.push({
      id: uuidv4(),
      componentType: COMPONENT_TYPES.TIME_STAMP.enumName,
      parameters: { text: Constants.DEFAULT_DATE_FORMAT },
    });
    return defaultNameTemplate;
  };

  useEffect(() => {
    let augmentedNameTemplate;
    if (template.presentation_name_template?.components?.length) {
      // Need to give each component of the name template a temporary ID to make them easier to refer to in the UI
      augmentedNameTemplate = augmentNameTemplateWithIds(template.presentation_name_template.components);
      setInitialNameTemplatePreview(getNameTemplatePreview(augmentedNameTemplate));
    } else {
      augmentedNameTemplate = getDefaultNameTemplate();
      setInitialNameTemplatePreview('');
    }
    setNameTemplate(augmentedNameTemplate);
  }, []);

  useEffect(() => {
    setPrimaryButtonDisabled(saveIsDisabled());
  }, [nameTemplate]);

  const addElementButton = (
    <button className="button is-secondary" aria-label="Add element" onClick={(e) => e.preventDefault()}>
      <span className="icon">
        <img src={circle_plus_icon} alt="circle plus icon" />
      </span>
      <span>Add element</span>
    </button>
  );

  const saveIsDisabled = () => {
    return !!initialNameTemplatePreview && initialNameTemplatePreview === getNameTemplatePreview(nameTemplate);
  };

  const resetTemplateToDefault = () => {
    setNameTemplate(getDefaultNameTemplate());
  };

  const getNameTemplatePreview = (nameTemplate) => {
    const renderComponent = (component) => {
      switch (component.componentType) {
        case COMPONENT_TYPES.INPUT_NAME.enumName:
          for (let orderedInput of orderedInputs) {
            if (component.parameters && orderedInput.id === component.parameters.parameterId) {
              return `{${COMPONENT_TYPES.INPUT_NAME.previewName}: ${orderedInput.name}}`;
            }
          }
          break;
        case COMPONENT_TYPES.INPUT_VALUE.enumName:
          for (let orderedInput of orderedInputs) {
            if (component.parameters && orderedInput.id === component.parameters.parameterId) {
              return `{${COMPONENT_TYPES.INPUT_VALUE.previewName}: ${orderedInput.name}}`;
            }
          }
          break;
        case COMPONENT_TYPES.PLAIN_TEXT.enumName:
          return component.parameters.text;
        case COMPONENT_TYPES.TEMPLATE_NAME.enumName:
          return `{${COMPONENT_TYPES.TEMPLATE_NAME.previewName}}`;
        case COMPONENT_TYPES.TIME_STAMP.enumName:
          return `{${component.parameters.text}}`;
      }
      return '';
    };
    const componentStrings = nameTemplate.filter(nameTemplateComponentIsValid).map(renderComponent);
    return componentStrings.join(DELIMITER);
  };

  const addComponent = (component) => {
    let componentEnum = component.enumName;
    let componentParam =
      componentEnum === COMPONENT_TYPES.TIME_STAMP.enumName ? { text: Constants.DEFAULT_DATE_FORMAT } : {};
    let newNameTemplate = nameTemplate.map((component) => Object.assign({}, component));
    newNameTemplate.push({ id: uuidv4(), componentType: componentEnum, parameters: componentParam });
    setNameTemplate(newNameTemplate);
  };

  const onComponentChange = (componentId, newValue) => {
    let newNameTemplate = nameTemplate.map((component) => Object.assign({}, component));
    for (let i = 0; i < newNameTemplate.length; i++) {
      if (newNameTemplate[i].id === componentId) {
        let updateFieldName = COMPONENT_TYPES[newNameTemplate[i].componentType].parameters.parameterFieldName;
        newNameTemplate[i].parameters[updateFieldName] = newValue;
      }
    }
    setNameTemplate(newNameTemplate);
  };

  const onComponentDelete = (componentId) => {
    let newNameTemplate = nameTemplate
      .map((component) => Object.assign({}, component))
      .filter((component) => component.id !== componentId);

    setNameTemplate(newNameTemplate);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    updateComponentOrder(result.source.index, result.destination.index);
  };

  const updateComponentOrder = (startIndex, endIndex) => {
    let newNameTemplate = nameTemplate.map((component) => Object.assign({}, component));
    const [removed] = newNameTemplate.splice(startIndex, 1);
    newNameTemplate.splice(endIndex, 0, removed);
    setNameTemplate(newNameTemplate);
  };

  const stripNameTemplateIds = (nameTemplate) => {
    let newNameTemplate = nameTemplate.map((component) => Object.assign({}, component));
    for (let i = 0; i < newNameTemplate.length; i++) {
      delete newNameTemplate[i].id;
    }
    return newNameTemplate;
  };

  const saveTemplate = async () => {
    if (!validateDateTime()) {
      return false;
    }
    let finalNameTemplate = stripNameTemplateIds(
      nameTemplate.map((component) => Object.assign({}, component)).filter(nameTemplateComponentIsValid),
    );
    let newTemplate = Object.assign({}, template);
    newTemplate.presentation_name_template = { components: finalNameTemplate };
    await onTemplateUpdateWithServerCall(newTemplate);
    setInitialNameTemplatePreview(getNameTemplatePreview(nameTemplate));
    API.track('add_custom_template_name', { name: finalNameTemplate });
  };

  const validateDateTime = () => {
    let returnVal = true;
    let updateNameTemplate = nameTemplate;
    updateNameTemplate.forEach((component) => {
      if (
        component.componentType === COMPONENT_TYPES.TIME_STAMP.enumName &&
        Constants.VALID_DATE_FORMATS.indexOf(component?.parameters?.text.trim()) < 0
      ) {
        component.parameters.error = 'Invalid Date Format';
        returnVal = false;
      } else {
        component.parameters.error = '';
        returnVal = true;
      }
    });
    if (!returnVal) {
      setNameTemplate(updateNameTemplate);
    }
    return returnVal;
  };

  const nameTemplateComponentIsValid = (component) => {
    const componentType = COMPONENT_TYPES[component.componentType];
    if (!componentType.parameters || !componentType.parameters.inputType) {
      return true;
    }
    return !!component.parameters[componentType.parameters.parameterFieldName];
  };

  return (
    <div className="border border-grey-300 rounded-md">
      <div className="p-5 border-b border-grey-300">
        <p>Naming Structure</p>
        <p className="help">Set the naming convention for content generated from this template</p>
      </div>
      <div className="p-5">
        <div className="mb-4">
          <Button category="secondary" onClick={resetTemplateToDefault}>
            <Icon name="arrow_undo" size={16} theme="regular" />
            <p className="ml-2 text-sm">Reset to default</p>
          </Button>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => {
              return (
                <div {...provided.droppableProps} ref={provided.innerRef} style={{}}>
                  {nameTemplate.map((nameComponent, index) => (
                    <Draggable key={nameComponent.id} draggableId={nameComponent.id} index={index}>
                      {(provided) => (
                        <div
                          className="param-wrapper"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style, left: null, top: null }}
                        >
                          <NameComponent
                            nameComponent={nameComponent}
                            onValueChange={onComponentChange}
                            onDelete={onComponentDelete}
                            componentTypes={COMPONENT_TYPES}
                            key={nameComponent.id}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        <ModalDropDown
          dropdownTrigger={addElementButton}
          isHoverable={true}
          active={false}
          isRight={false}
          className="mtm"
        >
          {[
            COMPONENT_TYPES.INPUT_NAME,
            COMPONENT_TYPES.INPUT_VALUE,
            COMPONENT_TYPES.PLAIN_TEXT,
            COMPONENT_TYPES.TEMPLATE_NAME,
            COMPONENT_TYPES.TIME_STAMP,
          ].map((componentType) => (
            <a
              href="#dummy"
              className="dropdown-item"
              key={componentType.enumName}
              onClick={(e) => {
                e.preventDefault();
                addComponent(componentType);
              }}
            >
              <div className="is-flex tpn-menu-item">
                <div className="icon">
                  <Icon name={componentType.icon} />
                </div>
                <div>
                  <div className="tpn-menu-name">{componentType.displayName}</div>
                  <div className="tpn-menu-description">{componentType.description}</div>
                </div>
              </div>
            </a>
          ))}
        </ModalDropDown>
        <Preview heading="Outcome" body={getNameTemplatePreview(nameTemplate)} />
      </div>
    </div>
  );
}

NameTemplateModalBody.propTypes = {
  template: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  orderedInputs: PropTypes.array,
  setPrimaryButtonDisabled: PropTypes.func,
  setPrimaryButtonOnClick: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(NameTemplateModalBody);
