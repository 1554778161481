import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/shared/modal/Modal';
import { Form } from 'react-bulma-components';
import InputWithError from '../shared/InputWithError';
import { AvatarSelect } from '../shared/FormSelect';
import { MConfirm } from '../shared/Alerts';
import PropTypes from 'prop-types';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import ToggleSwitch from 'components/lib/ToggleSwitch';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';
import TextboxWithEmbeddedIcons from 'components/lib/TextboxWithEmbeddedIcons';
import Icon from 'components/lib/Icon';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';

const GroupModal = ({ group, isFetchingMembers, allUsers = [], onClose, updateGroup, addGroup, deleteGroup, ui }) => {
  const initialFolderStates = { google: null, microsoft: null };
  const initialIconStates = {
    google: <Icon name="chevron_right" size={16} />,
    microsoft: <Icon name="chevron_right" size={16} />,
  };
  const [description, setDescription] = useState(group ? group.description : '');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(group ? group.name : '');
  const [nameError, setNameError] = useState('');
  const [useCustomFolder, setUseCustomFolder] = useState({ google: false, microsoft: false });
  const googleRef = useRef(null);
  const microsoftRef = useRef(null);
  const [defaultIntegrationFolders, setDefaultIntegrationFolders] = useState(initialFolderStates);
  const [currentIntegrationFolders, setCurrentIntegrationFolders] = useState(initialFolderStates);
  const sourceProviders = ['google', 'microsoft'];
  const [currentIntegrationFolderNames, setCurrentIntegrationFolderNames] = useState({
    google: '/Matik Presentations/',
    microsoft: '/Matik Presentations/',
  });
  const [rightIcons, setRightIcons] = useState(initialIconStates);
  const LDClient = useLDClient();
  const customFolderFeatureFlagIsOn = LDClient?.variation('customPresentationGenerationFolders', false) || false;

  const handleOpenFolderModal = (folderProvider) => {
    if (folderProvider === 'google') {
      googleRef.current?.click();
    }

    if (folderProvider === 'microsoft') {
      microsoftRef.current?.click();
    }
  };

  const computeRightIconName = (folderProvider) => {
    if (currentIntegrationFolders[folderProvider] || defaultIntegrationFolders[folderProvider]) {
      return 'dismiss';
    }

    return 'chevron_right';
  };

  const handleRemoveTemplateIntegrationFolder = (folderProvider) => {
    setCurrentIntegrationFolders({ ...currentIntegrationFolders, [folderProvider]: null });
    setCurrentIntegrationFolderNames('/Matik Presentations/');
  };

  const handleRightIconClick = (folderProvider) => {
    const rightIconName = rightIcons[folderProvider]?.props?.name;
    if (rightIconName === 'dismiss') {
      handleRemoveTemplateIntegrationFolder(folderProvider);
      setCurrentIntegrationFolderNames({ ...currentIntegrationFolderNames, [folderProvider]: '/Matik Presentations/' });
      setRightIcons({ ...rightIcons, [folderProvider]: <Icon name="chevron_right" size={16} /> });
    } else {
      handleOpenFolderModal(folderProvider);
    }
  };

  const initDefaultIntegrationFolders = async () => {
    if (group?.id) {
      const folderPromises = sourceProviders.map(
        (provider) =>
          new Promise((resolve) => {
            utils.getIntegrationFolderByUserGroup(provider, group.id, (res) => {
              if (res?.folder_id) {
                resolve({ ...res, folder_provider: provider });
              } else {
                resolve({ ...res, [provider]: null });
              }
            });
          }),
      );

      const defaultFolders = await Promise.all(folderPromises);

      const updatedFoldersToBeSet = defaultFolders.reduce((acc, folder) => {
        return folder?.folder_provider ? { ...acc, [folder.folder_provider]: folder } : acc;
      }, initialFolderStates);
      setDefaultIntegrationFolders(updatedFoldersToBeSet);
    }
  };

  const initRightIcons = async () => {
    setRightIcons(initialIconStates);
    const icons = await Promise.all(
      sourceProviders.map(async (provider) => {
        const rightIconName = await computeRightIconName(provider);
        return { [provider]: <Icon name={rightIconName} size={16} /> };
      }),
    );
    icons.forEach((icon) => {
      setRightIcons((prevIcons) => ({ ...prevIcons, ...icon }));
    });
  };

  useEffect(() => {
    const initCurrentFoldersAndIcons = async () => {
      setCurrentIntegrationFolderNames({
        google: defaultIntegrationFolders.google?.folder_name || '/Matik Presentations/',
        microsoft: defaultIntegrationFolders.microsoft?.folder_name || '/Matik Presentations/',
      });

      setCurrentIntegrationFolders({
        google: defaultIntegrationFolders.google,
        microsoft: defaultIntegrationFolders.microsoft,
      });

      setUseCustomFolder({
        google: defaultIntegrationFolders.google !== null,
        microsoft: defaultIntegrationFolders.microsoft !== null,
      });

      await initRightIcons();
    };

    initCurrentFoldersAndIcons();
  }, [defaultIntegrationFolders]);

  const _createNewIntegrationFoldersIfNeeded = async (provider, gId) => {
    if (
      !defaultIntegrationFolders[provider] &&
      currentIntegrationFolders[provider] &&
      useCustomFolder[provider] &&
      gId
    ) {
      const newFolderCreated = await new Promise((resolve) => {
        utils.createIntegrationFolder(
          {
            source_type: provider,
            folder_id: currentIntegrationFolders[provider].folder_id,
            drive_id: currentIntegrationFolders[provider]?.drive_id || null,
            folder_name: currentIntegrationFolders[provider].name,
            group_id: gId,
          },
          Constants.IntegrationFolderTypes.GROUP,
          () => {
            resolve();
          },
        );
      });
      await newFolderCreated;
      return true;
    }
    return false;
  };

  const _updateIntegrationFoldersIfNeeded = async (foldersCreated, provider, gId) => {
    if (!foldersCreated[provider] && currentIntegrationFolders[provider] && useCustomFolder[provider] && gId) {
      const updatedFolder = new Promise((resolve) => {
        utils.updateIntegrationFolder(
          currentIntegrationFolders[provider].id,
          {
            source_type: provider,
            folder_id: currentIntegrationFolders[provider].folder_id,
            drive_id: currentIntegrationFolders[provider]?.drive_id || null,
            folder_name: currentIntegrationFolders[provider].name,
            group_id: gId,
            id: currentIntegrationFolders[provider].id,
          },
          () => {
            resolve();
          },
        );
      });
      await updatedFolder;
      return true;
    }
    return false;
  };

  const handleNewIntegrationFoldersSaving = async (newGroup = {}) => {
    let foldersCreated = {};
    let foldersUpdated = {};
    const gId = group?.id || newGroup?.id;

    for (const provider of sourceProviders) {
      const createdFolderProvider = await _createNewIntegrationFoldersIfNeeded(provider, gId);
      foldersCreated[provider] = Boolean(createdFolderProvider);
    }

    for (const provider of sourceProviders) {
      const updatedFolderProvider = await _updateIntegrationFoldersIfNeeded(foldersCreated, provider, gId);
      foldersUpdated[provider] = Boolean(updatedFolderProvider);
    }

    for (const provider of sourceProviders) {
      const shouldDeleteFolder =
        !foldersUpdated[provider] && !foldersCreated[provider] && defaultIntegrationFolders[provider]?.id;

      const shouldDeleteCustomFolder = !useCustomFolder[provider] && defaultIntegrationFolders[provider]?.id;

      if (shouldDeleteFolder || shouldDeleteCustomFolder) {
        await new Promise((resolve) => {
          utils.deleteIntegrationFolder(defaultIntegrationFolders[provider].id, resolve);
        });
      }
    }
  };

  const onFolderSelect = (folderData, folderProvider) => {
    if (!folderData) {
      return;
    }

    const isGoogleProvider = folderProvider === 'google';
    const selectedFolder = isGoogleProvider ? folderData[0] : folderData.items[0];

    const newFolderData = {
      ...selectedFolder,
      folder_id: selectedFolder.id,
      id: defaultIntegrationFolders[folderProvider]?.id || null,
    };

    setCurrentIntegrationFolders({
      ...currentIntegrationFolders,
      [folderProvider]: newFolderData,
    });

    setCurrentIntegrationFolderNames({
      ...currentIntegrationFolderNames,
      [folderProvider]: selectedFolder.name,
    });
  };

  const mapUserToMember = (user) => ({
    value: `${user.id}`,
    label: user.name ? user.name : user.email,
    photo_url: user.photo_url,
  });

  const initialMembers = group && group.members ? group.members.map(mapUserToMember) : [];
  const [members, setMembers] = useState(initialMembers);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      if (group) {
        setDescription(group.description);
        setMembers(group.members ? group.members.map(mapUserToMember) : []);
        setName(group.name);
      }
      await initDefaultIntegrationFolders();
      setIsLoading(false);
    };

    initialize();
  }, [group]);

  const getSuggestions = () => {
    const memberIds = members.map((member) => member.value);
    const usersNotIncludingMembers = allUsers.filter((user) => memberIds.indexOf(user.id) < 0);
    return usersNotIncludingMembers.map(mapUserToMember);
  };

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.name === 'active' ? e.target.value === '1' : e.target.value;

    if (name === 'name') {
      setName(value);
      if (nameError) {
        setNameError('');
      }
    } else if (name === 'description') {
      setDescription(value);
    }
  };

  const onMemberChange = (_obj, action) => {
    const updatedMembers = [...members];
    if (action.action === 'select-option') {
      const member = action.option;
      updatedMembers.push(member);
      setMembers(updatedMembers);
    } else if (action.action === 'select-all-options') {
      updatedMembers.push(...action.option);
      setMembers(updatedMembers);
    } else if (action.action === 'remove-value') {
      const memberId = parseInt(action.removedValue.value);
      removeMember(memberId);
    } else if (action.action === 'clear') {
      setMembers([]);
    }
  };

  const removeMember = (memberIdToRemove) => {
    const updatedMembers = members.filter((member) => parseInt(member.value) !== memberIdToRemove);
    setMembers(updatedMembers);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let newGroup = {};
    if (validateForm()) {
      const data = {
        name: name,
        description: description,
        member_ids: members.map((member) => member.value),
      };

      if (group) {
        await new Promise((resolve) => {
          updateGroup(group.id, data, (res) => {
            newGroup = res;
            resolve();
          });
        });
      } else {
        await new Promise((resolve) => {
          addGroup(data, (res) => {
            newGroup = res;
            resolve();
          });
        });
      }
      await handleNewIntegrationFoldersSaving(newGroup);
      onClose();
    }
  };

  const validateForm = () => {
    if (isFetchingMembers) {
      return false;
    }

    if (!name) {
      setNameError('Name is required');
      return false;
    }

    return true;
  };

  const handleDeleteGroup = (e) => {
    e.preventDefault();
    setIsLoading(true);
    MConfirm('Delete Group?', 'Are you sure you want to delete this group?', 'warning', (confirmed) => {
      if (confirmed) {
        deleteGroup(group.id);
        setMembers([]);
        onClose();
      }
      setIsLoading(false);
    });
  };

  const suggestions = getSuggestions();
  let mainText;
  let secondaryButtonText;
  let secondaryButtonOnClick;
  let tertiaryButtonText;
  let tertiaryButtonOnClick;
  if (group) {
    mainText = 'Update Group';
    secondaryButtonText = 'Delete Group';
    secondaryButtonOnClick = handleDeleteGroup;
    tertiaryButtonText = 'Cancel';
    tertiaryButtonOnClick = onClose;
  } else {
    mainText = 'Add Group';
    secondaryButtonText = 'Cancel';
    secondaryButtonOnClick = onClose;
  }

  const toggleCustomFolderSettingsValue = (type) => {
    if (type === 'google') {
      setUseCustomFolder({ ...useCustomFolder, google: !useCustomFolder.google });
    }

    if (type === 'microsoft') {
      setUseCustomFolder({ ...useCustomFolder, microsoft: !useCustomFolder.microsoft });
    }
  };

  return (
    <Modal
      show={ui.modal?.name === 'groupModal'}
      onClose={onClose}
      title={mainText}
      showDefaultFooter={true}
      footerIsSticky={true}
      primaryButtonText={mainText}
      primaryButtonOnClick={onFormSubmit}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonOnClick={secondaryButtonOnClick}
      tertiaryButtonText={tertiaryButtonText}
      tertiaryButtonOnClick={tertiaryButtonOnClick}
      primaryButtonLoading={isFetchingMembers || isLoading}
    >
      <Form.Field>
        <Form.Label>Name</Form.Label>
        <Form.Control>
          <InputWithError error={nameError} type="text" value={name} onChange={onChange} name="name" />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Description</Form.Label>
        <Form.Control>
          <InputWithError type="text" value={description} onChange={onChange} name="description" />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Members</Form.Label>
        <Form.Control>
          {isFetchingMembers ? (
            <span>Loading...</span>
          ) : (
            <AvatarSelect
              options={suggestions}
              onChange={onMemberChange}
              isMulti={true}
              placeholder="Enter members for group"
              classNamePrefix="matik-select"
              value={members}
            />
          )}
        </Form.Control>
      </Form.Field>
      {customFolderFeatureFlagIsOn && (
        <>
          <Form.Field>
            <Form.Label>Custom Export Folder For This Group</Form.Label>
            <Form.Help>
              Matik will use this folder as the default location for the specified user group(s), unless more granular
              defaults are set.{' '}
              <a
                className="agree-to-terms underline"
                target="_blank"
                rel="noreferrer noopener"
                /* TODO: insert link to order of saving precedence when ready. ETA unknown */
                href="http://help.matik.io/"
              >
                Learn more.
              </a>
            </Form.Help>
            <Form.Control>
              <div className="flex align-middle my-2">
                <div className="flex align-middle w-11">
                  <ToggleSwitch
                    switchState={useCustomFolder.google}
                    size="m"
                    onClick={() => toggleCustomFolderSettingsValue('google')}
                  />
                </div>
                <div className="flex align-middle w-auto">
                  <div className="flex align-middle">Google Drive</div>
                </div>
              </div>
              {useCustomFolder.google && !isLoading && (
                <div className="flex w-full gap-x-3 mb-4">
                  <TextboxWithEmbeddedIcons
                    iconName={'google_drive_logo'}
                    readOnly={true}
                    textboxValue={'Google Drive'}
                  />
                  <TextboxWithEmbeddedIcons
                    readOnly={true}
                    textboxValue={currentIntegrationFolderNames.google}
                    rightIconClickHandler={() => handleRightIconClick('google')}
                    rightIcon={rightIcons.google}
                  />
                  <GoogleDrivePicker
                    className="hidden"
                    onPicked={(e) => onFolderSelect(e, 'google')}
                    view="folders"
                    permissionsMessage="In order to set a custom folder, you will need to select allow in the authentication window."
                    buttonRef={googleRef}
                  />
                </div>
              )}
              <div className="flex align-middle my-2">
                <div className="flex align-middle w-11">
                  <ToggleSwitch
                    switchState={useCustomFolder.microsoft}
                    size="m"
                    onClick={() => toggleCustomFolderSettingsValue('microsoft')}
                  />
                </div>
                <div className="flex align-middle w-auto">
                  <div className="flex align-middle">Office 365</div>
                </div>
              </div>
              {useCustomFolder.microsoft && !isLoading && (
                <div className="flex w-full gap-x-3 mb-4">
                  <TextboxWithEmbeddedIcons iconName={'office_365_logo'} readOnly={true} textboxValue={'Office 365'} />
                  <TextboxWithEmbeddedIcons
                    readOnly={true}
                    textboxValue={currentIntegrationFolderNames.microsoft}
                    rightIconClickHandler={() => handleRightIconClick('microsoft')}
                    rightIcon={rightIcons.microsoft}
                  />
                  <MicrosoftPicker
                    className="hidden"
                    onPicked={(e) => onFolderSelect(e, 'microsoft')}
                    pickerMode="folders"
                    buttonRef={microsoftRef}
                  />
                </div>
              )}
            </Form.Control>
          </Form.Field>
        </>
      )}
    </Modal>
  );
};

GroupModal.propTypes = {
  addGroup: PropTypes.func,
  allUsers: PropTypes.array,
  deleteGroup: PropTypes.func,
  group: PropTypes.object,
  isFetchingMembers: PropTypes.bool,
  onClose: PropTypes.func,
  members: PropTypes.array,
  show: PropTypes.bool,
  updateGroup: PropTypes.func,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(GroupModal);
