import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConditionGroup from '../../shared/ConditionGroup';
import TemplateConditionClause from './TemplateConditionClause';

class TemplateConditionGroup extends Component {
  render() {
    const clauses =
      !this.props.condition.clauses || this.props.condition.clauses.length === 0
        ? [this.props.generateEmptyClause()]
        : this.props.condition.clauses;

    const conditionIntro =
      this.props.templateType === 'email' ? (
        <span>
          <strong>{this.props.conditionIndex > 0 && 'OR'} SEND</strong>&nbsp;the email:
        </span>
      ) : ['document', 'presentation'].includes(this.props.templateType) ? (
        <span>
          <strong>{this.props.conditionIndex > 0 && 'OR'} GENERATE</strong>&nbsp;{`the ${this.props.templateType}`}:
        </span>
      ) : (
        <span>
          <strong>{this.props.conditionIndex > 0 && 'OR'} KEEP</strong>&nbsp;the slide:
        </span>
      );

    return (
      <ConditionGroup
        conditionIntro={conditionIntro}
        queryObj={this.props.conditions}
        conditionIndex={this.props.conditionIndex}
        updateQuery={this.props.onConditionsUpdate}
        deleteAllConditions={this.props.deleteAllConditions}
      >
        {clauses.map((clause, idx) => (
          <TemplateConditionClause
            key={`0_${idx}`}
            onConditionsUpdate={this.props.onConditionsUpdate}
            conditionIndex={this.props.conditionIndex}
            conditions={this.props.conditions}
            clauseIndex={idx}
            clause={clause}
            generateEmptyClause={this.props.generateEmptyClause}
          />
        ))}
      </ConditionGroup>
    );
  }
}

TemplateConditionGroup.propTypes = {
  condition: PropTypes.object,
  conditionIndex: PropTypes.number,
  deleteAllConditions: PropTypes.func,
  generateEmptyClause: PropTypes.func,
  conditions: PropTypes.object,
  onConditionsUpdate: PropTypes.func,
  templateType: PropTypes.string,
};

export default TemplateConditionGroup;
