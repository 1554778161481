import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import { Select } from '../../shared/FormSelect';
import { remove } from 'lodash';

class AnalyticsGroupSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      groups: [],
    };
  }

  componentDidMount() {
    API.get(
      '/groups/',
      (response) => {
        this.setState({ groups: response.data, isLoading: false });
      },
      API.defaultError,
    );
  }

  render() {
    return (
      <Select
        id="analytics-group-selector"
        className="analytics-dropdown"
        isMulti={true}
        isSearchable={true}
        onChange={this.onGroupSelect}
        name="group"
        value={this.props.selectedGroups}
        classNamePrefix="matik-select"
        options={this.state.groups.map((group) => ({ label: group.name, value: group.id }))}
        isLoading={this.state.isLoading}
        placeholder="Choose a Group"
      />
    );
  }

  onGroupSelect = (obj, action) => {
    const updatedGroups = this.props.selectedGroups.slice();
    if (action.action === 'select-option') {
      updatedGroups.push(action.option);
      this.props.updateQueryParams({ groups: updatedGroups });
    } else if (action.action === 'select-all-options') {
      updatedGroups.push(...action.option);
      this.props.updateQueryParams({ groups: updatedGroups });
    } else if (action.action === 'remove-value') {
      remove(updatedGroups, (el) => {
        return action.removedValue.value === el.value;
      });
      this.props.updateQueryParams({ groups: updatedGroups });
    } else if (action.action === 'clear') {
      this.props.updateQueryParams({ groups: [] });
    }
  };
}

AnalyticsGroupSelector.propTypes = {
  selectedGroups: PropTypes.array,
  updateQueryParams: PropTypes.func,
};

export default AnalyticsGroupSelector;
