import { isEmpty } from 'lodash';

const presentations = {
  getRegenerationTooltipMessage: (presentation, canRead) => {
    let regenerationTooltipMessage = '';
    if (!presentation.template || isEmpty(presentation.template) || presentation.template.deleted) {
      regenerationTooltipMessage = 'Template no longer exists, cannot regenerate';
    } else if (!canRead) {
      regenerationTooltipMessage = 'You do not have access to this presentation';
    }
    return regenerationTooltipMessage;
  },
};

export default presentations;
