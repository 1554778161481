import React from 'react';
import PropTypes from 'prop-types';
import { Container, Hero } from 'react-bulma-components';
import loading_icon from '../../images/loading.gif';
import EmptyStateBanner from './EmptyStateBanner';
import DefaultEmptyState from './DefaultEmptyState';
import Constants from '../Constants';
import Button from '../lib/Button';

function WithLoadingAndEmpty(Component) {
  const WithLoadingComponent = ({ isLoading, elements, emptyInfo, firstName, ...props }) => {
    const showBannerForPage = {
      templates: true,
      dynamic_content: true,
      inputs: true,
      data_sources: true,
      home: true,
    };

    const currentPage = props?.match?.params?.tab || 'home';
    const templatesFilter = props?.match?.params?.item || Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
    const isAdminView = props?.match?.path?.indexOf('create') === -1;

    let isBannerDisplayed = currentPage in showBannerForPage;
    if (currentPage === 'templates') {
      isBannerDisplayed = isAdminView && templatesFilter === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
    }
    if (currentPage === 'home') {
      isBannerDisplayed = isAdminView;
    }

    if (isLoading) {
      return (
        <Container className="is-fluid">
          <Hero className="is-fullheight-with-navbar">
            <Hero.Body>
              <Container className="has-text-centered">
                <img src={loading_icon} width="100rem" alt="loading" />
              </Container>
            </Hero.Body>
          </Hero>
        </Container>
      );
    }

    if (!isLoading && (!elements || elements.length === 0)) {
      let buttons = null;
      if (emptyInfo.buttonComponent) {
        buttons = emptyInfo.buttonComponent;
      } else if (emptyInfo.buttonText) {
        buttons = (
          <div className="btn-group">
            <Button onClick={emptyInfo.emptyOnClick} id="create-first">
              {emptyInfo.buttonText}
            </Button>
            {emptyInfo.secondaryButtonText && emptyInfo.secondaryButtonOnClick && (
              <Button category="secondary" onClick={emptyInfo.secondaryButtonOnClick}>
                {emptyInfo.secondaryButtonText}
              </Button>
            )}
          </div>
        );
      }

      return (
        <Container className="is-fluid pan">
          {isBannerDisplayed && <EmptyStateBanner buttons={buttons} bannerToLoad={currentPage} firstName={firstName} />}
          <props.emptyState {...props} isBannerDisplayed={isBannerDisplayed} buttons={buttons} emptyInfo={emptyInfo} />
        </Container>
      );
    }

    return <Component {...props} />;
  };

  WithLoadingComponent.propTypes = {
    isLoading: PropTypes.bool,
    elements: PropTypes.array,
    emptyInfo: PropTypes.object,
    entityType: PropTypes.string,
    onNewDataSourceAdd: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    firstName: PropTypes.string,
    emptyState: PropTypes.func,
  };

  WithLoadingComponent.defaultProps = {
    emptyState: DefaultEmptyState,
  };

  return WithLoadingComponent;
}

export default WithLoadingAndEmpty;
