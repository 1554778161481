import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';

function HtmlBuilderImageEmptyOrDcState({ dcName, selectImageType }) {
  return selectImageType === 'static' ? (
    <div className="html-builder-image-empty-state is-transparent" id="image_placeholder">
      <Icon name="image_copy" size={20} theme="regular" />
      <p className="image-empty-state-font bold">Static Image</p>
      <p className="image-empty-state-font">
        Select the Static Image that should appear here using the Image Customization panel.
      </p>
    </div>
  ) : dcName ? (
    <div id="image_placeholder" className="html-builder-image-empty-state dc">
      <Icon name="image_copy" size={20} theme="regular" />
      <p className="image-empty-state-font bold">Dynamic Image</p>
      <p className="image-empty-state-font">{`{{${dcName}}}`}</p>
    </div>
  ) : (
    <div id="image_placeholder" className="html-builder-image-empty-state">
      <Icon name="image_copy" size={20} theme="regular" />
      <p className="image-empty-state-font bold">Dynamic Image</p>
      <p className="image-empty-state-font">
        Select the Dynamic Content that should appear here using the Image Customization panel.
      </p>
    </div>
  );
}

HtmlBuilderImageEmptyOrDcState.propTypes = {
  dcName: PropTypes.string,
  selectImageType: PropTypes.string,
};

export default HtmlBuilderImageEmptyOrDcState;
