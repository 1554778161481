import React from 'react';
import PropTypes from 'prop-types';

function Indicator({ color }) {
  return <div className={`flex rounded-full bg-${color} min-w-1.5 w-1.5 h-1.5`}></div>;
}

Indicator.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Indicator;
