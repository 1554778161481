import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function VerticalTabItem({ isActive = false, iconName, label, onClick = () => {} }) {
  let statusClass = 'text-grey-600 bg-matik-white';
  let hoverClass = 'hover:bg-grey-50';
  if (isActive) {
    statusClass = 'text-matik-black bg-matik-white';
  }

  const borderClass = isActive ? 'border-matik-green' : 'border-transparent';

  const buttonOnClick = () => onClick(label || '');

  return (
    <button
      role="tab"
      aria-selected={isActive}
      className={`py-2 pl-4 ${hoverClass} ${statusClass}`}
      onClick={buttonOnClick}
      type="button"
    >
      <div className={`border-r ${borderClass} h-full items-center flex gap-2`}>
        {iconName && (
          <div className="flex">
            <Icon name={iconName} size={20} />
          </div>
        )}
        <span className="pr-4">{label || ''}</span>
      </div>
    </button>
  );
}

VerticalTabItem.propTypes = {
  isActive: PropTypes.bool,
  iconName: PropTypes.string,
  indicatorColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default VerticalTabItem;
