import React, { Component } from 'react';
import { Tile, Form, Message, Container } from 'react-bulma-components';
import QueryEditor from '../DynamicContentQueryEditor';
import PropTypes from 'prop-types';
import OutputTypeTabs from './OutputTypeTabs';
import Constants from '../../../Constants';
import { toast } from 'react-toastify';
import withUserContext from 'components/shared/WithUserContext';
import FileUpload from '../../../shared/fileUpload/FileUpload';
import UploadedFileBanner from '../../../shared/fileUpload/UploadedFileBanner';
import API from '../../../../lib/api';
import utils from '../../../../lib/utils';
import Button from '../../../lib/Button';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
class MatikContentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageURL: '',
      imageName: '',
      showDropzone: true,
    };
  }

  componentDidMount() {
    if (this.context.query) {
      this.setState({ showDropzone: false });
    }
  }

  render() {
    const mainTileSize =
      this.props.selectedInputName && this.props.inputsInQueryString[this.props.selectedInputName] ? 8 : 12;

    const helpText =
      this.context.dynamicContentType === Constants.DynamicContentTypes.RECIPIENTS ? (
        <span>
          Recipients Dynamic Content should consist of email addresses. If entering more than one email, separate each
          address with a comma.{' '}
          <a
            href="https://help.matik.io/hc/en-us/articles/19331802474779-Creating-Matik-Mail-Templates"
            target="_blank"
            rel="noreferrer"
            className="how-to-text link"
          >
            Learn more.
          </a>
        </span>
      ) : (
        ''
      );

    let formBody = '';
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.TEXT) {
      formBody = (
        <Tile kind="child" className="box is-paddingless is-shadowless query-grandparent" size={mainTileSize}>
          <Form.Field className="query-parent">
            <Form.Control className="flex-1">
              <QueryEditor
                mode="plaintext"
                inputs={this.context.existingInputs}
                inputsInQueryString={this.props.inputsInQueryString}
              />
              {this.context.queryStringError && <Form.Help color="danger">{this.context.queryStringError}</Form.Help>}
            </Form.Control>
          </Form.Field>
        </Tile>
      );
    } else if (this.context.dynamicContentType === Constants.DynamicContentTypes.IMAGE) {
      formBody = (
        <Tile kind="child" className="box is-paddingless is-shadowless query-grandparent" size={mainTileSize}>
          <Container className="has-text-centered pal" style={{ width: '100%' }}>
            {this.state.showDropzone ? (
              <div style={{ width: '100%' }}>
                <FileUpload
                  onSelectFile={this.onSelectFile}
                  buttonText="Upload an image to Matik"
                  inputAccept="image/*"
                />
                {this.context.query ? (
                  <UploadedFileBanner
                    onCancel={this.clearQueryString}
                    uploadedFileName={this.state.imageName}
                    uploadedIconSrc={this.context.query}
                  />
                ) : null}
                <Form.Field kind={'group'} className="mtl">
                  <Form.Control className="is-expanded">
                    <Form.Input
                      type="text"
                      name="image-url"
                      onChange={this.onImageURLChange}
                      value={this.state.imageURL}
                      id="image-url"
                      placeholder="Paste an image weblink here"
                      className="long-input"
                      aria-label="Dynamic content image url"
                    />
                  </Form.Control>
                  <Form.Control>
                    <Button type="button" category="secondary" onClick={this.onFetchImageClick}>
                      Fetch Image from URL
                    </Button>
                  </Form.Control>
                </Form.Field>
              </div>
            ) : (
              <div className="w-full ">
                <img src={this.context.query} alt="Static" style={{ width: '180px' }} />
                <p className="mt-2 flex justify-center">
                  <Button type="button" onClick={this.onChooseAnotherClick} category="secondary">
                    Choose another image
                  </Button>
                </p>
              </div>
            )}
          </Container>
        </Tile>
      );
    } else if (this.context.dynamicContentType === Constants.DynamicContentTypes.RECIPIENTS) {
      formBody = (
        <Tile kind="child" className="box is-paddingless is-shadowless query-grandparent" size={mainTileSize}>
          <Form.Field className="query-parent">
            <Form.Control className="flex-1">
              <QueryEditor
                mode="plaintext"
                inputs={this.context.existingInputs}
                inputsInQueryString={this.props.inputsInQueryString}
              />
              {this.context.queryStringError && <Form.Help color="danger">{this.context.queryStringError}</Form.Help>}
            </Form.Control>
          </Form.Field>
        </Tile>
      );
    } else {
      const message = Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[this.context.dynamicContentType].text;
      formBody = (
        <Message className="mtn mbl" color="message-info">
          <Message.Body>
            {message} is not supported for Static content. Please select a different Output Type.
          </Message.Body>
        </Message>
      );
    }

    let outputTypes = this.props.userContext?.user?.enterprise?.enterprise_settings?.matik_mail_enabled
      ? [
          Constants.DynamicContentTypes.TEXT,
          Constants.DynamicContentTypes.IMAGE,
          Constants.DynamicContentTypes.RECIPIENTS,
        ]
      : [Constants.DynamicContentTypes.TEXT, Constants.DynamicContentTypes.IMAGE];

    return (
      <>
        <Tile kind="parent" className="is-paddingless type-selector" vertical>
          <div className="header-wrapper py-2">
            <OutputTypeTabs
              outputTypes={outputTypes}
              isReadOnly={this.context.isReadOnly}
              selectedOutputType={this.context.dynamicContentType}
              onOutputTypeSelect={this.props.onOutputTypeSelect}
            />
          </div>
          {formBody}
        </Tile>
        {helpText && <Form.Help className="my-2">{helpText}</Form.Help>}
      </>
    );
  }

  onImageURLChange = (e) => {
    const url = e.target.value || '';
    this.setState({ imageURL: url });
  };

  onFetchImageClick = async () => {
    API.request(
      'post',
      '/dynamic_content/fetch_image/',
      { image_url: this.state.imageURL },
      (url_response) => {
        this.onSelectFile([url_response.data]);
      },
      () => {
        if (!this.state.imageURL.indexOf('http://') == 0 && !this.state.imageURL.indexOf('https://') == 0) {
          return utils.notify('Invalid URL. URL must start with http:// or https://', toast.TYPE.ERROR);
        } else if (
          this.state.imageURL.includes('127.0.0.1') ||
          this.state.imageURL.includes('//client') ||
          this.state.imageURL.includes('//localhost')
        ) {
          return utils.notify('Invalid URL. URL Cannot be LocalHost', toast.TYPE.ERROR);
        } else {
          return utils.notify('Invalid URL', toast.TYPE.ERROR);
        }
      },
      'blob',
    );
  };

  onChooseAnotherClick = () => {
    this.setState({ showDropzone: true });
  };

  clearQueryString = () => {
    this.context.onQueryStringUpdate(null);
  };

  onSelectFile = (acceptedFiles) => {
    let file = acceptedFiles?.[0];
    if (!file) {
      return toast.error('Invalid File Type');
    }
    // read the file contents to render a preview image
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (readerEvent) => {
      const content = readerEvent.target.result;
      this.context.onQueryStringUpdate(content);
      this.setState({ imageURL: '' });
    };
    this.setState({ imageName: file.name });
  };
}

MatikContentForm.contextType = DynamicContentContext;

MatikContentForm.propTypes = {
  inputsInQueryString: PropTypes.object,
  selectedInputName: PropTypes.string,
  onOutputTypeSelect: PropTypes.func,
  userContext: PropTypes.object,
};

export default withUserContext(MatikContentForm);
