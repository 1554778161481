import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropdownMenu extends Component {
  constructor(props) {
    super(props);

    this.htmlElement = null;
  }

  componentDidMount() {
    if (!this.props.isHoverable) {
      document.addEventListener('mouseup', this.close);
    }
  }

  componentWillUnmount() {
    if (!this.props.isHoverable) {
      document.removeEventListener('mouseup', this.close);
    }
  }

  render() {
    const hoverableClassName = this.props.isHoverable ? 'is-hoverable' : '';
    const activeClassName = this.props.active ? 'is-active' : '';
    const passedClassName = this.props.className ? this.props.className : '';
    const orientationClass = this.props.isRight ? 'is-right' : '';
    const upDownClass = this.props.isUp ? 'is-up' : '';
    return (
      <div
        className={`dropdown ${orientationClass} ${upDownClass} ${hoverableClassName} ${activeClassName} ${passedClassName}`}
        ref={(elem) => (this.htmlElement = elem)}
      >
        <div className="dropdown-trigger">{this.props.dropdownTrigger}</div>
        <div className="dropdown-menu" role="menu">
          <div className="dropdown-content">{this.props.children}</div>
        </div>
      </div>
    );
  }

  close = (e) => {
    if (this.props.isHoverable || (this.htmlElement && this.htmlElement.contains(e.target))) {
      return;
    }
    if (this.props.active) {
      this.props.onClose(e);
    }
  };
}

DropdownMenu.propTypes = {
  isHoverable: PropTypes.bool,
  active: PropTypes.bool,
  dropdownTrigger: PropTypes.any,
  children: PropTypes.any,
  onClose: PropTypes.func,
  className: PropTypes.string,
  isRight: PropTypes.bool,
  isUp: PropTypes.bool,
};

export default DropdownMenu;
