import React from 'react';
import PropTypes from 'prop-types';

function FontDropdown({ currentState, expanded, onChange, onExpandEvent }) {
  const currentFontFamily = currentState?.fontFamily;

  const onSelectFont = (fontFamily, e) => {
    e.stopPropagation();
    onChange(fontFamily);
  };

  const fontFamilyOptions = [
    { label: 'American Typewriter', value: 'american typewriter' },
    { label: 'Andale Mono', value: 'andale mono' },
    { label: 'Arial', value: 'arial' },
    { label: 'Arial Black', value: 'arial black' },
    { label: 'Arial Narrow', value: 'arial narrow' },
    { label: 'Arial Rounded MT Bold', value: 'arial rounded mt bold' },
    { label: 'Baskerville', value: 'baskerville' },
    { label: 'Big Caslon', value: 'big caslon' },
    { label: 'Bradley Hand', value: 'bradley hand' },
    { label: 'Brush Script MT', value: 'brush script mt' },
    { label: 'Comic Sans MS', value: 'comic sans ms' },
    { label: 'Copperplate', value: 'copperplate' },
    { label: 'Courier', value: 'courier' },
    { label: 'Courier New', value: 'courier new' },
    { label: 'Cursive', value: 'cursive' },
    { label: 'Didot', value: 'didot' },
    { label: 'Fantasy', value: 'fantasy' },
    { label: 'Futura', value: 'futura' },
    { label: 'Geneva', value: 'geneva' },
    { label: 'Georgia', value: 'georgia' },
    { label: 'Gill Sans', value: 'gill sans' },
    { label: 'Helvetica', value: 'helvetica' },
    { label: 'Hoefler Text', value: 'hoefler text' },
    { label: 'Impact', value: 'impact' },
    { label: 'Lucida Grande', value: 'Lucida Grande' },
    { label: 'Luminari', value: 'luminari' },
    { label: 'Monaco', value: 'Monaco' },
    { label: 'Monospace', value: 'monospace' },
    { label: 'Palatino', value: 'palatino' },
    { label: 'Papyrus', value: 'papyrus' },
    { label: 'Perpetua', value: 'perpetua' },
    { label: 'Rockwell', value: 'rockwell' },
    { label: 'Sans Serif', value: 'sans-serif' },
    { label: 'Tahoma', value: 'tahoma' },
    { label: 'Times', value: 'times' },
    { label: 'Times New Roman', value: 'times new roman' },
    { label: 'Trebuchet MS', value: 'trebuchet ms' },
    { label: 'Trattatello', value: 'trattatello' },
    { label: 'Verdana', value: 'verdana' },
  ];

  const font_label_style = {
    fontFamily: currentFontFamily,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '120px',
  };

  return (
    <div
      aria-expanded={expanded}
      aria-haspopup="true"
      aria-label="rdw-font-family-dropdown"
      className="rich-text-component font-family"
      onClick={onExpandEvent}
    >
      <div style={font_label_style}>{currentFontFamily ? currentFontFamily : 'Font'}</div>
      <div className={expanded ? 'dropdown-carettoclose' : 'dropdown-carettoopen'} />
      {expanded && (
        <div className="rich-text-component-dropdown-container">
          {fontFamilyOptions.map((fontOption) => (
            <div
              className={`rich-text-component font-dropdown ${
                fontOption.label === currentFontFamily ? 'active-font' : ''
              }`}
              key={fontOption.value}
              onClick={(e) => onSelectFont(fontOption.label, e)}
              style={{ fontFamily: fontOption.value }}
            >
              {fontOption.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

FontDropdown.propTypes = {
  currentState: PropTypes.object,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  onExpandEvent: PropTypes.func,
};

export default FontDropdown;
