import React from 'react';
import PropTypes from 'prop-types';

function TabBar({ children }) {
  return (
    <div
      role="tablist"
      className="border border-grey-300 rounded-md self-start flex flex-col h-fit w-fit overflow-hidden"
    >
      {children}
    </div>
  );
}

TabBar.propTypes = {
  children: PropTypes.node,
};

export default TabBar;
