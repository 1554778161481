import React, { useState, useEffect, useRef } from 'react';
import { Card, Heading, Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import ToggleSwitch from 'components/lib/ToggleSwitch';
// TODO (low priority): refactor FolderPicker to use the newer GoogleDrivePicker component
import FolderPicker from './FolderPicker';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';

function CustomFolderSettings({
  folder,
  enabled,
  o365FolderEnabled, // true if folder is enabled from admin enterprise settings, false otherwise
  oauthFunction,
  handleSelectGoogleSheet,
  auth,
  title,
  onFolderSettingsChange,
  customOffice365Folder,
}) {
  const [useCustomGoogleDriveFolder, setUseCustomGoogleDriveFolder] = useState(enabled);
  const [useCustomOffice365Folder, setUseCustomOffice365Folder] = useState(o365FolderEnabled);
  const isInitialMount = useRef(true);

  const toggleCustomFolderSettingsValue = (type) => {
    if (type === 'googleDrive') {
      setUseCustomGoogleDriveFolder(!useCustomGoogleDriveFolder);
    }

    if (type === 'office365') {
      setUseCustomOffice365Folder(!useCustomOffice365Folder);
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onFolderSettingsChange({
        enable_presentation_folder_select: useCustomGoogleDriveFolder,
        enable_office_365_presentation_folder_select: useCustomOffice365Folder,
        custom_office_365_presentation_folder: customOffice365Folder,
      });
    }
  }, [useCustomGoogleDriveFolder, useCustomOffice365Folder]);

  const handle365SelectFolder = ({ items }) => {
    onFolderSettingsChange({
      enable_presentation_folder_select: useCustomGoogleDriveFolder,
      enable_office_365_presentation_folder_select: useCustomOffice365Folder,
      custom_office_365_presentation_folder: items[0],
    });
  };

  return (
    <Card className="is-shadowless mbl enterprise-settings-card">
      <Card.Header className="pal is-shadowless">
        <Card.Header.Title className="p-0">
          <Heading size={5} className="break-after-all">
            Custom Folder for Generated Presentations
            <Form.Help>
              Matik will use this folder as the default save location, unless more granular defaults are set.{' '}
              <a
                className="agree-to-terms underline"
                target="_blank"
                rel="noreferrer noopener"
                /* TODO: insert link to order of saving precedence when ready. ETA unknown */
                href="http://help.matik.io/"
              >
                Learn more.
              </a>
            </Form.Help>
          </Heading>
        </Card.Header.Title>
      </Card.Header>
      <Card.Content className="space-y-2">
        {/* Google Drive settings */}
        <div className="flex align-middle">
          <div className="flex align-middle w-11">
            <ToggleSwitch
              switchState={useCustomGoogleDriveFolder}
              size="m"
              onClick={() => toggleCustomFolderSettingsValue('googleDrive')}
            />
          </div>
          <div className="flex align-middle w-auto">
            <div className="flex align-middle">Google Drive</div>
          </div>
        </div>
        {useCustomGoogleDriveFolder && (
          <>
            <div className="flex align-middle text-grey-500">
              <div className="flex w-11" />
              <div className="flex align-middle">
                Select a Google Drive folder for your generated Google Slide presentations
              </div>
            </div>
            <div className="flex align-middle">
              <div className="flex w-11" />
              <FolderPicker
                folderProvider="googleDrive"
                folder={folder}
                enabled={useCustomGoogleDriveFolder}
                oauthFunction={oauthFunction}
                handleSelectGoogleSheet={handleSelectGoogleSheet}
                auth={auth}
                title={title}
              />
            </div>
          </>
        )}

        <>
          <div className="flex align-middle mt-4">
            <div className="flex align-middle w-11">
              <ToggleSwitch
                switchState={useCustomOffice365Folder}
                size="m"
                onClick={() => toggleCustomFolderSettingsValue('office365')}
              />
            </div>
            <div className="flex align-middle w-auto">
              <div className="flex align-middle">Office 365</div>
            </div>
          </div>
          {useCustomOffice365Folder && (
            <>
              <div className="flex align-middle text-grey-500">
                <div className="flex w-11" />
                <div className="flex align-middle">
                  Select an Office 365 folder for your generated PowerPoint presentations
                </div>
              </div>
              <div className="flex align-middle justify-between w-full h-10">
                <div className="flex w-11" />
                <div className="flex align-middle w-5/6 mr-4">
                  <div className="border border-grey-300 w-full flex items-center py-2 px-4 rounded">
                    {customOffice365Folder?.name}
                  </div>
                </div>
                <MicrosoftPicker className={'mrm'} onPicked={handle365SelectFolder} pickerMode="folders" />
                <div className="-mr-4" />
              </div>
            </>
          )}
        </>
      </Card.Content>
    </Card>
  );
}

CustomFolderSettings.propTypes = {
  folder: PropTypes.object,
  enabled: PropTypes.bool,
  oauthFunction: PropTypes.func,
  handleSelectGoogleSheet: PropTypes.func,
  auth: PropTypes.object,
  title: PropTypes.string,
  onFolderSettingsChange: PropTypes.func,
  isLoading: PropTypes.bool,
  customOffice365Folder: PropTypes.object,
  o365FolderEnabled: PropTypes.bool,
};

export default CustomFolderSettings;
