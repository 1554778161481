import {
  REQUEST_API_INFO,
  RECEIVE_API_INFO,
  REQUEST_REPORT_METADATA,
  RECEIVE_REPORT_METADATA,
  RECEIVE_REPORT_COUNT,
  RECEIVE_OBJECT_COUNT,
  IS_FETCHING,
  IS_NOT_FETCHING,
} from './actionTypes';
import API from '../../lib/api';
import Constants from 'components/Constants';

const requestCache = {};

function fetchApiInfo(dataSourceId, resourceName, resourceId) {
  return (dispatch) => {
    dispatch(requestApiInfo(dataSourceId));
    return API.get(
      _buildFetchUrl(dataSourceId, resourceName, resourceId),
      (result) => {
        dispatch(receiveApiInfo(dataSourceId, result, resourceName, resourceId));
      },
      (err) => {
        dispatch(receiveApiError(err, dataSourceId, resourceName, resourceId));
        API.defaultError(err);
      },
    );
  };
}

function _buildFetchUrl(dataSourceId, resourceName, resourceId) {
  let url = `/data_sources/${dataSourceId}/info/`;
  if (resourceName) {
    url += `?resource_name=${resourceName}`;
    if (resourceId) {
      url += `&resource_id=${resourceId}`;
    }
  }

  return url;
}

function requestApiInfo(dataSourceId) {
  return {
    type: REQUEST_API_INFO,
    dataSourceId,
  };
}

function receiveApiInfo(dataSourceId, result, resourceName, resourceId) {
  return {
    type: RECEIVE_API_INFO,
    dataSourceId,
    resourceName,
    resourceId,
    apiInfo: result.data,
    receivedAt: Date.now(),
  };
}

function receiveApiError(err, dataSourceId, resourceName, resourceId) {
  return {
    type: RECEIVE_API_INFO,
    err,
    dataSourceId,
    resourceName,
    resourceId,
    apiInfo: {},
    receivedAt: Date.now(),
  };
}

function shouldFetchApiInfo(state, dataSourceId, resourceName, resourceId, subResourceName) {
  let apiInfo = state.apiInfoByDataSourceId[dataSourceId];
  if (apiInfo && apiInfo['info'] && resourceName) {
    apiInfo = apiInfo['info'][resourceName];
    if (apiInfo && resourceId) {
      apiInfo = apiInfo[resourceId];
      if (apiInfo && subResourceName) {
        apiInfo = apiInfo[subResourceName];
      }
    }
  }
  // check to make sure the resourceID api info object is empty before fetching
  if (
    !apiInfo ||
    (resourceId && Object.keys(apiInfo).length === 0) ||
    Date.now() - apiInfo.lastUpdated > 1000 * 60 * 10
  ) {
    return true;
  } else if (apiInfo.isFetching) {
    return false;
  } else {
    return apiInfo.didInvalidate;
  }
}

export function fetchApiInfoIfNeeded(dataSourceId, resourceName = null, resourceId = null, subResourceName = null) {
  return (dispatch, getState) => {
    // there are edge cases where a component will endlessly rerender, repeating identical network requests
    // to prevent this, we will cache the request and return the same promise if it is already in progress
    const requestKey = `${dataSourceId}-${resourceName}-${resourceId}-${subResourceName}`;

    if (requestCache[requestKey]) {
      return requestCache[requestKey];
    }

    if (shouldFetchApiInfo(getState(), dataSourceId, resourceName, resourceId, subResourceName)) {
      requestCache[requestKey] = dispatch(fetchApiInfo(dataSourceId, resourceName, resourceId, subResourceName))
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          delete requestCache[requestKey];
        });

      return requestCache[requestKey];
    }

    return Promise.resolve();
  };
}

export function updateApiInfoWithObject(dataSourceId, obj) {
  return (dispatch) => {
    if (obj) {
      return dispatch(receiveApiInfo(dataSourceId, obj, 'objects', null));
    }
  };
}

export function fetchReportCountFromApi(dataSourceId) {
  return (dispatch) => {
    if (dataSourceId) {
      return dispatch(fetchReportCount(dataSourceId));
    }

    return Promise.resolve();
  };
}

export function fetchObjectCountFromApi(dataSourceId) {
  return (dispatch) => {
    if (dataSourceId) {
      return dispatch(fetchObjectCount(dataSourceId));
    }

    return Promise.resolve();
  };
}

export function refreshSalesforceReportMetadata(dataSourceId, reportId, reportName) {
  return (dispatch) => {
    if (dataSourceId && reportId && reportName) {
      return dispatch(fetchReportMetadata(dataSourceId, reportId, reportName));
    }

    return Promise.resolve();
  };
}

export function setIsFetching(dataSourceId, isFetching) {
  return (dispatch) => {
    if (dataSourceId) {
      if (isFetching) {
        return dispatch(setIsFetchingTrue(dataSourceId));
      } else {
        return dispatch(setIsFetchingFalse(dataSourceId));
      }
    }
  };
}

function setIsFetchingTrue(dataSourceId) {
  return {
    type: IS_FETCHING,
    dataSourceId,
  };
}

function setIsFetchingFalse(dataSourceId) {
  return {
    type: IS_NOT_FETCHING,
    dataSourceId,
  };
}

function fetchReportMetadata(dataSourceId, reportId, reportName) {
  return (dispatch) => {
    dispatch(requestReportMetadata(dataSourceId, reportId, reportName));
    return API.get(
      `/data_sources/${dataSourceId}/metadata/${reportId}/`,
      (result) => {
        dispatch(receiveReportMetadata(dataSourceId, reportId, reportName, result));
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };
}

function requestReportMetadata(dataSourceId, reportId, reportName) {
  return {
    type: REQUEST_REPORT_METADATA,
    dataSourceId,
    reportId,
    reportName,
  };
}

function receiveReportMetadata(dataSourceId, reportId, reportName, result) {
  return {
    type: RECEIVE_REPORT_METADATA,
    dataSourceId,
    reportId,
    reportName,
    apiInfo: result.data,
    receivedAt: Date.now(),
  };
}

function fetchReportCount(dataSourceId) {
  return (dispatch) => {
    return API.get(
      `/data_sources/${dataSourceId}/count/Report/`,
      (result) => {
        dispatch(receiveReportCount(dataSourceId, result));
        if (result.data <= Constants.MAX_SALESFORCE_REPORTS) {
          dispatch(fetchApiInfoIfNeeded(dataSourceId, 'reports'));
        } else {
          dispatch(setIsFetching(dataSourceId, false));
        }
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };
}

function fetchObjectCount(dataSourceId) {
  return (dispatch) => {
    return API.get(
      `/data_sources/${dataSourceId}/count/Object/`,
      (result) => {
        dispatch(receiveObjectCount(dataSourceId, result));
        if (result.data <= Constants.MAX_SALESFORCE_REPORTS) {
          dispatch(fetchApiInfoIfNeeded(dataSourceId, 'objects'));
        } else {
          dispatch(setIsFetching(dataSourceId, false));
        }
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };
}

function receiveReportCount(dataSourceId, result) {
  return {
    type: RECEIVE_REPORT_COUNT,
    dataSourceId,
    count: result.data,
  };
}

function receiveObjectCount(dataSourceId, result) {
  return {
    type: RECEIVE_OBJECT_COUNT,
    dataSourceId,
    count: result.data,
  };
}
