import React, { Component } from 'react';
import PropTypes from 'prop-types';
import html_visual_editor from 'images/html_visual_editor.svg';

class EditorVisualCard extends Component {
  render() {
    return (
      <div className="narrative-card">
        <img src={html_visual_editor} className="narrative-card-icon" />
        <div className="narrative-card-center">
          <p className="narrative-card-title">Visual Builder</p>
          <p className="narrative-card-subtitle">
            Design your email template using a drag-and-drop visual editor. No HTML coding required
          </p>
        </div>
        <button className="button is-secondary is-fullwidth" onClick={this.props.onEditorVisualClick}>
          Use Visual Builder
        </button>
      </div>
    );
  }
}

EditorVisualCard.propTypes = {
  onEditorVisualClick: PropTypes.func,
  user: PropTypes.object,
};

export default EditorVisualCard;
