import React from 'react';
import PropTypes from 'prop-types';
import CRMForm from './CRMForm';

function ApiFormWithQuery({
  apiInfo,
  dataSource,
  fetchApiInfoIfNeeded,
  formRef,
  inputsInQueryString,
  refreshReport,
  updateTestResult,
  reportCount,
  objectCount,
  queryObj,
}) {
  return (
    <CRMForm
      apiInfo={apiInfo}
      dataSource={dataSource}
      fetchApiInfoIfNeeded={fetchApiInfoIfNeeded}
      formRef={formRef}
      inputsInQueryString={inputsInQueryString}
      queryObj={queryObj}
      refreshReport={refreshReport}
      updateTestResult={updateTestResult}
      reportCount={reportCount}
      objectCount={objectCount}
    />
  );
}

ApiFormWithQuery.propTypes = {
  apiInfo: PropTypes.object,
  inputsInQueryString: PropTypes.object,
  updateTestResult: PropTypes.func,
  dataSource: PropTypes.object,
  refreshReport: PropTypes.func,
  fetchApiInfoIfNeeded: PropTypes.func,
  formRef: PropTypes.object,
  reportCount: PropTypes.number,
  objectCount: PropTypes.number,
  queryObj: PropTypes.object,
};

export default ApiFormWithQuery;
