import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Footer, Heading } from 'react-bulma-components';
import SidepaneListItem from './SidepaneListItem';
import { ReactComponent as ArrowSync } from '../../../images/arrow_sync.svg';
import { ReactComponent as XShape } from '../../../images/x_shape.svg';
import { closeSidepane } from 'redux/ui/action';
import Button from '../../lib/Button';
import ButtonGroup from '../../lib/ButtonGroup';
import { useTemplateLinkedObjectStatus } from 'lib/hooks/useTemplate';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function SidepaneList({ template }) {
  const { updateDataSourceError } = useFlags();

  const { data: linkedObjectStatus, invalidate: forceLinkedObjectCheck } = useTemplateLinkedObjectStatus(
    template,
    updateDataSourceError,
  );

  const loading = false;
  const dispatch = useDispatch();

  const matikTemplateLinkedObjects = linkedObjectStatus?.linked_objects || [];
  const reqAllPerms = () => {
    matikTemplateLinkedObjects.forEach((element) => {
      if (element.status_code !== 200) {
        let url = `https://docs.google.com/${element.type}/d/${element.id}/edit`;
        window.open(url, `${element.type} - ${element.id}`);
      }
    });
  };
  return (
    <div className="checker-sidepane">
      <div className="sidepane-header bottom-border">
        <div className="sidepane-header-inner">
          <Heading>Permission Checker</Heading>
          <div className="sidepane-header-buttons">
            <Button
              category="tertiary"
              onClick={(e) => {
                e.preventDefault();
                forceLinkedObjectCheck();
              }}
            >
              <span>
                <ArrowSync />
              </span>
            </Button>
            <Button
              category="tertiary"
              onClick={(e) => {
                e.preventDefault();
                dispatch(closeSidepane());
              }}
            >
              <span>
                <XShape />
              </span>
            </Button>
          </div>
        </div>
      </div>
      <ul className="sidepane-list">
        {matikTemplateLinkedObjects.map((obj) => (
          <SidepaneListItem key={obj.id} {...obj} />
        ))}
      </ul>
      <Footer>
        <ButtonGroup align="right">
          <Button
            category="secondary"
            status={loading ? 'loading' : 'default'}
            onClick={(e) => {
              e.preventDefault();
              dispatch(closeSidepane());
            }}
          >
            Cancel
          </Button>
          <Button
            status={loading ? 'loading' : 'default'}
            onClick={(e) => {
              e.preventDefault();
              reqAllPerms();
            }}
          >
            Request All
          </Button>
        </ButtonGroup>
      </Footer>
    </div>
  );
}

SidepaneList.propTypes = {
  template: PropTypes.object,
};
