import React from 'react';
import PropType from 'prop-types';
import { Form } from 'react-bulma-components';
import trash_icon from '../../../../images/trash.svg';
import { ReactComponent as Plus } from '../../../../svg/plus.svg';
import Button from '../../../lib/Button';
import Constants from '../../../Constants';

function SalesforceFilterButtons(props) {
  return (
    <Form.Field style={{ flex: '.5 1' }} kind="group">
      {props.queryObj.filters && props.queryObj.filters.length !== 0 && (
        <Form.Control>
          <Button
            type="button"
            onClick={(e) => props.removeFilter(e, props.idx, props.queryObj)}
            category="secondary"
            aria-label="Remove Filter"
            size="small"
            status={props.isReadOnly ? 'disabled' : 'default'}
          >
            <span className="icon">
              <img src={trash_icon} alt="Remove Filter" />
            </span>
          </Button>
        </Form.Control>
      )}
      {(!props.queryObj.filters ||
        props.idx === props.queryObj.filters.length - 1 ||
        props.queryObj.filters.length === 0) &&
        !(
          props?.dataSource?.type === Constants.DATA_SOURCE_TYPES.hubspot && props.queryObj.selectedObject === 'owners'
        ) && (
          <Form.Control>
            <Button
              type="button"
              onClick={(e) =>
                props.addFilter(
                  e,
                  props.queryObj,
                  props?.dataSource?.type === Constants.DATA_SOURCE_TYPES.hubspot ? 'equal' : '=',
                )
              }
              category="secondary"
              aria-label="Add Filter"
              size="small"
              status={props.isReadOnly ? 'disable' : 'default'}
            >
              <span className="icon">
                <Plus />
              </span>
            </Button>
          </Form.Control>
        )}
    </Form.Field>
  );
}

SalesforceFilterButtons.propTypes = {
  queryObj: PropType.object,
  idx: PropType.number,
  isReadOnly: PropType.bool,
  removeFilter: PropType.func,
  addFilter: PropType.func,
  dataSource: PropType.object,
};

export default SalesforceFilterButtons;
