import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bulma-components';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import API from '../../../lib/api';
import { ReactComponent as Gear } from '../../../svg/gear_icon.svg';
import ReactSelect from 'react-select';
import Button from '../../lib/Button';

function AboutMe() {
  const history = useHistory();
  const [intent, setIntent] = useState('');
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [size, setSize] = useState('');
  const [team, setTeam] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);

  const summitDate = moment('08-24-2023', 'MM-DD-YYYY');
  const isSummitDate = summitDate.isSame(moment(), 'day');
  useEffect(() => {
    if (isSummitDate) {
      setIntent('summitAttendee');
    }
  }, []);

  useEffect(() => {
    if (intent !== '' && title !== '' && company !== '' && size !== '' && team !== '') {
      setDisableSubmit(false);
    }
  });

  const handleSubmit = (e) => {
    const payload = {
      intent: intent,
      title: title,
      company: company,
      size: size,
      team: team,
    };

    e.preventDefault();
    API.post(
      '/about_me/',
      payload,
      () => {
        API.track('user_info_submit');
        history.push('/welcome');
      },
      submitError,
    );
  };

  const submitError = () => {
    history.push('/welcome');
  };

  const intentOptions = [
    { value: 'looking', label: 'Just looking around' },
    { value: 'automate', label: 'Automate an existing presentation or one-pager' },
    { value: 'create', label: 'Create a new presentation or one-pager' },
  ];

  const sizeOptions = [
    { value: 'me', label: 'Only me' },
    { value: '2-50', label: '2-50' },
    { value: '51-200', label: '51-200' },
    { value: '201-1000', label: '201-1,000' },
    { value: '1001-5000', label: '1,001-5,000' },
    { value: '5000+', label: '5,000+' },
  ];

  const teamOptions = [
    { value: 'customer_success', label: 'Customer Success' },
    { value: 'sales', label: 'Sales' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'operations', label: 'Operations' },
    { value: 'finance', label: 'Finance' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <div className="about-me-survey">
      <Link to="/welcome" className="signup-logo">
        <img src="/images/matik_logo_black.svg" alt="matik logo" height="40px" />
      </Link>
      <Card className="signup-survey-card">
        <Gear className="about-me-header-icon" />
        <h1 className="signup-survey-title ptm">Set up your team</h1>
        <h2 className="signup-survey-subtitle">
          This will help us guide you through Matik & personalize your experience.
        </h2>
        <div className="survey-inputs-container">
          {!isSummitDate && (
            <Form.Field>
              <Form.Control>
                <Form.Label className="survey-label mtl">What would you like to do?</Form.Label>
                <ReactSelect
                  classNamePrefix="matik-select"
                  className="survey-select fs-unmask"
                  options={intentOptions}
                  value={size.value}
                  onChange={(e) => setIntent(e.value)}
                  placeholder="Select..."
                  menuPosition="fixed"
                  hideSelectedOptions={true}
                />
              </Form.Control>
            </Form.Field>
          )}
          <Form.Field>
            <Form.Control>
              <Form.Label className="survey-label mtl">Job title</Form.Label>
              <Form.Input
                placeholder="Enter your job title"
                type="text"
                className="survey-input fs-unmask"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                blurInputOnSelect={false}
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Form.Label className="survey-label mtl">Company name</Form.Label>
              <Form.Input
                placeholder="Enter your company's name"
                type="text"
                className="survey-input fs-unmask"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Form.Label className="survey-label mtl">Company size</Form.Label>
              <ReactSelect
                classNamePrefix="matik-select"
                className="survey-select fs-unmask"
                options={sizeOptions}
                value={size.value}
                onChange={(e) => setSize(e.value)}
                placeholder="Select your company size"
                menuPosition="fixed"
                hideSelectedOptions={true}
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Form.Label className="survey-label mtl">Team function</Form.Label>
              <ReactSelect
                classNamePrefix="matik-select"
                className="survey-select fs-unmask"
                options={teamOptions}
                placeholder="Select your team's function"
                onChange={(e) => setTeam(e.value)}
                value={team.value}
                menuPosition="fixed"
                hideSelectedOptions={true}
              />
            </Form.Control>
          </Form.Field>
          <div className="mx-14 mt-6">
            <Button onClick={handleSubmit} status={disableSubmit ? 'disabled' : 'default'} width="fullWidth">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AboutMe;
