import React, { useEffect, useState } from 'react';
import { Heading } from 'react-bulma-components';
import { capitalize, map } from 'lodash';
import API from '../../../lib/api';
import utils from '../../../lib/utils';
import CheckboxWithLabel from '../CheckboxWithLabel';
import SmallLoader from '../SmallLoader';
import { EVENT_TYPE_NAMES } from './Constants';

const CHANNELS = { email: 'EMAIL' };

const NotificationPreferences = () => {
  const [preferences, setPreferences] = useState(null);

  useEffect(() => {
    API.get('/notifications/preferences/', (response) => setPreferences(response.data), API.defaultError);
  }, []);

  const onChange = (channelEvent, preference) => {
    const [channel, eventType] = channelEvent.split('-');
    const updatedPreferences = Object.assign({}, preferences);
    updatedPreferences[channel][eventType] = preference;
    API.put(
      '/notifications/preferences/',
      updatedPreferences,
      () => {
        utils.notify('Notification preferences updated');
        setPreferences(updatedPreferences);
      },
      API.defaultError,
    );
  };

  return (
    <React.Fragment>
      {preferences ? (
        map(CHANNELS, (channel_value, channel_key) => (
          <div key={channel_key} className="notification-preferences">
            <Heading size={5} className="pbm mbm light-bottom-border">
              {`${capitalize(channel_key)} Notification`}
            </Heading>
            {map(EVENT_TYPE_NAMES, (event_value, event_key) => {
              if (preferences[channel_value] && preferences[channel_value][event_value] !== null) {
                if (event_value === EVENT_TYPE_NAMES.all) {
                  return (
                    <CheckboxWithLabel
                      key={`${channel_key}-${event_key}`}
                      id={`${channel_key}-${event_key}`}
                      name={`${channel_value}-${event_value}`}
                      label={`Allow ${event_key} ${channel_key} notifications`}
                      checked={preferences[channel_value][event_value]}
                      onChange={(e) => onChange(e.target.name, e.target.checked)}
                    />
                  );
                } else {
                  return (
                    <CheckboxWithLabel
                      key={`${channel_key}-${event_key}`}
                      id={`${channel_key}-${event_key}`}
                      name={`${channel_value}-${event_value}`}
                      label={`${capitalize(event_value.replace('_', ' '))}`}
                      checked={preferences[channel_value][event_value]}
                      disabled={!preferences[channel_value][EVENT_TYPE_NAMES.all]}
                      onChange={(e) => onChange(e.target.name, e.target.checked)}
                    />
                  );
                }
              }
            })}
          </div>
        ))
      ) : (
        <SmallLoader />
      )}
    </React.Fragment>
  );
};

export default NotificationPreferences;
