const REQUEST_ANALYTICS_USERS = 'REQUEST_ANALYTICS_USERS';
const RECEIVE_ANALYTICS_USERS = 'RECEIVE_ANALYTICS_USERS';
const REQUEST_USERS = 'REQUEST_USERS';
const RECEIVE_USERS = 'RECEIVE_USERS';
const REQUEST_ANALYTICS_USER = 'REQUEST_ANALYTICS_USER';
const RECEIVE_ANALYTICS_USER = 'RECEIVE_ANALYTICS_USER';
const REQUEST_USER = 'REQUEST_USER';
const RECEIVE_USER = 'RECEIVE_USER';
const CLEAR_SELECTED_ENTERPRISE_USER = 'CLEAR_SELECTED_ENTERPRISE_USER';
const ADD_USER = 'ADD_USER';
const DELETE_USER = 'DELETE_USER';
const UPDATE_USER = 'UPDATE_USER';
const START_WORK = 'START_WORK';
const END_WORK = 'END_WORK';

export {
  ADD_USER,
  CLEAR_SELECTED_ENTERPRISE_USER,
  DELETE_USER,
  END_WORK,
  REQUEST_ANALYTICS_USERS,
  RECEIVE_ANALYTICS_USERS,
  REQUEST_USERS,
  RECEIVE_USERS,
  REQUEST_ANALYTICS_USER,
  RECEIVE_ANALYTICS_USER,
  RECEIVE_USER,
  REQUEST_USER,
  START_WORK,
  UPDATE_USER,
};
