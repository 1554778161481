import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../Constants';
import Button from '../../lib/Button';
import ButtonGroup from '../../lib/ButtonGroup';

const DataSourceOauthFormButtons = (props) => {
  const isConnectedToDataSource =
    props.selectedDataSource &&
    props.selectedDataSource.auth_info &&
    (props.selectedDataSource.auth_info.access_token || props.selectedDataSource.auth_info.refresh_token);

  return (
    <div className="mt-3">
      <ButtonGroup align="right">
        {props.type === Constants.DATA_SOURCE_TYPES.google_sheet && !props.selectedDataSource ? (
          <Button type="submit" status={props.isAuthenticatedToDataSource ? 'default' : 'disabled'}>
            Add Data Source
          </Button>
        ) : null}
        {props.selectedDataSource ? (
          <React.Fragment>
            {props.type === Constants.DATA_SOURCE_TYPES.google_sheet && props.isAuthenticatedToDataSource ? (
              <Button category="secondary" onClick={props.oauthFunction}>
                Reconnect to Google
              </Button>
            ) : (
              <Button
                onClick={(e) => props.reconnectToDataSource(e, props.type, props.isProduction)}
                category="secondary"
              >
                Reconnect to Data Source
              </Button>
            )}
            <Button type="submit" status={props.buttonDisabled ? 'disabled' : 'default'}>
              Update Data Source
            </Button>
          </React.Fragment>
        ) : null}
        {props.type === Constants.DATA_SOURCE_TYPES.google_sheet &&
        !props.selectedDataSource ? null : !isConnectedToDataSource &&
          props.type !== Constants.DATA_SOURCE_TYPES.google_sheet ? (
          <Button onClick={(e) => props.connectToDataSource(e, props.type, props.isProduction)}>
            Connect to {props.dataSourceType}
          </Button>
        ) : null}
      </ButtonGroup>
    </div>
  );
};

DataSourceOauthFormButtons.propTypes = {
  selectedDataSource: PropTypes.object,
  type: PropTypes.string,
  isAuthenticatedToDataSource: PropTypes.bool,
  oauthFunction: PropTypes.func,
  isProduction: PropTypes.bool,
  connectToDataSource: PropTypes.func,
  reconnectToDataSource: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  dataSourceType: PropTypes.string,
};

export default DataSourceOauthFormButtons;
