import React, { useState } from 'react';
import { useEditor } from '@craftjs/core';

function isNodeListEmpty(nodes) {
  let isEmpty = true;
  for (let key in nodes) {
    let nodeName = nodes[key].data.name;
    if (
      nodeName !== 'EmailBody' &&
      nodeName !== 'EmailContainer' &&
      nodeName !== 'HtmlBuilderEmptyState' &&
      nodes[key].id !== 'ROOT'
    ) {
      isEmpty = false;
    }
  }
  return isEmpty;
}

function HtmlBuilderEmptyState() {
  const [isDragOver, setDragOver] = useState(false);
  const { isEmpty } = useEditor((state, query) => {
    const currentNodes = query.getNodes();
    const isEmpty = isNodeListEmpty(currentNodes);

    return {
      isEmpty,
    };
  });

  const handleDragEnter = () => {
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = () => {
    setDragOver(false);
  };

  const emptyStateClasses = `html-builder-empty-state ${isDragOver ? 'drag-over' : ''}`;

  return isEmpty ? (
    <>
      <div className="html-builder-empty-state-container">
        <div
          className={emptyStateClasses}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          Drag Module Here To Start Creating Your Template
        </div>
      </div>
    </>
  ) : null;
}

export default HtmlBuilderEmptyState;
