import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import API from '../../lib/api';
import InputLoading from '../shared/InputLoading';
import { Select } from '../shared/FormSelect';
import HighspotIntegrationModal from './HighspotIntegrationModal';
import { openModal } from '../../redux/ui/action';
import { find } from 'lodash';

function HighspotIntegrationSettings(props) {
  const MODAL_NAME = 'HighspotIntegrationModal';
  const [isLoading, setIsLoading] = useState(false);
  const [availableSpots, setAvailableSpots] = useState(null);
  const [spotLoadingError, setSpotLoadingError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.highspotIntegration && !isLoading && !availableSpots) {
      setIsLoading(true);
      setSpotLoadingError(null);
      API.post(
        '/enterprise_integrations/highspot/get_all_spots/',
        {},
        (response) => {
          setAvailableSpots(response.data.result.map((d) => ({ value: d.id, label: d.title })));
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          setSpotLoadingError(err);
        },
      );
    }
  }, [props.highspotIntegration]);

  const onUpdateSpot = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      props.onUpdateHighspotSpot(value);
    }
  };

  const onModalClick = (e) => {
    e.preventDefault();
    dispatch(openModal(MODAL_NAME));
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    API.delete('/enterprise_integrations/highspot/', () => {
      props.onDeleteEnterpriseIntegration('highspot');
    });
  };

  const getInitVal = () => {
    if (availableSpots) {
      return find(availableSpots, (s) => s.value === props.highspotSpotLocation);
    }
    return null;
  };

  let input = null;
  if (props.highspotIntegration) {
    if (isLoading) {
      input = <InputLoading />;
    } else if (spotLoadingError) {
      input = <p>There was an error loading your spots: {spotLoadingError.message}</p>;
    } else {
      let initVal = getInitVal();
      input = (
        <Select
          classNamePrefix="matik-select"
          name="spot_select"
          aria-label="Select Highspot Spot"
          options={availableSpots}
          value={initVal}
          onChange={onUpdateSpot}
        />
      );
    }
  }

  const modal_button_text = props.highspotIntegration ? 'Update Highspot integration' : 'Add Highspot integration';
  return (
    <div className="mvm">
      <Form.Field>
        <Form.Control>{input}</Form.Control>
      </Form.Field>
      <Form.Field>
        <div className="buttons">
          <button className="button is-secondary" onClick={onModalClick}>
            {modal_button_text}
          </button>
          {props.highspotIntegration && (
            <button className="button is-tertiary" onClick={onDeleteClick}>
              Delete Highspot integration
            </button>
          )}
        </div>
      </Form.Field>
      <HighspotIntegrationModal
        highspotIntegration={props.highspotIntegration}
        modal_name={MODAL_NAME}
        onUpdateIntegration={props.onUpdateIntegration}
      />
    </div>
  );
}

HighspotIntegrationSettings.propTypes = {
  highspotIntegration: PropTypes.object,
  highspotSpotLocation: PropTypes.string,
  onDeleteEnterpriseIntegration: PropTypes.func,
  onUpdateHighspotSpot: PropTypes.func,
  onUpdateIntegration: PropTypes.func,
};

export default HighspotIntegrationSettings;
