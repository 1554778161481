import React, { useState, useEffect } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Card, Form } from 'react-bulma-components';
import API from '../../lib/api';
import Constants from '../Constants';
import PropTypes from 'prop-types';
import { ReactComponent as SmallRocket } from '../../svg/small_rocket.svg';
import { Redirect } from 'react-router-dom';
import Button from '../lib/Button';

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState('');
  const [zip, setZip] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    API.post('/payments/create_payment_intent/', { items: [{ id: props.selectedTierId }] }, null, API.defaultError);
  }, [props.selectedTierId]);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    setError(null);

    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: name,
        address: { postal_code: zip },
        email: props.user.email,
      },
    });

    if (error) {
      setError('Payment failed: ' + error.message);
      setProcessing(false);
    } else {
      updateSubscription(paymentMethod.id);
    }
  };

  const setSuccessfulPayment = () => {
    API.track('tier_upgrade_successful_payment');
    setSucceeded(true);
    setError(null);
  };

  const updateSubscription = (paymentMethodId) => {
    API.post(
      '/payments/update_subscription/',
      {
        payment_method_id: paymentMethodId,
        items: [{ id: props.selectedTierId }],
        name: name,
      },
      (response) => {
        setProcessing(false);
        if (response.data.status === Constants.SUBSCRIPTION_STATUSES.active) {
          setSuccessfulPayment();
        } else if (response.data.status === Constants.SUBSCRIPTION_STATUSES.incomplete) {
          setError('Please confirm the payment');
          const error = stripe.confirmCardPayment(response.data.latest_invoice.payment_intent.client_secret, {
            setup_future_usage: 'off_session',
          });
          if (error) {
            setError(error.message);
          } else {
            setSuccessfulPayment();
          }
        } else {
          setSucceeded(false);
          setError('Unable to create subscription');
        }
      },
      (response) => {
        setProcessing(false);
        setSucceeded(false);
        setError(response.error);
      },
    );
  };

  let buttonStatus = 'default';
  if (disabled || succeeded) {
    buttonStatus = 'disabled';
  } else if (processing) {
    buttonStatus = 'loading';
  }

  if (succeeded) {
    return <Redirect to={'/'} />;
  }

  return (
    <React.Fragment>
      <form id="payment-form" onSubmit={handleSubmit} className="payment-form">
        <Card className="credit-card-info">
          <div className="credit-info-header">Your credit card details</div>
          <Form.Field className="mtl">
            <Form.Label>
              <span className="credit-input-label">Name on card</span>
            </Form.Label>
            <Form.Control>
              <Form.Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Control>
          </Form.Field>
          <Form.Field className="mtm">
            <Form.Label>
              <span className="credit-input-label">Card Number</span>
            </Form.Label>
            <CardNumberElement id="card-number-element" className="stripe-credit-input" onChange={handleChange} />
          </Form.Field>
          <div className="mtm expiry-cvc-capture">
            <Form.Field className="shortened-input">
              <Form.Label>
                <span className="credit-input-label">Expiry</span>
              </Form.Label>
              <CardExpiryElement id="card-expiry-element" className="stripe-credit-input" onChange={handleChange} />
            </Form.Field>
            <Form.Field className="pll cvc-field">
              <Form.Label>
                <span className="credit-input-label">CVC</span>
              </Form.Label>
              <CardCvcElement id="card-cvc-element" className="stripe-credit-input" onChange={handleChange} />
            </Form.Field>
          </div>
          <Form.Field className="mtm shortened-input">
            <Form.Label>
              <span className="credit-input-label">ZIP / Postal Code</span>
            </Form.Label>
            <Form.Control>
              <Form.Input type="text" value={zip} onChange={(e) => setZip(e.target.value)} />
            </Form.Control>
          </Form.Field>
        </Card>
        <Card className="plan-summary">
          <div className="plan-summary-header">Plan summary</div>
          <div className="is-flex pbm ptm">
            <SmallRocket />
            {props.selectedTierId && (
              <div className="is-flex">
                <div className="is-block plm">
                  <div className="plan-tier-name">{Constants.MATIK_TIERS[props.selectedTierId].display_name} Plan</div>
                  <div className="plan-billing-cycle">Billed Annually</div>
                </div>
                <div className="plan-summary-price pll">
                  ${Constants.MATIK_TIERS[props.selectedTierId].annual_price}
                </div>
              </div>
            )}
          </div>
          <hr className="plan-summary-hr" />
          <div className="ptl">
            <Button status={buttonStatus} id="submit" type="submit" width="fullWidth">
              <span id="button-text" className="payment-form-submit">
                {processing ? '' : 'Place order'}
              </span>
            </Button>
            {disabled && <div className="payment-info incomplete-message">Your information is incomplete</div>}
            {/* Show any error that happens when processing the payment */}
            {error && (
              <div className="payment-info card-error" role="alert">
                {error}
              </div>
            )}
            {/* Show a success message upon completion */}
            {succeeded && <div className="payment-info payment-success">Payment succeeded</div>}
          </div>
        </Card>
      </form>
    </React.Fragment>
  );
}

CheckoutForm.propTypes = {
  selectedTierId: PropTypes.string,
  user: PropTypes.object,
};
