import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import API from '../../../lib/api';
import InputsContent from './InputsContent';
import * as PropTypes from 'prop-types';
import utils from '../../../lib/utils';
import Constants from '../../Constants';
import parameters_empty_icon from '../../../images/parameters_empty.png';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import InputForm from '../dynamicContent/InputForm';
import { Heading } from 'react-bulma-components';

class InputsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSources: [],
      isLoading: true,
      isPaginating: false,
      pagination: null,
      inputs: [],
    };
    this.mounted = true;
  }

  componentDidMount() {
    API.fetchContent('/parameters/', () => this.setState({ isLoading: true }), this.onContentFetch);

    API.get(
      '/data_sources/?all=true&lite=true',
      (response) => {
        if (this.mounted) {
          this.setState({ dataSources: response.data });
        }
      },
      API.defaultError,
    );

    API.track('inputs_tab_navigation');
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const headerCenter = <Heading className="full-screen-modal-title">Add Input</Heading>;
    const onClose = () => {
      this.props.closeFullScreenModal();
    };

    const emptyInfo = {
      emptyImageUrl: parameters_empty_icon,
      emptyPrompt: "You haven't created any inputs yet.",
      buttonText: 'Create Your First',
      emptyOnClick: () => this.props.openFullScreenModal(),
    };

    return (
      <div className="tab-content">
        <FullScreenModal
          show={this.props.ui.fullScreenModal}
          showDefaultFooter={false}
          backgroundColor="white"
          onClose={onClose}
          headerCenter={headerCenter}
        >
          <div
            className={
              this.props.ui.fullScreenModal
                ? 'fullscreen-modal-dynamic-content-with-sheet'
                : 'fullscreen-modal-dynamic-content'
            }
          >
            <InputForm
              allowNameChange={true}
              dataSources={this.state.dataSources}
              entityId="new"
              entityType="input"
              headerLinkDisabled={true}
              onInputAdd={this.onInputAdd}
              saveButtonText="Add"
              isFullscreen={true}
              buttonWidth="100%"
            />
          </div>
        </FullScreenModal>
        <InputsContent
          isLoading={this.state.isLoading}
          elements={this.state.inputs}
          emptyInfo={emptyInfo}
          match={this.props.match}
          dataSources={this.state.dataSources}
          entityType="input"
          onInputAdd={this.onInputAdd}
          inputs={this.state.inputs}
          fetchItems={this.paginateContent}
          onInputUpdate={this.onInputUpdate}
          onInputDelete={this.onInputDelete}
          pagination={this.state.pagination}
          isPaginating={this.state.isPaginating}
        />
      </div>
    );
  }

  onContentFetch = (inputs, pagination) => {
    if (this.mounted) {
      this.setState({ inputs, pagination, isLoading: false });
    }
  };

  beforePagination = () => {
    this.setState({ isPaginating: true });
  };

  onContentPagination = (inputs, pagination) => {
    this.setState({ inputs, pagination, isPaginating: false });
  };

  paginateContent = (offset = 0, limit = Constants.PAGE_SIZE, sort = null) => {
    API.fetchContent('/parameters/', this.beforePagination, this.onContentPagination, offset, limit, sort);
  };

  onInputAdd = (newInput) => {
    const updatedInputs = this.state.inputs;
    updatedInputs.unshift(newInput);
    utils.notify('Input added');
    this.setState({ inputs: updatedInputs, showInputsFullscreen: false });
  };

  onInputUpdate = (updatedInput) => {
    const updatedInputs = this.state.inputs;
    for (let i = 0; i < updatedInputs.length; i++) {
      if (updatedInputs[i].id === updatedInput.id) {
        updatedInputs[i] = updatedInput;
      }
    }

    utils.notify('Input updated');
    this.setState({ inputs: updatedInputs });
  };

  onInputDelete = (deletedInput) => {
    const updatedInputs = [];
    for (let i = 0; i < this.state.inputs.length; i++) {
      if (this.state.inputs[i].id !== deletedInput.id) {
        updatedInputs.push(this.state.inputs[i]);
      }
    }

    utils.notify('Input deleted');
    this.setState({ inputs: updatedInputs });
    this.props.history.push('/inputs');
  };
}

InputsTab.propTypes = {
  match: PropTypes.any,
  history: PropTypes.object,
  ui: PropTypes.object,
  closeFullScreenModal: PropTypes.func,
  openFullScreenModal: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...mapUiStateToProps(state, ownProps),
  };
};

const mapDispatchToProps = (state, ownProps) => {
  return {
    ...mapUiDispatchToProps(state, ownProps),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InputsTab));
