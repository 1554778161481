import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Constants from '../../Constants';

function DependentOnBulkInput({ input, placeholder }) {
  const inputPlaceholder = placeholder
    ? placeholder
    : input.type === Constants.InputTypes.LIST
    ? 'All options selected'
    : 'First option selected';
  return (
    <Form.Input
      className="dependent-on-bulk-input"
      placeholder={inputPlaceholder}
      name={`${input.name}_input_placeholder`}
      type="text"
      disabled
    />
  );
}

DependentOnBulkInput.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
};

export default DependentOnBulkInput;
