import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData1 from '../../lottie/preloader-01';
import animationData2 from '../../lottie/preloader-02';
import animationData3 from '../../lottie/preloader-03';
import animationData4 from '../../lottie/preloader-04';

class PageLoader extends PureComponent {
  render() {
    const activeClass = this.props.isActive ? 'is-active' : '';
    let animation = null;
    switch (this.props.title.toLowerCase()) {
      case 'processing':
        animation = animationData1;
        break;
      case 'copy':
        animation = animationData2;
        break;
      case 'custom':
        animation = animationData3;
        break;
      case 'google':
        animation = animationData4;
        break;
      default:
        animation = animationData1;
        break;
    }
    const defaultOptions = {
      loop: true,
      autoplay: false,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMin',
        runExpressions: false,
      },
    };
    return (
      <>
        {this.props?.showLottie ? (
          <div className={`pageloader lottie ${this.props.direction} ${activeClass}`}>
            <div className="titlelottie">
              <div className="loader-wrapper">
                <div className="lottie-loader">
                  <Lottie options={this.props.isActive ? defaultOptions : {}} height={400} width={400} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`pageloader ${this.props.direction} ${activeClass}`}>
            <span className="title">{this.props.title}</span>
          </div>
        )}
      </>
    );
  }
}

PageLoader.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  showLottie: PropTypes.bool,
};

export default PageLoader;
