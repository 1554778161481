import React from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import AuthTabs from '../AuthTabs';

function LookerAuth({ auth, centered, onAuthChange }) {
  const clientId = auth?.client_id ?? '';
  const clientSecret = auth?.client_secret ?? '';
  const tab = { name: 'login-tab', title: 'Access via Login' };
  return (
    <>
      <AuthTabs activeAuthTab={tab.name} tabs={[tab]} setActiveAuthTab={() => {}} centered={centered} />
      <Box className="data-source-box" style={centered ? { margin: '0 auto' } : null}>
        <Form.Field>
          <Form.Label htmlFor="client-id">Looker Client Id</Form.Label>
          <Form.Help>
            Client id can be found in Looker under Admin -&gt; Users -&gt; &quot;Edit&quot; for user to connect -&gt;
            Edit Keys -&gt; New API3 Key
          </Form.Help>
          <Form.Control>
            <Form.Input
              placeholder="Looker Client Id"
              type="text"
              name="client_id"
              onChange={onAuthChange}
              value={clientId}
              id="client-id"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="client-secret">Looker Client Secret</Form.Label>
          <Form.Help>
            Client secret can be found in Looker under Admin -&gt; Users -&gt; &quot;Edit&quot; for user to connect
            -&gt; Edit Keys -&gt; New API3 Key
          </Form.Help>
          <Form.Control>
            <Form.Input
              placeholder="Looker Client Secret"
              type="password"
              name="client_secret"
              onChange={onAuthChange}
              value={clientSecret}
              id="client-secret"
            />
          </Form.Control>
        </Form.Field>
      </Box>
    </>
  );
}

LookerAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  onAuthChange: PropTypes.func,
};

export default LookerAuth;
