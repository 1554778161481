import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bulma-components';
import analytics_empty from '../../../images/analytics_empty.png';
import teams from '../../../lib/teams';
import withUserContext from '../../shared/WithUserContext';
import Button from '../../lib/Button';

class AnalyticsColumnChartUpsell extends Component {
  render() {
    return (
      <Container className="has-text-centered narrow-container product-upsell-modal pam">
        <img src={analytics_empty} width={this.props.small ? 128 : 256} alt="Team Insights Chart" />
        <span className="product-upsell-header mbm">Monitor Team insights with Matik Enterprise</span>
        <span className="product-upsell-content mbl">
          Team insights chart is an Enterprise Plan feature. Upgrade to Matik Enterprise to monitor usage.
        </span>
        <Button onClick={this.onClick}>Discover Matik Enterprise</Button>
      </Container>
    );
  }

  onClick = (e) => {
    e.preventDefault();
    const demoUrl = teams.buildRequestDemoUrl(
      this.props.userContext.user,
      'insights_chart',
      this.props.userContext.user.enterprise.trial_days_remaining,
    );
    window.open(demoUrl, '_blank');
  };
}

AnalyticsColumnChartUpsell.propTypes = {
  small: PropTypes.bool,
  userContext: PropTypes.object,
};

export default withUserContext(AnalyticsColumnChartUpsell);
