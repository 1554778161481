import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import Pluralize from 'pluralize';
import DataSourceSelect from './forms/DataSourceSelect';
import { MConfirm } from '../../shared/Alerts';
import API from '../../../lib/api';
import Banner, { toastBanner } from 'components/shared/Banner';
import { closeModal } from '../../../redux/ui/action';
import { useBulkDynamicContentMutator } from '../../../lib/hooks/useDynamicContent';

function BulkUpdateDataSourceModal({ selectedItemIds, dataSourceType }) {
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const { bulkUpdateDataSource } = useBulkDynamicContentMutator();

  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const onClose = (e) => {
    e.preventDefault();
    dispatch(closeModal());
  };

  const onSave = () => {
    MConfirm(
      'Updating Data Source',
      `You are about to update the data source of ${Pluralize(
        'item',
        selectedItemIds.length,
        true,
      )}. Are you sure you want to continue?`,
      'warning',
      (ok) => {
        ok &&
          bulkUpdateDataSource(selectedItemIds, selectedDataSource.id)
            .then((response) => {
              toastBanner(
                <Banner
                  bannerType="success"
                  text={`Successfully updated data source for ${Pluralize('item', response.data.updated.length, true)}`}
                />,
              );
              setSaveEnabled(false);
            })
            .catch(API.defaultError);
      },
    );
  };

  const onDataSourceSelect = (dataSource) => {
    setSaveEnabled(dataSource.id !== selectedDataSource?.id);
    setSelectedDataSource(dataSource);
  };

  const title = `Update data source for ${Pluralize('item', selectedItemIds.length, true)}`;
  return (
    <Modal
      show={ui.modal?.name === 'bulkUpdateDataSourceModal'}
      title={title}
      onClose={onClose}
      showDefaultFooter={true}
      footerIsSticky={false}
      primaryButtonText="Save"
      primaryButtonOnClick={onSave}
      primaryButtonDisabled={!saveEnabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onClose}
    >
      <DataSourceSelect
        onDataSourceSelect={onDataSourceSelect}
        selectedDataSource={selectedDataSource}
        dataSourceTypeFilter={[dataSourceType]}
        omitHeadline={true}
      />
    </Modal>
  );
}

BulkUpdateDataSourceModal.propTypes = {
  selectedItemIds: PropTypes.array,
  dataSourceType: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

export default BulkUpdateDataSourceModal;
