import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import { Select } from '../../shared/FormSelect';
import { remove } from 'lodash';

class AnalyticsTemplateSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      templates: [],
    };
  }

  componentDidMount() {
    API.get(
      '/templates/',
      (response) => {
        this.setState({ templates: response.data, isLoading: false });
      },
      API.defaultError,
    );
  }

  render() {
    return (
      <Select
        className="analytics-dropdown"
        isMulti={true}
        isSearchable={true}
        onChange={this.onTemplateSelect}
        name="template"
        value={this.props.selectedTemplates}
        classNamePrefix="matik-select"
        options={this.state.templates.map((template) => ({ label: template.name, value: template.id }))}
        isLoading={this.state.isLoading}
        placeholder="Choose a Template"
      />
    );
  }

  onTemplateSelect = (obj, action) => {
    const updatedTemplates = this.props.selectedTemplates.slice();
    if (action.action === 'select-option') {
      updatedTemplates.push(action.option);
      this.props.updateQueryParams({ templates: updatedTemplates });
    } else if (action.action === 'select-all-options') {
      updatedTemplates.push(...action.option);
      this.props.updateQueryParams({ templates: updatedTemplates });
    } else if (action.action === 'remove-value') {
      remove(updatedTemplates, (el) => {
        return action.removedValue.value === el.value;
      });
      this.props.updateQueryParams({ templates: updatedTemplates });
    } else if (action.action === 'clear') {
      this.props.updateQueryParams({ templates: [] });
    }
  };
}

AnalyticsTemplateSelector.propTypes = {
  selectedTemplates: PropTypes.array,
  updateQueryParams: PropTypes.func,
};

export default AnalyticsTemplateSelector;
