import React from 'react';
import EmailRecipientDcCard from './EmailRecipientDcCard';
import PropTypes from 'prop-types';

function EmailRecipientDropdownList(props) {
  const filteredDynamicContent = props.entitiesToRender?.filter(
    (content) => content.dynamic_content_type === 'recipients',
  );
  return filteredDynamicContent.map((content) => (
    <EmailRecipientDcCard
      key={content.id}
      content={content}
      primary={false}
      onRowClick={props.onDynamicRecipientClick}
    />
  ));
}

EmailRecipientDropdownList.propTypes = {
  entitiesToRender: PropTypes.array,
  onDynamicRecipientClick: PropTypes.func,
};

export default EmailRecipientDropdownList;
