import React from 'react';
import PropTypes from 'prop-types';
import InsightCard from 'components/shared/flows/InsightCard';
function InsightsPanel({ run, statusDetail, progressBar }) {
  const insightCardsTitles = ['Sent', 'Opened', 'Clicked', 'Bounces', 'Spam Reports/Unsubscribes'];

  const sentCardBottom = (
    <div>
      {progressBar}
      {statusDetail}
    </div>
  );

  const renderInsightCards = () => {
    const insightCards = [];
    insightCardsTitles.forEach((title, idx) => {
      switch (title) {
        case 'Sent':
          insightCards.push(
            <InsightCard
              key={idx}
              cardBottom={sentCardBottom}
              title={title}
              percent={run.sendgrid_data?.sent_pct}
              total={run.presentation_status_data?.sent}
              cardClass="sent"
            />,
          );
          break;
        case 'Opened':
          insightCards.push(
            <InsightCard
              key={idx}
              title={title}
              percent={run.sendgrid_data?.opens_pct}
              total={run.sendgrid_data?.opens}
              cardClass="opens"
            />,
          );
          break;
        case 'Clicked':
          insightCards.push(
            <InsightCard
              key={idx}
              title={title}
              percent={run.sendgrid_data?.clicks_pct}
              total={run.sendgrid_data?.clicks}
              cardClass="clicks"
            />,
          );
          break;
        case 'Bounces':
          insightCards.push(
            <InsightCard
              key={idx}
              title={title}
              percent={run.sendgrid_data?.bounces_pct}
              total={run.sendgrid_data?.bounces}
              cardClass="bounces"
            />,
          );
          break;
        case 'Spam Reports/Unsubscribes':
          insightCards.push(
            <InsightCard
              key={idx}
              title={title}
              percent={run.sendgrid_data?.unsubscribes_pct + run.sendgrid_data?.spam_reports_pct}
              total={run.sendgrid_data?.unsubscribes + run.sendgrid_data?.spam_reports}
              cardClass="spam"
            />,
          );
          break;
        default:
          null;
      }
    });
    return insightCards;
  };
  return (
    <div className="analytics-panel">
      <div className="analytics-panel-title">Insights</div>
      <div className="analytics-panel-content">{renderInsightCards()}</div>
    </div>
  );
}

InsightsPanel.propTypes = {
  run: PropTypes.object,
  statusDetail: PropTypes.array,
  progressBar: PropTypes.element,
};

export default InsightsPanel;
