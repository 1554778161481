import { useContext } from 'react';
import utils from 'lib/utils';
import { UserContext } from 'components/UserContext';
import { MInsufficientPermissions, MInsufficientPerms } from 'components/shared/Alerts';
import Constants from 'components/Constants';

function useMicrosoftIntegration() {
  const context = useContext(UserContext);

  const microsoftIntegration = utils.microsoftIntegration(context.user);
  const needsToConnect = !microsoftIntegration;

  const reconnect = () => {
    utils.reconnectMicrosoft(context.user, context.updateUser, () => {}, showPermsAlert);
  };
  const showPermsAlert = () => {
    const title = 'Grant Microsoft permissions to Matik';
    const message =
      'To generate PowerPoint presentations from Office 365, you must grant Matik the permissions requested on the next screen.';
    MInsufficientPermissions(title, message, 'microsoft', reconnect);
  };

  const connect = () => {
    utils.connectMicrosoft(context.user, context.updateUser, () => {}, showPermsAlert);
  };

  return {
    needsToConnect,
    connect,
  };
}

function useGoogleIntegration() {
  const context = useContext(UserContext);

  const googleIntegration = utils.googleIntegration(context.user);
  const needsToConnect = !googleIntegration || !googleIntegration.has_necessary_scopes;

  const reconnect = () => {
    utils.reconnectGoogle(context.user, context.updateUser, () => {}, showPermsAlert);
  };
  const showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message =
      'In order to generate your presentation, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, reconnect);
  };

  const connect = () => {
    utils.connectGoogle(context.user, context.updateUser, () => {}, showPermsAlert);
  };

  return {
    needsToConnect,
    connect,
  };
}

/** Determine if a user is connected to Google or O365 and provide a method to
 * let them connect.
 *
 * @param {*} templateSourceType The type of template used to determine which (if any)
 * service the user needs to be connected to.
 * @returns `{needsToConnect, connect}`
 */
export default function useIntegrationConnector(templateSourceType) {
  const microsoftIntegration = useMicrosoftIntegration();
  const googleIntegration = useGoogleIntegration();

  if (
    templateSourceType === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
    templateSourceType === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
  ) {
    return microsoftIntegration;
  } else if (templateSourceType === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES) {
    return googleIntegration;
  } else {
    return {
      needsToConnect: false,
      connect: () => {},
    };
  }
}
