import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { cloneDeep } from 'lodash';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { useAllDynamicContentById } from 'lib/hooks/useDynamicContent';

function MatikAutoInsightsForm() {
  const dynamicContentContext = useContext(DynamicContentContext);
  const { dynamicContentById: allDynamicContentNamesById, isLoading: isFetchingContent } = useAllDynamicContentById();
  const queryObj = dynamicContentContext.query ? JSON.parse(dynamicContentContext.query) : {};

  const actionOptions = [
    { label: 'Describe', value: 'describe' },
    { label: 'Analyze', value: 'analyze' },
    { label: 'Summarize', value: 'summarize' },
    { label: 'Compare', value: 'compare' },
  ];
  const action = queryObj.action ? queryObj.action : { label: 'Describe', value: 'describe' };
  const outputOptions = [
    { label: 'One Sentence', value: 'one_sentence' },
    { label: 'One Paragraph', value: 'one_paragraph' },
    { label: 'Bulleted List', value: 'bullets' },
    { label: 'Numbered List', value: 'numbers' },
  ];
  const listCount = queryObj?.list_count ? queryObj.list_count : { label: 'Three Entries', value: '3' };
  const listCountOptions = [
    { label: 'One Entry', value: '1' },
    { label: 'Two Entries', value: '2' },
    { label: 'Three Entries', value: '3' },
    { label: 'Four Entries', value: '4' },
    { label: 'Five Entries', value: '5' },
    { label: 'Six Entries', value: '6' },
    { label: 'Seven Entries', value: '7' },
  ];
  const output = queryObj?.output ? queryObj.output : { label: 'One Sentence', value: 'one_sentence' };

  const updateQueryObj = (queryObj) => {
    dynamicContentContext.onQueryObjectUpdate(queryObj);
  };

  const onChange = (name, obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      updatedQueryObj[name] = { label: obj.label, value: obj.value };
      if (name === 'output' && (obj.value === 'bullets' || obj.value === 'numbers')) {
        updatedQueryObj.list_count = listCount;
      }
      updateQueryObj(updatedQueryObj);
    }
  };

  const onDynamicContentChange = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      updatedQueryObj.dynamicContent = [{ label: obj.label, value: `{{${obj.label}}}` }];
      updateQueryObj(updatedQueryObj);
    }
  };

  const onMultiDynamicContentChange = (obj, action) => {
    const updatedQueryObj = Object.assign({}, queryObj);
    if (action.action === 'select-option') {
      if (!updatedQueryObj.dynamicContent) {
        updatedQueryObj.dynamicContent = [];
      }
      updatedQueryObj.dynamicContent.push({ label: action.option.label, value: `{{${action.option.label}}}` });
    } else if (action.action === 'select-all-options') {
      const entries = action.option.map((option) => ({ label: option.label, value: `{{${option.label}}}` }));
      updatedQueryObj.dynamicContent.push(...entries);
    } else if (action.action === 'remove-value') {
      updatedQueryObj.dynamicContent = updatedQueryObj.dynamicContent.filter(
        (content) => content.label !== action.removedValue.label,
      );
    } else if (action.action === 'clear') {
      updatedQueryObj.dynamicContent = [];
    }
    updateQueryObj(updatedQueryObj);
  };

  const renderDynamicContentSelect = (currentDynamicContent) => {
    if (isFetchingContent) {
      return <Skeleton height={40} borderRadius="4px" duration={1.2} baseColor="#EFF1F6" />;
    }

    if (action.value === 'compare') {
      return (
        <Select
          aria-label="this dynamic content"
          classNamePrefix="matik-select"
          placeholder="Compare dynamic content..."
          isMulti={true}
          options={dynamicContentOptions}
          value={currentDynamicContent}
          onChange={onMultiDynamicContentChange}
        />
      );
    } else {
      return (
        <Select
          aria-label="this dynamic content"
          classNamePrefix="matik-select"
          placeholder="Select dynamic content..."
          options={dynamicContentOptions}
          value={currentDynamicContent ? currentDynamicContent[0] : null}
          onChange={onDynamicContentChange}
        />
      );
    }
  };

  let dynamicContentOptions = [];
  let currentDynamicContent = null;
  if (!isFetchingContent) {
    if (allDynamicContentNamesById) {
      Object.values(allDynamicContentNamesById).forEach((dc) => {
        dynamicContentOptions.push({ label: dc.name, value: dc.id });
      });
    }
    if (queryObj?.dynamicContent) {
      currentDynamicContent = cloneDeep(queryObj?.dynamicContent);
      currentDynamicContent.forEach((content) => {
        if (content && content.value) {
          content.value = dynamicContentOptions.find((dc) => dc.label === content.label)?.value;
        }
      });
    }
  }
  return (
    <Form.Field className="pal">
      <Form.Control>
        <p>Matik Automated Insights can automatically summarize, analyze, or give recommendations about your data</p>
        <Form.Field className="mtm">
          <Form.Label>I want to</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              options={actionOptions}
              value={action}
              onChange={(obj, action) => onChange('action', obj, action)}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field data-testid="dynamic-content-select">
          <Form.Label>this dynamic content</Form.Label>
          <Form.Control>{renderDynamicContentSelect(currentDynamicContent)}</Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>in</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              options={outputOptions}
              value={output}
              onChange={(obj, action) => onChange('output', obj, action)}
            />
          </Form.Control>
        </Form.Field>
        {(output.value === 'bullets' || output.value === 'numbers') && (
          <Form.Field>
            <Form.Label>with a length of</Form.Label>
            <Form.Control>
              <Select
                classNamePrefix="matik-select"
                options={listCountOptions}
                value={listCount}
                onChange={(obj, action) => onChange('list_count', obj, action)}
              />
            </Form.Control>
          </Form.Field>
        )}
      </Form.Control>
    </Form.Field>
  );
}

export default MatikAutoInsightsForm;
