import React from 'react';
import AccessAvatarsWithShareButton from 'components/shared/AccessAvatarsWithShareButton';
import EditableTemplateName from 'components/shared/templates/EditableTemplateName';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import TemplateActionDropdown from './TemplateActionDropdown';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Tag from 'components/lib/Tag';
import IconPill from 'components/lib/IconPill';
import PropTypes from 'prop-types';
import { openFullScreenModal } from 'redux/ui/action';
import Tabs from 'components/lib/Tabs';
import TabItem from 'components/lib/TabItem';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { find } from 'lodash';

function PresentationTemplateHeader({
  currentTemplate,
  allOrderedInputs,
  onTemplateUpdateWithServerCall,
  canEdit,
  status,
  accessesByTemplateId,
  onTemplateTest,
  onTemplateDelete,
  onTemplateArchive,
  convertTemplate,
  syncTemplate,
  setIsLibrary,
  activeTab,
  toggleTab,
}) {
  const shouldShowTemplateConditionsIcon = currentTemplate?.conditions?.length;
  const containsLibrarySlide = find(currentTemplate.slides, (slide) => slide.is_library);

  const ldClient = useLDClient();
  const showAdminGenerateTab = ldClient?.variation('show-admin-generate-tab', false);

  const tabs = (
    <Tabs>
      <TabItem label="Setup" onClick={toggleTab} isActive={activeTab === 'setup'} />
      <TabItem label="Generate" onClick={toggleTab} isActive={activeTab === 'generate'} />
    </Tabs>
  );

  return (
    <Level className="light-bottom-border mbn templates-header">
      <Level.Side align="left">
        <Level.Item>
          <Link to="/templates" className="button is-secondary is-round-button">
            <Icon name="chevron_left" size={16} theme="regular" />
          </Link>
        </Level.Item>
        <Level.Item>
          <EditableTemplateName
            template={currentTemplate}
            orderedInputs={allOrderedInputs}
            onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
            canEdit={canEdit}
          />
        </Level.Item>
        {status && status.status === 'error' && (
          <Level.Item>
            <Tag color="red" luminosity="medium" label="Template disconnected" />
          </Level.Item>
        )}
      </Level.Side>
      {showAdminGenerateTab ? tabs : null}
      <Level.Side align="right" className="w-96">
        <Level.Item>
          <AccessAvatarsWithShareButton
            template={currentTemplate}
            accessesByTemplateId={accessesByTemplateId}
            showShareButton={true}
          />
        </Level.Item>
        <Level.Item>
          {shouldShowTemplateConditionsIcon ? (
            <div
              data-tooltip-id="matik-tooltip"
              data-tooltip-content="This template has conditions applied. A presentation will only be generated if it meets these conditions. (Click here to manage the conditions.)"
              onClick={() => openFullScreenModal('generationSettingsModal')}
              disabled={!canEdit}
            >
              <IconPill iconName="conditional" size="m" color="blue" theme="circle" />
            </div>
          ) : null}
        </Level.Item>
        {activeTab === 'setup' && (
          <Level.Item>
            <Button category="secondary" onClick={onTemplateTest}>
              <Icon name="play_circle" size={20} theme="regular" />
              Test Presentation
            </Button>
          </Level.Item>
        )}
        <Level.Item>
          <TemplateActionDropdown
            onTemplateDelete={onTemplateDelete}
            onTemplateArchive={onTemplateArchive}
            convertTemplate={convertTemplate}
            syncTemplate={syncTemplate}
            setIsLibrary={setIsLibrary}
            currentTemplate={currentTemplate}
            canEdit={canEdit}
            containsLibrarySlide={!!containsLibrarySlide}
            orderedInputs={allOrderedInputs}
            onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
          />
        </Level.Item>
      </Level.Side>
    </Level>
  );
}

PresentationTemplateHeader.propTypes = {
  currentTemplate: PropTypes.object,
  allOrderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  canEdit: PropTypes.bool,
  status: PropTypes.object,
  accessesByTemplateId: PropTypes.object,
  onTemplateTest: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  convertTemplate: PropTypes.func,
  syncTemplate: PropTypes.func,
  setIsLibrary: PropTypes.func,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
};

export default PresentationTemplateHeader;
