import API from 'lib/api';
import { useQuery, useQueries, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import { useDispatch } from 'react-redux';
import { addNotificationSuccess } from 'redux/notifications/action';
import { addTemplateToLibraries, removeTemplateFromLibraries } from 'redux/libraries/action';
import LongRequest from 'lib/longRequest';
import { MAlert } from 'components/shared/Alerts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toast } from 'react-toastify';
import useBatcher from 'lib/useBatcher';
import useAccesses from './useAccess';
import { useDynamicContentInputs } from './useDynamicContent';

/** Fetch and cache the full template data from the backend */
const useTemplate = (id) => {
  const queryClient = useQueryClient();

  const queryKey = ['template', parseInt(id)];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/templates/${id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  /** Invalidate cached template data and optionally replace the data with a given value
   * @param templateId (optional) the ID of the template to invalidate. if not given, it will fallback
   * to the value passed into `useTemplate(id)`
   * @param replacementData (optional) This data can be used to seed the cache with new template data.
   */
  const invalidate = (templateId, replacementData) => {
    templateId = parseInt(templateId);
    if (isNaN(templateId)) {
      templateId = parseInt(replacementData?.id);
    }
    if (isNaN(templateId)) {
      templateId = parseInt(id);
    }
    if (isNaN(templateId)) {
      // eslint-disable-next-line no-console
      console.warn('Template invalidation called, but no ID is given. This is a noop.', templateId, replacementData);
      return;
    }

    const key = ['template', templateId];
    queryClient.invalidateQueries({ queryKey: key });

    if (replacementData) {
      queryClient.setQueryData(key, replacementData);
    }
  };

  /** Invalidate any cached templates that match the given filter function.
   * The filter function will be passed in the cached template data and should
   * return `true` if it is to be invalidated.
   */
  const invalidateMatching = (filter) => {
    queryClient.invalidateQueries({
      queryKey: ['template'],
      predicate: (query) => {
        if (query.queryKey.length === 2 && query.state.data) {
          return filter(query.state.data);
        } else {
          return false;
        }
      },
    });
  };

  return {
    isPending: isLoading,
    isError,
    data,
    error,
    invalidate,
    invalidateMatching,
  };
};

export default useTemplate;

/** Create / Update / Delete, etc. a template */
export const useTemplateMutator = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { invalidate: invalidateAccesses } = useAccesses();

  /** Update select attributes on the template */
  const update = (updatedTemplate, role, showNotification = true) => {
    const id = updatedTemplate.id;
    const queryKey = ['template', parseInt(id)];

    if (role && role === Constants.CONSUMER_ROLE) {
      queryClient.setQueryData(queryKey, updatedTemplate);
      dispatch(addNotificationSuccess('Template updated'));
      return Promise.resolve(updatedTemplate);
    } else {
      // Optimistically update the cache
      const cachedData = queryClient.getQueryData(queryKey);
      if (cachedData) {
        const optimistic = { ...cachedData };
        for (const key in updatedTemplate) {
          const value = updatedTemplate[key];
          if (value !== undefined) {
            optimistic[key] = value;
          }
        }
        queryClient.setQueryData(queryKey, optimistic);
      }

      const data = {
        published: updatedTemplate.published,
        name: updatedTemplate.name,
        description: updatedTemplate.description,
        presentation_name_template: updatedTemplate.presentation_name_template,
        source: updatedTemplate.source,
        newTags: updatedTemplate.newTags,
        html: updatedTemplate.html,
        attached_template_id: updatedTemplate.attached_template_id,
        params_order: updatedTemplate.params_order,
        integration_folders: updatedTemplate.integration_folders,
      };
      return API.put(
        `/templates/${id}/`,
        data,
        (response) => {
          queryClient.setQueryData(queryKey, response.data.updated_entity);
          if (showNotification) {
            dispatch(addNotificationSuccess('Template updated'));
          }

          // invalidate search results
          queryClient.invalidateQueries({ queryKey: ['templates'] });

          return response.data.updated_entity;
        },
        (err) => {
          // failure updating, so roll back the optimistic cache
          if (cachedData) {
            queryClient.setQueryData(queryKey, cachedData);
          }
          if (showNotification) {
            API.defaultError(err);
          } else {
            throw err;
          }
        },
        null,
      );
    }
  };

  /** Import/create a new template */
  const create = (templateData, file, onStatusUpdate, onProgress) => {
    return new Promise((resolve, reject) => {
      let postAddTemplateHandled = false;
      const handleSuccess = (response, onComplete) => {
        if (postAddTemplateHandled) {
          return;
        }

        if (response.data.template_status === 'done') {
          postAddTemplateHandled = true;

          API.track('template_add', { source_type: templateData['source_type'] });
          onComplete();

          if (response.data.new_entity && isEmpty(response.data.new_entity.pdf)) {
            MAlert(
              'Consider reducing the file size by using library slides or by downsizing assets.',
              'File is too large',
              'warning',
            );
          }

          queryClient.setQueryData(['template', parseInt(response.data.new_entity.id)], response.data.new_entity);
          dispatch(addNotificationSuccess(`'${response.data.new_entity.name}' added`));

          // invalidate search results
          queryClient.invalidateQueries({ queryKey: ['templates'] });

          // refresh accesses to include the newly created template
          invalidateAccesses('template');

          resolve(response.data.new_entity);
        } else if (response.data.template_status === 'unknown') {
          postAddTemplateHandled = true;

          // This may happen if there is a race condition: another user loads the templates page in between status checks
          onComplete();
          window.location.reload();
          resolve();
        } else {
          if (onStatusUpdate) {
            try {
              onStatusUpdate(response.data.template_status);
            } catch (e) {
              // protect against side-effects
            }
          }
        }
      };

      const handleError = (err) => {
        reject(err);
      };

      const longRequest = new LongRequest('/templates');
      longRequest.post(templateData, handleSuccess, handleError, file, onProgress || (() => {}));
    });
  };

  /** Create a new template via direct backend call that don't need a long request. i.e. email templates */
  const createFast = (templateData, file) => {
    return API.post(
      '/templates/',
      templateData,
      (response) => {
        API.track('template_add', { source_type: templateData['source_type'] });

        const newTemplate = response.data.new_entity;
        queryClient.setQueryData(['template', parseInt(newTemplate.id)], newTemplate);

        // invalidate search results
        queryClient.invalidateQueries({ queryKey: ['templates'] });

        // refresh accesses to include the newly created template
        invalidateAccesses('template');

        return newTemplate;
      },
      (err) => {
        throw err;
      },
      file,
    );
  };

  /** Delete (*not* archive -- use `archive()` for that) a template */
  const del = (template) => {
    const templateId = parseInt(template.id);

    return API.delete(
      template.deleted ? `/templates/archives/${templateId}/` : `/templates/${templateId}/`,
      () => {
        dispatch(removeTemplateFromLibraries(template.id));
        dispatch(addNotificationSuccess('Template deleted'));

        const key = ['template', templateId];
        queryClient.removeQueries({ queryKey: key });

        // invalidate search results
        queryClient.invalidateQueries({ queryKey: ['templates'] });
      },
      (err) => {
        throw err;
      },
    );
  };

  /** Create a sub-template */
  const createSubTemplate = (templateId, data) => {
    return API.post(
      `/templates/${templateId}/sub_template/`,
      data,
      (response) => {
        // refresh accesses to include the newly created sub-template
        invalidateAccesses('template');
        return response.data;
      },
      (err) => {
        throw err;
      },
    );
  };
  /** Delete a sub-template */
  const deleteSubTemplate = (id) => {
    return API.delete(`/sub_templates/${id}/`, () => {
      queryClient.removeQueries({ queryKey: ['template', parseInt(id)] });
    });
  };

  /** Update template's "archived" status */
  const archive = (templateId, isArchived) => {
    const url = isArchived ? `/templates/archives/${templateId}/unarchive/` : `/templates/${templateId}/archive/`;
    return API.get(
      url,
      (response) => {
        if (isArchived) {
          dispatch(addNotificationSuccess('Template un-archived'));
        } else {
          dispatch(addNotificationSuccess('Template archived'));
        }
        queryClient.removeQueries({ queryKey: ['template', parseInt(templateId)] });
        queryClient.invalidateQueries({ queryKey: ['templates'] });

        return response.data;
      },
      (err) => {
        throw err;
      },
    );
  };

  /** Update the "is_library" flag on a template */
  const updateLibrary = async (templateId, isLibrary) => {
    let invalidateTemplates = [];
    if (!isLibrary) {
      // We're removing the library which will potentially remove slides from referencing libraries.
      // We want to invalidate those referencing library templates so they'll see the slide updates right away.
      try {
        invalidateTemplates = await API.get(`/templates/libraries/${templateId}/templates/`, (response) => {
          if (response.data && response.data.length > 0) {
            return response.data.map((template) => template.id);
          } else {
            return [];
          }
        });
      } catch (e) {
        // ignore any errors
      }
    }

    return API.post(
      `/templates/${templateId}/library/`,
      { is_library: isLibrary },
      (response) => {
        const updatedTemplate = response.data;
        if (isLibrary) {
          dispatch(addNotificationSuccess('Template published as library'));
          dispatch(addTemplateToLibraries(updatedTemplate));
        } else {
          dispatch(addNotificationSuccess('Library unpublished'));
          dispatch(removeTemplateFromLibraries(templateId));
        }

        // we've got the updated template data, so just push that into the cache
        queryClient.setQueryData(['template', parseInt(templateId)], updatedTemplate);

        // search results include the library flag, so we need to refresh those
        queryClient.invalidateQueries({ queryKey: ['templates'] });

        // decache referencing templates since they might have been updated, too
        invalidateTemplates
          .map((id) => ['template', parseInt(id)])
          .forEach((queryKey) => queryClient.removeQueries({ queryKey }));

        return updatedTemplate;
      },
      (err) => {
        throw err;
      },
    );
  };

  /** Insert a library slide into the template */
  const insertLibrarySlide = (slidesToInsert, templateId, insertionIdx) => {
    const slideIds = [];
    for (let slideKey in slidesToInsert) {
      slideIds.push(slidesToInsert[slideKey].slideId);
    }
    const data = {
      library_slide_ids: slideIds,
      library_slide_idx: insertionIdx,
    };
    return API.post(
      `/templates/${templateId}/library_slides/`,
      data,
      (response) => {
        queryClient.invalidateQueries({ queryKey: ['template', parseInt(templateId)] });
        return response.data;
      },
      (err) => {
        throw err;
      },
    );
  };

  /** Remove a library slide from a template */
  const removeLibrarySlide = (librarySlideId, templateId) => {
    const data = {
      library_slide_ids: [librarySlideId],
    };
    return API.post(`/templates/${templateId}/library_slides/delete/`, data, (response) => {
      queryClient.invalidateQueries({ queryKey: ['template', parseInt(templateId)] });
      return response.data;
    });
  };

  return {
    update,
    create,
    createFast,
    del,
    createSubTemplate,
    deleteSubTemplate,
    archive,
    updateLibrary,
    insertLibrarySlide,
    removeLibrarySlide,
  };
};

/** If you need to fetch an arbitrary set of templates by id, you can use this one. If you only need a single template,
 * `useTemplate()` is a bit easier to work with. Note, this does not batch the requests, it will issue a request
 * per id.
 * @returns the { isPending, isError, data, error } result of each query keyed by ID.
 */
export const useTemplatesById = (ids) => {
  const queries = ids.map((id) => ({
    queryKey: ['template', parseInt(id)],
    queryFn: () => {
      return API.get(
        `/templates/${id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
  }));

  const resultById = {};

  const results = useQueries({ queries });
  for (let i = 0; i < ids.length; i++) {
    const { isLoading, isError, data, error } = results[i];
    resultById[ids[i]] = {
      isPending: isLoading,
      isError,
      data,
      error,
    };
  }

  return resultById;
};

/** Fetch content-related template data */
export const useTemplateContent = (id, archived) => {
  const queryClient = useQueryClient();

  const queryKey = ['template', parseInt(id), 'content', !!archived];
  const { isLoading, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => {
      return API.get(
        `/templates${archived ? '/archives' : ''}/${id}/content/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  let hydratedTemplateContent = data;
  const { isPending: isInputsFetching, data: hydratedContent } = useDynamicContentInputs(data?.content_by_slide);
  if (data && hydratedContent) {
    hydratedTemplateContent = { ...data, content_by_slide: hydratedContent };
  }

  /** Invalidate the content referred to in this hook */
  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  /** Invalidate all template content */
  const invalidateAll = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === 'template' && query.queryKey[2] === 'content',
    });
  };

  return {
    isPending: isLoading || isInputsFetching,
    isError,
    data: hydratedTemplateContent,
    error,
    invalidate,
    invalidateAll,
  };
};

export const useTemplatesUnsavedContent = (templateId, dynamicContentNames) => {
  const queryClient = useQueryClient();
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['template', parseInt(templateId), 'unsaved_content', dynamicContentNames],
    queryFn: () => {
      return API.post(
        '/templates/unsaved_content/',
        { dynamic_content_names: dynamicContentNames },
        (response) => {
          queryClient.invalidateQueries({ queryKey: ['template', parseInt(templateId), 'unsaved_content'] });
          return response.data;
        },
        (err) => err,
      );
    },
  });

  let hydratedTemplateContent = data;
  const { isPending: isInputsFetching, data: hydratedContent } = useDynamicContentInputs(data?.dynamic_content);
  if (data && hydratedContent) {
    hydratedTemplateContent = { ...data, dynamic_content: hydratedContent };
  }

  return {
    isLoading: isLoading || isInputsFetching,
    isError,
    error,
    dynamicContent: hydratedTemplateContent?.dynamic_content,
  };
};

/** Fetch content-related template data for several (unarchived) templates. If you only need a single
 * template's content, use `useTemplateContent()` instead.
 * Use this in cases where you have a variable number of IDs and `useTemplateContent(id)` would break
 * the rule of hooks.
 * @returns the { isPending, isError, data, error } result of each query keyed by ID.
 */
export const useTemplatesContent = (ids) => {
  const queries = ids.map((id) => ({
    queryKey: ['template', parseInt(id), 'content', false],
    queryFn: () => {
      return API.get(
        `/templates/${id}/content/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
  }));

  const resultById = {};

  const results = useQueries({ queries });
  for (let i = 0; i < ids.length; i++) {
    const { isLoading, isError, data, error } = results[i];
    resultById[ids[i]] = {
      isPending: isLoading,
      isError,
      data,
      error,
    };
  }

  // hydrate content parameters
  let allContent = [];
  results.forEach((result) => {
    if (result.data?.content_by_slide) {
      allContent.push(...result.data.content_by_slide);
    }
  });
  const { isPending: isInputsFetching, data: hydratedContent } = useDynamicContentInputs(allContent);
  if (hydratedContent) {
    Object.values(resultById).forEach((result) => {
      result.isPending = result.isPending || isInputsFetching;
      if (result.data) {
        result.data.content_by_slide = result.data.content_by_slide?.map((content) => {
          return hydratedContent.find((hydrated) => hydrated.id === content.id) || content;
        });
      }
    });
  }

  return resultById;
};

/** Fetch PDF-related template data */
export const useTemplatePdf = (id) => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['template', parseInt(id), 'pdf'],
    queryFn: () => {
      return API.get(
        `/templates/${id}/pdf/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

/** Fetch thumbnail-related template slide data, in case multiple unarchived templates' need to be fetched.
 * If you only need thumbnails for a single template, better to use 'useTemplateThumbnails'.
 */
export const useTemplatesThumbnails = (ids) => {
  const queries = ids.map((id) => ({
    queryKey: ['template', parseInt(id), 'slides', 'thumbnails', false],
    queryFn: () => {
      return API.get(
        `/templates/${id}/slides/thumbnail/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
  }));

  const resultById = {};

  const results = useQueries({ queries });
  for (let i = 0; i < ids.length; i++) {
    const { isLoading, isError, data, error } = results[i];
    resultById[ids[i]] = {
      isPending: isLoading,
      isError,
      data,
      error,
    };
  }

  return resultById;
};

/** Fetch thumbnail-related template slide data */
export const useTemplateThumbnails = (id, archived) => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['template', parseInt(id), 'slides', 'thumbnails', !!archived],
    queryFn: () => {
      return API.get(
        `/templates${archived ? '/archives' : ''}/${id}/slides/thumbnail/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

/** Bulk fetch the first "max" thumbnails per template */
export const useBulkTemplateThumbnails = (id, max = 1) => {
  const queryClient = useQueryClient();

  // Listen to the cache for when thumbnails are added for this specific id
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['template', parseInt(id), 'bulk_thumbnails', max],
    queryFn: () => {
      /* not called, just listening to the cache */
    },
    enabled: false,
  });

  const addIdsToBatch = useBatcher('bulk-thumbnails', (batch) => {
    const idsByMax = {};
    batch.forEach(([id, max]) => {
      if (!idsByMax[max]) {
        idsByMax[max] = new Set();
      }
      idsByMax[max].add(id);
    });

    for (const maxThumbs in idsByMax) {
      const queryPayload = { template_ids: [...idsByMax[maxThumbs]], thumbnail_count: parseInt(maxThumbs) };
      API.post(
        '/templates/bulk_thumbnails/',
        queryPayload,
        (response) => {
          for (const id in response.data) {
            // cache response per template + thumbnail_count and the useQuery() above will pick it up
            queryClient.setQueryData(
              ['template', parseInt(id), 'bulk_thumbnails', parseInt(maxThumbs)],
              response.data[id],
            );
          }
        },
        (err) => {
          // Just swallow the error since there is no way to set an error via setQueryData
          // Also best not do a noisy user alert. The thumbnails will just be stuck in "loading" state.
          console.warn('Problem fetching thumbnail batch', queryPayload, err); // eslint-disable-line no-console
        },
      );
    }
  });

  useEffect(() => {
    if (id && !data) {
      addIdsToBatch(id, max);
    }
  }, [id, max, data]);

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

/** Fetch search results (which aren't necessarily compatible with full template data entities) */
export const useTemplates = (
  offset = 0,
  limit = Constants.PAGE_SIZE,
  sort,
  preset,
  keepPreviousData, // e.g. when paginating
) => {
  if (!sort) {
    const urlParams = new URLSearchParams(window.location.search);
    const sortParameter = urlParams.get('sort');
    sort = sortParameter ? sortParameter.split(' ') : null;
  }
  if (!preset) {
    preset = Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
  }
  if (!(offset > 0)) {
    offset = 0;
  }

  let baseUrl =
    preset === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates ? '/templates/' : `/templates/presets/${preset}`;
  let fullUrl = API.generate_paginated_url(baseUrl, offset, limit, sort, null);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery({
    queryKey: ['templates', preset, offset, limit, sort],
    queryFn: () => {
      return API.get(
        fullUrl + '&view=card',
        (response) => {
          return {
            count: API.getCountFromResponse(response),
            elements: response.data,
          };
        },
        (err) => {
          throw err;
        },
      );
    },
    cacheTime: 0,
    keepPreviousData,
  });

  const pagination = data ? utils.getPaginationFromRequest(data?.count, limit, offset, sort) : undefined;
  return {
    isPending: isLoading,
    isPreviousData,
    isError,
    count: data?.count,
    elements: data?.elements,
    pagination,
    error,
    refetch,
  };
};

export const useTemplateLoopMutator = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const upsert = (data, loop = null) => {
    if (loop) {
      API.track('update_slide_loop', { data: data });
      return API.put(
        `/slide_loops/${loop.id}/`,
        data,
        (response) => {
          dispatch(addNotificationSuccess('Slide Loop updated'));
          queryClient.invalidateQueries({ queryKey: ['template', parseInt(response.data.updated_loop.template_id)] });
          return response.data;
        },
        (err) => {
          throw err;
        },
      );
    } else {
      API.track('add_slide_loop', { data: data });
      return API.post(
        '/slide_loops/',
        data,
        (response) => {
          dispatch(addNotificationSuccess('Slide Loop added'));
          queryClient.invalidateQueries({ queryKey: ['template', parseInt(response.data.added_loop.template_id)] });
          return response.data.added_loop;
        },
        (err) => {
          throw err;
        },
      );
    }
  };

  const del = (loop) => {
    return API.delete(
      `/slide_loops/${loop.id}/`,
      () => {
        dispatch(addNotificationSuccess('Slide Loop deleted'));
        queryClient.invalidateQueries({ queryKey: ['template', parseInt(loop.template_id)] });
      },
      (err) => {
        throw err;
      },
    );
  };

  return {
    upsert,
    del,
  };
};

export const useTemplateResyncStatus = (template) => {
  const getResyncStatus = async (templateId, archived) => {
    // Trigger the resync check
    const statusId = await API.get(
      archived ? `/templates/archives/${templateId}/resync_status/` : `/templates/${templateId}/resync_status/`,
      undefined,
      (err) => {
        throw err;
      },
    );

    // Check the status of the check and wait for the result
    const needsResync = await new Promise((resolve, reject) => {
      const long_request = new LongRequest('/templates/resync_status');
      long_request.getStatus(
        statusId.data.check_status_info,
        (response, onComplete) => {
          if (response.data.status === 'done') {
            onComplete();
            resolve(response.data.result);
          } else if (response.data.status === 'error') {
            onComplete();
            reject();
          }
        },
        (e) => reject(e),
      );
    });

    return needsResync;
  };

  const id = template?.id;
  const archived = template?.deleted;

  const { updateTemplateError } = useFlags();

  const needsResyncCheck =
    updateTemplateError &&
    id &&
    (template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
      template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365);

  const queryKey = ['template', parseInt(id), 'resync_status'];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getResyncStatus(id, archived);
    },
    enabled: !!needsResyncCheck,
  });

  // invalidating the status will trigger a new resync status check and reset the "show warning" setting to true
  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.setQueryData(showWarningKey, true);
  };

  const showWarningKey = [...queryKey, 'show_warning'];
  const hideWarning = () => {
    queryClient.setQueryData(showWarningKey, false);
  };
  const { data: isWarningShown } = useQuery({
    queryKey: showWarningKey,
    queryFn: () => {
      // not used, just listening to the cache
    },
    initialData: () => true,
    enabled: false,
  });

  const result = {
    isPending: isLoading,
    isError,
    data,
    error,
    /** force a new resync status check */
    invalidate,
    isWarningShown,
    hideWarning,
  };

  // On templates that don't need to be resync'd, we always return "false"
  if (!needsResyncCheck) {
    result.data = false;
  }

  return result;
};

export const useTemplateLinkedObjectStatus = (template) => {
  const getLinkedObjectStatus = async (templateId, archived) => {
    // Trigger the linked object permissions check
    const statusId = await API.get(
      archived
        ? `/templates/archives/${templateId}/linked_objects_status/`
        : `/templates/${templateId}/linked_objects_status/`,
      undefined,
      (err) => {
        throw err;
      },
    );

    // Check the status of the check and wait for the result
    const needsLinkedObjectsUpdate = await new Promise((resolve, reject) => {
      if (!statusId) {
        reject();
        return;
      }

      const long_request = new LongRequest('/templates/linked_objects_status');
      long_request.getStatus(
        statusId.data.check_status_info,
        (response, onComplete) => {
          if (response.data.status === 'done') {
            onComplete();
            resolve(response.data.result);
          } else if (response.data.status === 'error') {
            onComplete();
            reject();
          }
        },
        (e) => reject(e),
      );
    });

    return needsLinkedObjectsUpdate;
  };

  const id = template?.id;
  const archived = template?.deleted;

  const { updateDataSourceError } = useFlags();

  const needsCheck = updateDataSourceError && id;

  const queryKey = ['template', parseInt(id), 'linked_objects_status'];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return getLinkedObjectStatus(id, archived);
    },
    enabled: !!needsCheck,
  });

  // invalidating the status will trigger a new resync status check and reset the "show warning" setting to true
  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.setQueryData(showWarningKey, true);
  };

  const showWarningKey = [...queryKey, 'show_warning'];
  const hideWarning = () => {
    queryClient.setQueryData(showWarningKey, false);
  };
  const { data: isWarningShown } = useQuery({
    queryKey: showWarningKey,
    queryFn: () => {
      // not used, just listening to the cache
    },
    initialData: () => true,
    enabled: false,
  });

  return {
    isPending: isLoading,
    isError,
    /** e.g. { should_update: true, linked_objects: [] } */
    data,
    error,
    /** force a new resync status check */
    invalidate,
    isWarningShown,
    hideWarning,
  };
};

/** Get the ID for all the templates flagged as favorites */
export const useTemplateFavorites = () => {
  const queryClient = useQueryClient();

  const queryKey = ['template_favorites'];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        '/item_favorites/templates/',
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
  });

  const update = (template, isFavorite) => {
    // Optimistically cache the update so we see the result immediately.
    const cached = queryClient.getQueryData(queryKey);
    if (cached) {
      let optimistic;
      if (isFavorite) {
        optimistic = [...cached, template.id];
      } else {
        optimistic = cached.filter((id) => id !== template.id);
      }
      queryClient.setQueryData(queryKey, optimistic);
    }

    const errorHandler = (err) => {
      utils.notify('Error updating Favorites', toast.TYPE.ERROR);
      // rollback optimistic update
      if (cached) {
        queryClient.setQueryData(queryKey, cached);
      }
      throw err;
    };

    if (isFavorite) {
      return API.post(
        `/item_favorites/templates/${template.id}/`,
        null,
        () => {
          utils.notify(`${template.name} added to Favorites.`);

          queryClient.invalidateQueries({ queryKey: queryKey });
          queryClient.invalidateQueries({
            queryKey: ['templates', Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites],
          });
        },
        errorHandler,
      );
    } else {
      return API.delete(
        `/item_favorites/templates/${template.id}/`,
        () => {
          utils.notify(`${template.name} removed from Favorites.`);

          queryClient.invalidateQueries({ queryKey: queryKey });
          queryClient.invalidateQueries({
            queryKey: ['templates', Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites],
          });
        },
        errorHandler,
      );
    }
  };

  return {
    isPending: isLoading,
    isError,
    data,
    error,
    update,
  };
};
