import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ChevronRight } from '../../svg/chevron_right.svg';
import Icon from '../lib/Icon';

const Pagination = ({ pages, page = -1, onPageChange }) => {
  const Page = ({ index }) => {
    return (
      <button
        type="button"
        onClick={() => onPageChange(index)}
        disabled={index === page}
        className={`button is-secondary square small phn ${index === page ? 'is-selected' : ''}`}
      >
        {index + 1}
      </button>
    );
  };
  Page.propTypes = {
    index: PropTypes.number,
  };

  const Spacer = () => <div className="ptxs">…</div>;

  // 7 total "slots" available for either page numbers or "..." spacers.
  let pageButtons;

  if (pages > 0) {
    let leadingSpacer = false;
    let trailingSpacer = false;

    if (pages > 7) {
      // more pages than slots, so we'll need at least one spacer, depending on current selected page
      leadingSpacer = page >= 4;
      trailingSpacer = page < pages - 4;
    }

    pageButtons = [];
    if (!leadingSpacer && !trailingSpacer) {
      // no spacers, all pages.

      for (let i = 0; i < pages; i++) {
        pageButtons.push(<Page key={i} index={i} />);
      }
    } else if (!leadingSpacer && trailingSpacer) {
      // only trailing spacer: first 5 pages, spacer, last page

      for (let i = 0; i < 5; i++) {
        pageButtons.push(<Page key={i} index={i} />);
      }
      pageButtons[5] = <Spacer key="trail" />;
      pageButtons[6] = <Page key={pages - 1} index={pages - 1} />;
    } else if (leadingSpacer && !trailingSpacer) {
      // only leading spacer: first page, spacer, last 5 pages.

      pageButtons[0] = <Page key={0} index={0} />;
      pageButtons[1] = <Spacer key="trail" />;
      for (let i = pages - 5; i < pages; i++) {
        pageButtons.push(<Page key={i} index={i} />);
      }
    } else {
      // both leading and trailing spacer: first page, spacer, 3 pages relative to selected page, spacer, last page

      pageButtons[0] = <Page key={0} index={0} />;
      pageButtons[1] = <Spacer key="lead" />;
      for (let i = page - 1; i <= page + 1; i++) {
        pageButtons.push(<Page key={i} index={i} />);
      }
      pageButtons[5] = <Spacer key="trail" />;
      pageButtons[6] = <Page key={pages - 1} index={pages - 1} />;
    }
  }

  return (
    <div className="is-flex is-justify-content-space-between gap-small">
      <button
        type="button"
        disabled={!(pages > 0) || !(page > 0)}
        onClick={() => onPageChange(page - 1)}
        className="button is-secondary square small phn mrm"
      >
        <Icon name="chevron_left" size={16} theme="regular" />
      </button>

      {pageButtons && <div className="is-flex gap-small">{pageButtons}</div>}

      <button
        type="button"
        disabled={!(pages > 0) || page + 1 >= pages}
        onClick={() => onPageChange(page + 1)}
        className="button is-secondary square small phn mlm"
      >
        <ChevronRight />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  /** total number of pages */
  pages: PropTypes.number,
  /** selected page number (0 based) */
  page: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
