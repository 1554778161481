import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import { cloneDeep, isEqual } from 'lodash';
import Constants from '../../../Constants';
import utils from '../../../../lib/utils';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import SalesforceFilterButtons from './SalesforceFilterButtons';
import info_icon from '../../../../images/info_icon.svg';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import dataSources from '../../../../lib/dataSources';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import CRMReturnFields from './CRMReturnFields';
import InputMapping from '../InputMapping';
import inputs from '../../../../lib/inputs';

class GainsightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnFields: [],
      showFormFields: false,
      currentlyRenamingField: null,
      displayNameUpdate: false,
    };
  }

  componentDidMount() {
    if (
      this.props.queryObj &&
      this.props.apiInfo.objects &&
      this.props.queryObj.selectedObject &&
      this.props.apiInfo.objects[this.props.queryObj.selectedObject]
    ) {
      if (this.props.apiInfo.objects[this.props.queryObj.selectedObject].fields) {
        this.setState({ showFormFields: true });
      } else {
        this.getObjectFields(this.props.queryObj.selectedObject);
      }
      const returnFields = dataSources.buildCRMReturnFields(
        this.props.apiInfo,
        'objects',
        this.props.queryObj.selectedObject,
        'fields',
      );
      this.setState({ returnFields });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.queryObj?.selectedObject, this.props.queryObj?.selectedObject) ||
      !isEqual(prevProps.apiInfo, this.props.apiInfo)
    ) {
      if (
        this.props.queryObj &&
        this.props.apiInfo.objects &&
        this.props.queryObj.selectedObject &&
        this.props.apiInfo.objects[this.props.queryObj.selectedObject]
      ) {
        if (this.props.apiInfo.objects[this.props.queryObj.selectedObject].fields) {
          this.setState({ showFormFields: true });
          const returnFields = dataSources.buildCRMReturnFields(
            this.props.apiInfo,
            'objects',
            this.props.queryObj.selectedObject,
            'fields',
          );
          this.setState({ returnFields });
        } else {
          this.getObjectFields(this.props.queryObj.selectedObject);
        }
      }
    }
  }

  render() {
    const objectOptions = this.props.apiInfo.objects
      ? Object.keys(this.props.apiInfo.objects).map((objectName) => ({
          value: objectName,
          label: this.props.apiInfo.objects[objectName].label,
        }))
      : [];

    let objectValue = null;

    if (
      this.props.queryObj &&
      this.props.queryObj.selectedObject &&
      Object.keys(this.props.apiInfo).length &&
      this.props.apiInfo.objects[this.props.queryObj.selectedObject]
    ) {
      objectValue = {
        label: this.props.apiInfo.objects[this.props.queryObj.selectedObject].label,
        value: this.props.queryObj.selectedObject,
      };
    }
    const returnFieldsArray = this.props.queryObj.returnFieldsByName
      ? Object.keys(this.props.queryObj?.returnFieldsByName).filter(
          (field) => this.props.queryObj.returnFieldsByName[field],
        )
      : [];
    const selectedObjFields = this.props.apiInfo?.objects?.[this.props.queryObj.selectedObject]?.['fields'] || [];
    const objectFields =
      selectedObjFields
        .map((field) => {
          if (field.dataType !== 'LOOKUP') {
            return field.fieldName;
          }
        })
        .filter((x) => x) || [];

    const lookupFieldObjects = selectedObjFields.map((field) => field.lookupFields || []).flat();
    const lookupFields = lookupFieldObjects?.map((fieldObject) => fieldObject.label) || [];
    const nonLookupfields = selectedObjFields.map((field) => field.label || '') || [];
    const allFields = [...lookupFields, ...nonLookupfields];

    let newReturnFieldMapping = {};
    let oldReturnFieldMapping = this.props.queryObj?.returnFieldMapping || {};
    for (let key in oldReturnFieldMapping) {
      newReturnFieldMapping[key] = oldReturnFieldMapping[key].displayName || '';
    }

    const returnFieldMapping = this.props.queryObj?.return_field_mapping || newReturnFieldMapping || {};

    const adjustOrderbyField = (returnFields, queryObj) => {
      if (!returnFields.includes(queryObj.orderByField) && returnFields.length > 0) {
        return dataSources.createReturnFieldLabel(
          this.props.queryObj,
          returnFields[0],
          this.state.returnFields,
          this.toggleRenameModal,
        );
      }
      return { value: queryObj.orderByField, label: queryObj.orderByFieldLabel };
    };

    const onReturnFieldAdd = (addedOption) => {
      const updatedQueryObj = cloneDeep(this.props.queryObj);
      updatedQueryObj.returnFieldsByName[addedOption.name] = true;

      const updatedReturnFieldsArray = [...returnFieldsArray, addedOption.name];
      updatedQueryObj.fields = updatedReturnFieldsArray;

      const orderByFieldInfo = adjustOrderbyField(updatedReturnFieldsArray, updatedQueryObj);
      updatedQueryObj.orderByField = orderByFieldInfo.value || '';
      updatedQueryObj.orderByFieldLabel = orderByFieldInfo.label || '';
      // check if the newly added return field is the sort by field or not
      updatedQueryObj.unselectedOrderField = !updatedQueryObj.returnFieldsByName[updatedQueryObj.orderByField];

      this.context.onQueryObjectUpdate(updatedQueryObj);
    };

    const onReturnFieldSelectAll = (options) => {
      const returnFields = options.map((fieldOption) => fieldOption.value);
      const updatedQueryObj = cloneDeep(this.props.queryObj);
      updatedQueryObj.returnFieldsByName = Object.fromEntries(returnFields.map((fieldName) => [fieldName, true]));
      updatedQueryObj.fields = returnFields;
      this.context.onQueryObjectUpdate(updatedQueryObj);
    };

    const onReturnFieldClear = () => {
      const updatedQueryObj = cloneDeep(this.props.queryObj);
      updatedQueryObj.fields = [];
      updatedQueryObj.returnFieldsByName = {};
      this.context.onQueryObjectUpdate(updatedQueryObj);
    };

    const onReturnFieldsMappingUpdate = (returnField, newAlias) => {
      const updatedQueryObj = cloneDeep(this.props.queryObj);
      if (newAlias === '') {
        delete updatedQueryObj.return_field_mapping[returnField];
      } else {
        updatedQueryObj.return_field_mapping = { ...returnFieldMapping, [returnField]: newAlias };
      }

      const inputMapping = this.props.inputMapping || {};
      const newInputMapping = inputs.updateInputMappingWithAlias(
        inputMapping,
        this.props.queryObj?.return_field_mapping,
        returnField,
        newAlias,
      );

      if (!isEqual(inputMapping, newInputMapping)) {
        this.props.onInputMappingUpdate(newInputMapping);
      }
      this.context.onQueryObjectUpdate(updatedQueryObj);
    };

    const onReturnFieldRemove = (removedOption) => {
      const updatedQueryObj = cloneDeep(this.props.queryObj);
      delete updatedQueryObj.returnFieldsByName[removedOption.value];

      const updatedReturnFieldsArray = returnFieldsArray.filter((removedValue) => removedValue !== removedOption.value);
      updatedQueryObj.fields = updatedReturnFieldsArray;

      const orderByFieldInfo = adjustOrderbyField(updatedReturnFieldsArray, updatedQueryObj);
      updatedQueryObj.orderByField = orderByFieldInfo.value || '';
      updatedQueryObj.orderByFieldLabel = orderByFieldInfo.label || '';
      updatedQueryObj.unselectedOrderField = !updatedQueryObj.returnFieldsByName[updatedQueryObj.orderByField];

      if (this.props.inputMapping?.[removedOption.displayName || removedOption.value]) {
        this.props.onInputMappingUpdate({});
      }
      this.context.onQueryObjectUpdate(updatedQueryObj);
    };

    const inputMappingReturnFields = returnFieldsArray
      .filter((returnField) => this.state.returnFields[returnField])
      .map((returnField) => {
        const field = this.state.returnFields[returnField];
        const name = field.name || returnField;
        const displayName = returnFieldMapping[name] || '';
        return {
          label: field.label || name,
          value: name,
          displayName: displayName,
        };
      });

    return (
      <>
        <Form.Field className="mbl">
          <Form.Label>Object</Form.Label>
          <Form.Help>Select Gainsight object to return</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Gainsight Object"
              classNamePrefix="matik-select"
              className="matik-select-container"
              isDisabled={this.context.isReadOnly}
              value={objectValue}
              name="selectedObject"
              onChange={(obj, action) => this.selectGainsightObject(obj, action)}
              placeholder="Select Gainsight Object"
              options={objectOptions}
              menuPortalTarget={this.props.formRef}
            ></Select>
          </Form.Control>
          {this.props.queryObj &&
            this.props.queryObj.selectedObject &&
            this.props.apiInfo.objects &&
            !this.props.apiInfo.objects[this.props.queryObj.selectedObject] && (
              <Form.Help color="danger">
                Selected Object &quot;{this.props.queryObj.selectedObject}&quot; missing in Gainsight
              </Form.Help>
            )}
        </Form.Field>
        {this.state.showFormFields && (
          <React.Fragment>
            <CRMReturnFields
              returnFields={returnFieldsArray}
              allFields={allFields}
              fieldGroups={this.returnFieldGroups(false)}
              objectFields={objectFields}
              returnFieldMapping={returnFieldMapping}
              removeToolTip="Remove this field entirely."
              dataSourceName="Gainsight"
              canCreateReturnField={false}
              onReturnFieldAdd={onReturnFieldAdd}
              onReturnFieldSelectAll={onReturnFieldSelectAll}
              onReturnFieldClear={onReturnFieldClear}
              onReturnFieldMappingUpdate={onReturnFieldsMappingUpdate}
              onReturnFieldRemove={onReturnFieldRemove}
            />
            {this.props.onInputMappingUpdate && (
              <InputMapping
                input={this.props.input}
                inputMapping={this.props.inputMapping}
                onInputMappingUpdate={this.props.onInputMappingUpdate}
                returnFieldsArray={inputMappingReturnFields}
              />
            )}
            {this.renderFilters()}
            {this.renderOrderLimit()}
          </React.Fragment>
        )}
      </>
    );
  }

  selectGainsightObject = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj.selectedObject = value;
      updatedQueryObj.returnFieldsByName = {};
      updatedQueryObj.filters = [];
      if (
        this.props.apiInfo &&
        this.props.apiInfo.objects &&
        this.props.apiInfo.objects[value] &&
        this.props.apiInfo.objects[value]['fields']
      ) {
        let orderByField = this.props.apiInfo.objects[value]['fields'][0];
        updatedQueryObj.orderByField = orderByField.fieldName;
        updatedQueryObj.orderByFieldLabel = orderByField.label;
        updatedQueryObj.orderByDirection = 'asc';
        updatedQueryObj.orderByDirectionLabel = 'ASC';
      }

      this.context.onQueryObjectUpdate(updatedQueryObj);
      if (!this.props.apiInfo.objects[updatedQueryObj.selectedObject]['fields']) {
        this.getObjectFields(updatedQueryObj.selectedObject);
      }
    }
  };

  getObjectFields = (selectedObject) => {
    this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'objects', selectedObject, 'fields');
  };

  defaultSortField() {
    let orderByField;
    this.props.apiInfo.objects[this.props.queryObj.selectedObject]['fields'].forEach((field) => {
      return (orderByField = field);
    });
    return { label: orderByField.label, value: orderByField.fieldName };
  }

  renderOrderLimit = () => {
    const objectFields = this.props.apiInfo?.objects?.[this.props.queryObj.selectedObject]?.['fields'] || [];
    const orderFieldsArray = objectFields.map((field) => {
      return { value: field.fieldName, label: field.label };
    });
    orderFieldsArray.forEach((field) => {
      if (field.dataType === 'PICKLIST') {
        field.isDisabled = true;
      }
    });
    return (
      <React.Fragment>
        <Form.Label>Result Order</Form.Label>
        <Form.Help>
          Select Gainsight field to order by.
          <span
            className="mls order-hint-tooltip"
            data-tooltip-id="matik-tooltip"
            data-tooltip-html="Ordering by Picklist fields is not supported by the Gainsight API<br/>Ordering on these fields will result in default ordering."
            data-tooltip-place="right"
          >
            <img src={info_icon} alt="Order By Info" width="14px" className="icon-pull-down" />
          </span>
        </Form.Help>
        <Form.Control>
          <Form.Field className="mbl" kind="group">
            <Form.Control style={{ flex: '3 1' }}>
              <Select
                value={
                  this.props.queryObj.orderByField
                    ? { label: this.props.queryObj.orderByFieldLabel, value: this.props.queryObj.orderByField }
                    : orderFieldsArray.length > 0
                    ? orderFieldsArray[0]
                    : this.defaultSortField()
                }
                classNamePrefix="matik-select"
                onChange={(obj, action) => this.onSortChange(obj, action)}
                isDisabled={this.context.isReadOnly}
                options={orderFieldsArray}
                menuPortalTarget={this.props.formRef}
                aria-label="Select Gainsight field to order by"
              />
            </Form.Control>
            <Form.Control style={{ flex: '1 1' }}>
              <Select
                aria-label="Select Direction to Order By"
                classNamePrefix="matik-select"
                isDisabled={this.context.isReadOnly}
                value={
                  this.props.queryObj.orderByDirection
                    ? { label: this.props.queryObj.orderByDirectionLabel, value: this.props.queryObj.orderByDirection }
                    : { label: 'ASC', value: 'asc' }
                }
                onChange={(obj, action) => this.onSortDirectionChange(obj, action)}
                options={[
                  { label: 'ASC', value: 'asc' },
                  { label: 'DESC', value: 'desc' },
                ]}
                menuPortalTarget={this.props.formRef}
              />
            </Form.Control>
          </Form.Field>
        </Form.Control>

        <Form.Field className="mbl">
          <Form.Label>Result Limit</Form.Label>
          <Form.Help>Maximum number of results to return (leave blank for no limit)</Form.Help>
          <Form.Control>
            <Form.Input
              type="text"
              value={this.props.queryObj.limit}
              onChange={this.onLimitChange}
              name="limit"
              style={{ maxWidth: '50px' }}
              onKeyPress={utils.preventSubmit}
              aria-label="Gainsight limit"
              disabled={this.context.isReadOnly}
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  renderFilters = () => {
    const queryFilters = this.props.queryObj.filters || [];
    let body = (
      <a href="#dummy" onClick={(e) => this.addFilter(e, 'EQ')}>
        Add Filter
      </a>
    );
    if (queryFilters.length > 0) {
      const supportedOperators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.gainsight;
      body = queryFilters.map((filter, idx) => {
        const options = this.returnFieldGroups(true);

        const objectFilter = (
          <Form.Control style={{ flex: '3 1' }}>
            <Select
              aria-label="Select Filter Gainsight Field"
              placeholder="Select Gainsight fields to filter..."
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={filter['field'] && filter['label'] ? { value: filter['field'], label: filter['label'] } : null}
              name="filterObject"
              onChange={(obj, action) => this.onFilterFieldChange(obj, action, idx)}
              options={options}
              menuPortalTarget={this.props.formRef}
            ></Select>
          </Form.Control>
        );

        const operators = [];
        for (let key in supportedOperators) {
          operators.push({ label: key, value: supportedOperators[key] });
        }

        return (
          <Form.Field kind="group" key={idx}>
            {objectFilter}
            <Form.Control style={{ flex: '1 1' }}>
              <Select
                value={{ label: filter['operatorLabel'] || 'equals', value: filter['operator'] || 'EQ' }}
                onChange={(obj, action) => this.onOperatorChange(obj, action, idx)}
                classNamePrefix="matik-select"
                isDisabled={this.context.isReadOnly}
                options={operators}
                menuPortalTarget={this.props.formRef}
              />
            </Form.Control>
            <Form.Control style={{ flex: '2 1' }}>
              <InputWithOptionalInputs
                onChange={(newValue) => this.onFilterInputChange(idx, newValue)}
                value={filter['value']}
                inputs={this.context.existingInputs}
                isReadOnly={this.context.isReadOnly}
                isInputPopoverDisabled={this.props.isInputPopoverDisabled}
              />
            </Form.Control>
            <SalesforceFilterButtons
              queryObj={this.props.queryObj}
              addFilter={this.addFilter}
              removeFilter={this.removeFilter}
              isReadOnly={this.context.isReadOnly}
              idx={idx}
            />
          </Form.Field>
        );
      });
    }
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Filters</Form.Label>
          <Form.Help>
            Select fields and inputs to filter by.
            <span
              className="mls filter-hint-tooltip"
              data-tooltip-id="matik-tooltip"
              data-tooltip-html="Hint: Create & insert inputs using &:&lt;input_name&gt;"
              data-tooltip-place="right"
            >
              <img src={info_icon} alt="Filter Info" width="14px" className="icon-pull-down" />
            </span>
          </Form.Help>
          <Form.Control>{body}</Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  onSortChange = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const orderByFieldLabel = obj.value.includes('.') ? obj.value : obj.label;
      updatedQueryObj.orderByField = obj.isDisabled ? null : obj.value;
      updatedQueryObj.orderByFieldLabel = obj.isDisabled ? null : orderByFieldLabel;
      updatedQueryObj.unselectedOrderField = obj.isDisabled ? null : !updatedQueryObj.returnFieldsByName[obj.value];
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  onSortDirectionChange = (obj) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.orderByDirection = obj.value;
    updatedQueryObj.orderByDirectionLabel = obj.label;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onLimitChange = (e) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const name = e.target.name;
    updatedQueryObj[name] = e.target.value;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  addFilter = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filters = updatedQueryObj.filters || [];
    filters.push({ field: '', value: '', type: 'string', operator: 'EQ' });
    updatedQueryObj.filters = filters;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  removeFilter = (e, idx) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filters = [];
    const queryFilters = updatedQueryObj.filters || [];
    for (let i = 0; i < queryFilters.length; i++) {
      if (i !== idx) {
        filters.push(updatedQueryObj.filters[i]);
      }
    }
    updatedQueryObj.filters = filters;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onFilterFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const filterField = obj.value;
      // Need special case for field names with '_' character in them
      const filterFieldLabel = obj.value.includes('.') ? obj.value : obj.label;
      const filterType = obj.type || filterField[filterField.length - 1];
      const filters = updatedQueryObj.filters || [];
      if (idx === filters.length) {
        filters.push({ field: filterField, value: '', type: filterType, label: filterFieldLabel });
      } else {
        filters[idx].field = filterField;
        filters[idx].type = filterType;
        filters[idx].label = filterFieldLabel;
      }
      updatedQueryObj.filters = filters;
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  onFilterInputChange = (idx, value) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filters = updatedQueryObj.filters || [];
    if (idx === filters.length) {
      filters.push({ field: '', value: value });
    } else {
      filters[idx].value = value;
    }

    updatedQueryObj.filters = filters;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onOperatorChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const filters = updatedQueryObj.filters;
      filters[idx].operator = obj.value;
      filters[idx].operatorLabel = obj.label;

      updatedQueryObj.filters = filters;
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  toggleRenameModal = (e, field) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ currentlyRenamingField: field });
    if (this.props.ui?.modal?.name === 'renamableModal') {
      this.props.closeModal();
    } else {
      this.props.openModal?.('renamableModal');
    }
  };

  closeRenamableModal = () => {
    this.setState({ currentlyRenamingField: null });
    this.props.closeModal();
  };

  returnFieldGroups = (forFilter) => {
    const objectFields = this.props.apiInfo?.objects?.[this.props.queryObj.selectedObject]?.['fields'] || [];

    const fieldGroups = [];
    const fieldOptions = [];

    // Loop through object's fields
    objectFields.forEach((field) => {
      // If field has Lookup Fields
      if (field.lookupFields) {
        // Lookup field is a Gainsight join, get its fields and create options
        const lookupOptions = [];
        field.lookupFields.forEach((lookupField) => {
          // Cannot filter on Lookup fields
          if (!forFilter || (forFilter && lookupField.datatype !== 'LOOKUP')) {
            lookupOptions.push({
              label: lookupField.label,
              type: lookupField.dataType,
              value: `${field.fieldName}.${lookupField.fieldName}`,
            });
          }
        });
        // Add the grouped Lookup Field options to fieldGroups
        fieldGroups.push({ type: 'group', label: field.label, options: lookupOptions });
      } else {
        // Return option for top level field group
        fieldOptions.push({ label: field.label, type: field.dataType, value: field.fieldName });
      }
    });

    // Insert the top level field group with populated fieldOptions
    fieldGroups.unshift({
      type: 'group',
      label: this.props.apiInfo?.objects[this.props.queryObj?.selectedObject]?.label || '',
      options: fieldOptions,
    });
    return fieldGroups;
  };
}

GainsightForm.contextType = DynamicContentContext;

GainsightForm.propTypes = {
  apiInfo: PropTypes.object,
  selectedObject: PropTypes.string,
  queryObj: PropTypes.object,
  input: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  inputMapping: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  isInputPopoverDisabled: PropTypes.bool,
  formRef: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(GainsightForm);
