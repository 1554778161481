import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BooleanInputField extends Component {
  render() {
    const inputValue = this.props.inputValues[this.props.input.name];
    return (
      <div className="control">
        <label className="radio radio-add-padding" color={this.props.inputColor}>
          <input
            type="radio"
            data-name={this.props.input.name}
            value={true}
            checked={!!inputValue.value}
            onChange={this.onChange}
            disabled={this.props.isReadOnly}
          />
          TRUE
        </label>
        <label className="radio radio-add-padding" color={this.props.inputColor}>
          <input
            type="radio"
            data-name={this.props.input.name}
            value={false}
            checked={!inputValue.value}
            onChange={this.onChange}
            disabled={this.props.isReadOnly}
          />
          FALSE
        </label>
      </div>
    );
  }
  onChange = (e) => {
    let value = e.target.value;
    if (e.target.type === 'radio') {
      value = value === 'true';
    }
    const name = e.target.getAttribute('data-name');
    this.props.onChange(name, { [name]: { value, error: '' } });
  };
}

BooleanInputField.propTypes = {
  input: PropTypes.object,
  inputColor: PropTypes.string,
  isReadOnly: PropTypes.bool,
  inputValues: PropTypes.object,
  onChange: PropTypes.func,
};

export default BooleanInputField;
