import React from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import AuthTabs from '../AuthTabs';

function ProductboardAuth({ auth, centered, checkboxes, onAuthChange }) {
  const authToken = auth.auth_token ?? '';
  const tab = { name: 'login-tab', title: 'Access via Token' };
  const checkbox = checkboxes?.[1];
  return (
    <>
      <AuthTabs activeAuthTab={tab.name} tabs={[tab]} setActiveAuthTab={() => {}} centered={centered} />
      <Box className="data-source-box">
        {checkbox}
        <Form.Field>
          <Form.Label>Productboard Auth Token</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="The auth token in the integrations tab of your productboard instance"
              type="password"
              id="auth-token"
              name="auth_token"
              onChange={onAuthChange}
              value={authToken}
            />
          </Form.Control>
        </Form.Field>
      </Box>
    </>
  );
}

ProductboardAuth.propTypes = {
  auth: PropTypes.object.isRequired,
  centered: PropTypes.bool,
  checkboxes: PropTypes.array,
  onAuthChange: PropTypes.func.isRequired,
};

export default ProductboardAuth;
