import React from 'react';

export const EndUserCreateContext = React.createContext({
  addedSlides: null,
  addSlideFromTemplateToCurrentTemplate: () => {},
  highlightedSlideNums: null,
  highlightSlideNums: () => {},
  removeSlideFromTemplate: () => {},
  selectedSlidesBySlideNum: {},
  toggleAllSlides: () => {},
  toggleSlideNumSelect: () => {},
});
