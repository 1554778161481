import React, { useEffect, useState } from 'react';
import AuthPage from './AuthPage';
import { Card, Heading } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import matik_logo_black from '../../images/matik_logo_black.svg';

const ERROR_MESSAGE_MAPPING = {
  response_invalid: 'Could not log in: SAML response invalid',
  user_not_found: 'Could not log in: user not found',
  not_initialized: 'Could not log in: SSO not initialized',
  lifetime_exceeded: 'Could not log in: Response lifetime exceeded',
  metadata_incorrect: 'Could not log in: Metadata incorrect',
};

function SsoError() {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (window.location.search) {
      const query_obj = new URLSearchParams(window.location.search);
      const errorMessage = ERROR_MESSAGE_MAPPING[query_obj.get('errorMessage')]
        ? ERROR_MESSAGE_MAPPING[query_obj.get('errorMessage')]
        : 'Something unexpected went wrong';
      setErrorMessage(errorMessage);
    }
  }, []);
  return (
    <AuthPage>
      <div className="auth-logo">
        <img src={matik_logo_black} alt="matik logo" height="40px" />
      </div>
      <Card className="auth-card">
        <div className="has-text-centered">
          <Heading size={3} className="mvl">
            {errorMessage}
          </Heading>
        </div>
        <div className="has-text-centered mbm">
          <Link to="/login" className="button is-primary is-large-button">
            Back to login
          </Link>
        </div>
      </Card>
    </AuthPage>
  );
}

export default SsoError;
