import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import utils from '../../../../lib/utils';
import { Select } from '../../../shared/FormSelect';
import RenamableModal from '../RenamableModal';
import CRMObjectsForm from './CRMObjectsForm';
import SalesforceReportsForm from './SalesforceReportsForm';
import SalesforceFilterButtons from './SalesforceFilterButtons';
import { capitalize } from 'lodash';
import Constants from 'components/Constants';
import { closeModal, openModal } from 'redux/ui/action';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import UniqueValuesFilter from '../UniqueValuesFilter';

function CRMForm({
  apiInfo,
  dataSource,
  entityType,
  fetchApiInfoIfNeeded,
  input,
  inputMapping,
  inputMappingOptions,
  onInputMappingUpdate,
  queryObj,
  refreshReport,
  updateTestResult,
  isInputPopoverDisabled,
  formRef,
  reportCount,
  objectCount,
  updateInputFilterDuplicates,
}) {
  const [currentlyRenamingField, setCurrentlyRenamingField] = useState(null);
  const [displayNameUpdate, setDisplayNameUpdate] = useState(false);
  const [filterDuplicates, setFilterDuplicates] = useState(
    queryObj.filterDuplicates ? queryObj.filterDuplicates : false,
  );
  const [resultLimit, setResultLimit] = useState('');

  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const dynamicContentContext = useContext(DynamicContentContext);

  useEffect(() => {
    if (queryObj?.limit) {
      setResultLimit(queryObj.limit);
    }
  }, []);

  let sourceVal = null;
  if (queryObj.source) {
    sourceVal = { label: utils.capitalizeString(queryObj.source), value: queryObj.source };
  }
  let dataSourceType = '';
  if (dataSource && dataSource.type) {
    dataSourceType = dataSource.type;
  }
  let options = [];
  if (dataSourceType === Constants.DATA_SOURCE_TYPES.salesforce) {
    options = [
      { value: 'objects', label: 'Objects' },
      { value: 'reports', label: 'Reports' },
    ];
  } else if (dataSourceType === Constants.DATA_SOURCE_TYPES.hubspot) {
    options = [{ value: 'objects', label: 'Objects' }];
  }

  const renderFilter = (
    idx,
    queryObj,
    fieldType,
    filter,
    supportedOperators,
    objectFilter,
    defaultFilterField,
    filterFieldOptions,
    isReadOnly,
    inputs,
  ) => {
    let filterField = filter['field'];
    if (fieldType === 'columns' || dataSource?.type === Constants.DATA_SOURCE_TYPES.salesforce) {
      filterField = filter['label'];
    }

    if (dataSource?.type === Constants.DATA_SOURCE_TYPES.hubspot && queryObj.selectedObject === 'owners') {
      let options = filterFieldOptions.filter((option) => {
        if (option.value === 'email_string' || option.value === 'userId_number' || option.value === 'id_string') {
          return option;
        }
      });

      let operators = supportedOperators.filter((operator) => {
        if (operator.value === 'equal') {
          return operator;
        }
      });

      filterFieldOptions = options;
      defaultFilterField = filterFieldOptions?.[0];
      supportedOperators = operators;
    }
    return (
      <Form.Field kind="group" key={idx}>
        {objectFilter}
        <Form.Control style={{ flex: '3 1' }}>
          <Select
            value={
              filterField ? { label: `${filterField}`, value: `${filterField}_${filter['type']}` } : defaultFilterField
            }
            isDisabled={isReadOnly}
            onChange={(obj, action) => onFilterFieldChange(obj, action, queryObj, idx)}
            classNamePrefix="matik-select"
            options={filterFieldOptions}
            menuPortalTarget={formRef?.current}
          />
        </Form.Control>
        <Form.Control style={{ flex: '1 1' }}>
          <Select
            value={{ label: filter['operator'], value: filter['operator'] }}
            onChange={(obj, action) => onOperatorChange(obj, action, queryObj, idx)}
            classNamePrefix="matik-select"
            isDisabled={isReadOnly}
            options={supportedOperators.map((supportedOperator) => ({
              value: supportedOperator,
              label: supportedOperator,
            }))}
            menuPortalTarget={formRef?.current}
          />
        </Form.Control>
        <Form.Control style={{ flex: '2 1' }}>
          <InputWithOptionalInputs
            onChange={(newValue) => onFilterInputChange(idx, queryObj, newValue, fieldType)}
            value={filter['value']}
            inputs={inputs}
            isReadOnly={isReadOnly}
            isInputPopoverDisabled={isInputPopoverDisabled}
          />
        </Form.Control>
        <SalesforceFilterButtons
          queryObj={queryObj}
          addFilter={addFilter}
          removeFilter={removeFilter}
          isReadOnly={isReadOnly}
          idx={idx}
          dataSource={dataSource}
        />
      </Form.Field>
    );
  };

  const onFilterInputChange = (idx, queryObj, value, fieldType) => {
    const updatedQueryObj = Object.assign({}, queryObj);
    const filters = queryObj.filters || [];
    if (idx === filters.length) {
      filters.push({ field: '', value: value });
    } else {
      if (!filters[idx].field) {
        if (filters[idx].object) {
          filters[idx].field = apiInfo.objects[filters[idx].object[1][0]][fieldType][0].name;
          filters[idx].type = apiInfo.objects[filters[idx].object[1][0]][fieldType][0].type;
        } else {
          filters[idx].field = apiInfo.objects[queryObj.selectedObject][fieldType][0].name;
          filters[idx].type = apiInfo.objects[queryObj.selectedObject][fieldType][0].type;
        }
      }
      filters[idx].value = value;
    }
    updatedQueryObj.filters = filters;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const renderOrderLimit = (
    queryObj,
    orderOptions,
    orderByObjectField,
    groupingOption,
    helpText,
    defaultSortField,
    isReadOnly,
    onSortChange,
  ) => {
    const orderByFieldValue = queryObj?.orderByField || defaultSortField?.value;
    const showSorting =
      dataSource?.type === Constants.DATA_SOURCE_TYPES.hubspot && queryObj?.selectedObject === 'owners' ? false : true;
    let orderValue = orderByFieldValue ? { label: orderByFieldValue, value: orderByFieldValue } : null;
    if (dataSource?.type === Constants.DATA_SOURCE_TYPES.salesforce) {
      orderValue = orderOptions.find((field) => field.value === orderByFieldValue) || orderValue;
    }
    return (
      <React.Fragment>
        {showSorting && (
          <Form.Field className="mbl">
            <Form.Label>Result Order</Form.Label>
            <Form.Help>{helpText}</Form.Help>
            <Form.Control>
              <Form.Field kind="group">
                {orderByObjectField}
                <Form.Control style={{ flex: '3 1' }}>
                  <Select
                    value={orderValue}
                    classNamePrefix="matik-select"
                    onChange={(obj, action) => onSortChange(obj, action, 'orderByField')}
                    aria-label="Select Salesforce Field to Order By"
                    isDisabled={isReadOnly || groupingOption}
                    options={orderOptions}
                    menuPortalTarget={formRef?.current}
                  />
                </Form.Control>
                <Form.Control style={{ flex: '1 1' }}>
                  <Select
                    aria-label="Select Direction to Order By"
                    classNamePrefix="matik-select"
                    isDisabled={isReadOnly || groupingOption}
                    value={
                      queryObj.orderByDirection
                        ? { label: queryObj.orderByDirection, value: queryObj.orderByDirection }
                        : { label: 'ASC', value: 'ASC' }
                    }
                    onChange={(obj, action) => onSortChange(obj, action, 'orderByDirection')}
                    options={[
                      { label: 'ASC', value: 'ASC' },
                      { label: 'DESC', value: 'DESC' },
                    ]}
                    menuPortalTarget={formRef?.current}
                  />
                </Form.Control>
              </Form.Field>
            </Form.Control>
          </Form.Field>
        )}
        <Form.Field className="mbl">
          <Form.Label>Result Limit</Form.Label>
          <Form.Help>Maximum number of results to return (leave blank for no limit)</Form.Help>
          <Form.Control>
            <Form.Input
              type="text"
              value={resultLimit}
              onChange={(e) => onLimitChange(e, queryObj)}
              name="limit"
              style={{ maxWidth: '50px' }}
              onKeyPress={utils.preventSubmit}
              aria-label="Salesforce limit"
              disabled={isReadOnly}
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  const renderUniqueValuesFilter = () => (
    <UniqueValuesFilter filterDuplicates={filterDuplicates} toggleFilterDuplicates={toggleFilterDuplicates} />
  );

  const toggleFilterDuplicates = (bool = !filterDuplicates) => {
    setFilterDuplicates(bool);
    const updatedQueryObj = { ...queryObj, filterDuplicates: bool };
    if (updateInputFilterDuplicates) {
      updateInputFilterDuplicates(queryObj);
    } else {
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  const onLimitChange = (e, queryObj) => {
    const updatedQueryObj = { ...queryObj };
    const name = e.target.name;
    updatedQueryObj[name] = e.target.value;
    setResultLimit(e.target.value);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const addFilter = (e, queryObj, defaultOperator) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, queryObj);
    const filters = queryObj.filters || [];
    filters.push({ field: '', value: '', type: 'string', operator: defaultOperator });
    updatedQueryObj.filters = filters;

    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const removeFilter = (e, idx, queryObj) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, queryObj);
    const filters = [];
    const queryFilters = queryObj.filters || [];
    for (let i = 0; i < queryFilters.length; i++) {
      if (i !== idx) {
        filters.push(queryObj.filters[i]);
      }
    }
    updatedQueryObj.filters = filters;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onFilterFieldChange = (obj, action, queryObj, idx) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      const filterField = obj.value.split('_');
      // Need special case for field names with '_' character in them
      const filterFieldName = filterField.slice(0, filterField.length - 1).join('_');
      const filterFieldLabel = obj.label || '';
      const filterType = filterField[filterField.length - 1];
      const filters = queryObj.filters || [];
      if (idx === filters.length) {
        filters.push({ field: filterFieldName, value: '', type: filterType, label: filterFieldLabel });
      } else {
        filters[idx].field = filterFieldName;
        filters[idx].type = filterType;
        filters[idx].label = filterFieldLabel;
      }
      updatedQueryObj.filters = filters;
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  const onOperatorChange = (obj, action, queryObj, idx) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      const operator = obj.value;
      const filters = queryObj.filters;
      filters[idx].operator = operator;

      updatedQueryObj.filters = filters;
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  const updateReturnFieldMapping = (fieldName, displayName, fieldLabel) => {
    const updatedQueryObj = { ...queryObj };
    if (displayName) {
      if (!updatedQueryObj.returnFieldMapping) {
        updatedQueryObj.returnFieldMapping = {};
      }
      if (!updatedQueryObj.returnFieldMapping[fieldName]) {
        updatedQueryObj.returnFieldMapping[fieldName] = { label: fieldLabel, displayName: displayName };
      } else {
        updatedQueryObj.returnFieldMapping[fieldName].displayName = displayName;
      }
      setDisplayNameUpdate(true);
    } else {
      if (updatedQueryObj.returnFieldMapping && updatedQueryObj.returnFieldMapping[fieldName]) {
        delete updatedQueryObj.returnFieldMapping[fieldName].displayName;
      }
    }

    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    setCurrentlyRenamingField(null);
    dispatch(closeModal());
  };

  const toggleRenameModal = (e, field) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentlyRenamingField(field);

    if (ui?.modal?.name === 'renamableModal') {
      dispatch(closeModal());
    } else {
      dispatch(openModal?.('renamableModal'));
    }
  };

  const closeRenamableModal = () => {
    setCurrentlyRenamingField(null);
    dispatch(closeModal());
  };

  const selectSource = (obj, action) => {
    if (action.action === 'select-option') {
      let updatedQueryObj;

      updatedQueryObj = { source: obj.value };
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      updateTestResult(null);
    }
  };

  return (
    <div>
      <Form.Field className="mbl">
        <Form.Label>Source API</Form.Label>
        <Form.Help>Select {capitalize(dataSourceType)} Data Source API</Form.Help>
        <Form.Control>
          <Select
            aria-label={`Select ${capitalize(dataSourceType)} Source API`}
            classNamePrefix="matik-select"
            className="matik-select-container"
            isDisabled={dynamicContentContext.isReadOnly}
            value={sourceVal}
            name="selectedSource"
            onChange={(obj, action) => selectSource(obj, action)}
            placeholder={`Select ${capitalize(dataSourceType)} Source API`}
            options={options}
            menuPortalTarget={formRef?.current}
          ></Select>
        </Form.Control>
      </Form.Field>
      {(queryObj.source === 'objects' || !queryObj.source) && (
        <CRMObjectsForm
          queryObj={queryObj}
          apiInfo={apiInfo}
          renderOrderLimit={renderOrderLimit}
          renderUniqueValuesFilter={renderUniqueValuesFilter}
          toggleRenameModal={toggleRenameModal}
          addFilter={addFilter}
          removeFilter={removeFilter}
          renderFilter={renderFilter}
          source={queryObj.source}
          onFilterFieldChange={onFilterFieldChange}
          onFilterInputChange={onFilterInputChange}
          onLimitChange={onLimitChange}
          onOperatorChange={onOperatorChange}
          entityType={entityType}
          input={input}
          inputMappingOptions={inputMappingOptions}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          dataSource={dataSource}
          displayNameUpdate={displayNameUpdate}
          formRef={formRef}
          objectCount={objectCount}
        />
      )}
      {queryObj.source === 'reports' && (
        <SalesforceReportsForm
          apiInfo={apiInfo}
          queryObj={queryObj}
          refreshReport={refreshReport}
          renderOrderLimit={renderOrderLimit}
          renderUniqueValuesFilter={renderUniqueValuesFilter}
          toggleRenameModal={toggleRenameModal}
          addFilter={addFilter}
          removeFilter={removeFilter}
          renderFilter={renderFilter}
          source={queryObj.source}
          onFilterFieldChange={onFilterFieldChange}
          onFilterInputChange={onFilterInputChange}
          onLimitChange={onLimitChange}
          onOperatorChange={onOperatorChange}
          entityType={entityType}
          input={input}
          inputMappingOptions={inputMappingOptions}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          fetchApiInfoIfNeeded={fetchApiInfoIfNeeded}
          dataSource={dataSource}
          formRef={formRef}
          reportCount={reportCount}
        />
      )}
      <RenamableModal
        show={ui.modal?.name === 'renamableModal'}
        key={currentlyRenamingField}
        onClose={closeRenamableModal}
        fieldName={currentlyRenamingField?.name}
        fieldLabel={currentlyRenamingField?.label}
        initialInput={currentlyRenamingField?.displayName}
        onSave={updateReturnFieldMapping}
      />
    </div>
  );
}

CRMForm.propTypes = {
  apiInfo: PropTypes.object,
  updateTestResult: PropTypes.func,
  refreshReport: PropTypes.func,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMappingOptions: PropTypes.array,
  onInputMappingUpdate: PropTypes.func,
  inputMapping: PropTypes.object,
  dataSource: PropTypes.object,
  ui: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  isInputPopoverDisabled: PropTypes.bool,
  formRef: PropTypes.object,
  reportCount: PropTypes.number,
  objectCount: PropTypes.number,
  queryObj: PropTypes.object,
  updateInputFilterDuplicates: PropTypes.func,
};

export default CRMForm;
