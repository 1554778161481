import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TemplatesContent from './TemplatesContent';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import API from '../../../lib/api';
import WithNotifications from '../../shared/WithNotifications';
import AddPresentationTemplateModal from './AddPresentationTemplateModal';
import AddDocumentTemplateModal from 'components/producer/templates/AddDocumentTemplateModal';
import templates_empty_icon from '../../../images/templates_empty_icon.png';
import AddNarrativeModal from './AddNarrativeModal';
import { MAlert } from '../../shared/Alerts';
import withUserContext from 'components/shared/WithUserContext';
import EmailEditTypeModal from '../email/EmailEditTypeModal';
import Constants from '../../Constants';
import SortableEntityGrid from 'components/shared/SortableEntityGrid';
import { ReactComponent as Archive } from '../../../images/icons/archive-28x28-filled.svg';
import { ReactComponent as Favorite } from '../../../images/icons/star-28x28-filled.svg';
import { useTemplates } from 'lib/hooks/useTemplate';
import { UserContext } from 'components/UserContext';

class TemplatesTab extends React.Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const showAddFirstTemplate = urlParams.get('first') === 'true';

    this.state = {
      showAddFirstTemplate: showAddFirstTemplate,
      showAddPresentationTemplateModal: false,
      showEmailEditTypeModal: false,
      showAddDocumentTemplateModal: false,
      redirectToPresentation: null,
      presentations: [],
      templateLibraryTemplates: null,
      schedule: null,
    };

    API.track('templates_page_navigation');
  }

  canSelectNarrativeType = () => {
    return (
      this.props.flags.addDocsTemplate ||
      this.props.userContext?.user?.enterprise?.enterprise_settings?.matik_mail_enabled
    );
  };

  render() {
    const selectNarrativeType = this.canSelectNarrativeType();
    const activeSidebarMenuItem =
      this.props?.match?.params?.item || Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
    const templateEmptyInfo = this.getTemplateEmptyInfo(activeSidebarMenuItem);
    const emptyInfo = {
      emptyImageUrl: templates_empty_icon,
      buttonText: 'Create Template',
      emptyOnClick: this.addNarrative,
      secondaryButtonText: 'Learn More',
      secondaryButtonOnClick: () =>
        window.open('https://help.matik.io/hc/en-us/articles/8159607392923-Overview-of-Building-Templates'),
      emptyImageIcon: <></>,
      emptyPrompt: 'You have not created any templates yet.',
      emptyTitle: '',
      emptyTemplateChild: null,
      ...templateEmptyInfo,
    };

    let backHandler = this.onModalClose;
    if (selectNarrativeType) {
      backHandler = this.showNarrativeModal;
    }
    return (
      <>
        <TemplatesContent
          isLoading={this.props.isFetching}
          elements={this.props.templates}
          emptyInfo={emptyInfo}
          templates={this.props.templates}
          match={this.props.match}
          onSearchParamsChange={this.props.onSearchParamsChange}
          isPaginating={this.props.isPaginating}
          entityType="template"
          pagination={this.props.pagination}
          addNarrative={this.addNarrative}
          history={this.props.history}
          onPresentationCreate={this.onPresentationCreate}
          fetchPresentation={this.fetchPresentation}
          presentations={this.state.presentations}
          schedule={this.state.schedule}
        />
        <AddNarrativeModal
          show={this.props.ui?.fullScreenModal?.name === 'narrativeTemplateModal'}
          onClose={this.onModalClose}
          onTemplateAdd={this.onTemplateAdd}
          onPresentationNarrativeClick={this.showPresentationModal}
          onEmailNarrativeClick={this.showEmailEditTypeModal}
          onDocumentNarrativeClick={this.showDocumentModal}
          templates={this.props.templates}
        />
        <EmailEditTypeModal
          show={this.state.showEmailEditTypeModal}
          onClose={this.onModalClose}
          onTemplateAdd={this.onTemplateAdd}
          onEmailEditTypeBackClick={backHandler}
          history={this.props.history}
        />
        <AddPresentationTemplateModal
          show={this.state.showAddPresentationTemplateModal}
          onClose={this.onModalClose}
          onTemplateAdd={this.onTemplateAdd}
          onPresentationBackClick={backHandler}
        />
        <AddDocumentTemplateModal
          show={this.state.showAddDocumentTemplateModal}
          onClose={this.onModalClose}
          onTemplateAdd={this.onTemplateAdd}
          onPresentationBackClick={backHandler}
        />
      </>
    );
  }

  onPresentationCreate = (presentation, schedule, attachedPresentation = {}) => {
    const updatedPresentations = [...this.state.presentations];
    updatedPresentations.push(presentation);
    if (Object.keys(attachedPresentation).length) {
      updatedPresentations.push(attachedPresentation);
    }

    this.setState({ presentations: updatedPresentations, schedule });

    if (schedule) {
      this.props.history.push(
        `/templates/${presentation.template.id}/presentation/${presentation.id}/schedule-preview`,
      );
    } else if (presentation.bulk_presentation_id) {
      this.props.history.push(
        `/templates/${presentation.template.id}/bulk/${presentation.bulk_presentation_id}/preview/${presentation.id}`,
      );
    } else {
      this.props.history.push(`/templates/${presentation.template.id}/presentation/${presentation.id}/complete`);
    }
  };

  fetchPresentation = (presentation_id) => {
    this.setState({ isLoading: true });
    API.get(
      `/presentations/${presentation_id}/`,
      (response) => {
        const updatedPresentations = this.state.presentations.filter((p) => p.id !== response.data.id);
        updatedPresentations.push(response.data);
        this.setState({ presentations: updatedPresentations, isLoading: false });
      },
      (err) => {
        if (err.response.status === 404) {
          MAlert('Presentation not found', 'Error', 'error', () => {
            window.location.assign('/create');
          });
        }
      },
    );

    return null;
  };

  addNarrative = () => {
    if (this.canSelectNarrativeType()) {
      this.showNarrativeModal();
    } else {
      this.showPresentationModal();
    }
  };

  toMostUsedTemplates = () => {
    this.props.history.push('/templates/menu/all_templates?sort=presentation_count%20desc');
    this.props.onSearchParamsChange(
      0,
      ['presentation_count', 'desc'],
      Constants.PAGE_SIZE,
      Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates,
      false,
    );
  };

  toLeastUsedTemplates = () => {
    this.props.history.push('/templates/menu/all_templates?sort=presentation_count%20asc');
    this.props.onSearchParamsChange(
      0,
      ['presentation_count', 'asc'],
      Constants.PAGE_SIZE,
      Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates,
      false,
    );
  };

  onTemplateAdd = (newTemplate) => {
    this.setState({
      showAddFirstTemplate: false,
      showAddPresentationTemplateModal: false,
      showEmailEditTypeModal: false,
      showAddDocumentTemplateModal: false,
    });
    this.props.closeFullScreenModal();
    this.props.history.push(`/templates/${newTemplate.id}`);
  };

  showNarrativeModal = () => {
    this.setState({
      showAddPresentationTemplateModal: false,
      showEmailEditTypeModal: false,
      showAddDocumentTemplateModal: false,
    });
    this.props.openFullScreenModal('narrativeTemplateModal');
  };

  showPresentationModal = () => {
    this.setState({
      showAddPresentationTemplateModal: true,
      showEmailEditTypeModal: false,
      showAddDocumentTemplateModal: false,
    });
    this.props.closeFullScreenModal();
  };

  showEmailEditTypeModal = () => {
    this.setState({
      showEmailEditTypeModal: true,
      showAddPresentationTemplateModal: false,
      showAddDocumentTemplateModal: false,
    });
    this.props.closeFullScreenModal();
  };

  showDocumentModal = () => {
    this.setState({
      showAddDocumentTemplateModal: true,
      showAddPresentationTemplateModal: false,
      showEmailEditTypeModal: false,
    });
    this.props.closeFullScreenModal();
  };

  onModalClose = () => {
    this.setState({
      showAddPresentationTemplateModal: false,
      showEmailEditTypeModal: false,
      showAddDocumentTemplateModal: false,
    });
    this.props.closeFullScreenModal();
  };

  getTemplateEmptyInfo = (narrative) => {
    if (narrative === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites) {
      return {
        buttonText: 'View Top Templates',
        emptyOnClick: this.toMostUsedTemplates,
        secondaryButtonText: null,
        secondaryButtonOnClick: null,
        emptyImageIcon: <Favorite className="fill-green" />,
        emptyPrompt: "Mark your favorite templates with the 'star' icon to pin them to this page for easy access.",
        emptyTitle: 'No Favorite Templates',
        isBannerDisplayed: false,
      };
    }
    if (narrative === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.archived_templates) {
      return {
        buttonText: 'View least used templates',
        emptyOnClick: this.toLeastUsedTemplates,
        secondaryButtonText: null,
        secondaryButtonOnClick: null,
        emptyImageIcon: <Archive className="fill-green" />,
        emptyPrompt:
          'Use Archiving to clean up your workspace! Archive outdated templates to save them here, while hiding them from end users and preventing presentation generation.',
        emptyTitle: 'No Archived Templates',
        isBannerDisplayed: false,
      };
    }
    if (narrative === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates) {
      let noAccessTemplateCount = this.props.templates ? this.props.allTemplatesCount - this.props.templates.length : 0;
      const newTemplatesGrid = (
        <div className="end-user-templates-list">
          <div className="ptl mbs pll mtxxxl title is-4">Choose Template from Library</div>
          <div className="template-library-list">
            <SortableEntityGrid
              entitiesToRender={this.state.templateLibraryTemplates}
              entityType="template"
              isSearching={false}
              pagination={{ numberOfPages: 1 }}
              templateBaseUrl="/templates/"
              noAccessEntityCount={noAccessTemplateCount}
              showFavorites={false}
              isTemplateLibrary={true}
            />
          </div>
        </div>
      );
      return {
        emptyImageUrl: null,
        emptyPrompt: null,
        emptyTemplateChild: newTemplatesGrid,
      };
    }
    return {
      emptyPrompt:
        'You have no templates available to use yet. Templates will appear here once your admin has created and shared them with you.',
      emptyTitle: 'No Templates',
    };
  };
}

TemplatesTab.propTypes = {
  onSearchParamsChange: PropTypes.func,
  isFetching: PropTypes.bool,
  isPaginating: PropTypes.bool,
  match: PropTypes.object,
  flags: PropTypes.object,
  pagination: PropTypes.object,
  templates: PropTypes.array,
  history: PropTypes.object,
  userContext: PropTypes.object,
  allTemplatesCount: PropTypes.number,
  // From UI redux
  closeFullScreenModal: PropTypes.func,
  openFullScreenModal: PropTypes.func,
  ui: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const props = {
    ...ownProps,
    entityType: 'template',
  };
  return {
    ...mapUiStateToProps(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...mapUiDispatchToProps(dispatch),
  };
}

const useDefaultTemplateSort = () => {
  const userContext = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  const sortParameter = urlParams.get('sort');

  const defaultSort = sortParameter
    ? sortParameter
    : userContext.user && userContext.user.enterprise.enterprise_settings.template_default_sort_order;
  if (!defaultSort) {
    return null;
  }

  const sort = defaultSort.split(' ');
  if (sort.length !== 2) {
    return null;
  }
  return sort;
};

const TemplatesTabWrapper = (props) => {
  const defaultSort = useDefaultTemplateSort();
  const flags = useFlags();
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    limit: Constants.PAGE_SIZE,
    sort: defaultSort,
    preset: props.match.params.item || Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates,
  });

  const { offset, limit, sort, preset, keepPreviousData } = searchParams;
  const { pagination, elements, isPending, isPreviousData } = useTemplates(
    offset,
    limit,
    sort,
    preset,
    keepPreviousData,
  );

  const handleSearchParamsChange = (offset, sort, limit, preset, keepPreviousData) => {
    if (!sort) {
      sort = defaultSort;
    }
    setSearchParams({ offset, sort, limit, preset: preset || searchParams.preset, keepPreviousData });
  };

  return (
    <TemplatesTab
      {...props}
      isFetching={isPending}
      isPaginating={isPreviousData}
      templates={elements}
      pagination={pagination}
      onSearchParamsChange={handleSearchParamsChange}
      flags={flags}
    />
  );
};
TemplatesTabWrapper.propTypes = {
  match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithNotifications(withUserContext(TemplatesTabWrapper)));
