import { bindActionCreators } from 'redux';
import {
  fetchApiInfoIfNeeded,
  refreshSalesforceReportMetadata,
  fetchReportCountFromApi,
  fetchObjectCountFromApi,
  updateApiInfoWithObject,
  setIsFetching,
} from './action';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchApiInfoIfNeeded,
      refreshSalesforceReportMetadata,
      fetchReportCountFromApi,
      fetchObjectCountFromApi,
      updateApiInfoWithObject,
      setIsFetching,
    },
    dispatch,
  );
};

export { mapDispatchToProps };
