import React from 'react';
import PropTypes from 'prop-types';
import Pill from './Pill';
import { SizeToIconSize } from './IconPill';
import Logo from './Logo';

function LogoPill({ color, luminosity, name, size = 'l', text, theme = 'circle' }) {
  return (
    <Pill color={color} luminosity={luminosity} size={size} text={text} theme={theme}>
      <Logo name={name} size={SizeToIconSize[size]} />
    </Pill>
  );
}

LogoPill.propTypes = {
  color: Pill.propTypes.color,
  luminosity: Pill.propTypes.luminosity,
  name: PropTypes.string.isRequired,
  size: Pill.propTypes.size,
  text: Pill.propTypes.text,
  theme: Pill.propTypes.theme,
};

export default LogoPill;
