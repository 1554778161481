import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bulma-components';
import Pluralize from 'pluralize';
import TextOverflowWithTooltip from './TextOverflowWithTooltip';
import { ReactComponent as NoAccessTemplateIcon } from '../../svg/template_no_access.svg';

class NoAccessTemplateCard extends Component {
  render() {
    let titleText = 'No access to ' + this.props.numTemplates + ' ' + Pluralize('Template', this.props.numTemplates);
    let secondaryText =
      this.props.numTemplates +
      ' ' +
      Pluralize('Template', this.props.numTemplates) +
      ' not shared by the creator with you';
    return (
      <Card className="template-card">
        <div className="no-access-template-card-thumbnail-container">
          <NoAccessTemplateIcon height={161} />
        </div>
        <Card.Content className="template-card-content">
          <TextOverflowWithTooltip className="title is-4 primary-card-text text-overflow-ellipsis" text={titleText} />
          <div className="secondary-card-content">
            <span className="tertiary-card-text">{secondaryText}</span>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

NoAccessTemplateCard.propTypes = {
  numTemplates: PropTypes.number,
};

export default NoAccessTemplateCard;
