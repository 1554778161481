import React, { Component } from 'react';
import { components } from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Form } from 'react-bulma-components';
import { CreatableSelect, Select } from '../../../shared/FormSelect';
import RenamableModal from '../RenamableModal';
import InputMapping from '../InputMapping';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import SenderApiReturnFields from './SenderApiReturnFields';
import Constants from 'components/Constants';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import Tag from '../../../lib/Tag';
class ApiReturnFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlyRenamingField: null,
      returnFieldsByName: {},
      calculatedFieldsByName: {},
    };
  }

  componentDidMount() {
    this.componentDidMountOrUpdate();
  }
  componentDidUpdate(prevProps) {
    if (this.props.queryObj.query.fields !== prevProps.queryObj.query.fields) {
      this.componentDidMountOrUpdate();
    }
  }
  componentDidMountOrUpdate() {
    const fields = this.props?.queryObj?.query?.fields ? this.props.queryObj.query.fields : [];
    const dynamicFields = this.props?.queryObj?.query?.dynamic_fields
      ? this.props.queryObj.query.dynamic_fields.map((dynamicField) => this._getDynamicFieldName(dynamicField))
      : [];
    const allFields = fields.concat(dynamicFields);
    let returnFieldsByName = {};
    let calculatedFieldsByName = {};
    for (let fieldName of allFields) {
      returnFieldsByName[fieldName] = true;
      //table calcs are done after aggregations
      calculatedFieldsByName[fieldName] = false;
      if (fields.indexOf(fieldName) < 0) {
        calculatedFieldsByName[fieldName] = true;
      }
    }
    if (!isEqual(returnFieldsByName, this.props?.queryObj?.returnFieldsByName)) {
      // this function sets state and was in
      // the render function which breaks a cardinal rule of react
      this.updateReturnFieldsInQuery(returnFieldsByName);
    }
    this.setState({ returnFieldsByName });
    this.setState({ calculatedFieldsByName });
  }

  render() {
    const returnFieldMapping = this.props?.queryObj?.query?.return_field_mapping
      ? this.props.queryObj.query.return_field_mapping
      : {};

    let returnFieldsArray =
      Object.keys(this.state.returnFieldsByName).length > 0
        ? Object.keys(this.state.returnFieldsByName)
            .filter((fieldName) => this.state.returnFieldsByName[fieldName])
            .map((fieldName) => ({
              label: fieldName,
              value: fieldName,
              displayName: returnFieldMapping[fieldName],
              toggleRenameModal: this.toggleRenameModal,
            }))
        : [];
    let fieldOptions = [];
    if (this.props.fields && Object.keys(this.props.fields).length > 0) {
      fieldOptions = this.props.fields.map((fieldName) => ({ label: fieldName, value: fieldName }));

      const concatFields = this.props.fields.concat(
        this.props.queryObj.query.cached_dynamic_fields
          ? this.props.queryObj.query.cached_dynamic_fields.map((dynamicField) =>
              this._getDynamicFieldName(dynamicField),
            )
          : [],
      );
      const totalFields = Array.from(new Set(concatFields));
      fieldOptions = totalFields.map((dynamicField) => {
        return { label: dynamicField, value: dynamicField };
      });
    }
    const SelectComponent = this.props.canCreateReturnField ? CreatableSelect : Select;

    const MultiValueTagLabel = (props) => {
      const iconName =
        props.data?.value in this.state.calculatedFieldsByName &&
        this.state.calculatedFieldsByName[props.data?.value] === true
          ? 'math_formula'
          : '';
      const displayName = props.data.displayName ? props.data.displayName : 'Add Display Name';

      return (
        <components.MultiValueLabel {...props}>
          <Tag
            label={props.data.label}
            iconName={iconName}
            size="s"
            onClick={(e) => {
              e.preventDefault();
            }}
            onClose={() => this.onFieldRemove(props.data?.value, this.state.returnFieldsByName)}
            actionLabel={displayName}
            actionOnClick={(e) =>
              props.data.toggleRenameModal(e, {
                name: props.data.value,
                label: props.data.label,
                displayName: props.data.displayName,
              })
            }
          />
        </components.MultiValueLabel>
      );
    };

    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      return (
        <SenderApiReturnFields
          onReturnFieldChange={this.onReturnFieldChange}
          options={fieldOptions}
          returnFieldsByName={this.state.returnFieldsByName}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Form.Field className="mbl">
            <Form.Label>{this.props.filterTitle}</Form.Label>
            <Form.Help>Select field(s) to return</Form.Help>
            <Form.Control>
              <SelectComponent
                aria-label="Select Return Fields"
                classNamePrefix="matik-select"
                value={returnFieldsArray}
                isMulti={true}
                isRenamable={true}
                isPasteable={this.props.canCreateReturnField}
                noOptionsMessage={() => (this.props.noOptionsMessage ? this.props.noOptionsMessage : 'No options')}
                options={fieldOptions}
                isDisabled={this.context.isReadOnly}
                onChange={(obj, action) => this.onReturnFieldChange(obj, action, this.state.returnFieldsByName)}
                placeholder={this.props.placeholder}
                componentsToAdd={{
                  MultiValueContainer: MultiValueTagLabel,
                  Option: components.Option,
                  ValueContainer: components.ValueContainer,
                }}
              />
            </Form.Control>
          </Form.Field>
          {this.props.entityType && this.props.entityType === 'input' && (
            <InputMapping
              queryObj={this.props.queryObj}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
              input={this.props.input}
              inputMapping={this.props.inputMapping}
              returnFieldsArray={returnFieldsArray}
            />
          )}
          <RenamableModal
            show={this.props.ui.modal?.name === 'renamableModal'}
            key={this.state.currentlyRenamingField}
            onClose={this.closeRenamableModal}
            fieldName={this.state.currentlyRenamingField}
            initialInput={returnFieldMapping[this.state.currentlyRenamingField]}
            onSave={this.updateReturnFieldMapping}
          />
        </React.Fragment>
      );
    }
  }

  onFieldRemove = (value, returnFieldsByName) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    returnFieldsByName[value] = false;
    let updatedDynamicFields = [];
    if (this.props.queryObj.query.cached_dynamic_fields) {
      for (let dynamicField of this.props.queryObj.query.cached_dynamic_fields) {
        const dynamicFieldName = this._getDynamicFieldName(dynamicField);
        if (returnFieldsByName[dynamicFieldName]) {
          updatedDynamicFields.push(dynamicField);
          // Sometimes dynamic fields can also be considered fields in looker queries. In that case, we don't want
          // to remove them from the fields that are being returned
          if (
            this.props.queryObj.query.cached_fields &&
            this.props.queryObj.query.cached_fields.indexOf(dynamicFieldName) < 0
          ) {
            returnFieldsByName[dynamicFieldName] = false;
          }
        }
      }
    }
    updatedQueryObj.returnFieldsByName = returnFieldsByName;
    updatedQueryObj.query.dynamic_fields = updatedDynamicFields;
    updatedQueryObj.query.fields = Object.keys(returnFieldsByName).filter((fieldName) => returnFieldsByName[fieldName]);
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  updateReturnFieldsInQuery = (returnFieldsByName) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.returnFieldsByName = returnFieldsByName;
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onReturnFieldChange = async (obj, action, returnFieldsByName) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      await this.context.onSenderFieldUpdate(action.name, obj.value);
      const senderReturnFields = new Set(Object.values(this.context.multiFieldMapping));
      returnFieldsByName = {};
      senderReturnFields.forEach((field) => (returnFieldsByName[field] = true));
    } else {
      if (action.action === 'select-option') {
        returnFieldsByName[action.option.value] = true;
      } else if (action.action === 'select-all-options') {
        const entries = action.option.map((option) => [option.value, true]);
        returnFieldsByName = Object.fromEntries(entries);
      } else if (action.action === 'remove-value') {
        returnFieldsByName[action.removedValue.value] = false;
      } else if (action.action === 'clear') {
        returnFieldsByName = {};
      } else if (action.action === 'create-option') {
        const createdVal = obj.find((el) => !!el.__isNew__);
        returnFieldsByName[createdVal.value] = true;
      } else if (action.action === 'create-option-bulk') {
        const createdVals = obj.filter((el) => !!el.__isNew__);
        createdVals.forEach((createdVal) => (returnFieldsByName[createdVal.value] = true));
      }
    }
    let updatedDynamicFields = [];
    if (this.props.queryObj.query.cached_dynamic_fields) {
      for (let dynamicField of this.props.queryObj.query.cached_dynamic_fields) {
        const dynamicFieldName = this._getDynamicFieldName(dynamicField);
        if (returnFieldsByName[dynamicFieldName]) {
          updatedDynamicFields.push(dynamicField);
          // Sometimes dynamic fields can also be considered fields in looker queries. In that case, we don't want
          // to remove them from the fields that are being returned
          if (
            this.props.queryObj.query.cached_fields &&
            this.props.queryObj.query.cached_fields.indexOf(dynamicFieldName) < 0
          ) {
            returnFieldsByName[dynamicFieldName] = false;
          }
        }
      }
    }
    updatedQueryObj.returnFieldsByName = returnFieldsByName;
    updatedQueryObj.query.dynamic_fields = updatedDynamicFields;
    updatedQueryObj.query.fields = Object.keys(returnFieldsByName).filter((fieldName) => returnFieldsByName[fieldName]);
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  updateReturnFieldMapping = (fieldName, displayName) => {
    const fieldNameString = fieldName?.name || fieldName;
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (displayName) {
      if (!updatedQueryObj.query.return_field_mapping) {
        updatedQueryObj.query.return_field_mapping = { [fieldNameString]: displayName };
      } else {
        updatedQueryObj.query.return_field_mapping[fieldNameString] = displayName;
      }
    } else {
      if (updatedQueryObj.query.return_field_mapping && updatedQueryObj.query.return_field_mapping[fieldNameString]) {
        delete updatedQueryObj.query.return_field_mapping[fieldNameString];
      }
    }
    this.context.onQueryObjectUpdate(updatedQueryObj);
    this.setState({ currentlyRenamingField: null });
    this.props.closeModal();
  };

  toggleRenameModal = (e, fieldName) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ currentlyRenamingField: fieldName?.name || fieldName });
    if (this.props.ui.modal?.name === 'renamableModal') {
      this.props.closeModal();
    } else {
      this.props.openModal('renamableModal');
    }
  };

  closeRenamableModal = () => {
    this.setState({ currentlyRenamingField: null });
    this.props.closeModal();
  };

  _getDynamicFieldName = (dynamicField) => {
    if (dynamicField['category']) {
      return dynamicField[dynamicField['category']];
    }
    if (dynamicField['table_calculation']) {
      return dynamicField['table_calculation'];
    }
    if (dynamicField['measure']) {
      return dynamicField['measure'];
    }
    if (dynamicField['label']) {
      return dynamicField['label'];
    }
  };
}

ApiReturnFields.contextType = DynamicContentContext;

ApiReturnFields.propTypes = {
  fields: PropTypes.array,
  filterTitle: PropTypes.string,
  isReadOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  queryObj: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  noOptionsMessage: PropTypes.string,
  onInputMappingUpdate: PropTypes.func,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  canCreateReturnField: PropTypes.bool,
};

ApiReturnFields.defaultProps = {
  filterTitle: 'Fields To Return',
  placeholder: 'Select Fields to Return',
};

export default connect(mapUiStateToProps, mapDispatchToProps)(ApiReturnFields);
