import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

class MatikLogoForm extends Component {
  render() {
    return (
      <Form.Field>
        <Form.Label>Logo Domain</Form.Label>
        <Form.Help>
          Input needs to be in the form of a domain (i.e. google.com for Google). Hint: use &:&lt;input_name&gt; to use
          an input or &#123;&#123;&lt;content_name&gt;&#125;&#125; to use a piece of dynamic content
        </Form.Help>
        <Form.Control className="single-param">
          <InputWithOptionalInputs
            onChange={this.updateQueryObj}
            value={this.context.query}
            inputs={this.context.existingInputs}
            isReadOnly={this.context.isReadOnly}
            isDynamicContentSuggested
          />
        </Form.Control>
      </Form.Field>
    );
  }

  updateQueryObj = (queryString) => {
    this.context.onQueryStringUpdate(queryString);
  };
}

MatikLogoForm.contextType = DynamicContentContext;

MatikLogoForm.propTypes = {
  isReadOnly: PropTypes.bool,
  updateQuery: PropTypes.func,
  query: PropTypes.string,
  inputs: PropTypes.object,
  dataSource: PropTypes.object,
};

export default MatikLogoForm;
