import React from 'react';

export const DynamicContentContext = React.createContext({
  currentDynamicContent: {},
  dynamicContentType: '',
  isReadOnly: false,
  existingInputs: {},
  query: '',
  queryStringError: '',
  onQueryStringUpdate: () => {},
  onQueryObjectUpdate: () => {},
  multiFieldMapping: {},
  onSenderFieldUpdate: () => {},
});
