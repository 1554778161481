import { useState, useEffect } from 'react';
import API from 'lib/api';

export const useAllDataSources = () => {
  const [dataSources, setDataSources] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setIsFetching(true);
    setFetchError(null);

    let abortController = new AbortController();
    API.get(
      '/data_sources/',
      (response) => setDataSources(response.data),
      (err) => {
        setFetchError(err);
        if (err.message !== 'canceled') {
          API.defaultError(err);
        }
      },
      null,
      abortController,
    ).finally(() => {
      setIsFetching(false);
      abortController = undefined;
    });

    return () => {
      abortController?.abort();
    };
  }, []);

  return {
    isFetching,
    fetchError,
    dataSources,
  };
};
