import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import tagIcon from '../../../images/tag.svg';
import BulkItemTagModal from './BulkItemTagModal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

function BulkTagButton(props) {
  const onBulkTagClick = () => {
    props.openModal('bulkItemTagModal');
  };

  const onBulkTagModalClose = () => {
    props.closeModal();
  };

  return (
    <React.Fragment>
      <button className="button is-secondary" onClick={onBulkTagClick}>
        <img className="mrs" src={tagIcon} alt="Manage Tags" /> Manage Tags
      </button>
      <BulkItemTagModal itemIds={props.selectedItemIds} entityType={props.entityType} onClose={onBulkTagModalClose} />
    </React.Fragment>
  );
}

BulkTagButton.propTypes = {
  selectedItemIds: PropTypes.array,
  entityType: PropTypes.string,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(BulkTagButton);
