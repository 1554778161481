import React from 'react';
import {
  ArrayInput,
  Datagrid,
  Edit,
  List,
  TextField,
  TextInput,
  SimpleForm,
  EditButton,
  required,
  DateField,
  Create,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  SimpleFormIterator,
} from 'react-admin';

export const ClientList = (props) => (
  <List {...props} perPage={25}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="client_name" />
      <TextField source="client_id" />
      <TextField source="client_secret" />
      <ReferenceField label="User" source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="client_secret_expires_at" label="Expires" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ClientEdit = (props) => (
  <Edit title="client_name" {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="client_name" validate={required('Name is required')} />
      <TextInput source="client_id" disabled />
      <TextInput source="client_secret" disabled />
      <TextInput source="client_secret_expires_at" label="Expires" disabled />
      <ArrayInput source="redirect_uris" validate={required('At least one redirect URI is required')}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectArrayInput
        label="scope"
        source="scopes"
        validate={required('Scope is required')}
        choices={[
          { id: 'producer', name: 'Producer' },
          { id: 'consumer', name: 'Consumer' },
        ]}
      />
      <ReferenceInput label="User" source="user_id" reference="users" validate={required('User is required.')}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ClientCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Name" source="client_name" validate={required('Name is required')} />
      <ArrayInput source="redirect_uris" validate={required('At least one redirect URI is required')}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectArrayInput
        label="scopes"
        source="scopes"
        validate={required('Scope is required')}
        choices={[
          { id: 'producer', name: 'Producer' },
          { id: 'consumer', name: 'Consumer' },
        ]}
      />
      <ReferenceInput label="User" source="user_id" reference="users" validate={required('User is required')}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
