import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Constants from '../Constants';
import FormFromInputs from './FormFromInputs';
import QueryProcessor from '../../lib/queryProcessor';
import inputs from '../../lib/inputs';
import { ReactComponent as Run } from '../../svg/run.svg';
import utils from '../../lib/utils';
import QueryDisplay from './QueryDisplay';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';
import Banner from './Banner';
import useInputsAndValues from 'lib/hooks/useInputsAndValues';

class TestInputsForm extends Component {
  render() {
    if (!this.props.inputValues || Object.keys(this.props.inputValues).length === 0) {
      return null;
    }
    const buttonActive = inputs.areInputsFilledOut(this.props.inputValues, this.props.inputs);
    const queryProcessor = new QueryProcessor(
      this.props.queryString,
      this.props.inputs,
      this.props.inputValues,
      this.props.dataSource,
    );
    let parameterizedQuery = queryProcessor.parameterizeQueryString();

    if (
      this.props.dataSource.type === 'salesforce' &&
      utils.isValidJSON(parameterizedQuery) &&
      JSON.parse(parameterizedQuery).soql_string
    ) {
      parameterizedQuery = JSON.parse(parameterizedQuery).soql_string;
    }

    let matik_user_banner_text = '';

    const hasMatikUser = utils.hasMatikUserInputs(Object.values(this.props.inputs));

    if (hasMatikUser) {
      const entityTypeToBannerTextMap = {
        template: 'Template',
        input: 'Input',
        dynamic_content: 'Dynamic Content',
      };

      matik_user_banner_text = entityTypeToBannerTextMap[this.props.entityType] || '';
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        {hasMatikUser && (
          <div className="pb-4">
            <Banner
              bannerType="info"
              text={`This ${matik_user_banner_text} uses an input that populates based on the 
              current user. To test, select the user you would like to impersonate.`}
            />
          </div>
        )}
        <FormFromInputs
          inputs={Object.values(this.props.inputs)}
          inputValues={this.props.inputValues}
          onInputValueChange={this.props.onInputValueChange}
          test={true}
          isProducer={true}
        />
        <Form.Field className="mtm">
          <Form.Label className="phm">Generated Query</Form.Label>
          <QueryDisplay queryString={parameterizedQuery} />
        </Form.Field>
        <ButtonGroup align="right">
          <Button type="button" category="secondary" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" status={!buttonActive ? 'disabled' : 'default'}>
            <Run />
            &nbsp;{this.props.submitButtonText ? this.props.submitButtonText : 'Test Query'}
          </Button>
        </ButtonGroup>
      </form>
    );
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.validateFormData()) {
      const queryProcessor = new QueryProcessor(
        this.props.queryString,
        this.props.inputs,
        this.props.inputValues,
        this.props.dataSource,
      );
      this.props.onFormSubmit(queryProcessor.parameterizeQueryString(), this.props.inputValues);
    }
  };

  validateFormData = () => {
    let isValidated = true;
    const updatedInputValues = {};

    for (let inputName in this.props.inputValues) {
      const inputValue = this.props.inputValues[inputName];
      const input = this.props.inputs[inputName];
      if (
        (input.type !== Constants.InputTypes.BOOLEAN && !inputValue.value) ||
        (input.type === Constants.InputTypes.LIST && inputValue.value.length === 0)
      ) {
        updatedInputValues[inputName] = { error: 'This parameter is required' };
        isValidated = false;
      }
    }

    if (!isValidated) {
      this.props.onInputValueChange(updatedInputValues);
    }
    return isValidated;
  };
}

TestInputsForm.propTypes = {
  queryString: PropTypes.string,
  inputs: PropTypes.object,
  onFormSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.string,
  dataSource: PropTypes.object,
  onInputValueChange: PropTypes.func,
  inputValues: PropTypes.object,
  entityType: PropTypes.string,
};

function TestInputsFormWrapper(props) {
  const useRelevantInputs = () => ({ inputs: Object.values(props.inputs) });
  const { inputValues, updateInputValues } = useInputsAndValues(useRelevantInputs);

  return <TestInputsForm inputValues={inputValues} onInputValueChange={updateInputValues} {...props} />;
}
TestInputsFormWrapper.propTypes = {
  inputs: PropTypes.object,
};

export default TestInputsFormWrapper;
