import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Level, Hero } from 'react-bulma-components';
import { isEmpty, uniq, isEqual } from 'lodash';
import Pluralize from 'pluralize';
import 'sweetalert2/dist/sweetalert2.css';
import TemplateSlide from './TemplateSlide';
import { ReactComponent as PlayThin } from '../../../svg/play_thin.svg';
import { ReactComponent as ConditionalThin } from '../../../svg/conditional_thin.svg';
import { ReactComponent as GridViewIcon } from '../../../svg/grid_view.svg';
import { ReactComponent as SingleSlideViewIcon } from '../../../svg/single_slide_view.svg';
import SlideConditionsModal from './SlideConditionsModal';
import TestSlideModal from './TestSlideModal';
import { ContentContext } from './ContentContext';
import { MInsufficientPerms, MInsufficientPermissions } from '../../shared/Alerts';
import { mapLibraryDispatchToProps } from '../../../redux/libraries/dispatchers';
import withUserContext from '../../shared/WithUserContext';
import API from 'lib/api';
import { getLoopedSlideInfo } from 'lib/looping';
import useTemplate, {
  useTemplateLinkedObjectStatus,
  useTemplateResyncStatus,
  useTemplateThumbnails,
} from 'lib/hooks/useTemplate';
import Banner from 'components/shared/Banner';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import SlideCarousel from '../../shared/SlideCarousel';
import utils from '../../../lib/utils';
import Constants from 'components/Constants';
import Icon from 'components/lib/Icon';
import ConditionContextPopupMenu from '../conditions/ConditionContextPopupMenu';

const trackEvent = (eventName, { currentTemplate = {}, showSingleSlide }) => {
  const additionalTrackingInfo = {
    templateType: currentTemplate.source_type, // word_365, google_slides, etc...
    viewType: showSingleSlide ? 'single_slide_view' : 'grid_view',
  };

  API.track(eventName, additionalTrackingInfo);
};

class TemplateSlides extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insertLibrarySlideReferenceElement: null,
      selectedSlideIdxs: [],
      viewingSlide: {},
      scrollToSlideIdx: 0,
    };
  }

  componentDidMount() {
    this.props.fetchLibrariesIfNeeded();
    trackEvent('view_template', this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedSlides != this.props.selectedSlides && this.props.selectedSlides?.length > 0) {
      this.setViewingSlide(this.props.selectedSlides[0]);
    }

    // Track, when view transitions between single slide view and grid view
    if (prevProps.showSingleSlide !== this.props.showSingleSlide) {
      if (this.props.showSingleSlide === true) {
        trackEvent('enter_single_slide_view', this.props);
      } else if (this.props.showSingleSlide === false) {
        trackEvent('enter_grid_view', this.props);
      }
    }
  }

  render() {
    let rightSideMenu = '';

    // Note that selectedConditionCount includes "undefined" by design when a selected slide has no conditions.
    // We don't want to allow users to edit a condition if they have selected a slide with one condition
    // and another slide with a different, or no, condition.
    const selectedConditionCount = uniq(this.props.selectedSlides.map((slide) => slide.conditions[0]?.id)).length;

    let disabledConditionTooltip = null;
    if (this.props.selectedSlides.length === 0) {
      disabledConditionTooltip = 'To edit conditions, please select a slide.';
    } else if (selectedConditionCount > 1) {
      disabledConditionTooltip = 'To edit conditions, only select slides associated with a single condition.';
    }

    if (this.props.userContext.user && this.props.userContext.user.enterprise.plan_id) {
      rightSideMenu = (
        <div className="right-side-menu">
          <div className="right-side-menu-item mbl">
            <button
              className="button is-secondary test-slide-button"
              onClick={this.showTestSlideModal}
              data-tooltip-content={`Test ${Pluralize('Slide', this.props.selectedSlides.length, false)}`}
              data-tooltip-place="left"
              data-tooltip-id="matik-tooltip"
              disabled={this.props.selectedSlides.length < 1}
            >
              <PlayThin className="disabled-fill-path" />
            </button>
          </div>
          {this.props.flags.enableMultiConditions ? (
            <ConditionButton
              template={this.props.currentTemplate}
              selectedSlides={this.props.selectedSlides}
              onCopyConditions={this.copyConditions}
              copiedConditions={this.state.conditionClauses}
              onSelectSlidesMatchingConditions={this.selectSlidesMatchingConditions}
              onClick={this.props.onConditionButtonClick}
            />
          ) : (
            <div
              className="right-side-menu-item"
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={disabledConditionTooltip}
              data-tooltip-place="left"
            >
              <button
                className="button is-secondary"
                onClick={this.showConditionsModal}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Manage Conditions"
                data-tooltip-place="left"
                disabled={!!disabledConditionTooltip}
              >
                <ConditionalThin className="disabled-fill-path" />
              </button>
            </div>
          )}
        </div>
      );
    }

    let slideBody = '';
    if (this.props.showSingleSlide && !isEmpty(this.props.currentTemplate.pdf)) {
      slideBody = (
        <SlideCarousel
          currentTemplate={this.props.currentTemplate}
          dynamicContentTags={this.props.dynamicContentTags}
          selectedSlides={this.props.selectedSlides}
          status={this.props.status}
          highlightedSlideNums={this.props.highlightedSlideNums}
          toggleSlide={this.props.toggleSlide}
          toggleAllSlides={this.props.toggleAllSlides}
          showConditionsModal={this.showConditionsModal}
          showLoopModal={this.showLoopModal}
          showingSingleSlide={this.props.showSingleSlide}
          viewingSlide={this.state.viewingSlide}
          setViewingSlide={this.setViewingSlide}
          scrollToSlideIdx={this.state.scrollToSlideIdx}
          setScrollToSlideIdx={this.setScrollToSlideIdx}
          matchingInputs={this.props.matchingInputs}
          filteredSlides={this.props.filteredSlides}
          onCopyConditions={this.copyConditions}
          copiedConditions={this.state.conditionClauses}
          onSelectSlidesMatchingConditions={this.selectSlidesMatchingConditions}
        />
      );
    } else {
      slideBody = this.renderGrid();
    }

    let updateTemplateWarningProps = {
      text: 'Connected Google Slide Template is outdated',
      sublineText: 'This presentation might not be generated as expected',
      primaryButtonLabel: 'Sync Template',
      onPrimaryButtonClick: this.syncTemplate,
      secondaryButtonLabel: 'Open in Google slides',
      onSecondaryButtonClick: this.openGoogleSlidesTab,
      onDismiss: this.dismissTemplateBanner,
    };

    let updateO365TemplateWarningProps = {
      ...updateTemplateWarningProps,
      text: 'Connected Microsoft 365 Template is outdated',
      secondaryButtonLabel: 'Open in PowerPoint',
    };

    const templateOwnerName = this.props.currentTemplate?.user?.name
      ? this.props.currentTemplate?.user?.name
      : this.props.currentTemplate?.user?.email;
    let sublineText = 'Missing permission can lead to generation errors';
    if (templateOwnerName && this.props.currentTemplate?.user?.id !== this.props.userContext.user?.id) {
      sublineText = `${templateOwnerName} (Owner) does not have access to all the objects associated with this template in Google Drive. `;
      sublineText += "You won't be able to generate a presentation.";
    }

    let updateDataSourceProps = {
      text: 'Permissions for linked objects are missing',
      sublineText: sublineText,
      primaryButtonLabel: 'Review',
      onPrimaryButtonClick: this.reviewPermissions,
      secondaryButtonLabel: 'Request permission',
      onSecondaryButtonClick: this.requestPermissions,
      onDismiss: this.dismissPermsBanner,
    };

    let selectedConditionId = null;
    for (const slide of this.props.selectedSlides) {
      selectedConditionId = slide.conditions?.[0]?.id;
      if (selectedConditionId) {
        break;
      }
    }
    const slidesForSelectedCondition = selectedConditionId
      ? this.props.currentTemplate.slides.filter((slide) => slide.conditions?.[0]?.id === selectedConditionId)
      : this.props.selectedSlides;

    const handleSlideConditionsUpdated = () => {
      // Updating slide conditions triggers a number of side-effects to the template data, so reload the data
      // to get the latest.
      this.props.invalidateTemplate(this.props.currentTemplate.id);
    };

    const warningProps =
      this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365
        ? updateO365TemplateWarningProps
        : updateTemplateWarningProps;

    return (
      <>
        {((this.props.needsResync && this.props.isResyncWarningShown) ||
          this.props.needsLinkedObjectPermission ||
          this.props.isLinkedObjectWarningShown) && (
          <div className="ptl prl pll template-banners">
            {this.props.isResyncWarningShown && this.props.needsResync && this.props.flags.updateTemplateError && (
              <Level className="grid-header">
                <Banner bannerType="warning" {...warningProps} />
              </Level>
            )}
            {this.props.isLinkedObjectWarningShown &&
              this.props.needsLinkedObjectPermission &&
              this.props.flags.updateDataSourceError && (
                <Level className="grid-header">
                  <Banner bannerType="error" {...updateDataSourceProps} />
                </Level>
              )}
            {this.props.showSingleSlide &&
              this.state.viewingSlide &&
              (!this.state.viewingSlide.locations || this.state.viewingSlide.locations.length === 0) && (
                <Level className="grid-header">
                  <Banner
                    bannerType="warning"
                    text="Could not parse slide content. Resync or upload template to try again."
                    sublineText="This presentation might not be generated as expected"
                    primaryButtonLabel="Sync Template"
                    onPrimaryButtonClick={this.syncTemplate}
                  />
                </Level>
              )}
          </div>
        )}
        <Hero className={this.props.showSingleSlide ? 'ssv' : ''}>{slideBody}</Hero>
        {rightSideMenu}
        <span className="view-buttons">
          <span
            className={`view-button top ${this.props.showSingleSlide ? '' : 'active'}`}
            onClick={(e) => this.hideSlides(e, this.props.selectedSlides)}
          >
            <GridViewIcon className="grid-view-icon" />
          </span>
          <span
            className={`view-button bottom ${this.props.showSingleSlide ? 'active' : ''}`}
            onClick={(e) => this.showSingleSlide(e, this.props.selectedSlides)}
          >
            <SingleSlideViewIcon className="single-slide-view-icon disabled-fill-rect" />
          </span>
        </span>
        {this.props.ui.modal?.name === 'slideConditionsModal' && (
          <SlideConditionsModal
            show
            onClose={this.closeConditionsModal}
            allDynamicContent={this.props.allDynamicContent}
            currentTemplate={this.props.currentTemplate}
            onConditionsUpdated={handleSlideConditionsUpdated}
            slides={slidesForSelectedCondition}
            status={this.props.status}
          />
        )}
        <TestSlideModal
          allDynamicContent={this.props.allDynamicContent}
          matchingInputs={this.props.matchingInputs}
          onClose={this.closeTestSlideModal}
          onPresentationCreate={this.onTestSlideCreate}
          show={this.props.ui.modal?.name === 'testSlideModal'}
          slides={this.props.selectedSlides}
          tags={this.props.tags}
          template={this.props.currentTemplate}
        />
      </>
    );
  }

  renderGrid() {
    const handleContainerClick = (e) => {
      // We're only interested in clicks on the background, i.e. either "slides-wrapper"
      // or "template-grid" but not on the TemplateSlides
      if (e.target === e.currentTarget) {
        this.props.toggleAllSlides(false);
      }
    };
    const isUnfiltered = (slide) => {
      if (this.props.filteredSlides?.length > 0) {
        return this.props.filteredSlides.some((slideId) => slide.id === slideId);
      } else {
        return true;
      }
    };
    return (
      <div className="slides-wrapper" onClick={handleContainerClick}>
        <div className="template-grid" onClick={handleContainerClick}>
          {this.props.currentTemplate.slides.map((slide, idx) => {
            const thumbData = this.props.thumbnails?.[parseInt(slide.id)];
            const slideFetchStatus = thumbData && {
              data: thumbData,
              fetchState: 'fetched',
              errorMessage: '',
            };
            return (
              <TemplateSlide
                key={`${slide.id}_${idx}`}
                canEdit={this.props.canEdit}
                currentTemplate={this.props.currentTemplate}
                highlightedSlideNums={this.props.highlightedSlideNums}
                isHidden={!isUnfiltered(slide)}
                isLast={idx === this.props.currentTemplate.slides.length - 1}
                selectedSlides={this.props.selectedSlides}
                slideFetchStatus={slideFetchStatus}
                slideNum={idx + 1}
                slide={slide}
                showConditions={this.showConditionsModal}
                showLoops={this.showLoopModal}
                showSingleSlide={this.showSingleSlide}
                status={this.props.status}
                toggleSlide={this.props.toggleSlide}
                showingSingleSlide={this.props.showSingleSlide}
                setScrollToSlideIdx={this.setScrollToSlideIdx}
                matchingInputs={this.props.matchingInputs}
                onInsertLibrarySlide={this.props.onInsertLibrarySlide}
                onRemoveLibrarySlide={this.props.onRemoveLibrarySlide}
                onCopyConditions={this.copyConditions}
                copiedConditions={this.state.conditionClauses}
                onSelectSlidesMatchingConditions={this.selectSlidesMatchingConditions}
              />
            );
          })}
        </div>
      </div>
    );
  }

  copyConditions = (conditionClauses) => {
    this.setState({ conditionClauses });
  };

  selectSlidesMatchingConditions = (conditionClauses) => {
    const hasSameConditions = (slide) => isEqual(slide.condition_clauses, conditionClauses);

    const matchingSlides = this.props.currentTemplate.slides.filter(hasSameConditions);
    this.props.toggleSlide(null, matchingSlides, true);
  };

  setScrollToSlideIdx = (slideIdx) => {
    this.setState({ scrollToSlideIdx: slideIdx });
  };

  openGoogleSlidesTab = () => {
    window.open(this.props.currentTemplate.source.url, '_blank');
  };

  reviewPermissions = (e) => {
    e.preventDefault();
    if (!this.props.ui.sidepane) {
      this.props.openSidepane({ entityType: 'permissions' }, 'template-sidepane');
    }
  };

  requestPermissions = (e) => {
    e.preventDefault();
    this.props.linkedObjects?.forEach((element) => {
      if (element.status_code !== 200) {
        let url = `https://docs.google.com/${element.type}/d/${element.id}/edit`;
        window.open(url, `${element.type} - ${element.id}`);
      }
    });
  };

  dismissTemplateBanner = (e) => {
    e.preventDefault();
    this.props.hideResyncWarning();
  };

  dismissPermsBanner = (e) => {
    e.preventDefault();
    this.props.hideLinkedObjectWarning();
  };

  setViewingSlide = (slide) => {
    this.setState({ viewingSlide: slide });
  };

  showSingleSlide = (e, selectedSlide) => {
    if (e) {
      e.preventDefault();
    }
    if (this.props.currentTemplate && !isEmpty(this.props.currentTemplate.pdf)) {
      if (!Array.isArray(selectedSlide)) {
        selectedSlide = [selectedSlide];
      }
      this.props.toggleSlide(e, selectedSlide, true);
      this.setViewingSlide(selectedSlide[0] || this.props.currentTemplate.slides[0]);
      this.props.setShowSingleSlide(true);
    }
  };

  hideSlides = (e, selectedSlides) => {
    this.context.selectContent(null);
    this.props.toggleSlide(e, selectedSlides, false);
    this.props.setShowSingleSlide(false);
  };

  showConditionsModal = (e) => {
    e.preventDefault();
    this.props.openModal('slideConditionsModal');
  };

  closeConditionsModal = () => {
    this.props.closeModal();
    this.props.resetSelectedSlides();
  };

  showTestSlideModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openModal('testSlideModal');
  };

  closeTestSlideModal = () => {
    this.props.closeModal();
  };

  onTestSlideCreate = (presentation) => {
    this.closeTestSlideModal();
    window.open(presentation.presentation_file.url, '_blank');
  };

  showLoopModal = (e, slide = null) => {
    e.preventDefault();

    if (slide) {
      const loop = getLoopedSlideInfo(slide, this.props.currentTemplate.slide_loops)?.loop;
      if (loop) {
        let loopedInput = Object.values(this.props.matchingInputs).find((input) => input.id === loop.parameter);
        if (!loopedInput) {
          loopedInput = { id: loop.parameter };
        }
        if (loopedInput) {
          this.props.onLoopClick(loopedInput);
        }
      }
    }
  };

  syncTemplate = (e) => {
    if (this.needsToConnect()) {
      return this.connectIntegration();
    }
    this.props.onUpdateTemplateFile(e);
    if (this.props.selectedSlides) {
      this.hideSlides(e, this.props.selectedSlides);
    }
    this.props.hideResyncWarning();
  };

  needsToConnect = () => {
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES) {
      const googleIntegration = utils.googleIntegration(this.props.userContext.user);
      return !googleIntegration || !googleIntegration.has_necessary_scopes;
    } else if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      const microsoftIntegration = utils.microsoftIntegration(this.props.userContext.user);
      return !microsoftIntegration || !microsoftIntegration.has_necessary_scopes;
    }
    return false;
  };

  connectIntegration = () => {
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      utils.connectMicrosoft(
        this.props.userContext.user,
        this.props.userContext.updateUser,
        this.syncTemplate,
        this.showMSPermsAlert,
      );
      return;
    }
    utils.connectGoogle(
      this.props.userContext.user,
      this.props.userContext.updateUser,
      this.syncTemplate,
      this.showPermsAlert,
    );
  };

  reconnectIntegration = () => {
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      utils.reconnectMicrosoft(
        this.props.userContext.user,
        this.props.userContext.updateUser,
        this.syncTemplate,
        this.showMSPermsAlert,
      );
      return;
    }
    utils.reconnectGoogle(
      this.props.userContext.user,
      this.props.userContext.updateUser,
      this.syncTemplate,
      this.showPermsAlert,
    );
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message = 'In order to sync your template, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectIntegration);
  };

  showMSPermsAlert = () => {
    const title = 'Grant Microsoft permissions to Matik';
    const message =
      'To sync PowerPoint template from Office 365, you must grant Matik the permissions requested on the next screen.';
    MInsufficientPermissions(title, message, 'microsoft', this.reconnectIntegration);
  };
}

TemplateSlides.propTypes = {
  allDynamicContent: PropTypes.object,
  canEdit: PropTypes.bool,
  currentTemplate: PropTypes.object,
  closeModal: PropTypes.func,
  dynamicContentTags: PropTypes.array,
  flags: PropTypes.object,
  fetchLibrariesIfNeeded: PropTypes.func,
  highlightedSlideNums: PropTypes.array,
  matchingInputs: PropTypes.object,
  onUpdateTemplateFile: PropTypes.func,
  openModal: PropTypes.func,
  openSidepane: PropTypes.func,
  resetSelectedSlides: PropTypes.func,
  selectedSlides: PropTypes.array,

  needsResync: PropTypes.bool,
  isResyncWarningShown: PropTypes.bool,
  hideResyncWarning: PropTypes.func,

  linkedObjects: PropTypes.array,
  needsLinkedObjectPermission: PropTypes.bool,
  isLinkedObjectWarningShown: PropTypes.bool,
  hideLinkedObjectWarning: PropTypes.func,

  setShowSingleSlide: PropTypes.func,
  showSingleSlide: PropTypes.bool,
  slideIdxByInputName: PropTypes.object,
  status: PropTypes.object,
  tags: PropTypes.object,
  template: PropTypes.object,
  toggleAllSlides: PropTypes.func,
  toggleSlide: PropTypes.func,
  ui: PropTypes.object,
  userContext: PropTypes.object,
  invalidateTemplate: PropTypes.func,
  onLoopClick: PropTypes.func,
  thumbnails: PropTypes.object,
  onInsertLibrarySlide: PropTypes.func,
  onRemoveLibrarySlide: PropTypes.func,
  filteredSlides: PropTypes.arrayOf(PropTypes.number),

  onConditionButtonClick: PropTypes.func,
};

TemplateSlides.contextType = ContentContext;

function mapStateToProps(state) {
  return Object.assign({}, mapUiStateToProps(state));
}

function mapDispatchToProps(dispatch) {
  return Object.assign({}, mapLibraryDispatchToProps(dispatch), mapUiDispatchToProps(dispatch));
}

const TemplateSlidesWrapper = (props) => {
  const { data } = useTemplateThumbnails(props.currentTemplate?.id, props.currentTemplate?.deleted);
  const { invalidate } = useTemplate();

  const {
    data: needsResync,
    isWarningShown: isResyncWarningShown,
    hideWarning: hideResyncWarning,
  } = useTemplateResyncStatus(props.currentTemplate);

  const {
    data: linkedObjectStatus,
    isWarningShown: isLinkedObjectWarningShown,
    hideWarning: hideLinkedObjectWarning,
  } = useTemplateLinkedObjectStatus(props.currentTemplate);

  return (
    <TemplateSlides
      {...props}
      thumbnails={data}
      invalidateTemplate={invalidate}
      needsResync={needsResync}
      hideResyncWarning={hideResyncWarning}
      isResyncWarningShown={isResyncWarningShown}
      linkedObjects={linkedObjectStatus?.linked_objects}
      needsLinkedObjectPermission={linkedObjectStatus?.should_update}
      hideLinkedObjectWarning={hideLinkedObjectWarning}
      isLinkedObjectWarningShown={isLinkedObjectWarningShown}
    />
  );
};
TemplateSlidesWrapper.propTypes = {
  currentTemplate: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(withUserContext(TemplateSlidesWrapper)));

const ConditionButton = ({
  template,
  selectedSlides,
  copiedConditions,
  onCopyConditions,
  onSelectSlidesMatchingConditions,
  onClick,
}) => {
  const menuRef = useRef();
  const buttonRef = useRef();

  const handleClick = () => {
    menuRef.current.toggle();
    onClick?.();
  };
  return (
    <div>
      <button
        ref={buttonRef}
        type="button"
        disabled={!selectedSlides?.length}
        className="button is-secondary"
        data-tooltip-id="matik-tooltip"
        data-tooltip-content={
          selectedSlides?.length > 0 ? 'Manage Conditions' : 'To edit conditions, please select a slide.'
        }
        data-tooltip-place="left"
        onClick={handleClick}
      >
        <Icon name="condition_arrow" size={16} />
      </button>
      <ConditionContextPopupMenu
        ref={menuRef}
        anchorRef={buttonRef}
        position="x"
        selectedSlides={selectedSlides}
        template={template}
        copiedConditions={copiedConditions}
        onCopyConditions={onCopyConditions}
        onSelectSlidesMatchingConditions={onSelectSlidesMatchingConditions}
      />
    </div>
  );
};
ConditionButton.propTypes = {
  template: PropTypes.object,
  selectedSlides: PropTypes.array,

  onCopyConditions: PropTypes.func,
  copiedConditions: PropTypes.object,
  onSelectSlidesMatchingConditions: PropTypes.func,
  onClick: PropTypes.func,
};
