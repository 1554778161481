import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TierComparison from './TierComparison';
import StripeCheckout from './StripeCheckout';
import PaymentsHeader from './PaymentsHeader';
import { withRouter } from 'react-router-dom';
import WithFetchUser from '../shared/WithFetchUser';

class UpgradeTier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 'comparison',
      tierId: null,
    };
  }

  render() {
    let body = null;
    if (this.state.stage === 'comparison') {
      body = <TierComparison user={this.props.user} onTierSelection={this.onTierSelection} />;
    } else if (this.state.stage === 'payment') {
      body = <StripeCheckout user={this.props.user} selectedTierId={this.state.tierId} />;
    }

    return (
      <div>
        <PaymentsHeader onBackClick={this.onBackClick} />
        {body}
      </div>
    );
  }

  onTierSelection = (e) => {
    this.setState({ stage: 'payment', tierId: e.target.getAttribute('tierid') });
  };

  onBackClick = () => {
    if (this.state.stage === 'comparison') {
      this.props.history.goBack();
    } else if (this.state.stage === 'payment') {
      this.setState({ stage: 'comparison', tierId: null });
    }
  };
}

UpgradeTier.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withRouter(WithFetchUser(UpgradeTier));
