import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TemplateSlide from '../consumer/TemplateSlide';
import { EndUserCreateContext } from './EndUserCreateContext';
import withUserContext from '../shared/WithUserContext';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useTemplatesThumbnails } from 'lib/hooks/useTemplate';

class TemplateSlides extends Component {
  render() {
    let isAllSelected = true;
    let isAllDeselected = true;
    for (let slideNum in this.context.selectedSlidesBySlideNum) {
      if (!this.context.selectedSlidesBySlideNum[slideNum]) {
        isAllSelected = false;
      }
      if (this.context.selectedSlidesBySlideNum[slideNum]) {
        isAllDeselected = false;
      }
      if (!isAllDeselected && !isAllSelected) {
        break;
      }
    }
    return (
      <div className="slides-wrapper pbxl">
        <div className="template-grid">
          {this.props.slides?.map((slide, idx) => {
            const thumbData = this.props.thumbnails?.[parseInt(slide.id)];
            const slideFetchStatus = thumbData && {
              data: thumbData,
              fetchState: 'fetched',
              errorMessage: '',
            };

            return (
              <TemplateSlide
                key={`${slide.id}_${idx}`}
                currentTemplate={this.props.currentTemplate}
                isLast={idx === this.props.slides.length - 1}
                selectedSlide={this.props.selectedSlide}
                slideFetchStatus={slideFetchStatus}
                slideNum={idx + 1}
                slide={slide}
                status={this.props.status}
                inputs={this.props.inputs}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

TemplateSlides.propTypes = {
  selectedSlide: PropTypes.object,
  status: PropTypes.object,
  currentTemplate: PropTypes.object,
  slides: PropTypes.array,
  flags: PropTypes.object,
  userContext: PropTypes.object,
  inputs: PropTypes.object,
  thumbnails: PropTypes.object,
};

TemplateSlides.contextType = EndUserCreateContext;

const TemplateSlidesWrapper = (props) => {
  const templateIds = new Set();
  if (props.currentTemplate?.id) {
    templateIds.add(props.currentTemplate.id);
  }
  // We also need the thumbnails for any ad-hoc library slide additions
  props.slides?.filter((s) => s.isAdded)?.forEach((s) => templateIds.add(s.template.id));

  const resultsByTemplateId = useTemplatesThumbnails([...templateIds]);
  const thumbnails = {};
  Object.values(resultsByTemplateId)
    .map((result) => result.data)
    .filter((data) => !!data)
    .forEach((data) => {
      for (const slideId in data) {
        thumbnails[slideId] = data[slideId];
      }
    });

  return <TemplateSlides {...props} thumbnails={thumbnails} />;
};
TemplateSlidesWrapper.propTypes = {
  currentTemplate: PropTypes.object,
  slides: PropTypes.array,
};

export default withUserContext(withLDConsumer()(TemplateSlidesWrapper));
