import React, { useState, useEffect } from 'react';
import Modal from 'components/shared/modal/Modal';
import { Form, Section } from 'react-bulma-components';
import utils from 'lib/utils';
import API from 'lib/api';
import trash_icon from '../../../images/trash.svg';
import PropTypes from 'prop-types';
import Button from '../../lib/Button';

function AddEmailsModal(props) {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [currentSubusers, setCurrentSubusers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sendgridInfo = props.enterprise?.integrations?.find((i) => i.name === 'sendgrid');
    if (sendgridInfo && sendgridInfo.auth?.subusers) {
      setCurrentSubusers(sendgridInfo.auth?.subusers);
    }
  }, [props.enterprise]);

  const onAddSuccess = (response) => {
    props.onEnterpriseUpdate(response.data, false);
    utils.notify('Email Address Added');
    setIsLoading(false);
  };

  const onDeleteSuccess = (response) => {
    props.onEnterpriseUpdate(response.data, false);
    utils.notify('Email Address Removed');
    setIsLoading(false);
  };

  const addEmail = (e) => {
    e.preventDefault();
    const emailValid = utils.isEmailValid(emailAddress);
    if (emailValid) {
      setIsLoading(true);
      const data = { subuser: emailAddress };
      API.post(`/enterprises/${props.enterprise.id}/add_mail_subuser/`, data, onAddSuccess);
    } else if (!emailValid) {
      setEmailErrorMsg('Invalid email address');
    }
  };

  const deleteEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = { subuser: e.currentTarget.id };
    API.post(`/enterprises/${props.enterprise.id}/delete_mail_subuser/`, data, onDeleteSuccess);
  };

  return (
    <Modal title="Manage From Email Addresses" show={props.show} onClose={props.onClose}>
      <Section className="is-paddingless">
        <form className="add-email-modal-top" onSubmit={addEmail}>
          <Form.Field className="add-email-input">
            <Form.Input placeholder="Type New Email Address" onChange={(e) => setEmailAddress(e.target.value)} />
            {emailErrorMsg && <p className="help is-danger">{emailErrorMsg}</p>}
          </Form.Field>
          <Form.Field>
            <Button status={isLoading ? 'loading' : 'default'}>Add Email Address</Button>
          </Form.Field>
        </form>
      </Section>
      <Section className="is-paddingless">
        {currentSubusers?.map((subuserEmail) => (
          <tr className="subuser-table-row" key={subuserEmail}>
            <td>{subuserEmail}</td>
            <td onClick={deleteEmail} className="subuser-delete" id={subuserEmail}>
              <img src={trash_icon} alt="Delete Access" />
            </td>
          </tr>
        ))}
      </Section>
    </Modal>
  );
}

AddEmailsModal.propTypes = {
  enterprise: PropTypes.object,
  onEnterpriseUpdate: PropTypes.func,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddEmailsModal;
