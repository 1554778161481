import React from 'react';
import PropTypes from 'prop-types';

export default function ToggleOff({ width = 28, height = 18 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="18" rx="9" fill="#E0E5EE" />
      <rect x="3" y="3" width="12" height="12" rx="6" fill="#FBFCFD" />
    </svg>
  );
}

ToggleOff.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
