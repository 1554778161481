import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Level, Form } from 'react-bulma-components';
import { Select } from '../FormSelect';
import draggable_icon from '../../../images/draggable.svg';
import { ReactComponent as TrashIcon } from '../../../svg/trash.svg';
import TextOverflowWithTooltip from '../TextOverflowWithTooltip';
import IconPill from 'components/lib/IconPill';

const PARAMETER_TYPE_TEXT = 'TEXT';
const PARAMETER_TYPE_SINGLE_SELECT = 'SINGLE_SELECT';

class NameComponent extends Component {
  render() {
    let componentType = this.props.componentTypes[this.props.nameComponent.componentType];
    let formElement = null;
    if (componentType.parameters) {
      const parameterValue = this.props.nameComponent?.parameters?.[componentType.parameters.parameterFieldName];
      if (componentType.parameters.inputType === PARAMETER_TYPE_TEXT) {
        formElement = (
          <Form.Field>
            <Form.Control>
              <Form.Input
                autoFocus
                value={parameterValue || ''}
                onChange={(e) => this.props.onValueChange(this.props.nameComponent.id, e.target.value)}
                placeholder="Enter plain text"
                className="tpn-input"
              />
              {this.props.nameComponent?.parameters?.error && (
                <div className="help is-danger">{this.props.nameComponent.parameters.error}</div>
              )}
            </Form.Control>
          </Form.Field>
        );
      } else if (componentType.parameters.inputType === PARAMETER_TYPE_SINGLE_SELECT) {
        let selectedOption = null;
        for (let option of componentType.parameters.options) {
          if (option.value === parameterValue) {
            selectedOption = option;
          }
        }
        formElement = (
          <Form.Field>
            <Form.Control>
              <Select
                onChange={(obj) => this.props.onValueChange(this.props.nameComponent.id, obj.value)}
                value={selectedOption}
                isSearchable={true}
                classNamePrefix="matik-select"
                aria-label={`${componentType.displayName} Select`}
                options={componentType.parameters.options}
                placeholder="Select input value"
                className="tpn-input"
              />
            </Form.Control>
          </Form.Field>
        );
      }
    }
    return (
      <div className="param-card !h-16">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <IconPill size="s" iconName={componentType.icon} theme="square" />
            </Level.Item>
            <Level.Item>
              <span className="prs tpn-component-name">{componentType.displayName}:</span>
              {formElement ? (
                formElement
              ) : (
                <TextOverflowWithTooltip className="tpn-preview text-overflow-ellipsis" text={componentType.preview} />
              )}
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <button className="button is-tertiary" onClick={() => this.props.onDelete(this.props.nameComponent.id)}>
                <TrashIcon />
              </button>
              <img src={draggable_icon} alt="Move name element" className="mll" />
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
    );
  }
}

NameComponent.propTypes = {
  nameComponent: PropTypes.object,
  onValueChange: PropTypes.func,
  onDelete: PropTypes.func,
  componentTypes: PropTypes.object,
};

export default NameComponent;
