import React from 'react';
import { UserContext } from '../UserContext';

export default function withUserContext(Component) {
  return function contextComponent(props) {
    return (
      <UserContext.Consumer>{(userContext) => <Component {...props} userContext={userContext} />}</UserContext.Consumer>
    );
  };
}
