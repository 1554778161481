import React, { useState } from 'react';

function HtmlBuilderColumnEmptyState() {
  const [isDragOver, setDragOver] = useState(false);

  const handleDragEnter = () => {
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = () => {
    setDragOver(false);
  };

  const emptyStateClasses = `html-builder-column-empty-state ${isDragOver ? 'drag-over' : ''}`;

  return (
    <>
      <div
        className={emptyStateClasses}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        Drop Modules
      </div>
    </>
  );
}

export default HtmlBuilderColumnEmptyState;
