import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleSheetUpload from './GoogleSheetUpload';
import utils from '../../../../lib/utils';
import { UserContext } from '../../../UserContext';

class GoogleSheetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      googleAuth: null,
      googleIntegration: null,
      isAuthenticatedToDataSource: false,
      selectedSheet: null,
    };
  }

  componentDidMount() {
    this.initState(null, true);
  }

  componentDidUpdate(prevProps) {
    this.initState(prevProps);
  }

  render() {
    return (
      <GoogleSheetUpload
        database={this.state.selectedSheet}
        handleSelectGoogleSheet={this.handleSelectGoogleSheet}
        oauthFunction={this.props.oauthFunction}
        auth={this.state.googleAuth}
        view="sheets"
        initState={this.initState}
      />
    );
  }

  handleSelectGoogleSheet = (data) => {
    if (data.docs) {
      this.props.onChange({ target: { name: 'host', value: JSON.stringify(data.docs[0]) } });
    }
  };

  initState = (prevProps, mount) => {
    if (mount || !prevProps || (prevProps && prevProps.host !== this.props.host)) {
      let googleAuth = null;
      const googleIntegration = utils.googleIntegration(this.context.user);
      if (googleIntegration) {
        googleAuth = {
          access_token: googleIntegration.access_token,
          expires_on: googleIntegration.expires_on,
          hasNecessaryScopes: googleIntegration.has_necessary_scopes,
        };
      }
      const isAuthenticatedToDataSource = !!googleAuth && googleAuth.hasNecessaryScopes;

      let selectedSheet = null;
      if (this.props.host) {
        try {
          selectedSheet = JSON.parse(this.props.host);
        } catch (err) {
          // Don't do anything here -- keep it set to null for now.
        }
      }
      this.setState({
        googleAuth,
        googleIntegration,
        isAuthenticatedToDataSource,
        selectedSheet,
      });
      this.props.setIsAuthenticatedToDataSource(isAuthenticatedToDataSource, selectedSheet);
    }
  };
}

GoogleSheetForm.propTypes = {
  auth: PropTypes.object,
  host: PropTypes.string,
  deleteDataSource: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selectedDataSource: PropTypes.object,
  submitForm: PropTypes.func,
  type: PropTypes.string,
  setIsAuthenticatedToDataSource: PropTypes.func,
  oauthFunction: PropTypes.func,
};

GoogleSheetForm.contextType = UserContext;

export default GoogleSheetForm;
