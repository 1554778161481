import axios from 'axios';
import { toast } from 'react-toastify';
import { MAlert } from '../components/shared/Alerts';

import { Auth } from './auth';
import Constants from '../components/Constants';
import utils from './utils';

axios.interceptors.request.use((config) => {
  const authToken = Auth.getAuthToken();
  if (
    authToken &&
    config.url !== API._generateUrlFromRelativeUrl('/auth/login/') &&
    config.url !== API._generateUrlFromRelativeUrl('/auth/logout/') &&
    config.url !== API._generateUrlFromRelativeUrl('/auth/refresh/')
  ) {
    return Auth.refreshToken()
      .then((authToken) => {
        if (config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }

        return Promise.resolve(config);
      })
      .catch((err) => {
        if ((err.response.data && err.response.data.error === 'ExpiredRefreshError') || err === 'no_token') {
          API.track('expired_refresh_error');
          Auth.clearAuthToken();
          window.location.reload();
        } else if (err.response.status === 403) {
          // TODO (Zak): Not sure why the refresh endpoint would throw a 403 but if it does, just bail out and
          // have the user sign in again.
          API.track('refresh 403', { err });
          Auth.clearAuthToken();
          window.location.reload();
        } else {
          MAlert(
            'Could not communicate with Matik servers. Check your internet connection',
            'Connection Error',
            'error',
          );
        }
        return Promise.reject(err);
      });
  }

  return config;
});

function apiFactory(apiPrefix) {
  return class _API {
    static defaultError(err) {
      if (err.response) {
        if (err.response.status === 401) {
          _API.track('unauthorized_401');
          Auth.clearAuthToken();
          window.location.reload();
        } else if (err.response.status === 403) {
          if (err.response.data?.error === 'MissingRoleError') {
            if (err.response.data.message.indexOf('producer_admin') >= 0) {
              window.location.assign('/');
            } else if (err.response.data.message.indexOf('producer') >= 0) {
              window.location.assign('/create');
            } else if (err.response.data.message.indexOf('consumer') >= 0) {
              window.location.assign('/');
            }
          } else if (err.response.data?.error === 'BlacklistedError') {
            Auth.clearAuthToken();
            window.location.reload();
          } else if (err.response.data?.error === 'MatikAuthorizationError') {
            MAlert('Authorization error', 'Error', 'error');
            _API.track('uncaught_client_side_403');
          } else {
            MAlert('Authorization error', 'Error', 'error');
            _API.track('uncaught_client_side_403_no_context');
          }
        } else if (err.response.status === 404) {
          MAlert('Endpoint not found: ' + err.message, 'Error', 'error');
        } else if (err.response.status === 400) {
          const message = err.response.data ? err.response.data.message : 'Malformed request';
          const title = err.response.data?.status ? err.response.data.status : 'Error';
          // eslint-disable-next-line no-console
          console.warn(err);
          MAlert(message, title, 'error');
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
          MAlert('An unexpected error occurred. Status: ' + err.response.status, 'Error', 'error');
        }
      } else if (err.request) {
        // eslint-disable-next-line no-console
        console.error(err.request);
        _API.track(`no_response_received: ${err.request}`);
        MAlert('No response received', 'Error', 'error');
      } else {
        // eslint-disable-next-line no-console
        console.warn(err);
        MAlert('An unexpected error occurred. ' + err.message, 'Error', 'error');
      }
    }

    static post(relativeUrl, data, success, error, files, onProgress) {
      if (files !== undefined) {
        const postData = new FormData();
        postData.set('data', JSON.stringify(data));
        postData.set('file', files);
        return _API.requestWithFiles('post', relativeUrl, postData, success, error, onProgress);
      } else {
        return _API.request('post', relativeUrl, data, success, error);
      }
    }

    static get(relativeUrl, success, error, data = null, abortController) {
      if (data) {
        return _API.request('get', relativeUrl, data, success, error);
      } else {
        return _API.requestNoData('get', relativeUrl, success, error, abortController);
      }
    }

    static getMultiple(relativeUrls, success, error) {
      return _API.requestMultipleNoData('get', relativeUrls, success, error);
    }

    static delete(relativeUrl, success, error) {
      return _API.requestNoData('delete', relativeUrl, success, error);
    }

    static archive(relativeUrl, success, error) {
      return API.requestNoData('archive', relativeUrl, success, error);
    }

    static put(relativeUrl, data, success, error, files, onProgress) {
      if (files !== undefined) {
        const postData = new FormData();
        postData.set('data', JSON.stringify(data));
        postData.set('file', files);
        return _API.requestWithFiles('put', relativeUrl, postData, success, error, onProgress);
      } else {
        return _API.request('put', relativeUrl, data, success, error);
      }
    }

    static requestMultipleNoData(method, relativeUrls, success, error) {
      const errorFunc = error || _API.defaultError;
      return axios
        .all(
          relativeUrls.map((relativeUrl) =>
            axios[method](_API._generateUrlFromRelativeUrl(relativeUrl), {
              headers: { Authorization: `Bearer ${Auth.getAuthToken()}` },
            }),
          ),
        )
        .then(axios.spread(success))
        .catch(errorFunc);
    }

    static requestNoData(method, relativeUrl, success, error, abortController) {
      const errorFunc = error || _API.defaultError;
      return axios[method](_API._generateUrlFromRelativeUrl(relativeUrl), {
        headers: { Authorization: `Bearer ${Auth.getAuthToken()}` },
        signal: abortController?.signal,
      })
        .then(success)
        .catch(errorFunc);
    }

    static requestWithFiles(method, relativeUrl, data, success, error, onProgress) {
      const errorFunc = error || _API.defaultError;
      const onUploadProgress = onProgress || function () {};
      return axios[method](_API._generateUrlFromRelativeUrl(relativeUrl), data, {
        headers: {
          Authorization: `Bearer ${Auth.getAuthToken()}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgress,
      })
        .then(success)
        .catch(errorFunc);
    }

    static requestWithDownload(method, relativeUrl, responseType, success, error) {
      const errorFunc = error || _API.defaultError;
      return axios[method](_API._generateUrlFromRelativeUrl(relativeUrl), {
        headers: { Authorization: `Bearer ${Auth.getAuthToken()}` },
        responseType,
      })
        .then(success)
        .catch(errorFunc);
    }

    static request(method, relativeUrl, data, success, error, specifiedType) {
      const errorFunc = error || _API.defaultError;

      return axios[method](_API._generateUrlFromRelativeUrl(relativeUrl), data, {
        headers: { Authorization: `Bearer ${Auth.getAuthToken()}` },
        responseType: specifiedType,
      })
        .then(success)
        .catch(errorFunc);
    }

    static track(eventName, properties = {}, userId = null) {
      const authToken = Auth.getAuthToken();
      if (!authToken && !userId) {
        return false;
      }
      const data = {
        event_name: eventName,
        properties: {
          httpReferrer: document.referrer,
          location: document.location.href,
          sessionId: Auth.getSessionId(),
          ...properties,
        },
      };
      if (userId) {
        data.user_id = userId;
      }
      const headers = userId ? null : { headers: { Authorization: `Bearer ${Auth.getAuthToken()}` } };
      axios
        .post(API._generateUrlFromRelativeUrl('/events/'), data, headers)
        .then(() => {})
        // eslint-disable-next-line no-console
        .catch((err) => console.warn(err));
    }

    static _generateUrlFromRelativeUrl(relativeUrl) {
      // eslint-disable-next-line no-undef
      return `${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/${apiPrefix}${relativeUrl}`;
    }

    static getCountFromResponse(response) {
      const contentRange = response.headers['content-range'];
      let count = null;
      if (contentRange) {
        count = contentRange.split('/')[1];
      }

      return count;
    }

    static fetchContent(
      baseUrl,
      beforeRequest,
      onSuccess,
      offset = 0,
      limit = Constants.PAGE_SIZE,
      sort = null,
      filter = null,
    ) {
      beforeRequest();
      let url = _API.generate_paginated_url(baseUrl, offset, limit, sort, filter);
      _API.get(
        url,
        (response) => {
          const count = _API.getCountFromResponse(response);
          const pagination = utils.getPaginationFromRequest(count, limit, offset, sort);
          onSuccess(response.data, pagination);
        },
        _API.defaultError,
      );

      return Promise.resolve();
    }

    static generate_paginated_url(baseUrl, offset, limit, sort, filter) {
      const delimiter = baseUrl.includes('?') ? '&' : '?';
      let url = `${baseUrl}${delimiter}range=[${offset},${offset + limit}]`;
      if (sort) {
        url += `&sort=["${sort[0]}", "${sort[1]}"]`;
      }
      if (filter) {
        url += `&filter=${filter}`;
      }
      return url;
    }

    static redirectOnError(history, url, error) {
      history.push(url);
      utils.notify(`An error occurred: ${error}`, toast.TYPE.ERROR);
    }
  };
}

export class API extends apiFactory('_api') {
  static postExternal(relativeUrl, data, success, error) {
    const errorFunc = error || API.defaultError;
    return axios
      .post(API._generateExternalUrlFromRelativeUrl(relativeUrl), data, {
        headers: {
          Authorization: `Bearer ${Auth.getAuthToken()}`,
          'Content-Type': 'multipart/form-data',
        },
        maxRedirects: 0,
      })
      .then(success)
      .catch(errorFunc);
  }

  static _generateExternalUrlFromRelativeUrl(relativeUrl) {
    // eslint-disable-next-line no-undef
    return `${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/api/v1${relativeUrl}`;
  }

  static setUserProfile(updateLastLogin) {
    const authToken = Auth.getAuthToken();
    if (!authToken) {
      return false;
    }
    let data = {};
    if (updateLastLogin) {
      data['updateLastLogin'] = true;
    }
    return (
      axios
        .post(API._generateUrlFromRelativeUrl('/events/profile/'), data, {
          headers: { Authorization: `Bearer ${Auth.getAuthToken()}` },
        })
        .then((result) => {
          if (window.Appcues) {
            window.Appcues.identify(Auth.getId(), result.data.userData);
          }
          if (window.FS && result.data.userData) {
            window.FS.identify(Auth.getId(), { email: result.data.userData.$email });
          }
          return result.data.userData;
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.warn(err))
    );
  }
}

export const AssistantAPI = apiFactory('_assistant');

export default API;
