import {
  ADD_USER,
  CLEAR_SELECTED_ENTERPRISE_USER,
  DELETE_USER,
  END_WORK,
  RECEIVE_ANALYTICS_USER,
  RECEIVE_ANALYTICS_USERS,
  RECEIVE_USER,
  RECEIVE_USERS,
  REQUEST_ANALYTICS_USER,
  REQUEST_ANALYTICS_USERS,
  REQUEST_USER,
  REQUEST_USERS,
  START_WORK,
  UPDATE_USER,
} from './actionTypes';
import { map } from 'lodash';

export function fetchedAnalyticsUsers(state = { isFetching: true }, action) {
  switch (action.type) {
    case REQUEST_ANALYTICS_USER:
    case REQUEST_ANALYTICS_USERS:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVE_ANALYTICS_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        users: action.users,
        pagination: action.pagination,
      });
    case RECEIVE_ANALYTICS_USER:
      return Object.assign({}, state, {
        isFetching: false,
        selectedUser: action.user,
      });
    default:
      return state;
  }
}

export function fetchedEnterpriseUsers(state = { isFetching: true }, action) {
  switch (action.type) {
    case REQUEST_USER:
    case REQUEST_USERS:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVE_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        users: action.users,
        pagination: action.pagination,
      });
    case RECEIVE_USER:
      return Object.assign({}, state, {
        isFetching: false,
        selectedUser: action.user,
      });
    case CLEAR_SELECTED_ENTERPRISE_USER:
      return Object.assign({}, state, { selectedUser: null });
    case START_WORK:
      return Object.assign({}, state, { isWorking: true });
    case END_WORK:
      return Object.assign({}, state, { isWorking: false });
    case ADD_USER:
      return Object.assign({}, state, { isWorking: false, users: addUser(action.newUser, state.users) });
    case DELETE_USER:
      return Object.assign({}, state, {
        isWorking: false,
        users: state.users.filter((user) => user.id !== action.userId),
      });
    case UPDATE_USER:
      return Object.assign({}, state, {
        isWorking: false,
        users: map(state.users, (user) => (user.id === action.updatedUser.id ? action.updatedUser : user)),
      });
    default:
      return state;
  }
}

function addUser(newUser, users) {
  if (!users) {
    return [newUser];
  }

  const updatedUsers = users.slice();
  updatedUsers.unshift(newUser);

  return updatedUsers;
}
