import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import InputForm from '../dynamicContent/InputForm';
import InputsSidebar from './InputsSidebar';
import utils from '../../../lib/utils';

class InputsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInput: null,
    };
  }

  componentDidMount() {
    this.setCurrentInput();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setCurrentInput();
    }
  }

  render() {
    let inputsByName = {};
    for (let input of this.props.inputs) {
      inputsByName[input.name] = input;
    }

    const formSaveButtonText = this.state.currentInput ? 'Update' : 'Add';

    return (
      <div className="wrapper">
        <InputsSidebar {...this.props} currentInput={this.state.currentInput} />
        <div className="input-form-main">
          <InputForm
            key={this.state.currentInput ? this.state.currentInput.id : 0}
            allowNameChange={true}
            dataSources={this.props.dataSources}
            entityId={`${this.state.currentInput ? this.state.currentInput.id : 0}`}
            entityType="input"
            existingInputs={inputsByName}
            headerLinkDisabled={true}
            input={this.state.currentInput}
            onInputAdd={this.onInputAdd}
            onInputUpdate={this.onInputUpdate}
            onInputDelete={this.props.onInputDelete}
            saveButtonText={formSaveButtonText}
            showDelete={!!this.state.currentInput}
          />
        </div>
      </div>
    );
  }

  onInputAdd = (newInput) => {
    this.props.history.push(`/inputs/${newInput.id}`);
    this.props.onInputAdd(newInput);
  };

  onInputUpdate = (updatedInput) => {
    this.setState({ currentInput: updatedInput });
    this.props.onInputUpdate(updatedInput);
  };

  setCurrentInput = () => {
    utils.getItemFromId(
      this.props.match.params.id,
      this.state.currentInput,
      this.props.inputs,
      '/parameters/',
      (currentInput) => this.setState({ currentInput }),
      () => this.props.history.replace('/inputs/'),
      () => this.setState({ currentInput: null }),
    );
  };
}

InputsMain.propTypes = {
  dataSources: PropTypes.array,
  fetchItems: PropTypes.func,
  history: PropTypes.object,
  inputs: PropTypes.array,
  isPaginating: PropTypes.bool,
  match: PropTypes.any,
  onInputAdd: PropTypes.func,
  onInputUpdate: PropTypes.func,
  onInputDelete: PropTypes.func,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
};

export default withRouter(InputsMain);
