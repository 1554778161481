import { bindActionCreators } from 'redux';
import { fetchLibrariesIfNeeded } from './action';

const mapLibraryDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchLibrariesIfNeeded,
    },
    dispatch,
  );
};

export { mapLibraryDispatchToProps };
