import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ListItemCondition from './ListItemCondition';
import AssetItem from 'components/lib/AssetItem';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import ListItemDynamicContent from 'components/producer/dynamicContent/ListItemDynamicContent';

const Clause = ({ clause, dynamicContentById }) => {
  const content = dynamicContentById?.[clause.dynamicContent];
  return (
    <div className="flex gap-1">
      <div className="rounded-md bg-grey-100 px-2 py-1 leading-none text-grey-500">
        <ListItemDynamicContent size="s" content={content} />
      </div>
      <div className="rounded-md bg-grey-100 p-2 leading-none text-grey-500">{clause.operator}</div>
      {clause.operator !== 'is null' && clause.operator !== 'is not null' && (
        <div className="rounded-md bg-grey-100 p-2 leading-none text-grey-500">{clause.operand}</div>
      )}
    </div>
  );
};
Clause.propTypes = {
  clause: PropTypes.shape({
    dynamicContent: PropTypes.number,
    operator: PropTypes.string,
    operand: PropTypes.any,
  }),
  dynamicContentById: PropTypes.object,
};

const Condition = ({ condition, dynamicContentById }) => {
  return (
    <div className="grid grid-cols-[auto_1fr] gap-1.5">
      {condition.clauses.map((clause, index) => (
        <Fragment key={index}>
          <div className="border-grey-200 border p-2 rounded-md text-grey-500 leading-none text-center">
            {index === 0 ? 'When' : 'And'}
          </div>
          <Clause clause={clause} dynamicContentById={dynamicContentById} />
        </Fragment>
      ))}
    </div>
  );
};
Condition.propTypes = {
  condition: PropTypes.shape({
    clauses: PropTypes.arrayOf(Clause.propTypes.clause),
  }),
  dynamicContentById: PropTypes.object,
};

const ConditionCriteria = ({ conditions, dynamicContentById }) => {
  if (!conditions) {
    return null;
  }
  // OR each condition, AND each clause
  return (
    <div className="flex gap-2 flex-col items-start">
      {conditions.map((condition, index) => (
        <>
          {index > 0 && (
            <div className="border-grey-200 border p-2 rounded-md text-grey-500 leading-none inline-block">Or</div>
          )}
          <Condition key={index} condition={condition} dynamicContentById={dynamicContentById} />
        </>
      ))}
    </div>
  );
};
ConditionCriteria.propTypes = {
  conditions: PropTypes.arrayOf(Condition.propTypes.condition),
  dynamicContentById: PropTypes.object,
};

const ConditionGroupItem = ({ condition, onDeleteClick, dynamicContentById }) => {
  const [isExpanded, setExpanded] = useState(false);

  if (!condition) {
    return null;
  }

  return (
    <AssetItem
      className="group"
      bottom={
        isExpanded ? (
          <ConditionCriteria conditions={condition.conditions?.conditions} dynamicContentById={dynamicContentById} />
        ) : null
      }
    >
      <ListItemCondition condition={condition} showIndicator showSubtitle={!isExpanded} />
      {/* show the buttons on hover or always when it's expanded */}
      <div className={`flex gap-2 ${!isExpanded ? 'invisible group-hover:visible' : ''}`}>
        <Button category="tertiary" size="small" width="square" onClick={() => setExpanded(!isExpanded)}>
          <Icon name={`${isExpanded ? 'chevron_up' : 'chevron_down'}`} theme="regular" size={16} />
        </Button>
        <Button category="tertiary" size="small" width="square" onClick={() => onDeleteClick(condition.id)}>
          <Icon name="trash_can" theme="regular" size={16} />
        </Button>
      </div>
    </AssetItem>
  );
};
ConditionGroupItem.propTypes = {
  onDeleteClick: PropTypes.func,
  condition: PropTypes.shape({
    id: PropTypes.number,
    conditions: PropTypes.shape({
      conditions: ConditionCriteria.propTypes.conditions,
    }),
  }),
  dynamicContentById: PropTypes.object,
};

export default ConditionGroupItem;
