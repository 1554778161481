import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-bulma-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import PhotoWithUpload from '../shared/PhotoWithUpload';
import Pluralize from 'pluralize';

class PageWrapper extends Component {
  render() {
    return (
      <div className="wrapper with-navbar">
        <div className="container is-fluid mhn">
          <Box className="is-paddingless is-shadowless">
            {this.renderTopInfo()}
            {this.renderTabs()}
          </Box>
          {this.props.children}
        </div>
      </div>
    );
  }

  renderTopInfo() {
    return (
      <div className="level">
        <div className="level-left pll">
          <div className="level-item has-text-centered ptm">
            <PhotoWithUpload
              uploadText={this.props.enterprise && this.props.enterprise.logo_url ? 'Update logo' : 'Add logo'}
              uploadUrl={`/enterprises/${this.props.enterprise.id}/upload/`}
              onUpload={this.props.onEnterpriseUpdate}
              photoUrl={this.props.enterprise.logo_url}
            />
            <p className="title plm">{this.props.enterprise.name}</p>
          </div>
        </div>
        <div className="level-right enterprise-stats">
          <div className="level-item">
            <Link to="/enterprise_settings/users">
              <p className="title">{this.props.enterprise.users_count}</p>
              <p className="has-text-grey-dark">Active {Pluralize('User', this.props.enterprise.users_count, false)}</p>
            </Link>
          </div>
          <div className="level-item">
            <Link to="/data_sources">
              <p className="title">{this.props.enterprise.data_sources_count}</p>
              <p className="has-text-grey-dark">
                Data {Pluralize('Source', this.props.enterprise.data_sources_count, false)}
              </p>
            </Link>
          </div>
          <div className="level-item">
            <Link to="/dynamic_content">
              <p className="title">{this.props.enterprise.dynamic_content_count}</p>
              <p className="has-text-grey-dark">Dynamic Content</p>
            </Link>
          </div>
          <div className="level-item">
            <Link to="/templates">
              <p className="title">{this.props.enterprise.templates_count}</p>
              <p className="has-text-grey-dark">
                {Pluralize('Template', this.props.enterprise.templates_count, false)}
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderTabs() {
    const settingsUrl = '/enterprise_settings';
    const userUrl = '/enterprise_settings/users';
    const groupsUrl = '/enterprise_settings/groups';
    const clientsUrl = '/enterprise_settings/clients';
    return (
      <div className="tabs is-medium pll">
        <ul>
          <li className={this.props.currentUrl === settingsUrl ? 'is-active' : ''}>
            <Link to={settingsUrl}>Settings</Link>
          </li>
          <li className={this.props.currentUrl === userUrl ? 'is-active' : ''}>
            <Link to={userUrl}>Users</Link>
          </li>
          <li className={this.props.currentUrl === groupsUrl ? 'is-active' : ''}>
            <Link to={groupsUrl}>Groups</Link>
          </li>
          {this.props.flags.enableClientManagement && (
            <li className={this.props.currentUrl === clientsUrl ? 'is-active' : ''}>
              <Link to={clientsUrl}>Clients</Link>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

PageWrapper.propTypes = {
  children: PropTypes.any,
  currentUrl: PropTypes.string,
  enterprise: PropTypes.object,
  flags: PropTypes.object,
  onEnterpriseUpdate: PropTypes.func,
};

export default withLDConsumer()(PageWrapper);
