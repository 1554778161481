import React from 'react';
import { Card, Heading } from 'react-bulma-components';
import { useDispatch } from 'react-redux';
import { openFullScreenModal, openModal } from 'redux/ui/action';
import PropTypes from 'prop-types';
import ButtonGroup from '../lib/ButtonGroup';
import Button from '../lib/Button';
import Tag from '../lib/Tag';

function MatikMailSettingsCard(props) {
  const dispatch = useDispatch();

  const authOnClick = () => {
    dispatch(openFullScreenModal('domainAuthModal'));
  };

  const addEmailsOnClick = () => {
    dispatch(openModal('addEmailsModal'));
  };

  const sendgridIntegration = props.enterprise.integrations.find((int) => int.name === 'sendgrid');
  const buttonText = sendgridIntegration?.auth?.dns ? 'View & Edit Record' : 'Start Authentication';
  const pillText = sendgridIntegration?.auth?.domain_authenticated ? 'Validation needed' : 'Domain not authenticated';

  return (
    <Card className="is-shadowless mbl enterprise-settings-card">
      <Card.Header className="pal is-shadowless">
        <Card.Header.Title className="p-0 ">
          <Heading size={5}>Matik Mail</Heading>
        </Card.Header.Title>
        {!sendgridIntegration?.auth.domain_validated && (
          <Card.Header.Icon className="p-0">
            <Tag label={pillText} color="red" id="dynamic-content-inputs-used" />
          </Card.Header.Icon>
        )}
      </Card.Header>
      <Card.Content>
        <p className="mail-settings-text">Authenticate your domain with Sendgrid to send emails</p>
        <ButtonGroup align="left">
          {!sendgridIntegration?.auth.domain_validated && sendgridIntegration?.auth?.dns && (
            <Button category="secondary" onClick={authOnClick}>
              Validate
            </Button>
          )}
          <Button category="secondary" onClick={authOnClick}>
            {buttonText}
          </Button>
        </ButtonGroup>
        <div className="mail-settings-divider"></div>
        <Button category="secondary" onClick={addEmailsOnClick}>
          Manage From Email Addresses
        </Button>
      </Card.Content>
    </Card>
  );
}

MatikMailSettingsCard.propTypes = {
  enterprise: PropTypes.object,
};

export default MatikMailSettingsCard;
