import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bulma-components';
import API from '../../../lib/api';
import LongRequest from '../../../lib/longRequest';
import PageLoader from '../../shared/PageLoader';
import utils from '../../../lib/utils';
import { UserContext } from '../../UserContext';
import { withRouter } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { MInsufficientPerms } from '../../../components/shared/Alerts';
import Constants from '../../Constants';

class SampleContentLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    return (
      <div>
        <Container className="is-fluid has-text-centered pts">
          <a href="#start" className="button is-primary is-size-5" onClick={this.connectGoogle}>
            Start with a sample
          </a>
        </Container>
        <PageLoader title="Creating Sample Presentation" isActive={this.state.isLoading} />
      </div>
    );
  }

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message =
      'In order to copy a sample template into your Google Drive, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectGoogle);
  };

  connectGoogle = () => {
    utils.connectGoogle(
      this.context.user,
      this.context.updateUser,
      this.onChooseSampleContentClick,
      this.showPermsAlert,
    );
  };

  reconnectGoogle = () => {
    utils.reconnectGoogle(
      this.context.user,
      this.context.updateUser,
      this.onChooseSampleContentClick,
      this.showPermsAlert,
    );
  };

  onChooseSampleContentClick = () => {
    this.setState({ isLoading: true, processingStatus: 'Uploading' });
    const template_id = Constants.useSample.template_id;
    API.track('create_sample_content_click');
    const onSuccess = (response, onComplete) => {
      if (response.data.template_status === 'done' && this.state.processingStatus !== 'done') {
        this.setState({
          isLoading: false,
          selectedFile: null,
          name: '',
          description: '',
          processingStatus: response.data.template_status,
        });
        if (this.templateForm) {
          this.templateForm.reset();
        }
        onComplete();
        this.props.history.push('/templates');
      } else if (response.data.template_status === 'unknown') {
        // This may happen if there is a race condition: another user loads the templates page in between status checks
        onComplete();
        window.location.reload();
      } else {
        this.setState({ processingStatus: response.data.template_status });
      }
    };

    const sampleTemplateLongRequest = new LongRequest('/templates');
    API.post(
      '/sample_content/create_sample_content/',
      {},
      () => {
        sampleTemplateLongRequest.post({}, onSuccess, this.submitError, null, null, `copy_sample/${template_id}`);
      },
      this.submitError,
    );
  };

  submitError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };
}

SampleContentLink.propTypes = {
  history: PropTypes.object,
  flags: PropTypes.object,
};

SampleContentLink.contextType = UserContext;

export default withRouter(withLDConsumer()(SampleContentLink));
