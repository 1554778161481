import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Level, Media, Heading, Columns, Container } from 'react-bulma-components';
import UserAvatar from 'react-user-avatar';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import UpdateAvatarModal from './UpdateAvatarModal';
import ChangePassword from '../auth/ChangePassword';
import SmallLoader from './SmallLoader';
import DataSourceLogin from '../consumer/DataSourceLogin';
import EditableField from './EditableField';
import default_profile_icon from '../../images/default_profile.svg';
import UserProfileWrapper from './UserProfileWrapper';
import Constants from '../Constants';
import NotificationPreferences from './notifications/NotificationPreferences';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import UserProfileConnections from './UserProfileConnections';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDataSources: undefined,
      userStats: undefined,
    };
  }

  componentDidMount() {
    if (this.props.consumerProfile) {
      API.get(
        '/user_data_sources/',
        (response) => {
          const userDataSources = response.data && response.data.length > 0 ? response.data : [];
          this.setState({ userDataSources });
        },
        API.defaultError,
      );
    }
    API.get(
      '/users/me/stats/',
      (response) => {
        this.setState({ userStats: response.data });
      },
      API.defaultError,
    );
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={`${this.props.match.path}/security`} render={this.renderSecurity} />
          <Route path={`${this.props.match.path}/notifications`} render={this.renderNotifications} />
          <Route path={`${this.props.match.path}/connections`} render={this.renderConnections} />
          <Route path={`${this.props.match.path}`} render={this.renderPersonalInformation} />
        </Switch>
        <UpdateAvatarModal
          show={this.props.ui.modal?.name === 'updateAvatarModal'}
          onClose={this.props.closeModal}
          onUserAvatarUpdate={this.onUpdateAvatar}
        />
      </React.Fragment>
    );
  }

  renderPersonalInformation = (props) => {
    const imgSrc = this.props.user && this.props.user.photo_url ? this.props.user.photo_url : default_profile_icon;
    const imgText = this.props.user && this.props.user.photo_url ? 'Change Image' : 'Add Image';
    return (
      <UserProfileWrapper
        user={this.props.user}
        updateUser={this.props.updateUser}
        currentUrl={props.match.url}
        consumerProfile={this.props.consumerProfile}
        updateAvatar={this.updateAvatar}
        userStats={this.state.userStats}
      >
        <Box className="user-profile">
          <Media className="is-vertical-centered pal light-bottom-border">
            {this.props.user && (
              <React.Fragment>
                <UserAvatar colors={Constants.AVATAR_COLORS} size={100} name={this.props.user.email} src={imgSrc} />
                <div className="plm has-cursor-pointer" onClick={this.updateAvatar}>
                  {imgText}
                </div>
              </React.Fragment>
            )}
          </Media>
          <Media.Item className="pal">
            <Columns>
              <Columns.Column>
                <Heading size={5} className="mbs">
                  Name:
                </Heading>
                <EditableField
                  value={this.props.user && this.props.user.name ? this.props.user.name : ''}
                  onSave={(newName) => this.onUserFieldChange('name', newName)}
                  placeholder="Click to add name"
                  canEdit={true}
                  id="profileName"
                />
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Heading size={5} className="mbs">
                  Title:
                </Heading>
                <EditableField
                  value={this.props.user && this.props.user.title ? this.props.user.title : ''}
                  onSave={(newName) => this.onUserFieldChange('title', newName)}
                  placeholder="Click to add title"
                  canEdit={true}
                  id="profileTitle"
                />
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Heading size={5} className="mbs">
                  Email:
                </Heading>
                {this.props.user && <div>{this.props.user.email}</div>}
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Heading size={5} className="mbs">
                  Phone:{' '}
                </Heading>
                <EditableField
                  value={this.props.user && this.props.user.phone ? this.props.user.phone : ''}
                  onSave={(newName) => this.onUserFieldChange('phone', newName)}
                  placeholder="Click to add phone"
                  canEdit={true}
                  id="profilePhone"
                />
              </Columns.Column>
            </Columns>
          </Media.Item>
        </Box>
      </UserProfileWrapper>
    );
  };

  renderSecurity = (props) => {
    return (
      <UserProfileWrapper
        user={this.props.user}
        updateUser={this.props.updateUser}
        currentUrl={props.match.url}
        consumerProfile={this.props.consumerProfile}
        updateAvatar={this.updateAvatar}
        userStats={this.state.userStats}
      >
        <Box className="user-profile">
          <Heading size={4} className="pal light-bottom-border">
            Change Password
          </Heading>

          <ChangePassword />
        </Box>
        {this.props.consumerProfile && this.renderUserDataSources()}
      </UserProfileWrapper>
    );
  };

  renderNotifications = (props) => {
    return (
      <UserProfileWrapper
        user={this.props.user}
        updateUser={this.props.updateUser}
        currentUrl={props.match.url}
        consumerProfile={this.props.consumerProfile}
        updateAvatar={this.updateAvatar}
        userStats={this.state.userStats}
      >
        <Box className="user-profile">
          <Heading size={4} className="pal light-bottom-border">
            Notification Preferences
          </Heading>
          <NotificationPreferences />
        </Box>
      </UserProfileWrapper>
    );
  };

  renderConnections = (props) => {
    return (
      <UserProfileConnections
        id={this.props.user ? this.props.user.id : 0}
        user={this.props.user}
        updateUser={this.props.updateUser}
        match={this.props.match}
        userStats={this.state.userStats}
        consumerProfile={this.props.consumerProfile}
        {...props}
      />
    );
  };

  renderUserDataSources = () => {
    let body = '';
    if (this.state.userDataSources === undefined) {
      body = <SmallLoader />;
    } else {
      if (this.state.userDataSources.length === 0) {
        body = <p>No data source logins</p>;
      } else {
        body = (
          <React.Fragment>
            {this.state.userDataSources.map((userDataSource) => (
              <Level className="is-block" key={userDataSource.id}>
                <DataSourceLogin
                  dataSource={userDataSource}
                  onUserDataSourceUpdate={this.onUserDataSourceUpdate}
                  onUserDataSourceDelete={this.onUserDataSourceDelete}
                  onClose={this.props.closeModal}
                  nextPrevButtons=""
                />
              </Level>
            ))}
          </React.Fragment>
        );
      }
    }
    return (
      <Container>
        <Box className="user-profile">
          <Heading size={4} className="pal light-bottom-border">
            Data Sources
          </Heading>
          <div className="phl pbl lined-form">{body}</div>
        </Box>
      </Container>
    );
  };

  onUserFieldChange = (fieldName, updatedField) => {
    const data = { [fieldName]: updatedField };
    API.put(
      '/users/me/',
      data,
      (response) => {
        if (fieldName === 'name') {
          API.setUserProfile(false);
        }
        this.props.updateUser(response.data);
      },
      API.defaultError,
      null,
    );
  };

  onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const targetErrorState = [e.target.name] + 'Error';
    this.setState({
      [e.target.name]: value,
      [targetErrorState]: false,
      submitError: '',
    });
  };

  updateAvatar = (e) => {
    e.preventDefault();
    this.props.openModal('updateAvatarModal');
  };

  onUpdateAvatar = (updatedUser) => {
    this.props.closeModal();
    this.props.updateUser(updatedUser);
  };

  onUserDataSourceUpdate = (newUserDataSource) => {
    const updatedUserDataSources = [];
    for (let dataSource of this.state.userDataSources) {
      if (dataSource.id === newUserDataSource.id) {
        updatedUserDataSources.push(newUserDataSource);
      } else {
        updatedUserDataSources.push(dataSource);
      }
    }
    this.setState({ userDataSources: updatedUserDataSources });
  };

  onUserDataSourceDelete = (userDataSourceId) => {
    const updatedUserDataSources = [];
    for (let dataSource of this.state.userDataSources) {
      if (dataSource.id !== userDataSourceId) {
        updatedUserDataSources.push(dataSource);
      }
    }
    this.setState({ userDataSources: updatedUserDataSources });
  };
}

UserProfile.propTypes = {
  id: PropTypes.number.isRequired,
  consumerProfile: PropTypes.bool,
  updateUser: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.any,
  flags: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(UserProfile);
