import React, { useState } from 'react';
import Constants from 'components/Constants';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import Icon from 'components/lib/Icon';
import PropTypes from 'prop-types';
import Indicator from 'components/lib/Indicator';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
function EmailSubjectFormField({ subject, onSubjectFieldChange }) {
  const [subjectLength, setSubjectLength] = useState(subject.length);
  const [showField, setShowField] = useState(false);
  const { dynamicContentById } = useAllDynamicContentById();

  const onChange = (value) => {
    setSubjectLength(value.length);
    onSubjectFieldChange(value);
  };

  const toggleExpandField = () => {
    setShowField(!showField);
  };

  const dynamicContentList = Object.values(dynamicContentById || {});
  const textOnlyDynamicContent = dynamicContentList
    ? dynamicContentList.filter((dc) => dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT)
    : [];

  const wrappedDynamicContentTags = textOnlyDynamicContent.map((dc) => dc.name + '}}');
  const dynamicContentOptions = { '{{': wrappedDynamicContentTags };
  const chevronName = showField ? 'chevron_down' : 'chevron_right';
  const headerClass = showField ? '' : 'bottom-radius';
  const statusColor = subject.length > 0 ? 'green-500' : 'red-500';
  return (
    <>
      <div className={`email-form-field-header ${headerClass}`} onClick={toggleExpandField}>
        <div className="email-form-field-header-left">
          <Indicator color={statusColor} />
          <span className="ml-2">Subject</span>
        </div>
        <Icon name={chevronName} size={16} theme="filled"></Icon>
      </div>
      {showField && (
        <div className="email-settings-collapsible-field">
          <TextInput
            className="input subject-field"
            name="subject"
            type="text"
            placeholder="Enter Subject"
            value={subject}
            onChange={onChange}
            options={dynamicContentOptions}
            trigger={['{{']}
            maxLength="255"
          />
          <div className="input-char-counter">{`${subjectLength}/255`}</div>
        </div>
      )}
    </>
  );
}

export default EmailSubjectFormField;

EmailSubjectFormField.propTypes = {
  subject: PropTypes.string,
  onSubjectFieldChange: PropTypes.func,
};
