import utils from './utils';

class SlideGridSlide {
  constructor(slide, template, templateContent, status, flags) {
    this.slide = slide;
    this.template = template;
    this.templateContent = templateContent;
    this.status = status;
    this.flags = flags;
  }

  thumbnailFetchUrl() {
    return this.status && this.status.status === 'success'
      ? `/templates/${this.template.id}/slides/${this.slide.id}/thumbnail/`
      : '';
  }

  conditionsExist() {
    return !!this.slide.conditions && this.slide.conditions.length > 0;
  }

  hasUnmatchedContent(isContentFetching) {
    return !isContentFetching && utils.slideHasUnmatchedContentTags(this.slide, this.templateContent);
  }

  selectedClass(selectedSlides) {
    if (!selectedSlides) {
      return '';
    }

    const selectedSlideIds = selectedSlides.map((slide) => slide.id);
    return selectedSlideIds.indexOf(this.slide.id) === -1 ? '' : 'selected';
  }

  opaqueClass(highlightedSlideNums, slideNum) {
    let isOpaque = false;
    if (highlightedSlideNums && highlightedSlideNums.length > 0) {
      isOpaque = highlightedSlideNums.indexOf(slideNum) < 0;
    }
    return isOpaque ? 'is-opaque' : '';
  }
}

export default SlideGridSlide;
