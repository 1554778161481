import React from 'react';
import PropTypes from 'prop-types';

const ThemeToRadius = {
  circle: 'full',
  square: 'md',
};

export const SizeToDimension = {
  xxl: 'w-14 h-14', // 56
  xl: 'w-12 h-12', // 48
  l: 'w-10 h-10', // 40
  m: 'w-8 h-8', // 32
  s: 'w-7 h-7', // 28
  xs: 'w-6 h-6', // 24
  xxs: 'w-4 h-4', // 16
};

const LuminosityMap = {
  light: 50,
  medium: 500,
  dark: 700,
};

function Pill({ color = 'white', luminosity = 'medium', size = 'l', theme = 'circle', children, text }) {
  let colorClass = 'bg-matik-white text-matik-black border border-grey-300';
  const radiusClass = `rounded-${ThemeToRadius[theme]}`;
  if (color !== 'white') {
    colorClass = `bg-${color.toLowerCase()}-${LuminosityMap[luminosity]} text-matik-white`;
  }
  return (
    <div
      className={`flex items-center justify-center ${colorClass} ${SizeToDimension[size]} ${radiusClass}`}
      data-tooltip-id={text ? 'matik-tooltip' : ''}
      data-tooltip-content={text}
    >
      {children}
    </div>
  );
}

Pill.propTypes = {
  color: PropTypes.string,
  luminosity: PropTypes.oneOf(Object.keys(LuminosityMap)),
  size: PropTypes.oneOf(Object.keys(SizeToDimension)),
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(ThemeToRadius)),
  children: PropTypes.node.isRequired,
};

export default Pill;
