import React from 'react';
import PropTypes from 'prop-types';
import Indicator from './Indicator';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';

const ListItemStatus = {
  DEFAULT: 'default',
  DISABLED: 'disabled',
  WARNING: 'warning',
};

function ListItem({
  title,
  subtitle,
  isSubtitleInline,
  icon,
  indicatorColor,
  metadata,
  size = 'm',
  status = ListItemStatus.DEFAULT,
  onClick,
}) {
  const mainTextClass = status === ListItemStatus.DEFAULT ? 'text-matik-black' : 'text-grey-500';
  let subtitleTextClass;
  switch (status) {
    case ListItemStatus.WARNING:
      subtitleTextClass = 'text-yellow-500';
      break;
    case ListItemStatus.DEFAULT:
      subtitleTextClass = 'text-grey-600';
      break;
    default:
      subtitleTextClass = 'text-grey-400';
      break;
  }
  const item = (
    <>
      {icon}
      {indicatorColor && <Indicator color={indicatorColor} />}
      <div className={`flex-1 min-w-0 flex ${isSubtitleInline ? 'flex-row gap-2' : 'flex-col'}`}>
        <TextOverflowWithTooltip className="leading-6 truncate">{title}</TextOverflowWithTooltip>
        {subtitle && (
          <TextOverflowWithTooltip
            className={`${subtitleTextClass} truncate ${isSubtitleInline ? 'leading-6 italic' : 'leading-none'}`}
          >
            {subtitle}
          </TextOverflowWithTooltip>
        )}
      </div>
    </>
  );
  return (
    <div className={`flex items-center w-full gap-${size === 's' ? '2' : '3'} ${mainTextClass}`}>
      {onClick && status !== ListItemStatus.DISABLED ? (
        <button
          onClick={onClick}
          className={`flex items-center grow text-left truncate gap-${size === 's' ? '2' : '3'}`}
        >
          {item}
        </button>
      ) : (
        <div className={`flex items-center grow text-left truncate gap-${size === 's' ? '2' : '3'}`}>{item}</div>
      )}
      {metadata && <div className={subtitleTextClass}>{metadata}</div>}
    </div>
  );
}
ListItem.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  isSubtitleInline: PropTypes.bool,
  icon: PropTypes.node,
  indicatorColor: PropTypes.string,
  metadata: PropTypes.node,
  size: PropTypes.oneOf(['s', 'm']),
  status: PropTypes.oneOf(Object.values(ListItemStatus)),
  onClick: PropTypes.func,
  showWarning: PropTypes.bool,
};
export default ListItem;
