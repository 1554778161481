import connectSearchBoxWithFilters from './connectSearchBoxWithFilter';
import { forEach } from 'lodash';
import { Form } from 'react-bulma-components';
import { ReactComponent as Search } from '../../../svg/search_filled.svg';
import React from 'react';
import FilterContainer from './FilterContainer';

const ConnectedSearchBox = connectSearchBoxWithFilters((props) => {
  const { currentRefinement, refine, refinementList, filterPlaceholder, toggleFilters, showFilters, showFilterButton } =
    props;
  let filterCount = 0;
  if (currentRefinement && props.updateIsFetchingPresentation) {
    props.updateIsFetchingPresentation();
  }
  if (refinementList) {
    forEach(refinementList, (list) => {
      if (list) {
        filterCount += list.length;
      }
    });
  }

  return (
    <Form.Field className="mbn is-paddingless">
      <Form.Control className="has-icons-left has-icons-right clickable-icon">
        {showFilterButton || showFilterButton === undefined ? (
          <FilterContainer toggleFilters={toggleFilters} filterCount={filterCount} showFilters={showFilters} />
        ) : (
          ''
        )}
        <Form.Input
          id="connected-search-box-input"
          type="text"
          placeholder={filterPlaceholder}
          value={currentRefinement || ''}
          onChange={(e) => refine(e.currentTarget.value)}
          aria-label={filterPlaceholder}
        />
        <span className="icon is-left has-text-grey-dark">
          <Search />
        </span>
      </Form.Control>
    </Form.Field>
  );
});

export default ConnectedSearchBox;
