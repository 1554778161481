import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import DataSourcesContent from './DataSourcesContent';
import utils from '../../../lib/utils';
import Constants from '../../Constants';
import DataSourceEmptyContainer from './DataSourceEmptyContainer';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import DataSourceForm from './DataSourceForm';
import { Heading } from 'react-bulma-components';
import { ReactComponent as LockIcon } from '../../../images/lock.svg';
import { ReactComponent as NoteIcon } from '../../../svg/note.svg';

class DataSourcesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isPaginating: false,
      pagination: {},
      selectedDataSource: null,
      initialDataSourceType: null,
      dataSources: [],
      showDataSourceFullscreen: false,
    };
    this.mounted = true;
  }

  componentDidMount() {
    API.fetchContent('/data_sources/', () => this.setState({ isLoading: true }), this.onContentFetch);
    API.track('data_sources_tab_navigation');
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const headerCenter = <Heading className="full-screen-modal-title">Connect Data Source</Heading>;
    const onClose = () => this.onModalClose();

    const emptyInfo = {
      emptyPrompt: "You haven't created any data sources yet.",
      buttonText: 'Connect Data Source',
      emptyOnClick: () => this.setState({ showDataSourceFullscreen: true, initialDataSourceType: null }),
      secondaryButtonText: 'Learn More',
      secondaryButtonOnClick: () =>
        window.open('https://help.matik.io/hc/en-us/articles/7851324921755-Data-Sources-Overview'),
    };

    return (
      <div className="tab-content">
        <FullScreenModal
          show={this.state.showDataSourceFullscreen}
          showDefaultFooter={false}
          backgroundColor="white"
          onClose={onClose}
          headerCenter={headerCenter}
        >
          <div className="fullscreen-modal-data-source">
            <div className="fullscreen-data-source-form">
              <DataSourceForm
                onDataSourceAdd={this.onDataSourceAdd}
                initialDataSourceType={this.state.initialDataSourceType}
                centered={true}
                isFullscreen={true}
              />
            </div>
            <div className="fullscreen-disclaimer">
              <div className="fs-icon-lock">
                <LockIcon />
              </div>
              <div className="fs-icon-help">
                <NoteIcon />
              </div>
              <div className="fullscreen-disclaimer-text">
                We do not save any customer data on our servers.
                <p>
                  <a
                    href="https://www.matik.io/security"
                    target="_blank"
                    rel="noreferrer"
                    className="fullscreen-disclaimer-link"
                  >
                    Learn More
                  </a>
                </p>
              </div>
              <div className="fullscreen-disclaimer-help-container">
                <a href="https://help.matik.io" target="_blank" rel="noreferrer" className="fullscreen-disclaimer-link">
                  Help Center
                </a>
              </div>
            </div>
          </div>
        </FullScreenModal>
        <DataSourcesContent
          isLoading={this.state.isLoading}
          elements={this.state.dataSources}
          emptyInfo={emptyInfo}
          selectedDataSource={this.state.selectedDataSource}
          initialDataSourceType={this.state.initialDataSourceType}
          onDataSourceSelect={this.onDataSourceSelect}
          pagination={this.state.pagination}
          isPaginating={this.state.isPaginating}
          onNewDataSourceAdd={this.onNewDataSourceAdd}
          onDataSourceAdd={this.onDataSourceAdd}
          dataSources={this.state.dataSources}
          match={this.props.match}
          fetchItems={this.paginateContent}
          onDataSourceUpdate={this.onDataSourceUpdate}
          deleteDataSource={this.deleteDataSource}
          entityType="data_source"
          emptyState={() => (
            <DataSourceEmptyContainer emptyInfo={emptyInfo} onNewDataSourceAdd={this.onNewDataSourceAdd} />
          )}
        />
      </div>
    );
  }

  onContentFetch = (dataSources, pagination) => {
    if (this.mounted) {
      this.setState({ dataSources, pagination, isLoading: false });
    }
  };

  beforePagination = () => {
    this.setState({ isPaginating: true });
  };

  onContentPagination = (dataSources, pagination) => {
    this.setState({ dataSources, pagination, isPaginating: false });
  };

  paginateContent = (offset = 0, limit = Constants.PAGE_SIZE, sort = null) => {
    API.fetchContent('/data_sources/', this.beforePagination, this.onContentPagination, offset, limit, sort);
  };

  onModalClose = () => {
    this.setState({ showDataSourceFullscreen: false });
  };

  onDataSourceAdd = (newDataSource) => {
    const updatedDataSources = [...this.state.dataSources];
    updatedDataSources.push(newDataSource);
    utils.notify('Data source added');
    this.setState({
      dataSources: updatedDataSources,
      showDataSourceFullscreen: false,
      selectedDataSource: newDataSource,
    });
  };

  onDataSourceUpdate = (updatedDataSource) => {
    const updatedDataSources = [...this.state.dataSources];
    for (let i = 0; i < updatedDataSources.length; i++) {
      if (updatedDataSources[i].id === updatedDataSource.id) {
        updatedDataSources[i] = updatedDataSource;
      }
    }

    utils.notify('Data source updated');
    this.setState({ dataSources: updatedDataSources, selectedDataSource: updatedDataSource });
  };

  onNewDataSourceAdd = (dataSource) => {
    if (dataSource) {
      this.setState({ initialDataSourceType: dataSource });
    }
    this.setState({ showDataSourceFullscreen: true });
  };

  deleteDataSource = (dataSource) => {
    const updatedDataSources = [];
    for (let i = 0; i < this.state.dataSources.length; i++) {
      if (this.state.dataSources[i].id !== dataSource.id) {
        updatedDataSources.push(this.state.dataSources[i]);
      }
    }

    utils.notify('Data source deleted');
    this.setState({ dataSources: updatedDataSources, selectedDataSource: null });
  };
}

DataSourcesTab.propTypes = {
  match: PropTypes.any,
};

export default DataSourcesTab;
