import React from 'react';
import { Card } from 'react-bulma-components';
import AICardImage from '../../images/ai_card_image.png';
import { useDispatch, useSelector } from 'react-redux';
import { openFullScreenModal, closeFullScreenModal } from 'redux/ui/action';

import TemplateBuilderModal from './templateBuilder/TemplateBuilderModal';

function TemplateBuilderCard() {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const onClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    dispatch(closeFullScreenModal());
  };

  const openModal = () => {
    dispatch(openFullScreenModal('templateBuilderModal'));
  };
  return (
    <Card className="template-card" id="template-builder-card" onClick={openModal}>
      <img src={AICardImage} alt="AI Builder Illustration" />
      <Card.Content className="template-card-content">
        <div className="title is-4 primary-card-text text-overflow-ellipsis has-text-left">AI Template Builder</div>
      </Card.Content>
      <TemplateBuilderModal
        onClose={onClose}
        showModal={ui.fullScreenModal?.name === 'templateBuilderModal'}
        openModal={openModal}
      />
    </Card>
  );
}

export default TemplateBuilderCard;
