import '../../animate.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import error_icon from 'images/error_light_circle.svg';
import warning_icon from 'images/warning_light_circle.svg';
import success_icon from 'images/success.svg';
import info_icon from 'images/info_light_circle.svg';
import google_icon from 'images/perms_google.svg';
import microsoft_icon from 'images/perms_microsoft.svg';
import google_perms from 'images/google_perms.png';
import empty_google from 'images/empty_state_small_google.png';
import empty_google_slides from 'images/empty_state_small_google_slides.png';
import empty_microsoft_365 from 'images/empty_state_small_microsoft_365.png';
import empty_powerpoint from 'images/empty_state_small_powerpoint.png';
import Icon from 'components/lib/Icon';
import React from 'react';
import ReactDomServer from 'react-dom/server';

const typeToImageUrl = {
  error: error_icon,
  warning: warning_icon,
  success: success_icon,
  info: info_icon,
  google: google_icon,
  microsoft: microsoft_icon,
};

const typeToEmptyUrl = {
  google: empty_google,
  google_slides: empty_google_slides,
  microsoft: empty_microsoft_365,
  powerpoint: empty_powerpoint,
};

const MConfirm = (
  title,
  text,
  type,
  callback,
  confirmButtonText = 'OK',
  cancelButtonText = 'Cancel',
  onBeforeOpen = null,
) => {
  Swal.fire({
    animation: false,
    title: title,
    text: text,
    imageUrl: typeToImageUrl[type],
    imageWidth: 72,
    showCancelButton: true,
    buttonsStyling: false,
    onBeforeOpen: onBeforeOpen,
    customClass: {
      cancelButton: 'button mvm mhs is-secondary',
      confirmButton: 'button is-primary mvm mhs',
      popup: 'animated slideInUp faster pal has-text-black',
      title: 'has-text-black',
      content: 'alert-content',
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
  }).then((result) => {
    callback(result.value);
  });
};

const MConfirmHtml = (
  title,
  html,
  type,
  callback,
  confirmButtonText = 'OK',
  cancelButtonText = 'Cancel',
  onBeforeOpen = null,
  customClass = '',
) => {
  Swal.fire({
    animation: false,
    title: title,
    html: html,
    imageUrl: typeToImageUrl[type],
    imageWidth: 72,
    showCancelButton: true,
    buttonsStyling: false,
    onBeforeOpen: onBeforeOpen,
    customClass: {
      cancelButton: 'button mvm mhs is-secondary',
      confirmButton: 'button is-primary mvm mhs',
      popup: `animated slideInUp faster pal has-text-black ${customClass}`,
      title: 'has-text-black',
      content: 'alert-content',
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
  }).then((result) => {
    callback(result.value);
  });
};

const MAlert = (text, title, type, callback) => {
  Swal.fire({
    animation: false,
    title: title,
    text: text,
    imageUrl: typeToImageUrl[type],
    showCancelButton: false,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'button is-primary mam',
      popup: 'animated slideInUp faster',
      content: 'alert-content',
    },
    reverseButtons: true,
  }).then((result) => {
    if (callback) {
      callback(result.value);
    }
  });
};

const MAlertHtml = (html, title, type, callback) => {
  Swal.fire({
    animation: false,
    title: title,
    html: html,
    imageUrl: typeToImageUrl[type],
    showCancelButton: false,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'button is-primary mam',
      popup: 'animated slideInUp faster',
    },
    reverseButtons: true,
  }).then((result) => {
    if (callback) {
      callback(result.value);
    }
  });
};

const MProductUpsell = (html, large, callback) => {
  let width = '40em';
  if (large) {
    width = '50em';
  }
  Swal.fire({
    animation: true,
    html: html,
    showCancelButton: true,
    buttonsStyling: false,
    width: width,
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Discover Matik Enterprise',
    customClass: {
      confirmButton: 'button is-primary',
      cancelButton: 'button mam',
      closeButton: 'product-upsell-close-button',
      popup: 'animated slideInUp faster p-0',
      actions: 'm-0 pbl',
    },
    reverseButtons: true,
    showCloseButton: !large,
  }).then((result) => {
    if (callback) {
      callback(result);
    }
  });
};

const MInsufficientPerms = (
  title,
  text,
  callback = null,
  confirmButtonText = 'OK',
  cancelButtonText = 'Cancel',
  onBeforeOpen = null,
) => {
  Swal.fire({
    animation: false,
    title: title,
    text: text,
    imageUrl: google_perms,
    imageAlt: 'Google Permissions',
    width: '40em',
    imageWidth: 672,
    showCancelButton: true,
    buttonsStyling: false,
    onBeforeOpen: onBeforeOpen,
    customClass: {
      container: 'google-perms-alert',
      cancelButton: 'button mvm mhs is-secondary',
      confirmButton: 'button is-primary mvm mhs',
      popup: 'animated slideInUp faster pal has-text-black google-perms-popup',
      image: 'google-perms-image',
      title: 'has-text-black pts',
      content: 'alert-content',
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
  }).then((result) => {
    callback(result.value);
  });
};

const MInsufficientPermissions = (
  title,
  text,
  type = 'google',
  callback = null,
  confirmButtonText = 'Review Permissions',
  cancelButtonText = 'Cancel',
  onBeforeOpen = null,
) => {
  Swal.fire({
    animation: false,
    title: title,
    text: text,
    width: '40em',
    imageUrl: typeToImageUrl[type],
    showCancelButton: true,
    buttonsStyling: false,
    onBeforeOpen: onBeforeOpen,
    customClass: {
      container: 'no-perms-alert',
      cancelButton: 'button mvm mhs is-secondary',
      confirmButton: 'button is-primary mvm mhs',
      popup: 'animated slideInUp faster pal has-text-black',
      title: 'has-text-black pts',
      content: 'alert-content',
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
  }).then((result) => {
    callback(result.value);
  });
};

const MConnect = (
  title,
  text,
  type = 'google',
  callback = null,
  confirmButtonText = 'Connect',
  cancelButtonText = 'Cancel',
  onBeforeOpen = null,
) => {
  Swal.fire({
    animation: false,
    title: title,
    text: text,
    width: '40em',
    imageUrl: typeToEmptyUrl[type],
    showCancelButton: true,
    buttonsStyling: false,
    onBeforeOpen: onBeforeOpen,
    customClass: {
      container: 'no-perms-alert',
      cancelButton: 'button mvm mhs is-secondary',
      confirmButton: 'button is-primary mvm mhs',
      popup: 'animated slideInUp faster pal has-text-black',
      title: 'has-text-black pts',
      content: 'alert-content',
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
  }).then((result) => {
    callback(result.value);
    Swal.close();
  });
};

const MConfirmModal = (
  title,
  text,
  callback,
  confirmButtonText = 'OK',
  cancelButtonText = 'Cancel',
  onBeforeOpen = null,
) => {
  Swal.fire({
    animation: false,
    title: title,
    text: text,
    width: '40em',
    showCancelButton: true,
    buttonsStyling: false,
    onBeforeOpen: onBeforeOpen,
    customClass: {
      cancelButton: 'button my-0 mx-2 is-tertiary',
      confirmButton: 'button is-primary my-0 mx-2',
      popup: 'animated slideInUp faster has-text-black p-0',
      title: 'has-text-black !text-base self-start !m-0',
      content: 'alert-content !text-sm p-5 !text-left has-text-grey-dark min-h-44',
      header: 'light-bottom-border p-5',
      actions: 'light-top-border p-5 !justify-end',
      closeButton: 'm-3 items-center is-dark-gray',
    },
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    reverseButtons: true,
    showCloseButton: true,
    closeButtonHtml: ReactDomServer.renderToString(<Icon name="dismiss" size={20} />),
  }).then((result) => {
    callback(result.value);
  });
};

export {
  MConfirm,
  MConfirmHtml,
  MAlert,
  MAlertHtml,
  MProductUpsell,
  MInsufficientPerms,
  MInsufficientPermissions,
  MConnect,
  MConfirmModal,
};
