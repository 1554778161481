import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from '../Constants';
import UserAvatar from 'react-user-avatar';

class MultiAvatar extends Component {
  render() {
    return (
      <div className="avatars has-cursor-pointer" title={this.props.title}>
        {this.renderAvatars()}
      </div>
    );
  }

  renderAvatars() {
    const avatars = [];
    for (let i = 0; i < this.props.avatars.length; i++) {
      const avatarInfo = this.props.avatars[i];
      if (avatarInfo && avatarInfo.name) {
        const avatar = (
          <UserAvatar
            key={avatarInfo.accessId}
            colors={Constants.AVATAR_COLORS}
            src={avatarInfo.src}
            name={avatarInfo.name}
            size={this.props.size}
          />
        );
        avatars.push(avatar);
        if (i === this.props.limit - 1) {
          break;
        }
      }
    }

    if (this.props.avatars.length && this.props.avatars.length > this.props.limit) {
      const additional = this.props.avatars.length - this.props.limit;
      avatars.unshift(
        <UserAvatar
          key={'additional-users'}
          colors={['#52606F']}
          src={''}
          name={`${additional}`}
          size={this.props.size}
          className="is-size-4"
        />,
      );
    }

    return avatars;
  }
}

MultiAvatar.propTypes = {
  avatars: PropTypes.array,
  limit: PropTypes.number,
  size: PropTypes.number,
  title: PropTypes.string,
};

MultiAvatar.defaultProps = {
  limit: 3,
  size: 50,
};

export default MultiAvatar;
