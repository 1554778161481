import API from '../../lib/api';
import {
  ADD_TEMPLATE_TO_LIBRARIES,
  RECEIVE_FETCH_LIBRARIES,
  REMOVE_TEMPLATE_FROM_LIBRARIES,
  REQUEST_FETCH_LIBRARIES,
} from './actionTypes';

export function fetchLibrariesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchLibraries(getState())) {
      dispatch(fetchLibraries());
    }
  };
}

function shouldFetchLibraries(state) {
  return (
    !state.libraries.isFetching &&
    (!state.libraries.librariesById || Object.keys(state.libraries.librariesById).length === 0)
  );
}

function fetchLibraries() {
  return (dispatch) => {
    dispatch(requestFetchLibrary());
    API.get('/templates/libraries/', (response) => {
      dispatch(receiveFetchLibrary(response));
    });
  };
}

function requestFetchLibrary() {
  return {
    type: REQUEST_FETCH_LIBRARIES,
  };
}

function receiveFetchLibrary(response) {
  return {
    type: RECEIVE_FETCH_LIBRARIES,
    libraries: response.data,
  };
}

export function addTemplateToLibraries(template) {
  return {
    type: ADD_TEMPLATE_TO_LIBRARIES,
    template,
  };
}

export function removeTemplateFromLibraries(templateId) {
  return {
    type: REMOVE_TEMPLATE_FROM_LIBRARIES,
    libraryId: templateId,
  };
}
