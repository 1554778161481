import React from 'react';

export const ContentContext = React.createContext({
  selectedContent: null,
  selectContent: () => {},
  highlightedContentName: null,
  highlightContentName: () => {},
  selectSlideTag: () => {},
  selectedSlideTag: null,
});
