import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../lib/Icon';

const TemplateIcon = ({ templateType, className }) => {
  let icon;
  switch (templateType) {
    case 'email':
      icon = <Icon name="email" size={20} theme="filled" />;
      break;
    case 'document':
      icon = <Icon name="document" size={20} theme="filled" />;
      break;
    case 'spreadsheet':
      icon = <Icon name="spreadsheet" size={20} theme="filled" />;
      break;
    default:
      icon = <Icon name="presentation" size={20} theme="filled" />;
      break;
  }
  return <div className={`template-type-icon-bg ${templateType} ${className || ''}`}>{icon}</div>;
};
TemplateIcon.propTypes = {
  templateType: PropTypes.oneOf(['email', 'document', 'presentation', 'spreadsheet']).isRequired,
  className: PropTypes.string,
};

export default TemplateIcon;
