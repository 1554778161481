import React from 'react';
import PropTypes from 'prop-types';

import { IconSelect } from 'components/shared/FormSelect';

import InputIcon from 'components/producer/templates/InputIcon';
import InputDisplayName from 'components/shared/templates/InputDisplayName';

const InputSelect = ({ value, onChange, inputs }) => {
  const inputOptions = inputs
    .sort((a, b) => InputDisplayName.text(a).localeCompare(InputDisplayName.text(b)))
    .map((input) => ({
      label: <InputDisplayName input={input} />,
      icon: <InputIcon className="icon-frame" input={input} />,
      value: input,
    }));

  const handleSelect = (option) => {
    onChange(option.value);
  };
  const selectedOption = inputOptions.find((opt) => opt.value.id === value?.id);

  return (
    <IconSelect
      isSearchable={false}
      classNamePrefix="matik-select"
      options={inputOptions}
      value={selectedOption}
      onChange={handleSelect}
    />
  );
};
InputSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  /** An array of inputs to show as options in the select */
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
      name: PropTypes.string,
      source_type: PropTypes.string,
    }),
  ),
};

export default InputSelect;
