const REQUEST_GROUPS = 'REQUEST_GROUPS';
const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
const REQUEST_ADD_GROUP = 'REQUEST_ADD_GROUP';
const RECEIVE_ADD_GROUP = 'RECEIVE_ADD_GROUP';
const REQUEST_UPDATE_GROUP = 'REQUEST_UPDATE_GROUP';
const RECEIVE_UPDATE_GROUP = 'RECEIVE_UPDATE_GROUP';
const REQUEST_DELETE_GROUP = 'REQUEST_DELETE_GROUP';
const RECEIVE_DELETE_GROUP = 'RECEIVE_DELETE_GROUP';
const REQUEST_GROUP_MEMBERS = 'REQUEST_GROUP_MEMBERS';
const RECEIVE_GROUP_MEMBERS = 'RECEIVE_GROUP_MEMBERS';

export {
  REQUEST_GROUPS,
  RECEIVE_GROUPS,
  REQUEST_ADD_GROUP,
  RECEIVE_ADD_GROUP,
  REQUEST_DELETE_GROUP,
  RECEIVE_DELETE_GROUP,
  RECEIVE_UPDATE_GROUP,
  REQUEST_UPDATE_GROUP,
  RECEIVE_GROUP_MEMBERS,
  REQUEST_GROUP_MEMBERS,
};
