import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import API from '../../lib/api';
import teams from '../../lib/teams';
import withUserContext from './WithUserContext';
import Tag from '../lib/Tag';

class EnterpriseUpsellTag extends Component {
  render() {
    let text = 'ENTERPRISE';
    if (this.props.small) {
      text = 'E';
    }
    return (
      <div className="ml-1">
        <Tag color="gradient" label={text} onClick={this.onTagClick} />
      </div>
    );
  }

  onTagClick = (e) => {
    e.preventDefault();
    if (this.props.feature !== 'conditional_content') {
      API.track('discover_matik_enterprise_click', { from: 'enterprise_upsell_tag' });
      const demoUrl = teams.buildRequestDemoUrl(
        this.props.userContext.user,
        this.props.feature,
        this.props.userContext.user.enterprise.trial_days_remaining,
      );
      window.open(demoUrl, '_blank');
    }
  };
}

EnterpriseUpsellTag.propTypes = {
  history: PropTypes.object,
  small: PropTypes.bool,
  feature: PropTypes.string,
  userContext: PropTypes.object,
};

export default withRouter(withUserContext(EnterpriseUpsellTag));
