import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TagTree from 'lib/tagTree';
import withHtmlBuilder from 'components/shared/withHtmlBuilder';
import utils from 'lib/utils';
import EmailTemplateSidebar from 'components/producer/templates/EmailTemplateSidebar';
import EmailTemplateBody from './EmailTemplateBody';
import API from 'lib/api';
import inputs from 'lib/inputs';
import { Section } from 'react-bulma-components';
import { isEqual } from 'lodash';
import { useEditor } from '@craftjs/core';
import { useHistory } from 'react-router-dom';
import { useTemplateContent } from 'lib/hooks/useTemplate';

function EmailTemplateComponents({
  allDynamicContent,
  attachedTemplate,
  canEdit,
  currentTemplate,
  emailPreviewValues,
  enterprise,
  jsonNodes,
  onTemplateTest,
  onTemplateUpdateWithServerCall,
  openAuthModal,
  saveEditorQuery,
  sendgridInfo,
  setCanUndo,
  onContentClick,
  onInputClick,
  isPreviewLoading,
  emailHtml,
  dynamicRecipientsName,
}) {
  const history = useHistory();

  const { actions } = useEditor();

  const { canUndo, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId]?.related?.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
      canUndo: query.history.canUndo(),
    };
  });

  useEffect(() => {
    setCanUndo?.(canUndo);
  }, [canUndo]);

  useEffect(() => {
    API.track('input_form_in_template_sidepane');

    const urlParams = new URLSearchParams(window.location.search);
    const sync = urlParams.get('sync');
    if (sync === 'true') {
      onEditorSaveClick();
      urlParams.delete(sync);
      history.push(`/templates/${currentTemplate.id}`);
      API.track('new_email_template_navigation');
    }
  }, []);

  const { data: templateContent, isPending: isTemplateContentFetching } = useTemplateContent(
    currentTemplate?.id,
    currentTemplate?.deleted,
  );
  const { data: attachedTemplateContent, isPending: isAttachmentContentFetching } = useTemplateContent(
    attachedTemplate?.id,
    attachedTemplate?.deleted,
  );
  const isContentFetching = isTemplateContentFetching || (attachedTemplate?.id && isAttachmentContentFetching);

  const onEditorSaveClick = () => {
    saveEditorQuery(jsonNodes, currentTemplate, onTemplateUpdateWithServerCall, actions);
  };

  const { dynamicContentTags, templateInputs, attachmentInputs } = findTemplateContentTagsAndInputs(
    isContentFetching,
    currentTemplate,
    templateContent,
    attachedTemplate,
    attachedTemplateContent,
    allDynamicContent,
  );

  const showHtmlBuilderSettings =
    selected && selected.name !== 'EmailBody' && selected.name !== 'EmailContainer' && selected.name !== 'div';
  return (
    <div className="template-wrapper">
      <div className="template-components">
        <EmailTemplateSidebar
          dynamicContentTags={dynamicContentTags}
          isContentFetching={isContentFetching}
          inputsInMatchingContent={templateInputs}
          attachmentInputsInMatchingContent={attachmentInputs}
          currentTemplate={currentTemplate}
          attachedTemplate={attachedTemplate}
          isBuilderSettingsVisible={showHtmlBuilderSettings}
          onContentClick={onContentClick}
          onInputClick={onInputClick}
        />
      </div>
      <Section className="is-paddingless template-slides email">
        <EmailTemplateBody
          allDynamicContent={allDynamicContent}
          currentTemplate={currentTemplate}
          onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
          attachedTemplate={attachedTemplate}
          sendgridInfo={sendgridInfo}
          openAuthModal={openAuthModal}
          enterprise={enterprise}
          canEdit={canEdit}
          jsonNodes={jsonNodes}
          onEditorSaveClick={onEditorSaveClick}
          onTemplateTest={onTemplateTest}
          emailPreviewValues={emailPreviewValues}
          isPreviewLoading={isPreviewLoading}
          emailHtml={emailHtml}
          dynamicRecipientsName={dynamicRecipientsName}
        />
      </Section>
    </div>
  );
}
EmailTemplateComponents.propTypes = {
  allDynamicContent: PropTypes.object,
  attachedTemplate: PropTypes.object,
  canEdit: PropTypes.bool,
  currentTemplate: PropTypes.object,
  emailHtml: PropTypes.string,
  enterprise: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  openAuthModal: PropTypes.func,
  sendgridInfo: PropTypes.object,
  ui: PropTypes.object,
  onTemplateTest: PropTypes.func,
  emailPreviewValues: PropTypes.object,
  setCanUndo: PropTypes.func,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  isPreviewLoading: PropTypes.bool,
  dynamicRecipientsName: PropTypes.string,

  /* injected by withHtmlBuilder() */
  jsonNodes: PropTypes.string,
  saveEditorQuery: PropTypes.func,
};

export default withHtmlBuilder(EmailTemplateComponents);

const dedupeAttachmentContentList = (bodyTagList, attachmentTagList) => {
  const filteredAttachmentContentTags = [];
  if (attachmentTagList.length) {
    attachmentTagList.forEach((attachmentContent) => {
      let isDuplicate = false;
      bodyTagList.forEach((bodyContent) => {
        if (
          attachmentContent.name === bodyContent.name &&
          isEqual(attachmentContent.formats, bodyContent.formats) &&
          attachmentContent.nodeType === 'TAG'
        ) {
          isDuplicate = true;
        }
      });
      if (!isDuplicate) {
        filteredAttachmentContentTags.push(attachmentContent);
      }
    });
  }
  return filteredAttachmentContentTags;
};
const flattenTags = (tagTree) => {
  return tagTree?.flattenTree((a) => {
    if (!a.matchingContent) {
      return -1;
    } else {
      return 1;
    }
  });
};

const findTemplateContentTagsAndInputs = (
  isContentFetching,
  currentTemplate,
  templateContent,
  attachedTemplate,
  attachedTemplateContent,
  allDynamicContent,
) => {
  let allTagTree = new TagTree();
  let attachedTagTree = new TagTree();
  let flattenedContentTags = [];
  let flattenedAttachmentContentTags = [];
  if (!isContentFetching) {
    allTagTree = utils.getDynamicContentTags(currentTemplate, templateContent, allDynamicContent);
    attachedTagTree = utils.getDynamicContentTags(attachedTemplate, attachedTemplateContent, allDynamicContent);
    flattenedContentTags = flattenTags(allTagTree);
    flattenedAttachmentContentTags = flattenTags(attachedTagTree);
  }

  const filteredAttachmentContentTags = dedupeAttachmentContentList(
    flattenedContentTags,
    flattenedAttachmentContentTags,
  );
  const combinedDynamicContentTags = [...flattenedContentTags, ...filteredAttachmentContentTags];

  const allTags = allTagTree.getTagNodes();
  const attachmentTags = attachedTagTree?.getTagNodes();
  const allInputsOnTemplate = inputs.getAllInputs(allTags, allDynamicContent, currentTemplate, templateContent, null);
  const attachmentMatchingInputs = attachmentTags.length
    ? inputs.getAllInputs(attachmentTags, allDynamicContent, attachedTemplate, attachedTemplateContent)
    : {};

  return {
    dynamicContentTags: combinedDynamicContentTags,
    templateInputs: allInputsOnTemplate,
    attachmentInputs: attachmentMatchingInputs,
  };
};
