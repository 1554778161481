import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Preview extends Component {
  render() {
    return (
      <div className="preview-box">
        <div className="preview-box-header is-size-7 has-text-weight-semibold">{this.props.heading}</div>
        <div className="preview-box-body is-size-7">{this.props.body}</div>
      </div>
    );
  }
}

Preview.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
};

export default Preview;
