import React, { useContext } from 'react';
import { Form } from 'react-bulma-components';
import { Select } from 'components/shared/FormSelect';
import PropTypes from 'prop-types';
import { DynamicContentContext } from '../DynamicContentContext';
import { components } from 'react-select';

function SenderApiReturnFields({
  formRef,
  options,
  onReturnFieldChange,
  queryObj,
  formattedValues,
  returnFieldsByName,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);
  const multiFieldMapping = dynamicContentContext.multiFieldMapping;

  const onChange = queryObj
    ? (obj, action) => onReturnFieldChange(obj, action, queryObj)
    : returnFieldsByName
    ? (obj, action) => onReturnFieldChange(obj, action, returnFieldsByName)
    : (obj, action) => onReturnFieldChange(obj, action);
  const useFormattedValues = formattedValues && Object.keys(formattedValues).length;
  const fromNameValue = useFormattedValues
    ? formattedValues.from_name
    : multiFieldMapping.from_name
    ? { label: multiFieldMapping.from_name, value: multiFieldMapping.from_name }
    : null;
  const fromEmailValue = useFormattedValues
    ? formattedValues.from_email
    : multiFieldMapping.from_email
    ? { label: multiFieldMapping.from_email, value: multiFieldMapping.from_email }
    : null;
  const replyEmailValue = useFormattedValues
    ? formattedValues.reply_email
    : multiFieldMapping.reply_email
    ? { label: multiFieldMapping.reply_email, value: multiFieldMapping.reply_email }
    : null;
  return (
    <Form.Field className="mbl">
      <Form.Label>Fields To Return</Form.Label>
      <div className="flex flex-row">
        <Form.Control className="w-1/3 pr-3">
          <Form.Help>From Name</Form.Help>
          <Select
            aria-label="Select Return Fields"
            classNamePrefix="matik-select"
            value={fromNameValue}
            isMulti={false}
            isRenamable={true}
            options={options}
            onChange={onChange}
            isDisabled={dynamicContentContext.isReadOnly}
            menuPortalTarget={formRef?.current}
            name="from_name"
            componentsToAdd={{ Option: components.Option, ValueContainer: components.ValueContainer }}
          />
        </Form.Control>
        <Form.Control className="w-1/3 pr-3">
          <Form.Help>From Email</Form.Help>
          <Select
            aria-label="Select Return Fields"
            classNamePrefix="matik-select"
            value={fromEmailValue}
            isMulti={false}
            isRenamable={true}
            options={options}
            onChange={onChange}
            isDisabled={dynamicContentContext.isReadOnly}
            menuPortalTarget={formRef?.current}
            name="from_email"
            componentsToAdd={{ Option: components.Option, ValueContainer: components.ValueContainer }}
          />
        </Form.Control>
        <Form.Control className="w-1/3">
          <Form.Help>Reply To Email</Form.Help>
          <Select
            aria-label="Select Return Fields"
            classNamePrefix="matik-select"
            value={replyEmailValue}
            isMulti={false}
            isRenamable={true}
            options={options}
            onChange={onChange}
            isDisabled={dynamicContentContext.isReadOnly}
            menuPortalTarget={formRef?.current}
            name="reply_email"
            componentsToAdd={{ Option: components.Option, ValueContainer: components.ValueContainer }}
          />
        </Form.Control>
      </div>
      {dynamicContentContext.queryStringError && (
        <p className="help is-danger">{dynamicContentContext.queryStringError}</p>
      )}
    </Form.Field>
  );
}

SenderApiReturnFields.propTypes = {
  formRef: PropTypes.object,
  objectCount: PropTypes.number,
  options: PropTypes.array,
  onReturnFieldChange: PropTypes.func,
  queryObj: PropTypes.object,
  formattedValues: PropTypes.object,
  returnFieldsByName: PropTypes.object,
};

export default SenderApiReturnFields;
