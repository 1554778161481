import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../shared/modal/Modal';
import DataSourceLogin from './DataSourceLogin';
import { Form } from 'react-bulma-components';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import utils from '../../lib/utils';

class DataSourceLoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDataSourceCount: 0,
      currentDataSourceLoginIdx: 0,
    };
  }

  componentDidUpdate() {
    if (
      this.props.dataSourcesWithLoginRequired &&
      this.state.totalDataSourceCount === 0 &&
      this.props.dataSourcesWithLoginRequired.length > 0
    ) {
      this.setState({ totalDataSourceCount: this.props.dataSourcesWithLoginRequired.length });
    }
  }

  render() {
    let currentDataSource = null;
    let nextPrevButtons = '';

    if (!isEmpty(this.props.dataSourcesWithLoginRequired)) {
      currentDataSource = this.props.dataSourcesWithLoginRequired[0];
      nextPrevButtons = (
        <>
          <Form.Help>
            Data Source {this.state.currentDataSourceLoginIdx + 1} of {this.state.totalDataSourceCount}
          </Form.Help>
        </>
      );
    }

    return (
      <Modal show={this.props.show} onClose={this.onClose} title="Log in to Data Source">
        <>
          {this.props.show && (
            <DataSourceLogin
              dataSource={currentDataSource}
              hideHeading={false}
              onClose={this.props.closeModal}
              onUserDataSourceAdd={this.onUserDataSourceAdd}
              nextPrevButtons={nextPrevButtons}
            />
          )}
        </>
      </Modal>
    );
  }

  onUserDataSourceAdd = (newUserDataSource) => {
    this.setState({ currentDataSourceLoginIdx: this.state.currentDataSourceLoginIdx + 1 });
    this.props.onUserDataSourceAdd(newUserDataSource);
    utils.notify('Login successful', toast.TYPE.SUCCESS);
    this.props.closeModal();
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };
}

DataSourceLoginModal.propTypes = {
  dataSourcesWithLoginRequired: PropTypes.array,
  onClose: PropTypes.func,
  onUserDataSourceAdd: PropTypes.func,
  show: PropTypes.bool,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(DataSourceLoginModal);
