import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LookerForm from './LookerForm';

class LookerDynamicContentForm extends Component {
  render() {
    return (
      <LookerForm
        fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
        inputsInQueryString={this.props.inputsInQueryString}
        dataSourceId={this.props.dataSourceId}
        apiInfo={this.props.apiInfo}
        apiErrors={this.props.apiErrors}
        updateTestResult={this.props.updateTestResult}
        formRef={this.props.formRef}
        queryObj={this.props.queryObj}
      />
    );
  }
}

LookerDynamicContentForm.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  dataSourceId: PropTypes.number,
  inputsInQueryString: PropTypes.object,
  updateTestResult: PropTypes.func,
  formRef: PropTypes.object,
  queryObj: PropTypes.object,
};

export default LookerDynamicContentForm;
