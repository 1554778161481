import Pluralize from 'pluralize';
import { MConfirmHtml } from '../../shared/Alerts';
import API from 'lib/api';

function getDeleteTitle(template) {
  const { is_library, sub_template_count, attachment_count } = template;
  if ([is_library, sub_template_count > 0, attachment_count > 0].filter(Boolean).length > 1) {
    return 'Template has connections to other content';
  }
  if (is_library) {
    return 'Template is published as a Library';
  }
  if (sub_template_count > 0) {
    return 'Template has Sub-Templates';
  }
  if (attachment_count > 0) {
    return 'Template is an attachment';
  }
  return 'Delete';
}

function getDeleteMessage(template) {
  const { is_library, sub_template_count, library_count, attachment_count } = template;
  if ([is_library, sub_template_count > 0, attachment_count > 0].filter(Boolean).length > 1) {
    const library_li = is_library
      ? `<li>This template is published as a Library template. ${library_count} other ${Pluralize(
          'template',
          library_count,
          false,
        )} use slides from it.</li>`
      : '';
    const sub_template_li =
      sub_template_count > 0
        ? `<li>This template is used by ${Pluralize('sub-template', sub_template_count, true)}.</li>`
        : '';
    const attachment_li =
      attachment_count > 0
        ? `<li>This template is used as an attachment in ${Pluralize('email', attachment_count, true)}.</li>`
        : '';
    const consequences = [];
    is_library && consequences.push('remove its Library slides from all other templates');
    sub_template_count > 0 && consequences.push('delete all its sub-templates');
    attachment_count > 0 && consequences.push('remove it as an attachment from all email templates');
    return `
      <ul>
        ${library_li}
        ${sub_template_li}
        ${attachment_li}
      </ul>
      </br>
      <p>
        Deleting this template will ${consequences.join(' and ')}.
      </p>
    `;
  }
  if (is_library) {
    return `${Pluralize(
      'template',
      library_count,
      true,
    )} use slides from this template. Deleting this template will remove those slides from any other templates they're currently used in.`;
  }
  if (sub_template_count > 0) {
    return `This template is used by ${Pluralize(
      'sub-template',
      sub_template_count,
      true,
    )}. Deleting this template will delete its sub-templates.`;
  }
  if (attachment_count > 0) {
    return `Deleting this template will remove it as an attachment from ${Pluralize('email', attachment_count, true)}.`;
  }
  return 'Are you sure you want to delete this template?';
}

function onTemplateDelete(template, deleteTemplate, callback = null) {
  const title = getDeleteTitle(template);
  const message = getDeleteMessage(template);
  MConfirmHtml(
    title,
    message,
    'warning',
    (confirmed) => {
      if (confirmed) {
        deleteTemplate(template)
          .then(() => callback?.())
          .catch(API.defaultError);
      }
    },
    'Delete',
    'Cancel',
    null,
    'archive-popup',
  );
}

export default onTemplateDelete;
