import React, { Component } from 'react';
import { Columns, Hero, Container } from 'react-bulma-components';
import PropTypes from 'prop-types';

class AuthPage extends Component {
  render() {
    return (
      <Hero className="login-background" size="fullheight">
        <Hero.Header></Hero.Header>
        <Hero.Body className="columns">
          <Columns.Column size="one-third"></Columns.Column>
          <Columns.Column>{this.props.children}</Columns.Column>
          <Columns.Column size="one-third"></Columns.Column>
        </Hero.Body>
        <Hero.Footer>
          <nav className="tabs is-boxed is-fullwidth">
            <Container></Container>
          </nav>
        </Hero.Footer>
      </Hero>
    );
  }
}

AuthPage.propTypes = {
  children: PropTypes.any,
};

export default AuthPage;
