import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../lib/Card';
import TBConstants from './TBConstants';
import Tag from '../../lib/Tag';

export default function SelectNarrativeScreenV2({ onClick, options, emailEnabled }) {
  return (
    <div className="modal-body-center space-y-2">
      {options.map((option) => {
        const disabled = !emailEnabled && option.narrativeType === TBConstants.narrativeTypes.EMAIL;
        let rightInstance = undefined;
        if (disabled) {
          rightInstance = <Tag label="Requires Matik Mail" color="gradient" size="xs" iconName="mail" />;
        }
        let iconPillProps = {
          pillTheme: 'circle',
          iconTheme: 'filled',
        };
        if (option.narrativeType === TBConstants.narrativeTypes.EMAIL) {
          iconPillProps['iconName'] = 'mail';
          if (disabled) {
            iconPillProps['pillColor'] = 'grey';
            iconPillProps['pillLuminosity'] = 'light';
            iconPillProps['iconColor'] = 'grey-600';
          } else {
            iconPillProps['pillColor'] = 'purple';
            iconPillProps['pillLuminosity'] = 'medium';
            iconPillProps['iconColor'] = 'matik-white';
          }
        } else {
          iconPillProps['iconName'] = 'presentation';
          iconPillProps['pillColor'] = 'green';
          iconPillProps['pillLuminosity'] = 'medium';
          iconPillProps['iconColor'] = 'matik-white';
        }
        return (
          <div onClick={() => !disabled && onClick(option.templateType)} key={option.templateType}>
            <Card
              size="s"
              title={option.name}
              subLine={option.description}
              rightInstance={rightInstance}
              {...iconPillProps}
            />
          </div>
        );
      })}
    </div>
  );
}

SelectNarrativeScreenV2.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      templateType: PropTypes.string,
      narrativeType: PropTypes.string,
    }),
  ),
  emailEnabled: PropTypes.bool,
};
