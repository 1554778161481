import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import BulkPresentationPreview from './BulkPresentationPreview';
import ScheduledPresentationPreview from './ScheduledPresentationPreview';
import { Container } from 'react-bulma-components';
import { Route, withRouter, Switch } from 'react-router';
import { UserContext } from '../UserContext';
import Constants from '../Constants';
import GeneratedPresentation from './GeneratedPresentation';
import Presentation from '../shared/presentations/Presentation';
import templates_empty_icon from '../../images/templates_empty_icon.png';
import TemplatesListMain from '../shared/templates/TemplatesListMain';
import BulkPresentationComplete from './BulkPresentationComplete';
import ScheduledPresentationComplete from './ScheduledPresentationComplete';
import PresentationCreator from './PresentationCreator';
import { useTemplates } from 'lib/hooks/useTemplate';

class CreateContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/:template_id/presentation/:presentation_id/schedule-preview`}
          render={this.renderSchedulePreview}
        />
        <Route
          exact
          path={`${this.props.match.path}/:template_id/presentation/:presentation_id/schedule-complete`}
          render={this.renderScheduleComplete}
        />
        <Route path={`${this.props.match.path}/:template_id/presentation/:presentation_id/complete`}>
          <Container className="consumer-section is-fluid">
            <GeneratedPresentation
              fetchPresentation={this.props.fetchPresentation}
              presentations={this.props.presentations}
              selectPresentation={this.selectPresentation}
              emptyInfo={this.emptyInfo}
              elements={this.props.presentations}
              isLoading={this.props.isLoading}
            />
          </Container>
        </Route>
        <Route path={`${this.props.match.path}/:template_id/presentation/:presentation_id`}>
          <RouterRenderPresentation />
        </Route>
        <Route
          exact
          path={`${this.props.match.path}/:template_id/bulk/:bulk_presentation_id/preview/:presentation_id`}
          render={this.renderBulkPreview}
        />
        <Route
          exact
          path={`${this.props.match.path}/:template_id/bulk/:bulk_presentation_id/complete`}
          render={this.renderBulkComplete}
        />
        <Route exact path={`${this.props.match.path}/:template_id`} render={this.renderCreator} />
        <Route exact path={this.props.match.path} render={this.renderList} />
      </Switch>
    );
  }

  renderList = () => {
    return <TemplatesList emptyInfo={this.emptyInfo} />;
  };

  renderCreator = () => {
    return (
      <PresentationCreator
        userDataSources={this.props.userDataSources}
        onUserDataSourceAdd={this.props.onUserDataSourceAdd}
        emptyInfo={this.emptyInfo}
        onPresentationCreate={this.props.onPresentationCreate}
      />
    );
  };

  renderBulkPreview = (props) => {
    return (
      <Container className="consumer-section is-fluid bg-matik-white">
        <BulkPresentationPreview
          bulkPresentationId={props.match.params.bulk_presentation_id}
          selectPresentation={this.selectPresentation}
          templateId={props.match.params.template_id}
          urlBase="/create/templates/"
        >
          <GeneratedPresentation
            isBulk={true}
            fetchPresentation={this.props.fetchPresentation}
            presentations={this.props.presentations}
            selectPresentation={this.selectPresentation}
            emptyInfo={this.emptyInfo}
            elements={this.props.presentations}
            isLoading={this.props.isLoading}
          />
        </BulkPresentationPreview>
      </Container>
    );
  };

  renderSchedulePreview = (props) => {
    return (
      <Container className="consumer-section is-fluid bg-matik-white">
        <ScheduledPresentationPreview
          presentationId={props.match.params.presentation_id}
          schedule={this.props.schedule}
          selectPresentation={this.selectPresentation}
          templateId={props.match.params.template_id}
          urlBase="/create/templates/"
        >
          <GeneratedPresentation
            isBulk={true}
            isSchedule={true}
            fetchPresentation={this.props.fetchPresentation}
            presentations={this.props.presentations}
            selectPresentation={this.selectPresentation}
            emptyInfo={this.emptyInfo}
            elements={this.props.presentations}
            isLoading={this.props.isLoading}
          />
        </ScheduledPresentationPreview>
      </Container>
    );
  };

  renderBulkComplete = (props) => {
    return (
      <BulkPresentationComplete
        presentationId={props.location.state?.presentationId}
        selectPresentation={this.selectPresentation}
        templateId={props.match.params.template_id}
        urlBase="/create/templates/"
      />
    );
  };

  renderScheduleComplete = (props) => {
    return (
      <ScheduledPresentationComplete
        presentationId={props.location.state?.presentationId}
        templateId={props.match.params.template_id}
        selectPresentation={this.selectPresentation}
        urlBase="/create/templates/"
      />
    );
  };

  selectPresentation = (presentationId) => {
    let selectedPresentation = this.props.presentations.filter(
      (presentation) => presentation.id === parseInt(presentationId),
    );
    if (selectedPresentation.length === 0 && presentationId) {
      this.props.history.push('/create');
    }
    return selectedPresentation[0];
  };

  emptyInfo = {
    emptyImageUrl: templates_empty_icon,
    emptyPrompt:
      'You have no templates available to use yet. Templates will appear here once your admin has created and shared them with you.',
    emptyTitle: 'No Templates',
  };

  activeSidebarMenuItem = () => {
    return this.props.match.params.item || Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
  };
}

CreateContent.propTypes = {
  //State managed by parent CreateTab
  presentations: PropTypes.array,
  schedule: PropTypes.object,
  userDataSources: PropTypes.object,
  //Callbacks from parent
  onPresentationCreate: PropTypes.func,
  resetPresentationFlow: PropTypes.func,
  fetchPresentation: PropTypes.func,
  onUserDataSourceAdd: PropTypes.func,
  isLoading: PropTypes.bool,
  //From withRouter
  history: PropTypes.object,
  match: PropTypes.any,
  location: PropTypes.object,
};

CreateContent.contextType = UserContext;

const RenderPresentation = (props) => {
  const { match } = props;
  return (
    <Container className="consumer-section-with-sidebar is-fluid pln">
      <Presentation presentationId={match.params.presentation_id} presentationsPath="/create/presentations" />
    </Container>
  );
};
RenderPresentation.propTypes = {
  match: PropTypes.any,
};
const RouterRenderPresentation = withRouter(RenderPresentation);

const TemplatesList = ({ emptyInfo }) => {
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    limit: Constants.PAGE_SIZE,
    sort: null,
    preset: Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates,
  });

  const { offset, limit, sort, preset, keepPreviousData } = searchParams;
  const { pagination, elements, isPending, isPreviousData } = useTemplates(
    offset,
    limit,
    sort,
    preset,
    keepPreviousData,
  );

  const handleSearchParamsChange = (offset, sort, limit, preset, keepPreviousData) => {
    setSearchParams({ offset, sort, limit, preset: preset || searchParams.preset, keepPreviousData });
  };

  return (
    <TemplatesListMain
      isFetching={isPending}
      templates={elements}
      emptyInfo={emptyInfo}
      entityType="template"
      onSearchParamsChange={handleSearchParamsChange}
      pagination={pagination}
      isPaginating={isPreviousData}
    />
  );
};
TemplatesList.propTypes = {
  emptyInfo: PropTypes.object,
};

export default withRouter(CreateContent);
