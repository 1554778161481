import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import ApiInputField from '../../../shared/ApiInputField';

const RestForm = (props) => {
  const testUrl = props.auth && props.auth['test_url'] ? props.auth['test_url'] : '';
  const baseUrl = props.auth && props.auth['base_url'] ? props.auth['base_url'] : '';
  return (
    <React.Fragment>
      <Form.Field>
        <Form.Label htmlFor="base-url">REST API Base URL</Form.Label>
        <Form.Help>The base url used by the API Matik is connecting to</Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="API Base URL"
            type="text"
            name="base_url"
            onChange={props.onAuthChange}
            value={baseUrl}
            id="base-url"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="test-url">Test URL (Optional)</Form.Label>
        <Form.Help>
          URL path used to test connection with the API using the &quot;Test Connection&quot; button below
        </Form.Help>
        <Form.Control>
          <ApiInputField
            placeholder="/path_to_test"
            baseUrl={baseUrl}
            includeBaseUrl={true}
            method="get"
            id="test-url"
            name="test_url"
            apiVal={testUrl}
            onChange={props.onAuthChange}
          />
        </Form.Control>
      </Form.Field>
    </React.Fragment>
  );
};

RestForm.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  testResult: PropTypes.object,
};

export default RestForm;
