import React from 'react';
import PropTypes from 'prop-types';
import Filters from '../search/Filters';

const PresentationsSidebar = (props) => {
  return (
    <div className="sidebar overflow-y-auto">
      <Filters
        attributes={props.searchAttributes}
        filterList={props.searchState.refinementList}
        sidebar={true}
        show={true}
        inputs={props.inputs}
        searchState={props.searchState}
        onSearchStateChange={props.onSearchStateChange}
        updateIsFetchingPresentation={props.updateIsFetchingPresentation}
      />
    </div>
  );
};

PresentationsSidebar.propTypes = {
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  fetchItems: PropTypes.func,
  pagination: PropTypes.object,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  inputs: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  updateIsFetchingPresentation: PropTypes.func,
};

export default PresentationsSidebar;
