import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../../lib/utils';
import Modal from 'components/shared/modal/Modal';
import PresentationInputsForm from '../../shared/presentations/PresentationInputsForm';
import Constants from 'components/Constants';
import { useDispatch } from 'react-redux';
import { closeModal } from 'redux/ui/action';
import { useTemplateContent } from '../../../lib/hooks/useTemplate';

function TestSlideModal(props) {
  let { data: allTemplateContent } = useTemplateContent(props.template?.id, props.template?.deleted);
  const dispatch = useDispatch();

  if (props.slides && props.slides.length === 0) {
    return null;
  }
  if (!props.template) {
    return null;
  }
  let matchingContent = [];
  if (props.tags) {
    matchingContent = props.tags.getTagNodes().map((tag) => utils.getDynamicContentFromTag(tag.name));
  }
  let attachmentMatchingContent = [];
  if (props.attachmentTags) {
    attachmentMatchingContent = props.attachmentTags
      .getTagNodes()
      .map((tag) => utils.getDynamicContentFromTag(tag.name));
  }

  const modalTitle = props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'Test Email' : 'Test Slide';

  const closeTestSlideModal = () => {
    dispatch(closeModal());
  };

  const selectedSlideIdxs = props.slides.map((slide) => props.template.slides.findIndex((s) => s.id === slide.id));

  return (
    <Modal onClose={closeTestSlideModal} show={props.show} title={modalTitle}>
      <PresentationInputsForm
        allDynamicContent={props.allDynamicContent}
        attachedTemplate={props.attachedTemplate}
        attachmentContent={attachmentMatchingContent}
        attachmentInputs={props.attachmentInputs}
        entityId={props.template.id}
        entityType="template"
        inModal={true}
        inputs={props.matchingInputs}
        isProducer={true}
        isTestSlide={true}
        onPresentationCreate={props.onPresentationCreate}
        setAllInputsFilledOut={props.setAllInputsFilledOut}
        slideIdxs={selectedSlideIdxs}
        template={props.template}
        templateContent={matchingContent}
        allTemplateContent={allTemplateContent}
        test={true}
        source_type={props.template.source_type}
      />
    </Modal>
  );
}

TestSlideModal.propTypes = {
  allDynamicContent: PropTypes.object,
  slides: PropTypes.array,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  tags: PropTypes.object,
  attachmentTags: PropTypes.object,
  matchingInputs: PropTypes.object,
  attachmentInputs: PropTypes.object,
  setAllInputsFilledOut: PropTypes.func,
  show: PropTypes.bool,
};

export default TestSlideModal;
