import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../lib/Button';
import Icon from '../../../lib/Icon';

function AgentActionButtons({ onDelete, onDownload }) {
  return (
    <div className="flex">
      <Button category="tertiary" onClick={onDownload}>
        <Icon name="arrow_download" size={20} />
      </Button>
      <Button category="tertiary" onClick={onDelete}>
        <Icon name="trash_can" size={20} />
      </Button>
    </div>
  );
}

AgentActionButtons.propTypes = {
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
};

export default AgentActionButtons;
