import React, { Component } from 'react';
import SignUpForm from './SignUpForm';
import WithUserlessLDProvider from './WithUserlessLDProvider';
import { Card, Level } from 'react-bulma-components';
import MatikAuthPlug from './MatikAuthPlug';
import matik_logo_black from '../../images/matik_logo_black.svg';

class SignUp extends Component {
  render() {
    return (
      <div>
        <a className="signup-logo" href="https://matik.io/">
          <img src={matik_logo_black} alt="matik logo" />
        </a>
        <Card className="signup-card">
          <Level>
            <Level.Item align="left">
              <SignUpForm />
            </Level.Item>
            <Level.Item align="right">
              <MatikAuthPlug />
            </Level.Item>
          </Level>
        </Card>
      </div>
    );
  }
}

export default WithUserlessLDProvider(SignUp);
