import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalPortal = document.getElementById('modal-portal');
    this.calendar = document.getElementById('calendar-portal');
    this.dropdown = document.getElementById('dropdown-portal');
  }

  render() {
    const activeClass = this.props.show ? 'is-active' : '';
    const widthStyle = this.props.width ? { width: this.props.width, maxWidth: this.props.width } : {};
    const bodyHeight = this.props.height
      ? { maxHeight: this.props.height }
      : this.props.footerIsSticky
      ? { maxHeight: '500px' }
      : { maxHeight: '577px' };
    const additionalModalClass = this.props.additionalModalClass ? this.props.additionalModalClass : '';

    return ReactDom.createPortal(
      <div className={`modal ${activeClass} v2 ${additionalModalClass}`} onMouseDown={this.onOutsideClick}>
        <div
          className={`modal-content ${this.props.modalContentClass}`}
          style={widthStyle}
          ref={(elem) => (this.htmlElement = elem)}
        >
          <ModalHeader title={this.props.title} onClose={this.props.onClose} linkTo={this.props.linkTo} />
          <div className={this.props.isInputModal ? 'input-modal-body' : 'modal-body'} style={bodyHeight}>
            {this.props.show && this.props.children}
          </div>
          {this.props.showDefaultFooter ? (
            <ModalFooter
              isSticky={this.props.footerIsSticky}
              primaryButtonText={this.props.primaryButtonText}
              primaryButtonOnClick={this.props.primaryButtonOnClick}
              primaryButtonDisabled={this.props.primaryButtonDisabled}
              primaryButtonLoading={this.props.primaryButtonLoading}
              secondaryButtonText={this.props.secondaryButtonText}
              secondaryButtonOnClick={this.props.secondaryButtonOnClick}
              secondaryButtonLoading={this.props.secondaryButtonLoading}
              secondaryButtonDisabled={this.props.secondaryButtonDisabled}
              tertiaryButtonText={this.props.tertiaryButtonText}
              tertiaryButtonOnClick={this.props.tertiaryButtonOnClick}
              tertiaryButtonDisabled={this.props.tertiaryButtonDisabled}
            />
          ) : null}
        </div>
      </div>,
      this.modalPortal,
    );
  }

  handleClose = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };

  insideActiveModalContent = (e) => {
    for (const modal of this.modalPortal.childNodes) {
      if (
        modal.className.includes('is-active') &&
        modal.firstChild !== this.htmlElement &&
        modal.firstChild.contains(e.target)
      ) {
        return true;
      }
    }
  };

  onOutsideClick = (e) => {
    if (
      (this.htmlElement && this.htmlElement.contains(e.target)) ||
      this.calendar.contains(e.target) ||
      this.dropdown.contains(e.target) ||
      this.insideActiveModalContent(e)
    ) {
      return;
    }
    if (this.props.show) {
      this.handleClose(e);
    }
  };
}

Modal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  showDefaultFooter: PropTypes.bool,
  footerIsSticky: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonLoading: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonLoading: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  tertiaryButtonText: PropTypes.string,
  tertiaryButtonOnClick: PropTypes.func,
  tertiaryButtonDisabled: PropTypes.bool,
  modalContentClass: PropTypes.string,
  closeModal: PropTypes.func,
  isInputModal: PropTypes.bool,
  linkTo: PropTypes.string,
  additionalModalClass: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(Modal);
