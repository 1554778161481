import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RocketShip } from '../../images/rocketship.svg';

class UpsellGenericModal extends Component {
  render() {
    return (
      <div className="product-upsell-modal pam">
        <RocketShip className="product-upsell-icon" />
        <span className="product-upsell-header mbm">{this.props.featureHeader}</span>
        <span className="product-upsell-content">{this.props.featureDescription}</span>
      </div>
    );
  }
}

UpsellGenericModal.propTypes = {
  featureHeader: PropTypes.string,
  featureName: PropTypes.string,
  featureDescription: PropTypes.string,
};

export default UpsellGenericModal;
