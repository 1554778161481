import React from 'react';
import PropTypes from 'prop-types';
import google_drive_logo from '../../../../images/google_drive_logo.png';
import WithGooglePicker from '../../../shared/WithGooglePicker';
import Button from '../../../lib/Button';
import Icon from '../../../lib/Icon';

const GoogleSheetUpload = ({ database, onGoogleSlidesClick }) => {
  return (
    <div className="flex gap-2 my-5">
      <Button category="secondary" onClick={onGoogleSlidesClick}>
        <span className="icon is-small">
          <img src={google_drive_logo} alt="Choose google sheet" />
        </span>
        <span>Choose Google Sheet</span>
      </Button>
      <span className="py-1 px-3 border border-grey-200 rounded content-center">{database ? database.name : '--'}</span>
      {database?.url && (
        <Button category="secondary" href={database.url} target="_blank" rel="noreferrer" size="small">
          <Icon name="open" size={20} />
        </Button>
      )}
    </div>
  );
};

GoogleSheetUpload.propTypes = {
  database: PropTypes.string,
  onGoogleSlidesClick: PropTypes.func,
};

export default WithGooglePicker(GoogleSheetUpload);
