import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container, Heading, Hero } from 'react-bulma-components';
import presentation_pack_icon from '../../images/presentation_pack.png';
import Constants from '../Constants';
import utils from '../../lib/utils';
import Button from '../lib/Button';
import useTemplate from 'lib/hooks/useTemplate';

function BulkPresentationComplete(props) {
  const { data: template } = useTemplate(props.templateId);

  const history = useHistory();
  const presentation = props.selectPresentation(props.presentationId);
  const templateText = utils.getTemplateText(template, presentation);
  const onBackClick = (e) => {
    e.preventDefault();
    history.push(`${props.urlBase}${props.templateId}`);
  };
  return (
    <Container className="is-fluid">
      <Hero>
        <Hero.Body>
          <Container className="has-text-centered narrow-container">
            <Heading size={5} subtitle>
              <img width="256px" src={presentation_pack_icon} alt="Presentation Pack" />
            </Heading>
            <Heading size={4} className="has-text-grey-dark">
              {`We're generating your ${templateText}`}
            </Heading>
            {template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && !presentation?.is_test_slide ? (
              <Heading size={5} subtitle className="has-text-grey-dark">
                You will receive an email when we&apos;ve generated them all.
              </Heading>
            ) : null}

            <span className="flex justify-center">
              <Button onClick={onBackClick}>Back to template</Button>
            </span>
          </Container>
        </Hero.Body>
      </Hero>
    </Container>
  );
}

BulkPresentationComplete.propTypes = {
  presentationId: PropTypes.object,
  selectPresentation: PropTypes.func,
  templateId: PropTypes.number,
  urlBase: PropTypes.string,
};

export default BulkPresentationComplete;
