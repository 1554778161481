import React, { useEffect } from 'react';
import BrandingFormWithWaterfall from 'components/shared/templateBuilder/BrandingFormWithWaterfall';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import SourceTypeDropdown from '../SourceTypeDropdown';
import API from 'lib/api';

export default function SelectBranding(props) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('start_flow');
    if (flow) {
      API.track('branding_info_navigation', {
        companyName: props.companyName,
        companyUrl: props.domainName,
        templateType: flow,
      });
    }
  }, []);
  return (
    <div className="modal-body-center">
      {props.sourceTypeDropdownOptions && (
        <>
          <div className="tb-branding-header">
            <div className="tb-branding-header-title">Format</div>
            <div className="tb-branding-header-body">Select the desired format for your template.</div>
          </div>
          <div className="tb-branding-form">
            <Form.Field className="is-horizontal">
              <Form.Control className="is-expanded is-fullwidth">
                <SourceTypeDropdown
                  selectedDropdownOption={props.sourceTypeSelectedOption}
                  onDropdownChange={props.onSourceDropdownChange}
                  dropdownOptions={props.sourceTypeDropdownOptions}
                />
              </Form.Control>
            </Form.Field>
          </div>
        </>
      )}
      <div className="tb-branding-header">
        <div className="tb-branding-header-title">Branding</div>
        <div className="tb-branding-header-body">
          Review the company logo and brand colors that Matik identified. You can make any desired changes here before
          generating the template.
        </div>
      </div>
      <div className="tb-branding-form">
        <BrandingFormWithWaterfall
          darkColorDescription={props.darkColorDescription}
          accentColorDescription={props.accentColorDescription}
          brandingInfo={props.brandingInfo}
          onBrandingInfoChange={props.onBrandingInfoChange}
          domainName={props.domainName}
          companyName={props.companyName}
          isLoading={props.isLoading}
          setIsLoading={props.setIsLoading}
          forceUpdate={props.forceUpdate}
        />
      </div>
    </div>
  );
}

SelectBranding.propTypes = {
  darkColorDescription: PropTypes.string,
  accentColorDescription: PropTypes.string,
  brandingInfo: PropTypes.object,
  onBrandingInfoChange: PropTypes.func,
  domainName: PropTypes.string,
  companyName: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  forceUpdate: PropTypes.number,
  sourceTypeSelectedOption: PropTypes.object,
  onSourceDropdownChange: PropTypes.func,
  sourceTypeDropdownOptions: PropTypes.array,
};
