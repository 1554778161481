import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useLocation, Redirect, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mapUiStateToProps } from './redux/ui/stateMappers';
import { onEscPress } from './redux/ui/action';
import OAuth from './components/auth/OAuth';
import { AuthRoute, ConsumerRoute, LoggedInRoute, ProducerRoute, SuperadminRoute } from './components/auth/Routes';
import ResetPassword from './components/auth/ResetPassword';
import SsoSignUp from './components/auth/SsoSignUp';
import SignUp from './components/auth/SignUp';
import Login from './components/auth/Login';
import SsoError from './components/auth/SsoError';
import ForgotPassword from './components/auth/ForgotPassword';
import ConsumerMain from './components/consumer/ConsumerMain';
import SoupMain from './components/soup_dumpling/SoupMain';
import Email from './components/email/Email';
import ProducerMain from './components/producer/ProducerMain';
import UpgradeTier from './components/payments/UpgradeTier';
import AccountInactive from './components/producer/account/AccountInactive';
import AboutMe from 'components/producer/onboarding/AboutMe';
import utils from '../src/lib/utils';
import LoginRedirect from './components/auth/LoginRedirect';
import Logout from './components/auth/Logout';
import SignUpTrial from './components/auth/SignUpTrial';
import pluralizeSettings from 'lib/pluralizeSettings';

function CoreApp() {
  const ui = useSelector((state) => mapUiStateToProps(state).ui);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isZendeskLoaded, setZendeskLoaded] = useState(!!window.zE);

  let zendeskChecker = null;
  const checkForZendesk = () => {
    if (!window.zE) {
      zendeskChecker = setTimeout(checkForZendesk, 100);
    } else {
      setZendeskLoaded(true);
    }
  };

  useEffect(() => {
    mountZendeskWidget();
    checkForZendesk();
    loadLinkedinTrackingScript();

    return () => {
      if (zendeskChecker) {
        clearTimeout(zendeskChecker);
      }
    };
  }, []);

  useEffect(() => {
    const escEventListener = (e) => dispatch(onEscPress(e, ui));
    document.addEventListener('keydown', escEventListener);
    return () => {
      document.removeEventListener('keydown', escEventListener);
    };
  }, [ui]);

  useEffect(() => {
    window.Appcues.page();
  }, [location.pathname]);

  useEffect(() => {
    if (isZendeskLoaded) {
      updateZendeskWidget();
    }
  }, [location.pathname, ui, isZendeskLoaded]);

  const mountZendeskWidget = () => {
    utils.getZendeskScript.then((script) => {
      document.body.appendChild(script);
    });
  };

  const updateZendeskWidget = () => {
    const { pathname } = location;
    const renderList = ['/', '/home', '/create'];
    const mount = renderList.indexOf(pathname) > -1 && !ui?.fullScreenModal?.isActive;
    if (!mount) {
      try {
        window.zE('messenger', 'close');
        window.zE('messenger:on', 'unreadMessages', (unreadMessages) => {
          if (unreadMessages > 0) {
            window.zE('messenger', 'open');
          }
        });
      } catch {
        mountZendeskWidget();
      }
    }
  };

  const loadLinkedinTrackingScript = () => {
    utils.getLinkedinTrackingScript().then().catch(); // leaving this here in case we need to perform additional actions after script loads or fails
  };

  pluralizeSettings();

  const showOverflow = ui?.overflowIsHidden ? 'overflow-hidden' : '';
  return (
    <div className={`${showOverflow}`}>
      <Switch>
        {
          // Add some redirects for backwards compatibility to support any lingering
          // external deep links to the old paths.
        }
        <Route path="/presentations">
          <PathPreservingRedirect toRootPath="/generated" />
        </Route>
        <Route path="/create/presentations">
          <PathPreservingRedirect toRootPath="/create/generated" />
        </Route>
        <Route path="/oauth/authorize">
          <OAuth />
        </Route>
        <AuthRoute path="/reset_password/:token" component={ResetPassword} />
        <AuthRoute path="/signup/trial/:flow" component={SignUpTrial} />
        <AuthRoute path="/signup/:token" component={SsoSignUp} />
        <AuthRoute path="/signup" component={SignUp} />
        <AuthRoute exact path="/login" component={Login} />
        <AuthRoute exact path="/login_redirect" component={LoginRedirect} />
        <AuthRoute exact path="/sso_error" component={SsoError} />
        <AuthRoute exact path="/forgot_password" component={ForgotPassword} />
        <LoggedInRoute path="/logout" component={Logout} />
        <LoggedInRoute path="/prices" component={UpgradeTier} />
        <LoggedInRoute path="/account_inactive" component={AccountInactive} />
        <LoggedInRoute path="/about_me" component={AboutMe} />
        <ConsumerRoute path="/create/:tab/menu/:item" component={ConsumerMain} />
        <ConsumerRoute path="/create/:tab" component={ConsumerMain} />
        <ConsumerRoute path="/create" component={ConsumerMain} />
        <SuperadminRoute path="/soup_dumpling" component={SoupMain} />
        <SuperadminRoute exact path="/emails/:name" component={Email} />
        <ProducerRoute path="/:tab/menu/:item" component={ProducerMain} />
        <ProducerRoute path="/:tab" component={ProducerMain} />
        <ProducerRoute path="/" component={ProducerMain} />
      </Switch>
    </div>
  );
}

export default CoreApp;

const PathPreservingRedirect = ({ toRootPath }) => {
  const match = useRouteMatch();
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: `${toRootPath}${location.pathname.substring(match.path.length)}`,
        search: location.search,
        hash: location.hash,
        state: location.state,
      }}
    />
  );
};
PathPreservingRedirect.propTypes = {
  toRootPath: PropTypes.string.isRequired,
};
