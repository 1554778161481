import PropTypes from 'prop-types';
import React from 'react';
import Constants from '../../Constants';
import Button from '../../lib/Button';
import ButtonGroup from '../../lib/ButtonGroup';

function DataSourceFormButtons(props) {
  const { auth, buttonDisabled, host, isLoading, selectedDataSource, testDataSource, testIsLoading, type } = props;
  let testButtonStatus = 'default';
  if (testIsLoading) {
    testButtonStatus = 'loading';
  } else if (
    auth &&
    !auth.test_url &&
    !auth.base_url &&
    !auth.x509_certificate &&
    !auth.private_key &&
    !auth.application_secret &&
    !host &&
    type !== Constants.DATA_SOURCE_TYPES.googlebq &&
    type !== Constants.DATA_SOURCE_TYPES.snowflake &&
    type !== Constants.DATA_SOURCE_TYPES.productboard
  ) {
    testButtonStatus = 'disabled';
  }
  let submitButtonStatus = 'default';
  if (isLoading) {
    submitButtonStatus = 'loading';
  } else if (buttonDisabled) {
    submitButtonStatus = 'disabled';
  }
  return (
    <div className="mt-3">
      <ButtonGroup align="right">
        {type !== Constants.DATA_SOURCE_TYPES.excel && (
          <Button type="button" category="secondary" status={testButtonStatus} onClick={testDataSource}>
            Test Connection
          </Button>
        )}
        <Button type="submit" status={submitButtonStatus}>
          {selectedDataSource ? 'Update Data Source' : 'Add Data Source'}
        </Button>
      </ButtonGroup>
    </div>
  );
}

DataSourceFormButtons.propTypes = {
  isLoading: PropTypes.bool,
  selectedDataSource: PropTypes.object,
  testIsLoading: PropTypes.bool,
  testDataSource: PropTypes.func,
  deleteDataSource: PropTypes.func,
  dataSourceType: PropTypes.string,
  type: PropTypes.string,
  auth: PropTypes.object,
  host: PropTypes.string,
  database: PropTypes.string,
  buttonDisabled: PropTypes.bool,
};

export default DataSourceFormButtons;
