import { useEffect } from 'react';

/**
 * Hook to fire an event if the user clicks outside of all given "inside" refs.
 * @param insideRefs An array of one or more DOM element refs considered to be "inside". e.g. a dropdown menu
 * @param handler The callback handler triggered when the user clicks outside.
 * @param handlerDeps A dependency array of any data required by the callback. This behaves
 * like a `useEffect` dependency array.
 */
export default function useOnClickOutside(insideRefs, handler, handlerDeps) {
  if (!Array.isArray(insideRefs)) {
    insideRefs = [insideRefs];
  }
  if (!handlerDeps) {
    handlerDeps = [handler];
  }

  useEffect(() => {
    const listener = (event) => {
      const isOutsideClick = (ref) => !ref.current?.contains(event.target);
      if (insideRefs.every(isOutsideClick)) {
        handler(event);
      }
    };
    document.addEventListener('click', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [...insideRefs, ...handlerDeps]);
}
