import React from 'react';
import PropTypes from 'prop-types';
import { withServiceWorkerUpdater } from '@3m1/service-worker-updater';

const Updater = (props) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  const onRefreshClick = (e) => {
    e.preventDefault();
    onLoadNewServiceWorkerAccept();
  };
  return newServiceWorkerDetected ? (
    <div className="refresh-container">
      <a href="#refresh" className="refresh-message" onClick={onRefreshClick}>
        New version detected. Update now!
      </a>
    </div>
  ) : null; // If no update is available, render nothing
};

Updater.propTypes = {
  newServiceWorkerDetected: PropTypes.bool,
  onLoadNewServiceWorkerAccept: PropTypes.func,
};

export default withServiceWorkerUpdater(Updater);
