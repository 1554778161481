import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import QueryEditor from '../DynamicContentQueryEditor';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

function MatikAiForm(props) {
  const dynamicContentContext = useContext(DynamicContentContext);

  return (
    <Form.Field className="query-parent">
      <Form.Control className="flex-1">
        <QueryEditor
          mode="plaintext"
          isDynamicContentSuggested
          inputs={dynamicContentContext.existingInputs}
          inputsInQueryString={props.inputsInQueryString}
        />
        {dynamicContentContext.queryStringError && (
          <Form.Help color="danger">{dynamicContentContext.queryStringError}</Form.Help>
        )}
      </Form.Control>
    </Form.Field>
  );
}

MatikAiForm.propTypes = {
  inputsInQueryString: PropTypes.object,
};

export default MatikAiForm;
