import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, Tag } from 'react-bulma-components';
import { UserContext } from '../UserContext';
import TextOverflowWithTooltip from './TextOverflowWithTooltip';
import qbrImage from '../../images/qbr-thumbnail.png';
import pricingImage from '../../images/pricingdeck.png';
import qbrOnePager from '../../images/QBRonepager.png';
import utils from '../../lib/utils';
import API from '../../lib/api';
import LongRequest from '../../lib/longRequest';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import TemplateLibraryModal from '../producer/templates/TemplateLibraryModal';
import PageLoader from './PageLoader';
import Constants from '../Constants';
import { useQuery } from '@tanstack/react-query';

class TemplateLibraryCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTemplateModal: false,
      templateId: null,
    };
  }

  render() {
    //this.fetchExpiredTemplateLibraryThumbnails();
    let image = qbrImage;

    if (this.props.templateData.template_id === Constants.TemplateLibraryInfo.pricing_template.template_id) {
      image = pricingImage;
    } else if (this.props.templateData.template_id === Constants.TemplateLibraryInfo.qbr_one_pager.template_id) {
      image = qbrOnePager;
    }

    return (
      <>
        <Card
          className="template-card"
          id={`template-card-${this.props.templateData.template_id}`}
          onClick={this.openModal}
        >
          <TemplateLibraryModal
            show={this.state.showTemplateModal}
            onClose={this.onModalClose}
            onGoogleClick={this.onGoogleContentClick}
            onPowerpointClick={this.onPowerpointContentClick}
            template={this.props.templateLibraryTemplate}
            googleTemplate={this.props.templateLibraryTemplate}
            powerpointTemplate={this.props.powerpointTemplateLibraryTemplate}
            onTemplateAdd={this.props.onTemplateAdd}
            templateData={this.props.templateData}
            googleThumbnailLoading={this.state.isGoogleThumbnailLoading}
            powerpointThumbnailLoading={this.state.isPowerPointThumbnailLoading}
          />
          <Tag className="template-library-template-tag">
            {this.props.templateData ? this.props.templateData.narrative_type : ''}
          </Tag>
          <img src={image} />
          <Card.Content className="template-card-content">
            <div className="title is-4 primary-card-text text-overflow-ellipsis has-text-left">
              <TextOverflowWithTooltip text={this.props.templateLibraryTemplate.name} />
              {this.props.templateData.template_title}
            </div>
          </Card.Content>
        </Card>
        <PageLoader
          isActive={this.state.isLoading}
          showLottie={false}
          title={this.props.templateData.generation_text}
        />
      </>
    );
  }

  fetchExpiredTemplateLibraryThumbnails = () => {
    const googleIntegration = utils.googleIntegration(this.context.user);
    if (googleIntegration && Date.parse(googleIntegration.expires_on) < Date.now() && this.state.showTemplateModal) {
      API.get(
        `/templates/template_library_template/google_slides/${this.props.templateData.template_id}/`,
        (response) => {
          this.setState({ templateLibraryTemplate: response.data });
        },
        API.defaultError,
      );
    }
  };

  onModalClose = (e) => {
    e.preventDefault();
    this.setState({ showTemplateModal: !this.state.showTemplateModal }, this.myFunction);
  };

  myFunction = () => {
    this.setState({ showTemplateModal: false });
  };

  openModal = () => {
    this.setState({ showTemplateModal: true });
    if (this.state.isGoogleThumbnailLoading) {
      this.fetchTemplateLibraryThumbnails();
    }
  };

  reopenModal = () => {
    this.setState({ showTemplateModal: true });
    this.fetchTemplateLibraryThumbnails();
  };

  onContentClick = (sourceType) => {
    let templateId;
    if (sourceType === Constants.TemplateLibrarySourceLabels.googleSlides.endpoint) {
      templateId = this.props.templateData.template_id;
    } else if (sourceType === Constants.TemplateLibrarySourceLabels.powerPoint.endpoint) {
      templateId = this.props.templateData.powerpoint_template_id;
    }
    this.setState({ isLoading: true, processingStatus: 'Uploading' });
    API.track('create_sample_content_click');
    const onSuccess = (response, onComplete) => {
      if (response.data.template_status === 'done' && this.state.processingStatus !== 'done') {
        this.setState({
          isLoading: false,
          selectedFile: null,
          name: '',
          description: '',
          processingStatus: response.data.template_status,
          templateId: response.data.new_entity.id,
        });
        if (this.templateForm) {
          this.templateForm.reset();
        }
        onComplete();
        this.props.history.push(`/templates/${response.data.new_entity.id}`);
        if (this.props.onTemplateAdd) {
          this.props.onTemplateAdd(response.data.new_entity);
        }
      } else if (response.data.template_status === 'unknown') {
        // This may happen if there is a race condition: another user loads the templates page in between status checks
        onComplete();
        window.location.reload();
      } else {
        this.setState({ processingStatus: response.data.template_status });
      }
    };

    const libraryTemplateLongRequest = new LongRequest('/templates');
    libraryTemplateLongRequest.post(
      {},
      onSuccess,
      this.submitError,
      null,
      null,
      `copy_from_template_library/${sourceType}/${templateId}/${this.props.templateData.template_title}`,
    );
    this.setState({ showTemplateModal: !this.state.showTemplateModal }, this.myFunction);
  };

  onGoogleContentClick = () => {
    this.onContentClick(Constants.TemplateLibrarySourceLabels.googleSlides.endpoint);
  };

  onPowerpointContentClick = () => {
    this.onContentClick(Constants.TemplateLibrarySourceLabels.powerPoint.endpoint);
  };

  submitError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };
}

TemplateLibraryCard.propTypes = {
  history: PropTypes.object,
  template: PropTypes.object,
  onTemplateAdd: PropTypes.func,
  templateData: PropTypes.object,
  templateLibraryTemplate: PropTypes.object,
  powerpointTemplateLibraryTemplate: PropTypes.object,
};

TemplateLibraryCard.contextType = UserContext;

const TemplateLibraryCardWrapper = (props) => {
  const userContext = useContext(UserContext);
  const googleIntegration = utils.googleIntegration(userContext.user);

  // ? const expired = Date.parse(googleIntegration.expires_on) < Date.now();

  const { data: googleSlidesLibraryTemplate } = useQuery({
    queryKey: ['template_library_template', 'google_slides', props.templateData.template_id],
    queryFn: () => {
      return API.get(
        `/templates/template_library_template/google_slides/${props.templateData.template_id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!props.templateData.template_id && !!googleIntegration,
  });

  const { data: powerpointLibraryTemplate } = useQuery({
    queryKey: ['template_library_template', 'powerpoint', props.templateData.powerpoint_template_id],
    queryFn: () => {
      return API.get(
        `/templates/template_library_template/powerpoint/${props.templateData.powerpoint_template_id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!props.templateData.powerpoint_template_id,
  });

  return (
    <TemplateLibraryCard
      templateLibraryTemplate={googleSlidesLibraryTemplate || {}}
      powerpointTemplateLibraryTemplate={powerpointLibraryTemplate || {}}
      {...props}
    />
  );
};
TemplateLibraryCardWrapper.propTypes = {
  templateData: PropTypes.object,
};

export default withRouter(withLDConsumer()(TemplateLibraryCardWrapper));
