import React from 'react';
import { Form, Box } from 'react-bulma-components';
import PropTypes from 'prop-types';

const TokenAuth = (props) => {
  const tokenDescription = props.auth ? props.auth.token_description : '';
  const authToken = props.auth ? props.auth.auth_token : '';
  return (
    <Box className="data-source-box">
      {props.checkboxes?.[0]}
      <Form.Field>
        <Form.Label htmlFor="token-description">Token Input Description</Form.Label>
        <Form.Help>Add a description that the End User sees</Form.Help>
        <Form.Control>
          <Form.Textarea
            name="token_description"
            onChange={props.onAuthChange}
            value={tokenDescription}
            autoComplete="off"
            id="token-description"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="auth-token">Test Access Token</Form.Label>
        <Form.Help>{tokenDescription}</Form.Help>
        <Form.Control>
          <Form.Input
            type="text"
            name="auth_token"
            onChange={props.onAuthChange}
            value={authToken}
            autoComplete="off"
            id="auth-token"
          />
        </Form.Control>
      </Form.Field>
    </Box>
  );
};

TokenAuth.propTypes = {
  onAuthChange: PropTypes.func,
  auth: PropTypes.object,
  checkboxes: PropTypes.array,
};

export default TokenAuth;
