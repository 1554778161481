import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DynamicContentForm from '../dynamicContent/DynamicContentForm';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import InputForm from '../dynamicContent/InputForm';
import Sidepane from '../../shared/sidepane/Sidepane';
import { SidepaneList } from '../../shared/sidepane/SidepaneList';
import Constants from 'components/Constants';
import RenamableModal from '../dynamicContent/RenamableModal';
import { useAllDataSources } from 'lib/hooks/useDataSource';
import ConditionForm from 'components/producer/conditions/ConditionForm';
import { useAllDynamicContentById, useOneDynamicContent } from '../../../lib/hooks/useDynamicContent';
import FormSkeletonLoader from '../dynamicContent/FormSkeletonLoader';

function TemplateSidepane(props) {
  const {
    template,
    accessesByItemId,
    closeModal,
    closeSidepane,
    openSidepane,
    deleteDynamicContent,
    openModal,
    toggleIsChanged,
    ui,
  } = props;

  useEffect(() => {
    return () => {
      closeSidepane();
    };
  }, []);

  const { dataSources } = useAllDataSources();

  const updateReturnFieldMapping = (fieldName, displayName) => {
    const { query_obj } = ui.modal.content.input;
    const queryString = JSON.parse(query_obj.query_string);
    if (displayName) {
      if (!queryString.return_field_mapping) {
        queryString.return_field_mapping = { [fieldName]: displayName };
      } else {
        queryString.return_field_mapping[fieldName] = displayName;
      }
    } else {
      if (queryString.return_field_mapping && queryString.return_field_mapping[fieldName]) {
        delete queryString.return_field_mapping[fieldName];
      }
    }
    const updatedContent = {
      ...ui.modal.content.input,
      query_obj: {
        ...query_obj,
        query_string: JSON.stringify(queryString),
      },
    };

    openModal('inputModal', updatedContent);
  };

  const handleCloseSidepane = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (ui.isChanged) {
      props.openUnsavedChangesModal({
        title: 'Close sidepane?',
        onConfirm: 'closeSidepane',
      });
    } else {
      closeSidepane();
    }
  };

  const onContentAdd = (contentId) => {
    if (contentId) {
      handleCloseSidepane();
    }
  };

  const formSaveButtonText = ui?.sidepane?.content ? 'Update' : 'Add';

  let sidepaneType = 'template';
  if (ui.googleSheetDrawer) {
    sidepaneType = 'template-with-sheet';
  }

  let sidepaneContent = null;
  if (ui.sidepane?.content?.entityType === Constants.UI_TYPES.dynamic_content) {
    if (ui.sidepane.content.matchingContent) {
      sidepaneContent = (
        <DynamicContentFormWrapper
          dynamicContentId={ui.sidepane.content.matchingContent.id}
          accessesByItemId={accessesByItemId}
          activeModal={ui.modal}
          closeModal={closeModal}
          deleteDynamicContent={deleteDynamicContent}
          googleSheetDrawerActive={!!ui.googleSheetDrawer}
          isChanged={ui.isChanged}
          onClose={handleCloseSidepane}
          onCloseDataTip="Close"
          onContentAdd={onContentAdd}
          saveButtonText={formSaveButtonText}
          showCancel={true}
          toggleIsChanged={toggleIsChanged}
          template={template}
        />
      );
    } else if (!ui.sidepane.content.matchingContent) {
      sidepaneContent = (
        <DynamicContentFormWrapper
          dynamicContentId={Constants.NEW_CONTENT_ID}
          activeModal={ui.modal}
          closeModal={closeModal}
          googleSheetDrawerActive={!!ui.googleSheetDrawer}
          isChanged={ui.isChanged}
          newContentName={ui.sidepane.content.name ? ui.sidepane.content.name : null}
          onClose={handleCloseSidepane}
          onContentAdd={onContentAdd}
          saveButtonText="Add"
          showCancel={true}
          toggleIsChanged={toggleIsChanged}
          template={template}
          defaultContentType={ui.sidepane.defaultContentType}
        />
      );
    }
  } else if (ui.sidepane?.content?.entityType === 'input') {
    sidepaneContent = (
      <InputForm
        activeModal={ui.modal}
        allowNameChange={true}
        closeModal={closeModal}
        dataSources={dataSources}
        entityId={`${ui.sidepane.content ? ui.sidepane.content.id : 0}`}
        entityType={Constants.UI_TYPES.input}
        headerLinkDisabled={false}
        input={ui.sidepane.content}
        isChanged={ui.isChanged}
        key={ui.sidepane.content ? ui.sidepane.content.id : 0}
        onClose={handleCloseSidepane}
        onCloseDataTip="Close"
        saveButtonText={formSaveButtonText}
        showCancel={true}
        showClickableCloseIcon={true}
        toggleIsChanged={toggleIsChanged}
      />
    );
  } else if (ui.sidepane?.content?.entityType === 'permissions') {
    sidepaneContent = <SidepaneList template={template} />;
  } else if (ui.sidepane?.content?.entityType === 'condition') {
    sidepaneContent = (
      <ConditionForm
        conditionId={ui.sidepane.content.conditionId}
        slideIds={ui.sidepane.content.slideIds}
        onClose={handleCloseSidepane}
        onCreated={(newCondition) => {
          openSidepane(
            { entityType: 'condition', conditionId: newCondition.id, slideIds: ui.sidepane.content.slideIds },
            'template-sidepane',
          );
        }}
      />
    );
  }

  return (
    <Sidepane sidepaneType={sidepaneType} name="template-sidepane">
      {sidepaneContent}
      <RenamableModal
        show={ui.modal?.name === 'renamableModal'}
        key={ui.modal?.content?.fieldName}
        fieldName={ui.modal?.content?.fieldName}
        isSubModal={true}
        onSave={updateReturnFieldMapping}
      />
    </Sidepane>
  );
}

TemplateSidepane.propTypes = {
  template: PropTypes.object,
  closeSidepane: PropTypes.func,
  openSidepane: PropTypes.func,
  dispatch: PropTypes.func,
  sidepaneContent: PropTypes.object,
  accessesByItemId: PropTypes.func,
  deleteDynamicContent: PropTypes.func,
  ui: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  isChanged: PropTypes.bool,
  toggleIsChanged: PropTypes.func,
  openUnsavedChangesModal: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const props = {
    ...ownProps,
    entityType: 'template',
  };
  return {
    ...mapUiStateToProps(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...mapUiDispatchToProps(dispatch),
  };
}

function DynamicContentFormWrapper({ dynamicContentId, ...otherProps }) {
  const { dynamicContent: currentContent, isLoading } = useOneDynamicContent(dynamicContentId);
  const { dynamicContentById } = useAllDynamicContentById();

  if (isLoading) {
    return (
      <div className="sidepane-loading">
        <FormSkeletonLoader />
      </div>
    );
  }

  const dynamicContentArr = Object.values(dynamicContentById || {});
  const query = currentContent?.query_obj ? currentContent.query_obj.query_string : '';

  return (
    <DynamicContentForm
      currentContent={currentContent}
      dynamicContentArr={dynamicContentArr}
      {...otherProps}
      query={query}
      key={dynamicContentId}
      entityId={dynamicContentId}
      entityType={Constants.UI_TYPES.dynamic_content}
    />
  );
}

DynamicContentFormWrapper.propTypes = {
  dynamicContentId: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSidepane);
