import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import { Form } from 'react-bulma-components';
import { Select } from '../FormSelect';
import trash_icon from '../../../images/trash.svg';
import { ReactComponent as Plus } from '../../../svg/plus.svg';
import Button from '../../lib/Button';

class InputNameAndValueRefinement extends Component {
  render() {
    const paramKeys = map(this.props.inputs.param_keys, (k) => ({ label: k.display_name, value: k.name }));
    return (
      <div>
        {map(this.props.paramInputs, (input) => (
          <div key={input} className="mbl">
            <Form.Field kind="group">
              <Form.Control fullwidth>
                <Select
                  classNamePrefix="matik-select"
                  isMulti={false}
                  placeholder={'Filter by Input'}
                  value={this.props.inputKeys[input] || []}
                  isClearable={false}
                  className="matik-refinement"
                  options={paramKeys}
                  onChange={(obj, action) => this.props.onInputKeySelect(input, obj, action)}
                />
              </Form.Control>
              <Form.Control>
                <Button category="secondary" onClick={() => this.props.deleteInput(input)}>
                  <img src={trash_icon} alt="Delete" />
                </Button>
              </Form.Control>
            </Form.Field>
            <Form.Field kind="group">
              <Form.Control className="man">
                <div className="flex mr-1 icon-button input-filter-icon-button pln">
                  <span className="input-filter-icon"></span>
                </div>
              </Form.Control>
              <Form.Control fullwidth className="mtm">
                <Form.Label>Input Value</Form.Label>
                <Select
                  options={this.props.selectedInputValues}
                  classNamePrefix="matik-select"
                  isMulti={true}
                  placeholder={''}
                  value={this.props.inputValues[input]}
                  isClearable={true}
                  className="matik-refinement"
                  onChange={(obj, action) => this.props.onInputValueSelect(input, obj, action)}
                  isDisabled={isEmpty(this.props.selectedInputValues)}
                />
              </Form.Control>
            </Form.Field>
          </div>
        ))}
        <Button onClick={this.props.appendInput} category="secondary">
          <Plus className="mrs" />
          Add Input
        </Button>
      </div>
    );
  }
}

InputNameAndValueRefinement.propTypes = {
  paramInputs: PropTypes.array,
  inputs: PropTypes.array,
  inputKeys: PropTypes.object,
  inputValues: PropTypes.object,
  selectedInputValues: PropTypes.array,
  onInputKeySelect: PropTypes.func,
  onInputValueSelect: PropTypes.func,
  appendInput: PropTypes.func,
  deleteInput: PropTypes.func,
};

export default InputNameAndValueRefinement;
