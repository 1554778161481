import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'images/icons';

function Icon({ color, name, size = 16, theme = 'regular' }) {
  const fullIconName = `${name}_${size}x${size}_${theme}`;
  const IconComponent = Icons[fullIconName];
  if (!IconComponent) {
    // eslint-disable-next-line no-console
    console.warn('Could not find icon ', fullIconName);
    return null;
  }
  let colorClass = '';
  if (color) {
    colorClass = `text-${color}`;
  }
  return <IconComponent className={colorClass} />;
}

Icon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf([12, 16, 20, 24, 28, 32, 48]),
  theme: PropTypes.oneOf(['filled', 'regular']),
};

export default Icon;
