import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bulma-components';
import DataSourcesGrid from './DataSourcesGrid.js';

const DataSourceEmptyContainer = ({ onNewDataSourceAdd }) => {
  return (
    <Container className="is-fluid pan data-source-empty-container">
      <div className="data-source-body">
        <div className="data-source-content-empty">
          <div className="data-source-header">Connect new Data Source</div>
          <DataSourcesGrid onClick={onNewDataSourceAdd} shouldAddFirst={true} />
        </div>
      </div>
    </Container>
  );
};

DataSourceEmptyContainer.propTypes = {
  onNewDataSourceAdd: PropTypes.func,
};

export default DataSourceEmptyContainer;
