import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tile, Form } from 'react-bulma-components';
import OutputTypeTabs from './OutputTypeTabs';
import TestResult from '../../../shared/TestResult';
import SampleData from '../SampleData';
import Constants from '../../../Constants';
import { UserContext } from '../../../UserContext';
import TabItem from 'components/lib/TabItem';
import Tabs from 'components/lib/Tabs';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

DataSourceFormWrapper.propTypes = {
  currentDataSource: PropTypes.object,
  dynamicContentMethod: PropTypes.string,
  formBody: PropTypes.element,
  onMethodChange: PropTypes.func,
  onOutputTypeSelect: PropTypes.func,
  testResult: PropTypes.object,
  helpText: PropTypes.string,
};

function DataSourceFormWrapper({
  currentDataSource,
  dynamicContentMethod,
  onOutputTypeSelect,
  onMethodChange,
  formBody,
  testResult,
  helpText,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);
  const widthRef = useRef(null);
  const userContext = useContext(UserContext);
  const ldClient = useLDClient();
  const enableDynamicSenderFeatures = ldClient?.variation('enable-dynamic-sender-features', false);

  const getWidth = () => {
    if (widthRef.current) {
      return widthRef.current.clientWidth;
    } else {
      return 0;
    }
  };

  const onTabClick = (updatedDynamicContentMethod) => {
    onMethodChange(updatedDynamicContentMethod);
  };

  let outputTypes = userContext?.user?.enterprise?.enterprise_settings?.matik_mail_enabled
    ? Object.values(Constants.DynamicContentTypes).filter((type) => type !== Constants.DynamicContentTypes.CONDITIONAL)
    : Object.values(Constants.DynamicContentTypes).filter(
        (type) =>
          ![
            Constants.DynamicContentTypes.CONDITIONAL,
            Constants.DynamicContentTypes.RECIPIENTS,
            Constants.DynamicContentTypes.SENDER,
          ].includes(type),
      );
  if (currentDataSource && Constants.DYNAMIC_CONTENT_TYPES_BY_DATA_SOURCE[currentDataSource.type]) {
    outputTypes = Constants.DYNAMIC_CONTENT_TYPES_BY_DATA_SOURCE[currentDataSource.type];
  }

  if (!enableDynamicSenderFeatures) {
    const senderIndex = outputTypes.indexOf(Constants.DynamicContentTypes.SENDER);
    if (senderIndex !== -1) {
      outputTypes.splice(senderIndex, 1);
    }
  }

  // Compute the correct methods to display for the selected data source
  let contentMethods = [];
  if (currentDataSource) {
    contentMethods = Object.values(Constants.DynamicContentMethods).filter(
      (method) => Constants.DATA_SOURCE_TYPES_FOR_METHOD[method].indexOf(currentDataSource.type) >= 0,
    );
    contentMethods = contentMethods.reverse();
  }

  const tabItems = contentMethods.map((method) => (
    <TabItem
      key={method}
      label={Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[method].text}
      onClick={onTabClick}
      isActive={method === dynamicContentMethod}
    />
  ));
  // TODO (zak): Need to differentiate between content types (Conditional/Static/Insights/Dynamic) and
  //  DynamicContentTypes (Text/Chart/Image/Table)
  const selectedOutput =
    dynamicContentContext.dynamicContentType === Constants.ContentType.INSIGHTS
      ? Constants.DynamicContentTypes.TEXT
      : dynamicContentContext.dynamicContentType;
  const width = getWidth();
  return (
    <>
      <div className="my-8">
        <OutputTypeTabs
          outputTypes={outputTypes}
          isReadOnly={dynamicContentContext.isReadOnly}
          selectedOutputType={selectedOutput}
          onOutputTypeSelect={onOutputTypeSelect}
        />
      </div>
      <Tile kind="child">
        <Tile kind="parent" className="is-paddingless lined-form" vertical>
          <Tile kind="parent" className="is-paddingless">
            <Tile kind="child" className="box is-paddingless is-shadowless has-light-gray-border query-grandparent">
              <div className="header-wrapper py-2" ref={widthRef}>
                <Tabs>{tabItems}</Tabs>
              </div>
              {formBody}
            </Tile>
          </Tile>
        </Tile>
        {helpText && <Form.Help className="my-2">{helpText}</Form.Help>}
        <TestResult
          testResult={testResult}
          dynamicContentType={dynamicContentContext.dynamicContentType}
          dataSource={currentDataSource?.type}
        />
        <SampleData
          testResult={testResult}
          dynamicContentType={dynamicContentContext.dynamicContentType}
          width={width}
        />
      </Tile>
    </>
  );
}

export default DataSourceFormWrapper;
