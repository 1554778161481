import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';

function HtmlBuilderVisualizeEmptyState({ dataType }) {
  return (
    <div className="html-builder-image-empty-state is-transparent" id="image_placeholder">
      <Icon name="table" size={20} theme="regular" />
      <p className="image-empty-state-font">Select Dynamic Content to start visualizing your {dataType}.</p>
    </div>
  );
}

HtmlBuilderVisualizeEmptyState.propTypes = {
  dataType: PropTypes.string,
};

export default HtmlBuilderVisualizeEmptyState;
