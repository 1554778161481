import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import utils from 'lib/utils';

import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { find, isEmpty } from 'lodash';
import Constants from '../../Constants';
import { useApiInfo } from '../../../lib/hooks/useApiInfo';
import { QueryEditor } from './QueryEditor';

function DynamicContentQueryEditor({
  mode = 'plaintext',
  isDynamicContentSuggested,
  dataSource,
  isInputPopoverDisabled,
  inputs,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);
  let query = dynamicContentContext.query;

  const [filters, setFilters] = useState([]);
  const [editorValue, setEditorValue] = useState(query);
  const initialRenderDone = useRef(false);

  const apiInfo = useApiInfo(dataSource || {}, 'objects', null, null);

  useEffect(() => {
    // skip running on initial render
    if (initialRenderDone.current) {
      const timer = setTimeout(async () => {
        if (dynamicContentContext.onQueryStringUpdate) {
          dynamicContentContext.onQueryStringUpdate(editorValue, filters);
        }
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [filters, 500]);

  useEffect(() => {
    initialRenderDone.current = true;
  }, []);

  // This really doesn't belong here. Ideally, it should be handled by the same code that
  // puts the updated query string back into the salesforce json blob
  if (dataSource?.type === 'salesforce' && utils.isValidJSON(query)) {
    query = JSON.parse(query).soql_string;
  }

  const handleChange = (val) => {
    setEditorValue(val);
    if (!isEmpty(apiInfo.data) && dataSource?.type === Constants.DATA_SOURCE_TYPES.salesforce) {
      addFiltersForApiFields(val);
    }

    if (dynamicContentContext.onQueryStringUpdate) {
      dynamicContentContext.onQueryStringUpdate(val, {});
    }
  };

  const addFiltersForApiFields = (val) => {
    const updatedFilters = [];
    val = val.split(/\s+/);
    val.forEach((item, idx) => {
      if (item.startsWith('&:') && find(inputs, { name: item.substring(2).toLowerCase() })) {
        let queryFieldName;
        if (['=', '!=', 'in', 'contains'].includes(val[idx - 1].toLowerCase())) {
          if (val[idx - 2].toLowerCase() === 'not') {
            queryFieldName = val[idx - 3];
          } else {
            queryFieldName = val[idx - 2];
          }
        }
        if (!queryFieldName) {
          return false;
        }

        let queryObjectName;
        for (let i = idx; i > 0; i--) {
          if (val[i].toLowerCase() === 'from') {
            queryObjectName = val[i + 1];
          }
        }

        let apiObjectName;
        Object.keys(apiInfo.data).forEach((key) => {
          if (key.toLowerCase() === queryObjectName.toLowerCase()) {
            apiObjectName = key;
          }
        });

        let fieldName;
        if (apiObjectName) {
          const apiObject = apiInfo.data[apiObjectName];
          if (apiObject) {
            apiInfo.data[apiObjectName]?.fields?.forEach((field) => {
              fieldName = field.name;
              if (fieldName.toLowerCase() === queryFieldName.toLowerCase()) {
                updatedFilters.push({
                  fieldName,
                  value: item,
                  type: field.type,
                });
              }
            });
          }
        }
      }
    });

    setFilters(updatedFilters);
  };

  return (
    <QueryEditor
      isReadOnly={dynamicContentContext.isReadOnly}
      queryString={query}
      onQueryStringUpdate={handleChange}
      isDynamicContentSuggested={isDynamicContentSuggested}
      isInputPopoverDisabled={isInputPopoverDisabled}
      inputs={inputs}
      mode={mode}
    />
  );
}
DynamicContentQueryEditor.propTypes = {
  mode: PropTypes.oneOf(['sql', 'plaintext', 'json']),
  /** Highlights dynamic content tags and provides autocomplete suggestions, when enabled */
  isDynamicContentSuggested: PropTypes.bool,
  /** True to suppress the input navigation popover. False will show the popover when the input name
   * is associated with a known input.
   */
  isInputPopoverDisabled: PropTypes.bool,
  dataSource: PropTypes.object,
  inputs: PropTypes.object,
};

export default DynamicContentQueryEditor;
