import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Section } from 'react-bulma-components';
import WithLoadingAndEmpty from '../../shared/WithLoadingAndEmpty';
import AnalyticsUserList from './AnalyticsUserList';
import AnalyticsEnterpriseOverview from './AnalyticsEnterpriseOverview';
import AnalyticsColumnChart from './AnalyticsColumnChart';

class AnalyticsContent extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="main">
          <Section className="pll">
            <Heading size={4}>Your Team Analytics</Heading>
          </Section>
          <Section className="pll ptn">
            <AnalyticsColumnChart />
          </Section>
          <Section className="pll ptn">
            <AnalyticsEnterpriseOverview analyticsData={this.props.analyticsData} />
          </Section>
          <Section className="pll ptn">
            <AnalyticsUserList />
          </Section>
        </div>
      </div>
    );
  }
}

AnalyticsContent.propTypes = {
  analyticsData: PropTypes.object,
  match: PropTypes.any,
};

export default WithLoadingAndEmpty(AnalyticsContent);
