import OperatorMap from './operatorMap';
import { isEmpty } from 'lodash';

export default class ConditionalContentProcessor {
  constructor(conditionalContent, inputValues) {
    this.conditionalContent = conditionalContent;
    this.inputValues = inputValues;
  }

  getOutputContent() {
    if (!this.conditionalContent.conditions || this.conditionalContent.conditions.length === 0) {
      return null;
    }

    let outputContent = null;
    for (let condition of this.conditionalContent.conditions) {
      if (this.isConditionTrue(condition)) {
        outputContent = condition.dynamicContent;
      }
    }

    return outputContent;
  }

  getAllContentNames() {
    return this.conditionalContent.conditions
      ? this.conditionalContent.conditions.map((condition) => condition.dynamicContent)
      : [];
  }

  isConditionTrue(condition) {
    if (!condition.clauses || condition.clauses.length === 0) {
      return false;
    }

    for (let clause of condition.clauses) {
      if (!this.isClauseTrue(clause)) {
        return false;
      }
    }

    return true;
  }

  isClauseTrue(clause) {
    const cleanValue = (value) => {
      const conditionalTrim = (v) => (typeof v === 'string' ? v.trim() : v);
      const maybeCoerceToNumber = (v) => (typeof v === 'string' && v !== '' && !isNaN(v) ? Number(v) : v);
      if (Array.isArray(value)) {
        return value.map(conditionalTrim).map(maybeCoerceToNumber);
      } else {
        return maybeCoerceToNumber(conditionalTrim(value));
      }
    };
    const inputValue = this.inputValues[clause.param];
    if (!inputValue) {
      return false;
    }
    const operatorFunc = OperatorMap.mapOperatorToFunc(clause.operator);
    let mappedValue = cleanValue(inputValue.value);
    if (!isEmpty(inputValue.mappedOptions)) {
      mappedValue = inputValue.mappedOptions[mappedValue];
    }
    return operatorFunc(mappedValue, cleanValue(clause.operand));
  }
}
