import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from '../../Constants';
import UserAvatar from 'react-user-avatar';
import { Heading, Media } from 'react-bulma-components';
import Pluralize from 'pluralize';

class MostActiveUserInsight extends Component {
  render() {
    return (
      <div>
        <Heading size={6} className="mtm mbm text-grey">
          Most Active User
        </Heading>
        <Media>
          <Media.Item align="left" className="user-avatar">
            {this.props.user && (
              <UserAvatar
                className="active-user-avatar has-text-white"
                colors={Constants.AVATAR_COLORS}
                src={this.props.user.photo_url}
                name={this.props.user.name}
                size={46}
              />
            )}
          </Media.Item>
          <Media.Item align="left">
            <div>
              <div className="user-presentations-name">{this.props.user.name}</div>
              <div className="user-presentations-count">
                {Pluralize('Presentation', this.props.presentationsCount ? this.props.presentationsCount : 0, true)}
              </div>
            </div>
          </Media.Item>
        </Media>
      </div>
    );
  }
}

MostActiveUserInsight.propTypes = {
  user: PropTypes.object,
  presentationsCount: PropTypes.number,
};

export default MostActiveUserInsight;
