import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from '../Constants';
import { Form } from 'react-bulma-components';
import ConditionalClauseInputField from './ConditionalClauseInputField';
import Clause from './Clause';
import { Select } from './FormSelect';
import InputText from 'components/lib/InputText';
import { useIsInputForbidden } from 'lib/hooks/useInput';

class ConditionalClause extends Component {
  render() {
    const input = this.props.clause.param;

    const operator = this.props.clause.operator;
    const onChange = (name, inputValues) => {
      return this.onClauseOperandChange(inputValues[name].value);
    };
    const supportedOperators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditional;
    return (
      <Clause
        conditionIndex={this.props.conditionIndex}
        clauseIndex={this.props.clauseIndex}
        generateEmptyClause={this.props.generateEmptyClause}
        queryObj={this.props.queryObj}
        updateQuery={this.props.updateQuery}
        isReadOnly={this.props.isReadOnly}
      >
        <Form.Control style={{ flex: '3 1' }}>
          <Select
            onChange={this.onClauseInputChange}
            classNamePrefix="matik-select"
            className="param-select-container"
            value={{ value: input, label: input }}
            options={Object.values(this.props.inputs).map((input) => ({ value: input.name, label: input.name }))}
            isDisabled={this.props.isReadOnly}
            menuPortalTarget={this.props.formRef?.current}
          />
        </Form.Control>
        <Form.Control style={{ flex: '1 1' }}>
          <Select
            onChange={this.onClauseOperatorChange}
            classNamePrefix="matik-select"
            value={{ value: operator, label: operator }}
            isDisabled={this.props.isReadOnly}
            options={supportedOperators.map((supportedOperator) => ({
              value: supportedOperator,
              label: supportedOperator,
            }))}
            menuPortalTarget={this.props.formRef?.current}
          />
        </Form.Control>
        <Form.Control style={{ flex: '3 1' }}>
          <InputCheckingClauseInputField
            clause={this.props.clause}
            inputs={this.props.inputs}
            updateQuery={this.props.updateQuery}
            queryObj={this.props.queryObj}
            conditionIndex={this.props.conditionIndex}
            clauseIndex={this.props.clauseIndex}
            onChange={onChange}
            isReadOnly={this.props.isReadOnly}
            formRef={this.props.formRef}
          />
        </Form.Control>
      </Clause>
    );
  }

  onClauseOperandChange = (value) => {
    this.onClauseChange(value, 'operand');
  };

  onClauseInputChange = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      const input = this.props.inputs[value];
      let initialValue =
        input.type === Constants.InputTypes.STRING && input.source_type === Constants.InputSources.LIST
          ? input.source_list[0]
          : Constants.INITIAL_VALUES_FOR_INPUT_TYPES[input.type];
      this.onClauseChange(initialValue, 'operand');
      this.onClauseChange(value, 'param');
    }
  };

  onClauseOperatorChange = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      this.onClauseChange(value, 'operator');
    }
  };

  onClauseChange = (newValue, fieldToUpdate) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (!updatedQueryObj.conditions) {
      updatedQueryObj.conditions = [];
    }
    if (updatedQueryObj.conditions.length === this.props.conditionIndex) {
      updatedQueryObj.conditions.push({ clauses: [] });
    }
    if (updatedQueryObj.conditions[this.props.conditionIndex].clauses.length === this.props.clauseIndex) {
      updatedQueryObj.conditions[this.props.conditionIndex].clauses.push(this.props.generateEmptyClause());
    }
    updatedQueryObj.conditions[this.props.conditionIndex].clauses[this.props.clauseIndex][fieldToUpdate] = newValue;
    this.props.updateQuery(updatedQueryObj, true);
  };
}

ConditionalClause.propTypes = {
  clause: PropTypes.object,
  clauseIndex: PropTypes.number,
  conditionIndex: PropTypes.number,
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  inputs: PropTypes.object,
  updateQuery: PropTypes.func,
  generateEmptyClause: PropTypes.func,
  formRef: PropTypes.object,
};

export default ConditionalClause;

function InputCheckingClauseInputField({
  clause,
  inputs,
  updateQuery,
  queryObj,
  conditionIndex,
  clauseIndex,
  onChange,
  isReadOnly,
  formRef,
}) {
  const input = clause.param;
  const inputObj = inputs[input];
  const operand = clause.operand;

  const { isPending, isForbidden } = useIsInputForbidden(inputObj?.id ? null : input);

  if (inputObj?.id) {
    return (
      <ConditionalClauseInputField
        updateQuery={updateQuery}
        queryObj={queryObj}
        conditionIndex={conditionIndex}
        clauseIndex={clauseIndex}
        operand={operand}
        inputObj={inputObj}
        onChange={onChange}
        isReadOnly={isReadOnly}
        formRef={formRef}
      />
    );
  } else if (isPending) {
    return null;
  } else if (isForbidden) {
    return <InputText isReadOnly status="error" value="You are missing read access to this input" />;
  } else {
    return <div>Input has been deleted.</div>;
  }
}
InputCheckingClauseInputField.propTypes = {
  clause: PropTypes.object,
  clauseIndex: PropTypes.number,
  conditionIndex: PropTypes.number,
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  inputs: PropTypes.object,
  updateQuery: PropTypes.func,
  onChange: PropTypes.func,
  formRef: PropTypes.object,
};
