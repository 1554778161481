import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RestForm from './RestForm';

class RestDynamicContentForm extends Component {
  render() {
    return (
      <RestForm
        queryObj={this.props.queryObj}
        inputsInQueryString={this.props.inputsInQueryString}
        dataSource={this.props.dataSource}
        apiInfo={this.props.apiInfo}
        entityType="dynamic_content"
      />
    );
  }
}

RestDynamicContentForm.propTypes = {
  queryObj: PropTypes.object,
  apiInfo: PropTypes.object,
  dataSource: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
};

export default RestDynamicContentForm;
