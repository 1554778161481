import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import Constants from '../../Constants';
import unknown_icon from '../../../images/unknown.svg';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { mapDispatchToProps } from '../../../redux/ui/dispatchers';

function InputCard(props) {
  const { hovering, input } = props;
  const imgSrc = Constants.INPUT_TYPES_FOR_DISPLAY[input.source_type]
    ? Constants.INPUT_TYPES_FOR_DISPLAY[input.source_type].icon
    : unknown_icon;

  return (
    <div className={`param-card text-overflow-ellipsis ptl ${hovering ? 'hover' : ''}`} id={`param-card-${input.id}`}>
      <Link to={`/inputs/${input.id}`}>
        <Level className="has-cursor-pointer has-text-black">
          <Level.Side align="left">
            <Level.Item>
              <img src={imgSrc} alt="Content Type" />
            </Level.Item>
            <div className="content-card-container">
              <TextOverflowWithTooltip
                className={'text-overflow-ellipsis min-width-0 dc-content-title pbs'}
                text={input.name}
              />
              <span>
                <TextOverflowWithTooltip
                  className="text-overflow-ellipsis min-width-0 dc-content-formats has-text-grey is-size-7"
                  text={input.formats ? input.formats.join(', ') : ''}
                />
              </span>
            </div>
          </Level.Side>
        </Level>
      </Link>
    </div>
  );
}

InputCard.propTypes = {
  content: PropTypes.object,
  hovering: PropTypes.bool,
  link: PropTypes.string,
  onChangeContent: PropTypes.func,
  showEditIcon: PropTypes.bool,
  openSidepane: PropTypes.func,
  closeSidepane: PropTypes.func,
  closeModal: PropTypes.func,
  input: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(InputCard);
