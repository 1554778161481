import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components, NonceProvider, createFilter } from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import UserAvatar from 'react-user-avatar';
import Constants from '../Constants';
import { ReactComponent as Close } from '../../svg/close.svg';
import QueryLoading from 'components/shared/QueryLoading';
import CheckboxWithLabel from './CheckboxWithLabel';
import { Form } from 'react-bulma-components';
import { ReactComponent as Search } from '../../svg/search_filled.svg';
import { Virtuoso } from 'react-virtuoso';
import ListItem from '../lib/ListItem';
import { v4 as uuidv4 } from 'uuid';
import Tag from '../lib/Tag';

const PasteableInput = (props) => {
  const { onPaste } = props.selectProps;
  return <components.Input onPaste={onPaste} {...props} />;
};

PasteableInput.propTypes = {
  selectProps: PropTypes.object,
};

const FormSelectStyles = (props) => {
  return {
    ...props.styles,
    menuPortal: (base) => ({
      ...(props.styles && props.styles.menuPortal ? props.styles.menuPortal(base) : base),
      zIndex: 111,
    }),
  };
};

const assignMenuPortalTarget = (props) => {
  return props.menuPortalTarget !== undefined ? props.menuPortalTarget : document.getElementById('dropdown-portal');
};

const isValueEqual = (prevValue, nextValue) =>
  prevValue?.label === nextValue?.label &&
  prevValue?.value === nextValue?.value &&
  prevValue?.displayName === nextValue?.displayName;
const areValuesEqual = (prevValue, nextValue) => {
  if (Array.isArray(prevValue) !== Array.isArray(nextValue)) {
    return false;
  }
  if (Array.isArray(prevValue)) {
    return areOptionsEqual(prevValue, nextValue);
  }
  return isValueEqual(prevValue, nextValue);
};
const areOptionsEqual = (prevOptions, nextOptions) => {
  if (prevOptions?.length !== nextOptions?.length || !prevOptions) {
    return false;
  }
  return prevOptions.every((element, index) => isValueEqual(element, nextOptions[index]));
};

const arePropsEqual = (prevProps, nextProps) => {
  const valueEqual = areValuesEqual(prevProps.value, nextProps.value);
  const optionsEqual = areOptionsEqual(prevProps?.options, nextProps?.options);
  const isMultiEqual = prevProps.isMulti === nextProps.isMulti;
  const isRenamableEqual = prevProps.isRenamable === nextProps.isRenamable;
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const isDisabledEqual = prevProps.isDisabled === nextProps.isDisabled;
  const isOnChangeEqual = prevProps.onChange === nextProps.onChange;
  return (
    valueEqual &&
    optionsEqual &&
    isMultiEqual &&
    isRenamableEqual &&
    isLoadingEqual &&
    isDisabledEqual &&
    isOnChangeEqual
  );
};

const ALL_VALUE = '<ALL>';

const flattenOptions = (option) => {
  if (option?.type === 'group') {
    return option.options.map(flattenOptions);
  }
  return option.value === ALL_VALUE ? [] : option;
};

const useSelectProps = (componentsToAdd, props) => {
  const containerRef = useRef(null);
  const searchRef = useRef(null);

  const [selectIsFocused, setSelectIsFocused] = useState(false);
  // This is a little weird. Setting the value of searchBarIsFocussed has no effect on the search bar's focus.
  // It's a means for the Menu to communicate back that the search bar has lost focus since react select
  // treats the search bar as a totally foreign entity and therefore its focus state has to be kept track of
  // independently.
  const [searchBarIsFocused, setSearchBarIsFocused] = useState(false);
  const menuIsOpen = selectIsFocused || searchBarIsFocused;

  let cleanedValue = props.value || [];
  if (props.isMulti && !Array.isArray(props.value)) {
    cleanedValue = [props.value];
  } else if (!props.isMulti && Array.isArray(props.value)) {
    cleanedValue = props.value[0];
  }

  let inputOptions = [];
  if (props.isMulti && props.options?.length > 1) {
    const selectOption = {
      label: 'Select All',
      value: ALL_VALUE,
    };
    const allOptions = props.options?.flatMap(flattenOptions);
    const createdOptions =
      props.value?.filter(
        (value) => allOptions.filter((option) => option && value && option.value === value.value).length === 0,
      ) || [];
    if (props.options && props.options[0]?.value !== ALL_VALUE) {
      inputOptions = [selectOption, ...props.options, ...createdOptions];
    } else {
      inputOptions = [selectOption, ...props.options.slice(1), ...createdOptions];
    }
  } else {
    if (props.options && props.options[0]?.value === ALL_VALUE) {
      inputOptions = props.options.slice(1);
    } else {
      if (
        props.options &&
        props.options.length <= 1 &&
        props.options[0]?.type !== 'group' &&
        (!props.options[0]?.value || props.options[0].value === '')
      ) {
        inputOptions = [];
      } else {
        inputOptions = props.options;
      }
    }
  }

  const finalComponents = {
    IndicatorSeparator: QueryLoadingIndicatorSeparator,
    DropdownIndicator,
    ClearIndicator,
    MultiValueRemove,
    Menu,
    MenuList,
    MultiValueContainer: MultiValueTagLabel,
    Option: props.isMulti ? Option : DefaultOption,
    ValueContainer: props.isMulti ? ValueContainerMulti : ValueContainer,
    MultiValueLabel: props.isRenamable ? RenamableMultiValueLabel : components.MultiValueLabel,
    ...componentsToAdd,
  };

  const onChange = (value, action) => {
    if (action.option?.value === ALL_VALUE) {
      const allOptions = inputOptions.flatMap(flattenOptions);
      if (value?.length > allOptions.length) {
        action.action = 'clear';
      }
    }

    //Treat deselect the same as remove
    if (action.action === 'deselect-option') {
      action = {
        action: 'remove-value',
        removedValue: action.option,
        name: action.name,
      };
    }
    if (action.action === 'clear') {
      value = { label: '', value: null };
    }
    if (action.action === 'select-option') {
      const selectedName = action.option || value;
      if (props.isMulti) {
        if (selectedName.value === ALL_VALUE) {
          const allOptions = inputOptions.flatMap(flattenOptions);
          value = allOptions;
          action = {
            option: allOptions,
            action: 'select-all-options',
          };
        }
      }
    }
    if (action.action === 'create-option' || action.action === 'create-option-bulk') {
      let valueWithNewOption = value;
      if (action.action === 'create-option') {
        valueWithNewOption = [value];
      }
      const newOptions = valueWithNewOption.filter((option) => !!option.__isNew__);
      newOptions.map((option) => ({ value: option.value, label: option.label }));
    }
    props.onChange ? props.onChange(value, action) : null;
  };

  const onFocus = () => {
    setSelectIsFocused(true);
  };

  const onBlur = () => {
    setSelectIsFocused(false);
  };

  const onMenuOpen = () => {
    setSelectIsFocused(true);
  };

  const onMenuClose = () => {
    setSelectIsFocused(false);
  };

  return {
    // Force the searchbar to clear when menu is closed so that the chosen value displays correctly
    // Setting this to undefined when the menu is open allows react select to default to controlling
    // this value itself.
    inputValue: menuIsOpen ? undefined : '',
    autoFocus: props.autoFocus,
    components: finalComponents,
    options: inputOptions,
    onChange: onChange,
    onBlur: onBlur,
    onFocus: onFocus,
    setSearchBarIsFocused: setSearchBarIsFocused,
    isLoading: props.isLoading || false,
    isSearchable: false,
    isMulti: props.isMulti,
    placeholder: props.placeholder,
    classNamePrefix: props.classNamePrefix,
    value: cleanedValue,
    menuPortalTarget: assignMenuPortalTarget(props),
    styles: FormSelectStyles(props),
    isDisabled: props.isDisabled,
    hideSelectedOptions: false,
    closeMenuOnSelect: !props.isMulti,
    isClearable: props.isClearable || false,
    menuRef: containerRef,
    searchRef: searchRef,
    menuIsOpen: menuIsOpen,
    isFocused: selectIsFocused,
    onMenuOpen: onMenuOpen,
    onMenuClose: onMenuClose,
    filterOption: createFilter({ ignoreAccents: false }),
    ignoreAccents: false,
    inputPlaceholder: 'Search',
    onPaste: null,
    openMenuOnClick: props.openMenuOnClick !== false,
  };
};

// the dropdown option placement is not reliable when the dropdown is near the bottom of the page
// this hook calculates the dropdown placement dynamically as a workaround
function useMenuPlacement(selectContainerRef) {
  const [menuPlacement, setMenuPlacement] = useState('auto');

  useEffect(() => {
    const calculateMenuPlacement = () => {
      if (selectContainerRef.current) {
        const rootHeight = document.getElementById('root')?.offsetHeight || 0;
        const selectElement = selectContainerRef?.current;
        const selectRect = selectElement?.getBoundingClientRect?.();
        const selectOffsetBottom = selectRect?.bottom || 0;

        // Check if the dropdown fits below the select element, otherwise flip it to 'top'
        const newMenuPlacement = rootHeight - selectOffsetBottom > 250 ? 'auto' : 'top';
        setMenuPlacement(newMenuPlacement);
      }
    };

    calculateMenuPlacement();
    window.addEventListener('resize', calculateMenuPlacement);

    return () => window.removeEventListener('resize', calculateMenuPlacement);
  }, [selectContainerRef.current]);
  return menuPlacement;
}

const Select = React.memo(({ componentsToAdd = {}, reactSelectRef, menuPosition = 'fixed', ...props }) => {
  const selectContainerRef = useRef(null);
  const menuPlacement = useMenuPlacement(selectContainerRef);

  const selectProps = useSelectProps(componentsToAdd, props);

  return (
    <div ref={selectContainerRef}>
      <ReactSelect
        ref={reactSelectRef}
        {...props}
        {...selectProps}
        menuPosition={menuPosition}
        menuPlacement={menuPlacement}
      />
    </div>
  );
}, arePropsEqual);

Select.displayName = 'Select';

Select.propTypes = {
  reactSelectRef: PropTypes.object,
  classNamePrefix: PropTypes.string,
  isMulti: PropTypes.bool,
  isRenamable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  menuPortalTarget: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  styles: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.any,
  autoFocus: PropTypes.bool,
  componentsToAdd: PropTypes.object,
  menuPosition: PropTypes.oneOf(['fixed', 'absolute']),
};

const QueryLoadingIndicatorSeparator = (props) => {
  return (
    !!props.selectProps.loadingData && (
      <QueryLoading
        isLoading={props.selectProps.loadingData.isLoading}
        dataTS={props.selectProps.loadingData.dataTS}
        onSyncButtonClick={props.selectProps.loadingData.onSyncButtonClick}
        hideText={true}
      />
    )
  );
};

QueryLoadingIndicatorSeparator.propTypes = {
  selectProps: PropTypes.object,
};

const CreatableSelect = React.memo(({ componentsToAdd = {}, reactSelectRef, ...props }) => {
  const selectProps = useSelectProps({ Input: PasteableInput, ...componentsToAdd }, props);
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardText = e.clipboardData.getData('Text');
    const elements = clipboardText
      .trim()
      .split(new RegExp('[\\n\\r\\t]'))
      .map((value) => {
        return { label: value, value: value, __isNew__: true };
      });

    selectProps.onChange(props.value.concat(elements), {
      action: 'create-option-bulk',
    });
  };
  const cacheKey = uuidv4().replace(/[^a-z]/g, '');
  const selectContainerRef = useRef(null);
  const menuPlacement = useMenuPlacement(selectContainerRef);
  return (
    <NonceProvider nonce="**CSP_NONCE**" cacheKey={cacheKey}>
      <div ref={selectContainerRef}>
        <ReactCreatableSelect
          ref={reactSelectRef}
          {...props}
          {...selectProps}
          formatCreateLabel={props.formatCreateLabel}
          onPaste={props.isPasteable ? handlePaste : null}
          inputPlaceholder="Search or create new ..."
          isClearable={props.isClearable}
          menuPlacement={menuPlacement}
        />
      </div>
    </NonceProvider>
  );
}, arePropsEqual);

CreatableSelect.displayName = 'CreatableSelect';

CreatableSelect.propTypes = {
  reactSelectRef: PropTypes.object,
  classNamePrefix: PropTypes.string,
  formatCreateLabel: PropTypes.func,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isPasteable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  components: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  menuPortalTarget: PropTypes.string,
  componentsToAdd: PropTypes.object,
  isRenamable: PropTypes.bool,
  formRef: PropTypes.func,
  isClearable: PropTypes.bool,
};

const CreatableBulkSelect = (props) => {
  return (
    <CreatableSelect
      {...props}
      components={props.components || { DropdownIndicator, ClearIndicator, Option, MultiValue: BulkMultiValue }}
      onMultiValueClick={props.onMultiValueClick}
      styles={FormSelectStyles(props)}
    />
  );
};

CreatableBulkSelect.propTypes = {
  components: PropTypes.object,
  onMultiValueClick: PropTypes.func,
  styles: PropTypes.object,
};

const AvatarSelect = (props) => {
  const cacheKey = uuidv4().replace(/[^a-z]/g, '');

  return (
    <NonceProvider nonce="**CSP_NONCE**" cacheKey={cacheKey}>
      <ReactSelect
        components={{
          DropdownIndicator,
          ClearIndicator,
          Option: AvatarOption,
          MultiValueLabel: AvatarMultiValueLabel,
          MultiValueRemove,
        }}
        options={props.options}
        onChange={props.onChange}
        isSearchable={props.isSearchable}
        {...props}
        isMulti={props.isMulti}
        placeholder={props.placeholder}
        classNamePrefix={props.classNamePrefix}
        value={props.value}
        menuPortalTarget={assignMenuPortalTarget(props)}
        styles={FormSelectStyles(props)}
      />
    </NonceProvider>
  );
};

AvatarSelect.propTypes = {
  classNamePrefix: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  styles: PropTypes.object,
};

const IconSelect = (props) => {
  const cacheKey = uuidv4().replace(/[^a-z]/g, '');

  return (
    <NonceProvider nonce="**CSP_NONCE**" cacheKey={cacheKey}>
      <ReactSelect
        components={{
          DropdownIndicator,
          ClearIndicator,
          Option: IconOption,
          Control: IconControl,
          MultiValueLabel: AvatarMultiValueLabel,
          MultiValueRemove,
        }}
        options={props.options}
        onChange={props.onChange}
        isSearchable={props.isSearchable}
        {...props}
        isMulti={props.isMulti}
        placeholder={props.placeholder}
        classNamePrefix={props.classNamePrefix}
        value={props.value}
        menuPortalTarget={assignMenuPortalTarget(props)}
        styles={FormSelectStyles(props)}
      />
    </NonceProvider>
  );
};

IconSelect.propTypes = {
  classNamePrefix: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  styles: PropTypes.object,
};

const CreatableInputOnly = (props) => {
  const [inputValue, setInputValue] = useState('');
  const handlePaste = (e) => {
    e.preventDefault();

    const clipboardText = e.clipboardData.getData('Text');
    // elements are separated by whitespace and commas
    const elements = clipboardText
      .trim()
      .replace(/\s+/g, ' ')
      .split(/[\s,]+/i)
      .map((value) => {
        return { label: value, value: value, __isNew__: true };
      });

    const updatedValue = props.value ? props.value.concat(elements) : elements;

    props.onChange(updatedValue, {
      action: 'create-option-bulk',
    });
  };
  const handleKeyDown = (e) => {
    if (!inputValue) {
      return;
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      setInputValue('');
      let newVal = [{ label: inputValue, value: inputValue, __isNew__: true }];
      if (props.value) {
        newVal = [...props.value, { label: inputValue, value: inputValue, __isNew__: true }];
      }
      props.onChange(newVal, {
        action: 'create-option',
      });
    }
  };
  const cacheKey = uuidv4().replace(/[^a-z]/g, '');

  return (
    <NonceProvider nonce="**CSP_NONCE**" cacheKey={cacheKey}>
      <ReactCreatableSelect
        classNamePrefix="matik-select"
        components={{
          Input: PasteableInput,
          DropdownIndicator: null,
          ClearIndicator,
          Option,
          ValueContainer: ValueContainerInputOnly,
          MultiValueContainer: MultiValueTagLabel,
        }}
        inputValue={inputValue}
        isDisabled={props.isDisabled}
        isMulti
        menuIsOpen={false}
        onChange={props.onChange}
        onInputChange={(inputValue) => setInputValue(inputValue)}
        placeholder={props.placeholder}
        value={props.value}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
      />
    </NonceProvider>
  );
};

CreatableInputOnly.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

/** Cap the number displayed values so we don't crash the user's browser. */
const ValueContainerInputOnly = ({ children, ...props }) => {
  const maxToShow = 100;
  const length = props.getValue().length;

  if (length > maxToShow && children.length > 1 && Array.isArray(children[0])) {
    // We're expecting 'children' to be an array of 2 elements
    const valueChildren = children[0];
    const inputChild = children[1];

    const trimmedValues = valueChildren.slice(0, maxToShow);
    trimmedValues.push(<MoreSelectedBadge key="...more" items={length - maxToShow} />);
    return <components.ValueContainer {...props}>{[trimmedValues, inputChild]}</components.ValueContainer>;
  } else {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }
};
ValueContainerInputOnly.propTypes = {
  children: PropTypes.array,
  getValue: PropTypes.func,
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        className="dropdown-indicator"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3 5L8 10L13 5" stroke="currentColor" strokeWidth="2" />
      </svg>
    </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let iconElement = null;
  if (props.selectProps.iconSelect) {
    iconElement = props.selectProps.iconElement;
  }
  return (
    <components.ValueContainer {...props}>
      {iconElement}
      {children}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.array,
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Close />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Close width="10px" />
    </components.MultiValueRemove>
  );
};

const MultiValueTagLabel = (props) => {
  const handleClose = (e) => {
    e.preventDefault();
    props.selectProps.onChange([], {
      action: 'remove-value',
      removedValue: props.data,
    });
  };
  const displayDefault = props.selectProps.isRenamable || props.data.isRenamable ? 'Add Display Name' : '';
  const displayName = displayDefault && props.data.displayName ? props.data.displayName : displayDefault;
  const tagColor = props.data.inErrorState ? 'red' : 'white';

  return (
    <components.MultiValueLabel {...props}>
      <Tag
        label={props.data?.label}
        size="s"
        onClick={(e) => {
          e.preventDefault();
        }}
        onClose={handleClose}
        actionLabel={displayName}
        actionOnClick={(e) =>
          props.data.toggleRenameModal(e, {
            name: props.data.value,
            label: props.data.label,
            displayName: props.data.displayName,
          })
        }
        color={tagColor}
        iconName={props.data.iconName}
        rightIconName={props.data.rightIconName}
        rightIconOnClick={props.data.rightIconOnClick}
        iconToolTip={props.data.iconToolTip}
        rightIconToolTip={props.data.rightIconToolTip}
        onRemoveToolTip={props.data.onRemoveToolTip}
        state={props.data.state}
      />
    </components.MultiValueLabel>
  );
};

MultiValueTagLabel.propTypes = {
  data: PropTypes.object,
  selectProps: PropTypes.object,
};

const BulkMultiValue = (props) => {
  const onClick = () => {
    if (!props.data.fullySelected && props.selectProps.onMultiValueClick) {
      props.selectProps.onMultiValueClick(props.data.value);
    }
  };
  return (
    <div className={props.data.fullySelected ? 'is-fully-selected' : null}>
      <components.MultiValue {...props}>
        <div onClick={onClick}>
          <span>{props.data.label}</span>
          {props.data.fullySelected && <span className="is-secondary-description">&nbsp;(All)</span>}
        </div>
      </components.MultiValue>
    </div>
  );
};

BulkMultiValue.propTypes = {
  selectProps: PropTypes.object,
  data: PropTypes.object,
};

const DefaultOption = (props) => <components.Option {...props} />;

const Option = (props) => {
  if (props.label === 'Select All') {
    const optionSelected = (option) =>
        props.selectProps.value?.some((o) => option.value === o?.value || option.value === ALL_VALUE),
      allOptions = props.options.flatMap(flattenOptions),
      count = allOptions.filter(optionSelected).length,
      allSelected = count === allOptions.length,
      someSelected = count > 0;

    return (
      <components.Option {...props}>
        <CheckboxWithLabel
          id={`${props.value}`}
          value={props.value}
          checked={allSelected}
          indeterminate={!allSelected && someSelected}
          label={props.label}
          onChange={() => null}
          readOnly={true}
          disabled={true}
          className="ignore-disabled option-select-all"
        />
      </components.Option>
    );
  }
  let label = props.label;
  if (typeof label === 'object') {
    // is create option
    label = props.value;
  }
  const content = (
    <CheckboxWithLabel
      id={`${props.value}`}
      value={props.value}
      checked={props.isSelected || false}
      label={label}
      onChange={() => null}
      readOnly={true}
      disabled={true}
      className="ignore-disabled"
    />
  );
  return <components.Option {...props}>{content}</components.Option>;
};

Option.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.any,
  value: PropTypes.any,
  selectProps: PropTypes.object,
  options: PropTypes.array,
  setValue: PropTypes.func,
};

const IconOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="is-flex is-vertical-centered ">
        {props.data.icon}
        <span className="mlm">{props.label}</span>
        {props.data.disabledMessage && (
          <span className="mlm disabled-message is-italic">* {props.data.disabledMessage}</span>
        )}
      </div>
    </components.Option>
  );
};

IconOption.propTypes = {
  data: PropTypes.object,
  label: PropTypes.any,
  disabledMessage: PropTypes.string,
};

const ListItemOption = (props) => {
  return (
    <components.Option {...props}>
      <ListItem
        indicatorColor={props.data.indicatorColor}
        title={props.label}
        subtitle={props.data.subtitle}
        isSubtitleInline={true}
        icon={props.data.icon}
        metadata={props.data.metadata}
      />
    </components.Option>
  );
};

ListItemOption.propTypes = {
  data: PropTypes.shape({
    indicatorColor: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.node,
    metadata: PropTypes.node,
  }),
  label: PropTypes.any,
};

const IconControl = ({ children, ...props }) => {
  const currentValue = props.getValue()?.[0]?.value;
  const icon = Object.values(props.options).find((option) => option.value === currentValue)?.icon;
  return (
    <components.Control {...props}>
      <div className="data-source-dropdown-select">{icon}</div>
      {children}
    </components.Control>
  );
};
IconControl.propTypes = {
  getValue: PropTypes.func,
  options: PropTypes.array,
  children: PropTypes.any,
};

const AvatarOption = (props) => {
  let photoUrl = '';
  if (props.data.photo_url) {
    photoUrl = props.data.photo_url;
  }
  return (
    <components.Option {...props}>
      <div className="is-flex is-vertical-centered ">
        <UserAvatar
          src={photoUrl}
          name={props.label}
          colors={Constants.AVATAR_COLORS}
          size={30}
          className="has-text-white is-uppercase"
        />
        <span className="mlm">{props.label}</span>
      </div>
    </components.Option>
  );
};

AvatarOption.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
};

const AvatarMultiValueLabel = (props) => {
  let photoUrl = '';
  if (props.data.photo_url) {
    photoUrl = props.data.photo_url;
  }

  const backgroundClass =
    props.selectProps.styles.backgroundColor && props.selectProps.styles.backgroundColor === 'white'
      ? 'has-background-white'
      : 'has-background-grey-lighter';

  return (
    <components.MultiValueLabel {...props}>
      <div className={`is-flex is-vertical-centered ${backgroundClass}`}>
        <UserAvatar
          src={photoUrl}
          name={props.data.label}
          colors={Constants.AVATAR_COLORS}
          size={16}
          style={{ fontSize: '8px' }}
          className="has-text-white is-uppercase"
        />
        <span className="mls">{props.data.label}</span>
      </div>
    </components.MultiValueLabel>
  );
};

AvatarMultiValueLabel.propTypes = {
  data: PropTypes.object,
  selectProps: PropTypes.object,
};

const RenamableMultiValueLabel = (props) => {
  if (props && props.data) {
    const displayName = props.data.displayName ? props.data.displayName : 'Add Display Name';
    return (
      <React.Fragment>
        <components.MultiValueLabel {...props}>
          <span className="mrs">{props.data.label}</span>(
          <a
            href="#updateDisplayName"
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) =>
              props.data.toggleRenameModal(e, {
                name: props.data.value,
                label: props.data.label,
                displayName: props.data.displayName,
              })
            }
          >
            {displayName}
          </a>
          )
        </components.MultiValueLabel>
      </React.Fragment>
    );
  }
};

RenamableMultiValueLabel.propTypes = {
  data: PropTypes.object,
};

const MoreSelectedBadge = ({ items }) => {
  return (
    <div title="See more items">
      ...{' '}
      <span className="tag more-selected">
        <Tag
          label={items.toString()}
          size="s"
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </span>
    </div>
  );
};

MoreSelectedBadge.propTypes = {
  items: PropTypes.number,
};

const ValueContainerMulti = ({ children, getValue, ...props }) => {
  let iconElement = null;
  if (props.selectProps.iconSelect) {
    iconElement = props.selectProps.iconElement;
  }

  const maxToShow = 3;
  const length = getValue().length;
  const childs = React.Children.toArray(children);
  let displayChips = childs.slice(0, maxToShow);
  let displayLength = length - maxToShow;
  let displayMoreBadge = length > maxToShow ? <MoreSelectedBadge items={displayLength} /> : null;

  const hasDummy = displayChips.some(
    (child) => child.type.name === 'DummyInput' || child.type.name === 'PasteableInput',
  );
  if (!hasDummy) {
    // Dummy input is always last
    displayChips.push(childs.pop());
  }

  return (
    <components.ValueContainer {...props}>
      {iconElement}
      {!props.selectProps.inputValue && displayChips}
      {displayMoreBadge}
    </components.ValueContainer>
  );
};

ValueContainerMulti.propTypes = {
  children: PropTypes.array,
  getValue: PropTypes.func,
  selectProps: PropTypes.object,
  inputValue: PropTypes.string,
};

const Menu = ({ selectProps, ...props }) => {
  const { setSearchBarIsFocused, onInputChange, inputValue, menuRef, searchRef, inputPlaceholder, onPaste } =
    selectProps;
  const SearchBar = (
    <Form.Control className="has-icons-left">
      <Form.Input
        id="select-menu-search"
        className="select-menu-search"
        type="text"
        size="medium"
        domRef={searchRef}
        placeholder={inputPlaceholder}
        value={inputValue}
        onBlur={() => setSearchBarIsFocused && setSearchBarIsFocused(false)}
        onFocus={() => setSearchBarIsFocused && setSearchBarIsFocused(true)}
        onChange={(e) => {
          return onInputChange(e.currentTarget.value, {
            action: 'input-change',
          });
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onPaste={onPaste}
        autoFocus
      />
      <span className="icon is-small is-left">
        <Search />
        <i className="fas fa-search" />
      </span>
    </Form.Control>
  );
  const addedClass = props.isMulti ? 'matik-multi-select__menu' : 'matik-single-select__menu';
  return (
    <components.Menu {...props} selectProps={selectProps} className={addedClass} innerRef={menuRef}>
      <div className="menu-list-container">
        <div className="select-menu-list-header search">{SearchBar}</div>
        {props.children}
      </div>
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.object,
  selectProps: PropTypes.object,
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  menuRef: PropTypes.any,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
};

const MenuNoSearch = ({ selectProps, ...props }) => {
  const { menuRef } = selectProps;
  return (
    <components.Menu {...props} selectProps={selectProps}>
      <div className="menu-list-container" ref={menuRef}>
        {props.children}
      </div>
    </components.Menu>
  );
};

MenuNoSearch.propTypes = {
  children: PropTypes.object,
  selectProps: PropTypes.object,
  menuRef: PropTypes.any,
};

const InnerItem = React.memo(({ children }) => {
  return <>{children}</>;
});
InnerItem.displayName = 'InnerItem';
InnerItem.propTypes = {
  children: PropTypes.object,
};

const getListHeight = (length) => {
  const itemHeight = 40;
  return length < 6 ? length * itemHeight : 6 * itemHeight;
};

const ResizeObserverErrorHandler = (e) => {
  if (
    e.message === 'ResizeObserver loop limit exceeded' ||
    e.message === 'ResizeObserver loop completed with undelivered notifications.'
  ) {
    e.stopImmediatePropagation();
    e.preventDefault();
    return true;
  }
};

const MenuList = ({ children }) => {
  const virtuosoRef = useRef(null);

  useEffect(() => {
    window.addEventListener('error', ResizeObserverErrorHandler);
    return () => {
      window.removeEventListener('error', ResizeObserverErrorHandler);
    };
  }, []);

  let childrenLengthHeight = children?.length || 0;
  const iterableChildren = children?.length > 0 ? children : [];
  for (const c of iterableChildren) {
    if (c.type === components.Group) {
      childrenLengthHeight += c.props?.children?.length || 0;
    }
  }

  return Array.isArray(children) ? (
    <Virtuoso
      ref={virtuosoRef}
      overscan={{ main: 12, reverse: 12 }}
      style={{ height: `${getListHeight(childrenLengthHeight)}px` }}
      totalCount={children?.length || 0}
      itemContent={(index) => <InnerItem>{children[index]}</InnerItem>}
    />
  ) : (
    <div>{children}</div>
  );
};

MenuList.propTypes = {
  children: PropTypes.any,
  focusedOption: PropTypes.object,
  selectProps: PropTypes.object,
};

const ListItemSelect = (props) => (
  <ReactSelect
    components={{
      DropdownIndicator,
      ClearIndicator,
      Option: ListItemOption,
    }}
    options={props.options}
    onChange={props.onChange}
    isSearchable={props.isSearchable}
    {...props}
    isMulti={props.isMulti}
    placeholder={props.placeholder}
    classNamePrefix={props.classNamePrefix}
    value={props.value}
    menuPortalTarget={assignMenuPortalTarget(props)}
    styles={FormSelectStyles(props)}
  />
);

ListItemSelect.propTypes = {
  classNamePrefix: PropTypes.string,
  formatCreateLabel: PropTypes.func,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  menuPortalTarget: PropTypes.string,
};

export {
  Select,
  CreatableSelect,
  CreatableBulkSelect,
  AvatarSelect,
  IconSelect,
  CreatableInputOnly,
  MenuNoSearch,
  ListItemSelect,
};
