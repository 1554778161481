import React from 'react';
import { useEditor } from '@craftjs/core';

function findNodeByName(nodes, nodeName) {
  for (let key in nodes) {
    if (nodes[key].data.name === nodeName) {
      return nodes[key];
    }
  }
  return null;
}

function EmailBodySettingsRender() {
  const { emailBodyNode } = useEditor((state, query) => {
    const currentNodes = query.getNodes();
    const emailBodyNode = findNodeByName(currentNodes, 'EmailBody');

    return {
      emailBodyNode,
    };
  });

  return emailBodyNode ? (
    <>
      <p className="modules-header">Email Body Settings</p>
      <div>{React.createElement(emailBodyNode.related.settings)}</div>
    </>
  ) : null;
}

export default EmailBodySettingsRender;
