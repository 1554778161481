import React from 'react';
import PropTypes from 'prop-types';
import InputWithError from './InputWithError';
import { ReactComponent as OpenSpreadsheet } from '../../svg/open_spreadsheet.svg';

function InputWithSpreadSheet(props) {
  let className = '';
  if (props.isSelected) {
    className = 'is-focused';
  }

  return (
    <div className="input-with-icon">
      <div className="input-box">
        <InputWithError
          aria-label={props.ariaLabel}
          className={className}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          placeholder={props.placeholder}
          disabled={props.isReadOnly}
          error={props.error}
          onFocus={() => props.onFocus()}
        />
      </div>
      <div className="open-spreadsheet-icon has-cursor-pointer" onClick={() => props.onOpenDrawer()}>
        <OpenSpreadsheet />
      </div>
    </div>
  );
}

InputWithSpreadSheet.propTypes = {
  ariaLabel: PropTypes.string,
  error: PropTypes.string,
  isSelected: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onOpenDrawer: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default InputWithSpreadSheet;
