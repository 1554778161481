import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Menu } from 'react-bulma-components';
import { isEmpty } from 'lodash';
import { ReactComponent as AllTemplates } from '../../../images/icons/template_grid-20x20-regular.svg';
import { ReactComponent as TopTemplates } from '../../../images/icons/template_grid_top-20x20-regular.svg';
import { ReactComponent as RecentTemplates } from '../../../images/icons/clock-20x20-regular.svg';
import { ReactComponent as Star } from '../../../images/icons/star-20x20-regular.svg';
import { ReactComponent as New } from '../../../images/icons/megaphone-20x20-regular.svg';
import { ReactComponent as Archive } from '../../../images/icons/archive-20x20-regular.svg';
import './TemplatesSidebar.sass';
import Constants from '../../Constants';
import Filters from '../search/Filters';
import API from '../../../lib/api';

function TemplatesSidebar(props) {
  const history = useHistory();
  const [itemTags, setItemTags] = useState([]);

  useEffect(() => {
    let isMounted = true;
    selectSidebarItem(props.activeSidebarMenuItem);
    API.get(
      '/item_tags/enterprise/template/count/',
      (response) => {
        if (isMounted) {
          setItemTags(response.data.sorted_tags_with_count);
        }
      },
      API.defaultError,
    );

    return () => (isMounted = false);
  }, []);

  const handleClick = (item) => {
    history.push(`${props.urlBase || ''}/templates/menu/${item}`);
    selectSidebarItem(item);
  };

  const selectSidebarItem = (item) => {
    if (item === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.top_templates) {
      props.onSearchParamsChange(0, null, Constants.PAGE_SIZE, Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.top_templates);
    } else if (item === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites) {
      props.onSearchParamsChange(0, null, Constants.PAGE_SIZE, Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites);
    } else if (item === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.new_for_me) {
      props.onSearchParamsChange(
        0,
        ['updated_on', 'desc'],
        Constants.PAGE_SIZE,
        Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.new_for_me,
      );
    } else if (item === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.recent_templates) {
      props.onSearchParamsChange(
        0,
        null,
        Constants.PAGE_SIZE,
        Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.recent_templates,
      );
    } else if (item === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.archived_templates) {
      props.onSearchParamsChange(
        0,
        ['updated_on', 'desc'],
        Constants.PAGE_SIZE,
        Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.archived_templates,
      );
    } else {
      props.onSearchParamsChange(0, null, Constants.PAGE_SIZE, Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates);
    }
  };

  return (
    <div className="sidebar narrow-sidebar overflow-y-auto right-border">
      <Menu className="mtm">
        <Menu.List className="phl">
          <Menu.List.Item
            id="end-user-all-templates"
            key="all_templates"
            className="mbs"
            active={props.activeSidebarMenuItem === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates}
            onClick={() => handleClick(Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates)}
          >
            <AllTemplates className="sidebar-icon-pull-down mrm" />
            All Templates
          </Menu.List.Item>
          <Menu.List.Item
            id="end-user-top-templates"
            key="top_templates"
            className="mbs"
            active={props.activeSidebarMenuItem === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.top_templates}
            onClick={() => handleClick(Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.top_templates)}
          >
            <TopTemplates className="sidebar-icon-pull-down mrm" />
            My Top Templates
          </Menu.List.Item>
          <Menu.List.Item
            id="end-user-recent-templates"
            key="recent_templates"
            className="mbs"
            active={props.activeSidebarMenuItem === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.recent_templates}
            onClick={() => handleClick(Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.recent_templates)}
          >
            <RecentTemplates className="sidebar-icon-pull-down mrm" />
            My Recent Templates
          </Menu.List.Item>
          <Menu.List.Item
            id="end-user-favorite-templates"
            key="favorites"
            className="mbs"
            active={props.activeSidebarMenuItem === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites}
            onClick={() => handleClick(Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.favorites)}
          >
            <Star className="sidebar-icon-pull-down mrm" />
            My Favorite Templates
          </Menu.List.Item>
          <Menu.List.Item
            id="end-user-new-templates"
            key="new_for_me"
            className="mbs"
            active={props.activeSidebarMenuItem === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.new_for_me}
            onClick={() => handleClick(Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.new_for_me)}
          >
            <New className="sidebar-icon-pull-down mrm" />
            New For Me
          </Menu.List.Item>
          {props.isAdminView && (
            <Menu.List.Item
              id="end-user-archived-templates"
              key="archived_templates"
              active={props.activeSidebarMenuItem === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.archived_templates}
              onClick={() => handleClick(Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.archived_templates)}
            >
              <Archive className="sidebar-icon-pull-down mrm" />
              Archived Templates
            </Menu.List.Item>
          )}
        </Menu.List>
        {!isEmpty(itemTags) && (
          <Container className="pbl ptl is-fluid">
            <div className="light-top-border ptl">
              <Filters
                attributes={{ item_tags: 'Filter by Tags' }}
                filterList={props.searchState.refinementList}
                sidebar={false}
                show={true}
                inputs={null}
                searchState={props.searchState}
                onSearchStateChange={props.onSearchStateChange}
                itemTags={itemTags}
                templateTags={true}
              />
            </div>
          </Container>
        )}
      </Menu>
    </div>
  );
}

TemplatesSidebar.propTypes = {
  urlBase: PropTypes.string,
  activeSidebarMenuItem: PropTypes.string,
  // Props drilled through from WithInstantSearch
  searchState: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  onSearchParamsChange: PropTypes.func,
  isAdminView: PropTypes.bool,
};

export default TemplatesSidebar;
