import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal/Modal';

class TestInputsModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        title={`Input Values For ${this.props.title}`}
        onClose={this.props.onClose}
        showDefaultFooter={false}
      >
        {this.props.children}
      </Modal>
    );
  }
}

TestInputsModal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
};

export default TestInputsModal;
