import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../svg/close_14.svg';
import { Form } from 'react-bulma-components';

const FileUploadButton = (props) => {
  let fileName = 'No File Uploaded';
  if (props.fileData?.file) {
    fileName = props.fileData?.file.name;
  }
  return (
    <Form.Field className="mvl">
      <Form.Control>
        <Form.Field className="has-addons">
          <Form.Control>
            <label className="button is-secondary">
              {props.fileData.logo && (
                <img
                  src={props.fileData.logo}
                  alt={props.fileData.logo_alt ? props.fileData.logo_alt : ''}
                  className="icon is-small"
                ></img>
              )}
              <span>Upload {props.fileData?.fileType ? props.fileData.fileType : ''} file</span>
              <input
                id="upload-file"
                className="template-file-picker-input"
                type="file"
                name="file"
                onChange={props.onFileSelected}
                accept={props.fileData.mimeType}
              />
            </label>
            <span className={props.fileError ? 'help is-danger' : ''}>{props.fileError}</span>
          </Form.Control>
          <Form.Control>
            <span className="file-name merge">
              {fileName}
              {props.fileData?.file && (
                <button className="button is-close" aria-label="close" onClick={props?.removeFile}>
                  <CloseIcon className="is-small mls mt" />
                </button>
              )}
            </span>
          </Form.Control>
        </Form.Field>
      </Form.Control>
    </Form.Field>
  );
};

FileUploadButton.propTypes = {
  fileData: PropTypes.object,
  onFileSelected: PropTypes.func,
  fileError: PropTypes.string,
  removeFile: PropTypes.func,
};

export default FileUploadButton;
