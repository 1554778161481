import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Form } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Auth } from '../../lib/auth';
import CheckboxWithLabel from '../shared/CheckboxWithLabel';
import matik_logo_black from '../../images/matik_logo_black.svg';
import google_logo from '../../images/google_logo.svg';
import azure_logo from '../../images/azure_logo.svg';
import okta_logo from '../../images/okta_logo.svg';
import { ReactComponent as Mail } from '../../svg/mail_filled.svg';
import { ReactComponent as WarningTriangle } from '../../svg/warning_triangle_solid.svg';
import { ReactComponent as Lock } from '../../svg/lock_closed.svg';
import Button from '../lib/Button';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.LOGIN_STATES = {
      email: 'email',
      password: 'password',
    };
    this.state = {
      email: '',
      password: '',
      emailErrorMsg: '',
      isEmailInvalid: false,
      isLoginInvalid: false,
      isPasswordInvalid: false,
      loginState: this.LOGIN_STATES.email,
      redirectUrl: null,
      rememberMe: false,
      ssoName: '',
    };

    this.SSO_NAME = {
      Google: 'Google',
      Azure: 'Azure',
      Okta: 'Okta',
      OneLogin: 'OneLogin',
    };

    this.SSO_LOGO_MAP = {
      Google: google_logo,
      Azure: azure_logo,
      Okta: okta_logo,
      onelogin: null,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="auth-logo">
          <img src={matik_logo_black} alt="matik logo" height="40px" />
        </div>
        <Card className="auth-card">
          {this.props.loginSubtitle && (
            <h1 className="is-size-4 has-text-centered mbn mtm">{this.props.loginSubtitle}</h1>
          )}
          <form onSubmit={this.onLoginSubmit} className="card-content" noValidate>
            {this.state.loginState === this.LOGIN_STATES.email && this.emailForm()}
            {this.state.loginState === this.LOGIN_STATES.password && this.passwordForm()}
          </form>
        </Card>
      </React.Fragment>
    );
  }

  emailForm = () => {
    let emailInputClass = 'input is-medium';
    if (this.state.isEmailInvalid || this.state.isLoginInvalid) {
      emailInputClass += ' is-danger';
    }
    return (
      <React.Fragment>
        <Form.Field className="mtl">
          <Form.Control className="has-icons-left has-icons-right">
            <Form.Input
              autoFocus
              autoComplete="email"
              className={emailInputClass}
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.onEmailUpdate}
            />
            <span className="icon is-small is-left">
              <Mail />
            </span>
            {this.state.isEmailInvalid && (
              <span className="icon is-small is-right">
                <WarningTriangle />
              </span>
            )}
          </Form.Control>
          {this.state.isEmailInvalid && <p className="help is-danger">{this.state.emailErrorMsg}</p>}
        </Form.Field>
        <Form.Field>
          <Form.Control>
            <input type="password" name="password" value="" className="display-none" readOnly />
          </Form.Control>
        </Form.Field>
        <CheckboxWithLabel
          checked={this.state.rememberMe}
          id="remember-me"
          label="Remember me on this device"
          onChange={this.onRememberMeUpdate}
        />
        <Form.Field className="mvm">
          <Form.Control>
            <Button type="submit" width="fullWidth">
              Next
            </Button>
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  passwordForm = () => {
    let passwordInputClass = 'input is-medium';
    if (this.state.isPasswordInvalid || this.state.isLoginInvalid) {
      passwordInputClass += ' is-danger';
    }
    const allowsPassword = this.state.redirectUrl && this.state.ssoName;
    const logInButtonCategory = allowsPassword ? 'secondary' : 'primary';
    return (
      <React.Fragment>
        <Form.Field>
          <Form.Control className="has-text-centered">
            <Form.Help>
              Logging in as {this.state.email}{' '}
              <a href="#dummy" onClick={this.changeEmail}>
                Not you?
              </a>
            </Form.Help>
            <input type="hidden" name="email" value={this.state.email} />
          </Form.Control>
        </Form.Field>
        {this.ssoButton()}
        <hr />
        <Form.Field className="mtl pts">
          <Form.Control className="has-icons-left has-icons-right">
            <Form.Input
              autoFocus
              className={passwordInputClass}
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.onPasswordUpdate}
            />
            <span className="icon is-small is-left">
              <Lock />
            </span>
            {this.state.isPasswordInvalid && (
              <span className="icon is-small is-right">
                <WarningTriangle />
              </span>
            )}
          </Form.Control>
          {(this.state.isPasswordInvalid || this.state.isLoginInvalid) && (
            <p className="help is-danger">{this.state.passwordErrorMsg}</p>
          )}
        </Form.Field>
        <Form.Field className="mvm">
          <Form.Control>
            <Button type="submit" category={logInButtonCategory} width="fullWidth">
              Log In
            </Button>
          </Form.Control>
        </Form.Field>
        <Link
          to={`/forgot_password?email=${encodeURIComponent(this.state.email)}`}
          className="has-text-grey-dark forgot-password"
        >
          Forgot password?
        </Link>
      </React.Fragment>
    );
  };

  ssoButton = () => {
    const displayButton = this.state.redirectUrl && this.state.ssoName;
    if (!displayButton) {
      return null;
    }

    return (
      <Form.Field className="mvm">
        <Form.Control>
          <a href={this.state.redirectUrl} className="button is-primary is-fullwidth is-medium is-large-button">
            {this.SSO_LOGO_MAP[this.state.ssoName] && (
              <span className="icon is-small mrs">
                <img src={this.SSO_LOGO_MAP[this.state.ssoName]} alt="sso" />
              </span>
            )}
            Log In With {this.state.ssoName}
          </a>
        </Form.Control>
      </Form.Field>
    );
  };

  onEmailUpdate = (e) => {
    const email = e.target.value;
    this.setState({ email: email, emailErrorMsg: '', isEmailInvalid: false, isLoginInvalid: false });
  };

  onPasswordUpdate = (e) => {
    this.setState({ password: e.target.value, passwordErrorMsg: '', isPasswordInvalid: false, isLoginInvalid: false });
  };
  onRememberMeUpdate = (e) => {
    this.setState({ rememberMe: e.target.checked });
  };

  onEmailSubmit = () => {
    if (this.validateEmail()) {
      Auth.preLogIn(this.state.email, this.onPreLoginSuccess, this.onLoginError);
    }
  };

  onPasswordSubmit = () => {
    if (this.validateForm()) {
      this.setState({ isEmailInvalid: false, isPasswordInvalid: false });
      Auth.logIn(this.state.email, this.state.password, this.state.rememberMe, this.onLoginSuccess, this.onLoginError);
    }
  };

  onLoginSubmit = (e) => {
    e.preventDefault();
    if (this.state.loginState === this.LOGIN_STATES.email) {
      this.onEmailSubmit();
    } else {
      this.onPasswordSubmit();
    }
  };

  onLoginSuccess = (user) => {
    this.setState({
      isEmailInvalid: false,
      isPasswordInvalid: false,
    });
    this.props.onLoginSuccess(user);
  };

  onPreLoginSuccess = (response) => {
    if (response.data.redirect_url) {
      let redirectUlrWithState = `${response.data.redirect_url}?RelayState=${encodeURIComponent(
        encodeURIComponent(this.props.relayState),
      )}:::${this.state.rememberMe}`;
      if (response.data.sso_name === this.SSO_NAME.Google) {
        redirectUlrWithState = `${response.data.redirect_url}&state={"remember_me": ${this.state.rememberMe}, "nonce": "${response.data.nonce}"}`;
      }
      if (response.data.allow_password) {
        this.setState({ redirectUrl: redirectUlrWithState, ssoName: response.data.sso_name });
      } else {
        return window.location.assign(redirectUlrWithState);
      }
    }
    this.setState({ loginState: this.LOGIN_STATES.password });
  };

  validateForm = () => {
    if (!this.state.email || this.state.email.indexOf('@') < 0 || this.state.email.indexOf('.') < 0) {
      this.setState({ isEmailInvalid: true, emailErrorMsg: 'Invalid email address' });
      return false;
    }

    if (!this.state.password) {
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: 'Password is required' });
      return false;
    }

    return true;
  };

  validateEmail = () => {
    if (!this.state.email || this.state.email.indexOf('@') < 0 || this.state.email.indexOf('.') < 0) {
      this.setState({ isEmailInvalid: true, emailErrorMsg: 'Invalid email address' });
      return false;
    }

    return true;
  };

  changeEmail = (e) => {
    e.preventDefault();
    this.setState({ loginState: this.LOGIN_STATES.email, email: '' });
  };

  onLoginError = (err) => {
    if (!err || err.status === 429) {
      this.setState({ isLoginInvalid: true, passwordErrorMsg: 'Could not process login request. Try again later' });
    } else if (err.data) {
      const errorType = err.data.error;
      if (errorType === 'AuthenticationError') {
        this.setState({ isLoginInvalid: true, passwordErrorMsg: err.data.message });
      } else if (errorType === 'MissingUserError') {
        this.setState({ isEmailInvalid: true, emailErrorMsg: err.data.message });
      } else if (errorType === 'InvalidUserError') {
        this.setState({
          isLoginInvalid: true,
          passwordErrorMsg: 'This user is inactive. Contact your admin for more details',
        });
      }
    }
  };
}

LoginForm.propTypes = {
  onLoginSuccess: PropTypes.func,
  loginSubtitle: PropTypes.string,
  relayState: PropTypes.string,
};

LoginForm.defaultProps = {
  relayState: '/',
};

export default LoginForm;
