import { CLEAR_NOTIFICATION, NOTIFY_ERROR, NOTIFY_SUCCESS } from './actionTypes';

export function notifications(state = { [NOTIFY_SUCCESS]: [], [NOTIFY_ERROR]: [] }, action) {
  switch (action.type) {
    case NOTIFY_SUCCESS: {
      const newSuccessNotifications = state[NOTIFY_SUCCESS].slice();
      newSuccessNotifications.push(action.notificationMessage);
      return Object.assign({}, state, { [NOTIFY_SUCCESS]: newSuccessNotifications });
    }
    case NOTIFY_ERROR: {
      const newSuccessNotifications = state[NOTIFY_ERROR].slice();
      newSuccessNotifications.push(action.notificationMessage);
      return Object.assign({}, state, { [NOTIFY_ERROR]: newSuccessNotifications });
    }
    case CLEAR_NOTIFICATION: {
      const newNotificationArray = state[action.notificationType].filter((msg) => msg !== action.notificationMessage);
      return Object.assign({}, state, { [action.notificationType]: newNotificationArray });
    }
    default:
      return state;
  }
}
