import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../shared/FormSelect';

class HomeInsightsPeriodDropdown extends Component {
  render() {
    const options = [
      { label: 'Last Week', value: 'week' },
      { label: 'Last Month', value: 'month' },
      { label: 'Last Quarter', value: 'quarter' },
    ];

    return (
      <Select
        classNamePrefix="home-select"
        onChange={this.onPeriodSelect}
        name="period"
        value={this.props.selectedPeriod}
        options={options}
        isMulti={false}
        isSearchable={false}
      />
    );
  }

  onPeriodSelect = (obj) => {
    this.props.updateQueryParams({ selectedPeriod: obj, period: obj.value });
  };
}

HomeInsightsPeriodDropdown.propTypes = {
  selectedPeriod: PropTypes.object,
  updateQueryParams: PropTypes.func,
};

export default HomeInsightsPeriodDropdown;
