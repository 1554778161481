import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';
import EmailRecipientDcCard from './EmailRecipientDcCard';
import EmailRecipientDropdownList from './EmailRecipientDropdownList';
import add_circle from '../../../images/add_circle_16x16.svg';
import dynamic_recipients_180x120 from '../../../images/dynamic_recipients_180x120.svg';
import { Hero, Heading, Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import utils from 'lib/utils';
import { openSidepane } from 'redux/ui/action';
import WithFilterListHeader from 'components/shared/WithFilterListHeader';
import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import { usePrevious } from 'ra-core/esm/util/hooks';
import Button from '../../lib/Button';

const DropdownWithFilterHeader = WithFilterListHeader(EmailRecipientDropdownList);

function EmailRecipientDropdown(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedContent, setSelectedContent] = useState();
  const [width, setWidth] = useState(0);
  const ref = useRef();
  const dispatch = useDispatch();

  useOnClickOutside(ref, () => setShowDropdown(false), [setShowDropdown]);

  const recipientDynamicContentList = Object.values(props.dynamicContent).filter(
    (content) => content.dynamic_content_type === 'recipients',
  );

  const prevRecipientDynamicContentList = usePrevious(recipientDynamicContentList);

  const onDynamicRecipientClick = (e) => {
    props.onChange('dynamicRecipients', e.currentTarget.getAttribute('id'));
    setShowDropdown(false);
  };

  useEffect(() => {
    getInitialSelectedContent();
  }, [props.dynamicRecipientsId]);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener('resize', getwidth);
    return () => window.removeEventListener('resize', getwidth);
  }, []);

  useEffect(() => {
    return () => {
      const navbar = document.getElementById('producer-navbar');
      navbar.style.zIndex = '101';
      const fullScreenModal = document.getElementById('full-screen-modal');
      if (fullScreenModal) {
        fullScreenModal.style.zIndex = '101';
      }
    };
  }, []);

  useEffect(() => {
    if (!props.ui.sidepane) {
      const navbar = document.getElementById('producer-navbar');
      navbar.style.zIndex = '99';
      const fullScreenModal = document.getElementById('full-screen-modal');
      fullScreenModal.style.zIndex = '99';
    }
  }, [props.ui.sidepane]);

  useEffect(() => {
    // if a dynamic recipient list is added or deleted in the sidepane, change the selected content accordingly
    let newSelectedContent;
    if (
      recipientDynamicContentList.length &&
      prevRecipientDynamicContentList &&
      recipientDynamicContentList.length !== prevRecipientDynamicContentList.length
    ) {
      newSelectedContent = recipientDynamicContentList.reduce((contentA, contentB) =>
        contentA.id > contentB.id ? contentA : contentB,
      );
      props.onChange('dynamicRecipients', newSelectedContent.id.toString());
    }
  }, [recipientDynamicContentList]);

  const getInitialSelectedContent = () => {
    let currentSelectedContent;
    if (recipientDynamicContentList.length) {
      if (props.dynamicRecipientsId) {
        currentSelectedContent = recipientDynamicContentList.filter(
          (content) => content.id === parseInt(props.dynamicRecipientsId),
        )[0];
      } else {
        currentSelectedContent = recipientDynamicContentList[0];
        props.setDynamicRecipientsId(currentSelectedContent.id.toString());
      }
      setSelectedContent(currentSelectedContent);
    }
  };

  const addSidepaneLayering = () => {
    const navbar = document.getElementById('producer-navbar');
    navbar.style.zIndex = '99';
    const fullScreenModal = document.getElementById('full-screen-modal');
    fullScreenModal.style.zIndex = '90';
  };

  const editOnClick = (contentTag) => {
    addSidepaneLayering();
    dispatch(openSidepane(contentTag, 'template-sidepane'));
  };

  const addOnClick = () => {
    addSidepaneLayering();
    dispatch(openSidepane(null, 'template-sidepane'));
  };

  const getTagFromDynamicContent = (content) => {
    if (content) {
      return utils.getTagFromSingleDynamicContent(content);
    }
  };

  const onRowClick = (e) => {
    if (e?.target?.name !== 'edit-icon') {
      const show = !showDropdown;
      setShowDropdown(show);
    }
  };

  return (
    <div className="email-recipients-dropdown" ref={ref}>
      <table>
        <tbody>
          {recipientDynamicContentList.length ? (
            <EmailRecipientDcCard
              key={selectedContent?.id + 'current'}
              content={selectedContent}
              contentTag={getTagFromDynamicContent(selectedContent)}
              onRowClick={onRowClick}
              primary={true}
              editOnClick={editOnClick}
            />
          ) : (
            <tr>
              <td>
                <Hero>
                  <Hero.Body className="has-text-centered">
                    <img width="180px" src={dynamic_recipients_180x120} alt="Set Up Dynamic Content" />
                    <Heading className="recipients-modal-empty-title" size={4}>
                      Add recipients to your email
                    </Heading>
                    <p className="recipients-modal-subtitle">Use dynamic content to create a list of recipients.</p>
                    <Form.Field className="pam">
                      <Form.Control className="centered-button">
                        <Button category="secondary" onClick={addOnClick}>
                          <img src={add_circle} className="mr-2" />
                          Create Recipient List
                        </Button>
                      </Form.Control>
                    </Form.Field>
                  </Hero.Body>
                </Hero>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showDropdown ? (
        <table className="email-recipient-dropdown-box">
          <tbody className="email-recipient-dropdown-body" style={{ width: `${width}px` }}>
            <DropdownWithFilterHeader
              entities={Object.values(props.dynamicContent)}
              entityType="dynamic_content"
              onDynamicRecipientClick={onDynamicRecipientClick}
              searchState={props.searchState}
              searchAttributes={props.searchAttributes}
              hideTopPagination={true}
              isSearching={props.isSearching}
              showFilterButton={false}
              filterPlaceholder="Find Dynamic Content"
              inTable={true}
            />
            <tr className="add-new-dc-row" onClick={addOnClick}>
              <img src={add_circle} />
              <span>Create New Recipient List</span>
            </tr>
          </tbody>
        </table>
      ) : null}
    </div>
  );
}

EmailRecipientDropdown.propTypes = {
  ccRecipients: PropTypes.array,
  dynamicRecipientsId: PropTypes.string,
  onChange: PropTypes.func,
  dynamicContent: PropTypes.object,
  searchState: PropTypes.object,
  searchAttributes: PropTypes.object,
  isSearching: PropTypes.bool,
  setResetSearchKey: PropTypes.func,
  onSearchStateChange: PropTypes.func,
  entityType: PropTypes.string,
  setDynamicRecipientsId: PropTypes.func,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps, mapUiDispatchToProps)(WithInstantSearch(EmailRecipientDropdown));
