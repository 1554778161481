import React from 'react';
import { Frame, Element, useEditor } from '@craftjs/core';
import PropTypes from 'prop-types';
import EmailBody from './html_builder_components/EmailBody';
import EmailContainer from './html_builder_components/EmailContainer';
import HtmlBuilderEmptyState from './html_builder_components/HtmlBuilderEmptyState';
import Icon from '../../lib/Icon';
import { Prompt } from 'react-router-dom';
import FixedFormButtons from '../FixedFormButtons';
import API from '../../../lib/api';

function HtmlBuilderDroppable({ currentTemplate, isLoading, jsonNodes, onEditorSaveClick }) {
  const { canUndo, canRedo, actions } = useEditor((state, query) => ({
    canRedo: query.history.canRedo(),
    canUndo: query.history.canUndo(),
  }));

  const onSaveClick = (e) => {
    e.preventDefault();
    onEditorSaveClick();
  };

  return (
    <div className="html-builder-container">
      <div className="html-builder-view">
        <div className="html-builder-history mam">
          <button className="button html-builder-undo-btn" onClick={() => actions.history.undo()} disabled={!canUndo}>
            <Icon name="arrow_undo" size={16} theme="regular" />
            Undo
          </button>
          <button className="button html-builder-redo-btn" onClick={() => actions.history.redo()} disabled={!canRedo}>
            <Icon name="arrow_redo" size={16} theme="regular" />
            Redo
          </button>
          <a
            className="button html-builder-save-btn"
            download={`${currentTemplate.name}.html`}
            href={`data:text/html,${encodeURIComponent(currentTemplate?.slides?.[0]?.slide_xml)}`}
            id="export"
            onClick={() => API.track('email_template_export_html_click')}
          >
            <Icon name="code" size={16} theme="regular" />
            <span className="ml-2">Export HTML</span>
          </a>
        </div>
        {jsonNodes ? (
          <Frame data={jsonNodes}></Frame>
        ) : (
          <Frame>
            <div className="main-builder-container">
              <Element is={EmailBody} canvas>
                <Element is={EmailContainer} canvas>
                  <HtmlBuilderEmptyState />
                </Element>
              </Element>
            </div>
          </Frame>
        )}
        <FixedFormButtons
          dynamicContentType={''}
          isChanged={canUndo}
          isLoading={isLoading}
          method={''}
          saveButtonText="Save"
          showCancel={false}
          submitForm={onSaveClick}
        />
      </div>
      <Prompt when={canUndo} message="Are you sure you want to navigate away? There are unsaved changes." />
    </div>
  );
}

HtmlBuilderDroppable.propTypes = {
  currentTemplate: PropTypes.object,
  isLoading: PropTypes.bool,
  jsonNodes: PropTypes.string,
  onEditorSaveClick: PropTypes.func,
};

export default HtmlBuilderDroppable;
