import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import ApiInputField from '../../../shared/ApiInputField';
import RestResults from './RestResults';
import InputMapping from '../InputMapping';
import { Select } from '../../../shared/FormSelect';
import QueryEditor from '../QueryEditor';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

function RestForm({ dataSource, entityType, input, inputMapping, onInputMappingUpdate, queryObj }) {
  const dynamicContentContext = useContext(DynamicContentContext);
  const updateApiMethod = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      updatedQueryObj.method = obj.value;
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };
  const updateApiQuery = (newValue) => {
    const updatedQueryObj = Object.assign({}, queryObj);
    updatedQueryObj.apiQuery = newValue;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const updateApiBody = (apiBody) => {
    const updatedQueryObj = Object.assign({}, queryObj);
    updatedQueryObj.apiBody = apiBody;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    return Promise.resolve();
  };
  let inputMappingOptions = [];
  if (queryObj.results) {
    queryObj.results.forEach((result) => {
      if (result.colName) {
        inputMappingOptions.push(result.colName);
      }
    });
  }

  const httpMethodOptions = [
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
  ];
  const httpMethodVal = queryObj.method ? { label: queryObj.method, value: queryObj.method } : httpMethodOptions[0];

  return (
    <div className="mbl rest-dynamic-content-form">
      <Form.Field>
        <Form.Label>API HTTP Method</Form.Label>
        <Form.Help>Select either GET or POST</Form.Help>
        <Form.Control>
          <Select
            classNamePrefix="matik-select"
            options={httpMethodOptions}
            value={httpMethodVal}
            onChange={updateApiMethod}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field className="mbl">
        <Form.Label>API URL</Form.Label>
        <Form.Help>Select URL to fetch</Form.Help>
        <Form.Control>
          <ApiInputField
            allowInputs={true}
            apiVal={queryObj.apiQuery ? queryObj.apiQuery : ''}
            baseUrl={dataSource.auth_info && dataSource.auth_info.base_url}
            includeBaseUrl={true}
            inputs={dynamicContentContext.existingInputs}
            isInputPopoverDisabled={entityType === 'input'}
            isReadOnly={dynamicContentContext.isReadOnly}
            method={httpMethodVal.value}
            onChangeWithInputs={updateApiQuery}
          />
        </Form.Control>
      </Form.Field>
      {queryObj.method === 'POST' && (
        <Form.Field>
          <Form.Label>Request Body</Form.Label>
          <Form.Help>Select URL to fetch</Form.Help>
          <Form.Control>
            <QueryEditor
              mode="json"
              queryString={queryObj.apiBody || ''}
              onQueryStringUpdate={updateApiBody}
              inputs={dynamicContentContext.existingInputs}
            />
          </Form.Control>
        </Form.Field>
      )}
      <Form.Field className="mbl">
        <Form.Label>Format Results</Form.Label>
        <Form.Help>
          Select fields to return from the API. Note: the source field uses{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://stedolan.github.io/jq/manual/#Basicfilters">
            the JQ filtering language.
          </a>
        </Form.Help>
        <Form.Control>
          <RestResults
            queryObj={queryObj}
            entityType={entityType}
            input={input}
            inputMapping={inputMapping}
            onInputMappingUpdate={onInputMappingUpdate}
          />
        </Form.Control>
      </Form.Field>
      {entityType && entityType === 'input' && (
        <InputMapping
          queryObj={queryObj}
          onInputMappingUpdate={onInputMappingUpdate}
          input={input}
          returnFieldsArray={inputMappingOptions}
          inputMapping={inputMapping}
        />
      )}
    </div>
  );
}

RestForm.propTypes = {
  dataSource: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  queryObj: PropTypes.object,
};

export default RestForm;
