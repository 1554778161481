import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { Redirect } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import API from '../../lib/api';

// eslint-disable-next-line no-undef
const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class StripeCheckout extends Component {
  constructor(props) {
    super(props);
    API.track('tier_payments_navigation');
  }

  render() {
    if (this.props.flags.enablePaymentsPage === false) {
      return <Redirect to={'/'} />;
    }

    return (
      <div>
        <Elements stripe={promise}>
          <CheckoutForm user={this.props.user} selectedTierId={this.props.selectedTierId} />
        </Elements>
      </div>
    );
  }
}

StripeCheckout.propTypes = {
  flags: PropTypes.object,
  selectedTierId: PropTypes.string,
  user: PropTypes.object,
};

export default withLDConsumer()(StripeCheckout);
