import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-bulma-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MConfirm } from 'components/shared/Alerts';
import { UserContext } from 'components/UserContext';
import IconPill from '../lib/IconPill';

function SelectContentType(props) {
  const { clearContentType, contentType, onClose, onContentTypeSelect } = props;

  const { openAiIntegration } = useFlags() || {};
  const userContext = useContext(UserContext);

  const onOutsideClick = (e) => {
    if (e.target.className.includes) {
      if (!e.target.className.includes('select-content') || !e.target.className.includes('content-type')) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);
    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, []);

  const onClick = (e, updatedContentType) => {
    if (updatedContentType !== contentType) {
      MConfirm(
        'Dynamic Content Type Change',
        'You are about to change the dynamic content type. \
                This will result in removing any queries, conditions, \
                or text you had associated with the dynamic content. \
                Are you sure you want to change the dynamic content type? \
                Note: Your changes will not be saved until you click Update.',
        'warning',
        (confirmed) => {
          if (confirmed) {
            clearContentType();
            onContentTypeSelect(updatedContentType);
          }
        },
      );
    }
  };

  let contentTypes = [
    {
      type: 'dynamic',
      label: 'Data Driven',
      iconName: 'data_driven',
    },
    {
      type: 'static',
      label: 'Static',
      iconName: 'static',
    },
    {
      type: 'conditional',
      label: 'Conditional',
      iconName: 'conditional',
    },
    {
      type: 'insights',
      label: 'Insights',
      iconName: 'wand',
    },
  ];

  // TODO (Tyler): Remove LaunchDarkly flag once enterprises that currently have access to AI via the LaunchDarkly
  // flag are granted access to AI via the soup_dumpling toggle.
  if (!userContext?.user?.enterprise?.enterprise_settings?.ai_enabled && !openAiIntegration) {
    contentTypes = contentTypes.filter((contentTypes) => contentTypes.type !== 'insights');
  }

  return (
    <Box className="select-content is-shadowless is-paddingless">
      {contentTypes.map((contentTypeOption) => (
        <div
          className="pl-1.5 py-1 pr-1 my-1.5 flex items-center hover:bg-blue-100"
          key={contentTypeOption.label}
          onClick={(e) => onClick(e, contentTypeOption.type)}
        >
          <IconPill iconName={contentTypeOption.iconName} color="white" size="m" theme="square" />
          <div className="ml-2">{contentTypeOption.label}</div>
        </div>
      ))}
    </Box>
  );
}

SelectContentType.propTypes = {
  clearContentType: PropTypes.func,
  contentType: PropTypes.string,
  onClose: PropTypes.func,
  onContentTypeSelect: PropTypes.func,
};

export default SelectContentType;
