import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/lib/Button';
import WithGooglePicker from '../shared/WithGooglePicker';
import googleDriveLogo from 'images/google_drive_logo.png';
import microsoft365DriveLogo from 'images/microsoft365_drive_logo.png';

const FolderPicker = ({ folder, onGoogleSlidesClick, folderProvider }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (folderProvider === 'googleDrive') {
      setLogo(googleDriveLogo);
    }

    if (folderProvider === 'office365') {
      setLogo(microsoft365DriveLogo);
    }
  }, [folderProvider]);

  return (
    <div className="flex justify-between w-full space-x-4 h-10">
      <div className="flex align-middle w-full">
        <div className="border border-grey-300 w-full flex items-center py-2 px-4 rounded">{folder?.name}</div>
      </div>
      <div className="flex w-auto min-w-fit">
        <Button size="medium" category="secondary" onClick={onGoogleSlidesClick}>
          {logo ? (
            <span className="icon">
              <img src={logo} alt="Choose folder" />
            </span>
          ) : null}
          <span className="ml-2">Select from Google Drive</span>
        </Button>
      </div>
    </div>
  );
};

FolderPicker.propTypes = {
  folder: PropTypes.object,
  onGoogleSlidesClick: PropTypes.func,
  folderProvider: PropTypes.string,
};

export default WithGooglePicker(FolderPicker, 'folders');
