import PropTypes from 'prop-types';
import React from 'react';
import loading_icon from '../../images/loading.gif';

const LoadingOverlay = (props) => {
  if (!props.loading) {
    return null;
  }

  return (
    <div className="loading-container">
      <div className="loading-helper"></div>
      <img src={loading_icon} width="85px" alt="loading" className="loading" />
    </div>
  );
};

LoadingOverlay.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingOverlay;
