import { useEffect, useReducer } from 'react';
import inputs from 'lib/inputs';
import Constants from 'components/Constants';
import utils from 'lib/utils';

// The state comprises of inputValues and inputs by input name. `{ inputs: {}, inputValues: {} }`
// The action is the updated inputs or input values by name in the same shape as the state. When inputs included in
// the action, they will replace the current state inputs entirely. But inputValues are considered as delta data,
// so only contain the data that has actually changed;
function reduce(state, action) {
  let updatedState = { ...state };
  if (action.inputs) {
    updatedState.inputs = reduceInputs(action.inputs);
  }
  if (action.inputValues) {
    updatedState.inputValues = reduceInputValues(state, action.inputValues);
  }
  return Object.freeze(updatedState);
}

// Simply replace the state inputs with the inputs in the action.
function reduceInputs(updatedInputs) {
  return Object.freeze({ ...updatedInputs });
}

// The action is the updated inputs or input values by name in the same shape as the state, but is considered as a delta,
// so only contains the data that has actually changed. e.g. a single input update will
// usually only include a single input value in the action. It may contain multiple changes in cases where
// changing one value has a side effect of changing other values, e.g. input queries dependent on other inputs.
// NOTE: For asyncronous updates (i.e. query result options are returned), the only place comparisons to the current state
// can happen is right here in the reducer. Otherwise you risk working with stale data.
function reduceInputValues(state, inputValues) {
  validateValueFromOptions(state, inputValues);
  resetDependentValues(state, inputValues);

  const updatedInputValues = { ...state.inputValues };

  Object.entries(inputValues).forEach(([inputName, inputValue]) => {
    // If inputValue is null or undefined, remove it
    if (inputValue === null || inputValue === undefined) {
      delete updatedInputValues[inputName];
    } else {
      // Otherwise, merge the updated value into any pre-existing value.
      const oldValue = state.inputValues[inputName];
      utils.deepFreeze(inputValue);
      updatedInputValues[inputName] = oldValue ? Object.freeze({ ...oldValue, ...inputValue }) : inputValue;
    }
  });

  return Object.freeze(updatedInputValues);
}

const findDependentInputs = (inputs, inputName) => {
  const dependents = new Set();
  const input = inputs[inputName];
  if (input?.parameters) {
    for (let childInputName in input.parameters) {
      dependents.add(childInputName);
    }
  }
  return dependents;
};

/** If the input value has changed, any other inputs dependent on that value will need to be
 *  reset and new queries triggered. (mike thinks this logic is better off handled in the input components)
 */
const resetDependentValues = (state, updatedInputValues) => {
  const currentInputValues = state.inputValues;
  const relevantInputs = state.inputs;

  const hasChanged = (inputName) => {
    const updatedValue = updatedInputValues[inputName]?.value;
    if (updatedValue === undefined) {
      return false;
    }
    const existingValue = currentInputValues[inputName]?.value;
    return updatedValue !== existingValue;
  };

  const dependents = new Set();
  Object.keys(updatedInputValues)
    .filter(hasChanged)
    .forEach((inputName) => {
      const input = relevantInputs[inputName];
      if (input?.type === Constants.InputTypes.DATE_RANGE && updatedInputValues[inputName].error) {
        // Date range changed, but with an invalid value. Don't clear out any dependents in this case.
        return;
      }
      // Note, we only need to trigger re-queries on inputs that directly depend on the changed values.
      // Any dependencies down the chain from that will get re-queried if/when the values change after the query
      // returns.
      findDependentInputs(relevantInputs, inputName).forEach((name) => dependents.add(name));
    });

  dependents.forEach((childName) => {
    // Note: options == null is the specific thing that triggers a re-query and retaining isLoading is key to ensuring we don't
    // have a race condition when a dependent input is changed before the child queries finish running
    const updatedInputValue = updatedInputValues[childName] || {};
    updatedInputValue.options = null;
    updatedInputValue.fetch_error = null;
    updatedInputValues[childName] = updatedInputValue;
  });
};

/** In case the input value options have changed, ensure the selected value(s) are still valid options. */
const validateValueFromOptions = (state, updatedInputValues) => {
  const currentInputValues = state.inputValues;
  const relevantInputs = state.inputs;

  const hasOptionsChanged = (inputName) => {
    const updatedOptions = updatedInputValues[inputName]?.options;
    if (!updatedOptions) {
      // when options are cleared for re-fetching, do nothing to the value.
      return false;
    }
    const existingOptions = currentInputValues[inputName]?.options;
    if (!existingOptions) {
      return true;
    }
    return updatedOptions.length !== existingOptions.length || updatedOptions.some((v) => !existingOptions.includes(v));
  };

  Object.keys(updatedInputValues)
    .filter(hasOptionsChanged)
    .forEach((inputName) => {
      const updatedInputValue = updatedInputValues[inputName];
      const options = updatedInputValue.options;
      if (options) {
        const value = updatedInputValue.value || currentInputValues[inputName]?.value;

        if (Array.isArray(value)) {
          // If some of the current selected values are not valid options, remove them.
          if (value.some((v) => !options.includes(v))) {
            updatedInputValue.value = value.filter((val) => options.includes(val));
          }
          // If the value is an empty array, leave it empty.
        } else {
          // Single value...
          if (options.length === 0) {
            // No options, so clear out the value.
            const input = relevantInputs[inputName];
            updatedInputValue.value = input ? inputs.initialValue(input) : null;
          } else if (value) {
            if (!options.includes(value)) {
              // The current selected value is not a valid option, so just default to the first option value.
              updatedInputValue.value = options[0];
            }
          } else {
            const input = relevantInputs[inputName];
            if (input?.type === Constants.InputTypes.STRING) {
              // No selected value but we have options so let's pick one for them
              if (options.includes(input.default_value)) {
                updatedInputValue.value = input.default_value;
              } else {
                updatedInputValue.value = options[0];
              }
            }
          }
        }
      }
    });
};

/** Initialize/remove input values based on the given inputs */
function synchronizeValuesForInputs(currentInputValues, currentInputs) {
  const updatedInputValues = {};

  // Remove inputValues for inputs no longer present
  Object.keys(currentInputValues).forEach((inputName) => {
    if (!currentInputs.find((input) => input.name === inputName)) {
      updatedInputValues[inputName] = null;
    }
  });

  // Add inputValues that aren't defined for the given inputs
  currentInputs.forEach((input) => {
    if (!currentInputValues[input.name]) {
      updatedInputValues[input.name] = inputs.initInputValue(input);
    }
  });

  return updatedInputValues;
}
/** Handle input and value state for forms with a set of inputs.
 * Note: the state is component-scoped.
 * @param {*} useRelevantInputs hook-like function which takes `inputValues` as an argument and returns
 * `{ isLoading, inputs }`
 * @returns
 * ```
 * // values by input name
 * inputValues,
 * // called to update input value state. Payload is { [inputName]: inputValue } and should only contain
 * // the value(s) changed.
 * updateInputValues,
 * areInputsLoading,
 * // Array of inputs relevant to the context after any adjustments based on input values. e.g. conditional content
 * inputs
 * ```
 */
export default function useInputsAndValues(useRelevantInputs) {
  const [state, updateState] = useReducer(reduce, { inputs: {}, inputValues: {} });
  const inputValues = state.inputValues;
  const updateInputValues = (updated) => {
    updateState({ inputValues: updated });
  };

  const { isLoading: areInputsLoading, inputs: relevantInputs } = useRelevantInputs(inputValues);

  // We need the input metadata in the reducer function, so we have to include it in the state.
  useEffect(() => {
    if (!areInputsLoading && relevantInputs) {
      updateState({
        inputs: relevantInputs.reduce((byName, input) => {
          byName[input.name] = input;
          return byName;
        }, {}),
      });
    }
  }, [areInputsLoading, JSON.stringify(relevantInputs)]);

  // Available inputs might change when values change. i.e. conditional content might yield different content
  // which might reference different inputs. When they do change, we want to initialize any default values on
  // new content and cleanup any values no longer associated with the current input set.
  // Also, input values might be removed and we may need to re-initialize them if the inputs remain relevant.
  useEffect(() => {
    if (!areInputsLoading && relevantInputs) {
      const changes = synchronizeValuesForInputs(inputValues, relevantInputs);
      if (Object.keys(changes).length > 0) {
        updateInputValues(changes);
      }
    }
  }, [areInputsLoading, JSON.stringify(relevantInputs), JSON.stringify(Object.keys(inputValues))]);

  return {
    inputValues,
    updateInputValues,
    areInputsLoading,
    inputs: relevantInputs,
  };
}
