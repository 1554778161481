import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import ItemTagMultiSelect from '../ItemTagMultiSelect';
import WYSIWYGEditor from '../WYSIWYGEditor';
import NameTemplateModal from 'components/shared/NameTemplateModal';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import Constants from 'components/Constants';
function TemplateNameForm(props) {
  const openModal = () => {
    props.openModal('nameTemplateModal', props.template);
  };

  return (
    <React.Fragment>
      {props.template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && (
        <>
          <Form.Field>
            <Form.Control>
              <button
                className="button is-tertiary"
                aria-label="Set name for generated presentations"
                onClick={openModal}
              >
                Set name for generated presentations
                <ChevronRight />
              </button>
            </Form.Control>
          </Form.Field>
          <NameTemplateModal
            show={props.ui.modal?.name === 'nameTemplateModal'}
            onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
            orderedInputs={props.orderedInputs}
            template={props.template}
            onClose={props.closeModal}
          />
        </>
      )}
      <ItemTagMultiSelect itemTags={props.itemTags} updateItemTags={props.onItemTagsChange} disabled={false} />
      <Form.Field>
        <Form.Control>
          <WYSIWYGEditor
            name="description"
            className="long-input"
            placeholder="Enter template description"
            id="template-desc"
            ariaLabel="Template description"
            updateEditor={props.onDescriptionChange}
            value={props.description}
          />
        </Form.Control>
      </Form.Field>
    </React.Fragment>
  );
}

TemplateNameForm.propTypes = {
  itemTags: PropTypes.array,
  description: PropTypes.string,
  onItemTagsChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  orderedInputs: PropTypes.array,
  template: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(TemplateNameForm);
