import React from 'react';
import PropTypes from 'prop-types';
import InputForm from './InputForm';
import Sidepane from 'components/shared/sidepane/Sidepane';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import { closeSidepane, openUnsavedChangesModal } from 'redux/ui/action';
import { useDispatch, useSelector } from 'react-redux';

function InputFormSidepane({ dataSources, onInputUpdated, onInputAdded }) {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const handleCloseSidepane = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (ui.isChanged) {
      dispatch(
        openUnsavedChangesModal({
          title: 'Close sidepane?',
          onConfirm: 'closeSidepane',
        }),
      );
    } else {
      dispatch(closeSidepane());
    }
  };

  if (ui.sidepane?.content?.entityType !== Constants.UI_TYPES.input) {
    return null;
  }

  const handleInputUpdated = (updatedInput) => {
    onInputUpdated(updatedInput);
    dispatch(closeSidepane());
    utils.notify('Input updated');
  };

  const handleInputAdded = (updatedInput) => {
    onInputAdded(updatedInput);
    dispatch(closeSidepane());
    utils.notify('Input updated');
  };

  return (
    <Sidepane sidepaneType="input-form" name="input-form">
      <InputForm
        // InputForm doesn't respond to input/entityId prop changes, so a brute-force
        // remount via key change will take care of that.
        key={ui.sidepane.content.id}
        dataSources={dataSources}
        entityId={`${ui.sidepane.content.id}`}
        entityType={Constants.UI_TYPES.input}
        input={ui.sidepane.content}
        isChanged={ui.isChanged}
        onClose={handleCloseSidepane}
        onCloseDataTip="Close"
        onInputUpdate={handleInputUpdated}
        onInputAdd={handleInputAdded}
        saveButtonText="Update"
        showCancel={true}
        showClickableCloseIcon={true}
      />
    </Sidepane>
  );
}

InputFormSidepane.propTypes = {
  dataSources: PropTypes.array,
  onInputUpdated: PropTypes.func.isRequired,
  onInputAdded: PropTypes.func.isRequired,
};

export default InputFormSidepane;
