import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Hero, Container } from 'react-bulma-components';
import { withRouter } from 'react-router-dom';
import SampleContentLink from './SampleContentLink';
import onboarding_main_icon from '../../../images/onboarding_main.svg';
import API from '../../../lib/api';

class OnboardingWelcome extends Component {
  constructor(props) {
    super(props);
    API.track('onboarding_welcome_navigation');
  }

  render() {
    return (
      <div className="tab-content">
        <div className="wrapper">
          <div className="main">
            <Container className="is-fluid">
              <Hero className="is-fullheight-with-navbar">
                <Hero.Body>
                  <Container className="has-text-centered">
                    <Heading>
                      <img width="1046px" src={onboarding_main_icon} alt="Welcome to Matik" />
                    </Heading>
                    <Heading size={4}>
                      Welcome to Matik!{' '}
                      <span role="img" aria-label="Wave">
                        👋
                      </span>
                    </Heading>
                    <Heading size={5} subtitle className="has-text-grey-dark welcome-onboarding-text-box mtl">
                      We are excited to have you here, let&apos;s start getting you up to speed. First, let&apos;s set
                      up your first template!
                    </Heading>
                    <div>
                      <SampleContentLink />
                    </div>
                    <div className="mtl">
                      <a className="skip-onboarding-link" onClick={this.onSkipOnboardingClick} href="#skip">
                        Skip for now
                      </a>
                    </div>
                  </Container>
                </Hero.Body>
              </Hero>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  onSkipOnboardingClick = () => {
    API.track('skip_onboarding_click');
    this.props.history.push('/');
  };
}

OnboardingWelcome.propTypes = {
  history: PropTypes.object,
};

export default withRouter(OnboardingWelcome);
