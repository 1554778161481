import FixedFormButtons from 'components/shared/FixedFormButtons';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

function FormSkeletonLoader() {
  return (
    <div className="form-skeleton-loader">
      <div className="skeleton-loader-header">
        <Skeleton height="3rem" />
      </div>
      <div className="skeleton-loader-sub-header">
        <Skeleton height="3rem" />
      </div>
      <div className="skeleton-loader-body">
        <Skeleton height="100%" />
      </div>
      <div className="skeleton-loader-footer">
        <Skeleton height="5rem" />
      </div>
      <FixedFormButtons saveButtonText="Dummy" isLoading={true} />
    </div>
  );
}

export default FormSkeletonLoader;
