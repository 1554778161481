import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

import API from 'lib/api';
import teams from 'lib/teams';

import { MProductUpsell } from 'components/shared/Alerts';
import Constants from 'components/Constants';
import UpsellDetailedModal from 'components/payments/UpsellDetailedModal';
import upsellSlideLoopingImage from 'images/upsell_slide_looping.png';
import { UserContext } from 'components/UserContext';

const showUpsell = (user) => {
  const planId = user.enterprise.plan_id;
  if (teams.isTeamsUser(planId)) {
    const planName = Constants.MATIK_TIERS[planId].display_name;
    const noSlideLoopingHtml = ReactDOMServer.renderToStaticMarkup(
      <UpsellDetailedModal
        feature="slide_loops"
        featureImage={upsellSlideLoopingImage}
        featureImageAlt="Slide Looping"
        featureHeader={`Slide Looping with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
        featureDescription={`Slide Looping is not available for the ${planName} plan. Please upgrade to loop slides.`}
      />,
    );
    API.track('enterprise_upsell_shown', { from: 'no_slide_looping' });
    MProductUpsell(`${noSlideLoopingHtml}`, true, (result) => {
      if (!result.dismiss) {
        API.track('discover_matik_enterprise_click', { from: 'no_slide_looping' });
        const demoUrl = teams.buildRequestDemoUrl(user, 'slide_looping', user.enterprise.trial_days_remaining);
        window.open(demoUrl, '_blank');
      }
    });

    return true;
  } else {
    return false;
  }
};

/** Intercept child 'interceptPropName' events and check to see if they are
 * allowed to create loops. If not, pitch them an upsell message. If so,
 * send that 'interceptPropName' event on.
 */
const LoopingUpsell = ({ children, interceptPropName }) => {
  const userContext = useContext(UserContext);
  return (
    <>
      {React.Children.map(children, (child) => {
        const childHandler = child.props[interceptPropName];
        const interceptHandler = (...args) => {
          if (!showUpsell(userContext.user)) {
            childHandler(...args);
          }
        };
        return React.cloneElement(child, { [interceptPropName]: interceptHandler });
      })}
    </>
  );
};
LoopingUpsell.propTypes = {
  children: PropTypes.any,
  interceptPropName: PropTypes.string,
};

export default LoopingUpsell;
