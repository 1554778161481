import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Auth } from '../../lib/auth';
import API from '../../lib/api';
import utils from '../../lib/utils';
import Button from '../lib/Button';

function SignUpForm({ setCompanyName }) {
  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameErrorMsg, setNameErrorMsg] = useState('');
  const [company, setCompany] = useState('');
  const [isCompanyValid, setIsCompanyValid] = useState(true);
  const [companyErrorMsg, setCompanyErrorMsg] = useState('');
  const [companyUrl, setCompanyUrl] = useState('https://');
  const [isCompanyUrlValid, setIsCompanyUrlValid] = useState(true);
  const [companyUrlErrorMsg, setCompanyUrlErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isTermsValid, setIsTermsValid] = useState(true);
  const [termsErrorMsg, setTermsErrorMsg] = useState('');
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);
  const [isPrivacyValid, setIsPrivacyValid] = useState(true);
  const [privacyErrorMsg, setPrivacyErrorMsg] = useState('');
  const [isLoginValid, setIsLoginValid] = useState(true);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [next, setNext] = useState(false);

  const { enableSignUpPage, preventPlusSignEmails } = useFlags();
  const history = useHistory();

  useEffect(() => {
    API.track('signup_page_navigation_info_gathering', {}, -1);

    const urlParams = new URLSearchParams(window.location.search);
    const nameParam = urlParams.get('name');
    setName(nameParam);
    const companyUrlParam = urlParams.get('company_url');
    setCompanyUrl(companyUrlParam);
    const companyNameParam = urlParams.get('company_name');
    setCompany(companyNameParam);
    setCompanyName(companyNameParam);
    if (
      (nameParam || companyNameParam || companyUrlParam) &&
      validatePreForm(nameParam, companyNameParam, companyUrlParam)
    ) {
      setNext(true);
    }
  }, []);

  if (enableSignUpPage === false) {
    return <Redirect to={'/'} />;
  }

  let nameInputClass = 'input signup-input';
  if (!isNameValid) {
    nameInputClass += ' is-danger';
  }

  let emailInputClass = 'input signup-input';
  if (!isEmailValid) {
    emailInputClass += ' is-danger';
  }

  let passwordInputClass = 'input signup-input';
  if (!isPasswordValid) {
    passwordInputClass += ' is-danger';
  }

  let companyInputClass = 'input signup-input';
  if (!isCompanyValid) {
    companyInputClass += ' is-danger';
  }

  let companyUrlInputClass = 'input signup-input';
  if (!isCompanyUrlValid) {
    companyUrlInputClass += ' is-danger';
  }

  const onNameUpdate = (e) => {
    setName(e.target.value);
    setNameErrorMsg('');
    setIsNameValid(true);
  };

  const onEmailUpdate = (e) => {
    setEmail(e.target.value);
    setEmailErrorMsg('');
    setIsEmailValid(true);
  };

  const onPasswordUpdate = (e) => {
    setPassword(e.target.value);
    setPasswordErrorMsg('');
    setIsPasswordValid(true);
  };

  const onCompanyUpdate = (e) => {
    setCompany(e.target.value);
    setCompanyErrorMsg('');
    setIsCompanyValid(true);
    setCompanyName(e.target.value);
  };

  const onCompanyUrlUpdate = (e) => {
    setCompanyUrl(e.target.value);
    setCompanyUrlErrorMsg('');
    setIsCompanyUrlValid(true);
  };

  const onTermsCheck = (e) => {
    setAgreeToTerms(e.target.checked);
    setTermsErrorMsg('');
    setIsTermsValid(true);
  };

  const onPrivacyCheck = (e) => {
    setAgreeToPrivacy(e.target.checked);
    setPrivacyErrorMsg('');
    setIsPrivacyValid(true);
  };

  const validatePreForm = (nameValue, companyValue, companyUrlValue) => {
    if (!nameValue) {
      setIsNameValid(false);
      setNameErrorMsg('Name is required');
    }

    if (!companyValue) {
      setIsCompanyValid(false);
      setCompanyErrorMsg('Company name is required');
    }

    if (!companyUrlValue) {
      setIsCompanyUrlValid(false);
      setCompanyUrlErrorMsg('Company website is required');
    }

    if (!utils.isURLValid(companyUrlValue)) {
      setIsCompanyUrlValid(false);
      setCompanyUrlErrorMsg('Please provide a valid company url starting with http:// or https://');
    }

    return nameValue && companyValue && companyUrlValue && utils.isURLValid(companyUrlValue);
  };

  const validateForm = () => {
    const emailIsValid = checkValidEmail();

    if (!name) {
      setIsNameValid(false);
      setNameErrorMsg('Name is required');
    }

    if (!password) {
      setIsPasswordValid(false);
      setPasswordErrorMsg('Password is required');
    }

    if (!agreeToTerms) {
      setIsTermsValid(false);
      setTermsErrorMsg('You must agree in order to sign up');
    }

    if (!agreeToPrivacy) {
      setIsPrivacyValid(false);
      setPrivacyErrorMsg('You must agree in order to sign up');
    }

    return name && emailIsValid && password && agreeToTerms && agreeToPrivacy;
  };

  const checkValidEmail = () => {
    const emailIsValid = utils.isEmailValid(email);

    if (!emailIsValid) {
      setIsEmailValid(false);
      setEmailErrorMsg('Invalid email address');
    }

    let emailContainsPlus = false;
    if (preventPlusSignEmails) {
      emailContainsPlus = email.indexOf('+') > 0;
    }

    if (emailContainsPlus) {
      setIsEmailValid(false);
      setEmailErrorMsg('Email address cannot contain "+"');
    }

    return emailIsValid && !emailContainsPlus;
  };

  const onNextClick = (e) => {
    e.preventDefault();
    setIsNameValid(true);
    setIsCompanyValid(true);
    setIsCompanyUrlValid(true);

    if (validatePreForm(name, company, companyUrl)) {
      setNext(true);
      API.track('signup_page_navigation_signup_or_signin', {}, -1);
    }
  };

  const onSignUpSubmit = (e) => {
    e.preventDefault();

    if (!next) {
      return false;
    }

    setIsNameValid(true);
    setIsEmailValid(true);
    setIsPasswordValid(true);
    setIsLoginValid(true);

    if (validateForm()) {
      Auth.createUser(name, email, password, onCreateAccountSuccess, onCreateAccountError);
    }
  };

  const onCreateAccountSuccess = () => {
    API.setUserProfile(true).then((user) => {
      API.track(
        'signup_page_create_user_success',
        { companyName: company, companyUrl, templateType: 'eoy_email' },
        user.id,
      );
      return history.push({
        pathname: '/templates',
        search: `?start_flow=eoy_email&company_name=${company}&company_url=${encodeURIComponent(companyUrl)}`,
      });
    });
  };

  const onCreateAccountError = (err) => {
    if (err && err.data) {
      const errorType = err.data.error;
      if (errorType === 'MalformedUserDataError') {
        setIsEmailValid(false);
        setEmailErrorMsg(err.data.message);
      } else {
        setIsLoginValid(false);
        setLoginErrorMsg(err.data.message);
      }
    }
  };

  return (
    <>
      <form onSubmit={onSignUpSubmit} className="signup-form-trial" noValidate>
        {!next ? (
          <>
            <p className="signup-trial-header mbs">Tell us about you and your company</p>
            <p className="is-secondary-description mbl">
              Matik will generate an End of Year Email with recommended metrics and branding that&apos;s personalized to
              your company.
            </p>
            <Form.Field>
              <Form.Label>Your Name</Form.Label>
              <Form.Help>We’ll use this to personalize the email template’s From: field.</Form.Help>
              <Form.Control>
                <Form.Input className={nameInputClass} type="text" onChange={onNameUpdate} value={name} />
              </Form.Control>
              {!isNameValid && <p className="help is-danger">{nameErrorMsg}</p>}
            </Form.Field>

            {/*COMPANY URL*/}
            <Form.Field className="mtm">
              <Form.Label>Your Company Website</Form.Label>
              <Form.Help>
                We’ll use your company’s marketing website to analyze your business and recommend good metrics.
              </Form.Help>
              <Form.Control>
                <Form.Input
                  className={companyUrlInputClass}
                  type="text"
                  placeholder="Enter Your Company Website"
                  value={companyUrl}
                  onChange={onCompanyUrlUpdate}
                />
              </Form.Control>
              {!isCompanyUrlValid && <p className="help is-danger">{companyUrlErrorMsg}</p>}
            </Form.Field>

            {/*COMPANY*/}
            <Form.Field className="mtm">
              <Form.Label>Your Company Name</Form.Label>
              <Form.Help>We’ll use this to personalize the email template content to your business.</Form.Help>
              <Form.Control>
                <Form.Input className={companyInputClass} type="text" value={company} onChange={onCompanyUpdate} />
              </Form.Control>
              {!isCompanyValid && <p className="help is-danger">{companyErrorMsg}</p>}
            </Form.Field>

            {/*SUBMIT*/}
            <Form.Field className="mvm ptl">
              <Form.Control>
                <Button width="fullWidth" onClick={onNextClick}>
                  Next step
                </Button>
              </Form.Control>
            </Form.Field>
          </>
        ) : (
          <>
            <p className="signup-trial-header mbs">Add email to your Matik template library</p>
            <p className="is-secondary-description mbl">
              Create a free account to access and edit the email, including exporting the email’s HTML.
            </p>
            {/*NAME*/}
            <Form.Field>
              <Form.Control>
                <Form.Input
                  className={nameInputClass}
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={onNameUpdate}
                />
              </Form.Control>
              {!isNameValid && <p className="help is-danger">{nameErrorMsg}</p>}
            </Form.Field>

            {/*EMAIL*/}
            <Form.Field className="mtm">
              <Form.Control>
                <Form.Input
                  className={emailInputClass}
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={onEmailUpdate}
                />
              </Form.Control>
              {!isEmailValid && <p className="help is-danger">{emailErrorMsg}</p>}
            </Form.Field>

            {/*PASSWORD*/}
            <Form.Field className="mtm">
              <Form.Control>
                <Form.Input
                  className={passwordInputClass}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={onPasswordUpdate}
                />
              </Form.Control>
              {!isPasswordValid && <p className="help is-danger">{passwordErrorMsg}</p>}
            </Form.Field>

            {/*AGREE TO TERMS OF SERVICE*/}
            <Form.Field className="mtm">
              <Form.Control>
                <input
                  id="agree_to_terms"
                  type="checkbox"
                  name="agree_to_terms"
                  onChange={onTermsCheck}
                  checked={agreeToTerms}
                />
                <Form.Label htmlFor="agree_to_terms">
                  <span className="mls agree-to-terms">
                    Creating an account means you agree to our&nbsp;
                    <a
                      className="agree-to-terms underline"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://matik.io/terms/"
                    >
                      Terms of Service
                    </a>
                  </span>
                </Form.Label>
              </Form.Control>
              {!isTermsValid && <p className="help is-danger">{termsErrorMsg}</p>}
            </Form.Field>

            {/*AGREE TO PRIVACY POLICY*/}
            <Form.Field className="mtm">
              <Form.Control>
                <input
                  id="agree_to_privacy"
                  type="checkbox"
                  name="agree_to_privacy"
                  onChange={onPrivacyCheck}
                  checked={agreeToPrivacy}
                />
                <Form.Label htmlFor="agree_to_privacy">
                  <span className="mls agree-to-terms">
                    Agree to our&nbsp;
                    <a
                      className="agree-to-terms underline"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://matik.io/privacy/"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </Form.Label>
              </Form.Control>
              {!isPrivacyValid && <p className="help is-danger">{privacyErrorMsg}</p>}
            </Form.Field>

            {!isLoginValid && <p className="help is-danger">{loginErrorMsg}</p>}

            {/*SUBMIT*/}
            <Form.Field className="mvm ptl">
              <Form.Control>
                <Button type="submit" width="fullWidth">
                  Sign Up
                </Button>
              </Form.Control>
            </Form.Field>
            <div className="have-account">
              Already have an account?{' '}
              <Link
                to={`/login?flow=eoy_email&company_name=${company}&company_url=${encodeURIComponent(companyUrl)}`}
                className="signup-login"
              >
                Log in
              </Link>
            </div>
          </>
        )}
      </form>
    </>
  );
}

SignUpForm.propTypes = {
  setCompanyName: PropTypes.func,
};

export default SignUpForm;
