import React from 'react';
import { useEditor } from '@craftjs/core';
import ClickableCloseIcon from 'components/shared/nav/ClickableCloseIcon';

function HtmlBuilderSettings() {
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;
    let columnAncestor;

    const findColumnAncestor = (nodeId) => {
      const ancestors = query.node(nodeId).ancestors();
      for (let i = 0; i < ancestors.length; i++) {
        if (state.nodes[ancestors[i]].data.name === 'ColumnContainer') {
          const containerAncestors = query.node(ancestors[i]).ancestors();
          for (let j = 0; j < containerAncestors.length; j++) {
            if (state.nodes[containerAncestors[j]].data.name === 'ColumnModule') {
              return containerAncestors[j];
            }
          }
        } else if (state.nodes[ancestors[i]].data.name === 'ColumnModule') {
          return ancestors[i];
        }
      }
    };

    if (currentNodeId) {
      columnAncestor =
        state.nodes[currentNodeId].data.name === 'ColumnContainer' || state.nodes[currentNodeId].data.name === 'Column'
          ? findColumnAncestor(currentNodeId)
          : null;
    }

    if (currentNodeId && !columnAncestor) {
      selected = {
        id: currentNodeId,
        isDeletable: query.node(currentNodeId).isDeletable(),
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId]?.related?.settings,
      };
    } else if (columnAncestor) {
      selected = {
        id: columnAncestor,
        isDeletable: query.node(columnAncestor).isDeletable(),
        name: state.nodes[columnAncestor].data.name,
        settings: state.nodes[columnAncestor]?.related?.settings,
      };
    }

    return {
      selected,
    };
  });

  const nameMapping = {
    ColumnModule: 'Column',
    DraggableButton: 'Button',
    DraggableText: 'Text',
  };

  const moduleName = nameMapping[selected.name] || selected.name;

  return selected ? (
    <>
      <div className="module-settings-top-bar">
        <div>{moduleName} Module Customization</div>
        <ClickableCloseIcon
          onClick={() => actions.selectNode(null)}
          data-tooltip-content="Deselect Module"
          data-tooltip-id="matik-tooltip"
        />
      </div>
      <div className="module-divider" />
      <p className="modules-header"></p>
      <div className="module-settings-container">
        <div>{selected.settings && React.createElement(selected.settings)}</div>
        {selected.isDeletable ? (
          <button
            className="button is-secondary"
            onClick={() => {
              actions.delete(selected.id);
            }}
          >
            Delete {moduleName} Module
          </button>
        ) : null}
      </div>
    </>
  ) : null;
}

export default HtmlBuilderSettings;
