import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Constants from '../../../Constants';
import SSHTunnelForm from './SSHTunnelForm';
import CheckboxWithLabel from '../../../shared/CheckboxWithLabel';

class GenericDatabaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSSHTunnel: !!(this.props.auth && this.props.auth['ssh_key']),
    };
  }

  render() {
    const hostColor = this.props.hostError ? 'danger' : null;
    const skipSslCheck = this.props.skipSslCheck ? this.props.skipSslCheck : false;
    return (
      <React.Fragment>
        <Form.Field>
          <Form.Label htmlFor="db-host">Host URL</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder={`URL of the host on which ${this.props.typeToUpperCase(this.props.type)} is running`}
              type="text"
              id="db-host"
              name="host"
              color={hostColor}
              onChange={this.props.onChange}
              value={this.props.host}
            />
            {this.props.hostError && <Form.Help color="danger">{this.props.hostError}</Form.Help>}
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="db-port">Host Port</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder={Constants.DATA_SOURCE_DEFAULT_PORTS[this.props.type]}
              type="text"
              name="port"
              onChange={this.props.onChange}
              value={this.props.port}
              autoComplete="off"
              id="db-port"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="db-database">Database</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Database to connect to"
              type="text"
              name="database"
              onChange={this.props.onChange}
              value={this.props.database}
              id="db-database"
            />
          </Form.Control>
        </Form.Field>
        <SSHTunnelForm
          onAuthChange={this.props.onAuthChange}
          onChange={this.onSSHTunnelChange}
          showSSHTunnel={this.state.showSSHTunnel}
          auth={this.props.auth}
          selectedDataSource={this.props.selectedDataSource}
          clearSSHKey={this.props.clearSSHKey}
        />
        <Form.Field>
          <CheckboxWithLabel
            checked={skipSslCheck}
            id="skip_ssl_check"
            label="Skip SSL Certificate Check"
            name="skipSslCheck"
            onChange={this.props.onChange}
          />
        </Form.Field>
      </React.Fragment>
    );
  }
  onSSHTunnelChange = (e) => {
    const value = e.target.checked;
    this.setState({
      [e.target.name]: value,
    });
    this.props.onSSHTunnelChange();
  };
}

GenericDatabaseForm.propTypes = {
  type: PropTypes.string,
  host: PropTypes.string,
  hostError: PropTypes.string,
  port: PropTypes.string,
  skipSslCheck: PropTypes.bool,
  database: PropTypes.string,
  testResult: PropTypes.object,
  testDataSource: PropTypes.func,
  testIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  typeToUpperCase: PropTypes.func,
  onChange: PropTypes.func,
  selectedDataSource: PropTypes.object,
  deleteDataSource: PropTypes.func,
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  onSSHTunnelChange: PropTypes.func,
  clearSSHKey: PropTypes.func,
};

export default GenericDatabaseForm;
