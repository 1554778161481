import React from 'react';
import AccessAvatarsWithShareButton from '../shared/AccessAvatarsWithShareButton';
import EditableTemplateName from '../shared/templates/EditableTemplateName';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { useTemplateFavorites } from 'lib/hooks/useTemplate';
import PropTypes from 'prop-types';
import Icon from '../lib/Icon';

function DocumentTemplateHeader(props) {
  const { data: favorites, update: updateFavorite } = useTemplateFavorites();
  const isFavorite = favorites?.indexOf(props.template.id) > -1;

  return (
    <Level className="end-user-template-nav">
      <Level.Side align="left">
        <Level.Item>
          <Link to="/create/templates" className="button is-secondary is-round-button" id="end-user-template-back">
            <Icon name="chevron_left" size={16} theme="regular" />
          </Link>
        </Level.Item>
        <Level.Item>
          <EditableTemplateName
            template={props.template}
            orderedInputs={props.orderedInputs}
            onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
            canEdit={props.canEdit}
          />
        </Level.Item>
      </Level.Side>
      <Level.Side align="right">
        <Level.Item>
          <AccessAvatarsWithShareButton
            template={props.template}
            accessesByTemplateId={props.accessesByTemplateId}
            showShareButton={true}
          />
        </Level.Item>
        <Level.Item>
          <button
            className="button is-secondary"
            disabled={!favorites}
            onClick={() => updateFavorite(props.template, !isFavorite)}
          >
            {isFavorite ? (
              <span>
                <Icon name="star" size={16} theme="filled" />
                <span className="ml-2">Remove from Favorites</span>
              </span>
            ) : (
              <span>
                <Icon name="star" size={16} theme="regular" />
                <span className="ml-2">Add to Favorites</span>
              </span>
            )}
          </button>
        </Level.Item>
      </Level.Side>
    </Level>
  );
}

DocumentTemplateHeader.propTypes = {
  template: PropTypes.object,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  canEdit: PropTypes.bool,
  accessesByTemplateId: PropTypes.object,
};

export default DocumentTemplateHeader;
