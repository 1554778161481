import React from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import Tag from '../../lib/Tag';

function FilterContainer({ toggleFilters, filterCount, showFilters }) {
  let tagColor = 'white';
  if (filterCount > 0) {
    tagColor = 'matik-black';
  } else if (showFilters) {
    tagColor = 'white';
  }

  return (
    <div className="inline-flex justify-center items-center pointer-events-auto absolute right-0 top-0 z-10 mt-1 mr-2">
      <Tag
        id="connected-search-box-filter"
        color={tagColor}
        onClick={toggleFilters}
        label={filterCount ? Pluralize('Filter', filterCount, true) : 'Filter'}
        iconName="filter"
      />
    </div>
  );
}

FilterContainer.propTypes = {
  filterCount: PropTypes.number,
  showFilters: PropTypes.bool,
  toggleFilters: PropTypes.func,
};

export default FilterContainer;
