import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { UserContext } from '../UserContext';
import API from '../../lib/api';

/** This HOC is deprecated, use GoogleDrivePicker instead. */
const WithGooglePicker = (WrappedComponent, specifiedView) => {
  return class Inner extends Component {
    static contextType = UserContext;
    static propTypes = {
      auth: PropTypes.object,
      handleSelectGoogleSheet: PropTypes.func,
      oauthFunction: PropTypes.func,
      title: PropTypes.string,
      view: PropTypes.string,
      initState: PropTypes.func,
    };

    constructor(props) {
      super(props);

      this.state = {
        isPickerLoading: false,
        openAfterSignin: false,
        picker: null,
      };
    }

    componentDidMount() {
      const showPicker = !!this.props.auth && this.props.auth.access_token;
      this.initPicker(showPicker);
    }

    componentDidUpdate(prevProps) {
      const showPicker =
        !!this.props.auth && this.props.auth.access_token && (!prevProps.auth || !prevProps.auth.access_token);
      this.initPicker(showPicker);
    }

    initPicker(showPicker) {
      if (showPicker) {
        if (!this.state.picker && !this.state.isPickerLoading) {
          this.setState({ isPickerLoading: true });
          window.gapi.load('picker', () => this.onPickerLoad(this.state.openAfterSignin));
        } else if (this.state.picker) {
          this.state.picker.setVisible(true);
        }
      }
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} {...this.state} onGoogleSlidesClick={this.onGoogleSlidesClick} />
        </React.Fragment>
      );
    }

    onGoogleSlidesClick = (e) => {
      e.preventDefault();
      if (this.state.picker) {
        this.state.picker.setVisible(true);
      } else {
        const onUpdate = () => {
          this.setState({ openAfterSignin: true }, this.props.initState);
          this.state.picker?.setVisible(true);
        };
        this.props.oauthFunction(onUpdate);
      }
    };

    onPickerLoad = (isVisible) => {
      // Add 'Z' to the end of the expires_on date string to ensure that it is parsed as UTC
      if (this.props.auth && this.props.auth.expires_on && moment(`${this.props.auth.expires_on}Z`) < moment()) {
        API.get('/users/me/oauth_token/', (result) => {
          this.context.updateUser(result.data);
          this._showPicker(isVisible);
        });
      } else {
        this._showPicker(isVisible);
      }
    };

    _showPicker = (isVisible) => {
      const pickerBuilder = new window.google.picker.PickerBuilder()
        .setOAuthToken(this.props.auth.access_token)
        .setAppId(768845740735)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_BROWSER_KEY) // eslint-disable-line no-undef
        .setCallback(this.props.handleSelectGoogleSheet)
        .enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES);

      let view = specifiedView || this.props.view;
      if (!view || view === 'presentations') {
        view = window.google.picker.ViewId.PRESENTATIONS;
        pickerBuilder.addView(view);
      } else if (view === 'sheets') {
        view = window.google.picker.ViewId.SPREADSHEETS;
        pickerBuilder.addView(view);
      } else if (view === 'folders') {
        var docsView = new window.google.picker.DocsView()
          .setIncludeFolders(true)
          .setMimeTypes('application/vnd.google-apps.folder')
          .setSelectFolderEnabled(true);
        pickerBuilder.addView(docsView);
      }

      if (this.props.title) {
        pickerBuilder.setTitle(this.props.title);
      }

      const picker = pickerBuilder.build();
      picker.setVisible(isVisible);
      this.setState({ picker: picker, isPickerLoading: false, openAfterSignin: false });
    };
  };
};

export default WithGooglePicker;
