import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { addPopoverListener } from 'aceeditor/ace-matik.js';

import { openSidepane, openModal } from 'redux/ui/action';
import Constants from 'components/Constants';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import { useIsInputForbidden } from 'lib/hooks/useInput';
import Banner from 'components/lib/Banner';

/** Used in the QueryEditor and InputWithOptionalInputs to quickly access referenced inputs
 */
const InputPopoverWrapper = ({
  ace,
  inputs,
  isInputPopoverDisabled,
  inputPopoverTrigger,
  adjustPopoverTop,
  children,
}) => {
  const [popoverDetails, setPopoverDetails] = useState(null);
  const [mouseInTheHouse, setMouseInTheHouse] = useState(false);

  useEffect(() => {
    if (ace) {
      if (isInputPopoverDisabled || (inputPopoverTrigger === 'mouse' && !mouseInTheHouse)) {
        setPopoverDetails(null);
      } else {
        return addPopoverListener(ace, inputs, setPopoverDetails, inputPopoverTrigger);
      }
    }
  }, [ace, isInputPopoverDisabled, JSON.stringify(inputs), inputPopoverTrigger, mouseInTheHouse]);

  const handleContainerBlur = (e) => {
    // hide the popover when this control loses focus, BUT not when it loses focus to the popover
    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      setPopoverDetails(null);
    }
  };

  const popoverTop = popoverDetails
    ? adjustPopoverTop
      ? adjustPopoverTop(popoverDetails.top)
      : popoverDetails.top
    : undefined;

  return (
    <div
      onBlur={handleContainerBlur}
      onMouseOver={() => setMouseInTheHouse(true)}
      onMouseEnter={() => setMouseInTheHouse(true)}
      onMouseLeave={() => setMouseInTheHouse(false)}
      className="is-relative"
    >
      {children}
      {popoverDetails && (
        <div
          style={{
            position: 'absolute',
            top: popoverTop + 'px',
            left: popoverDetails.left + 'px',
            zIndex: 2,
          }}
        >
          <InputPopover input={popoverDetails.input} />
        </div>
      )}
    </div>
  );
};
InputPopoverWrapper.propTypes = {
  ace: PropTypes.object,
  inputs: PropTypes.object,
  /** Used to adjust the vertical position of the popover
   */
  adjustPopoverTop: PropTypes.func,
  children: PropTypes.node,
  /** True to suppress the input navigation popover. False will show the popover when the input name
   * is associated with a known input.
   */
  isInputPopoverDisabled: PropTypes.bool,
  /** temporary feature flag
   */
  inputPopoverTrigger: PropTypes.oneOf(['cursor', 'mouse']),
};

export default InputPopoverWrapper;

const InputPopover = ({ input }) => {
  // If input.id is empty, the input can't be fetched either because it doesn't exist, or it does and the user
  // doesn't have read access to it. We want to know which it is.
  const { isPending, isForbidden } = useIsInputForbidden(input.id ? null : input.name);

  let content = null;
  if (isForbidden) {
    content = <ForbiddenInput name={input.name} />;
  } else if (input.id || !isPending) {
    content = <InputLinks input={input} />;
  } else {
    content = <div className="has-text-dark phm pvs">{input.name}</div>;
  }

  return <div className="popup-box">{content}</div>;
};
InputPopover.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

function InputLinks({ input }) {
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const editInput = (e) => {
    e.preventDefault();

    if (ui?.sidepane?.content?.entityType === Constants.UI_TYPES.dynamic_content) {
      // The sidepane is open with a DC form and we don't want to replace
      // that with the input form, so use a modal instead.
      dispatch(openModal('inputModal', input));
    } else {
      dispatch(openSidepane({ ...input, entityType: Constants.UI_TYPES.input }, 'input-form'));
    }
  };

  const logo =
    Constants.InputIconsBySource[input.source_type?.toUpperCase()] ||
    Constants.InputIconsBySource[Constants.InputSources.USER_INPUT.toUpperCase()];

  return (
    <div className="is-flex gap-large has-text-dark is-family-primary is-align-items-center phm pvs">
      <Button category="tertiary" width="hug" size="small" onClick={editInput}>
        <Icon name={logo} theme="regular" size={16} />
        <span className="ml-2 underline">{input.name}</span>
      </Button>
      {input.id && (
        <Link className="button small is-tertiary phs ml-auto" to={`/inputs/${input.id}`} target="_blank">
          <Icon name="open" theme="regular" size={20} />
        </Link>
      )}
    </div>
  );
}
InputLinks.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    source_type: PropTypes.string,
  }).isRequired,
};

function ForbiddenInput({ name }) {
  return <Banner theme="error" size="s" content={`You do not have read access to "${name}".`} />;
}
ForbiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
};
