import { connectHits } from 'react-instantsearch-dom';
import React from 'react';

const ComponentWithHits = connectHits((props) => {
  const { hits, WrappedComponent, fetchItems, modifyHits, ...rest } = props;
  const updatedHits = modifyHits ? hits.map(modifyHits) : hits;
  return <WrappedComponent entitiesToRender={updatedHits} fetchItems={fetchItems} {...rest} isSearching={true} />;
});

export default ComponentWithHits;
