import React from 'react';
import PropTypes from 'prop-types';
import ToggleSwitch from './ToggleSwitch';

function ToggleSwitchWithLabel({ label, onClick, switchState, disabled, tooltipText }) {
  const disabledClassname = disabled ? 'pointer-events-none text-grey-500' : '';

  return (
    <div className={`flex justify-between w-full ${disabledClassname}`}>
      {label}
      <div
        onClick={onClick}
        className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
        data-tooltip-id="matik-tooltip"
        data-tooltip-content={tooltipText}
      >
        <ToggleSwitch switchState={switchState} />
      </div>
    </div>
  );
}

ToggleSwitchWithLabel.propTypes = {
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  switchState: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  showTooltip: PropTypes.bool,
};

export default ToggleSwitchWithLabel;
