import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextOverflowWithTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: null,
    };

    this.titleTextRef = React.createRef();
  }

  componentDidMount() {
    this.setTooltip();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setTooltip();
    }
  }

  setTooltip = () => {
    if (this.titleTextRef.current && this.titleTextRef.current.offsetWidth < this.titleTextRef.current.scrollWidth) {
      this.setState({ tooltip: this.titleTextRef.current.innerText });
    } else {
      this.setState({ tooltip: null });
    }
  };

  render() {
    return (
      <div
        className={this.props.className}
        ref={this.titleTextRef}
        data-tooltip-id="matik-tooltip"
        data-tooltip-content={this.state.tooltip}
      >
        {this.props.text ?? this.props.children}
      </div>
    );
  }
}

TextOverflowWithTooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any,
};

export default TextOverflowWithTooltip;
