import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import PopupMenu from 'components/shared/PopupMenu';
import ConditionSelector from './ConditionSelector';

const ConditionContextMenu = ({
  conditionsById = {},
  conditionsInTemplate,
  conditionsOnSlides,
  onCreate,
  onApply,
  onCopy,
  onPaste,
  onSelectAllSlides,
  onRemove,
  onRemoveAll,
}) => {
  const applySubmenuRef = useRef();
  const removeSubmenuRef = useRef();

  // Hover-like behavior that opens a submenu when hovering over the menu item and closes it when not.
  // Except! don't close the submenu when the user's mouse is outside of the entire menu or submenu.
  const checkSubmenus = (e) => {
    [applySubmenuRef, removeSubmenuRef].forEach((ref) => {
      if (ref.current) {
        if (ref.current.dom().contains(e.target)) {
          // Mouse is over a submenu, open it.
          if (!ref.current.isOpen()) {
            ref.current.open();
          }
        } else {
          // Mouse is over another menu item, close the submenus.
          if (ref.current.isOpen()) {
            ref.current.close();
          }
        }
      }
    });
  };

  return (
    <div
      className="inline-flex flex-col rounded-lg border border-grey-300 bg-matik-white shadow-m py-1.5 whitespace-nowrap"
      onMouseMove={checkSubmenus}
    >
      <MenuItem onClick={onCreate}>
        <Icon size={20} name="add_circle" /> Create New Condition
      </MenuItem>
      <ConditionSelectorSubmenu
        ref={applySubmenuRef}
        conditionsInTemplate={conditionsInTemplate}
        conditions={Object.values(conditionsById)}
        onSelect={onApply}
      >
        <MenuItem>
          <Icon size={20} name="checkmark" />
          <div className="grow">Apply Condition</div>
          <Icon size={16} name="chevron_right" />
        </MenuItem>
      </ConditionSelectorSubmenu>
      {onCopy && conditionsOnSlides?.length > 0 && (
        <MenuItem onClick={onCopy}>
          <Icon size={20} name="copy" /> Copy Conditions
        </MenuItem>
      )}
      {onPaste && (
        <MenuItem onClick={onPaste}>
          <Icon size={20} name="clipboard_paste" /> Paste Conditions
        </MenuItem>
      )}
      {conditionsOnSlides?.length > 0 && (
        <>
          {onSelectAllSlides && (
            <MenuItem isBorderTop onClick={onSelectAllSlides}>
              <Icon size={20} name="questionnaire_1" /> Select all Slides with same Conditions
            </MenuItem>
          )}
          <ConditionSelectorSubmenu
            ref={removeSubmenuRef}
            conditionsInTemplate={conditionsOnSlides}
            onSelect={onRemove}
          >
            <MenuItem isBorderTop>
              <Icon size={20} name="trash_can" />
              <div className="grow">Remove Single Condition</div>
              <Icon size={16} name="chevron_right" />
            </MenuItem>
          </ConditionSelectorSubmenu>
          <MenuItem onClick={onRemoveAll}>
            <div className="w-5" /> Remove All Conditions
          </MenuItem>
        </>
      )}
    </div>
  );
};
ConditionContextMenu.propTypes = {
  conditionsById: PropTypes.object,
  conditionsInTemplate: PropTypes.array,
  conditionsOnSlides: PropTypes.array,
  onCreate: PropTypes.func,
  onApply: PropTypes.func,
  onCopy: PropTypes.func,
  onPaste: PropTypes.func,
  onSelectAllSlides: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
};

export default ConditionContextMenu;

const MaybeButton = ({ onClick, ...props }) => {
  if (onClick) {
    return (
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        {...props}
      />
    );
  } else {
    return <div {...props} />;
  }
};
MaybeButton.propTypes = {
  onClick: PropTypes.func,
};

const MenuItem = ({ children, onClick, isBorderTop }) => {
  return (
    <MaybeButton
      onClick={onClick}
      className={`flex items-center gap-2.5 px-4 py-2.5 hover:bg-grey-100 leading-none ${
        isBorderTop ? 'border-t border-grey-300' : ''
      }`}
    >
      {children}
    </MaybeButton>
  );
};
MenuItem.propTypes = {
  isBorderTop: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

const ConditionSelectorSubmenu = forwardRef(
  ({ conditions, conditionsInTemplate, onSelect, children }, forwardedRef) => {
    const menuRef = useRef();

    const timeoutsRef = useRef({ opener: null, closer: null });
    const closeMenu = () => {
      const timeouts = timeoutsRef.current;
      if (timeouts.opener) {
        clearTimeout(timeouts.opener);
        timeouts.opener = null;
      }
      if (timeouts.closer) {
        return;
      }
      timeouts.closer = setTimeout(() => {
        if (menuRef.current) {
          menuRef.current.close();
        }
        timeouts.closer = null;
      }, 0);
    };

    const openMenu = () => {
      const timeouts = timeoutsRef.current;
      if (timeouts.closer) {
        clearTimeout(timeouts.closer);
        timeouts.closer = null;
      }
      if (timeouts.opener) {
        return;
      }
      timeouts.opener = setTimeout(() => {
        if (menuRef.current) {
          menuRef.current.open();
        }
        timeouts.opener = null;
      }, 250);
    };

    useImperativeHandle(
      forwardedRef,
      () => {
        return {
          dom: () => triggerRef.current,
          isOpen: () => menuRef.current.isOpen,
          close: () => closeMenu(),
          open: () => openMenu(),
        };
      },
      [],
    );

    const handleSelect = (condition) => {
      menuRef.current.close();
      onSelect(condition.id);
    };

    const triggerRef = useRef();
    const trigger = <div ref={triggerRef}>{children}</div>;
    return (
      <div>
        <PopupMenu className="w-full text-left" offset={-5} ref={menuRef} position="x" trigger={trigger}>
          <div
            className="w-[268px] rounded-md border border-grey-300 bg-matik-white shadow-m"
            onMouseMove={(e) => e.stopPropagation()}
          >
            <ConditionSelector
              conditions={conditions}
              conditionsInTemplate={conditionsInTemplate}
              onSelect={handleSelect}
            />
          </div>
        </PopupMenu>
      </div>
    );
  },
);
ConditionSelectorSubmenu.displayName = 'ConditionSelectorSubmenu';
ConditionSelectorSubmenu.propTypes = {
  conditions: PropTypes.array,
  conditionsInTemplate: PropTypes.array,
  onSelect: PropTypes.func,
  children: PropTypes.any,
};
