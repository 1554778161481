import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import { ReactComponent as ArrowLeftCircle } from '../../svg/arrow_left_circle.svg';
import matik_logo_black from '../../images/matik_logo_black.svg';

class PaymentsHeader extends Component {
  render() {
    return (
      <Level className="payments-header">
        <Level.Item>
          <button onClick={this.props.onBackClick} className="back-button">
            <ArrowLeftCircle className="mls" />
            <span className="pls">Back</span>
          </button>
        </Level.Item>
        <Level.Item>
          <img src={matik_logo_black} alt="matik logo" height="40px" className="payments-logo" />
        </Level.Item>
      </Level>
    );
  }
}

PaymentsHeader.propTypes = {
  onBackClick: PropTypes.func,
};

export default PaymentsHeader;
