import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Constants from '../Constants';
import ConditionalClause from './ConditionalClause';
import ConditionGroup from './ConditionGroup';
import DynamicContentSelect from 'components/producer/dynamicContent/forms/DynamicContentSelect';
import chevron_up from '../../svg/chevron_up.svg';
import { cloneDeep } from 'lodash';

function ConditionalContentCondition({
  condition,
  conditionIndex,
  dynamicContentArr,
  formRef,
  generateEmptyClause,
  inputs,
  isReadOnly,
  queryObj,
  updateQuery,
}) {
  const [isExpanded, setIsExpanded] = useState(!condition.dynamicContent);

  const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditional;

  const clauses =
    !condition.clauses || condition.clauses.length === 0
      ? [{ param: Object.keys(inputs)[0], operator: supported_operators[0], operand: '' }]
      : condition.clauses;
  const conditionIntro = (
    <span>
      <strong>If</strong>&nbsp;all of the following conditions are met
    </span>
  );

  const onDynamicContentSelect = (contentId) => {
    const updatedQueryObj = cloneDeep(queryObj);
    updatedQueryObj.conditions[conditionIndex].dynamicContent = contentId;
    updateQuery(updatedQueryObj, false);
    setIsExpanded(false);
  };

  const toggleIsExpanded = (e, bool = !isExpanded) => {
    e.preventDefault();
    setIsExpanded(bool);
  };

  return (
    <ConditionGroup
      conditionIndex={conditionIndex}
      queryObj={queryObj}
      updateQuery={updateQuery}
      conditionIntro={conditionIntro}
      isReadOnly={isReadOnly}
    >
      {clauses.map((clause, idx) => {
        return (
          <ConditionalClause
            key={`${conditionIndex} - ${idx}`}
            generateEmptyClause={generateEmptyClause}
            queryObj={queryObj}
            updateQuery={updateQuery}
            clause={clause}
            isReadOnly={isReadOnly}
            clauseIndex={idx}
            conditionIndex={conditionIndex}
            inputs={inputs}
            formRef={formRef}
          />
        );
      })}
      <p className="mhl">
        <strong>Then</strong>&nbsp;insert the following piece of dynamic content
      </p>
      {isExpanded && (
        <div className="is-flex space-between">
          <button
            className="icon-button list-close-icon-left"
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Close"
            onClick={toggleIsExpanded}
          >
            <img src={chevron_up} />
          </button>
          <button
            className="icon-button list-close-icon-right"
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Close"
            onClick={toggleIsExpanded}
          >
            <img src={chevron_up} />
          </button>
        </div>
      )}

      <DynamicContentSelect
        onDynamicContentSelect={onDynamicContentSelect}
        isReadOnly={isReadOnly}
        selectedDynamicContentId={condition.dynamicContent}
        dynamicContentArr={dynamicContentArr}
        isExpanded={isExpanded}
        onExpanded={setIsExpanded}
      />
    </ConditionGroup>
  );
}

ConditionalContentCondition.propTypes = {
  condition: PropTypes.object,
  conditionIndex: PropTypes.number,
  dynamicContentArr: PropTypes.array,
  isReadOnly: PropTypes.bool,
  inputs: PropTypes.object,
  queryObj: PropTypes.object,
  updateQuery: PropTypes.func,
  generateEmptyClause: PropTypes.func,
  formRef: PropTypes.object,
};

export default ConditionalContentCondition;
