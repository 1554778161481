import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { isEqual } from 'lodash';
import simpleRestProvider from 'ra-data-simple-rest';
import { Admin, Resource, fetchUtils } from 'react-admin';
import API from 'lib/api';
import { Auth } from 'lib/auth';
import { MAlert } from 'components/shared/Alerts';
import AuthClient from './AuthClient';
import { BillingCreate, BillingEdit } from './Billing';
import { ClientCreate, ClientEdit, ClientList } from './Client';
import { EnterpriseCreate, EnterpriseEdit, EnterpriseList, EnterpriseShow } from './Enterprise';
import { RoleCreate, RoleEdit, RoleList } from './Role';
import { UserCreate, UserEdit, UserList } from './User';

class SoupMain extends Component {
  render() {
    return (
      <Admin
        title="Soup Dumpling: Matik's Dashboard"
        dataProvider={dataProviderWithTracking}
        authProvider={AuthClient}
        history={history}
      >
        <Resource
          name="enterprises"
          list={EnterpriseList}
          edit={EnterpriseEdit}
          create={EnterpriseCreate}
          show={EnterpriseShow}
        />
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="roles" list={RoleList} edit={RoleEdit} create={RoleCreate} />
        <Resource name="billings" edit={BillingEdit} create={BillingCreate} />
        <Resource name="clients" list={ClientList} edit={ClientEdit} create={ClientCreate} />
        <Resource name="settings" />
      </Admin>
    );
  }
}

export default SoupMain;
const history = createBrowserHistory({ basename: '/soup_dumpling/' });
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json', Authorization: `Bearer ${Auth.getAuthToken()}` });
  } else {
    options.headers.append('Authorization', `Bearer ${Auth.getAuthToken()}`);
  }
  // Insert trailing slash
  const [base, path] = url.split('?');

  const updatedUrl = `${base}/?${path ? path : ''}`;

  return fetchUtils.fetchJson(updatedUrl, options);
};

// eslint-disable-next-line no-undef
const dataProvider = simpleRestProvider(`${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api`, httpClient);

const dataProviderWithTracking = {
  ...dataProvider,
  create: (resource, params) => {
    if (resource === 'users') {
      const user = params.data;
      API.track('soup_dumpling_user_create', {}, user.id);
    }
    return dataProvider.create(resource, params).catch((error) => {
      MAlert(error.message);
      throw error;
    });
  },
  update: (resource, params) => {
    if (!isEqual(params.data.is_self_serve, params.previousData.is_self_serve)) {
      params.data.settings.matik_mail_enabled = params.data.is_self_serve;
      params.data.settings.ai_enabled = params.data.is_self_serve;
    }
    if (resource !== 'enterprises') {
      return dataProvider.update(resource, params).catch((error) => {
        MAlert(error.message);
        throw error;
      });
    }
    if (resource === 'enterprises') {
      let newUsers;
      if (params.data.files && params.data.files.rawFile instanceof File) {
        newUsers = params.data.files.rawFile;
        return convertFileToBase64(newUsers)
          .then((base64Csv) => bulkAddUsers(base64Csv, params.id))
          .then(() => MAlert('Users added succesfully.'))
          .then(() =>
            dataProvider.update(resource, {
              id: params.id,
              data: {
                ...params.data,
              },
            }),
          )
          .catch((error) => {
            MAlert(`Could not edit enterprise: ${error.message}`);
            throw error;
          });
      } else {
        return dataProvider.update(resource, params).catch((error) => {
          MAlert(error.message);
          throw error;
        });
      }
    }
  },
};

const bulkAddUsers = (csvFile, enterpriseId) =>
  new Promise((resolve) => {
    API.post('/users/bulk/', { users_csv: csvFile, enterprise_id: enterpriseId }, (response) => resolve(response));
  });

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export { dataProvider };
