import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { UserContext } from '../UserContext';
import MultiAvatar from './MultiAvatar';
import AccessManager from '../../lib/AccessManager';
import { mapDispatchToProps } from '../../redux/ui/dispatchers';
import { mapUiStateToProps } from '../../redux/ui/stateMappers';

class AccessAvatarsWithShareButton extends Component {
  render() {
    const accessObj = new AccessManager(this.props.template.id, this.props.accessesByTemplateId, this.context.user);
    let shareElem = '';
    if (!accessObj.isShared() && accessObj.can('edit')) {
      if (this.props.showShareButton) {
        shareElem = (
          <button className="button is-secondary share-template-button" onClick={this.handleClick}>
            Share
          </button>
        );
      }
    } else {
      shareElem = (
        <div>
          <span onClick={this.handleClick} className="avatars-with-grey-border">
            <MultiAvatar avatars={accessObj.bigListSharedColumnAvatars()} limit={4} size={30} />
          </span>
          {this.props.showTemplateCardShareButton && (
            <button
              className="button is-primary is-pulled-right share-template-button phs pvn"
              onClick={this.handleClick}
            >
              Share
            </button>
          )}
        </div>
      );
    }
    return <React.Fragment>{shareElem}</React.Fragment>;
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.openModal('accessModal', this.props.template);
  };
}

AccessAvatarsWithShareButton.propTypes = {
  template: PropType.object,
  accessesByTemplateId: PropType.object,
  showShareButton: PropType.bool,
  showTemplateCardShareButton: PropType.bool,
  openModal: PropType.func,
};

AccessAvatarsWithShareButton.contextType = UserContext;

export default connect(mapUiStateToProps, mapDispatchToProps)(AccessAvatarsWithShareButton);
