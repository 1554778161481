import React from 'react';
import PropTypes from 'prop-types';

function Tabs({ children }) {
  return (
    <div
      role="tablist"
      className="border border-grey-300 rounded-md h-10 divide-x divide-grey-300 self-start flex w-fit"
    >
      {children}
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
};

export default Tabs;
