import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import { capitalize, map } from 'lodash';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import { ReactComponent as CloseIcon } from '../../../svg/close_14.svg';
import Constants from '../../Constants';
import Sidepane from '../sidepane/Sidepane';
import PresentationLog from './PresentationLog';
import Button from '../../lib/Button';
import Tabs from '../../lib/Tabs';
import TabItem from '../../lib/TabItem';

function PresentationLogSidepane(props) {
  const { errors, warnings, info, presentation, closeSidepane } = props;

  const [currentTab, setCurrentTab] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    setCurrentTab(errors.length ? Constants.LOG_LEVEL.error : Constants.LOG_LEVEL.warning);
  }, [errors, warnings]);

  useEffect(() => {
    if (currentTab === Constants.LOG_LEVEL.error) {
      setTitle(Pluralize(capitalize(Constants.LOG_LEVEL.error), errors.length, true));
    } else if (currentTab === Constants.LOG_LEVEL.info) {
      setTitle(Pluralize(capitalize(Constants.LOG_LEVEL.info + ' Log'), info.length, true));
    } else {
      setTitle(Pluralize(capitalize(Constants.LOG_LEVEL.warning), warnings.length, true));
    }
  }, [currentTab]);

  const toggleTab = (tab) => {
    const upperTab = tab.toUpperCase();
    if (currentTab !== upperTab) {
      setCurrentTab(upperTab);
    }
  };

  const handleCloseSidepane = (e) => {
    if (e) {
      e.preventDefault();
    }
    closeSidepane();
  };

  const renderPresentationLogs = () => {
    if (currentTab === Constants.LOG_LEVEL.error) {
      return map(errors, (error, index) => <PresentationLog key={index} renderData={error} />);
    }
    if (currentTab === Constants.LOG_LEVEL.info) {
      return map(info, (item, index) => <PresentationLog key={index} renderData={item} />);
    } else {
      return map(warnings, (warning, index) => <PresentationLog key={index} renderData={warning} />);
    }
  };

  return (
    <>
      <Sidepane sidepaneType="presentation-log" name="presentation-log">
        <header>
          <h1>
            {title}
            <p>{`Template: ${presentation.template.name}`}</p>
          </h1>
          <Button category="tertiary" onClick={handleCloseSidepane}>
            <CloseIcon alt="close" />
          </Button>
        </header>
        <div className="flex my-2 ml-5">
          <Tabs>
            <TabItem
              label="Error"
              tag={errors.length}
              isActive={currentTab === Constants.LOG_LEVEL.error}
              onClick={toggleTab}
            />
            <TabItem
              label="Warning"
              tag={warnings.length}
              isActive={currentTab === Constants.LOG_LEVEL.warning}
              onClick={toggleTab}
            />
            <TabItem
              label="Info"
              tag={info.length}
              isACtive={currentTab === Constants.LOG_LEVEL.info}
              onClick={toggleTab}
            />
          </Tabs>
        </div>
        <div className="presentation-logs">{renderPresentationLogs()}</div>
      </Sidepane>
    </>
  );
}

PresentationLogSidepane.propTypes = {
  errors: PropTypes.array,
  warnings: PropTypes.array,
  info: PropTypes.array,
  presentation: PropTypes.object,
  closeSidepane: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const props = {
    ...ownProps,
  };
  return {
    ...mapUiStateToProps(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...mapUiDispatchToProps(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationLogSidepane);
