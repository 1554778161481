import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import PasswordAuth from './PasswordAuth';
import AuthTabs from '../AuthTabs';

function TableauAuth(props) {
  const personalAccessToken = 'access_token' in props.auth ? props.auth['access_token'] : '';
  const tokenName = 'token_name' in props.auth ? props.auth['token_name'] : '';
  const tabIndex = personalAccessToken ? 1 : 0;
  const checkbox = props.checkboxes?.[1];

  const tabs = [
    { name: 'login-tab', title: 'Access Via Login' },
    { name: 'auth-tab', title: 'Access Via Personal Access Token' },
  ];

  const [activeAuthTab, setActiveAuthTab] = useState(tabs[tabIndex].name);
  return (
    <React.Fragment>
      <AuthTabs
        setActiveAuthTab={setActiveAuthTab}
        tabs={tabs}
        activeAuthTab={activeAuthTab}
        centered={props.centered}
      />
      {activeAuthTab === tabs[0].name ? (
        <PasswordAuth
          onAuthChange={props.onAuthChange}
          password={props.password}
          username={props.username}
          auth={props.auth}
          checkboxes={props.checkboxes}
          usernameLabel="Tableau Username"
          usernameHelp="Username for your Tableau Account"
          usernamePlaceholder="Tableau Username"
          passwordHelp="Password for your Tableau Account"
          passwordLabel="Tableau Password"
          passwordPlaceholder="Tableau Password"
          disclaimer="Note: If your account has Multi-Factor Authentication enabled, you will need to use a Personal Access Token instead."
        />
      ) : (
        <Box className="data-source-box">
          {checkbox}
          <p className="auth-disclaimer">
            Note: Personal Access Tokens expire after 14 days of inactivity. Entering a Personal Access Token will clear
            your username and password.
          </p>
          <Form.Field>
            <Form.Label htmlFor="token-name">Personal Access Token Name</Form.Label>
            <Form.Help>Token Name</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Token Name"
                type="text"
                name="token_name"
                onChange={props.onAuthChange}
                value={tokenName}
                autoComplete="off"
                id="token-name"
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label htmlFor="personal-access-token">Personal Access Token Secret</Form.Label>
            <Form.Help>Token Secret from your tableau workspace</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Token Secret"
                type="password"
                name="access_token"
                onChange={props.onAuthChange}
                value={personalAccessToken}
                autoComplete="off"
                id="personal-access-token"
              />
            </Form.Control>
          </Form.Field>
        </Box>
      )}
    </React.Fragment>
  );
}

TableauAuth.propTypes = {
  auth: PropTypes.object,
  checkboxes: PropTypes.array,
  centered: PropTypes.bool,
  onAuthChange: PropTypes.func,
  password: PropTypes.string,
  username: PropTypes.string,
  activeAuthTab: PropTypes.string,
};

export default TableauAuth;
