import React, { useState, useEffect } from 'react';
import AuthPage from './AuthPage';
import { Auth } from '../../lib/auth';
import { Card, Form } from 'react-bulma-components';
import { MAlert } from '../shared/Alerts';
import ReCAPTCHA from 'react-google-recaptcha';
import matik_logo_black from '../../images/matik_logo_black.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Mail } from '../../svg/mail_filled.svg';
import { ReactComponent as WarningTriangle } from '../../svg/warning_triangle_solid.svg';
import Button from '../lib/Button';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [reCaptchaValue, setReCaptchaValue] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(decodeURIComponent(params.get('email')));
  }, []);

  const onEmailUpdate = (e) => {
    setEmail(e.target.value);
    setIsEmailInvalid(false);
  };

  const onForgotPasswordSubmit = (e) => {
    e.preventDefault();
    setIsEmailInvalid(false);
    Auth.forgotPassword({ email, reCaptchaValue }, {}, onForgotPasswordSuccess, onForgotPasswordError);
  };

  const showSuccessAndRedirect = () => {
    MAlert("If you have an email in our system, we've sent you an email to reset your password.", 'Success', 'success');
    setIsEmailInvalid(false);
    history.push('/login');
  };

  const onForgotPasswordSuccess = () => {
    showSuccessAndRedirect();
  };

  const onForgotPasswordError = (err) => {
    if (err.status === 400) {
      setIsEmailInvalid(true);
      setEmailErrorMsg('Email is required');
    } else if (err.status === 404) {
      // As per security best practices, don't inform the user that an account does or does not exist.
      // Use the same behavior for failure and success cases.
      showSuccessAndRedirect();
    } else {
      setIsEmailInvalid(true);
      setEmailErrorMsg('Unknown error');
    }
  };

  const onRecaptchaChange = (value) => {
    setReCaptchaValue(value);
    setSubmitDisabled(false);
  };

  return (
    <AuthPage>
      <div className="auth-logo">
        <img src={matik_logo_black} alt="matik logo" height="40px" />
      </div>
      <Card className="auth-card">
        <form onSubmit={onForgotPasswordSubmit} className="card-content">
          <h1 className="is-size-3 has-text-centered mbs">Forgot Password?</h1>
          <p className="has-text-centered mbl">
            Enter your email address and we’ll send you instructions to reset your password.
          </p>
          <Form.Field>
            <Form.Control className="has-icons-left has-icons-right">
              <Form.Input type="email" placeholder="Email" value={email} onChange={onEmailUpdate} />
              <span className="icon is-small is-left">
                <Mail />
              </span>
              {isEmailInvalid && (
                <span className="icon is-small is-right">
                  <WarningTriangle />
                </span>
              )}
            </Form.Control>
            {isEmailInvalid && <p className="help is-danger">{emailErrorMsg}</p>}
          </Form.Field>
          <div className="ptl forgot-captcha">
            <ReCAPTCHA
              // eslint-disable-next-line no-undef
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onRecaptchaChange}
            />
          </div>
          <Form.Field className="ptl">
            <Form.Control>
              <Button type="submit" width="fullWidth" status={submitDisabled ? 'disabled' : 'default'}>
                Send Reset Email
              </Button>
            </Form.Control>
          </Form.Field>
        </form>
      </Card>
    </AuthPage>
  );
}

export default ForgotPassword;
