import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConnectedSearchBox from './ConnectedSearchBox';
import Filters from './Filters';

class SearchBoxAndFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <ConnectedSearchBox
          filterPlaceholder={this.props.filterPlaceholder}
          toggleFilters={this.toggleFilters}
          showFilters={this.state.showFilters}
          showFilterButton={this.props.showFilterButton}
          updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
        />
        <Filters
          attributes={this.props.searchAttributes}
          filterList={this.props.searchState.refinementList}
          show={this.state.showFilters}
          sidebar={false}
          close={this.closeFilters}
          filtersAreShownInSidepane={this.props.filtersAreShownInSidepane}
        />
      </React.Fragment>
    );
  }

  closeFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showFilters: false });
  };

  toggleFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showFilters: !this.state.showFilters });
  };
}

SearchBoxAndFilters.propTypes = {
  filterPlaceholder: PropTypes.string,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  showFilters: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  updateIsFetchingPresentation: PropTypes.func,
  filtersAreShownInSidepane: PropTypes.bool,
};

export default SearchBoxAndFilters;
