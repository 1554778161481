import React, { useState } from 'react';
import PropTypes from 'prop-types';
import illustration from 'images/table_illustration_80x80.svg';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import API from 'lib/api';
import Constants from 'components/Constants';
function SpreadsheetNarrativeCard({ onTemplateAdd }) {
  return (
    <div className="narrative-card">
      <img src={illustration} className="narrative-card-icon" />
      <div className="narrative-card-center">
        <p className="narrative-card-title">Spreadsheet</p>
        <p className="narrative-card-subtitle">Add a Google Sheet</p>
      </div>
      <AddSpreadsheetButton className="button is-secondary is-fullwidth" onTemplateAdd={onTemplateAdd}>
        Add Spreadsheet Template
      </AddSpreadsheetButton>
    </div>
  );
}
SpreadsheetNarrativeCard.propTypes = {
  onTemplateAdd: PropTypes.func,
};

export default SpreadsheetNarrativeCard;

const AddSpreadsheetButton = ({ className, children, onTemplateAdd }) => {
  const [isAdding, setAdding] = useState(false);
  const { create } = useTemplateMutator();

  const handleSelect = (sheets) => {
    setAdding(true);

    const file = sheets[0];
    const templateData = {
      name: file.name,
      source_type: Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS,
      source: file,
      description: file.description,
    };

    create(templateData, file)
      .then((newTemplateData) => onTemplateAdd(newTemplateData))
      .catch(API.defaultError)
      .finally(() => setAdding(false));
  };

  if (isAdding) {
    className = `${className || ''} is-loading`;
  }
  return (
    <GoogleDrivePicker className={className} onPicked={handleSelect} view="sheets">
      {children}
    </GoogleDrivePicker>
  );
};
AddSpreadsheetButton.propTypes = {
  className: PropTypes.string,
  onTemplateAdd: PropTypes.func,
  children: PropTypes.any,
};
