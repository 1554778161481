import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export default function AutoCompleteSuggestions({ suggestions, searchQuery, onClose, onSelect, coordinates }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeIndexRef = useRef(activeIndex);
  const filteredSuggestionsRef = useRef(suggestions);

  // Reset active index when search query changes.
  useEffect(() => {
    setActiveIndex(0);
  }, [searchQuery]);

  useEffect(() => {
    // Use refs to ensure that the global event listener always has access
    // to the latest active index and filtered suggestions.
    activeIndexRef.current = activeIndex;

    // Filter suggestions based on search query.
    filteredSuggestionsRef.current = suggestions.filter((suggestion) => {
      return suggestion.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [activeIndex, searchQuery, suggestions]);

  const onKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        setActiveIndex(Math.min(activeIndexRef.current + 1, filteredSuggestionsRef.current.length - 1));
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        setActiveIndex(Math.max(activeIndexRef.current - 1, 0));
        break;
      }
      case 'Enter': {
        event.preventDefault();
        onSelect(filteredSuggestionsRef.current[activeIndexRef.current]);
        break;
      }
      case 'Escape': {
        event.preventDefault();
        onClose();
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const autoCompleteDiv = {
    position: 'absolute',
    x: 0,
    y: 0,
    transform: `translate(${coordinates.x}px, ${coordinates.y}px)`,
    zIndex: '100',
    padding: '4px 0px 6px 0px',
    borderRadius: '4px',
    border: '1px solid #E0E5EE',
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px 0px rgba(0, 31, 53, 0.06), 0px 1px 6px 0px rgba(0, 31, 53, 0.08)',
  };

  return (
    <div style={{ position: 'absolute' }}>
      <div onBlur={onClose} style={autoCompleteDiv}>
        {filteredSuggestionsRef.current.slice(0, 5).map((suggestion, index) => {
          return (
            <div
              tabIndex={index}
              key={index}
              className={`autocomplete-suggestion ${index === activeIndex ? 'active' : ''}`}
              onMouseDown={() => onSelect(suggestion)}
              onMouseOver={() => setActiveIndex(index)}
            >
              {suggestion}
            </div>
          );
        })}
      </div>
    </div>
  );
}

AutoCompleteSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  searchQuery: PropTypes.string,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  coordinates: PropTypes.object,
};
