import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import API from './api';
import utils from './utils';
import { MAlert } from '../components/shared/Alerts';
import jwt_decode from 'jwt-decode';
import Constants from '../components/Constants';

window.addEventListener('storage', (event) => {
  if (event.key === 'authToken' && event.newValue !== event.oldValue) {
    if (!event.oldValue || !event.newValue || jwt_decode(event.oldValue).id !== jwt_decode(event.newValue).id) {
      window.location.reload();
    }
  }
});

class Auth {
  static tokenRefreshing = null;

  static getAuthToken() {
    let authToken = localStorage.getItem('authToken');
    if (authToken) {
      return authToken;
    }

    authToken = sessionStorage.getItem('authToken');
    if (authToken) {
      return authToken;
    }

    return null;
  }

  static isLoggedIn() {
    const authToken = this.getAuthToken();

    return !!authToken;
  }

  static hasRole(rolename) {
    const authToken = this.getAuthToken();
    if (!authToken) {
      return false;
    }

    const decoded = jwt_decode(authToken);
    return decoded.rls.split(',').indexOf(rolename) >= 0;
  }

  static tokenIsExpired() {
    const authToken = this.getAuthToken();
    if (!authToken) {
      return true;
    }
    const decoded = jwt_decode(authToken);
    return Date.now() >= decoded.exp * 1000;
  }

  static refreshToken() {
    const authToken = this.getAuthToken();
    if (!authToken) {
      return Promise.reject('no_token');
    }
    const isExpired = this.tokenIsExpired();
    if (!isExpired) {
      return Promise.resolve(authToken);
    }

    if (!Auth.tokenRefreshing) {
      Auth.tokenRefreshing = axios
        .post(
          API._generateUrlFromRelativeUrl('/auth/refresh/'),
          {},
          { headers: { Authorization: `Bearer ${authToken}` } },
        )
        .then((response) => {
          const authToken = response.data.access_token;
          Auth.storeAuthToken(authToken);
          return Promise.resolve(authToken);
        })
        .catch((err) => {
          return Promise.reject(err);
        })
        .finally(() => {
          Auth.tokenRefreshing = null;
        });
    }

    return Auth.tokenRefreshing;
  }

  static getRoles() {
    const authToken = this.getAuthToken();
    if (!authToken) {
      return null;
    }

    const decoded = jwt_decode(authToken);
    return decoded.rls;
  }

  static getId() {
    const authToken = this.getAuthToken();
    if (!authToken) {
      return null;
    }

    const decoded = jwt_decode(authToken);
    return decoded.id;
  }

  static preLogIn(email, success, error) {
    const data = {
      email,
    };
    axios
      // eslint-disable-next-line no-undef
      .post(`${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/pre_login/`, data)
      .then((result) => {
        if (success) {
          success(result);
        }
      })
      .catch((err) => {
        if (error) {
          error(err.response);
        } else {
          MAlert('An unexpected error occurred. ' + err, 'Error', 'error');
        }
      });
  }

  static logIn(email, password, rememberMe, success, error) {
    const data = {
      email,
      password,
      remember_me: rememberMe,
    };
    axios
      // eslint-disable-next-line no-undef
      .post(`${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/login/`, data)
      .then((result) => {
        const authToken = result.data.access_token;
        this.storeAuthToken(authToken);
        if (success) {
          success(result.data.user);
        }
      })
      .catch((err) => {
        if (error) {
          error(err.response);
        } else {
          MAlert('An unexpected error occurred. ' + err, 'Error', 'error');
        }
      });
  }

  static forgotPassword(data, headers, success, error) {
    axios
      // eslint-disable-next-line no-undef
      .post(`${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/forgot_password/`, data, { headers: headers })
      .then((result) => {
        if (success) {
          success(result);
        }
      })
      .catch((err) => {
        if (error) {
          error(err.response);
        } else {
          MAlert('An unexpected error occurred. ' + err, 'Error', 'error');
        }
      });
  }

  static resetPassword(password, token, success, error) {
    const data = {
      new_password: password,
      token: token,
    };

    axios
      // eslint-disable-next-line no-undef
      .post(`${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/reset_password/`, data)
      .then((result) => {
        if (success) {
          success(result);
        }
      })
      .catch((err) => {
        if (error) {
          error(err.response);
        } else {
          MAlert('An unexpected error occurred. ' + err);
        }
      });
  }

  static logOut(logoutCallback) {
    API.post('/auth/logout/', { access_token: this.getAuthToken() }, () => {
      this.clearAuthToken();
      if (logoutCallback) {
        logoutCallback();
      }
    });
  }

  static verifySsoInformation(ssoParamString, onSuccess, onError) {
    axios
      // eslint-disable-next-line no-undef
      .get(`${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/sso_callback/?${ssoParamString}`)
      .then((result) => {
        onSuccess(result);
      })
      .catch((err) => {
        onError(err);
      });
  }

  static getSessionId() {
    if (utils.storageAvailable('sessionStorage')) {
      let uid = sessionStorage.getItem('sessionId');
      if (!uid) {
        uid = Auth.setSessionId();
      }
      return uid;
    }
    return null;
  }

  static setSessionId() {
    if (utils.storageAvailable('sessionStorage')) {
      const uid = String(uuidv4());
      sessionStorage.setItem('sessionId', uid);
      return uid;
    }
    return null;
  }

  static storeAuthToken(authToken) {
    if (authToken) {
      if (utils.storageAvailable('localStorage')) {
        localStorage.setItem('authToken', authToken);
      } else if (utils.storageAvailable('sessionStorage')) {
        sessionStorage.setItem('authToken', authToken);
      }
    }
  }

  static clearAuthToken() {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
  }

  static getHomeForAuthenticatedRole() {
    const roles = Auth.getRoles();
    if (roles.indexOf(Constants.PRODUCER_ROLE) >= 0 || roles.indexOf(Constants.PRODUCER_ADMIN_ROLE) >= 0) {
      return '/';
    }

    if (roles.indexOf(Constants.CONSUMER_ROLE) >= 0) {
      return '/create';
    }

    if (roles.indexOf(Constants.SUPERADMIN_ROLE) >= 0) {
      return '/soup_dumpling';
    }
  }

  static verifyAndLogInFromGoogle(token, rememberMe) {
    API.post('/_api/auth/google_callback', { credential: token, remember_me: rememberMe }, (accessToken) => {
      Auth.storeAuthToken(accessToken);
    });
  }

  static createUser(name, email, password, success, error) {
    const data = {
      name: name,
      email: email,
      password: password,
    };
    axios
      .post(API._generateUrlFromRelativeUrl('/auth/signup/'), data)
      .then((result) => {
        const authToken = result.data.access_token;
        this.storeAuthToken(authToken);
        if (success) {
          success(result.data.user);
        }
      })
      .catch((err) => {
        if (error) {
          error(err.response);
        } else {
          MAlert('An unexpected error occurred. ' + err, 'Error', 'error');
        }
      });
  }
}

export { Auth };
