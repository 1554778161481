import React, { Component } from 'react';
import PropTypes from 'prop-types';
import html_code_editor from 'images/html_code_editor.svg';

class EditorCodeCard extends Component {
  render() {
    return (
      <div className="narrative-card">
        <img src={html_code_editor} className="narrative-card-icon" />
        <div className="narrative-card-center">
          <p className="narrative-card-title">Code Editor</p>
          <p className="narrative-card-subtitle">
            Copy and paste your existing template code, or code your own template
          </p>
        </div>
        <button className="button is-secondary is-fullwidth" onClick={this.props.onEditorCodeClick}>
          Use Code Editor
        </button>
      </div>
    );
  }
}

EditorCodeCard.propTypes = {
  onEditorCodeClick: PropTypes.func,
  user: PropTypes.object,
};

export default EditorCodeCard;
