import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../../svg/close_14.svg';

function ModalHeader(props) {
  const { linkTo, onClose, title } = props;
  return (
    <header className="modal-card-head">
      {linkTo ? (
        <a href={linkTo} rel="noreferrer" className="modal-card-title text-overflow-ellipsis">
          {title}
        </a>
      ) : (
        <div className="modal-card-title text-overflow-ellipsis">{title}</div>
      )}
      <button className="button is-close" aria-label="close" onClick={onClose}>
        <CloseIcon />
      </button>
    </header>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  linkTo: PropTypes.string,
};

export default ModalHeader;
