import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import AuthTabs from '../AuthTabs';

function DatabricksAuth({ auth, centered, checkboxes, onAuthChange }) {
  const personalAccessToken = auth.access_token ?? '';
  const clusterId = auth.cluster_id ?? '';
  const path = auth.path ?? '';
  const tabIndex = path ? 1 : 0;

  const tabs = [
    { name: 'cluster-tab', title: 'Access Via Cluster' },
    { name: 'sql-tab', title: 'Access Via SQL Warehouse' },
  ];

  const [activeAuthTab, setActiveAuthTab] = useState(tabs[tabIndex].name);
  return (
    <React.Fragment>
      <AuthTabs setActiveAuthTab={setActiveAuthTab} tabs={tabs} activeAuthTab={activeAuthTab} centered={centered} />
      {activeAuthTab === tabs[0].name ? (
        <Box className="data-source-box">
          {checkboxes?.[1]}
          <Form.Field>
            <Form.Label htmlFor="cluster-id">Cluster ID</Form.Label>
            <Form.Help>Cluster ID</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Cluster ID"
                type="text"
                name="cluster_id"
                onChange={onAuthChange}
                value={clusterId}
                id="cluster-id"
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label htmlFor="personal-access-token">Personal Access Token</Form.Label>
            <Form.Help>Personal Access token from your databricks workspace</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Personal Access Token"
                type="password"
                name="access_token"
                onChange={onAuthChange}
                value={personalAccessToken}
                id="personal-access-token"
              />
            </Form.Control>
          </Form.Field>
        </Box>
      ) : (
        <Box className="data-source-box">
          <Form.Field>
            <Form.Label htmlFor="cluster-id">HTTP Path</Form.Label>
            <Form.Help>HTTP Path</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="HTTP Path"
                type="text"
                name="path"
                onChange={onAuthChange}
                value={path}
                id="path"
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label htmlFor="personal-access-token">Personal Access Token</Form.Label>
            <Form.Help>Personal Access token from your databricks workspace</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Personal Access Token"
                type="password"
                name="access_token"
                onChange={onAuthChange}
                value={personalAccessToken}
                id="personal-access-token"
              />
            </Form.Control>
          </Form.Field>
        </Box>
      )}
    </React.Fragment>
  );
}

DatabricksAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  checkboxes: PropTypes.array,
  onAuthChange: PropTypes.func,
};

export default DatabricksAuth;
