import React from 'react';
import PropTypes from 'prop-types';
import { capitalize, lowerCase, map, startCase } from 'lodash';
import Constants from '../../Constants';
import { ReactComponent as ErrorIcon } from '../../../svg/error_pill.svg';
import { ReactComponent as WarningIcon } from '../../../svg/warning_triangle_solid.svg';
import { ReactComponent as InfoIcon } from '../../../svg/info_icon_blue.svg';
import OpenInNewTabIcon from '../OpenInNewTabIcon';
function PresentationLog(props) {
  const { renderData } = props;
  let icon;
  switch (renderData.level) {
    case Constants.LOG_LEVEL.error:
      icon = <ErrorIcon className="icon" />;
      break;
    case Constants.LOG_LEVEL.warning:
      icon = <WarningIcon className="icon" />;
      break;
    case Constants.LOG_LEVEL.info:
      icon = <InfoIcon className="icon" />;
      break;
    default:
      icon = null;
  }

  const renderPresentationLogHeader = () => {
    let presentationLogHeader = `${capitalize(renderData.level)}:`;
    presentationLogHeader += ` (${startCase(renderData.type)})`;
    return presentationLogHeader;
  };

  const renderMoreDetails = () => {
    return (
      <details>
        <summary></summary>
        <ul>
          {map(Object.entries(renderData.details), ([key, value], index) => {
            if (value && typeof value === 'object') {
              value = JSON.stringify(value, null, 1);
            }
            if (renderData.level == Constants.LOG_LEVEL.info) {
              if (key == 'extra') {
                key = 'Query Time';
                value = value.toFixed(2) + ' Seconds';
              } else if (key == 'value') {
                key = 'ID';
              }
            }
            return <li key={index}>{`${key}: ${value}`}</li>;
          })}
        </ul>
      </details>
    );
  };

  return (
    <div className="presentation-log">
      <div className={`icon-wrapper ${lowerCase(renderData.level)}`}>{icon}</div>
      <div className="presentation-log-details">
        <div>{renderPresentationLogHeader()}</div>
        <div>
          {renderData.message}{' '}
          {renderData.type == 'query_done' && (
            <OpenInNewTabIcon linkTo={`/dynamic_content/${renderData.details?.value}`} />
          )}
        </div>
        {Object.keys(renderData.details).length > 0 && renderMoreDetails()}
      </div>
    </div>
  );
}

PresentationLog.propTypes = {
  renderData: PropTypes.object,
  templateSlideNumBySlideId: PropTypes.object,
};

export default PresentationLog;
