import React from 'react';
import PropTypes from 'prop-types';

import Constants from 'components/Constants';

import IconPill from '../../lib/IconPill';

const InputIcon = ({ input }) => {
  let IconSvg;
  switch (input.source_type) {
    case Constants.InputSources.QUERY:
      IconSvg = <IconPill iconName="query" size="s" theme="square" />;
      break;
    case Constants.InputSources.LIST:
      IconSvg = <IconPill iconName="list" size="s" theme="square" />;
      break;
    case Constants.InputSources.API:
      IconSvg = <IconPill iconName="api" size="s" theme="square" />;
      break;
    default:
      IconSvg = <IconPill iconName="user_input" size="s" theme="square" />;
  }

  return IconSvg;
};
InputIcon.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    source_type: PropTypes.string.isRequired,
  }).isRequired,
};

export default InputIcon;
