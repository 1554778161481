import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import InputForm from './InputForm';
import utils from '../../../lib/utils';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';

function InputModal(props) {
  const { dataSources, existingInputs, input, onClose, onInputAdd, onInputUpdate, ui } = props;

  const handleInputUpdate = (updatedInput) => {
    onInputUpdate(updatedInput);
    onClose();
    utils.notify('Input updated');
  };

  const handleInputAdd = (addedInput) => {
    onInputAdd(addedInput);
    onClose();
  };

  const formSaveButtonText = input && input.isNew ? 'Add' : 'Update';
  return (
    <Modal
      show={props.ui.modal?.name === 'inputModal'}
      title={props.ui.modal?.content?.name}
      onClose={props.onClose}
      showDefaultFooter={false}
      width={615}
      isInputModal={true}
      linkTo={`/inputs/${props.ui.modal?.content?.id}`}
    >
      <InputForm
        dataSources={dataSources}
        entityId={`${input ? input.id : 'new'}`}
        entityType="input"
        existingInputs={existingInputs}
        hideHeader={true}
        input={ui.modal?.content}
        noBackground={true}
        onClose={onClose}
        onInputAdd={handleInputAdd}
        onInputUpdate={handleInputUpdate}
        saveButtonText={formSaveButtonText}
        showCancel={true}
      />
    </Modal>
  );
}

InputModal.propTypes = {
  show: PropTypes.bool,
  input: PropTypes.object,
  existingInputs: PropTypes.object,
  dataSources: PropTypes.array,
  onClose: PropTypes.func,
  onInputAdd: PropTypes.func,
  onInputUpdate: PropTypes.func,
  ui: PropTypes.object,
  title: PropTypes.string,
};

export default connect(mapUiStateToProps)(InputModal);
