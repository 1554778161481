import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';
import { useSelector } from 'react-redux';

function ConnectedItemsPopup(props) {
  const { children, togglePopup } = props;
  const ui = useSelector((state) => state.ui);

  const ref = useRef();

  const onOutsideClick = (e) => {
    if (!ui.modal) {
      togglePopup(e, null);
    }
  };

  useOnClickOutside(ref, onOutsideClick);

  return (
    <div ref={ref} className="box connected-items-popup">
      <div>{children}</div>
    </div>
  );
}

ConnectedItemsPopup.propTypes = {
  children: PropTypes.node,
  togglePopup: PropTypes.func,
};

export default ConnectedItemsPopup;
