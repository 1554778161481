import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'lib/utils';
import API from '../../lib/api';
import { Container } from 'react-bulma-components';
import { withRouter } from 'react-router';
import useTemplate from 'lib/hooks/useTemplate';
import Constants from 'components/Constants';
import { FullScreenModalFooter } from 'components/shared/modal/FullScreenModalFooter';

class BulkPresentationPreview extends Component {
  render() {
    const template = this.props.template;
    const presentation = this.props.selectPresentation(this.props.match.params.presentation_id);

    const templateText = utils.getTemplateText(template, presentation);
    const buttonAction = template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'Send ' : 'Generate ';
    return (
      <Container className="is-fluid has-text-centered h-lvh">
        <div>{this.props.children}</div>
        <FullScreenModalFooter
          primaryButtonText={buttonAction + utils.toTitleCase(templateText)}
          primaryButtonOnClick={this.onSubmitBulk}
          secondaryButtonText="Cancel"
          secondaryButtonOnClick={this.onCloseBulk}
        />
      </Container>
    );
  }

  onCloseBulk = (e) => {
    e.preventDefault();
    this.props.history.push(`${this.props.urlBase}${this.props.templateId}`);
  };

  onSubmitBulk = (e) => {
    e.preventDefault();
    const data = {};

    let bulk_presentation_id = this.props.bulkPresentationId;
    API.post(`/bulk_presentations/${bulk_presentation_id}/start/`, data, this.onSubmitSuccess, this.onSubmitError);
  };

  onSubmitSuccess = () => {
    this.setState({ isLoading: false });
    this.props.history.push({
      pathname: `${this.props.urlBase}${this.props.templateId}/bulk/${this.props.bulkPresentationId}/complete`,
      state: { presentationId: this.props.match.params.presentation_id },
    });
  };

  onSubmitError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };
}

BulkPresentationPreview.propTypes = {
  bulkPresentationId: PropTypes.string,
  children: PropTypes.any,
  history: PropTypes.object,
  templateId: PropTypes.string,
  isConsumer: PropTypes.bool,
  selectPresentation: PropTypes.func,
  match: PropTypes.any,
  urlBase: PropTypes.string,
  template: PropTypes.object,
};

const BulkPresentationPreviewWrapper = (props) => {
  const { data } = useTemplate(props.templateId);
  return <BulkPresentationPreview {...props} template={data} />;
};
BulkPresentationPreviewWrapper.propTypes = {
  templateId: PropTypes.string,
};

export default withRouter(BulkPresentationPreviewWrapper);
