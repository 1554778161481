import React, { Children } from 'react';
import PropTypes from 'prop-types';

const AssetItem = ({ bottom, children, className = '' }) => {
  return (
    <div
      className={`py-3 px-4 bg-matik-white hover:bg-grey-50 border border-grey-300 rounded flex flex-col gap-2.5 ${className}`}
    >
      <div className="flex items-center gap-2.5">
        {Children.map(children, (child) => (
          <div className="first:grow first:truncate">{child}</div>
        ))}
      </div>
      {bottom && <div>{bottom}</div>}
    </div>
  );
};
AssetItem.propTypes = {
  bottom: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
};
export default AssetItem;
