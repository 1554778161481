import React from 'react';
import PropTypes from 'prop-types';

import { uniq } from 'lodash';

import IconPill from '../../lib/IconPill';

const CONDITION_COLORS = ['blue', 'red', 'purple', 'green', 'yellow'];
const DEFAULT_COLOR = 'gray';

const ConditionIcon = ({ currentTemplate, condition, className, size = 'm' }) => {
  let color = DEFAULT_COLOR;
  if (currentTemplate) {
    const conditionIdsOnTemplate = uniq(
      currentTemplate.slides.map((slide) => slide.conditions[0]?.id).filter((id) => !!id),
    );
    conditionIdsOnTemplate.sort(); // sort to try to keep the color -> condition map resonably stable
    color =
      CONDITION_COLORS[
        (condition ? conditionIdsOnTemplate.indexOf(condition.id) : conditionIdsOnTemplate.length) %
          CONDITION_COLORS.length
      ];
  }

  return (
    <span className={`${className || ''}`}>
      <IconPill iconName="conditional" color={color} size={size} theme="circle" />
    </span>
  );
};
ConditionIcon.propTypes = {
  currentTemplate: PropTypes.shape({
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        conditions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
          }),
        ),
      }),
    ),
  }),
  condition: PropTypes.shape({
    id: PropTypes.number,
  }),
  className: PropTypes.string,
  size: IconPill.propTypes.size,
};

export default ConditionIcon;
