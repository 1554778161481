import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithLoadingAndEmpty from '../../shared/WithLoadingAndEmpty';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { Section, Hero, Container, Heading } from 'react-bulma-components';
import WithInstantSearch from '../../shared/search/WithInstantSearch';
import InputsSidebar from './InputsSidebar';
import InputsMain from './InputsMain';
import parameters_empty_icon from '../../../images/parameters_empty.png';

class InputsContent extends Component {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.path}/:id`} render={this.renderContent} />
        <Route path={this.props.match.path} render={this.renderEmptyContent} />
      </Switch>
    );
  }

  renderContent = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { match: matchToIgnore, ...rest } = this.props;
    return <InputsMain {...rest} match={props.match} />;
  };

  renderEmptyContent = () => {
    return (
      <div className="wrapper">
        <InputsSidebar {...this.props} />
        <div className="main input-form">
          <Section className="pll ptxl">
            <Hero className="is-fullheight-with-navbar">
              <Hero.Body>
                <Container className="has-text-centered">
                  <Heading size={5} subtitle>
                    <img width="256px" src={parameters_empty_icon} alt="Dynamic Content" />
                  </Heading>
                  <Heading size={4} subtitle>
                    Use inputs to customize dynamic content for your templates
                  </Heading>
                  <Link className="button is-primary is-size-5" to="/inputs/new" id="add-new-input" role="button">
                    Add Input
                  </Link>
                </Container>
              </Hero.Body>
            </Hero>
          </Section>
        </div>
      </div>
    );
  };
}

InputsContent.propTypes = {
  dataSources: PropTypes.array,
  fetchItems: PropTypes.func,
  match: PropTypes.any,
  onInputAdd: PropTypes.func,
  onInputUpdate: PropTypes.func,
  onInputDelete: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  inputs: PropTypes.array,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
};

export default withRouter(WithLoadingAndEmpty(WithInstantSearch(InputsContent)));
