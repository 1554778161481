import React from 'react';
import PropTypes from 'prop-types';
import { Select } from './FormSelect';

function SortDropdownSelector({ width, value, onChange, entityType, isDisabled }) {
  const sortOptions = {
    presentation: [
      { value: ['presentation_count', 'desc'], label: 'Popularity', id: 'presentations-most-used-by-enterprise' },
      { value: ['updated_on', 'desc'], label: 'Newest', id: 'presentations-updated-desc' },
      { value: ['updated_on', 'asc'], label: 'Oldest', id: 'presentations-updated-asc' },
      { value: ['name', 'asc'], label: 'Name A->Z', id: 'presntations-name-asc' },
      { value: ['name', 'desc'], label: 'Name Z->A', id: 'presentations-name-desc' },
      { value: ['slide_count', 'asc'], label: 'Slide Count Ascending', id: 'presentations-slide-count-asc' },
      { value: ['slide_count', 'desc'], label: 'Slide Count Descending', id: 'presentations-slide-count-desc' },
      { value: ['input_count', 'asc'], label: 'Input Count Ascending', id: 'presentations-input-count-asc' },
      { value: ['input_count', 'desc'], label: 'Input Count Descending', id: 'presentations-input-count-desc' },
    ],
    template: [
      {
        value: ['presentation_count', 'desc'],
        label: 'Most used by enterprise',
        id: 'templates-most-used-by-enterprise',
      },
      {
        value: ['presentation_count', 'asc'],
        label: 'Least used by enterprise',
        id: 'templates-least-used-by-enterprise',
      },
      {
        value: ['current_user_presentation_count', 'desc'],
        label: 'Most used by me',
        id: 'templates-most-used-by-user',
      },
      { value: ['updated_on', 'desc'], label: 'Recently created', id: 'templates-recently-created' },
      { value: ['name', 'asc'], label: 'Name A->Z', id: 'templates-name-asc' },
      { value: ['name', 'desc'], label: 'Name Z->A', id: 'templates-name-desc' },
    ],
  };

  const customStyles = {
    menu: (provided) => {
      return { ...provided, width: width !== undefined ? width : 140 };
    },
    control: (provided) => {
      return { ...provided, width: width !== undefined ? width : 140 };
    },
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#52606F',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      visibility: 'hidden',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#52606F',
    }),
  };

  const handleChange = (option) => {
    onChange(option.value);
  };

  const selected = value
    ? sortOptions[entityType].find((option) => option.value[0] === value[0] && option.value[1] === value[1])
    : null;

  return (
    <Select
      id="sort-dropdown"
      classNamePrefix="matik-select"
      className="sort-dropdown-selector"
      isSearchable={false}
      isDisabled={isDisabled}
      aria-label="Select Sort Order"
      value={selected}
      onChange={handleChange}
      options={sortOptions[entityType]}
      styles={customStyles}
      placeholder="Sort by"
    />
  );
}

SortDropdownSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  entityType: PropTypes.string,
};

export default SortDropdownSelector;
