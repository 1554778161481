import React from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';

/**
 * PowerBIForm is an authentication form that allows admins to specify both the
 * directory ID and the application ID of their Entra application with the
 * service principal that will be used to access Power BI resources.
 */
function PowerBIForm(props) {
  const directoryID = props.auth?.directory_id ?? '';
  const applicationID = props.auth?.application_id ?? '';

  return (
    <React.Fragment>
      <Form.Field>
        <Form.Label htmlFor="directory-id">Directory (tenant) ID</Form.Label>
        <Form.Control>
          <Form.Input
            id="directory-id"
            data-testid="directory-id"
            name="directory_id"
            value={directoryID}
            onChange={props.onAuthChange}
            placeholder="Found in Microsoft Entra → App registrations → Directory (tenant) ID"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="application-id">Application (client) ID</Form.Label>
        <Form.Control>
          <Form.Input
            id="application-id"
            data-testid="application-id"
            name="application_id"
            value={applicationID}
            onChange={props.onAuthChange}
            placeholder="Found in Microsoft Entra → App registrations → Application (client) ID"
          />
        </Form.Control>
      </Form.Field>
    </React.Fragment>
  );
}

PowerBIForm.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
};

export default PowerBIForm;
