import React, { useEffect, useState } from 'react';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { Form, Heading } from 'react-bulma-components';
import PropTypes from 'prop-types';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import Constants from 'components/Constants';
import HowTo from '../../shared/HowTo';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';

function EmailSubjectModal(props) {
  const [subject, setSubject] = useState(props.subject);
  const [subjectLength, setSubjectLength] = useState(props.subject.length);
  const [primaryButtonText, setPrimaryButtonText] = useState('');
  const { dynamicContentById } = useAllDynamicContentById();

  useEffect(() => {
    const buttonText = props.subject ? 'Edit Subject' : 'Add Subject';
    setPrimaryButtonText(buttonText);
  }, []);

  useEffect(() => {
    setSubject(props.subject);
  }, [props.subject]);

  const onUpdateSubjectSubmit = async (e) => {
    e.preventDefault();
    const updatedTemplate = { ...props.currentTemplate };
    updatedTemplate.source.subject = subject;
    // scan subject text for any new dc tags and add them
    const tagsArray = props.dynamicRecipientsName ? [props.dynamicRecipientsName] : [];
    const subjectText = subject;
    const subjectDynamicContentTags = scanForDcTags(subjectText);
    const htmlText = props.emailHtml;
    const htmlDynamicContentTags = scanForDcTags(htmlText);
    const newTags = tagsArray.concat(subjectDynamicContentTags, htmlDynamicContentTags);

    updatedTemplate.newTags = newTags;
    await props.onTemplateUpdateWithServerCall(updatedTemplate);
    props.onClose();
  };

  const onChange = (value) => {
    setSubject(value);
    setSubjectLength(value.length);
    props.onChange(value);
  };

  const scanForDcTags = (text) => {
    const dynamicContentTags = text
      .split('{{')
      .filter((substringWithDcTag) => substringWithDcTag.indexOf('}}') > -1 && substringWithDcTag.indexOf('{') !== 0)
      .map((bracketedDynamicContent) => bracketedDynamicContent.split('}}')[0]);

    return dynamicContentTags.filter((tag) => tag !== '');
  };

  const dynamicContentList = Object.values(dynamicContentById || {});
  const textOnlyDynamicContent = dynamicContentList
    ? dynamicContentList.filter((dc) => dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT)
    : [];

  const wrappedDynamicContentTags = textOnlyDynamicContent.map((dc) => dc.name + '}}');

  const dynamicContentOptions = { '{{': wrappedDynamicContentTags };

  const headerCenter = <Heading className="full-screen-modal-title">{primaryButtonText}</Heading>;

  const primaryButtonDisabled =
    props.currentTemplate.source.subject === subject || (!props.currentTemplate.source.subject && !subject.length);

  return (
    <FullScreenModal
      show={props.show}
      headerCenter={headerCenter}
      showDefaultFooter={true}
      backgroundColor="white"
      onClose={props.onClose}
      primaryButtonText={primaryButtonText}
      primaryButtonOnClick={onUpdateSubjectSubmit}
      primaryButtonDisabled={primaryButtonDisabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={props.onClose}
    >
      <div className="three-column-modal-body">
        <div className="modal-sidebar invisible"></div>
        <div className="modal-body-center">
          <Form.Field>
            <Form.Label>Subject</Form.Label>
            <Form.Control>
              <TextInput
                className="input subject-modal"
                name="subject"
                type="text"
                placeholder="Enter Subject"
                value={subject}
                onChange={onChange}
                options={dynamicContentOptions}
                trigger={['{{']}
                maxLength="255"
              />
              <div className="input-char-counter">{`${subjectLength}/255`}</div>
            </Form.Control>
          </Form.Field>
          {/* TODO (Steph): figure out preview text */}
          {/* <Form.Field>
            <Form.Label>Custom Preview Text</Form.Label>
            <p className="form-info-text">Preview text appears in the inbox after the subject line.</p>
            <Form.Control>
              <Form.Input
                name="previewText"
                type="text"
                placeholder="Enter Custom Preview Text"
                value={previewText}
                onChange={onChange}
              ></Form.Input>
            </Form.Control>
          </Form.Field> */}
        </div>
        <div className="modal-sidebar">
          <HowTo>
            <p className="how-to-heading">HOW TO</p>
            <p className="how-to-text">
              {
                'To add dynamic content to your subject, type in the name of the dynamic content within {{}}. For example, {{account_name}}.'
              }
            </p>
            <a
              href="https://help.matik.io/hc/en-us/articles/13090747609627"
              target="_blank"
              rel="noreferrer"
              className="how-to-text link"
            >
              Help Center Article
            </a>
          </HowTo>
        </div>
      </div>
    </FullScreenModal>
  );
}

EmailSubjectModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  currentTemplate: PropTypes.object,
  subject: PropTypes.string,
  previewText: PropTypes.string,
  onTemplateUpdateWithServerCall: PropTypes.func,
  onChange: PropTypes.func,
  dynamicRecipientsName: PropTypes.string,
  emailHtml: PropTypes.string,
  dynamicContent: PropTypes.array,
};

export default EmailSubjectModal;
