import React, { Component } from 'react';
import { UserContext } from '../../UserContext';
import { Navbar } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import Constants from '../../Constants';
import Pluralize from 'pluralize';
import Icon from 'components/lib/Icon';

class ProducerNavPlanInfo extends Component {
  render() {
    let rocketComponent = <Icon name="rocket" size={20} theme="regular" color="green-500" />;
    let planName = '';
    let planDesc = '';
    let planLink = (
      <Link to="/enterprise_settings" className="plan-dropdown-link">
        Plan Settings
      </Link>
    );

    if (this.context.user) {
      if (this.context.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id) {
        planName = Constants.MATIK_TIERS.matik_enterprise.display_name;
        planDesc = 'Your account is on the Enterprise plan';
      } else {
        if (this.context.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_team.tier_id) {
          planName = Constants.MATIK_TIERS.matik_team.display_name;
          planDesc = 'Your account is on the Team plan';
        } else {
          rocketComponent = <Icon name="rocket" size={20} theme="regular" />;
          planName = 'Free trial';
          planDesc = this.fetchFreeTrialDesc();
          planLink = (
            <Link to="/prices" className="plan-dropdown-link">
              Upgrade plan
            </Link>
          );
        }
      }
    }

    return (
      <Navbar.Item renderAs="span" className="pts pbs user-dropdown border-bottom">
        <div className="user-dropdown-icon">{rocketComponent}</div>
        <div className="pll pbm plan-info vertical-center">
          <span className="user-dropdown-plan">{planName}</span>
          <span>{planDesc}</span>
          <span>{planLink}</span>
        </div>
      </Navbar.Item>
    );
  }

  fetchFreeTrialDesc = () => {
    let daysLeftClass = '';
    if (this.context.user.enterprise.trial_days_remaining >= 1) {
      daysLeftClass = 'positive-days-left';
    } else {
      daysLeftClass = 'warning-days-left';
    }
    return (
      <span className={daysLeftClass}>
        {Pluralize('day', this.context.user.enterprise.trial_days_remaining, true)} left
      </span>
    );
  };
}

ProducerNavPlanInfo.propTypes = {};

ProducerNavPlanInfo.contextType = UserContext;

export default ProducerNavPlanInfo;
