import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container, Hero, Heading } from 'react-bulma-components';
import utils from 'lib/utils';
import presentation_pack_icon from '../../images/presentation_pack.png';
import Button from '../lib/Button';
import useTemplate from 'lib/hooks/useTemplate';
function ScheduledPresentationComplete({ presentationId, templateId, selectPresentation, urlBase }) {
  const { data: template } = useTemplate(templateId);
  const history = useHistory();
  const presentation = selectPresentation(presentationId);
  const templateText = utils.getTemplateText(template, presentation);

  const onBackClick = (e) => {
    e.preventDefault();
    history.push(`${urlBase}${templateId}`);
  };
  return (
    <Container className="is-fluid">
      <Hero className="is-fullheight-with-navbar">
        <Hero.Body>
          <Container className="has-text-centered narrow-container">
            <Heading size={5} subtitle>
              <img width="256px" src={presentation_pack_icon} alt="Scheduled Generation" />
            </Heading>
            <Heading size={4}>{`Your ${templateText} are scheduled!`}</Heading>
            <Heading size={5} subtitle>
              {`You will receive an email when we've generated the scheduled ${templateText}.`}
            </Heading>
            <span className="flex justify-center">
              <Button onClick={onBackClick}>Back to template</Button>
            </span>
          </Container>
        </Hero.Body>
      </Hero>
    </Container>
  );
}

ScheduledPresentationComplete.propTypes = {
  presentationId: PropTypes.object,
  templateId: PropTypes.string,
  selectPresentation: PropTypes.func,
  urlBase: PropTypes.string,
};

export default ScheduledPresentationComplete;
