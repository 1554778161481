import React from 'react';
import PropTypes from 'prop-types';
import SortableEntityGrid from '../../shared/SortableEntityGrid';

function ModalTemplateLibrary({ noAccessTemplateCount }) {
  return (
    <div className="is-fullwidth">
      <div className="mbs mtxxxl is-4">Choose Template From Matik Library</div>
      <div className="template-library-list">
        <SortableEntityGrid
          entitiesToRender={null}
          entityType="template"
          isSearching={false}
          pagination={{ numberOfPages: 1 }}
          templateBaseUrl="/templates/"
          noAccessEntityCount={noAccessTemplateCount}
          showFavorites={false}
          isTemplateLibrary={true}
        />
      </div>
    </div>
  );
}

ModalTemplateLibrary.propTypes = {
  noAccessTemplateCount: PropTypes.number,
};

export default ModalTemplateLibrary;
