import React, { Component } from 'react';
import Confetti from 'react-confetti';
import logo from '../../images/logo.svg';

class MatikConfetti extends Component {
  render() {
    return (
      <Confetti
        drawShape={this.drawShape}
        numberOfPieces={50}
        colors={['#51B4FF', '#20DFAC', '#00BD4C', '#C770F6', '#FFA800']}
        recycle={false}
      />
    );
  }

  drawShape = (context) => {
    const image = new Image(100, 100);
    image.src = logo; // eslint-disable-line
    context.drawImage(image, 0, 0);
  };
}

MatikConfetti.propTypes = {};

export default MatikConfetti;
