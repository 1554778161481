import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';
import TextboxWithEmbeddedIcons from 'components/lib/TextboxWithEmbeddedIcons';
import utils from 'lib/utils';
import Constants from 'components/Constants';

const ExcelForm = (props) => {
  let fileName = '';
  let canRemoveFile = false;
  let fileIsFromOffice365 = false;

  if (props.selectedDataSource) {
    let host;
    if (props.excelFile) {
      canRemoveFile = true;
      fileName = props.excelFile?.name;
    } else if (props.host) {
      if (typeof props.host === 'string') {
        host = JSON.parse(props.host);
      } else {
        host = props.host;
      }
      canRemoveFile = true;
      fileName = host?.name;
      fileIsFromOffice365 = host?.type === Constants.MICROSOFT.excel_type;
    }
  } else {
    if (props.excelFile) {
      canRemoveFile = true;
      fileName = props.excelFile?.name;
    }
  }

  const handleExcelDownloadButton = (e) => {
    if (fileIsFromOffice365) {
      e?.preventDefault?.();
      utils.openExcelFileInNewTab(e, props.selectedDataSource);
    } else {
      utils.downloadExcelFile(e, props.selectedDataSource);
    }
  };

  const handle365SelectFile = (data) => {
    const item = data.items[0];
    const file = {
      name: item.name,
      file: JSON.stringify({
        type: Constants.MICROSOFT.excel_type,
        filename: item.name,
        content_type: 'application/vnd.ms-excel',
        key: item.id,
        file: item.name,
        extension: item.name.split('.').pop(),
        length: item.size,
        reproducible: false,
        timestamp: Date.now(),
        name: item.name.split('.').shift(),
        url: item.webUrl,
        driveItemUrl: item.webDavUrl,
        driveId: item.parentReference?.driveId,
      }),
    };
    // mimic event of excel file select
    props.onFileSelected({ target: { files: [file] } });
  };

  const rightIcon = <Icon name="dismiss" size={12} />;

  return (
    <>
      {!fileName && (
        <Form.Field className="mvl is-flex">
          <MicrosoftPicker
            className={'mrm gap-2'}
            onPicked={handle365SelectFile}
            pickerMode="files"
            filters={['.xls', '.xlsx']}
          />
          <Form.Control>
            <Form.Field className="has-addons">
              <Form.Control>
                <label className="button is-secondary is-flex items-center gap-2 py-2">
                  <Icon name="arrow_upload" size={20} theme="regular" />
                  <span>Upload</span>
                  <input
                    className="template-file-picker-input"
                    type="file"
                    name="file"
                    onInput={props.onFileSelected}
                    accept=".xls,.xlsx"
                  />
                </label>
                <span className={props.fileError ? 'help is-danger' : ''}>{props.fileError}</span>
              </Form.Control>
            </Form.Field>
          </Form.Control>
        </Form.Field>
      )}
      {fileName && (
        <div className={`is-flex gap-2 ${props.isNew && 'min-w-full'}`}>
          <TextboxWithEmbeddedIcons
            textboxLabel="Excel File"
            iconName="excel"
            rightIcon={rightIcon}
            rightIconClickHandler={props.removeFile}
            rightIconDisplayCondition={canRemoveFile}
            textboxValue={fileName}
            readOnly={true}
          />
          <div className={`${props.isNew ? 'hidden' : 'is-flex self-end'}`}>
            <Button category="secondary" onClick={handleExcelDownloadButton}>
              <span className="p-px flex">
                <Icon name={fileIsFromOffice365 ? 'open' : 'arrow_download'} size={20} theme="regular" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

ExcelForm.propTypes = {
  selectedDataSource: PropTypes.object,
  host: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  excelFile: PropTypes.object,
  onFileSelected: PropTypes.func,
  fileError: PropTypes.string,
  removeFile: PropTypes.func,
  isNew: PropTypes.bool,
};

export default ExcelForm;
