import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Hero } from 'react-bulma-components';
import WithLoadingAndEmpty from '../../shared/WithLoadingAndEmpty';
import { Switch, Route } from 'react-router';
import WithInstantSearch from '../../shared/search/WithInstantSearch';
import DataSourcesMain from './DataSourcesMain';
import DataSourcesSidebar from './DataSourcesSidebar';
import DataSourcesGrid from './DataSourcesGrid';
import { Link, withRouter } from 'react-router-dom';

class DataSourcesContent extends Component {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.path}/:id`} render={this.renderDataSources} />
        <Route exact path={this.props.match.path} render={this.renderDataSourcesLandingPage} />
      </Switch>
    );
  }
  renderDataSources = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { match: matchToIgnore, ...rest } = this.props;
    return <DataSourcesMain {...rest} match={props.match} />;
  };

  renderDataSourcesLandingPage = () => (
    <div className="wrapper">
      <DataSourcesSidebar {...this.props} />
      <div className="main">
        <Hero className="is-fullheight-with-navbar">
          <Hero.Body>
            <div className="data-source-content">
              <div className="data-source-header-button">
                <div>Connect new Data Source</div>
                <Link className="button is-primary" to="/data_sources/new">
                  Connect Data Source
                </Link>
              </div>
              <DataSourcesGrid
                {...this.props}
                onClick={this.navigateToNewPopulatedDataSourcesForm}
                shouldAddFirst={false}
              />
            </div>
          </Hero.Body>
        </Hero>
      </div>
    </div>
  );

  navigateToNewPopulatedDataSourcesForm = (datasource) => {
    const URL = `/data_sources/new?type=${datasource}`;
    this.props.history.push(URL);
  };
}

DataSourcesContent.propTypes = {
  dataSources: PropTypes.array,
  deleteDataSource: PropTypes.func,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  onDataSourceAdd: PropTypes.func,
  onNewDataSourceAdd: PropTypes.func,
  onDataSourceSelect: PropTypes.func,
  onDataSourceUpdate: PropTypes.func,
  match: PropTypes.any,
  notification: PropTypes.string,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedDataSource: PropTypes.object,
  history: PropTypes.object,
  emptyState: PropTypes.func,
};

export default withRouter(WithLoadingAndEmpty(WithInstantSearch(DataSourcesContent)));
