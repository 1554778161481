import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import OAuthAuth from './OAuthAuth';
import PasswordAuth from './PasswordAuth';
import AuthTabs from '../AuthTabs';

function SnowflakeAuth({ auth, centered, checkboxes, clearSSHKey, isOAuth, onAuthChange, setIsOAuth }) {
  const checkbox = checkboxes?.[1];
  const SSHKey = auth?.ssh_key ?? '';
  const SSHKeyPassword = auth?.ssh_key_password ?? '';
  const username = auth?.username ?? '';

  const tabs = [
    { name: 'login-tab', title: 'Access Via Login' },
    { name: 'auth-tab', title: 'Access Via Key Pair' },
    { name: 'oauth-tab', title: 'Access Via OAuth2' },
  ];

  const [activeAuthTab, setActiveAuthTab] = useState(tabs[SSHKey ? 1 : isOAuth ? 2 : 0].name);

  const setAuthTabWithMethod = (currentTab) => {
    if (currentTab === tabs[2].name) {
      setIsOAuth(true);
    } else {
      setIsOAuth(false);
    }
    setActiveAuthTab(currentTab);
  };

  const handleClick = (e) => {
    e.preventDefault();
    clearSSHKey();
  };

  return (
    <>
      <AuthTabs setActiveAuthTab={setAuthTabWithMethod} tabs={tabs} activeAuthTab={activeAuthTab} centered={centered} />
      {activeAuthTab === tabs[0].name && (
        <PasswordAuth
          auth={auth}
          checkboxes={checkboxes}
          onAuthChange={onAuthChange}
          usernameHelp="Username for your Snowflake Account"
          usernameLabel="Snowflake Username"
          usernamePlaceholder="Snowflake Username"
          passwordHelp="Password for your Snowflake Account"
          passwordLabel="Snowflake Password"
          passwordPlaceholder="Snowflake Password"
        />
      )}
      {activeAuthTab === tabs[1].name && (
        <Box className="data-source-box">
          {checkbox}
          <Form.Field>
            <Form.Label htmlFor="db-username">Snowflake Username</Form.Label>
            <Form.Help>Username for your Snowflake Account</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Snowflake Username"
                type="text"
                name="username"
                onChange={onAuthChange}
                value={username}
                autoComplete="off"
                id="db-username"
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label htmlFor="ssh-private-key">Private Key</Form.Label>
            {SSHKey && (
              <>
                <span className="has-text-success prs">Private Key Added</span>
                <a href="/#" onClick={(e) => handleClick(e)} className="is-text">
                  Change
                </a>
                <Form.Help>We&apos;ll never expose your private key once it&apos;s added</Form.Help>
              </>
            )}
            <Form.Control>
              {!SSHKey && (
                <>
                  <Form.Help>
                    Enter private key including delimiters. We&apos;ll never expose your private key once it&apos;s
                    added
                  </Form.Help>
                  <Form.Control>
                    <Form.Textarea
                      placeholder="Paste private key here to authenticate key pair connection"
                      type="text"
                      name="ssh_key"
                      onChange={onAuthChange}
                      value={SSHKey}
                      autoComplete="off"
                      id="ssh-private-key"
                    />
                  </Form.Control>
                </>
              )}
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label htmlFor="db-password">Private Key Encryption Password</Form.Label>
            <Form.Help>If your private key is encrypted, enter the private key encryption password</Form.Help>
            <Form.Control>
              <Form.Input
                placeholder="Private Key Encryption Password"
                type="password"
                name="ssh_key_password"
                onChange={onAuthChange}
                value={SSHKeyPassword}
                autoComplete="off"
                id="db-password"
              />
            </Form.Control>
          </Form.Field>
        </Box>
      )}
      {activeAuthTab === tabs[2].name && <OAuthAuth auth={auth} onAuthChange={onAuthChange} checkboxes={checkboxes} />}
    </>
  );
}

SnowflakeAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  checkboxes: PropTypes.array,
  clearSSHKey: PropTypes.func,
  isOAuth: PropTypes.bool,
  onAuthChange: PropTypes.func,
  setIsOAuth: PropTypes.func,
  type: PropTypes.string,
};

export default SnowflakeAuth;
