import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { CreatableSelect } from './FormSelect';
import API from '../../lib/api';
import { find, remove } from 'lodash';

class ItemTagMultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      existingItemTags: this.props.itemTags ? this.props.itemTags : [],
      availableItemTags: [],
    };
  }

  componentDidMount() {
    API.get(
      '/item_tags/enterprise/',
      (response) => {
        this.setState({ availableItemTags: response.data.tags, isLoading: false });
      },
      API.defaultError,
    );
  }

  formatItemTags = (itemTags) => {
    if (!itemTags) {
      return [];
    }
    return itemTags.map((tag) => ({ label: tag.tag, value: tag.id, tag: tag.tag, id: tag.id }));
  };

  render() {
    return (
      <Form.Field>
        <Form.Control>
          <CreatableSelect
            onChange={this.onTagSelect}
            value={this.formatItemTags(this.state.existingItemTags)}
            classNamePrefix="matik-select"
            aria-label="Tags"
            placeholder="Select Tags"
            options={this.formatItemTags(this.state.availableItemTags)}
            isMulti={true}
            isSearchable={true}
            isLoading={this.state.isLoading}
            isDisabled={this.props.disabled}
            menuPortalTarget={this.props.formRef?.current}
          />
        </Form.Control>
      </Form.Field>
    );
  }

  onTagSelect = (obj, action) => {
    const updatedTags = this.state.existingItemTags.slice();
    if (action.action === 'select-option') {
      updatedTags.push(action.option);
      this.setState({ existingItemTags: updatedTags });
      this.props.updateItemTags({ itemTags: updatedTags });
    } else if (action.action === 'select-all-options') {
      this.setState({ existingItemTags: action.option });
      this.props.updateItemTags({ itemTags: action.option });
    } else if (action.action === 'remove-value') {
      remove(updatedTags, (el) => {
        return action.removedValue.value === el.id;
      });
      this.setState({ existingItemTags: updatedTags });
      this.props.updateItemTags({ itemTags: updatedTags });
    } else if (action.action === 'clear') {
      this.setState({ existingItemTags: [] });
      this.props.updateItemTags({ itemTags: [] });
    } else if (action.action === 'create-option') {
      const newTag = find(obj, (el) => !!el.__isNew__);
      updatedTags.push({ tag: newTag.value });
      this.setState({ existingItemTags: updatedTags });
      this.props.updateItemTags({ itemTags: updatedTags });
    }
  };
}

ItemTagMultiSelect.propTypes = {
  itemTags: PropTypes.array,
  updateItemTags: PropTypes.func,
  disabled: PropTypes.bool,
  formRef: PropTypes.object,
};

export default ItemTagMultiSelect;
