import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@craftjs/core';
import EmailBody from './htmlBuilder/html_builder_components/EmailBody';
import EmailContainer from './htmlBuilder/html_builder_components/EmailContainer';
import ColumnContainer from './htmlBuilder/html_builder_components/ColumnContainer';
import ColumnModule from 'components/shared/htmlBuilder/html_builder_components/ColumnModule';
import Column from 'components/shared/htmlBuilder/html_builder_components/Column';
import DraggableButton from 'components/shared/htmlBuilder/html_builder_components/DraggableButton';
import DraggableText from 'components/shared/htmlBuilder/html_builder_components/DraggableText';
import Chart from './htmlBuilder/html_builder_components/Chart';
import Divider from './htmlBuilder/html_builder_components/Divider';
import Image from './htmlBuilder/html_builder_components/Image';
import Table from './htmlBuilder/html_builder_components/Table';
import HtmlBuilderEmptyState from './htmlBuilder/html_builder_components/HtmlBuilderEmptyState';
import htmlUtils from 'lib/html';
import { isEmpty, isEqual } from 'lodash';

function withHtmlBuilder(WrappedComponent) {
  return class Inner extends Component {
    static propTypes = {
      currentTemplate: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.state = {
        queryJson: this.props.currentTemplate?.source?.json_nodes || '',
      };
    }

    render() {
      return (
        <Editor
          resolver={{
            Chart,
            ColumnContainer,
            Column,
            EmailBody,
            EmailContainer,
            ColumnModule,
            DraggableButton,
            DraggableText,
            Divider,
            Image,
            Table,
            HtmlBuilderEmptyState,
          }}
          onNodesChange={(query) => this.onEditorChange(query)}
          indicator={{
            success: '#20DFAC',
            error: '#DF3C71',
          }}
        >
          <WrappedComponent {...this.props} saveEditorQuery={this.saveEditorQuery} jsonNodes={this.state.queryJson} />
        </Editor>
      );
    }

    onEditorChange(query) {
      if (query.serialize) {
        const jsonNodes = query.serialize();
        if (!isEmpty(jsonNodes) && !isEqual(jsonNodes, this.props.currentTemplate?.source?.json_nodes)) {
          this.setState({ queryJson: jsonNodes });
        }
      }
    }

    async saveEditorQuery(queryJson, currentTemplate, updateTemplate, actions) {
      if (!isEmpty(queryJson)) {
        const htmlString = htmlUtils.convertJsonNodesToHtmlString(queryJson);
        // Update the template with new html and JSON output
        const updatedTemplate = await htmlUtils.saveHtml(
          htmlString,
          currentTemplate,
          currentTemplate?.source?.subject,
          queryJson,
        );
        if (!isEmpty(updatedTemplate)) {
          updateTemplate(updatedTemplate, false, true);
          actions.history.clear();
        }
      }
    }
  };
}

export default withHtmlBuilder;
