import React, { useContext, useEffect, useState } from 'react';
import { Level, Columns } from 'react-bulma-components';
import { UserContext } from 'components/UserContext';
import { ReactComponent as Palette } from 'svg/palette.svg';
import { ReactComponent as TextDescriptionIcon } from 'svg/text_description.svg';
import PropTypes from 'prop-types';
import BrandingForm from '../../shared/templateBuilder/BrandingForm';
import API from '../../../lib/api';
import { cloneDeep } from 'lodash';

const TemplateLibraryModalTabs = (props) => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [brandingInfo, setBrandingInfo] = useState({});
  const { user, updateUser } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setBrandingInfo(user.enterprise.enterprise_settings.branding_info || {});
    }
  }, [user]);

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab('tab1');
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab('tab2');
  };

  const onBrandingInfoChange = (updatedBrandingInfo) => {
    setBrandingInfo(updatedBrandingInfo);
    API.put('/enterprises/current/', { branding_info: updatedBrandingInfo }, () => {
      const copyUser = cloneDeep(user);
      copyUser.enterprise.enterprise_settings.branding_info = updatedBrandingInfo;
      updateUser(copyUser);
    });
  };

  return (
    <div className="Tabs">
      {/* Tab nav */}
      <Columns>
        <Columns.Column size="one-third">
          <ul className="nav">
            <li className={activeTab === 'tab1' ? 'active' : ''} onClick={handleTab1}>
              <TextDescriptionIcon className="mrs" />
              Template Info
            </li>
            <li className={activeTab === 'tab2' ? 'active' : ''} onClick={handleTab2}>
              <Palette className="mrs" />
              Setup Branding
            </li>
          </ul>
        </Columns.Column>

        <Columns.Column size="two-thirds">
          <div className="outlet">
            {activeTab == 'tab1' ? (
              <Level className="level-item has-text-left ptm">
                <p className="template-library-description template-info-text-body">{props.templateDescription}</p>
              </Level>
            ) : (
              <div className="SecondTab">
                <BrandingForm
                  darkColorDescription={props.accentOneDesc}
                  accentColorDescription={props.accentTwoDesc}
                  onBrandingInfoChange={onBrandingInfoChange}
                  brandingInfo={brandingInfo}
                />
              </div>
            )}
          </div>
        </Columns.Column>
      </Columns>
    </div>
  );
};

TemplateLibraryModalTabs.propTypes = {
  templateDescription: PropTypes.string,
  accentOneDesc: PropTypes.string,
  accentTwoDesc: PropTypes.string,
};

export default TemplateLibraryModalTabs;
