import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { ReactComponent as Checkbox } from '../../../../svg/checkmark.svg';
import CheckboxWithLabel from '../../../shared/CheckboxWithLabel';

class SSHTunnelForm extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Field>
          <CheckboxWithLabel
            checked={this.props.showSSHTunnel}
            id="show_ssh_tunnel"
            label="Access via SSH tunnel"
            name="showSSHTunnel"
            onChange={this.props.onChange}
          />
        </Form.Field>
        {this.props.showSSHTunnel && this.renderSSHTunnelInfo()}
      </React.Fragment>
    );
  }

  renderSSHTunnelInfo() {
    const SSHKey = this.props.auth ? this.props.auth['ssh_key'] : '';
    const SSHHost = this.props.auth ? this.props.auth['ssh_host'] : '';
    const SSHPort = this.props.auth ? this.props.auth['ssh_port'] : '';
    const SSHUsername = this.props.auth ? this.props.auth['ssh_username'] : '';
    return (
      <div className="mbl">
        <Form.Help>
          To connect to a data source via an SSH Tunnel, enter the host url and port of the ssh server below and the url
          and port of the data source server above in the host URL and Host Port fields.
        </Form.Help>
        <Form.Field>
          <Form.Label htmlFor="ssh-host">SSH Host</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Host on which the ssh server is running"
              type="text"
              name="ssh_host"
              onChange={this.props.onAuthChange}
              value={SSHHost}
              id="ssh-host"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="ssh-port">SSH Port</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Port on which the ssh server is running"
              type="text"
              name="ssh_port"
              onChange={this.props.onAuthChange}
              value={SSHPort}
              autoComplete="off"
              id="ssh-port"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="ssh-username">SSH Username</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Username for SSH"
              type="text"
              name="ssh_username"
              id="ssh-username"
              onChange={this.props.onAuthChange}
              value={SSHUsername}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="ssh-private-key">SSH Private Key</Form.Label>
          {this.props.selectedDataSource && this.props.selectedDataSource.auth_info.ssh_key && SSHKey && (
            <>
              <Checkbox className="has-text-success" />
              <span className="has-text-success prs">&nbsp;&nbsp;Private Key Added</span>
              <a href="/#" onClick={(e) => this.handleClick(e)} className="is-text">
                Change
              </a>
              <Form.Help>We&apos;ll never expose your private key once it&apos;s added</Form.Help>
            </>
          )}
          <Form.Control>
            {(!this.props.selectedDataSource ||
              !this.props.selectedDataSource.auth_info.ssh_key ||
              !SSHKey ||
              SSHKey !== this.props.selectedDataSource.auth_info.ssh_key) && (
              <Form.Textarea
                placeholder="Paste private key here to authenticate ssh connection"
                type="text"
                name="ssh_key"
                onChange={this.props.onAuthChange}
                value={SSHKey}
                autoComplete="off"
                id="ssh-private-key"
              />
            )}
          </Form.Control>
        </Form.Field>
      </div>
    );
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.clearSSHKey();
  };
}

SSHTunnelForm.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  onChange: PropTypes.func,
  showSSHTunnel: PropTypes.bool,
  selectedDataSource: PropTypes.object,
  clearSSHKey: PropTypes.func,
};

export default SSHTunnelForm;
