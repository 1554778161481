import React, { Component } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import utils from '../../lib/utils';
import Constants from '../Constants';
import AccessAvatarsWithShareButton from './AccessAvatarsWithShareButton';

class DescriptionOverlay extends Component {
  render() {
    let description = this.props.template.description ? this.props.template.description : 'No description';
    let html;
    if (utils.isValidJSON(description)) {
      const descJSON = utils.removeCopiedFontStylesFromWYSIWYGOutput(JSON.parse(description));
      if (
        descJSON.blocks &&
        descJSON.blocks.length > 0 &&
        descJSON.blocks[0].text.length > Constants.TEMPLATE_CARD_DESCRIPTION_LENGTH
      ) {
        descJSON.blocks[0].text = utils.truncateString(
          descJSON.blocks[0].text,
          Constants.TEMPLATE_CARD_DESCRIPTION_LENGTH,
        );
      }
      html = parse(draftToHtml(descJSON));
    } else {
      if (description.length > Constants.TEMPLATE_CARD_DESCRIPTION_LENGTH) {
        description = utils.truncateString(description, Constants.TEMPLATE_CARD_DESCRIPTION_LENGTH);
      }
      html = description;
    }

    return (
      <div className="description-overlay">
        <span className="description">
          {html}
          {this.props.accessesByTemplateId && !this.props.template.deleted && (
            <div className="card-multi-avatar">
              <AccessAvatarsWithShareButton
                template={this.props.template}
                accessesByTemplateId={this.props.accessesByTemplateId}
                showShareButton={false}
                showTemplateCardShareButton={true}
              />
            </div>
          )}
        </span>
        {this.props.children}
      </div>
    );
  }
}

DescriptionOverlay.propTypes = {
  children: PropTypes.any,
  template: PropTypes.object,
  accessesByTemplateId: PropTypes.object,
  accessObj: PropTypes.object,
  onManageAccessClick: PropTypes.func,
};

export default DescriptionOverlay;
