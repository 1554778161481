import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import ListItemCondition from './ListItemCondition';

const ConditionSelector = ({ conditions, conditionsInTemplate, onSelect }) => {
  const [filter, setFilter] = useState('');

  const filterCondition = (condition) => {
    if (!filter) {
      return true;
    }
    return (
      condition.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      condition.description?.toLowerCase()?.indexOf(filter.toLowerCase()) > -1
    );
  };

  return (
    <div className="max-h-screen flex flex-col">
      {(conditionsInTemplate?.length ?? 0) + (conditions?.length ?? 0) > 5 && (
        <div className="border-b border-grey-300 items-center text-grey-400 leading-none relative">
          <div className="absolute py-3 pl-4 pointer-events-none">
            <Icon name="search" size={16} />
          </div>
          <input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="w-full py-3 pl-9 rounded-md"
            placeholder="Search for conditions or tags"
          ></input>
        </div>
      )}
      <div className="overflow-y-auto">
        {conditionsInTemplate?.length > 0 && (
          <ConditionOptions
            title="Conditions in this Template"
            conditions={conditionsInTemplate.filter(filterCondition)}
            onSelect={onSelect}
          />
        )}
        {conditions?.length > 0 && (
          <ConditionOptions
            title="Global Conditions"
            conditions={conditions.filter(filterCondition)}
            onSelect={onSelect}
          />
        )}
      </div>
    </div>
  );
};

const ConditionPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
});

ConditionSelector.propTypes = {
  conditions: PropTypes.arrayOf(ConditionPropType),
  conditionsInTemplate: PropTypes.arrayOf(ConditionPropType),
  onSelect: PropTypes.func,
};

export default ConditionSelector;

const ConditionOptions = ({ title, conditions, onSelect }) => {
  return (
    <div className="border-b pb-1 border-grey-300 last-of-type:border-b-0">
      <div className="text-grey-500 text-xs px-4 pt-3 pb-1">{title}</div>
      {conditions?.map((condition) => (
        <button
          key={condition.id}
          onClick={() => onSelect(condition)}
          className="hover:bg-grey-50 px-4 py-1.5 block m-0 text-left w-full"
        >
          <ListItemCondition condition={condition} showIndicator showSubtitle />
        </button>
      ))}
    </div>
  );
};
ConditionOptions.propTypes = {
  title: PropTypes.node,
  conditions: PropTypes.arrayOf(ConditionPropType),
  onSelect: PropTypes.func,
};
