import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../Constants';
import { MConfirm } from './Alerts';
import { Select, MenuNoSearch } from './FormSelect';
import team_icon from '../../images/team.svg';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import ListItemPerson from 'components/lib/ListItemPerson';
import { useFlags } from 'launchdarkly-react-client-sdk';

function AccessElement({ itemType, itemIds, accessor, accesses, canEdit, onDelete, onUpdate }) {
  const flags = useFlags();

  const handlePermissionChange = (obj, action) => {
    if (action.action === 'select-option') {
      const permission = obj.value;
      let message = '';
      if (permission === Constants.PERMISSIONS.read.value) {
        message =
          "Are you sure you want to change this accessor's permission to read? " +
          `They will no longer be able to modify ${itemIds.length === 1 ? 'this item' : 'these items'}.`;
      } else if (permission === Constants.PERMISSIONS.owner.value) {
        message = 'Are you sure you want to change the owner to this user?';
      } else {
        message =
          "Are you sure you want to change this accessor's permission to edit? " +
          `They will be able to modify ${itemIds.length === 1 ? 'this item' : 'these items'}.`;
      }
      MConfirm('Update Access', message, 'warning', (confirmed) => {
        if (confirmed) {
          onUpdate(itemType, itemIds, permission, [{ accessor_type: accessor.type, accessor_id: accessor.id }]);
        }
      });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const confirmBody =
      'Are you sure you want to delete this access? This accessor will no longer have access to ' +
      (itemIds.length === 1 ? 'this item.' : 'these items.');
    MConfirm('Delete Access', confirmBody, 'warning', (confirmed) => {
      if (confirmed) {
        onDelete(accesses);
      }
    });
  };

  // Special rules for "All Admins" and "All End Users" groups.
  // Dynamic Content cannot revoke "All Admins" or "All End Users" access until we
  // roll out the data-source permissions project. After that, we can remove this rule.
  let canEditElement = canEdit;
  let canRemoveElement = canEdit;
  const isSpecialGroupAccess =
    accessor.type === 'group' &&
    (accessor.id === parseInt(Constants.ROLE_ID_MAPPING[Constants.CONSUMER_ROLE]) ||
      accessor.id === parseInt(Constants.ROLE_ID_MAPPING[Constants.PRODUCER_ROLE]));

  if (itemType === 'dynamic_content' && isSpecialGroupAccess && !flags.dataSourcePermissions) {
    canRemoveElement = false;
  }

  const isOwner = accesses.some((access) => access.permission === Constants.PERMISSIONS.owner.value);
  const person = {
    name: accessor.name,
    email: accessor.type === 'user' ? accessor.email : null,
    photo_url: accessor.type === 'group' ? team_icon : accessor.photo_url,
  };
  return (
    <>
      <ListItemPerson person={person} size="m" />
      <PermissionSelectField
        canEdit={canEditElement}
        accessorType={accessor.type}
        accesses={accesses}
        itemIds={itemIds}
        onChange={handlePermissionChange}
      />
      {canRemoveElement && !isOwner ? (
        <Button category="secondary" width="square" type="button" onClick={handleDelete} alt="Delete Access">
          <Icon name="trash_can" size={20} theme="regular" />
        </Button>
      ) : (
        <div />
      )}
      <div className="col-span-3 border-t border-grey-300 last:border-0"></div>
    </>
  );
}
AccessElement.propTypes = {
  itemType: PropTypes.string,
  itemIds: PropTypes.arrayOf(PropTypes.number),
  accessor: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['group', 'user']),
    name: PropTypes.string,
    email: PropTypes.string,
    photo_url: PropTypes.string,
  }),
  accesses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      item_type: PropTypes.string,
      item_id: PropTypes.number,
      permission: PropTypes.oneOf(Object.keys(Constants.PERMISSIONS)),
    }),
  ),
  canEdit: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default AccessElement;

function PermissionSelectField({ canEdit, accessorType, accesses, itemIds, onChange }) {
  // Show "Mixed" if the accessor has differing permission levels across items
  // *or* if the accessor has no permissions on some of the items.
  const isMixed =
    new Set(accesses.map((access) => access.permission)).size > 1 ||
    !itemIds.every((itemId) => accesses.some((access) => access.item_id === parseInt(itemId)));

  const permission = accesses[0].permission;
  if (canEdit) {
    // Can't change permissions on an owner, since ownership must first be transferred to another user.
    const isOwner = accesses.some((access) => access.permission === Constants.PERMISSIONS.owner.value);
    if (isOwner) {
      return (
        <div
          className="pl-4 pr-2"
          data-tooltip-id="matik-tooltip"
          data-tooltip-content="Transfer Ownership to another user to change access for this user"
        >
          {isMixed ? 'Mixed' : Constants.PERMISSIONS.owner.label}
        </div>
      );
    }

    const permissionOptions = Object.values(Constants.PERMISSIONS).filter(
      (perm) => perm !== Constants.PERMISSIONS.owner,
    );
    if (accessorType !== 'group') {
      permissionOptions.push({
        label: (
          <div className="border-t border-grey-300 -mt-2 -mx-3">
            <div className="mt-2 mx-3">Transfer Ownership</div>
          </div>
        ),
        value: Constants.PERMISSIONS.owner.value,
      });
    }

    return (
      <Select
        className="w-[160px] ml-4 mr-2"
        componentsToAdd={{ Menu: MenuNoSearch }}
        onChange={onChange}
        placeholder="Mixed"
        classNamePrefix="matik-select"
        value={isMixed ? null : { label: Constants.PERMISSIONS[permission]?.label, value: permission }}
        menuPlacement="bottom"
        options={permissionOptions}
      />
    );
  } else {
    return <div className="pl-4 pr-2">{isMixed ? 'Mixed' : Constants.PERMISSIONS[permission]?.label}</div>;
  }
}
PermissionSelectField.propTypes = {
  canEdit: PropTypes.bool,
  accessorType: PropTypes.oneOf(['group', 'user']),
  accesses: PropTypes.arrayOf(
    PropTypes.shape({
      item_id: PropTypes.number,
      permission: PropTypes.oneOf(Object.keys(Constants.PERMISSIONS)),
    }),
  ),
  itemIds: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};
