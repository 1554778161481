import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Heading, Level } from 'react-bulma-components';
import Constants from '../../Constants';
import { ReactComponent as Plus } from '../../../svg/plus.svg';
import { UserContext } from '../../UserContext';
import API from '../../../lib/api';
import PresentationsInsight from './PresentationsInsight';
import MostActiveUserInsight from './MostActiveUserInsight';
import MostUsedTemplateInsight from './MostUsedTemplateInsight';
import GenerationProcessInsight from './GenerationProcessInsight';
import HomeInsightsPeriodDropdown from './HomeInsightsPeriodDropdown';
import SmallLoader from '../../shared/SmallLoader';

class HomeInsights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      insightsData: [],
      period: 'week',
      selectedPeriod: { label: 'Last Week', value: 'week' },
    };
  }

  componentDidMount() {
    this.fetchInsightsData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.period !== this.state.period) {
      this.fetchInsightsData();
    }
  }

  updateQueryParams = (updatedState) => {
    this.setState(updatedState);
  };

  render() {
    let body = '';
    if (this.state.isLoading) {
      body = <SmallLoader />;
    } else if (this.state.insightsData && !this.state.insightsData['presentations_exist']) {
      let inviteButton = '';
      if (this.context.user && this.context.user.roles.indexOf(Constants.PRODUCER_ADMIN_ROLE) >= 0) {
        inviteButton = (
          <Link className="button is-size-6 insights-invite-button" to="/enterprise_settings/users">
            <Plus />
            &nbsp;Invite your team
          </Link>
        );
      }
      body = (
        <div className="insight-box">
          <div>Data about your team&apos;s usage will appear here once they start using Matik</div>
          {inviteButton}
        </div>
      );
    } else {
      body = (
        <>
          {this.state.insightsData['presentations_timeseries_data'] &&
            this.state.insightsData['presentations_timeseries_data'].length && (
              <div className="insight-box insights-border-bottom">
                <PresentationsInsight
                  presentationData={this.state.insightsData['presentations_timeseries_data']}
                  totalPresentations={this.state.insightsData['total_presentations']}
                  percentChange={this.state.insightsData['presentations_percent_change']}
                />
              </div>
            )}
          {this.state.insightsData['most_active_user'] && (
            <div className="insight-box insights-border-bottom user-insight-box">
              <MostActiveUserInsight
                user={this.state.insightsData['most_active_user']}
                presentationsCount={this.state.insightsData['most_active_user_presentations_count']}
              />
            </div>
          )}
          {this.state.insightsData['most_used_template'] && (
            <div className="insight-box insights-border-bottom template-insight-box">
              <MostUsedTemplateInsight template={this.state.insightsData['most_used_template']} />
            </div>
          )}
          {this.state.insightsData['generation_process_times']['processing_inputs'] !== null && (
            <div className="insight-box insights-border-bottom">
              <GenerationProcessInsight generationProcessTimes={this.state.insightsData['generation_process_times']} />
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <Level className="ptl pbn phm">
          <Level.Side align="left">
            <Heading size={5}>Team Insights</Heading>
          </Level.Side>
          {this.state.insightsData['presentations_exist'] && (
            <Level.Side align="right">
              <HomeInsightsPeriodDropdown
                updateQueryParams={this.updateQueryParams}
                selectedPeriod={this.state.selectedPeriod}
              />
            </Level.Side>
          )}
        </Level>
        <div className="text-grey">{body}</div>
      </>
    );
  }

  fetchInsightsData = () => {
    this.setState({ isLoading: true });

    const params = new URLSearchParams({
      period: this.state.period,
    });

    API.get(
      '/home/insights/?' + params,
      (response) => {
        this.setState({ insightsData: response.data, isLoading: false });
      },
      API.defaultError,
    );
  };
}

HomeInsights.propTypes = {};

HomeInsights.contextType = UserContext;

export default HomeInsights;
