import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EnterpriseUpsellTag from '../../shared/EnterpriseUpsellTag';
import teams from '../../../lib/teams';
import mail_illustration from '../../../images/mail_illustration_80x80.svg';

class EmailNarrativeCard extends Component {
  render() {
    return (
      <div className="narrative-card">
        <img src={mail_illustration} className="narrative-card-icon" />
        <div className="narrative-card-center">
          <p className="narrative-card-title">
            Email
            {this.props.user && teams.isTeamsUser(this.props.user.enterprise.plan_id) && (
              <EnterpriseUpsellTag feature="email_narrative" />
            )}
          </p>
          <p className="narrative-card-subtitle">
            Create an email template with the option to attach a Matik-generated presentation
          </p>
        </div>
        <button className="button is-secondary is-fullwidth" onClick={this.props.onEmailNarrativeClick}>
          Create Email Template
        </button>
      </div>
    );
  }
}

EmailNarrativeCard.propTypes = {
  onEmailNarrativeClick: PropTypes.func,
  user: PropTypes.object,
};

export default EmailNarrativeCard;
