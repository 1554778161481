import { bindActionCreators } from 'redux';
import {
  fetchAnalyticsUsersIfNeeded,
  selectEnterpriseUser,
  fetchEnterpriseUsersIfNeeded,
  clearSelectedEnterpriseUser,
  onUserAdd,
  onBulkUserUpload,
  onUserDelete,
  onUserUpdate,
} from './action';

const mapUsersDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAnalyticsUsersIfNeeded,
      fetchEnterpriseUsersIfNeeded,
      selectEnterpriseUser,
      clearSelectedEnterpriseUser,
      onUserAdd,
      onBulkUserUpload,
      onUserDelete,
      onUserUpdate,
    },
    dispatch,
  );
};

export { mapUsersDispatchToProps };
