import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Level, Heading } from 'react-bulma-components';
import Pluralize from 'pluralize';
import SmallLoader from './SmallLoader';
import { ReactComponent as Library } from '../../svg/library.svg';
import { Select } from './FormSelect';
import AsyncLoadImage from './AsyncLoadImage';
import no_libraries_icon from '../../images/no_libraries.png';
import Button from '../lib/Button';
import { useTemplateThumbnails } from 'lib/hooks/useTemplate';

class InsertTemplatePopupBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSlides: {},
      isInserting: false,
    };
  }

  render() {
    let body = '';
    let selectValue = {};
    let isEndUser = typeof this.props.endUserLibraryExists !== 'undefined';
    let librariesExist = this.props.templatesById && Object.values(this.props.templatesById).length > 0;
    let librariesExistEndUserView = this.props.endUserLibraryExists;
    let isEndUserLibraryExist = isEndUser && librariesExistEndUserView;
    let isAdminUserLibraryExist = !isEndUser && librariesExist;
    const libraryOptions = this.libraryNameOptions();

    if (!this.props.currentTemplate || this.state.isInserting) {
      body = <SmallLoader />;
    } else {
      if (this.props.selectedTemplate) {
        selectValue = { label: this.props.selectedTemplate.name, value: this.props.selectedTemplate.id };
      } else {
        selectValue = null;
      }
      let selectedSlideCount = 0;
      for (let slideKey of Object.keys(this.state.selectedSlides)) {
        const keyArray = slideKey.split('_');
        if (parseInt(keyArray[1]) === this.props.selectedTemplate.id) {
          selectedSlideCount++;
        }
      }

      const areAllSlidesSelected = this.props.selectedTemplate
        ? selectedSlideCount === this.props.selectedTemplate.slides.length
        : false;

      body = (
        <React.Fragment>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Library className="mrs" />
                {this.props.title}
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              {librariesExist && (
                <Level.Item>
                  <div className="select-wrapper">
                    <Select
                      classNamePrefix="matik-select"
                      onChange={this.props.selectTemplate}
                      value={selectValue}
                      options={libraryOptions}
                      menuPortalTarget={null}
                      menuPosition="absolute"
                    />
                  </div>
                </Level.Item>
              )}
            </Level.Side>
          </Level>
          {(() => {
            if (isEndUserLibraryExist || isAdminUserLibraryExist) {
              return this.renderLibrarySlides();
            } else {
              return this.renderEmpty(isEndUser);
            }
          })()}
          <Level className="popup-bottom-bar">
            <Level.Side align="left">
              <Level.Item>
                {Pluralize('slide', Object.keys(this.state.selectedSlides).length, true)} selected
              </Level.Item>
              <Level.Item>
                <a href="#dummy" onClick={(e) => this.selectAllSlides(e, areAllSlidesSelected)}>
                  {areAllSlidesSelected ? 'Deselect all in this template' : 'Select all in this template'}
                </a>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Button disabled={!!this.state.isInserting} onClick={this.insertSelectedSlides}>
                Insert Slides
              </Button>
            </Level.Side>
          </Level>
        </React.Fragment>
      );
    }
    return <div className="insert-library-slide-popup-content">{body}</div>;
  }

  renderEmpty(isEndUser) {
    return (
      <React.Fragment>
        <div className="empty-libraries-wrapper">
          <img src={no_libraries_icon} alt="No libraries exist" />
        </div>
        <Heading size={5} subtitle className="phl ptm">
          {isEndUser ? (
            <>You don&apos;t have any other templates to pull slides from yet</>
          ) : (
            <>
              {' '}
              You haven&apos;t created a library yet. Create one by clicking the &quot;publish as library&quot; button
            </>
          )}
        </Heading>
      </React.Fragment>
    );
  }

  renderLibrarySlides() {
    if (!this.props.selectedTemplate) {
      return null;
    }
    return (
      <TemplateSlidesWithThumbnails
        currentTemplate={this.props.selectedTemplate}
        selectSlide={this.selectSlide}
        selectedSlides={this.state.selectedSlides}
      />
    );
  }

  libraryNameOptions() {
    if (this.props.templatesById) {
      return Object.values(this.props.templatesById).map((template) => ({ label: template.name, value: template.id }));
    }

    return [];
  }
  selectSlide = (slideId, templateId) => {
    const slideKey = `${slideId}_${templateId}`;
    if (this.state.selectedSlides[slideKey]) {
      const updatedSelectedSlides = {};
      for (let existingSlideKey of Object.keys(this.state.selectedSlides)) {
        if (existingSlideKey !== slideKey) {
          updatedSelectedSlides[existingSlideKey] = this.state.selectedSlides[existingSlideKey];
        }
      }
      this.setState({ selectedSlides: updatedSelectedSlides });
    } else {
      const updatedSelectedSlides = Object.assign({}, this.state.selectedSlides);
      updatedSelectedSlides[slideKey] = { slideId, templateId };
      this.setState({ selectedSlides: updatedSelectedSlides });
    }
  };

  selectAllSlides = (e, areAllSlidesSelected) => {
    e.preventDefault();
    const templateId = this.props.selectedTemplate.id;
    if (areAllSlidesSelected) {
      const updatedSelectedSlides = Object.assign({}, this.state.selectedSlides);
      for (let slide of this.props.selectedTemplate.slides) {
        const slideKey = `${slide.id}_${templateId}`;
        delete updatedSelectedSlides[slideKey];
      }
      this.setState({ selectedSlides: updatedSelectedSlides });
    } else {
      const newSelectedSlides = {};
      for (let slide of this.props.selectedTemplate.slides) {
        const slideKey = `${slide.id}_${templateId}`;
        newSelectedSlides[slideKey] = { slideId: slide.id, templateId };
      }
      const allSelectedSlides = Object.assign({}, this.state.selectedSlides, newSelectedSlides);
      this.setState({ selectedSlides: allSelectedSlides });
    }
  };

  insertSelectedSlides = () => {
    if (this.state.selectedSlides && Object.values(this.state.selectedSlides).length > 0) {
      this.setState({ isInserting: true });
      this.props
        .insertLibrarySlidesIntoTemplate(
          this.state.selectedSlides,
          this.props.currentTemplate.id,
          this.props.insertionIdx,
        )
        .finally(() => this.setState({ isInserting: false, selectedSlides: {} }));
    }
  };
}

InsertTemplatePopupBody.propTypes = {
  currentTemplate: PropTypes.object,
  insertionIdx: PropTypes.number,
  insertLibrarySlidesIntoTemplate: PropTypes.func,
  selectedTemplate: PropTypes.object,
  selectTemplate: PropTypes.func,
  templatesById: PropTypes.object,
  endUserLibraryExists: PropTypes.bool,
  title: PropTypes.string,
};

InsertTemplatePopupBody.defaultProps = {
  title: 'Insert Slide From Library',
};

export default InsertTemplatePopupBody;

const TemplateSlides = (props) => {
  const { data: thumbnails } = useTemplateThumbnails(props.currentTemplate.id, props.currentTemplate.deleted);

  return (
    <div className="insert-slides-wrapper">
      <div className="template-grid">
        {props.currentTemplate.slides.map((slide, idx) => {
          const thumbData = thumbnails?.[parseInt(slide.id)];
          const slideFetchStatus = thumbData && {
            data: thumbData,
            fetchState: 'fetched',
            errorMessage: '',
          };

          const checkId = `${props.currentTemplate.id}_${idx}_insert_check`;
          const checked = !!(props.selectedSlides && props.selectedSlides[`${slide.id}_${props.currentTemplate.id}`]);
          return (
            <div className="slide-wrapper" key={`thumbnail-${slide.id}`}>
              <div className="slide-view">
                <AsyncLoadImage
                  fetchUrl={'unsupported'}
                  fetchStatus={slideFetchStatus}
                  isBulk={true}
                  imageClass="async-rounded slide-thumbnail"
                  height="85px"
                  width="150px"
                />
                <div className="select-slide">
                  <input
                    id={checkId}
                    type="checkbox"
                    checked={checked}
                    onChange={() => props.selectSlide(slide.id, props.currentTemplate.id)}
                  />
                  <label htmlFor={checkId}></label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TemplateSlides.propTypes = {
  currentTemplate: PropTypes.object.isRequired,
  selectSlide: PropTypes.func,
  selectedSlides: PropTypes.object,
  slideFetchStatus: PropTypes.func,
};

const TemplateSlidesWithThumbnails = TemplateSlides;
