import { Form } from 'react-bulma-components';
import Icon from 'components/lib/Icon';
import React from 'react';

export function HeaderCenter() {
  return (
    <Form.Label className="full-screen-modal-title">
      <Icon name="wand" size={16} theme="regular" />
      <span className="ml-2">AI Template Builder</span>
    </Form.Label>
  );
}
