import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PresentationsInsightRechart from './PresentationsInsightRechart';
import PresentationsInsightTotal from './PresentationsInsightTotal';
import { Heading } from 'react-bulma-components';
import withUserContext from '../../shared/WithUserContext';
import teams from '../../../lib/teams';

class PresentationsInsight extends Component {
  render() {
    return (
      <div>
        <Heading size={6} className="text-grey mbs">
          Presentations
        </Heading>
        <PresentationsInsightTotal
          presentationData={this.props.totalPresentations}
          totalPresentations={this.props.totalPresentations}
          percentChange={this.props.percentChange}
        />
        {this.props.userContext.user && !teams.isTeamsUser(this.props.userContext.user.enterprise.plan_id) ? (
          <PresentationsInsightRechart presentationData={this.props.presentationData} />
        ) : null}
      </div>
    );
  }
}

PresentationsInsight.propTypes = {
  presentationData: PropTypes.array,
  totalPresentations: PropTypes.number,
  percentChange: PropTypes.number,
  userContext: PropTypes.object,
};

export default withUserContext(PresentationsInsight);
