import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from './Icon';
import IconPill from './IconPill';

const ModalHeader = ({ iconName, title, subtitle, onClose }) => {
  return (
    <div className="flex gap-4 bg-matik-white !py-6 !pl-6 pr-4 items-center border-grey-300 border-b">
      {iconName && (
        <div>
          <IconPill iconName={iconName} size="l" theme="square" />
        </div>
      )}
      <div className="grow flex flex-col">
        <div className="font-semibold text-base">{title}</div>
        {subtitle && <div className="text-xs">{subtitle}</div>}
      </div>
      {onClose && (
        <div>
          <Button category="tertiary" width="square" onClick={onClose}>
            <Icon name="dismiss" size={20} />
          </Button>
        </div>
      )}
    </div>
  );
};
ModalHeader.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
};

export default ModalHeader;
