import React from 'react';
import loading_icon from '../../images/loading.gif';

const SmallLoader = () => (
  <div className="has-text-centered">
    <img src={loading_icon} width="100rem" alt="loading" />
  </div>
);

export default SmallLoader;
