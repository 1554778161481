import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Level } from 'react-bulma-components';
import SearchBoxAndFilters from '../shared/search/SearchBoxAndFilters';
import Constants from '../Constants';
import API from 'lib/api';
import { MAlert } from 'components/shared/Alerts';
import ButtonWithDropdown from 'components/shared/ButtonWithDropdown';
const { parse } = require('json2csv');
import { ReactComponent as UploadButtonIcon } from 'images/icons/arrow_upload-20x20-regular.svg';
import { ReactComponent as PlusIcon } from 'svg/plus.svg';

const UsersHeader = (props) => {
  let billing = '';
  let userCounts = '';
  if (props.enterprise.billing && Object.keys(props.enterprise.billing).length > 0) {
    if (props.enterprise.billing.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id) {
      userCounts = (
        <React.Fragment>
          <Level.Item>
            {props.enterprise.consumer_users_count} / {props.enterprise.billing.consumer_count}{' '}
            {Constants.ROLE_DISPLAY_MAPPING[Constants.CONSUMER_ROLE]}
          </Level.Item>
          <Level.Item>
            {props.enterprise.producer_users_count} / {props.enterprise.billing.producer_count}{' '}
            {Constants.ROLE_DISPLAY_MAPPING[Constants.PRODUCER_ROLE]}
          </Level.Item>
        </React.Fragment>
      );
    } else {
      userCounts = (
        <React.Fragment>
          <Level.Item>
            {props.enterprise.users_count} / {Constants.MATIK_TIERS[props.enterprise.billing.plan_id].userLimit} users
          </Level.Item>
        </React.Fragment>
      );
    }
    billing = (
      <React.Fragment>
        {userCounts}
        <Level.Item>Plan expires {moment(props.enterprise.billing.expires_on).calendar()}</Level.Item>
      </React.Fragment>
    );
  }

  const fetchCsvData = () => {
    API.get('/users/csv/', (response) => {
      if (response.data.length > 0) {
        const data = parse(response.data);
        const downloadLink = document.createElement('a');
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        downloadLink.href = URL.createObjectURL(blob); // eslint-disable-line
        downloadLink.setAttribute('download', 'matik_users.csv');
        downloadLink.click();
      } else {
        MAlert("You haven't added any users yet.");
      }
    });
  };

  return (
    <React.Fragment>
      <Level>{billing}</Level>
      <Form.Field kind="group" className="mbl">
        <Form.Control className="has-icons-left flex-1">
          <SearchBoxAndFilters searchState={props.searchState} searchAttributes={props.searchAttributes} />
        </Form.Control>
        <Form.Control>
          <ButtonWithDropdown
            buttonText="Add Users"
            className="is-primary"
            dropdownDirection="down"
            isDropdownOnly={true}
          >
            <div className="dropdown-content generate-dropdown email-send-dropdown">
              <div onClick={props.onNewUserClick} className="pam vertical-center dropdown-item">
                <PlusIcon className="mrs add-user-icon" />
                Add New User
              </div>
              <div onClick={props.onCSVBulkUserClick} className="pam vertical-center dropdown-item">
                <UploadButtonIcon className="mrs add-user-icon" />
                Bulk Upload from CSV
              </div>
            </div>
          </ButtonWithDropdown>
        </Form.Control>
      </Form.Field>
      <button className="csv-download button is-secondary mbl" onClick={fetchCsvData}>
        Download CSV
      </button>
    </React.Fragment>
  );
};

UsersHeader.propTypes = {
  enterprise: PropTypes.object,
  filterTerm: PropTypes.string,
  onFilter: PropTypes.func,
  onNewUserClick: PropTypes.func,
  onCSVBulkUserClick: PropTypes.func,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
};

export default UsersHeader;
