import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { ReactComponent as ChevronDown } from '../../images/chevron_down.svg';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';

function ButtonWithDropdown(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = (e) => {
    e.preventDefault();
    ref.current = e.target;
    if (!props.dropdownDisabled) {
      setShowDropdown(!showDropdown);
    }
  };

  const ref = useRef();
  useOnClickOutside(ref, () => setShowDropdown(false), [setShowDropdown]);

  const dropdownDirection = props.dropdownDirection === 'down' ? 'is-down' : 'is-up';
  const leftButtonClass = props.isDropdownOnly ? 'inert' : '';
  const buttonClick = props.type !== 'submit' && !props.onClick ? toggleDropdown : props.onClick;
  return (
    <Form.Field className="has-addons">
      <Form.Control>
        <button
          className={`button ${props.className} ${leftButtonClass}`}
          disabled={props.disabled}
          type={props.type}
          onClick={buttonClick}
        >
          {props.icon}
          <span className="ml-2">{props.buttonText}</span>
        </button>
      </Form.Control>
      <Form.Control>
        <div className={`dropdown ${dropdownDirection} ${showDropdown && 'is-active'}`} onClick={toggleDropdown}>
          <div className="dropdown-trigger">
            <button
              className={`button button-dropdown ${props.className}`}
              disabled={props.dropdownDisabled}
              aria-haspopup="true"
            >
              <ChevronDown style={{ strokeWidth: '10' }} />
            </button>
          </div>
          <div className="dropdown-menu" role="menu">
            {props.children}
          </div>
        </div>
      </Form.Control>
    </Form.Field>
  );
}

ButtonWithDropdown.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownDisabled: PropTypes.bool,
  icon: PropTypes.object,
  type: PropTypes.string,
  isDropdownOnly: PropTypes.bool,
  dropdownDirection: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonWithDropdown;
