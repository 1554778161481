import React from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';

function UniqueValuesFilter({ filterDuplicates, toggleFilterDuplicates }) {
  return (
    <Form.Field className="mbl">
      <CheckboxWithLabel
        id="uniquefilter"
        checked={!!filterDuplicates}
        label={<span className="border-b border-grey-400 border-dotted">Unique Values Only</span>}
        onChange={(e) => toggleFilterDuplicates(e.target.checked)}
        tooltip="When enabled, duplicate values with be filtered out from the results."
        dataPlace="top"
        className="inline-block"
      />
    </Form.Field>
  );
}

UniqueValuesFilter.propTypes = {
  filterDuplicates: PropTypes.bool,
  toggleFilterDuplicates: PropTypes.func,
};

export default UniqueValuesFilter;
