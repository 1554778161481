import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import accessIcon from '../../../images/access.svg';
import BulkAccessModal from './BulkAccessModal';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

function BulkAccessButton(props) {
  const openModal = () => {
    props.openModal('bulkAccessModal');
  };

  return (
    <React.Fragment>
      <button className="button is-secondary" onClick={openModal}>
        <img className="mrs" src={accessIcon} alt="Manage Access" /> Manage Access
      </button>
      <BulkAccessModal itemIds={props.selectedItemIds} entityType={props.entityType} />
    </React.Fragment>
  );
}

BulkAccessButton.propTypes = {
  selectedItemIds: PropTypes.array,
  entityType: PropTypes.string,
  openModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(BulkAccessButton);
