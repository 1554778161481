import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TemplatesSidebar from './TemplatesSidebar';
import TemplatesList from './TemplatesList';
import { withRouter } from 'react-router-dom';
import Constants from '../../Constants';
import WithInstantSearch from '../search/WithInstantSearch';

class TemplatesListMain extends Component {
  render() {
    const isAdminView = this.props.match.path.indexOf('create') === -1;
    const baseUrl = isAdminView ? '' : '/create';
    const isEmpty = !this.props.isFetching && this.props.templates?.length === 0;
    const noSidebar =
      isEmpty && this.activeSidebarMenuItem() === Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
    const emptyClass = isEmpty ? 'templates-list-empty' : '';
    return (
      <div>
        <div className="wrapper templates-list-offset ">
          {!noSidebar && (
            <TemplatesSidebar
              urlBase={baseUrl}
              activeSidebarMenuItem={this.activeSidebarMenuItem()}
              searchState={this.props.searchState}
              onSearchStateChange={this.props.onSearchStateChange}
              isAdminView={isAdminView}
              onSearchParamsChange={this.props.onSearchParamsChange}
            />
          )}
          <div className={`main ${emptyClass}`}>
            <TemplatesList
              activeSidebarMenuItem={this.activeSidebarMenuItem()}
              emptyInfo={this.props.emptyInfo}
              addNarrative={this.props.addNarrative}
              isLoading={this.props.isFetching}
              elements={this.props.templates}
              templates={this.props.templates}
              pagination={this.props.pagination}
              isPaginating={this.props.isPaginating}
              searchState={this.props.searchState}
              searchAttributes={this.props.searchAttributes}
              onSearchStateChange={this.props.onSearchStateChange}
              isSearching={this.props.isSearching}
              onSearchParamsChange={this.props.onSearchParamsChange}
            />
          </div>
        </div>
      </div>
    );
  }

  activeSidebarMenuItem = () => {
    return this.props.match.params.item || Constants.TEMPLATES_SIDEBAR_MENU_CHOICES.all_templates;
  };
}

TemplatesListMain.propTypes = {
  // Props from parent
  emptyInfo: PropTypes.object,
  addNarrative: PropTypes.func,
  //For withInstantSearch
  entityType: PropTypes.string,
  // From WithInstantSearch
  searchAttributes: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  searchState: PropTypes.object,
  isSearching: PropTypes.bool,
  templates: PropTypes.array,
  isPaginating: PropTypes.bool,
  pagination: PropTypes.object,
  isFetching: PropTypes.bool,
  // From withRouter
  match: PropTypes.object,
  onSearchParamsChange: PropTypes.func,
};

export default WithInstantSearch(withRouter(TemplatesListMain));
