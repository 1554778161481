import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from 'components/shared/FormSelect';

function GoogleBQOauthForm({ auth, database, isOAuth, onAuthChange, onChange, setIsOAuth }) {
  const authOptions = [
    { label: 'OAuth2', value: 'OAuth2' },
    { label: 'Service Account Info', value: 'service_account' },
  ];

  const selectedAuthMethod = isOAuth ? authOptions[0] : authOptions[1];

  let projectId = (
    <Form.Field>
      <Form.Label htmlFor="project-id">Project ID</Form.Label>
      <Form.Control>
        <Form.Input
          placeholder="Project to connect to"
          type="text"
          name="database"
          id="project-id"
          onChange={onChange}
          value={database}
          autoComplete="off"
        />
      </Form.Control>
    </Form.Field>
  );
  let body = (
    <>
      {projectId}
      <Form.Field>
        <Form.Label htmlFor="service-account-info">Service Account JSON</Form.Label>
        <Form.Control>
          <Form.Textarea
            placeholder="Paste service account json file contents here."
            type="text"
            name="service_account_info"
            onChange={onAuthChange}
            value={auth.service_account_info}
            autoComplete="off"
            id="service-account-info"
          />
        </Form.Control>
      </Form.Field>
    </>
  );
  if (isOAuth) {
    body = projectId;
  }
  return (
    <>
      <Form.Field>
        <Form.Label>Select Google authentication method</Form.Label>
        <Form.Control>
          <Select
            classNamePrefix="matik-select"
            onChange={(obj) => setIsOAuth(obj.value === 'OAuth2')}
            value={selectedAuthMethod}
            options={authOptions}
          />
        </Form.Control>
      </Form.Field>
      {body}
    </>
  );
}

GoogleBQOauthForm.propTypes = {
  auth: PropTypes.object,
  database: PropTypes.string,
  isOAuth: PropTypes.bool,
  onAuthChange: PropTypes.func,
  onChange: PropTypes.func,
  setIsOAuth: PropTypes.func,
};

export default GoogleBQOauthForm;
