import React from 'react';
import PropTypes from 'prop-types';
import TextOverflowWithTooltip from './TextOverflowWithTooltip';
import { Heading } from 'react-bulma-components';
import { Link } from 'react-router-dom';

function LinkHeading(props) {
  const { linkDisabled, name, url } = props;
  return (
    <div className="link-heading text-overflow-ellipsis">
      {linkDisabled ? (
        <Heading size={5} className="text-overflow-ellipsis">
          <TextOverflowWithTooltip className="text-overflow-ellipsis" text={name} />
        </Heading>
      ) : (
        <Link to={url} className="text-overflow-ellipsis">
          <Heading size={5} className="text-overflow-ellipsis">
            <TextOverflowWithTooltip className="text-overflow-ellipsis has-cursor-pointer" text={name} />
          </Heading>
        </Link>
      )}
    </div>
  );
}

LinkHeading.propTypes = {
  isFullWidth: PropTypes.bool,
  linkDisabled: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
};

export default LinkHeading;
