import React, { Component } from 'react';
import { Form, Box, Container } from 'react-bulma-components';
import { toast } from 'react-toastify';
import { UserContext } from '../../UserContext';
import Constants from '../../Constants';
import utils from '../../../lib/utils';
import API from '../../../lib/api';
import { MAlert } from '../../shared/Alerts';
import FileUpload from '../../shared/fileUpload/FileUpload';
import UploadedFileBanner from '../../shared/fileUpload/UploadedFileBanner';
import csv_icon from '../../../images/csv_icon.svg';

class EmailCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_key: undefined,
      attachment_name: '',
      csv_file: null,
      dynamic_template_id: '',
      error: null,
      from_email: '',
      from_name: '',
      isLoading: false,
    };
  }

  render() {
    const apiKeyInForm = this._getApiKey();
    return (
      <Container className="with-navbar">
        <Box className="is-shadowless has-light-gray-border">
          <form>
            <Form.Field>
              <Form.Control>
                <Form.Label>Sendgrid API Key</Form.Label>
                <Form.Help>Can be found in Email API &gt; Integrations in your Sendgrid account</Form.Help>
                <Form.Input placeholder="API Key" value={apiKeyInForm} name="api_key" onChange={this.onChange} />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Control>
                <Form.Label>Dynamic Template Id</Form.Label>
                <Form.Help>Dynamic template in Sendgrid for this email</Form.Help>
                <Form.Input
                  placeholder="Format is d-13ff1191e0d94f9c8792123975f1cda1"
                  value={this.state.dynamic_template_id}
                  name="dynamic_template_id"
                  onChange={this.onChange}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Control>
                <Form.Label>From Email</Form.Label>
                <Form.Help>Emails will be generated with this from email</Form.Help>
                <Form.Input
                  placeholder="From email"
                  value={this.state.from_email}
                  name="from_email"
                  onChange={this.onChange}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Control>
                <Form.Label>From Name (Optional)</Form.Label>
                <Form.Help>From field will have this name</Form.Help>
                <Form.Input
                  placeholder="From name"
                  value={this.state.from_name}
                  name="from_name"
                  onChange={this.onChange}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Control>
                <Form.Label>Attachment Name</Form.Label>
                <Form.Help>
                  Name of the attachment to the email. Note: Do not include pdf extension, this will be done
                  automatically
                </Form.Help>
                <Form.Input
                  placeholder="Attachment"
                  value={this.state.attachment_name}
                  name="attachment_name"
                  onChange={this.onChange}
                />
              </Form.Control>
            </Form.Field>
            {!this.state.csv_file ? (
              <FileUpload buttonText="Upload a CSV to Matik" onSelectFile={this.onSelectFile} inputAccept=".csv" />
            ) : (
              <UploadedFileBanner
                onCancel={this.onChangeFile}
                uploadedFileName={this.state.csv_file.name}
                uploadedIconSrc={csv_icon}
              />
            )}

            <Form.Field className="mtl" kind="group" align="right">
              <Form.Control>
                <button
                  className={`button is-primary ${this.state.isLoading ? 'is-loading' : ''}`}
                  onClick={this.onSubmit}
                >
                  Send Emails
                </button>
              </Form.Control>
            </Form.Field>
          </form>
        </Box>
      </Container>
    );
  }

  _getApiKeyFromSendgrid = () => {
    let apiKey = '';
    let sendgridIntegration = null;
    if (this.context.user && this.context.user.integrations) {
      for (let integration of this.context.user.integrations) {
        if (integration.name === Constants.USER_INTEGRATION_TYPES.sendgrid) {
          sendgridIntegration = integration;
        }
      }
    }

    if (sendgridIntegration) {
      apiKey = sendgridIntegration.access_token;
    }

    return apiKey;
  };

  _getApiKey = () => {
    const existingSendgridAPIKey = this._getApiKeyFromSendgrid();
    return this.state.api_key !== undefined ? this.state.api_key : existingSendgridAPIKey;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      api_key: this._getApiKey(),
      attachment_name: this.state.attachment_name,
      dynamic_template_id: this.state.dynamic_template_id,
      from_email: this.state.from_email,
      from_name: this.state.from_name,
    };

    const onSuccess = (response) => {
      this.setState({
        isLoading: false,
        csv_file: null,
        dynamic_template_id: '',
        from_email: '',
        from_name: '',
        attachment_name: '',
      });
      MAlert(response.data.message, 'Done', 'success');
    };

    API.post('/emails/bulk/', data, onSuccess, API.defaultError, this.state.csv_file);
  };

  onChangeFile = (e) => {
    e.preventDefault();
    this.setState({ csv_file: null });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file || !file.type.includes('csv')) {
      return utils.notify('Invalid File Type', toast.TYPE.ERROR);
    }
    this.setState({ csv_file: file });
  };
}

EmailCSV.contextType = UserContext;

export default EmailCSV;
