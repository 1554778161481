import React, { useState, useEffect } from 'react';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { Heading, Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import utils from '../../../lib/utils';
import { CreatableSelect, Select } from 'components/shared/FormSelect';
import add_circle from '../../../images/add_circle_16x16.svg';
import API from 'lib/api';
import HowTo from '../../shared/HowTo';

function EmailFromModal(props) {
  const [name, setName] = useState(props.name);
  const [fromEmail, setFromEmail] = useState(props.fromEmail);
  const [fromEmailIsValid, setFromEmailIsValid] = useState(true);
  const [replyEmail, setReplyEmail] = useState(props.replyEmail);
  const [replyEmailIsValid, setReplyEmailIsValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [primaryButtonText, setPrimaryButtonText] = useState('');
  const domain = props.enterprise?.domain ? props.enterprise.domain : "the one you've authenticated";

  useEffect(() => {
    setName(props.name);
    setFromEmail(props.fromEmail);
    setReplyEmail(props.replyEmail);
  }, [props.name, props.fromEmail, props.replyEmail]);

  const onChange = (fieldName, fieldValue) => {
    if (fieldName === 'name') {
      setName(fieldValue);
    } else if (fieldName === 'replyEmail') {
      setReplyEmail(fieldValue);
    } else if (fieldName === 'fromEmail') {
      setFromEmail(fieldValue);
    }
    props.onChange(fieldName, fieldValue);
  };

  const onUpdateFromSubmit = async (e) => {
    e.preventDefault();
    if (checkValidEmails()) {
      if (!props.isEndUser) {
        const newSubuserAdded =
          fromEmail !== 'no-reply@matik.io' &&
          (props.sendgridInfo?.auth?.subusers?.includes(fromEmail) === false || !props.sendgridInfo?.auth?.subusers);
        if (newSubuserAdded) {
          const data = { subuser: fromEmail };
          await API.post(`/enterprises/${props.enterprise.id}/add_mail_subuser/`, data, () => {}, API.defaultError);
        }
      }
      const updatedTemplate = props.currentTemplate;
      updatedTemplate.source.from.name = name;
      updatedTemplate.source.from.fromEmail = fromEmail;
      updatedTemplate.source.from.replyEmail = replyEmail;
      await props.onTemplateUpdateWithServerCall(updatedTemplate);
      props.onClose();
    }
  };

  const checkValidEmails = () => {
    const fromEmailIsValid = utils.isEmailValid(fromEmail) || !fromEmail.length;
    const replyEmailIsValid = utils.isEmailValid(replyEmail) || !replyEmail.length;

    if (!fromEmailIsValid) {
      setFromEmailIsValid(false);
      setEmailErrorMsg('Invalid email address');
    }

    if (!replyEmailIsValid) {
      setReplyEmailIsValid(false);
      setEmailErrorMsg('Invalid email address');
    }

    return fromEmailIsValid && replyEmailIsValid;
  };

  useEffect(() => {
    const buttonText = props.name || props.fromEmail || props.replyEmail ? 'Edit From' : 'Add From';
    setPrimaryButtonText(buttonText);
  }, []);

  const headerCenter = <Heading className="full-screen-modal-title">{primaryButtonText}</Heading>;

  const domainValidated = props.sendgridInfo?.auth?.domain_validated;

  let authErrorMsg = '';

  if (!domainValidated) {
    if (props.isEndUser) {
      authErrorMsg = (
        <p className="help is-danger">To change the from email an admin needs to validate your domain first</p>
      );
    } else {
      authErrorMsg = (
        <p className="help is-danger">
          To change the from email you need to{' '}
          <span className="inline-warning-link" onClick={props.openAuthModal}>
            validate your domain
          </span>{' '}
          first
        </p>
      );
    }
  }

  const getEmailOptions = () => {
    let options = [];
    if (Array.isArray(props.sendgridInfo?.auth?.subusers)) {
      options = props.sendgridInfo?.auth?.subusers.map((subuser) => ({ value: subuser, label: subuser }));
    }
    options.push({ value: 'no-reply@matik.io', label: 'no-reply@matik.io' });
    return options;
  };

  const fieldsChanged = () => {
    let fieldsChanged = false;
    if (props.currentTemplate.source.from.name !== name || (!props.currentTemplate.source.from.name && name.length)) {
      fieldsChanged = true;
    }
    if (
      props.currentTemplate.source.from.fromEmail !== fromEmail ||
      (!props.currentTemplate.source.from.fromEmail && fromEmail.length)
    ) {
      fieldsChanged = true;
    }
    if (
      props.currentTemplate.source.from.replyEmail !== replyEmail ||
      (!props.currentTemplate.source.from.replyEmail && replyEmail.length)
    ) {
      fieldsChanged = true;
    }
    return fieldsChanged;
  };

  const primaryButtonDisabled = !fieldsChanged();

  return (
    <FullScreenModal
      show={props.show}
      headerCenter={headerCenter}
      showDefaultFooter={true}
      backgroundColor="white"
      onClose={props.onClose}
      primaryButtonText={primaryButtonText}
      primaryButtonOnClick={onUpdateFromSubmit}
      primaryButtonDisabled={primaryButtonDisabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={props.onClose}
    >
      <div className="three-column-modal-body">
        <div className="modal-sidebar invisible"></div>
        <div className="modal-body-center box is-shadowless has-light-gray-border">
          <Form.Field>
            <Form.Label>From Name</Form.Label>
            <Form.Control>
              <Form.Input
                name="name"
                type="text"
                placeholder="Enter From Name"
                value={name}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              ></Form.Input>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label>From Email</Form.Label>
            <Form.Control>
              {props.isEndUser ? (
                <Select
                  name="fromEmail"
                  placeholder="Select From Email"
                  onChange={(selection) => onChange('fromEmail', selection.value)}
                  isDisabled={!domainValidated}
                  options={getEmailOptions()}
                  value={fromEmail ? { value: fromEmail, label: fromEmail } : null}
                  isMulti={false}
                  styles={{ backgroundColor: 'white' }}
                  classNamePrefix="matik-select"
                />
              ) : (
                <CreatableSelect
                  name="fromEmail"
                  placeholder="Select From Email"
                  onChange={(selection) => onChange('fromEmail', selection.value)}
                  isDisabled={!domainValidated}
                  options={getEmailOptions()}
                  value={fromEmail ? { value: fromEmail, label: fromEmail } : null}
                  isMulti={false}
                  styles={{ backgroundColor: 'white' }}
                  classNamePrefix="matik-select"
                  formatCreateLabel={(typedVal) => (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={add_circle} />
                      &nbsp;&nbsp;Add {typedVal} to your enterprise
                    </span>
                  )}
                />
              )}
            </Form.Control>
            {authErrorMsg}
            {!fromEmailIsValid && <p className="help is-danger">{emailErrorMsg}</p>}
          </Form.Field>
          <Form.Field>
            <Form.Label>Reply To Email</Form.Label>
            <Form.Control>
              <Form.Input
                name="replyEmail"
                type="email"
                placeholder="Enter Reply to Email"
                value={replyEmail}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              ></Form.Input>
            </Form.Control>
            {!replyEmailIsValid && <p className="help is-danger">{emailErrorMsg}</p>}
          </Form.Field>
        </div>
        <div className="modal-sidebar">
          <HowTo>
            <p className="how-to-heading">HOW TO</p>
            <p className="how-to-text list">When adding an email, make sure the domain is {domain}.</p>
            <p className="how-to-text list">Dynamic content cannot be used in From fields.</p>
            <a
              href="https://help.matik.io/hc/en-us/articles/13090747609627"
              target="_blank"
              className="how-to-text link"
              rel="noreferrer"
            >
              Help Center Article
            </a>
          </HowTo>
        </div>
      </div>
    </FullScreenModal>
  );
}

EmailFromModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  fromEmail: PropTypes.string,
  replyEmail: PropTypes.string,
  currentTemplate: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  onChange: PropTypes.func,
  sendgridInfo: PropTypes.object,
  enterprise: PropTypes.object,
  openAuthModal: PropTypes.func,
  isEndUser: PropTypes.bool,
};

export default EmailFromModal;
