import { union, isEqual } from 'lodash';
import utils from './utils';

const NODE_TYPES = { TAG: 'TAG', SUBCONTENT: 'SUBCONTENT' };

export class Node {
  constructor(name, slideNums, contentType, matchingContent, formats, nodeType, tagNum) {
    this.name = name;
    this.slideNums = slideNums;
    this.type = contentType;
    this.matchingContent = matchingContent;
    this.nodeType = nodeType;
    this.formats = formats;
    this.children = {};
    this.parent = null;
    this.tagNum = tagNum;
  }

  addChild(childNode, childNodeKey) {
    if (this.children[childNodeKey]) {
      const existingNode = this.children[childNodeKey];
      existingNode.slideNums = union(existingNode.slideNums, childNode.slideNums);
    } else {
      childNode.parent = this;
      this.children[childNodeKey] = childNode;
    }
  }

  toObj() {
    return {
      name: this.name,
      formats: this.formats,
      type: this.contentType,
      matchingContent: this.matchingContent,
      slideNum: this.slideNums,
    };
  }

  matchesContentName(contentName) {
    const [tagNum, content, subcontent, formats] = utils.getTagParts(contentName);
    if (this.parent) {
      return (
        this.tagNum === tagNum &&
        this.name === subcontent &&
        this.parent &&
        this.parent.name === content &&
        isEqual(formats, this.formats)
      );
    } else if (Array.isArray(this.tagNum)) {
      return this.name === content && isEqual(formats, this.formats) && this.tagNum.includes(tagNum);
    } else {
      return this.name === content && isEqual(formats, this.formats) && this.tagNum === tagNum;
    }
  }

  matchesContentWithoutTagNum(contentName) {
    const [_, content, subcontent, formats] = utils.getTagParts(contentName); // eslint-disable-line no-unused-vars
    if (this.parent) {
      return this.name === subcontent && this.parent && this.parent.name === content && isEqual(formats, this.formats);
    } else if (Array.isArray(this.tagNum)) {
      return this.name === content && isEqual(formats, this.formats);
    } else {
      return this.name === content && isEqual(formats, this.formats);
    }
  }

  toContentName() {
    let contentName = this.name;
    if (this.subcontent && this.subcontent.length > 0) {
      contentName += '.' + this.subcontent[0];
    }
    if (this.formats && this.formats.length > 0) {
      contentName += '|' + this.formats.join('|');
    }
    if (this.name !== this.tagNum) {
      contentName = `${this.tagNum}:${contentName}`;
    }

    return contentName;
  }
}

export class SlideTag {
  constructor(elementId, selectedText, contentName = null, tagName = null, subcontent = '', format = null) {
    this.elementId = elementId;
    this.contentName = contentName;
    this.subcontent = subcontent;
    this.format = format;
    this.selectedText = selectedText;
    this.tagName = tagName;
  }

  fullTag() {
    return utils.tagFromPartsIncludingName(this.contentName, this.tagName, this.subcontent, this.format);
  }
}

class TagTree {
  constructor(tagNodesByName = {}) {
    this.tagNodesByName = tagNodesByName;
  }

  addTag(tagName, subContentName, formats, type, matchingContent, slideNum, tagNum) {
    let tagNameWithFormats = tagName;
    if (formats && formats.length > 0 && (!subContentName || subContentName.length === 0)) {
      tagNameWithFormats = `${tagName}|${formats}`;
    }
    if (this.tagNodesByName[tagNameWithFormats]) {
      const existingNode = this.tagNodesByName[tagNameWithFormats];
      if (subContentName && subContentName.length > 0) {
        const subContentNameWithFormats = this._subContentNameWithFormats(subContentName, formats);
        const subContentNode = new Node(
          subContentName,
          [slideNum],
          type,
          matchingContent,
          formats,
          NODE_TYPES.SUBCONTENT,
          tagNum,
        );
        existingNode.addChild(subContentNode, subContentNameWithFormats);
      }
      existingNode.slideNums = union(existingNode.slideNums, [slideNum]);
    } else {
      const tagNode = new Node(tagName, [slideNum], type, matchingContent, formats, NODE_TYPES.TAG, tagNum);
      if (subContentName && subContentName.length > 0) {
        const subContentNameWithFormats = this._subContentNameWithFormats(subContentName, formats);
        const subContentNode = new Node(
          subContentName,
          [slideNum],
          type,
          matchingContent,
          formats,
          NODE_TYPES.SUBCONTENT,
          tagNum,
        );
        tagNode.addChild(subContentNode, subContentNameWithFormats);
      }
      this.tagNodesByName[tagNameWithFormats] = tagNode;
    }
  }

  _subContentNameWithFormats(subContentName, formats) {
    let subContentNameWithFormats = subContentName;
    if (formats && formats.length > 0) {
      subContentNameWithFormats += `|${formats}`;
    }

    return subContentNameWithFormats;
  }

  getTagNodes() {
    return this.tagNodesByName ? Object.values(this.tagNodesByName).map((tag) => tag.toObj()) : [];
  }

  flattenTree(sortFunc) {
    let flattenedNodes = [];
    if (this.tagNodesByName) {
      let tagNodes = Object.values(this.tagNodesByName);
      if (sortFunc) {
        tagNodes = tagNodes.sort(sortFunc);
      }
      for (let tagNode of tagNodes) {
        flattenedNodes.push(tagNode);
        if (tagNode.children) {
          const childrenNodes = Object.values(tagNode.children);
          for (let i = 0; i < childrenNodes.length; i++) {
            const childNode = childrenNodes[i];
            childNode.isChild = true;
            if (i === childrenNodes.length - 1) {
              childNode.lastChild = true;
            }
            flattenedNodes.push(childNode);
          }
        }
      }
    }

    return flattenedNodes;
  }
}

export default TagTree;
