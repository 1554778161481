import { CLEAR_NOTIFICATION, NOTIFY_ERROR, NOTIFY_SUCCESS } from './actionTypes';

export function addNotificationSuccess(notificationMessage) {
  return {
    type: NOTIFY_SUCCESS,
    notificationMessage,
  };
}

export function addNotificationError(notificationMessage) {
  return {
    type: NOTIFY_ERROR,
    notificationMessage,
  };
}

export function clearNotification(notificationMessage, notificationType) {
  return {
    type: CLEAR_NOTIFICATION,
    notificationMessage,
    notificationType,
  };
}
