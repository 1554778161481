import React from 'react';
import FullPageList from '../FullPageList';
import { Form } from 'react-bulma-components';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import PropTypes from 'prop-types';

const SearchList = WithLoadingAndEmpty(FullPageList);

function CustomSearchList({
  columns,
  presentations,
  isLoading,
  pagination,
  searchValue,
  onSearchChange,
  emptyInfo,
  fetchItems,
}) {
  return (
    <>
      <Form.Input
        id="connected-search-box-input"
        className="mbm"
        type="text"
        placeholder={'Search Recipients'}
        value={searchValue}
        onChange={onSearchChange}
        aria-label={'Search Recipients'}
      />
      <SearchList
        columns={columns}
        entitiesToRender={presentations}
        loading={isLoading}
        pagination={pagination}
        emptyInfo={emptyInfo}
        elements={presentations}
        fetchItems={fetchItems}
      ></SearchList>
    </>
  );
}

CustomSearchList.propTypes = {
  columns: PropTypes.array,
  presentations: PropTypes.array,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func,
  emptyInfo: PropTypes.object,
  fetchItems: PropTypes.func,
};

export default CustomSearchList;
