import React, { Component } from 'react';
import PropType from 'prop-types';
import scheduler from '../../lib/scheduler';

class DaySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {scheduler.Days.map(({ label, value }) => {
          let className = 'day-button is-small is-secondary mrs mbs';
          if (value === this.props.selectedValue) {
            className = className + ' is-active';
          }
          return (
            <button className={className} key={`${label}_${value}`} type="button" onClick={this.onChange(value)}>
              {label}
            </button>
          );
        })}
      </div>
    );
  }

  onChange = (value) => (e) => {
    e.preventDefault();
    this.props.onChange(value);
  };
}

DaySelector.propTypes = {
  selectedValue: PropType.number,
  onChange: PropType.func,
};

export default DaySelector;
