import moment from 'moment';
import Constants from '../components/Constants';

const relativeUnits = ['days', 'weeks', 'months', 'years'];
const SUB = '-';
const RELATIVE_DATE_REGEX = new RegExp(/(days|weeks|months|years)([-+])(\d+)([-+]\d+)?/);

class RelativeDate {
  constructor(value, units, mod = null, operator = SUB) {
    this.value = value;
    this.units = units;
    this.mod = mod;
    this.operator = operator;
  }

  update = (value, units, mod = null, operator = SUB) => {
    this.value = value;
    this.units = units;
    this.mod = mod;
    this.operator = operator;
  };

  updateMod = (mod) => {
    this.mod = mod;
  };

  dateValue = () => {
    let date;
    if (this.operator === SUB) {
      date = moment().subtract(this.value, this.units);
      if (this.mod !== null) {
        date = this.applyMod(date);
      }
    } else {
      date = moment().add(this.value, this.units);
      if (this.mod !== null) {
        date = this.applyMod(date);
      }
    }
    return date;
  };

  applyMod = (date) => {
    if (this.units === relativeUnits[1]) {
      // adding one to this.mod to account for datetime day of week indices
      date.day(this.mod + 1);
    } else if (this.units === relativeUnits[2]) {
      if (this.mod === -1) {
        date.date(date.daysInMonth());
      } else {
        date.date(this.mod);
      }
    } else if (this.units === relativeUnits[3]) {
      if (this.mod === -1) {
        if (date.isLeapYear()) {
          date.dayOfYear(366);
        } else {
          date.dayOfYear(365);
        }
      } else {
        date.dayOfYear(this.mod);
      }
    }
    return date;
  };

  relativeValue = () => {
    /*
    matik-backend takes in relative dates in the form  of a string 'UUU+/-XXX'
    where 'UUU' are time units, '+/-' is plus or minus, and 'XXX' is an integer 
    with an option to include modifiers "UUU+/-XXX+/-MMM" that specifies the day of the week or month    
    */
    let relative = `${this.units}${this.operator}${this.value}`;
    if (this.mod !== null) {
      if (this.units === relativeUnits[1]) {
        const mod = this.mod >= 0 ? `+${this.mod}` : `${this.mod}`;
        relative = `${relative}${mod}`;
      } else if (this.units === relativeUnits[2] || this.units === relativeUnits[3]) {
        if (this.mod === -1) {
          relative = `${relative}${this.mod}`;
        } else {
          relative = `${relative}+${this.mod}`;
        }
      }
    }
    return relative;
  };

  static isRelativeDate = (dateValue) => {
    if (!dateValue || typeof dateValue !== Constants.InputTypes.STRING) {
      return false;
    }
    return RELATIVE_DATE_REGEX.test(dateValue);
  };

  static createFromString = (dateValue) => {
    if (!RelativeDate.isRelativeDate(dateValue)) {
      return null;
    }
    let relativeDate = null;
    const match = dateValue.match(RELATIVE_DATE_REGEX);
    if (match) {
      if (match[2] === SUB) {
        relativeDate = new RelativeDate(parseInt(match[3]), match[1]);
        if (match[4]) {
          relativeDate.updateMod(parseInt(match[4]));
        }
      } else {
        relativeDate = new RelativeDate(parseInt(match[3]), match[1], null, '+');
        if (match[4]) {
          relativeDate.updateMod(parseInt(match[4]));
        }
      }
    }
    return relativeDate;
  };
}

export default RelativeDate;
