import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { isEqual } from 'lodash';

import API from 'lib/api.js';
import utils from 'lib/utils';
import EditableField from '../EditableField';
import TemplateNameForm from './TemplateNameForm';

import { MAlert } from 'components/shared/Alerts';

const EditableTemplateName = ({ template, onSave, orderedInputs, onTemplateUpdateWithServerCall, canEdit }) => {
  // description is stored in a ref so that updating it would not cause unnecessary re-renders
  const descriptionRef = useRef(template.description);

  const [tooltip, setTooltip] = useState(null);
  const [itemTags, setItemTags] = useState(template.item_tags);

  const updateTemplate = async (nameVal, descriptionVal, itemTags, currentTemplate) => {
    if (!nameVal) {
      MAlert('Name is required. Please enter a name', 'Error', 'error');
      return;
    }

    if (!isEqual(currentTemplate.item_tags, itemTags)) {
      // Await item tags EP to get the template reindexed correctly with the tag updates before
      // onTemplateUpdateWithServerCall since the template will reindex again for the name/description
      // updates.
      await API.post(`/item_tags/update_template_tags/${currentTemplate.id}/`, { item_tags: itemTags });
    }
    const templateFieldsToUpdate = {
      id: currentTemplate.id,
      name: nameVal,
      description: descriptionVal,
    };
    onTemplateUpdateWithServerCall(templateFieldsToUpdate);
  };

  const onEditableFieldSave = (nameVal) => {
    if (onSave) {
      onSave(nameVal, descriptionRef.current, itemTags, template);
    }
    setTooltip(getToolTipVal());
    updateTemplate(nameVal, descriptionRef.current, itemTags, template);
  };

  const onDescriptionChange = (newDescription) => {
    descriptionRef.current = newDescription;
  };

  const onItemTagsChange = ({ itemTags }) => {
    setItemTags(itemTags);
  };

  /**
   * Wrapper of draftToHtml to handle cases more appropriately
   * @param {object} draftContent DraftJS Editor content object
   * @return {string} plain HTML string
   */
  const wrappedDraftToHtml = (draftContent) => {
    const isContentEmpty = draftContent?.blocks?.length === 1 && draftContent?.blocks[0]?.text === '';
    // @NOTE: draftToHtml returns '<p></p>\n\n' when the draftContent entry is empty
    // Modified the behavior by returning null if the content is empty
    if (isContentEmpty) {
      return null; // Return null if the content is empty
    }
    return draftToHtml(draftContent);
  };

  const getToolTipVal = () => {
    if (template.description) {
      if (utils.isValidJSON(template.description)) {
        const descJSON = utils.removeCopiedFontStylesFromWYSIWYGOutput(JSON.parse(template.description));
        return wrappedDraftToHtml(descJSON);
      } else {
        return template.description;
      }
    } else {
      return 'No description';
    }
  };

  useEffect(() => {
    setTooltip(getToolTipVal());
  }, [template.description]);

  return (
    <EditableField
      value={template.name}
      onSave={onEditableFieldSave}
      tooltip={tooltip}
      editableTextClassName="title is-3"
      canEdit={canEdit}
      id="templateName"
    >
      <TemplateNameForm
        itemTags={template.item_tags}
        description={template.description}
        onDescriptionChange={onDescriptionChange}
        onItemTagsChange={onItemTagsChange}
        orderedInputs={orderedInputs}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
        template={template}
      />
    </EditableField>
  );
};

EditableTemplateName.propTypes = {
  template: PropTypes.object,
  onSave: PropTypes.func,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default EditableTemplateName;
