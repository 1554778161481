import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthPage from './AuthPage';
import { Form, Card } from 'react-bulma-components';
import { Auth } from '../../lib/auth';
import { MAlert } from '../shared/Alerts';
import API from '../../lib/api';
import { Redirect } from 'react-router-dom';
import SmallLoader from '../shared/SmallLoader';
import matik_logo_black from '../../images/matik_logo_black.svg';
import { ReactComponent as WarningTriangle } from '../../svg/warning_triangle_solid.svg';
import { ReactComponent as Lock } from '../../svg/lock_closed.svg';
import Button from '../lib/Button';
import Constants from 'components/Constants';

class SsoSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      isPasswordInvalid: false,
      passwordErrorMsg: '',
      email: undefined,
      roles: [],
      ssoUrl: undefined,
      ssoName: undefined,
      isLoading: true,
      redirectToHome: false,
    };
  }

  componentDidMount() {
    API.get(
      `/auth/read_token/${this.props.match.params.token}/`,
      (response) => {
        this.setState({
          email: response.data.email,
          roles: response.data.roles,
          ssoUrl: response.data.sso_url,
          ssoName: response.data.sso_name,
          isLoading: false,
        });
      },
      () => {
        this.setState({ redirectToHome: true });
      },
    );
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to={{ pathname: '/' }} />;
    }
    let body = '';
    if (this.state.isLoading) {
      body = <SmallLoader />;
    } else {
      const isSso = this.state.ssoName && this.state.ssoUrl;
      const isProducerAdmin = this.state.roles.includes(Constants.PRODUCER_ADMIN_ROLE);
      body = (
        <>
          {isSso && (
            <form onSubmit={this.onSSOLogin} className="card-content">
              <Form.Field>
                <Form.Control>
                  <Button type="submit" width="fullWidth">
                    Log In With {this.state.ssoName}
                  </Button>
                </Form.Control>
              </Form.Field>
            </form>
          )}
          {(!isSso || (isSso && isProducerAdmin)) && (
            <form onSubmit={this.onResetPasswordSubmit} className="card-content">
              <h1 className="is-size-3 has-text-centered mbs">Set Your Password</h1>
              <p className="has-text-centered mbl">Enter a password for your account</p>
              <Form.Field>
                <Form.Control>
                  <input type="email" readOnly hidden value={this.state.email} />
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Form.Control className="has-icons-left has-icons-right">
                  <Form.Input
                    type="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.onPasswordUpdate}
                  />
                  <span className="icon is-small is-left">
                    <Lock />
                  </span>
                  {this.state.isPasswordInvalid && (
                    <span className="icon is-small is-right">
                      <WarningTriangle />
                    </span>
                  )}
                </Form.Control>
                {this.state.isPasswordInvalid && <p className="help is-danger">{this.state.passwordErrorMsg}</p>}
              </Form.Field>
              <Form.Field>
                <Form.Control>
                  <Button type="submit" category={!isSso ? 'primary' : 'secondary'} width="fullWidth">
                    Set Password
                  </Button>
                </Form.Control>
              </Form.Field>
            </form>
          )}
        </>
      );
    }

    return (
      <AuthPage>
        <div className="auth-logo">
          <img src={matik_logo_black} alt="matik logo" height="40px" />
        </div>
        <Card className="auth-card">{body}</Card>
      </AuthPage>
    );
  }

  onPasswordUpdate = (e) => {
    this.setState({ password: e.target.value, isPasswordInvalid: false, passwordErrorMsg: '' });
  };

  onResetPasswordSubmit = (e) => {
    e.preventDefault();
    if (!this.state.password) {
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: 'Password is required' });
      return;
    }

    if (this.state.password.length < 8) {
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: 'Password should be longer than 8 characters' });
      return;
    }

    this.setState({ isPasswordInvalid: false });
    Auth.resetPassword(this.state.password, this.props.match.params.token, this.onResetSuccess, this.onResetError);
  };

  onResetSuccess = (result) => {
    MAlert('Password successfully set');
    Auth.storeAuthToken(result.data.access_token);
    API.track('successful_login');
    this.setState({ redirectToHome: true });
  };

  onResetError = (err) => {
    if (err.data) {
      let errorMessage = 'Something went wrong';
      if (err.data.status === 'error') {
        errorMessage = err.data.message;
      }
      this.setState({ isPasswordInvalid: true, passwordErrorMsg: errorMessage });
    }
  };

  onSSOLogin = (e) => {
    e.preventDefault();
    return window.location.assign(this.state.ssoUrl);
  };
}

SsoSignUp.propTypes = {
  match: PropTypes.object,
};

export default SsoSignUp;
