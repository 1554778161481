import React from 'react';
import PropTypes from 'prop-types';

const TabHeader = (props) => {
  return (
    <div className={`tab-header ${props.inverted ? 'inverted' : ''}`}>
      {props.tabs.map((tab) => {
        return (
          <a
            key={tab.label}
            href=""
            onClick={tab.onClick}
            className={`tab-header-item ${tab.selected ? 'selected' : ''}`}
          >
            <span className="tab-header-label">
              {tab.icon}&nbsp;{tab.label}
            </span>
          </a>
        );
      })}
    </div>
  );
};

TabHeader.propTypes = {
  inverted: PropTypes.bool,
  tabs: PropTypes.array,
};

export default TabHeader;
