import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import upload_file_icon from '../../../images/upload_file_icon.svg';
import upload_button_icon from '../../../images/icons/arrow_upload-20x20-regular.svg';
import Button from '../../lib/Button';

function FileUpload(props) {
  return (
    <Dropzone onDrop={props.onSelectFile}>
      {({ getRootProps, getInputProps }) => (
        <section className="upload-container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <img src={upload_file_icon} alt="Drop files here" className="upload-icon" />
            <input {...getInputProps()} multiple={false} accept={props.inputAccept} />
            <Button type="button" category="secondary">
              <span className="icon is-small">
                <img src={upload_button_icon} alt="upload file" />
              </span>
              <span>{props.buttonText}</span>
            </Button>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

FileUpload.propTypes = {
  buttonText: PropTypes.string.isRequired,
  inputAccept: PropTypes.string,
  onSelectFile: PropTypes.func.isRequired,
};

export default FileUpload;
