import ace from 'ace-builds/src-noconflict/ace';
import Constants from 'components/Constants';

import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'aceeditor/ace-matik.css';

/**
 * Add input and dynamic content auto-complete suggestions to the given editor.
 * When combined with one of the modes defined below, this will enable input
 * and dynamic-content syntax highlighting and autocompletion.
 *
 * @param {*} editor
 * @param {*} inputsByName
 * @param {*} allDynamicContentNamesById
 */
export function addCompleters(editor, inputsByName, allDynamicContentNamesById) {
  const completers = [];
  const mode = editor.session.getMode();

  const inputs = Object.values(inputsByName || {});
  if (inputs.length > 0) {
    completers.push({
      getCompletions: (editor, session, pos, prefix, callback) => {
        // don't show these until the user has first typed the '&:' part
        if (prefix.indexOf('&:') !== 0) {
          callback(null, []);
          return;
        }
        callback(
          null,
          inputs.map((input) => ({
            caption: `&:${input.name}`,
            value: `&:${input.name}`,
            score: 1000,
            meta: 'input',
          })),
        );
      },
    });
    mode.setInputs(inputs.map((input) => input.name));
  } else {
    mode.setInputs(null);
  }

  const dynamicContent = Object.values(allDynamicContentNamesById || {});
  if (dynamicContent.length > 0) {
    completers.push({
      getCompletions: (editor, session, pos, prefix, callback) => {
        if (prefix.indexOf('{{') !== 0) {
          callback(null, []);
          return;
        }
        // TODO: handle any }+ immediately following the dc name?
        callback(
          null,
          dynamicContent.map((dc) => ({
            caption: `{{${dc.name}}}`,
            value: `{{${dc.name}}}`,
            score: 100,
            meta: 'dynamic content',
          })),
        );
      },
    });
    mode.setDynamicContent(dynamicContent.map((dc) => dc.name));
  } else {
    mode.setDynamicContent(null);
  }

  editor.session.setMode(mode);

  // force re-tokenization to show valid/invalid inputs and dc tags
  editor.session.resetCaches();
  // workaround for resetCaches-causes-cursor-to-disappear bug
  editor.session.setUseWrapMode(!editor.session.getUseWrapMode());
  editor.session.setUseWrapMode(!editor.session.getUseWrapMode());

  // https://github.com/ajaxorg/ace/issues/3337
  // We need to do a little hacking to ensure the '&:' and '{{' input and dc prefixes get
  // properly included in the autocomplete logic. We do that by using the completer
  // 'identifierRegexps' to identify all the valid characters that might be part of the
  // autocomplete values.
  // This code isn't well implemented on the ace side, so the regex for all of the completers
  // needs to be the same and encompass all of the symbols that might be part of any completion
  // token prefix. i.e. '{', '&', ':', '-', and word chars
  completers.forEach((completer) => (completer.identifierRegexps = [/[{&:\w-]/]));
  editor.completers = completers; // this will disable sql completions
}

/**
 * Listen for events that indicate whether an input details popover should
 * be shown/hidden.
 *
 * @param {*} editor The initialized ace editor instance
 * @param {*} inputs Object populated with input data, keyed by input id
 * @param {*} onPopoverChange Triggered when a user's editor activity should show or
 * hide an input detail popover. e.g. the cursor is on an input name token. The callback
 * argument will be null when the popover should be hidden or an object like this when
 * it should be shown:
 * ```
 * {
 *   input, // the input id/name
 *   top, // the pixel position of top of the input token, relative to the editor container
 *   left, // the pixel position of the left of the first character of the input token
 *   lineHeight // the number of pixels tall each editor line is
 * }
 * ```
 *
 * @returns A function that when called will stop listening for events. Suitable for
 * returning from the `useEffect()` callback.
 */
export function addPopoverListener(editor, inputs, onPopoverChange, eventMode = 'cursor') {
  if (eventMode === 'cursor') {
    return addPopoverCursorListener(editor, inputs, onPopoverChange);
  } else {
    return addPopoverMouseListener(editor, inputs, onPopoverChange);
  }
}
function addPopoverMouseListener(editor, inputs, onPopoverChange) {
  if (!editor?.session || !inputs) {
    return () => {};
  }

  let showThrottle;
  let hideThrottle;

  const stopShow = () => {
    if (showThrottle) {
      clearTimeout(showThrottle);
      showThrottle = null;
    }
  };
  const stopHide = () => {
    if (hideThrottle) {
      clearTimeout(hideThrottle);
      hideThrottle = null;
    }
  };

  const showPopover = (inputName, row, col) => {
    // use a placeholder if we don't have a ref to a saved input so we can open a sidepane with the
    // reference and allow the user to create the new input
    const input = inputs?.[inputName] || { name: inputName };
    const visibleTokenPos = editor.session.documentToScreenPosition(row, col);

    let x = Math.round(visibleTokenPos.column * editor.renderer.characterWidth);
    x += editor.renderer.$padding + editor.renderer.gutterWidth;
    x -= editor.renderer.scrollLeft;

    let y = visibleTokenPos.row * editor.renderer.lineHeight;
    y -= editor.renderer.scrollTop;

    onPopoverChange({
      input,
      top: y,
      left: x,
      lineHeight: editor.renderer.lineHeight,
    });
  };

  const checkShowPopoverAtMousePosition = (mouseEvent) => {
    const mouseTextPosition = editor.renderer.screenToTextCoordinates(mouseEvent.x, mouseEvent.y);
    const currentToken = editor.session.getTokenAt(mouseTextPosition.row, mouseTextPosition.column + 1);
    if (currentToken && currentToken.type.indexOf('input') > -1 && currentToken.value.length > 2) {
      if (!showThrottle) {
        showThrottle = setTimeout(() => {
          showThrottle = null;
          stopHide();
          showPopover(currentToken.value.substring(2), mouseTextPosition.row, currentToken.start);
        }, 250);
      }
    } else {
      stopShow();

      if (!hideThrottle) {
        hideThrottle = setTimeout(() => {
          onPopoverChange(null);
          hideThrottle = null;
        }, 500);
      }
    }
  };

  editor.on('mousemove', checkShowPopoverAtMousePosition);

  const hidePopover = () => {
    onPopoverChange(null);

    stopShow();
    stopHide();
  };
  editor.session.on('changeScrollTop', hidePopover);

  return () => {
    editor.off('mousemove', checkShowPopoverAtMousePosition);
    editor.session.off('changeScrollTop', hidePopover);

    stopShow();
    stopHide();
  };
}

function addPopoverCursorListener(editor, inputs, onPopoverChange) {
  if (!editor?.session?.selection || !inputs) {
    return () => {};
  }

  const checkShowPopover = () => {
    if (editor.isFocused()) {
      const cursor = editor.session.selection.getCursor();
      const currentToken = editor.session.getTokenAt(cursor.row, cursor.column + 1);

      if (currentToken && currentToken.type.indexOf('input') > -1 && currentToken.value.length > 2) {
        const inputName = currentToken.value.substring(2);

        // use a placeholder if we don't have a ref to a saved input so we can open a sidepane with the
        // reference and allow the user to create the new input
        const input = inputs?.[inputName] || { name: inputName };
        // The cursor is on a verified input, calculate position relative to the editor container and show the popover details
        const visibleTokenPos = editor.session.documentToScreenPosition(cursor.row, currentToken.start);

        let x = Math.round(visibleTokenPos.column * editor.renderer.characterWidth);
        x += editor.renderer.$padding + editor.renderer.gutterWidth;
        x -= editor.renderer.scrollLeft;

        let y = visibleTokenPos.row * editor.renderer.lineHeight;
        y -= editor.renderer.scrollTop;

        onPopoverChange({
          input,
          top: y,
          left: x,
          lineHeight: editor.renderer.lineHeight,
        });
        return;
      }
    }

    // The cursor is not on an input or we don't have focus: hide any popover details
    onPopoverChange(null);
  };
  editor.session.selection.on('changeCursor', checkShowPopover);
  editor.on('focus', checkShowPopover);
  checkShowPopover();

  const hidePopover = () => onPopoverChange(null);
  editor.session.on('changeScrollTop', hidePopover);

  return () => {
    editor.session.selection.off('changeCursor', checkShowPopover);
    editor.off('focus', checkShowPopover);

    editor.session.off('changeScrollTop', hidePopover);
  };
}

function getScrollParent(element) {
  if (element instanceof HTMLElement) {
    const getStyle = (el, prop) => window.getComputedStyle(el, null).getPropertyValue(prop);
    const isScrolling = (el) => {
      let overflow = [getStyle(el, 'overflow'), getStyle(el, 'overflow-y')];
      return overflow.indexOf('auto') > -1 || overflow.indexOf('scroll') > -1;
    };
    if (isScrolling(element)) {
      return element;
    } else {
      return getScrollParent(element.parentElement);
    }
  }
}

function getOffsetParents(element) {
  const offsets = [];
  if (element instanceof HTMLElement) {
    offsets.push(element);
    let parent = element.offsetParent;
    if (!parent) {
      parent = element.parentElement;
    }
    offsets.push(...getOffsetParents(parent));
  }
  return offsets;
}

/**
 * Whenever the cursor moves, check to see that it is visible in the current scrollable
 * viewport. If not, scroll the parent so that the cursor is visible.
 *
 * Use this if your editor is fitted to content size and/or the editor is larger than
 * the scrollable area in the window.
 *
 * @param {*} editor The initialized ace editor instance
 *
 * @returns A function that when called will stop listening for events. Suitable for
 * returning from the `useEffect()` callback.
 */
export function scrollParentToFollowCursor(editor) {
  const renderer = editor.renderer;

  // The scroll parent is the closest ancestor which can be scrolled vertically. We're assuming it
  // won't change while the editor is mounted so we only need to find it once.
  const scrollParent = getScrollParent(renderer.container.parentElement);
  if (!scrollParent) {
    // There should always be a scroll parent (except for unit tests)
    return;
  }

  // It's also unlikely that the offsetParent chain will change after the editor is mounted.
  const scrollParentOffsets = getOffsetParents(scrollParent);
  const containerOffsets = getOffsetParents(renderer.container.parentElement);

  // But the actual offset size might change any time the window is resized or the DOM is modified so we'll
  // calculate these on demand.
  const getContainerToScrollParentOffset = () => {
    // We need to calculate the offset between the top of the editor container and the scrolling element.
    // Note the scroller might not be a direct offset anscestor, so we are looking for the first common ancestor in the
    // offset chains.
    let totalContainerOffset = 0;
    let totalScrollOffset = 0;
    for (const containerOffset of containerOffsets) {
      let foundCommon = false;
      totalScrollOffset = 0;
      for (const scrollParentOffset of scrollParentOffsets) {
        totalScrollOffset += scrollParentOffset.offsetTop;
        if (containerOffset === scrollParentOffset) {
          foundCommon = true;
          break;
        }
      }
      if (foundCommon) {
        break;
      }
      totalContainerOffset += containerOffset.offsetTop;
    }

    return totalContainerOffset - totalScrollOffset;
  };

  const session = editor.session;
  const scrollToCursor = () => {
    const cursor = session.selection.getCursor();
    const screenPos = session.documentToScreenPosition(cursor.row, cursor.column);

    let y = screenPos.row * renderer.lineHeight;
    y -= renderer.scrollTop;

    // pixels from the top of the editor container to the top of the scroller
    const containerScrollerOffset = getContainerToScrollParentOffset();

    // Cursor position relative to the top of the scroller (with a buffer)
    const cursorPos = {
      top: y + containerScrollerOffset - 20,
      bottom: y + containerScrollerOffset + renderer.lineHeight + 50,
    };

    // The visible area relative to the top of the scroller
    const visibleWindow = {
      top: scrollParent.scrollTop,
      bottom: scrollParent.scrollTop + scrollParent.clientHeight,
    };

    if (cursorPos.top < visibleWindow.top) {
      scrollParent.scrollTo(0, cursorPos.top);
    } else if (cursorPos.bottom > visibleWindow.bottom) {
      scrollParent.scrollTo(0, cursorPos.bottom - scrollParent.clientHeight);
    }
  };
  session.selection.on('changeCursor', scrollToCursor);
  return () => {
    session.selection.off('changeCursor', scrollToCursor);
  };
}

// Support for "matik" theme which just adds the "ace-matik" class to the editor container when we set
// theme="matik" on the editor.
// We use that to scope CSS in matik.css
ace.define('ace/theme/matik', ['require', 'exports', 'module'], function (acerequire, exports, module) {
  module.exports.isDark = false;
  module.exports.cssClass = 'ace-matik';
});
ace.define('ace/theme/matik-one-line', ['require', 'exports', 'module'], function (acerequire, exports, module) {
  module.exports.isDark = false;
  module.exports.cssClass = 'ace-matik-one-line';
});

function addHighlightRules() {
  // input &: references will always be highlighted.

  // DC {{}} references highlighting can be enabled or disabled here:
  this.highlightDynamicContent = false;

  // When these get set, we will highlight valid/invalid values
  this.inputNames = [];
  this.dynamicContentNames = [];

  // we want our rules ahead of any others
  for (var i in this.$rules) {
    this.$rules[i].unshift({
      token: (value) => {
        if (this.inputNames?.length > 0) {
          if (this.inputNames.indexOf(value.substring(2)) > -1) {
            if (Object.values(Constants.MATIK_USER_INPUTS).includes(value.substring(2))) {
              return 'matik-user-input.valid-text';
            }
            return 'input.valid-text';
          } else {
            return 'input.invalid-text';
          }
        }
        return 'input';
      },
      regex: /&:(?:\w|[-])*/,
    });
    this.$rules[i].unshift({
      token: (value) => {
        if (!this.highlightDynamicContent) {
          return 'text';
        }

        if (this.dynamicContentNames?.length > 0) {
          if (this.dynamicContentNames.indexOf(value.substring(2, value.length - 2)) > -1) {
            return 'dynamic-content.valid-text';
          } else {
            return 'dynamic-content.invalid-text';
          }
        }
        return 'dynamic-content';
      },
      regex: /{{(?:\w|[-])*}?}?/,
    });
  }
}

// sort-of multiple inheritance. Note that "this.$highlightRules" doesn't get populated until the mode is set in the session,
// so don't call these methods until after calling "session.setMode(customMode)"
const modeMixin = {
  setInputs(inputNames) {
    this.$highlightRules.inputNames = inputNames;
  },
  setDynamicContent(dynamicContentNames) {
    this.$highlightRules.dynamicContentNames = dynamicContentNames;
  },
  setHighlightDynamicContent(doHighlight) {
    this.$highlightRules.highlightDynamicContent = doHighlight;
  },
};

// Expose modes which can be enabled by setting the AtomEditor 'mode' param.

ace.define(
  'ace/mode/matik-sql',
  ['require', 'exports', 'module', 'ace/mode/sql_highlight_rules', 'ace/mode/sql'],
  function (acerequire, exports) {
    class CustomSqlHighlightRules extends acerequire('ace/mode/sql_highlight_rules').SqlHighlightRules {
      constructor() {
        super();
        this.addHighlightRules();
      }
    }
    CustomSqlHighlightRules.prototype.addHighlightRules = addHighlightRules;

    /**
     * SQL syntax highlighting plus input and (optionally) dynamic content tag highlighting.
     */
    class SqlMode extends acerequire('ace/mode/sql').Mode {
      constructor() {
        super();
        this.HighlightRules = CustomSqlHighlightRules;
      }
    }
    Object.assign(SqlMode.prototype, modeMixin);

    exports.Mode = SqlMode;
  },
);

ace.define(
  'ace/mode/matik-text',
  ['require', 'exports', 'module', 'ace/mode/text_highlight_rules', 'ace/mode/text'],
  function (acerequire, exports) {
    class CustomTextHighlightRules extends acerequire('ace/mode/text_highlight_rules').TextHighlightRules {
      constructor() {
        super();
        this.addHighlightRules();
      }
    }
    CustomTextHighlightRules.prototype.addHighlightRules = addHighlightRules;

    /**
     * only input and (optionally) dynamic content tag highlighting
     */
    class TextMode extends acerequire('ace/mode/text').Mode {
      constructor() {
        super();
        this.HighlightRules = CustomTextHighlightRules;
      }
    }
    Object.assign(TextMode.prototype, modeMixin);

    exports.Mode = TextMode;
  },
);
