export const flowMenuItems = {
  PROCESSING_FLOWS: 'processing_flows',
  SCHEDULED_FLOWS: 'scheduled_flows',
  MANUAL_FLOWS: 'manual_flows',
  MY_FLOWS: 'my_flows',
};

export const flowTypes = {
  SCHEDULED: 'scheduled',
  MANUAL: 'manual',
};
export const runStatusHeaders = new Map([
  ['PROCESSING', 'Run in progress'],
  ['NOT_STARTED', 'Upcoming run'],
  ['DONE', 'Finished runs'],
  ['CANCELED', 'Canceled runs'],
  ['ERROR', 'Runs with errors'],
]);
export const runStatus = {
  PROCESSING: 'Processing',
  NOT_STARTED: 'Not Started',
  DONE: 'Done',
  CANCELED: 'Canceled',
  ERROR: 'Error',
};

export const flowStatus = {
  QUEUED: 'queued',
  PROCESSING: 'processing',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};
