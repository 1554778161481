import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithTestQuery from './WithTestQuery';
import Constants from '../Constants';
import utils from '../../lib/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps as mapUiDispatchToProps } from '../../redux/ui/dispatchers';
import { ReactComponent as Run } from '../../svg/run.svg';
import dataSources from '../../lib/dataSources';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import ButtonsTestInputsModal from './ButtonsTestInputsModal';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';

class FixedFormButtons extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let testButtonText = '';
    if (this.props.dynamicContentType === Constants.DynamicContentTypes.CONDITIONAL) {
      testButtonText = 'Test Condition';
    } else if (this.props.method === Constants.DynamicContentMethods.QUERY) {
      testButtonText = 'Test Query';
    } else if (this.props.method === Constants.DynamicContentMethods.API) {
      testButtonText = 'Test API';
    } else if (this.props.method === Constants.DynamicContentMethods.INSIGHTS) {
      testButtonText = 'Test Insights';
    }

    let saveButtonStatus = 'default';
    if (!this.props.isChanged) {
      saveButtonStatus = 'disabled';
    } else if (this.props.isLoading) {
      saveButtonStatus = 'loading';
    }

    let queryObj;
    try {
      queryObj = this.props.query ? JSON.parse(this.props.query) : {};
    } catch (_) {
      queryObj = {};
    }

    const style = {};
    if (this.props.buttonWidth) {
      style.width = this.props.buttonWidth;
    }

    return (
      <React.Fragment>
        <div
          className={`${this.props.ui.fullScreenModal ? 'bottom-buttons-fs' : 'bottom-buttons'} tab-buttons ${
            this.props.noBackground ? 'no-background' : ''
          }`}
          style={style}
        >
          <div className="mb-5">
            <ButtonGroup align="right">
              {this.props.showCancel && (
                <Button category="secondary" onClick={this.props.onClose} type="button">
                  Cancel
                </Button>
              )}
              {testButtonText && (
                <Button
                  category="secondary"
                  onClick={this.test}
                  status={this.props.testIsLoading || this.props.isLoading ? 'loading' : 'default'}
                  id="test-dynamic-content"
                  type="button"
                >
                  <Run className="mrs" />
                  {testButtonText}
                </Button>
              )}
              <Button
                id={`${this.props.saveButtonText.toLowerCase()}-dynamic-content`}
                onClick={this.props.submitForm}
                status={saveButtonStatus}
              >
                {this.props.saveButtonText}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <ButtonsTestInputsModal
          entityId={this.props.entityId}
          entityType={this.props.entityType}
          queryObj={queryObj}
          setTestIsLoading={this.props.setTestIsLoadingToVal}
          setTestResult={this.setTestResult}
          testButtonText={testButtonText}
          ui={this.props.ui}
        />
      </React.Fragment>
    );
  }
  test = (e) => {
    e.preventDefault();
    this.props.updateTestResult(null); // Sometimes, remnants from previous test don't get fully cleared. This is a way to clear it.
    let query = this.props.query;
    if (query && utils.isValidJSON(query)) {
      let queryObj = JSON.parse(query);
      if (
        this.props?.dataSource?.type === Constants.DATA_SOURCE_TYPES.salesforce &&
        queryObj.source !== 'reports' &&
        !queryObj.soql_string
      ) {
        queryObj['soql_string'] = dataSources.generateSOQLQueryString(this.props.query);
        query = JSON.stringify(queryObj);
      }
    }

    if (this.props.dynamicContentType === Constants.DynamicContentTypes.CONDITIONAL) {
      this.props.openModal('buttonsTestInputsModal');
    } else {
      this.props.testQuery(e, query);
    }
  };

  setTestIsLoading = (isLoading) => {
    this.props.setTestIsLoadingToVal(isLoading);
  };

  setTestResult = (testResult) => {
    this.props.updateTestResult(testResult);
  };
}

FixedFormButtons.propTypes = {
  buttonWidth: PropTypes.string,
  dataSource: PropTypes.object,
  method: PropTypes.string,
  dynamicContentType: PropTypes.string,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  isChanged: PropTypes.bool,
  isLoading: PropTypes.bool,
  query: PropTypes.string,
  saveButtonText: PropTypes.string,
  setTestIsLoadingToVal: PropTypes.func,
  submitForm: PropTypes.func,
  testIsLoading: PropTypes.bool,
  testQuery: PropTypes.func,
  updateTestResult: PropTypes.func,
  closeSidepane: PropTypes.func,
  ui: PropTypes.object,
  openUnsavedChangesModal: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  onClose: PropTypes.func,
  showCancel: PropTypes.bool,
  noBackground: PropTypes.bool,
  setTestIsLoading: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    ...mapUiStateToProps(state, ownProps),
  };
}

function mapDispatchToProps(state, ownProps) {
  const props = {
    ...ownProps,
    entityType: 'template',
  };
  return {
    ...mapUiDispatchToProps(state, props),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithTestQuery(FixedFormButtons));
