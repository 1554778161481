import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Level } from 'react-bulma-components';

class AnalyticsEnterpriseOverview extends Component {
  render() {
    return (
      <Box className="is-shadowless has-light-gray-border is-paddingless">
        <Level>
          <Level.Item className="analytics-overview-cell">
            <div>
              <p className="title">{this.props.analyticsData.presentation_count}</p>
              <p>Total Presentations</p>
            </div>
          </Level.Item>
          <Level.Item className="left-border analytics-overview-cell">
            <div>
              <p className="title">{this.props.analyticsData.presentations_per_user}</p>
              <p>Average Presentations per User</p>
            </div>
          </Level.Item>
          <Level.Item className="left-border analytics-overview-cell">
            <div>
              <p className="title">{this.props.analyticsData.activated_licenses}%</p>
              <p>Provisioned Licenses</p>
            </div>
          </Level.Item>
        </Level>
      </Box>
    );
  }
}

AnalyticsEnterpriseOverview.propTypes = {
  isFetching: PropTypes.bool,
  analyticsData: PropTypes.object,
};

export default AnalyticsEnterpriseOverview;
