import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import { Form } from 'react-bulma-components';
import { MAlert } from '../shared/Alerts';
import { Auth } from '../../lib/auth';
import { ReactComponent as Warning } from '../../svg/warning_triangle_solid.svg';
import { ReactComponent as Lock } from '../../svg/lock_closed.svg';
import Button from '../lib/Button';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  render() {
    let currentPasswordInputClass = '';
    if (this.state.isCurrentPasswordInvalid) {
      currentPasswordInputClass += ' is-danger';
    }
    let newPasswordInputClass = 'input';
    if (this.state.isNewPasswordInvalid) {
      newPasswordInputClass += ' is-danger';
    }
    let newPasswordRepeatClass = 'input';
    if (this.state.isNewPasswordRepeatInvalid) {
      newPasswordRepeatClass += ' is-danger';
    }
    return (
      <form className="change-password">
        <Form.Field>
          <Form.Label>Current Password</Form.Label>
          <Form.Control className="has-icons-left has-icons-right">
            <Form.Input
              className={currentPasswordInputClass}
              type="password"
              placeholder="Current password"
              value={this.state.currentPassword}
              onChange={this.onCurrentPasswordUpdate}
            />
            <span className="icon is-small is-left">
              <Lock />
            </span>
            {this.state.isCurrentPasswordInvalid && (
              <span className="icon is-small is-right">
                <Warning />
              </span>
            )}
          </Form.Control>
          {this.state.isCurrentPasswordInvalid && (
            <p className="help is-danger">{this.state.currentPasswordErrorMsg}</p>
          )}
        </Form.Field>
        <div className="is-flex mbm">
          <Form.Field className="mrm">
            <Form.Label>New Password</Form.Label>
            <Form.Control className="has-icons-left has-icons-right">
              <Form.Input
                className={newPasswordInputClass}
                type="password"
                placeholder="Password"
                value={this.state.newPassword}
                onChange={this.onNewPasswordUpdate}
              />
              <span className="icon is-small is-left">
                <Lock />
              </span>
              {this.state.isNewPasswordInvalid && (
                <span className="icon is-small is-right">
                  <Warning />
                </span>
              )}
            </Form.Control>
            {this.state.isNewPasswordInvalid && <p className="help is-danger">{this.state.newPasswordErrorMsg}</p>}
          </Form.Field>
          <Form.Field>
            <Form.Label>Repeat New Password</Form.Label>
            <Form.Control className="has-icons-left has-icons-right">
              <Form.Input
                className={newPasswordRepeatClass}
                type="password"
                placeholder="Repeat Password"
                value={this.state.newPasswordRepeat}
                onChange={this.onNewPasswordRepeatUpdate}
              />
              <span className="icon is-small is-left">
                <Lock />
              </span>
              {this.state.isNewPasswordRepeatInvalid && (
                <span className="icon is-small is-right">
                  <Warning />
                </span>
              )}
            </Form.Control>
            {this.state.isNewPasswordRepeatInvalid && (
              <p className="help is-danger">{this.state.newPasswordRepeatErrorMsg}</p>
            )}
          </Form.Field>
        </div>
        <Form.Control>
          <Button category="secondary" onClick={this.onPasswordChangeSubmit}>
            Change Password
          </Button>
        </Form.Control>
      </form>
    );
  }

  getInitialState = () => ({
    currentPassword: '',
    isCurrentPasswordInvalid: false,
    currentPasswordErrorMsg: '',
    newPassword: '',
    isNewPasswordInvalid: false,
    newPasswordErrorMsg: '',
    newPasswordRepeat: '',
    isNewPasswordRepeatInvalid: false,
    newPasswordRepeatErrorMsg: '',
  });

  onClose = (e) => {
    e.preventDefault();
    this.setState(this.getInitialState());
    this.props.onClose();
  };

  onPasswordChangeSubmit = (e) => {
    e.preventDefault();
    const data = {
      password: this.state.currentPassword,
      new_password: this.state.newPassword,
      new_password_confirm: this.state.newPasswordRepeat,
    };
    this.setState({ isCurrentPasswordInvalid: false, isNewPasswordInvalid: false, isNewPasswordRepeatInvalid: false });
    API.post('/auth/change_password/', data, this.onChangeSuccess, this.onChangeError);
  };

  onChangeSuccess = (result) => {
    const authToken = result.data.access_token;
    Auth.storeAuthToken(authToken);
    MAlert('Password successfully changed', 'Success', 'success');
    this.setState(this.getInitialState());
  };

  onChangeError = (err) => {
    if (err.response && err.response.data) {
      const newPasswordErr = err.response.data.status === 'PasswordError' ? err.response.data.message : '';
      const newPasswordRepeatErr =
        err.response.data.status === 'NewPasswordRepeatError' ? err.response.data.message : '';
      const currentPasswordErr = err.response.data.status_code === 401 ? err.response.data.message : '';
      if (currentPasswordErr) {
        this.setState({ isCurrentPasswordInvalid: true, currentPasswordErrorMsg: currentPasswordErr });
      }
      if (newPasswordErr) {
        this.setState({ isNewPasswordInvalid: true, newPasswordErrorMsg: newPasswordErr });
      }
      if (newPasswordRepeatErr) {
        this.setState({ isNewPasswordRepeatInvalid: true, newPasswordRepeatErrorMsg: newPasswordRepeatErr });
      }
    } else {
      MAlert('Unknown error: ' + err.message, 'Error', 'error');
    }
  };

  onCurrentPasswordUpdate = (e) => {
    this.setState({ currentPassword: e.target.value });
  };

  onNewPasswordUpdate = (e) => {
    this.setState({ newPassword: e.target.value });
  };

  onNewPasswordRepeatUpdate = (e) => {
    this.setState({ newPasswordRepeat: e.target.value });
  };
}

ChangePassword.propTypes = {
  onPasswordChange: PropTypes.func,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangePassword;
