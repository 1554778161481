import React from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  FilterButton,
  FilterForm,
  FormDataConsumer,
  Datagrid,
  Show,
  Edit,
  ReferenceManyField,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  SimpleForm,
  EditButton,
  required,
  DateField,
  Create,
  BooleanField,
  BooleanInput,
  List,
  SortButton,
  SearchInput,
  RadioButtonGroupInput,
  FileField,
  FileInput,
  NumberInput,
} from 'react-admin';
import { AddBillingButton } from './Billing';

export const EnterpriseShowForm = () => (
  <React.Fragment>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="domain" />
    <BooleanField source="is_self_serve" />
    <DateField source="updated_on" />
    <DateField source="created_on" />
    <TextField source="customer_id" />
  </React.Fragment>
);

const subscriptionStatusChoices = [
  { id: 'active', name: 'Active' },
  { id: 'trialing', name: 'Trialing' },
  { id: 'incomplete', name: 'Incomplete' },
  { id: 'incomplete_expired', name: 'Incomplete Expired' },
  { id: 'past_due', name: 'Past Due' },
  { id: 'canceled', name: 'Canceled' },
  { id: 'unpaid', name: 'Unpaid' },
];

const tierChoices = [
  { id: 'matik_team', name: 'Team' },
  { id: 'matik_enterprise', name: 'Enterprise' },
];

const enterpriseFilters = [
  <SearchInput source="name_q" alwaysOn resettable key="id" />,
  <RadioButtonGroupInput
    key="id"
    label="Subscription Status"
    source="billing.subscription_status"
    choices={subscriptionStatusChoices}
    optionValue="id"
  />,
  <RadioButtonGroupInput key="id" label="Tier" source="billing.plan_id" choices={tierChoices} optionValue="id" />,
];

const EnterpriseToolbar = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <FilterForm
      filters={enterpriseFilters}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
    />
    <div>
      <CreateButton />
      <SortButton fields={['id', 'name', 'created_on', 'updated_on']} />
      <FilterButton filters={enterpriseFilters} />
    </div>
  </div>
);

export const EnterpriseList = (props) => (
  <List perPage={25} {...props} actions={<EnterpriseToolbar />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="domain" />
      <BooleanField source="is_self_serve" />
      <BooleanField source="settings.matik_mail_enabled" label="Mail Enabled" />
      <BooleanField source="settings.ai_enabled" label="AI Enabled" />
      <DateField source="updated_on" />
      <DateField source="created_on" />
      <TextField source="billing.subscription_status" label="Subscription Status" />
      <ShowButton />
    </Datagrid>
  </List>
);

EnterpriseList.propTypes = {};

const EnterpriseTitle = ({ record }) => {
  return <span>Enterprise {record ? `"${record.name}"` : ''}</span>;
};

EnterpriseTitle.propTypes = {
  record: PropTypes.any,
};

const CustomCsvUploadField = () => {
  return (
    <FileInput
      source="files"
      label="Optional: Upload Users CSV"
      accept=".csv"
      placeholder={
        <div>
          <p>Drop a file here to upload, or click to select one.</p>
          <br></br>
          <p>The CSV format should be: </p>
          <p>· email · name · title · phone · active (e.g. true) · rolenames (e.g. consumer, producer) ·</p>
        </div>
      }
    >
      <FileField source="src" title="title" />
    </FileInput>
  );
};

export const EnterpriseEdit = (props) => (
  <Edit title={<EnterpriseTitle />} mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="domain" />
      <BooleanInput source="is_self_serve" />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <BooleanInput
              disabled={formData.is_self_serve}
              helperText={formData.is_self_serve ? 'Feature is automatically enabled for trialing enterprises.' : null}
              label="Mail Enabled"
              source="settings.matik_mail_enabled"
              {...rest}
            />
            <BooleanInput
              disabled={formData.is_self_serve}
              helperText={formData.is_self_serve ? 'Feature is automatically enabled for trialing enterprises.' : null}
              label="AI Enabled"
              source="settings.ai_enabled"
              {...rest}
            />
          </>
        )}
      </FormDataConsumer>
      <BooleanInput source="settings.uses_okta" label="Uses Okta" />
      <BooleanInput source="settings.uses_one_login" label="Uses OneLogin" />
      <BooleanInput source="settings.uses_azure" label="Uses Azure" />
      <TextInput source="saml_metadata_url" />
      <TextInput source="name" validate={required('Name is required')} />
      <TextInput source="customer_id" />
      <NumberInput
        source="settings.query_parallelism"
        label="Query Parallelism: CAUTION ONLY CHANGE THIS WITH INPUT FROM CUSTOMER"
      />
      <CustomCsvUploadField></CustomCsvUploadField>
    </SimpleForm>
  </Edit>
);

export const EnterpriseCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required('Name is required')} />
      <BooleanInput source="is_self_serve" />
      <BooleanInput source="settings.uses_okta" label="Uses Okta" />
      <BooleanInput source="settings.uses_one_login" label="Uses OneLogin" />
      <BooleanInput source="settings.uses_azure" label="Uses Azure" />
      <TextInput source="saml_metadata_url" />
    </SimpleForm>
  </Create>
);

export const EnterpriseShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="domain" />
        <BooleanField source="uses_okta" />
        <BooleanField source="is_self_serve" />
        <BooleanField source="settings.matik_mail_enabled" label="Mail Enabled" />
        <BooleanField source="settings.ai_enabled" label="AI Enabled" />
        <DateField source="updated_on" />
        <DateField source="created_on" />
        <TextField source="customer_id" />
        <TextField source="settings.query_parallelism" emptyText="Default" />
        <EditButton />
      </Tab>
      <Tab label="billing">
        <ReferenceManyField addLabel={false} reference="billings" target="enterprise_id">
          <Datagrid>
            <TextField source="id" />
            <TextField source="producer_count" />
            <TextField source="consumer_count" />
            <TextField source="plan_id" />
            <TextField source="subscription_status" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <AddBillingButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
