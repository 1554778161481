import React, { Component } from 'react';
import { toast } from 'react-toastify';
import CreateContent from './CreateContent';
import API from '../../lib/api';
import { MAlert } from '../shared/Alerts';
import WithNotifications from '../shared/WithNotifications';
import { Redirect } from 'react-router-dom';
import utils from '../../lib/utils';

class CreateTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      presentations: [],
      schedule: null,
      redirectToPresentation: null,
      userDataSources: undefined,
    };

    API.track('create_presentation_page_navigation');
  }

  componentDidMount() {
    API.get(
      '/user_data_sources/',
      (response) => {
        let userDataSourcesByDataSourceId = {};
        if (response.data && response.data.length > 0) {
          for (let dataSource of response.data) {
            userDataSourcesByDataSourceId[dataSource.data_source_id] = dataSource;
          }
        }
        this.setState({ userDataSources: userDataSourcesByDataSourceId });
      },
      API.defaultError,
    );
  }

  render() {
    if (this.state.redirectToPresentation) {
      const data = this.state.redirectToPresentation;
      let redirectUrl;
      if (this.state.schedule) {
        redirectUrl = `/create/templates/${data.template.id}/presentation/${data.id}/schedule-preview`;
      } else if (data.bulk_presentation_id && data.status_message !== 'manual override') {
        redirectUrl = `/create/templates/${data.template.id}/bulk/${data.bulk_presentation_id}/preview/${data.id}`;
      } else {
        redirectUrl = `/create/templates/${data.template.id}/presentation/${data.id}/complete`;
      }
      if (window.location.pathname !== redirectUrl) {
        this.setState({ redirectToPresentation: null });
        return <Redirect to={redirectUrl} push={true} />;
      }
    }
    return (
      <CreateContent
        userDataSources={this.state.userDataSources}
        onPresentationCreate={this.onPresentationCreate}
        resetPresentationFlow={this.resetPresentationFlow}
        presentations={this.state.presentations}
        schedule={this.state.schedule}
        fetchPresentation={this.fetchPresentation}
        onUserDataSourceAdd={this.onUserDataSourceAdd}
        isLoading={this.state.isLoading}
      />
    );
  }

  onPresentationCreate = (presentation, schedule, attachedPresentation = {}) => {
    const updatedPresentations = [...this.state.presentations];
    updatedPresentations.push(presentation);
    if (Object.keys(attachedPresentation).length) {
      updatedPresentations.push(attachedPresentation);
    }
    this.setState({
      presentations: updatedPresentations,
      redirectToPresentation: presentation,
      schedule,
      isLoading: false,
    });
  };

  resetPresentationFlow = () => {
    this.setState({ currentTemplate: null, currentPresentation: null });
  };

  fetchPresentation = (presentation_id) => {
    this.setState({ isLoading: true });
    API.get(
      `/presentations/${presentation_id}/`,
      (response) => {
        const updatedPresentations = [...this.state.presentations];
        updatedPresentations.push(response.data);
        this.setState({ presentations: updatedPresentations, isLoading: false });
      },
      (err) => {
        if (err.response?.status === 404) {
          MAlert('Presentation not found', 'Error', 'error', () => {
            window.location.assign('/create');
          });
        } else {
          utils.notify('Could not load this presentation', toast.TYPE.ERROR);
          window.location.assign('/create');
        }
      },
    );

    return null;
  };

  onUserDataSourceAdd = (newUserDataSource) => {
    const updatedUserDataSources = this.state.userDataSources ? Object.assign({}, this.state.userDataSources) : {};
    updatedUserDataSources[newUserDataSource.data_source_id] = newUserDataSource;
    this.setState({ userDataSources: updatedUserDataSources });
  };
}

export default WithNotifications(CreateTab);
