import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Media } from 'react-bulma-components';
import AsyncLoadImage from '../../shared/AsyncLoadImage';
import SourceTypeImage from '../../shared/SourceTypeImage';
import Pluralize from 'pluralize';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TextOverflowWithTooltip from '../../shared/TextOverflowWithTooltip';
import Constants from 'components/Constants';

class MostUsedTemplateInsight extends Component {
  render() {
    let fetchUrl = null;
    let usesIframe = false;

    if (this.props.template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      fetchUrl = '';
      usesIframe = true;
    } else if (this.props.template?.first_slide) {
      fetchUrl =
        '/templates/' + this.props.template.id + '/slides/' + this.props.template.first_slide.id + '/thumbnail/';
    }

    const templateUrl = 'templates/' + this.props.template.id;
    return (
      <div>
        <Link to={templateUrl}>
          <Heading size={6} className="mtm mbm text-grey">
            Most Used Template
          </Heading>
          <Media>
            <Media.Item align="left" className="template-insight-thumbnail">
              {fetchUrl !== null && (
                <AsyncLoadImage
                  height="73px"
                  fetchUrl={fetchUrl}
                  template={this.props.template}
                  usesIframe={usesIframe}
                />
              )}
            </Media.Item>
            <Media.Item>
              <TextOverflowWithTooltip
                className="title is-5 mbs mts text-overflow-ellipsis"
                text={this.props.template.name}
              />
              <div className="secondary-template-content">
                <SourceTypeImage className="source-type-img" sourceType={this.props.template.source_type} />
                <span className="secondary-template-text">
                  {Pluralize('Slide', this.props.template.slide_count ? this.props.template.slide_count : 0, true)}
                </span>
                <time className="tertiary-template-text">{moment(this.props.template.updated_on).calendar()}</time>
              </div>
            </Media.Item>
          </Media>
        </Link>
      </div>
    );
  }
}

MostUsedTemplateInsight.propTypes = {
  template: PropTypes.object,
};

export default MostUsedTemplateInsight;
