import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../lib/Button';
import Icon from '../../lib/Icon';

function ClickableCloseIcon({ dataTip, onClick }) {
  return (
    <Button
      category="tertiary"
      data-tooltip-content={dataTip}
      data-tooltip-id="matik-tooltip"
      onClick={onClick}
      id="dynamic-content-back-to-list"
      type="button"
      size="small"
    >
      <Icon name="dismiss" size={16} />
    </Button>
  );
}

ClickableCloseIcon.propTypes = {
  dataTip: PropTypes.string,
  onClick: PropTypes.func,
};

export default ClickableCloseIcon;
