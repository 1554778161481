import React from 'react';
import PropTypes from 'prop-types';
import Template from './Template';
import { withRouter } from 'react-router-dom';
import WithLoadingAndEmpty from '../../shared/WithLoadingAndEmpty';

import useTemplate, { useTemplateMutator } from 'lib/hooks/useTemplate';
import API from 'lib/api';

const TemplateMain = (props) => {
  const { data, isPending, isError, error, invalidate } = useTemplate(props.match.params.id);
  const { update } = useTemplateMutator();
  const { data: attachedTemplate, isPending: isAttachmentPending } = useTemplate(data?.attached_template_id);

  if (isError) {
    API.redirectOnError(props.history, '/templates', error);
    return null;
  }

  return (
    <WithLoadingTemplateMain
      {...props}
      currentTemplate={data}
      attachedTemplate={attachedTemplate}
      isLoading={isPending || (isAttachmentPending && data?.attached_template_id)}
      elements={data || attachedTemplate ? [0] : []}
      onTemplateUpdateWithServerCall={update}
      onTemplateInvalidate={invalidate}
    />
  );
};
TemplateMain.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const WithLoadingTemplateMain = WithLoadingAndEmpty(Template);

export default withRouter(TemplateMain);
