import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Hero, Heading } from 'react-bulma-components';

import dynamic_content_illustration from 'images/dynamic_content_illustration_180x120.png';

import TabHeader from 'components/producer/templates/TabHeader';
import TemplateContentList from 'components/producer/templates/TemplateContentList';
import TemplateInputsList from 'components/producer/templates/TemplateInputsList';
import TemplateConditionsList from 'components/producer/templates/TemplateConditionsList';
import EditTag from 'components/producer/templates/EditTag';
import { ContentContext } from 'components/producer/templates/ContentContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSlideConditionTargets } from 'lib/hooks/useCondition';

export const TABS = { INPUTS: 'inputs', CONTENT: 'content', CONDITIONS: 'conditions' };

const SlidesTemplateSidebar = forwardRef(
  (
    {
      currentTemplate,
      inputsInMatchingContent,
      handleHighlightSlideNums,
      dynamicContentTags,
      isContentFetching,
      openModal,
      selectedSlides,
      toggleSlideSelect,
      onInputLoopClick,
      onContentClick,
      onInputClick,
      onFilterSlides,
    },
    forwardedRef,
  ) => {
    const { enableMultiConditions } = useFlags();

    const conditionTargetsBySlide = useSlideConditionTargets(currentTemplate?.slides?.map((slide) => slide.id));

    const [currentTab, setCurrentTab] = useState(TABS.CONTENT);
    useImperativeHandle(
      forwardedRef,
      () => ({
        setCurrentTab,
      }),
      [],
    );

    const [filteringConditions, setFilteringConditions] = useState([]);

    // Translate filter conditions to filtered slide ids
    useEffect(() => {
      const isAnyFiltered = (conditionIds) => {
        return conditionIds?.some((conditionId) => filteringConditions.includes(conditionId));
      };

      const conditionFilteredSlideIds = currentTemplate?.slides
        ?.filter((slide) => {
          const clauses = conditionTargetsBySlide[slide.id]?.data?.condition_clauses;
          if (!clauses) {
            return false;
          }
          return isAnyFiltered(clauses.or?.flatMap((or) => or.and));
        })
        .map((slide) => slide.id);
      onFilterSlides(conditionFilteredSlideIds);
    }, [currentTemplate, JSON.stringify(conditionTargetsBySlide), JSON.stringify(filteringConditions)]);

    const context = useContext(ContentContext);
    const inputsLength = Object.values(inputsInMatchingContent).length;

    const highlightSlides = (slideIds) => {
      if (handleHighlightSlideNums) {
        handleHighlightSlideNums(slideIds);
      }
    };

    const unhighlightSlides = () => {
      if (handleHighlightSlideNums) {
        handleHighlightSlideNums([]);
      }
    };

    const handleTabClick = (e, tab) => {
      e.preventDefault();
      setCurrentTab(tab);

      if (tab !== TABS.CONDITIONS) {
        setFilteringConditions([]);
      }
    };

    const tabs = [
      {
        label: `Content ${dynamicContentTags ? dynamicContentTags.length : 0}`,
        selected: currentTab === TABS.CONTENT,
        onClick: (e) => handleTabClick(e, TABS.CONTENT),
      },
      {
        label: `Inputs ${inputsInMatchingContent ? inputsLength : 0}`,
        selected: currentTab === TABS.INPUTS,
        onClick: (e) => handleTabClick(e, TABS.INPUTS),
      },
    ];

    if (enableMultiConditions) {
      let isLoading = false;
      const conditions = new Set();
      Object.values(conditionTargetsBySlide).forEach((target) => {
        if (target.isPending) {
          isLoading = true;
        } else if (target.data) {
          target.data.condition_clauses.or
            ?.flatMap((or) => or.and)
            .forEach((conditionId) => conditions.add(conditionId));
        }
      });
      tabs.push({
        label: `Conditions ${isLoading ? '' : conditions.size}`,
        selected: currentTab === TABS.CONDITIONS,
        onClick: (e) => handleTabClick(e, TABS.CONDITIONS),
      });
    }

    let body = '';
    if (context.selectedSlideTag) {
      body = (
        <EditTag
          key={context.selectedSlideTag.contentName || 'new'}
          selectedSlideTag={context.selectedSlideTag}
          currentTemplate={currentTemplate}
          openModal={openModal}
          selectedSlide={selectedSlides[0]}
          toggleSlideSelect={toggleSlideSelect}
          handleClick={onContentClick}
        />
      );
    } else {
      body = (
        <React.Fragment>
          <TabHeader tabs={tabs} />
          {currentTab === TABS.CONTENT ? (
            <TemplateContentList
              currentTemplate={currentTemplate}
              dynamicContentTags={dynamicContentTags}
              isContentFetching={isContentFetching}
              entityType="dynamic_content"
              filters={`templates:"${currentTemplate.name}"`}
              handleClick={onContentClick}
              highlightSlides={highlightSlides}
              unhighlightSlides={unhighlightSlides}
              emptyContent={<EmptyContent />}
            />
          ) : currentTab === TABS.CONDITIONS ? (
            <TemplateConditionsList
              slides={currentTemplate?.slides}
              filtering={filteringConditions}
              onFilteringChange={setFilteringConditions}
              entityType="condition"
            />
          ) : (
            <TemplateInputsList
              inputsInMatchingContent={inputsInMatchingContent}
              currentTemplate={currentTemplate}
              selectedSlides={selectedSlides}
              entityType="input"
              filters={`templates:"${currentTemplate.name}"`}
              highlightSlides={highlightSlides}
              unhighlightSlides={unhighlightSlides}
              handleClick={onInputClick}
              onLoopClick={onInputLoopClick}
            />
          )}
        </React.Fragment>
      );
    }
    return <div className="components-wrapper">{body}</div>;
  },
);
SlidesTemplateSidebar.displayName = 'SlidesTemplateSidebar';
SlidesTemplateSidebar.propTypes = {
  dynamicContentTags: PropTypes.array,
  inputsInMatchingContent: PropTypes.object,
  isContentFetching: PropTypes.bool,
  currentTemplate: PropTypes.object,
  selectedSlides: PropTypes.array,
  openModal: PropTypes.func,
  toggleSlideSelect: PropTypes.func,
  handleHighlightSlideNums: PropTypes.func,
  onFilterSlides: PropTypes.func,
  onInputLoopClick: PropTypes.func,
  isBuilderSettingsVisible: PropTypes.bool,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
};

export default SlidesTemplateSidebar;

const EmptyContent = () => {
  return (
    <Hero>
      <Hero.Body className="has-text-centered no-dynamic-content-body">
        <Heading size={5} subtitle>
          <img src={dynamic_content_illustration} alt="No Content Pieces Connected" />
          <p className="no-dynamic-content-title">No Dynamic Content Added Yet</p>
        </Heading>
      </Hero.Body>
    </Hero>
  );
};
