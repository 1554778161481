import React, { useState, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Card, Level } from 'react-bulma-components';
import Pluralize from 'pluralize';
import AccessManager from '../../lib/AccessManager';
import { UserContext } from '../UserContext';
import SourceTypeImage from './SourceTypeImage';
import AsyncLoadImage from './AsyncLoadImage';
import DescriptionOverlay from './DescriptionOverlay';
import TextOverflowWithTooltip from './TextOverflowWithTooltip';
import { connect } from 'react-redux';
import Constants from 'components/Constants';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import TemplateCardActionDropdown from './TemplateCardActionDropdown';
import { MConfirm } from './Alerts';
import TemplateIcon from 'components/shared/TemplateIcon';
import Icon from '../lib/Icon';
import IconPill from '../lib/IconPill';
import { useBulkTemplateThumbnails, useTemplateFavorites, useTemplateMutator } from 'lib/hooks/useTemplate';
import utils from 'lib/utils';
function TemplateCard(props) {
  const {
    accessesByTemplateId,
    handleCloseSidepane,
    showAccessModal,
    showFavorites,
    showLibrary,
    template,
    templateBaseUrl,
    templateOnClick,
    isAdminView,
  } = props;
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState(false);
  const [isArchived, setIsArchived] = useState(template.deleted);

  const { del: deleteTemplate, archive: archiveTemplate } = useTemplateMutator();
  const { data: favorites, update: updateFavorite } = useTemplateFavorites();
  const isFavorite = favorites?.indexOf(template.id) > -1;

  const clickedTemplateCard = () => {
    if (handleCloseSidepane) {
      handleCloseSidepane();
    }
  };
  const isEmailTemplate = template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL;
  const fetchUrl = template?.slides?.length > 0 && !isEmailTemplate ? 'unused' : '';

  const { data: templateThumbnails } = useBulkTemplateThumbnails(!isEmailTemplate ? template?.id : null, 1);

  const fetchStatus =
    templateThumbnails && templateThumbnails.length > 0
      ? {
          data: templateThumbnails[0],
          fetchState: 'fetched',
          errorMessage: '',
        }
      : { fetchState: 'loading' };

  const userContext = useContext(UserContext);
  const accessObj = new AccessManager(template.id, accessesByTemplateId, userContext.user);
  const canEdit = isAdminView ? accessObj.can('edit') : false;
  const templateUrl = templateBaseUrl + template.id;
  let imageHeight = '161px';
  const templateType =
    template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
      ? 'email'
      : [
          Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS,
          Constants.TEMPLATE_SOURCE_TYPES.WORD,
          Constants.TEMPLATE_SOURCE_TYPES.WORD_365,
        ].includes(template.source_type)
      ? 'document'
      : template.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
      ? 'spreadsheet'
      : 'presentation';
  const toggleDetails = () => {
    const newState = !showOverlay;
    setShowOverlay(newState);
  };
  const formatEditedDate = (date) => {
    // Format date based on if it came from db (string) or Algolia (unix timestamp)
    let formattedDate;
    if (typeof date === 'number') {
      formattedDate = moment.unix(date);
    } else {
      formattedDate = moment(date);
    }
    return formattedDate.fromNow();
  };
  const onFavorite = (e) => {
    e.preventDefault();
    if (favorites) {
      updateFavorite(template, !isFavorite);
    }
  };
  const onManageAccessClick = (e, accessObj) => {
    e.preventDefault();
    e.stopPropagation();
    if (!showAccessModal || !accessObj.isShared()) {
      history.push(templateBaseUrl + template.id);
    }
  };
  const archivedClass = isArchived ? ' is-archived' : '';
  const isSubTemplate = template.is_sub_template;
  const subTemplateClass = isSubTemplate ? ' is-sub-template' : '';
  const archivedOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm(
      'Template Archived',
      'You must un-archive this template in order to view it.',
      'error',
      (confirmed) => {
        if (confirmed) {
          archiveTemplate(template.id, isArchived).then(() => setIsArchived(!isArchived));
        }
      },
      'Un-archive',
    );
  };
  const templateCardOnClick = isArchived ? archivedOnClick : templateOnClick ? templateOnClick : clickedTemplateCard;
  const archiveThenRerender = () => {
    return archiveTemplate(template.id, isArchived).then(() => setIsArchived(!isArchived));
  };
  const tagLocations = template.slides?.[0]?.locations || {};

  return (
    <Card
      className={`template-card${archivedClass}${subTemplateClass}`}
      id={`template-card-${template.id}`}
      onMouseEnter={toggleDetails}
      onMouseLeave={toggleDetails}
    >
      <Link id={template.id} to={templateUrl} onClick={templateCardOnClick}>
        <div className="template-card-thumbnail-container">
          {showLibrary && template.is_library && !isArchived && (
            <button className="libraries-icon mt-2" aria-label="library-slide">
              <IconPill iconName="book" iconTheme="filled" size="m" color="purple" theme="circle" />
            </button>
          )}
          {template.is_sub_template && (
            <button
              className="button archived-button"
              data-tooltip-id="matik-tooltip"
              data-tooltip-content="A customized version of an admin-created template."
            >
              Sub-template
            </button>
          )}
          {isArchived && (
            <button
              className="button archived-button"
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={
                'This template has been archived. Archived templates are hidden from end users and cannot be used to generate new content.'
              }
            >
              Archived
            </button>
          )}
          <DescriptionOverlay
            template={template}
            accessesByTemplateId={accessesByTemplateId}
            accessObj={accessObj}
            onManageAccessClick={onManageAccessClick}
          >
            <AsyncLoadImage
              isBulk={!isEmailTemplate}
              usesIframe={isEmailTemplate}
              height={imageHeight}
              fetchUrl={fetchUrl}
              fetchStatus={fetchStatus}
              template={template}
            />
          </DescriptionOverlay>
        </div>
      </Link>
      <Card.Content className="template-card-content">
        <Link id={template.id} to={templateUrl} className="min-width-0" onClick={templateCardOnClick}>
          <Level>
            <Level.Item id="template-card-icon-box">
              <TemplateIcon templateType={templateType} />
            </Level.Item>
            <Level.Item align="left" style={{ width: '94%' }} className="template-card-text">
              <div className="template-card-right">
                <div className="template-card-name">
                  <TextOverflowWithTooltip
                    className="title is-4 primary-card-text text-overflow-ellipsis"
                    text={template.name}
                  />
                  {showFavorites && (
                    <div
                      data-tooltip-id="matik-tooltip"
                      data-tooltip-content={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                      onClick={onFavorite}
                    >
                      {isFavorite ? (
                        <Icon name="star" size={16} theme="filled" />
                      ) : (
                        <Icon name="star" size={16} theme="regular" />
                      )}
                    </div>
                  )}
                </div>
                {showOverlay ? (
                  <div className="template-card-details">
                    <time className="tertiary-card-text">Edited {formatEditedDate(template.updated_on)}</time>
                  </div>
                ) : (
                  <div className="template-card-details">
                    <span>{utils.capitalizeString(templateType)}</span>
                    <span className="tertiary-card-text mls mrs">|</span>
                    <SourceTypeImage cssClassName="source-type-img" sourceType={template.source_type} size="sm" />
                    {templateType === 'presentation' ? (
                      <span className="tertiary-card-text">
                        {Pluralize('Slide', template.slides ? template.slides.length : 0, true)}
                      </span>
                    ) : templateType === 'spreadsheet' ? (
                      <span className="tertiary-card-text">
                        {Pluralize('Sheet', Object.keys(tagLocations).length || 0, true)}
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
              {showOverlay && (
                <TemplateCardActionDropdown
                  currentTemplate={{ ...template, deleted: isArchived, type: 'template' }}
                  canEdit={canEdit}
                  isFavorite={isFavorite}
                  onFavoriteUpdate={updateFavorite}
                  deleteTemplate={deleteTemplate}
                  archiveTemplate={archiveThenRerender}
                  openModal={props.openModal}
                />
              )}
            </Level.Item>
          </Level>
        </Link>
      </Card.Content>
    </Card>
  );
}

TemplateCard.propTypes = {
  template: PropTypes.object,
  accessesByTemplateId: PropTypes.object,
  showLibrary: PropTypes.bool,
  templateBaseUrl: PropTypes.string,
  showFavorites: PropTypes.bool,
  templateOnClick: PropTypes.func,
  showAccessModal: PropTypes.func,
  handleCloseSidepane: PropTypes.func,
  openModal: PropTypes.func,
  isAdminView: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    ...mapUiDispatchToProps(dispatch),
  };
}

export default connect(null, mapDispatchToProps)(TemplateCard);
