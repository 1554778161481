import React, { Component } from 'react';
import Constants from '../Constants';
import PropTypes from 'prop-types';
import ContactSalesLink from '../shared/ContactSalesLink';
import Button from '../lib/Button';

class FeatureComparisonHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerClass: 'feature-comparison-header',
    };
  }

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
  }

  handleScroll = () => {
    if (document.documentElement.scrollTop > 1250) {
      this.setState({ headerClass: 'feature-comparison-header active' });
    } else {
      this.setState({ headerClass: 'feature-comparison-header' });
    }
  };

  render() {
    const { buttonText, buttonDisabled } = this.fetchTeamButtonAttributes();

    return (
      <div className={this.state.headerClass}>
        <div className="feature-comparison-header-title">Compare Features</div>
        <div className="tier-cta">
          <div className="tier-title">{Constants.MATIK_TIERS.matik_team.display_name}</div>
          <Button disabled={buttonDisabled} onClick={this.props.onTierSelection} tierid={this.props.userTier}>
            {buttonText}
          </Button>
        </div>
        <div className="tier-cta">
          <div className="tier-title">{Constants.MATIK_TIERS.matik_enterprise.display_name}</div>
          <ContactSalesLink className="button" from="feature_comparison_header" />
        </div>
      </div>
    );
  }

  fetchTeamButtonAttributes = () => {
    let buttonText = 'Select';
    let buttonDisabled = false;
    if (this.props.userTier === Constants.MATIK_TIERS.matik_team.tier_id && !this.props.isTrialing) {
      buttonText = 'Current plan';
      buttonDisabled = true;
    }

    return { buttonText, buttonDisabled };
  };
}

FeatureComparisonHeader.propTypes = {
  onTierSelection: PropTypes.func,
  userTier: PropTypes.string,
  isTrialing: PropTypes.bool,
};

export default FeatureComparisonHeader;
