import { Auth } from '../../lib/auth';

export default {
  logout: () => {
    Auth.logOut(() => window.location.assign('/'));
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      window.location.assign('/');
    }
  },
  checkAuth: () => {
    return Auth.getAuthToken() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const roles = Auth.getRoles();
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};
