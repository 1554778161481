import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';

class AnalyticsRechart extends Component {
  render() {
    return (
      <ResponsiveContainer height={500} width="100%">
        <BarChart data={this.props.presentationData} barSize={34} margin={{ top: 5, right: 5, bottom: 5, left: -20 }}>
          <CartesianGrid vertical={false} className="analytics-cartesian-grid" />
          <XAxis
            dataKey="created_on"
            axisLine={false}
            tickLine={false}
            tick={<CustomizedAxisTick />}
            height={50}
            interval="preserveStartEnd"
          />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="presentation_count" className="analytics-bar" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

AnalyticsRechart.propTypes = {
  presentationData: PropTypes.array,
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    const formattedPayload = moment.utc(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize="smaller" fontWeight="800">
          {formattedPayload.format('dddd')}
        </text>
        <text x={0} y={16} dy={16} textAnchor="middle" fill="#666" fontSize="smaller">
          {formattedPayload.format('MMM Do')}
        </text>
      </g>
    );
  }
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
  payload: PropTypes.object,
};

class CustomTooltip extends PureComponent {
  render() {
    const { payload, label, active } = this.props;
    const formattedLabel = moment.utc(label);

    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${formattedLabel.format('dddd, MMM Do')} : ${payload ? payload[0].value : ''}`}</p>
        </div>
      );
    }

    return null;
  }
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  label: PropTypes.any,
  active: PropTypes.bool,
};

export default AnalyticsRechart;
