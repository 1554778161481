import React from 'react';
import PropTypes from 'prop-types';
import ToggleOn from 'images/toggle_on';
import ToggleOff from 'images/toggle_off';

// https://www.figma.com/file/2gIchNNqB7uCs7oT51pL7v/Design-System?type=design&node-id=7271-2335&mode=design&t=2VyzPv8FIe470JUG-0
// onClick handler is left open ended to allow for custom functionality, rather than a hard-coded toggle

// this component is theoretically infinitely scalable, but for now it will be restricted to small and medium sizes
const toggleSwitchSizeMapper = {
  s: { width: 24, height: 14 },
  m: { width: 28, height: 18 },
};

function ToggleSwitch({ size = 'm', onClick, switchState = false, className = '' }) {
  const width = toggleSwitchSizeMapper[size].width;
  const height = toggleSwitchSizeMapper[size].height;
  const fullClassName = `flex self-center ${className}`;

  return (
    <div onClick={onClick} className={fullClassName}>
      {switchState ? <ToggleOn width={width} height={height} /> : <ToggleOff width={width} height={height} />}
    </div>
  );
}

ToggleSwitch.propTypes = {
  switchState: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm']),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ToggleSwitch;
