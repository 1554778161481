import React from 'react';
import { useNode } from '@craftjs/core';
import PropTypes from 'prop-types';
import { PickColor } from 'components/shared/PickColor';
import FormInput from 'components/shared/FormInput';
import PaddingInput from 'components/shared/PaddingInput';

function EmailContainer({ children, style }) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div ref={(dom) => connect(drag(dom))} style={style}>
      {children}
    </div>
  );
}

EmailContainer.craft = {
  props: {
    style: {
      backgroundColor: '#FFFFFF',
      margin: '0 auto',
      paddingBottom: '15px',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '15px',
      width: '600px',
    },
  },
  rules: {
    canDrag: () => false,
    canDrop: () => false,
    canMoveIn: () => true,
    canMoveOut: () => true,
  },
  related: {
    settings: EmailContainerSettings,
  },
};

EmailContainer.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object,
};

function EmailContainerSettings() {
  const {
    actions: { setProp },
    backgroundColor,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
  } = useNode((node) => ({
    backgroundColor: node.data.props.style.backgroundColor,
    paddingBottom: parseInt(node.data.props.style.paddingBottom),
    paddingLeft: parseInt(node.data.props.style.paddingLeft),
    paddingRight: parseInt(node.data.props.style.paddingRight),
    paddingTop: parseInt(node.data.props.style.paddingTop),
    width: parseInt(node.data.props.style.width),
  }));

  const onColorChange = (propName) => (color) => {
    const hex = color.hex;
    setProp((props) => {
      props.style[propName] = hex;
    });
  };

  const onPaddingChange = (num, paddingProp) => {
    setProp((props) => {
      props.style[paddingProp] = `${num}px`;
    });
  };

  const onContainerWidthChange = (num) => {
    setProp((props) => {
      props.style.width = `${num}px`;
    });
  };

  return (
    <div className="module-settings-container">
      <div className="module-setting">
        <label>Container Width</label>
        <div>
          <FormInput
            inputClass="module-settings-input pixel-input"
            onChange={(e) => {
              onContainerWidthChange(e.target.value);
            }}
            suffix={<span>px</span>}
            type="number"
            value={width}
          />
        </div>
      </div>
      <div className="module-setting">
        <label>Background Color</label>
        <PickColor
          onChange={onColorChange('backgroundColor')}
          hexColor={backgroundColor}
          ancestorEl={document.querySelector('.template-components')}
        />
      </div>
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onPaddingChange}
            paddingRight={paddingRight}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingTop={paddingTop}
          />
        </div>
      </div>
    </div>
  );
}

EmailContainerSettings.propTypes = {
  style: PropTypes.object,
};

export default EmailContainer;
