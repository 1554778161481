import React from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'react-bulma-components';
import inputs from 'lib/inputs';
import FormFromInputs from './FormFromInputs';
import ConditionalContentProcessor from 'lib/conditionalContentProcessor';
import QueryProcessor from 'lib/queryProcessor';
import { MAlert } from './Alerts';
import { ReactComponent as Run } from '../../svg/run.svg';
import LongRequest from 'lib/longRequest';
import QueryDisplay from './QueryDisplay';
import Button from 'components/lib/Button';
import ButtonGroup from 'components/lib/ButtonGroup';
import API from 'lib/api';
import Constants from 'components/Constants';
import { useQueryInputList } from 'lib/hooks/useInputList';
import { useOneDynamicContent } from 'lib/hooks/useDynamicContent';
import useInputsAndValues from 'lib/hooks/useInputsAndValues';

function testQuery(content, inputValues, inputs) {
  const queryObj = content.query_obj;

  if (content.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
    // Static content, no need to run any queries
    const queryProcessor = new QueryProcessor(queryObj.query_string, inputs, inputValues, null);
    const results = {
      status: 'success',
      result: [[''], [queryProcessor.parameterizeQueryString()]],
      dynamicContentType: content.dynamic_content_type,
      timeElapsed: 0,
    };
    return Promise.resolve(results);
  }

  return new Promise((resolve, reject) => {
    const queryProcessor = new QueryProcessor(queryObj.query_string, inputs, inputValues, queryObj.data_source);
    const queryData = {
      data_source: { id: queryObj.data_source?.id },
      dynamic_content_type: content.dynamic_content_type,
      query_string: queryProcessor.parameterizeQueryString(),
      values_by_param_name: inputValues,
    };
    const startTime = Date.now();

    const onResponse = (response, onComplete) => {
      if (response.data.status === 'success') {
        const timeElapsed = Date.now() - startTime;

        const testResult = response.data;
        testResult['timeElapsed'] = timeElapsed;
        resolve(testResult);
        onComplete();
      } else if (response.data.status === 'error') {
        resolve(response.data);
        onComplete();
      }
    };

    const submitError = (err) => {
      reject(err);
    };

    const longRequest = new LongRequest('/queries');
    longRequest.post(queryData, onResponse, submitError, undefined, undefined, `test/${queryObj.data_source.type}`);
  });
}

function useConditionalOutputContent(conditionalContent, inputValues) {
  const conditionalContentProcessor = new ConditionalContentProcessor(conditionalContent, inputValues);
  const contentId = conditionalContentProcessor.getOutputContent();

  return useOneDynamicContent(contentId);
}

function TestConditionalContentInputsForm(props) {
  // Hook to get the inputs relevant to this conditional content with a given set of input values.
  const useRelevantInputs = (inputValues) => {
    const { isLoading, filteredInputsByName } = useQueryInputList(
      JSON.stringify(props.conditionalContent),
      Constants.DynamicContentTypes.CONDITIONAL,
      inputValues,
    );
    return {
      isLoading,
      inputs: filteredInputsByName ? Object.values(filteredInputsByName) : null,
    };
  };

  const { inputValues, updateInputValues, inputs: relevantInputs } = useInputsAndValues(useRelevantInputs);
  const filteredInputsInQueryString = {};
  if (relevantInputs) {
    relevantInputs.forEach((input) => (filteredInputsInQueryString[input.name] = input));
  }

  const { isLoading: isOutputContentLoading, dynamicContent: content } = useConditionalOutputContent(
    props.conditionalContent,
    inputValues,
  );

  const handleInputValueChange = (updatedInputValues) => {
    updateInputValues(updatedInputValues);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.setTestIsLoading(true);
    props.setTestResult(null);
    if (!content) {
      MAlert('No Dynamic Content returned', 'Error', 'error');
      props.setTestIsLoading(false);
      return;
    }

    const queryObj = content.query_obj;

    if (!queryObj || queryObj.dataSource) {
      MAlert('Unrecognized data source', 'Error', 'error');
      props.setTestIsLoading(false);
      return;
    }

    props.onCancel();

    testQuery(content, inputValues, filteredInputsInQueryString)
      .then((results) => {
        props.setTestResult(results);
      })
      .catch((err) => {
        API.defaultError(err);
        props.setTestResult(null);
      })
      .finally(() => {
        props.setTestIsLoading(false);
      });
  };

  let parameterizedQuery = '';
  if (content) {
    const queryProcessor = new QueryProcessor(
      content.query_obj.query_string,
      filteredInputsInQueryString,
      inputValues,
      // TODO: parameterizeQueryString should be made sophisticated enough to handle static data_source
      content.dynamic_content_method === Constants.DynamicContentMethods.STATIC ? null : content.query_obj.data_source,
    );
    parameterizedQuery = queryProcessor.parameterizeQueryString();
  }

  const buttonActive = inputs.areInputsFilledOut(inputValues, filteredInputsInQueryString || {});
  return (
    <form onSubmit={onFormSubmit}>
      <FormFromInputs
        inputs={Object.values(filteredInputsInQueryString || {})}
        inputValues={inputValues}
        onInputValueChange={handleInputValueChange}
        test={true}
        entityId={props.entityId}
        entityType={props.entityType}
      />
      <Form.Field className="mtm phm">
        <Form.Label>Dynamic Content Name</Form.Label>
        <p>{isOutputContentLoading ? '' : content ? content.name : 'No content matches condition'}</p>
      </Form.Field>
      {content && (
        <Form.Field className="mtm">
          {content.dynamic_content_type === Constants.DynamicContentTypes.IMAGE ? (
            <Image src={content.query_obj.query_string} />
          ) : (
            <QueryDisplay queryString={parameterizedQuery} />
          )}
        </Form.Field>
      )}
      <ButtonGroup align="right">
        <Button type="button" category="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button type="submit" status={!buttonActive ? 'disabled' : 'default'}>
          <Run />
          &nbsp;{props.submitButtonText}
        </Button>
      </ButtonGroup>
    </form>
  );
}
TestConditionalContentInputsForm.propTypes = {
  onCancel: PropTypes.func,
  conditionalContent: PropTypes.shape({
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        dynamicContent: PropTypes.number,
        clauses: PropTypes.arrayOf(
          PropTypes.shape({
            operand: PropTypes.string,
            operator: PropTypes.string,
            param: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
  setTestIsLoading: PropTypes.func,
  setTestResult: PropTypes.func,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  submitButtonText: PropTypes.string,
};

export default TestConditionalContentInputsForm;
