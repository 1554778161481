import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import LookerQueryForm from './LookerQueryForm';
import { Select } from '../../../shared/FormSelect';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

class LookerDashboardForm extends Component {
  componentDidMount() {
    if (
      this.props.queryObj.selectedDashboard &&
      (!this.props.apiInfo['dashboard'] || !this.props.apiInfo['dashboard'][this.props.queryObj.selectedDashboard.id])
    ) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'dashboard', this.props.queryObj.selectedDashboard.id);
    }
    if (
      this.props.queryObj.selectedDashboardElement &&
      this.props.queryObj.selectedDashboardElement['result_maker']['query'] &&
      (!this.props.apiInfo['query'] || !this.props.apiInfo['query'][this.props.queryObj.selectedDashboardElement.id])
    ) {
      this.props.fetchApiInfoIfNeeded(
        this.props.dataSourceId,
        'query',
        // TODO (zak): Change this back to id once Google gets its shit together
        this.props.queryObj.selectedDashboardElement['result_maker']['query']['slug'],
      );
    }
  }

  render() {
    const showFormFields = this.props.queryObj.selectedDashboardElement && Object.values(this.props.apiInfo).length > 0;
    const queryId =
      showFormFields &&
      this.props.queryObj.selectedDashboardElement &&
      this.props.queryObj.selectedDashboardElement['result_maker']['query']
        ? this.props.queryObj.selectedDashboardElement['result_maker']['query']['slug']
        : null;
    const dashboardQuery =
      showFormFields && this.props.apiInfo['query'] && this.props.apiInfo['query'][queryId]
        ? this.props.apiInfo['query'][queryId]
        : {};
    const dashboardOptions = this.props.apiInfo['dashboards']
      ? Object.keys(this.props.apiInfo['dashboards']).map((dashboardId) => {
          const dashboard = this.props.apiInfo['dashboards'][dashboardId];
          return { label: dashboard.title, value: dashboard.id };
        })
      : [];
    const selectedDashboardValue = this.props.queryObj.selectedDashboard
      ? {
          value: this.props.queryObj.selectedDashboard.id,
          label: this.props.queryObj.selectedDashboard.title,
        }
      : null;
    const dashboardErrorMessage =
      this.props.apiErrors &&
      this.props.apiErrors.dashboard &&
      selectedDashboardValue &&
      this.props.apiErrors.dashboard[selectedDashboardValue.value] &&
      'There appears to be a problem with the selected dashboard. Please check the configuration in Looker.';
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Dashboard</Form.Label>
          <Form.Help>Select Looker Dashboard</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Dashboard"
              placeholder="Select a Dashboard"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={selectedDashboardValue}
              onChange={(obj) => this.selectDashboard(obj.value)}
              options={dashboardOptions}
              color={dashboardErrorMessage ? 'danger' : null}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          {dashboardErrorMessage && <Form.Help color="danger">{dashboardErrorMessage}</Form.Help>}
        </Form.Field>
        {this.props.queryObj.selectedDashboard && this.props.apiInfo['dashboard'] && this.renderDashboardElements()}
        {showFormFields && (
          <LookerQueryForm
            queryObj={this.props.queryObj}
            apiInfo={this.props.apiInfo}
            inputs={this.context.inputs}
            fields={dashboardQuery['fields'] ? dashboardQuery['fields'] : []}
            dynamicFields={
              dashboardQuery['query'] && dashboardQuery['query']['dynamic_fields']
                ? dashboardQuery['query']['dynamic_fields']
                : []
            }
            entityType={this.props.entityType}
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
            menuPortalTarget={this.props.formRef}
          />
        )}
      </React.Fragment>
    );
  }

  renderDashboardElements() {
    const dashboardElements =
      this.props.apiInfo['dashboard'] && this.props.apiInfo['dashboard'][this.props.queryObj.selectedDashboard.id]
        ? this.props.apiInfo['dashboard'][this.props.queryObj.selectedDashboard.id]
        : {};
    const queryErrorMessage = this.props.apiErrors?.query?.[
      this.props.queryObj.selectedDashboardElement?.result_maker?.query?.slug
    ]
      ? 'There appears to be a problem with the selected query. Please check the configuration in Looker.'
      : null;
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Dashboard Query</Form.Label>
          <Form.Help>Select Looker Query</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Query"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={
                this.props.queryObj.selectedDashboardElement
                  ? {
                      value: this.props.queryObj.selectedDashboardElement.id,
                      label: this._getElementTitle(this.props.queryObj.selectedDashboardElement),
                    }
                  : null
              }
              onChange={(obj) => this.selectDashboardElement(obj.value)}
              options={Object.keys(dashboardElements).map((elementId) => {
                const element = dashboardElements[elementId];
                return { label: this._getElementTitle(element), value: element.id };
              })}
              color={queryErrorMessage ? 'danger' : null}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          {queryErrorMessage && <Form.Help color={'danger'}>{queryErrorMessage}</Form.Help>}
        </Form.Field>
      </React.Fragment>
    );
  }

  _getElementTitle = (element) => {
    let elementTitle = element.title;
    if (!elementTitle && element.look && element.look.title) {
      elementTitle = element.look.title;
    }
    return elementTitle;
  };

  _createDefaultQuery = () => {
    // If you change this, you might also want to change it in LookerModelForm
    return { output_snapshot: true };
  };

  selectDashboardElement = (id) => {
    let updatedQueryObj = Object.assign({}, this.props.queryObj);
    const dashboardId = this.props.queryObj.selectedDashboard ? this.props.queryObj.selectedDashboard.id : 0;
    const dashboardElement = this.props.apiInfo['dashboard'][dashboardId][id];
    if (dashboardElement['result_maker'] && dashboardElement['result_maker']['query']) {
      updatedQueryObj = this.createQueryObjFromLookerQuery(dashboardElement['result_maker']['query'], updatedQueryObj);
      this.props.fetchApiInfoIfNeeded(
        this.props.dataSourceId,
        'query',
        // TODO (zak): Change this back to id once Google gets its shit together
        dashboardElement['result_maker']['query']['slug'],
      );
    } else {
      updatedQueryObj.query = this._createDefaultQuery();
    }
    updatedQueryObj.selectedDashboardElement = dashboardElement;
    updatedQueryObj.returnFieldsByName = {};
    this.context.onQueryObjectUpdate(updatedQueryObj);
    this.props.updateTestResult(null);
  };

  selectDashboard = (id) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.selectedDashboard = this.props.apiInfo['dashboards'][id];
    updatedQueryObj.selectedDashboardElement = undefined;
    updatedQueryObj.returnFieldsByName = {};
    updatedQueryObj.query = this._createDefaultQuery();
    this.context.onQueryObjectUpdate(updatedQueryObj);
    this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'dashboard', updatedQueryObj.selectedDashboard.id);
  };

  createQueryObjFromLookerQuery = (query, queryObj) => {
    const updatedQueryObj = Object.assign({}, queryObj);
    updatedQueryObj.query = Object.assign(this._createDefaultQuery(), query);
    updatedQueryObj.query.filters = query['filters']
      ? Object.keys(query['filters']).map((filterField) => {
          const filterVal = query['filters'][filterField];
          return { field: filterField, val: filterVal };
        })
      : [];

    return updatedQueryObj;
  };
}

LookerDashboardForm.contextType = DynamicContentContext;

LookerDashboardForm.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  updateTestResult: PropTypes.func,
  formRef: PropTypes.object,
  queryObj: PropTypes.object,
};

export default LookerDashboardForm;
