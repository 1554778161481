import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Indicator from './Indicator';

function TabItem({
  isActive = false,
  iconName,
  indicatorColor,
  label,
  onClick = () => {},
  status = 'default',
  tag,
  tagColor = 'matik-white',
}) {
  let statusClass = 'text-grey-600 bg-matik-white';
  let hoverClass = 'hover:bg-grey-50';
  if (status === 'disabled') {
    statusClass = 'text-grey-300 bg-grey-100';
    hoverClass = '';
  } else if (isActive) {
    statusClass = 'text-matik-black bg-matik-white';
  }

  const borderClass = isActive ? 'border-matik-green' : 'border-transparent';

  const buttonOnClick = () => onClick(label ? label.toLowerCase() : '');
  let tagColorClass = 'bg-transparent border-grey-300';
  if (tagColor !== 'matik-white') {
    tagColorClass = `text-matik-white bg-${tagColor} border-${tagColor}`;
  }
  return (
    <button
      role="tab"
      aria-selected={isActive}
      disabled={status === 'disabled'}
      className={`px-4 flex items-center first:rounded-l-md last:rounded-r-md ${statusClass} ${hoverClass}`}
      onClick={buttonOnClick}
      type="button"
    >
      <div className={`border-b ${borderClass} h-full items-center flex gap-2`}>
        {iconName && (
          <div className="flex">
            <Icon name={iconName} size={20} />
          </div>
        )}
        {label ? label : ''}
        {(tag || tag === 0) && <div className={`flex border rounded-full ${tagColorClass} px-2`}>{tag}</div>}
        {indicatorColor && <Indicator color={indicatorColor} />}
      </div>
    </button>
  );
}

TabItem.propTypes = {
  isActive: PropTypes.bool,
  iconName: PropTypes.string,
  indicatorColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['default', 'disabled']),
  tag: PropTypes.node,
  tagColor: PropTypes.string,
};

export default TabItem;
