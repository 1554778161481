import React from 'react';
import PropTypes from 'prop-types';

function InsightCard({ title, percent, total, cardBottom, cardClass }) {
  return (
    <div className={`insight-card ${cardClass}`}>
      <div className="insight-card-title">{title}</div>
      <div className="insight-card-values">
        <p className="insight-card-percent">{percent}%</p>
        <p className="insight-card-total">{total}</p>
      </div>
      {cardBottom && <div className="insight-card-bottom">{cardBottom}</div>}
    </div>
  );
}

InsightCard.propTypes = {
  title: PropTypes.string,
  percent: PropTypes.number,
  total: PropTypes.number,
  cardBottom: PropTypes.element,
  cardClass: PropTypes.string,
};

export default InsightCard;
