import React from 'react';
import WithFilterListHeader from 'components/shared/WithFilterListHeader';
import SortableEntityGrid from 'components/shared/SortableEntityGrid';
import PropTypes from 'prop-types';

const GridWithFilterHeader = WithFilterListHeader(SortableEntityGrid);

function EmailAttachmentPopUpBody(props) {
  const isSearching = props.isSearching && props.tipInstance?.state?.isMounted;

  const templateOnClick = async (e) => {
    e.preventDefault();
    const updatedTemplate = { ...props.currentTemplate };
    updatedTemplate.attached_template_id = parseInt(e.currentTarget.id);
    await props.onTemplateUpdateWithServerCall(updatedTemplate);
    props.tipInstance.hide();
  };

  return (
    <div className="add-attachment-popup-body" style={{ width: `${props.width}px` }}>
      <GridWithFilterHeader
        entities={props.templates}
        entityType="template"
        pagination={{ numberOfPages: 1 }}
        showFavorites={false}
        isTemplateLibrary={false}
        displayFilterHeader={true}
        displaySortDropdown={false}
        filterPlaceholder="Find Template"
        searchState={props.searchState}
        onSearchStateChange={props.onSearchStateChange}
        searchAttributes={props.searchAttributes}
        isSearching={isSearching}
        templateOnClick={templateOnClick}
        templateBaseUrl="/templates/"
      />
    </div>
  );
}

EmailAttachmentPopUpBody.propTypes = {
  currentTemplate: PropTypes.object,
  entityType: PropTypes.string,
  onSearchStateChange: PropTypes.func,
  searchState: PropTypes.object,
  isSearching: PropTypes.bool,
  templates: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  tipInstance: PropTypes.object,
  width: PropTypes.number,
  searchAttributes: PropTypes.object,
};

export default EmailAttachmentPopUpBody;
