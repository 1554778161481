import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Pill from './Pill';

export const SizeToIconSize = {
  xxl: 24,
  xl: 24,
  l: 20,
  m: 20,
  s: 16,
  xs: 16,
  xxs: 12,
};

function IconPill({ color, iconColor, iconName, iconTheme = 'regular', luminosity, size = 'l', text, theme }) {
  return (
    <Pill color={color} luminosity={luminosity} size={size} text={text} theme={theme}>
      <Icon color={iconColor} name={iconName} size={SizeToIconSize[size]} theme={iconTheme} />
    </Pill>
  );
}

IconPill.propTypes = {
  color: Pill.propTypes.color,
  iconColor: PropTypes.string,
  iconTheme: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  luminosity: Pill.propTypes.luminosity,
  size: Pill.propTypes.size,
  text: Pill.propTypes.text,
  theme: Pill.propTypes.theme,
};

export default IconPill;
