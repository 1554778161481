const slides = {
  parseAddedSlides: (addedSlides) => {
    return addedSlides.map((addedSlideObj) => ({
      slide_id: addedSlideObj.slide.id,
      slide_id_in_template: addedSlideObj.slide.slide_id,
      matik_template_id: addedSlideObj.template.id,
      template_id: addedSlideObj.template.id,
      slide_idx: addedSlideObj.idx,
    }));
  },
};

export default slides;
