import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from '../../lib/auth';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  Edit,
  TextField,
  TextInput,
  SimpleForm,
  EditButton,
  required,
  DateField,
  Create,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  BooleanInput,
  BooleanField,
  SaveButton,
  DeleteButton,
  Toolbar,
  Confirm,
  FilterForm,
  SearchInput,
  SortButton,
  List,
  FunctionField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { MAlert } from 'components/shared/Alerts';
import Button from '@material-ui/core/Button';
import roleNames from './RoleNames';

const userFilters = [
  <SearchInput placeholder="Name" source="name_q" alwaysOn resettable key="name" />,
  <SearchInput placeholder="Email" source="email_q" alwaysOn resettable key="email" />,
  <SearchInput placeholder="Id" source="id_q" alwaysOn resettable key="id" />,
];

const UserToolbar = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <FilterForm filters={userFilters} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }} />
    <div>
      <CreateButton />
      <SortButton fields={['id', 'name', 'created_on', 'updated_on']} />
      <Button
        label="Clear Filters"
        color="primary"
        component={Link}
        to={{
          pathname: '/users',
          search: `filter=${JSON.stringify({})}`,
        }}
      >
        Clear Filters
      </Button>
    </div>
  </div>
);

const LinkToEnterpriseFilter = ({ record }) => {
  return record ? (
    <TextField
      source="name"
      color="primary"
      component={Link}
      to={{
        pathname: '/users',
        search: `filter=${JSON.stringify({ enterprise_id: record.id })}`,
      }}
    ></TextField>
  ) : null;
};

LinkToEnterpriseFilter.propTypes = {
  record: PropTypes.any,
};

export const UserList = (props) => (
  <List {...props} perPage={25} actions={<UserToolbar />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="title" />
      <TextField source="phone" />
      <TextField source="name" />
      <BooleanField source="active" />
      <ReferenceField label="Enterprise" source="enterprise_id" reference="enterprises">
        <LinkToEnterpriseFilter source="name" />
      </ReferenceField>
      <ReferenceArrayField label="Roles" source="role_ids" reference="roles">
        <SingleFieldList>
          <FunctionField
            render={(record) => {
              const customRoleName = roleNames.find((choice) => choice.name == record.name) || record.name;
              return <ChipField source="label" record={customRoleName} />;
            }}
          />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="updated_on" />
      <DateField source="created_on" />
      <EditButton />
    </Datagrid>
  </List>
);

UserList.propTypes = {};

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.name}"` : ''}</span>;
};

UserTitle.propTypes = {
  record: PropTypes.any,
};

const CustomResetPasswordButton = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    resetPassword();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick}>Send Password Reset Email</Button>
      <Confirm
        isOpen={open}
        title="Reset Password"
        content="Are you sure you want to send a password reset email?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        email="email"
      />
    </>
  );
};

const resetPassword = () => {
  Auth.forgotPassword(
    { email: email.value, reCaptchaValue: '' }, // eslint-disable-line no-undef
    { Authorization: `Bearer ${Auth.getAuthToken()}` },
    () => MAlert('Email Sent'),
    (err) => MAlert(`Error: ${err.message}`),
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <CustomResetPasswordButton onClick={resetPassword} label="Send Password Reset Email"></CustomResetPasswordButton>
    <DeleteButton mutationMode="pessimistic" />
  </Toolbar>
);

export const UserEdit = (props) => {
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar {...props} />}>
        <TextInput source="id" disabled />
        <TextInput source="email" validate={required('Email is required')} />
        <TextInput source="name" />
        <TextInput source="title" />
        <TextInput source="phone" />
        <BooleanInput source="active" />
        <ReferenceInput
          label="Enterprise"
          source="enterprise_id"
          reference="enterprises"
          perPage={5000}
          sort={{ field: 'name', order: 'ASC' }}
          validate={required('Enterprise is required')}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="role_ids" reference="roles" label="Roles">
          <SelectArrayInput
            optionText={(option) => {
              const customRoleName = roleNames.find((roleName) => roleName.name == option.name)?.label || option.name;
              return customRoleName;
            }}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" validate={required('Email is required')} />
      <TextInput source="name" />
      <TextInput source="title" />
      <TextInput source="phone" />
      <BooleanInput source="active" />
      <ReferenceArrayInput label="Roles" source="role_ids" reference="roles">
        <SelectArrayInput
          optionText={(option) => {
            const customRoleName = roleNames.find((roleName) => roleName.name == option.name)?.label || option.name;
            return customRoleName;
          }}
        />
      </ReferenceArrayInput>
      <ReferenceInput
        label="Enterprise"
        source="enterprise_id"
        reference="enterprises"
        perPage={5000}
        sort={{ field: 'name', order: 'ASC' }}
        validate={required('Enterprise is required')}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
