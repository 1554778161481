import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from './FormSelect';

class DateSelector extends Component {
  constructor(props) {
    super(props);
  }

  getOptions() {
    const daysInMonth = this.props.relativeDate.dateValue().daysInMonth();
    const options = [{ label: 'Last Day', value: daysInMonth }];
    for (let day = 1; day <= daysInMonth; day++) {
      options.push({ label: `${day}`, value: day });
    }
    return options;
  }

  render() {
    const { relativeDate } = this.props;
    const date = relativeDate ? relativeDate.dateValue().date() : '';
    let label;
    if (relativeDate.mod === null) {
      label = date;
    } else if (relativeDate.mod === -1) {
      label = 'Last Day';
    } else {
      label = relativeDate.mod;
    }
    const options = relativeDate.dateValue() ? this.getOptions() : '';
    const value = { label: label, value: date };
    return (
      <React.Fragment>
        <Select
          classNamePrefix="matik-select"
          className="matik-select-container control"
          options={options}
          value={value}
          onChange={(selected) => this.onChange(selected)}
          menuPortalTarget={null}
          menuPosition={'absolute'}
        />
      </React.Fragment>
    );
  }

  onChange = (selected) => {
    this.props.onChange(selected);
  };
}

DateSelector.propTypes = {
  fullDate: PropTypes.object,
  onChange: PropTypes.func,
  relativeDate: PropTypes.object,
};

export default DateSelector;
