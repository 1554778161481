import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import TemplateSlide from './TemplateSlide';
import { useTemplateThumbnails } from 'lib/hooks/useTemplate';

function SlideThumbnailsSlider(props) {
  const { data: thumbnailData } = useTemplateThumbnails(props.currentTemplate?.id, props.currentTemplate?.deleted);

  const [isScrolling, setIsScrolling] = useState(false);

  // use a ref here rather than state because we don't want or need to trigger a re-render on mouse-down
  // (re-rendering on mouse down seems to wreak havok with onClick event binding in child components)
  const dragStartRef = useRef();

  const sliderRef = useRef();

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      const newLeft = 176 * props.scrollToSlideIdx - 300;
      slider.scrollTo({ left: newLeft, behavior: Math.abs(slider.scrollLeft - newLeft) > 700 ? 'instant' : 'smooth' });
    }
  }, [props.scrollToSlideIdx]);

  const startDrag = (e) => {
    dragStartRef.current = { pageX: e.pageX, scrollLeft: sliderRef.current.scrollLeft };
    setIsScrolling(false); // we're not scrolling until the mouse moves
  };

  const stopDrag = () => {
    dragStartRef.current = null;

    // When the user finishes "dragging" a slide, both onMouseUp and slide clicks will fire when the
    // mouse button is released. If we set isScrolling=false immediately on mouse-up here, it'll be false
    // when handling slide clicks and TemplateSlide will fire associated events.
    // e.g. the user will scroll to where they want and then we'll select and immediately re-scroll to the slide.
    // By delaying it, we can tell TemplateSlide to ignore clicks until after everything has settled.
    setTimeout(() => setIsScrolling(false), 0);
  };

  const onMouseMove = (e) => {
    const dragStart = dragStartRef.current;
    if (dragStart) {
      sliderRef.current.scrollLeft = dragStart.scrollLeft - (e.pageX - dragStart.pageX);
      setIsScrolling(true);
    }
  };

  const isUnfiltered = (slide) => {
    if (props.filteredSlides?.length > 0) {
      return props.filteredSlides.some((slideId) => slide.id == slideId);
    } else {
      return true;
    }
  };

  return (
    <>
      <span
        className="slides-slider items pts"
        ref={sliderRef}
        onMouseDown={startDrag}
        onMouseUp={stopDrag}
        onMouseLeave={stopDrag}
        onMouseMove={onMouseMove}
      >
        {props.currentTemplate?.slides.map((slide, idx) => {
          const thumbData = thumbnailData?.[parseInt(slide.id)];
          const slideFetchStatus = thumbData && {
            data: thumbData,
            fetchState: 'fetched',
            errorMessage: '',
          };
          return (
            <span
              className={`slide-thumbnail-container item ${isEqual(props.viewingSlide, slide) ? 'viewing' : ''} ${
                idx === props.currentTemplate.slides.length - 1 ? 'is-last' : ''
              }`}
              key={`${slide.id}_${idx}`}
            >
              <TemplateSlide
                currentTemplate={props.currentTemplate}
                highlightedSlideNums={props.highlightedSlideNums}
                isHidden={!isUnfiltered(slide)}
                isLast={idx === props.currentTemplate.slides.length - 1}
                selectedSlides={props.selectedSlides}
                slideFetchStatus={slideFetchStatus}
                slideNum={idx + 1}
                slide={slide}
                showConditions={props.showConditionsModal}
                showLoops={props.showLoopModal}
                status={props.status}
                toggleSlide={props.toggleSlide}
                showingSingleSlide={props.showingSingleSlide}
                popupPlacement={'top'}
                inSlider={true}
                setViewingSlide={props.setViewingSlide}
                ignoreClicks={isScrolling}
                setScrollToSlideIdx={props.setScrollToSlideIdx}
                matchingInputs={props.matchingInputs}
                onCopyConditions={props.onCopyConditions}
                copiedConditions={props.copiedConditions}
                onSelectSlidesMatchingConditions={props.onSelectSlidesMatchingConditions}
              />
            </span>
          );
        })}
      </span>
    </>
  );
}

SlideThumbnailsSlider.propTypes = {
  onSlideClick: PropTypes.func,
  selectedSlides: PropTypes.array,
  viewingSlide: PropTypes.object,
  currentTemplate: PropTypes.object,
  status: PropTypes.object,
  highlightedSlideNums: PropTypes.array,
  toggleSlide: PropTypes.func,
  showConditionsModal: PropTypes.func,
  showLoopModal: PropTypes.func,
  showingSingleSlide: PropTypes.bool,
  setViewingSlide: PropTypes.func,
  scrollToSlideIdx: PropTypes.number,
  setScrollToSlideIdx: PropTypes.func,
  matchingInputs: PropTypes.object,
  filteredSlides: PropTypes.arrayOf(PropTypes.number),

  onCopyConditions: PropTypes.func,
  copiedConditions: PropTypes.object,
  onSelectSlidesMatchingConditions: PropTypes.func,
};

export default SlideThumbnailsSlider;
