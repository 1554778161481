import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dataSourceIcon from '../../../images/data_sources.svg';
import BulkUpdateDataSourceModal from './BulkUpdateDataSourceModal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

function BulkUpdateDataSourceButton(props) {
  const getDataSourceType = () => {
    return !!props.selectedDynamicContent &&
      props.selectedDynamicContent.length > 0 &&
      props.selectedDynamicContent[0].query_obj.data_source.id > 0 &&
      props.selectedDynamicContent.every((dc) => {
        return dc.query_obj.data_source.type === props.selectedDynamicContent[0].query_obj.data_source.type;
      })
      ? props.selectedDynamicContent[0].query_obj.data_source.type
      : null;
  };

  const onBulkUpdateDataSourceClick = () => {
    props.openModal('bulkUpdateDataSourceModal');
  };

  const dataSourceType = getDataSourceType();
  const buttonIsDisabled = !dataSourceType;

  return (
    <React.Fragment>
      <span
        data-tooltip-id="matik-tooltip"
        data-tooltip-content={
          buttonIsDisabled ? 'You can only bulk update dynamic content with data sources of the same type' : null
        }
      >
        <button className="button is-secondary" onClick={onBulkUpdateDataSourceClick} disabled={buttonIsDisabled}>
          <img className="mrs" src={dataSourceIcon} alt="Update data source" /> Update Data Source
        </button>
      </span>
      <BulkUpdateDataSourceModal
        buttonIsDisabled={buttonIsDisabled}
        dataSourceType={dataSourceType}
        selectedItemIds={(props.selectedDynamicContent || []).map((dc) => dc.id)}
      />
    </React.Fragment>
  );
}

BulkUpdateDataSourceButton.propTypes = {
  selectedDynamicContent: PropTypes.array,
  openModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(BulkUpdateDataSourceButton);
