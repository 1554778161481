import API from '../api';
import LongRequest from '../longRequest';

export const useTemplateTagMutator = () => {
  const getStatusCallback = (response, onComplete, onDone) => {
    if (response.data.template_status === 'done' || response.data.template_status === 'unknown') {
      onComplete();
      onDone(response.data);
    }
  };
  const create = (tagBody, tagId, currentTemplate, selectedSlide, textSelection) => {
    return new Promise((resolve, reject) => {
      const data = {
        content_tag: tagBody,
        page_element_id: tagId,
        text_to_replace: textSelection,
      };
      const longRequest = new LongRequest('/templates');
      API.post(
        `/templates/${currentTemplate.id}/${selectedSlide.slide_id}/tag/`,
        data,
        (initialResponse) =>
          longRequest.getStatus(
            currentTemplate.id,
            (response, onComplete) => {
              if (initialResponse.data?.tag) {
                response.data.tag = initialResponse.data.tag;
              }
              getStatusCallback(response, onComplete, resolve);
            },
            (err) => reject(err),
          ),
        (err) => reject(err),
      );

      API.track('create_tag', {
        templateType: currentTemplate.source_type, // word_365, google_slides, etc...
      });
    });
  };

  const update = (tagBody, tagId, existingTag, currentTemplate, selectedSlide) => {
    return new Promise((resolve, reject) => {
      const data = {
        new_content_tag: tagBody,
        content_tag_to_replace: existingTag,
        page_element_id: tagId,
      };
      const longRequest = new LongRequest('/templates');
      API.put(
        `/templates/${currentTemplate.id}/${selectedSlide.slide_id}/tag/`,
        data,
        () =>
          longRequest.getStatus(
            currentTemplate.id,
            (response, onComplete) => getStatusCallback(response, onComplete, () => resolve(tagBody, tagId)),
            (err) => reject(err),
          ),
        (err) => reject(err),
      );

      API.track('save_tag', {
        templateType: currentTemplate.source_type, // word_365, google_slides, etc...
      });
    });
  };

  const deleteTag = (elementId, tagName, currentTemplate, selectedSlide) => {
    return new Promise((resolve, reject) => {
      const longRequest = new LongRequest('/templates');

      API.delete(
        `/templates/${currentTemplate.id}/${selectedSlide.slide_id}/tag/${elementId}/${tagName}/`,
        () =>
          longRequest.getStatus(
            currentTemplate.id,
            (response, onComplete) => getStatusCallback(response, onComplete, () => resolve()),
            (err) => reject(err),
          ),
        (err) => reject(err),
      );

      API.track('delete_tag', {
        templateType: currentTemplate.source_type, // word_365, google_slides, etc...
      });
    });
  };

  return {
    create,
    deleteTag,
    update,
  };
};
