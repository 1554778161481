import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'components/lib/ButtonGroup';
import Button from 'components/lib/Button';

function GenericOauthForm({
  auth,
  children,
  connectToDataSource,
  deleteDataSource,
  reconnectToDataSource,
  selectedDataSource,
  type,
}) {
  const isConnectedToDataSource = auth.access_token || auth.refresh_token;
  const buttonColor = isConnectedToDataSource ? 'secondary' : 'primary';
  const onClick = isConnectedToDataSource
    ? (e) => deleteDataSource(e, selectedDataSource)
    : (e) => connectToDataSource(e, type);
  const buttonText = isConnectedToDataSource ? 'Delete Data Source' : 'Connect to Data Source';
  return (
    <>
      {children}

      <div className="mtl">
        <ButtonGroup>
          {isConnectedToDataSource ? (
            <Button onClick={(e) => reconnectToDataSource(e, type)} category="secondary">
              Reconnect to Data Source
            </Button>
          ) : null}
          <Button category={buttonColor} onClick={onClick}>
            {buttonText}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}

GenericOauthForm.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.any,
  connectToDataSource: PropTypes.func,
  deleteDataSource: PropTypes.func,
  onAuthMethodChange: PropTypes.func,
  reconnectToDataSource: PropTypes.func,
  selectedDataSource: PropTypes.object,
  type: PropTypes.string,
};

export default GenericOauthForm;
