import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithLoadingAndEmpty from '../shared/WithLoadingAndEmpty';
import EnterpriseSettingsSettings from './EnterpriseSettingsSettings';
import { Route, Switch } from 'react-router-dom';
import Users from './Users';
import PageWrapper from './PageWrapper';
import Groups from './Groups';
import Clients from './Clients';

class EnterpriseSettingsContent extends Component {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.path}/users`} render={this.renderUsers} />
        <Route path={`${this.props.match.path}/groups`} render={this.renderGroups} />
        <Route path={`${this.props.match.path}/clients`} render={this.renderClients} />
        <Route exact path={this.props.match.path} render={this.renderSettings} />
      </Switch>
    );
  }

  renderSettings = (props) => {
    return (
      <PageWrapper
        enterprise={this.props.enterprise}
        currentUrl={props.match.url}
        onEnterpriseUpdate={this.props.onEnterpriseUpdate}
      >
        <EnterpriseSettingsSettings
          enterprise={this.props.enterprise}
          onEnterpriseUpdate={this.props.onEnterpriseUpdate}
        />
      </PageWrapper>
    );
  };

  renderUsers = (props) => {
    return (
      <PageWrapper
        enterprise={this.props.enterprise}
        currentUrl={props.match.url}
        onEnterpriseUpdate={this.props.onEnterpriseUpdate}
      >
        <Users
          enterprise={this.props.enterprise}
          roles={this.props.roles}
          updateEnterpriseCounts={this.props.updateEnterpriseCounts}
          entityType="user"
        />
      </PageWrapper>
    );
  };

  renderGroups = (props) => {
    return (
      <PageWrapper
        enterprise={this.props.enterprise}
        currentUrl={props.match.url}
        onEnterpriseUpdate={this.props.onEnterpriseUpdate}
      >
        <Groups enterprise={this.props.enterprise} />
      </PageWrapper>
    );
  };

  renderClients = (props) => {
    return (
      <PageWrapper
        enterprise={this.props.enterprise}
        currentUrl={props.match.url}
        onEnterpriseUpdate={this.props.onEnterpriseUpdate}
      >
        <Clients enterprise={this.props.enterprise} />
      </PageWrapper>
    );
  };
}

EnterpriseSettingsContent.propTypes = {
  enterprise: PropTypes.object,
  match: PropTypes.any,
  onEnterpriseUpdate: PropTypes.func,
  roles: PropTypes.array,
  updateEnterpriseCounts: PropTypes.func,
};

export default WithLoadingAndEmpty(EnterpriseSettingsContent);
