import React from 'react';
import PropTypes from 'prop-types';

const AlignOptions = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

const WidthOptions = {
  FULL_WIDTH: 'fullWidth',
  HUG: 'hug',
};

function ButtonGroup({ children, align, width, gap = 2 }) {
  let alignClass = 'justify-center';
  if (align === AlignOptions.LEFT) {
    alignClass = 'justify-start';
  } else if (align === AlignOptions.RIGHT) {
    alignClass = 'justify-end';
  }

  const gapClass = gap !== 0 ? `gap-${gap}` : '';

  const widthClass = width === WidthOptions.FULL_WIDTH ? 'w-full' : '';
  return <div className={`flex ${gapClass}  pointer-events-auto ${alignClass} ${widthClass}`}>{children}</div>;
}

ButtonGroup.propTypes = {
  align: PropTypes.oneOf(Object.values(AlignOptions)),
  gap: PropTypes.number,
  width: PropTypes.oneOf(Object.values(WidthOptions)),
  children: PropTypes.arrayOf(PropTypes.node),
};

ButtonGroup.defaultProps = {
  width: WidthOptions.FULL_WIDTH,
};

export default ButtonGroup;
