const REQUEST_API_INFO = 'REQUEST_API_INFO';
const RECEIVE_API_INFO = 'RECEIVE_API_INFO';
const INVALIDATE_API_INFO = 'INVALIDATE_API_INFO';
const REQUEST_REPORT_METADATA = 'REQUEST_REPORT_METADATA';
const RECEIVE_REPORT_METADATA = 'RECEIVE_REPORT_METADATA';
const RECEIVE_REPORT_COUNT = 'RECEIVE_REPORT_COUNT';
const RECEIVE_OBJECT_COUNT = 'RECEIVE_OBJECT_COUNT';
const IS_FETCHING = 'IS_FETCHING';
const IS_NOT_FETCHING = 'IS_NOT_FETCHING';

export {
  REQUEST_API_INFO,
  RECEIVE_API_INFO,
  INVALIDATE_API_INFO,
  REQUEST_REPORT_METADATA,
  RECEIVE_REPORT_METADATA,
  RECEIVE_REPORT_COUNT,
  RECEIVE_OBJECT_COUNT,
  IS_FETCHING,
  IS_NOT_FETCHING,
};
