import React, { useState, useEffect } from 'react';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { Heading } from 'react-bulma-components';
import PropTypes from 'prop-types';
import EmailRecipientDropdown from './EmailRecipientDropdown';
import { EmailRecipientSelector } from './EmailRecipientSelector';
import utils from 'lib/utils';
import dynamicContent from 'lib/dynamicContent';
import { cloneDeep, isEqual } from 'lodash';
import HowTo from '../../shared/HowTo';

function EmailRecipientModal(props) {
  const initialDynamicRecipientsName = dynamicContent.getDynamicContentNameById(
    parseInt(props.currentTemplate.source.recipients.dynamicRecipients),
    props.allDynamicContent,
  );
  const [dynamicRecipientsId, setDynamicRecipientsId] = useState(props.dynamicRecipientsId);
  const [ccRecipients, setCcRecipients] = useState(props.ccRecipients);
  const [validEmails, setValidEmails] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [primaryButtonText, setPrimaryButtonText] = useState('');

  useEffect(() => {
    const buttonText = props.dynamicRecipientsId || props.ccRecipients?.length ? 'Edit Recipients' : 'Add Recipients';
    setPrimaryButtonText(buttonText);
  }, []);

  useEffect(() => {
    setCcRecipients(props.ccRecipients);
    setDynamicRecipientsId(props.dynamicRecipientsId);
  }, [props.ccRecipients, props.dynamicRecipientsId]);

  const headerCenter = <Heading className="full-screen-modal-title">{primaryButtonText}</Heading>;

  const onChange = (field, data) => {
    if (field === 'dynamicRecipients') {
      setDynamicRecipientsId(data);
      props.onRecipientFieldChange(field, data);
    }
  };

  const onCcRecipientChange = (e) => {
    const newEmailList = [];
    if (e) {
      for (const user of e) {
        newEmailList.push(user.value);
      }
    }
    setCcRecipients(newEmailList);
    props.onRecipientFieldChange('cc', newEmailList);
  };

  const onUpdateRecipientSubmit = async (e) => {
    e.preventDefault();
    if (checkValidEmails()) {
      const updatedTemplate = props.currentTemplate;
      // remove the old dynamic recipients from dynamic_content_tags and replace it with the new one to send to the backend
      const tagsArray = cloneDeep(props.currentTemplate.slides[0]?.dynamic_content_tags) || [];
      const currentDynamicRecipientIndex = tagsArray.indexOf(initialDynamicRecipientsName);
      if (currentDynamicRecipientIndex > -1) {
        tagsArray.splice(currentDynamicRecipientIndex, 1);
      }
      tagsArray.unshift(
        dynamicContent.getDynamicContentNameById(parseInt(dynamicRecipientsId), props.allDynamicContent),
      );

      updatedTemplate.newTags = tagsArray;
      updatedTemplate.source.recipients.cc = ccRecipients;
      updatedTemplate.source.recipients.dynamicRecipients = dynamicRecipientsId;
      await props.onTemplateUpdateWithServerCall(updatedTemplate);
      props.onClose();
    }
  };

  const checkValidEmails = () => {
    for (const email of ccRecipients) {
      if (!utils.isEmailValid(email)) {
        setValidEmails(false);
        setEmailErrorMsg(email + ' is not a valid email address');
        return;
      } else {
        continue;
      }
    }
    return true;
  };

  const primaryButtonDisabled =
    props.currentTemplate.source.recipients.dynamicRecipients === dynamicRecipientsId &&
    (isEqual(props.currentTemplate.source.recipients.cc, ccRecipients) ||
      (!props.currentTemplate.source.recipients.cc && !ccRecipients.length));

  return (
    <FullScreenModal
      show={props.show}
      headerCenter={headerCenter}
      showDefaultFooter={true}
      backgroundColor="white"
      onClose={props.onClose}
      primaryButtonText={primaryButtonText}
      primaryButtonOnClick={onUpdateRecipientSubmit}
      primaryButtonDisabled={primaryButtonDisabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={props.onClose}
    >
      <div className="three-column-modal-body">
        <div className="modal-sidebar invisible"></div>
        <div className="modal-body-center ptn">
          <div className="email-recipients-modal-box">
            <p>Dynamic Recipients</p>
            <EmailRecipientDropdown
              entityType="dynamic_content"
              dynamicRecipientsId={dynamicRecipientsId}
              ccRecipients={ccRecipients}
              onChange={onChange}
              dynamicContent={props.allDynamicContent}
              searchAttributes={props.searchAttributes}
              onSearchStateChange={props.onSearchStateChange}
              searchState={props.searchState}
              isSearching={props.isSearching}
              setDynamicRecipientsId={setDynamicRecipientsId}
            />
          </div>
          <div className="email-recipients-modal-box">
            <p>CC Recipients</p>
            <EmailRecipientSelector ccRecipients={ccRecipients} onCcRecipientChange={onCcRecipientChange} />
            {!validEmails && <p className="help is-danger recipient-modal">{emailErrorMsg}</p>}
          </div>
        </div>
        <div className="modal-sidebar">
          <HowTo>
            <p className="how-to-heading">HOW TO</p>
            <p className="how-to-text list">Create a recipient type dynamic content for your send list.</p>
            <p className="how-to-text list">Only emails of Matik users can be added to CC field.</p>
            <a
              href="https://help.matik.io/hc/en-us/articles/13090747609627"
              target="_blank"
              rel="noreferrer"
              className="how-to-text link"
            >
              Help Center Article
            </a>
          </HowTo>
        </div>
      </div>
    </FullScreenModal>
  );
}

EmailRecipientModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  currentTemplate: PropTypes.object,
  onRecipientFieldChange: PropTypes.func,
  dynamicRecipientsId: PropTypes.string,
  ccRecipients: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  allDynamicContent: PropTypes.object,
  searchState: PropTypes.object,
  searchAttributes: PropTypes.object,
  isSearching: PropTypes.bool,
  entityType: PropTypes.string,
  onSearchStateChange: PropTypes.func,
};

export default EmailRecipientModal;
