import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PasswordAuth from './PasswordAuth';
import TokenAuth from './TokenAuth';
import AuthTabs from '../AuthTabs';
import { ReactComponent as KeyMultiple } from '../../../../images/key_multiple.svg';
import OAuthAuth from './OAuthAuth';

function RestAuth({ auth, centered, checkboxes, isOAuth, onAuthChange, setIsOAuth }) {
  const tabs = [
    { name: 'login-tab', title: 'Access Via Login' },
    { name: 'token-tab', title: 'Access Via Auth Token', isOptional: true, TabIcon: KeyMultiple },
    { name: 'oauth-tab', title: 'Access Via OAuth2', isOptional: true },
  ];
  const [activeAuthTab, setActiveAuthTab] = useState(tabs[auth?.auth_token ? 1 : isOAuth ? 2 : 0].name);

  const setAuthTabWithMethod = (currentTab) => {
    if (currentTab === tabs[2].name) {
      setIsOAuth(true);
    } else {
      setIsOAuth(false);
    }
    setActiveAuthTab(currentTab);
  };

  return (
    <React.Fragment>
      <AuthTabs tabs={tabs} activeAuthTab={activeAuthTab} centered={centered} setActiveAuthTab={setAuthTabWithMethod} />
      {activeAuthTab === 'login-tab' && (
        <PasswordAuth
          onAuthChange={onAuthChange}
          auth={auth}
          checkboxes={checkboxes}
          usernameHelp="Username for basic authentication for REST API"
          passwordHelp="Password for basic authentication for REST API"
        />
      )}
      {activeAuthTab === 'token-tab' && <TokenAuth onAuthChange={onAuthChange} auth={auth} checkboxes={checkboxes} />}
      {activeAuthTab === 'oauth-tab' && <OAuthAuth auth={auth} onAuthChange={onAuthChange} checkboxes={checkboxes} />}
    </React.Fragment>
  );
}

RestAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  checkboxes: PropTypes.array,
  isOAuth: PropTypes.bool,
  onAuthChange: PropTypes.func,
  setIsOAuth: PropTypes.func,
};

export default RestAuth;
