import React from 'react';
import PropTypes from 'prop-types';

import { QueryEditor } from 'components/producer/dynamicContent/QueryEditor';

const QueryDisplay = ({ queryString }) => {
  return <QueryEditor queryString={queryString} mode="sql" isDynamicContentSuggested isReadOnly />;
};
QueryDisplay.propTypes = {
  queryString: PropTypes.string,
};

export default QueryDisplay;
