import React from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/lib/ListItem';
import IconPill from 'components/lib/IconPill';

const ListItemCondition = ({ condition, metadata, showSubtitle, showIndicator, onClick }) => {
  if (!condition) {
    return null;
  }

  const icon = <IconPill size="s" theme="square" luminosity="medium" color="white" iconName="condition_arrow" />;

  const indicatorColor = showIndicator ? condition.icon_color || 'red-500' : null;
  return (
    <ListItem
      icon={icon}
      indicatorColor={indicatorColor}
      title={condition.name}
      subtitle={showSubtitle ? condition.description : ''}
      metadata={metadata}
      onClick={onClick}
    />
  );
};
ListItemCondition.propTypes = {
  showSubtitle: PropTypes.bool,
  showIndicator: PropTypes.bool,
  metadata: PropTypes.node,
  onClick: PropTypes.func,
  condition: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    icon_color: PropTypes.string,
  }),
};

export default ListItemCondition;
