import React from 'react';
import PropTypes from 'prop-types';
import Sidepane from 'components/shared/sidepane/Sidepane';
import DynamicContentForm from './DynamicContentForm';
import { closeSidepane, openUnsavedChangesModal } from 'redux/ui/action';
import { useDispatch, useSelector } from 'react-redux';
import Constants from 'components/Constants';
import RenamableModal from './RenamableModal';
import SmallLoader from 'components/shared/SmallLoader';

function ConditionalContentSidepane(props) {
  const { accessesByItemId, dynamicContentArr } = props;

  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const handleCloseSidepane = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (ui.isChanged) {
      dispatch(
        openUnsavedChangesModal({
          title: 'Close sidepane?',
          onConfirm: 'closeSidepane',
        }),
      );
    } else {
      dispatch(closeSidepane());
    }
  };

  if (ui.sidepane?.content?.entityType !== 'dynamic_content') {
    return null;
  }

  let body = <SmallLoader />;
  if (ui.sidepane?.content?.entityType === 'dynamic_content') {
    body = (
      <DynamicContentForm
        accessesByItemId={accessesByItemId}
        currentContent={ui?.sidepane?.content}
        dynamicContentArr={dynamicContentArr}
        entityId={ui.sidepane.content.id}
        entityType={Constants.UI_TYPES.dynamic_content}
        headerLinkDisabled={false}
        key={ui.sidepane.content.id}
        onClose={handleCloseSidepane}
        saveButtonText="Update"
        showCancel={true}
      />
    );
  }

  return (
    <Sidepane sidepaneType="conditional" name="conditional-content-sidepane">
      {body}
      <RenamableModal show={ui.modal?.name === 'renamableModal'} key={ui.modal?.content?.fieldName} isSubModal={true} />
    </Sidepane>
  );
}

ConditionalContentSidepane.propTypes = {
  accessesByItemId: PropTypes.object,
  currentContent: PropTypes.object,
  dynamicContentArr: PropTypes.array,
};

export default ConditionalContentSidepane;
