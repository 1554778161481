import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Level } from 'react-bulma-components';
import Tag from '../../lib/Tag';

function GenerationProcessInsight({ generationProcessTimes }) {
  const formatElapsedMs = (elapsedMs) => {
    if (elapsedMs < 1000) {
      return '< 1';
    }
    return Math.round(elapsedMs / 1000);
  };

  const getTagColor = (elapsedMs) => {
    if (elapsedMs < 10000) {
      return 'green';
    } else if (elapsedMs >= 10000 && elapsedMs < 60000) {
      return 'yellow';
    } else {
      return 'red';
    }
  };

  return (
    <div className="mr-2">
      <Heading size={5} className="mtm mbm text-grey">
        Average Generation Process
      </Heading>
      {generationProcessTimes['processing_inputs'] > 0 && (
        <Level className="mbs">
          <Level.Side align="left">
            <Level.Item>
              <Heading size={6} className="mtm">
                Processing Inputs
              </Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <Tag
                color={getTagColor(generationProcessTimes['processing_inputs'])}
                label={`${formatElapsedMs(generationProcessTimes['processing_inputs'])}s`}
              />
            </Level.Item>
          </Level.Side>
        </Level>
      )}
      {generationProcessTimes['querying_data'] > 0 && (
        <Level className="mbs">
          <Level.Side align="left">
            <Level.Item>
              <Heading size={6} className="mtm">
                Querying Data
              </Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <Tag
                color={getTagColor(generationProcessTimes['querying_data'])}
                label={`${formatElapsedMs(generationProcessTimes['querying_data'])}s`}
              />
            </Level.Item>
          </Level.Side>
        </Level>
      )}
      {generationProcessTimes['generating_presentation'] > 0 && (
        <Level className="mbs">
          <Level.Side align="left">
            <Level.Item>
              <Heading size={6} className="mtm">
                Generating Presentation
              </Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <Tag
                color={getTagColor(generationProcessTimes['generating_presentation'])}
                label={`${formatElapsedMs(generationProcessTimes['generating_presentation'])}s`}
              />
            </Level.Item>
          </Level.Side>
        </Level>
      )}
    </div>
  );
}

GenerationProcessInsight.propTypes = {
  generationProcessTimes: PropTypes.object,
};

export default GenerationProcessInsight;
