import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { Auth } from '../../lib/auth';
import { MAlert } from '../shared/Alerts';

const RoleRoute = (allowedRole, disallowedRoles) => {
  const inner = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (Auth.isLoggedIn()) {
          if (Auth.hasRole(allowedRole)) {
            return <Component {...props} />;
          }
          for (let rolename in disallowedRoles) {
            if (Auth.hasRole(rolename)) {
              return (
                <Redirect
                  to={{
                    pathname: disallowedRoles[rolename],
                    state: { from: props.location },
                  }}
                />
              );
            }
          }

          Auth.logOut(() => {
            MAlert(
              "You don't have the proper roles to log in. Contact your admin and ask them to add admin or end user roles to your account.",
              'Login Error',
              'error',
              () => window.location.assign('/login'),
            );
          });
          return '';
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );

  inner.propTypes = {
    component: PropTypes.any,
    location: PropTypes.object,
  };

  return inner;
};

const ProducerRoute = RoleRoute('producer', { consumer: '/create' });
const ConsumerRoute = RoleRoute('consumer', { producer: '/' });
const SuperadminRoute = RoleRoute('superadmin', { producer: '/', consumer: '/create' });

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !Auth.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: Auth.hasRole('producer') ? '/' : Auth.hasRole('superadmin') ? '/soup_dumpling' : '/create',
          }}
        />
      )
    }
  />
);

AuthRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

const LoggedInRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

LoggedInRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export { ConsumerRoute, ProducerRoute, SuperadminRoute, AuthRoute, LoggedInRoute };
