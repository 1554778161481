import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { Section } from 'react-bulma-components';
import DynamicContentForm from './DynamicContentForm';
import DynamicContentList from './DynamicContentList';
import API from '../../../lib/api';
import ConditionalContentSidepane from './ConditionalContentSidepane';
import FormSkeletonLoader from './FormSkeletonLoader';
import { useOneDynamicContent } from '../../../lib/hooks/useDynamicContent';
import Constants from '../../Constants';

function DynamicContentMain({
  match,
  dynamicContent,
  onBackToList,
  deleteDynamicContent,
  onContentAdd,
  isSearching,
  pagination,
  searchState,
  searchAttributes,
  fetchItems,
  isPaginating,
}) {
  const { dynamicContent: currentContent, isLoading, isError, error } = useOneDynamicContent(match.params.id);
  const history = useHistory();

  if (isError) {
    if (error.response) {
      if (error.response.status === 404) {
        history.replace('/dynamic_content');
      } else {
        API.defaultError(error);
      }
    } else {
      API.defaultError(error);
    }
  }
  const formSaveButtonText = currentContent ? 'Update' : 'Add';
  let formId = currentContent ? currentContent.id : 0;

  return (
    <div className="wrapper">
      <DynamicContentSidebar
        dynamicContent={dynamicContent}
        isFetching={isLoading}
        isFetchingContent={isLoading}
        currentContent={currentContent}
        isSearching={isSearching}
        match={match}
        pagination={pagination}
        searchAttributes={searchAttributes}
        searchState={searchState}
        fetchItems={fetchItems}
        isPaginating={isPaginating}
      />
      <div className="main pan">
        <Section className="is-paddingless">
          {isLoading ? (
            <FormSkeletonLoader />
          ) : (
            <DynamicContentForm
              key={formId}
              currentContent={currentContent}
              deleteDynamicContent={deleteDynamicContent}
              dynamicContentArr={dynamicContent}
              entityId={`${currentContent ? currentContent.id : Constants.NEW_CONTENT_ID}`}
              entityType={Constants.UI_TYPES.dynamic_content}
              headerLinkDisabled={true}
              onClose={onBackToList}
              onCloseDataTip="To List"
              onContentAdd={onContentAdd}
              onContentDeleted={() => history.replace('/dynamic_content')}
              saveButtonText={formSaveButtonText}
            />
          )}
          <ConditionalContentSidepane dynamicContentArr={dynamicContent} />
        </Section>
      </div>
    </div>
  );
}

DynamicContentMain.propTypes = {
  deleteDynamicContent: PropTypes.func,
  dynamicContent: PropTypes.array,
  isFetching: PropTypes.bool,
  isFetchingContent: PropTypes.bool,
  match: PropTypes.object,
  setCurrentContentIfNeeded: PropTypes.func,
  pagination: PropTypes.object,
  onBackToList: PropTypes.func,
  onContentAdd: PropTypes.func,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
};

const DynamicContentSidebar = (props) => (
  <div className="sidebar">
    <DynamicContentList
      dynamicContent={props.dynamicContent}
      selectedDynamicContent={props.currentContent}
      searchState={props.searchState}
      isSearching={props.isSearching}
      searchAttributes={props.searchAttributes}
      pagination={props.pagination}
      fetchItems={props.fetchItems}
      isPaginating={props.isPaginating}
    />
  </div>
);

DynamicContentSidebar.propTypes = {
  currentContent: PropTypes.object,
  dynamicContent: PropTypes.array,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
};

export default withRouter(DynamicContentMain);
