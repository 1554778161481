import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Section } from 'react-bulma-components';
import API from '../../lib/api';
import SmallLoader from '../shared/SmallLoader';

class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      emailHtml: null,
    };
  }

  componentDidMount() {
    const query = window.location.search;
    API.get(`/emails/${this.props.match.params.name}/${query}`, (response) => {
      this.setState({ isLoading: false, emailHtml: response.data });
    });
  }

  render() {
    let body = '';
    if (this.state.isLoading) {
      body = <SmallLoader />;
    } else {
      body = <div dangerouslySetInnerHTML={{ __html: this.state.emailHtml }}></div>;
    }

    return (
      <Container>
        <Section>{body}</Section>
      </Container>
    );
  }
}

Email.propTypes = {
  match: PropTypes.any,
};

export default Email;
