import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SpreadSheetCalculatorForm from './SpreadSheetCalculatorForm';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import Constants from 'components/Constants';

// Placeholder class for when we have two types of sheet content
class SpreadSheetForm extends Component {
  render() {
    return (
      <SpreadSheetCalculatorForm
        queryObj={this.props.queryObj}
        allDynamicContent={this.props.allDynamicContent}
        inputs={this.context.existingInputs}
        contents={this.props.contents}
        updateQueryObj={this.context.onQueryObjectUpdate}
        dataSource={this.props.dataSource}
        isReadOnly={this.context.isReadOnly}
        queryStringError={this.context.queryStringError}
        columnNameError={this.props.columnNameError}
        dynamicContentType={this.props.dynamicContentType}
        isSenderContent={this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER}
        onSenderFieldUpdate={this.context.onSenderFieldUpdate}
        multiFieldMapping={this.context.multiFieldMapping}
      />
    );
  }
}

SpreadSheetForm.contextType = DynamicContentContext;

SpreadSheetForm.propTypes = {
  allDynamicContent: PropTypes.array,
  contents: PropTypes.object,
  dataSource: PropTypes.object,
  dynamicContentType: PropTypes.string,
  columnNameError: PropTypes.string,
  queryObj: PropTypes.object,
};

export default SpreadSheetForm;
