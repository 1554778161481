import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tippy from '@tippyjs/react/headless';
import { ReactComponent as Plus } from '../../../svg/plus.svg';
import { ReactComponent as PlusSmall } from '../../../svg/plus_sm.svg';
import withUserContext from '../../shared/WithUserContext';
import { UserContext } from 'components/UserContext';
import Constants from '../../Constants';
import ReactDOMServer from 'react-dom/server';
import UpsellDetailedModal from '../../payments/UpsellDetailedModal';
import { MProductUpsell } from '../Alerts';
import API from '../../../lib/api';
import upsellSlideLibraryImage from '../../../images/upsell_slide_library.png';
import teams from '../../../lib/teams';

function InsertSlide(props) {
  const [show, setShow] = useState(false);
  const [tipInstance, setTipInstance] = useState(null);

  const {
    currentTemplate,
    dataTip,
    ignoreLibraries,
    inSlider,
    insertLibrarySlidesIntoTemplate,
    isDisabled,
    isLast,
    placement,
    PopUpComponent,
    slideNum,
  } = props;

  const user = useContext(UserContext).user;

  let mouseOutTimeout = null;

  let showClass = 'insert-slide';
  if (show && (!currentTemplate.is_library || ignoreLibraries)) {
    showClass += ' show';
  }
  if (isLast) {
    showClass += ' is-last';
  }

  let disabledColor = '';
  if (isDisabled) {
    disabledColor = 'disabled-gray';
  }

  const onMouseOver = (e) => {
    e.preventDefault();
    if (mouseOutTimeout) {
      clearTimeout(mouseOutTimeout);
    }
    setShow(true);
  };

  const onMouseOut = () => {
    if (show && !tipInstance?.state?.isVisible) {
      setShow(false);
    }
  };

  const onClickOutside = (instance, e) => {
    e.preventDefault();
    setShow(false);
    instance.hide();
  };

  const onShow = () => {
    if (teams.isTeamsUser(user.enterprise.plan_id)) {
      const planName = Constants.MATIK_TIERS[user.enterprise.plan_id].display_name;
      const noSlideLibraryHtml = ReactDOMServer.renderToStaticMarkup(
        <UpsellDetailedModal
          feature="slide_library"
          featureImage={upsellSlideLibraryImage}
          featureImageWidth={600}
          featureImageAlt="Slide Library"
          featureImageClassName="full-image"
          featureHeader={`Slide Library with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
          featureDescription={`Slide Library is not available for the ${planName} plan. Please upgrade to add slides from library.`}
        />,
      );
      API.track('enterprise_upsell_shown', { from: 'no_slide_library' });
      MProductUpsell(`${noSlideLibraryHtml}`, true, (result) => {
        if (!result.dismiss) {
          API.track('discover_matik_enterprise_click', { from: 'no_slide_library' });
          const demoUrl = teams.buildRequestDemoUrl(user, 'slide_library', user.enterprise.trial_days_remaining);
          window.open(demoUrl, '_blank');
        }
      });
    }
  };

  const onTipMount = (instance) => {
    setTipInstance(instance);
  };

  return (
    <div
      className={showClass}
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={onMouseOver}
      onMouseLeave={() => (mouseOutTimeout = setTimeout(onMouseOut, 150))}
    >
      <Tippy
        render={() => (
          <PopUpComponent
            currentTemplate={currentTemplate}
            insertionIdx={slideNum - 1}
            insertLibrarySlidesIntoTemplate={insertLibrarySlidesIntoTemplate}
          />
        )}
        trigger="click"
        placement={placement || 'bottom'}
        interactive={true}
        onMount={onTipMount}
        onClickOutside={onClickOutside}
        onShow={onShow}
        disabled={isDisabled}
        appendTo={document.body}
      >
        <div className={`icon-wrapper ${isDisabled ? 'has-cursor-not-allowed' : ''}`}>
          {inSlider ? (
            <PlusSmall
              className={`insert-slide-icon ${disabledColor}`}
              data-tooltip-content={dataTip}
              data-tooltip-id="matik-tooltip"
            />
          ) : (
            <Plus
              className={`insert-slide-icon ${disabledColor}`}
              data-tooltip-content={dataTip}
              data-tooltip-id="matik-tooltip"
            />
          )}
        </div>
      </Tippy>
    </div>
  );
}

InsertSlide.propTypes = {
  currentTemplate: PropTypes.object,
  ignoreLibraries: PropTypes.bool,
  isLast: PropTypes.bool,
  PopUpComponent: PropTypes.any,
  slideNum: PropTypes.number,
  insertLibrarySlidesIntoTemplate: PropTypes.func,
  isDisabled: PropTypes.bool,
  history: PropTypes.object,
  userContext: PropTypes.object,
  placement: PropTypes.string,
  inSlider: PropTypes.bool,
  dataTip: PropTypes.string,
};

export default withRouter(withUserContext(InsertSlide));
