import {
  RECEIVE_ADD_GROUP,
  RECEIVE_DELETE_GROUP,
  RECEIVE_GROUP_MEMBERS,
  RECEIVE_GROUPS,
  RECEIVE_UPDATE_GROUP,
  REQUEST_ADD_GROUP,
  REQUEST_DELETE_GROUP,
  REQUEST_GROUP_MEMBERS,
  REQUEST_GROUPS,
  REQUEST_UPDATE_GROUP,
} from './actionTypes';

export function groups(state = { isFetching: true, isFetchingMembers: true, groups: null }, action) {
  const updatedGroups = state.groups ? state.groups.slice() : [];
  switch (action.type) {
    case REQUEST_GROUPS:
      return Object.assign({}, state, { isFetching: true, groups: [] });
    case RECEIVE_GROUPS:
      return Object.assign({}, state, { isFetching: false, groups: action.groups });
    case REQUEST_ADD_GROUP:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVE_ADD_GROUP:
      updatedGroups.push(action.groupToAdd);
      return Object.assign({}, state, { isFetching: false, groups: updatedGroups });
    case REQUEST_UPDATE_GROUP:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVE_UPDATE_GROUP:
      return Object.assign({}, state, { isFetching: false, groups: _updateGroups(updatedGroups, action.updatedGroup) });
    case REQUEST_DELETE_GROUP:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVE_DELETE_GROUP:
      return Object.assign({}, state, { isFetching: false, groups: _deleteGroup(updatedGroups, action.groupId) });
    case REQUEST_GROUP_MEMBERS:
      return Object.assign({}, state, { isFetchingMembers: true });
    case RECEIVE_GROUP_MEMBERS:
      return Object.assign({}, state, {
        isFetchingMembers: false,
        groups: _addMembers(updatedGroups, action.membersByGroupId),
      });
    default:
      return state;
  }
}

function _updateGroups(groups, updatedGroup) {
  const updatedGroups = [];
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (group.id === updatedGroup.id) {
      updatedGroups[i] = updatedGroup;
    } else {
      updatedGroups[i] = group;
    }
  }

  return updatedGroups;
}

function _deleteGroup(groups, deletedGroupId) {
  const updatedGroups = [];
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (group.id !== deletedGroupId) {
      updatedGroups[i] = group;
    }
  }

  return updatedGroups;
}

function _addMembers(groups, membersByGroupId) {
  for (let group of groups) {
    group.members = membersByGroupId[group.id] ? membersByGroupId[group.id] : [];
  }

  return groups;
}
