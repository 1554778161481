import React from 'react';
import PropTypes from 'prop-types';
import 'components/shared/FormInput.sass';

function FormInput(props) {
  const {
    containerClass,
    disabled,
    inputClass,
    max,
    min,
    name,
    onChange,
    placeholder,
    prefix,
    required,
    suffix,
    type,
    value,
  } = props;

  return (
    <span className={containerClass}>
      {prefix && <span className="input-prefix">{prefix}</span>}
      <input
        className={inputClass}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
      {suffix && <span className="input-suffix">{suffix}</span>}
    </span>
  );
}

FormInput.propTypes = {
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  inputClass: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.any,
  required: PropTypes.bool,
  suffix: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
};

export default FormInput;
