import React from 'react';
import PropTypes from 'prop-types';

/** A flow-chart-like connector with an optional label centered in the middle and line connections radiating out
 * to the top, right, and bottom. No label means just the lines will show up. */
function Connector({
  label,
  up,
  down,
  left,
  right,
  roundedUpRight,
  roundedDownRight,
  className = '',
  labelClassName = '',
  onClick,
}) {
  let connectorUpperRightBorder = '';
  let connectorBottomRightBorder = '';
  if (down && right && roundedDownRight) {
    connectorBottomRightBorder = 'rounded-tl-xl border-t border-l';
    if (up) {
      connectorUpperRightBorder = 'border-l -mb-2.5';
    }
  } else if (up && right && roundedUpRight) {
    connectorUpperRightBorder = 'rounded-bl-xl border-b border-l';
    if (down) {
      connectorBottomRightBorder = 'border-l -mt-2.5';
    }
  } else {
    connectorUpperRightBorder = `${up ? 'border-l' : ''}`;
    connectorBottomRightBorder = `${down ? 'border-l' : ''} ${right ? 'border-t' : ''}`;
  }

  return (
    <div className={`relative ${className} flex justify-center items-center`}>
      <div className={'inset-0 absolute grid grid-cols-2 grid-rows-2'}>
        <div className={`col-start-2 border-grey-300 ${connectorUpperRightBorder}`}></div>
        <div className={`border-grey-300 ${left ? 'border-t' : ''}`}></div>
        <div className={`border-grey-300 ${connectorBottomRightBorder}`}></div>
      </div>
      {label && (
        <Label className={labelClassName} onClick={onClick}>
          {label}
        </Label>
      )}
    </div>
  );
}
Connector.propTypes = {
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  up: PropTypes.bool,
  down: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  roundedUpRight: PropTypes.bool,
  roundedDownRight: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Connector;

const Label = ({ children, className, onClick }) => {
  let fullClassName =
    'inline-block z-10 px-1.5 py-1 border border-grey-300 rounded-lg bg-matik-white text-grey-500 leading-none flex justify-center';
  if (className) {
    fullClassName += ' ' + className;
  }

  if (onClick) {
    return (
      <button className={fullClassName} onClick={onClick}>
        {children}
      </button>
    );
  } else {
    return <div className={fullClassName}>{children}</div>;
  }
};
Label.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
