import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import HomeContent from './HomeContent';
import { Heading, Columns } from 'react-bulma-components';
import { UserContext } from '../../UserContext';
import { Link } from 'react-router-dom';
import HomeInsights from './HomeInsights';
import { ReactComponent as Plus } from '../../../svg/plus.svg';
import { connect } from 'react-redux';
import WithNotifications from '../../shared/WithNotifications';
import AccessManager from '../../../lib/AccessManager';
import AccessModal from '../../shared/AccessModal';
import SortableEntityGrid from '../../shared/SortableEntityGrid';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { useTemplates } from 'lib/hooks/useTemplate';
import useAccesses from 'lib/hooks/useAccess';

class HomeTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: {},
      showAccessModal: false,
      templateLibraryTemplates: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    API.track('home_tab_navigation');
  }

  render() {
    const buttonComponent = (
      <Link className="button is-primary is-size-5" to="/templates/?first=true" id="create-first-template">
        <Plus />
        &nbsp;Create Your First Template
      </Link>
    );

    const emptyInfo = {
      emptyImageUrl: '/images/home_empty.png',
      emptyTitle: "You haven't created any templates yet.",
      emptyPrompt: 'To get started, upload your first Template and start making your content dynamic!',
      buttonComponent: buttonComponent,
    };

    let userFirstName = null;
    if (this.context.user && this.context.user.name) {
      userFirstName = this.context.user.name.split(' ')[0];
    }

    const isEmptyStateBannerShown =
      !this.props.isFetching && (!this.props.templates || this.props.templates.length === 0);

    let noAccessTemplateCount = this.props.templates ? this.props.allTemplatesCount - this.props.templates.length : 0;

    const headerClassName = isEmptyStateBannerShown ? '' : 'below-header';

    return (
      <div className="tab-content">
        <Columns className={headerClassName}>
          <Columns.Column className="pan">
            {!isEmptyStateBannerShown && (
              <Heading className="ptl mbs pll" size={4}>
                Welcome back to Matik{userFirstName !== null && ', ' + userFirstName} 👋
              </Heading>
            )}
            <HomeContent
              isLoading={this.props.isFetching}
              elements={this.props.templates}
              emptyInfo={emptyInfo}
              emptyState={() => <></>}
              firstName={userFirstName}
              entityType="template"
              templates={this.props.templates}
              fetchItems={this.fetchItems}
              pagination={this.props.pagination}
              match={this.props.match}
            />
            <div className="ptl mbs pll mtxxxl title is-4">Create a New Template</div>
            <div className="template-library-list">
              <SortableEntityGrid
                entitiesToRender={this.state.templateLibraryTemplates}
                entityType="template"
                isSearching={false}
                pagination={{ numberOfPages: 1 }}
                templateBaseUrl="/templates/"
                noAccessEntityCount={noAccessTemplateCount}
                showFavorites={false}
                isTemplateLibrary={true}
              />
            </div>
          </Columns.Column>
          <Columns.Column className="is-narrow insights-column is-paddingless">
            <HomeInsights />
          </Columns.Column>
        </Columns>
        <AccessModal
          show={this.props.ui.modal?.name === 'accessModal'}
          accesses={new AccessManager(this.props.ui.modal?.content?.id, this.props.accessesByItemId, this.context.user)}
          item={this.props.ui.modal?.content}
        />
      </div>
    );
  }

  fetchItems = (page, sort = ['updated_on', 'desc']) => {
    const offset = page * 6;
    this.props.onSearchParamsChange(offset, sort, 6);
  };
}

HomeTab.propTypes = {
  isFetching: PropTypes.bool,
  onSearchParamsChange: PropTypes.func,
  templates: PropTypes.array,
  accessesByItemId: PropTypes.object,
  pagination: PropTypes.object,
  match: PropTypes.object,
  userFirstName: PropTypes.string,
  allTemplatesCount: PropTypes.number,
  ui: PropTypes.object,
};

HomeTab.contextType = UserContext;

const HomeTabWrapper = (props) => {
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    limit: 6,
    sort: ['updated_on', 'desc'],
  });

  const { offset, limit, sort } = searchParams;
  const { elements, isPending, pagination } = useTemplates(offset, limit, sort);

  const { data: accessesByItemId } = useAccesses('template');

  const handleSearchParamsChange = (offset, sort, limit) => {
    setSearchParams({ offset, sort, limit });
  };
  return (
    <HomeTab
      {...props}
      isFetching={isPending}
      templates={elements}
      pagination={pagination}
      onSearchParamsChange={handleSearchParamsChange}
      accessesByItemId={accessesByItemId}
    />
  );
};

export default withLDConsumer()(connect(mapUiStateToProps)(WithNotifications(HomeTabWrapper)));
