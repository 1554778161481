import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapEnterpriseUsersStateToProps } from '../../redux/users/stateMappers';
import { mapUsersDispatchToProps } from '../../redux/users/dispatchers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import UploadedFileBanner from 'components/shared/fileUpload/UploadedFileBanner';
import csv_icon from '../../images/csv_icon.svg';
import FileUpload from 'components/shared/fileUpload/FileUpload';
import { MAlert } from 'components/shared/Alerts';
import withUserContext from 'components/shared/WithUserContext';
import Modal from 'components/shared/modal/Modal';

function BulkUserUploadModal({
  closeModal,
  updateEnterpriseCounts,
  fetchEnterpriseUsersIfNeeded,
  onBulkUserUpload,
  ui,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const modalTitle = 'Bulk Upload Users via CSV';

  const handleSelectFile = (files) => {
    setSelectedFile(files[0]);
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  const uploadFile = (e) => {
    if (!selectedFile) {
      return MAlert('Please select a CSV of users to upload.', 'No file selected', 'error');
    }
    e.preventDefault();
    setIsLoading(true);
    const oldUser = null;
    onBulkUserUpload(selectedFile, (response) => {
      if (response) {
        updateEnterpriseCounts(response, oldUser);
        closeModal();
        fetchEnterpriseUsersIfNeeded();
      }
      setIsLoading(false);
    });
  };

  const downloadCsvTemplate = () => {
    const csvData = [
      '"name","email","title","phone","roles","active"',
      '"Jane Doe","jdoe@example.com","Project Manager","555-1234","[""producer"",""consumer"",""producer_admin""]",true',
      '"John Smith","jsmith@example.com","Sales","555-2345","[""consumer""]",false',
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url; // eslint-disable-line
    link.setAttribute('download', 'csv_upload_template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      show={ui.modal?.name === 'bulkUserUploadModal'}
      title={modalTitle}
      onClose={closeModal}
      primaryButtonText="Upload Users"
      primaryButtonOnClick={uploadFile}
      primaryButtonLoading={isLoading}
      primaryButtonDisabled={!selectedFile}
      secondaryButtonText="Download CSV Template"
      secondaryButtonOnClick={downloadCsvTemplate}
      tertiaryButtonText="Cancel"
      tertiaryButtonOnClick={closeModal}
      showDefaultFooter={true}
    >
      <div className="has-text-centered">
        <p className="mbm">
          Upload users to your enterprise by downloading this&nbsp;
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadCsvTemplate();
            }}
          >
            CSV template
          </a>
          , filling in the information for the users you want to add, and then re-uploading the populated CSV.
        </p>
        <FileUpload buttonText="Select CSV from your computer" onSelectFile={handleSelectFile} inputAccept=".csv" />
        {selectedFile && (
          <UploadedFileBanner
            onCancel={clearSelectedFile}
            uploadedFileName={selectedFile.name}
            uploadedIconSrc={csv_icon}
          />
        )}
      </div>
    </Modal>
  );
}

BulkUserUploadModal.propTypes = {
  closeModal: PropTypes.func,
  updateEnterpriseCounts: PropTypes.func,
  fetchEnterpriseUsersIfNeeded: PropTypes.func,
  onBulkUserUpload: PropTypes.func,
  ui: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return Object.assign({}, mapEnterpriseUsersStateToProps(state, ownProps), mapUiStateToProps(state));
}

function mapDispatchToProps(state, ownProps) {
  return Object.assign({}, mapUsersDispatchToProps(state, ownProps), mapUiDispatchToProps(state, ownProps));
}

export default connect(mapStateToProps, mapDispatchToProps)(withUserContext(BulkUserUploadModal));
