import Constants from '../components/Constants';
import { isEmpty, orderBy } from 'lodash';

const teams = {
  isTeamsUser: (planId) => {
    return planId === Constants.MATIK_TIERS.matik_team.tier_id;
  },

  buildRequestDemoUrl: (user, feature, trialDaysRemaining) => {
    let splitName = user.name.split(' ');
    const firstName = splitName.shift();
    const lastName = splitName.join(' ');
    let trialStatus = trialDaysRemaining ? 'trial_in_progress' : 'trial_ended';
    // It is possible for a user to make multiple about_me records -- get the most recent company name
    let company = '';
    if (!isEmpty(user.about_me)) {
      const sortedAboutMe = orderBy(user.about_me, 'id');
      const latestAboutMe = sortedAboutMe[sortedAboutMe.length - 1];
      company = latestAboutMe.company;
    }
    if (!company) {
      company = user.enterprise.name;
    }
    return `https://www.matik.io/request-demo?First-Name=${encodeURIComponent(
      firstName,
    )}&Last-Name=${encodeURIComponent(lastName)}&Email=${encodeURIComponent(user.email)}&Phone=${encodeURIComponent(
      user.phone,
    )}&Company=${encodeURIComponent(
      company,
    )}&utm_source=matik_team&utm_medium=organic&utm_campaign=in_product_upsell&utm_term=${feature}&utm_content=${trialStatus}`;
  },
};

export default teams;
