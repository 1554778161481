import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import LookerQueryForm from './LookerQueryForm';
import { Select } from '../../../shared/FormSelect';
import utils from '../../../../lib/utils';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

class LookerModelForm extends Component {
  componentDidMount() {
    const modelExploreId = this.props.queryObj.selectedModelExplore
      ? utils.modelExploreId(this.props.queryObj.selectedModel.name, this.props.queryObj.selectedModelExplore.name)
      : null;
    if (
      this.props.queryObj.selectedModel &&
      (!this.props.apiInfo['models'] || !this.props.apiInfo['models'][this.props.queryObj.selectedModel.name])
    ) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'model_explore', modelExploreId);
    } else if (
      this.props.queryObj.selectedModelExplore &&
      (!this.props.apiInfo['model_explore'] || !this.props.apiInfo['model_explore'][modelExploreId])
    ) {
      this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'model_explore', modelExploreId);
    }
  }
  render() {
    let fields = [];
    if (
      this.props.queryObj.selectedModelExplore &&
      this.props.apiInfo?.['model_explore']?.[
        utils.modelExploreId(this.props.queryObj.selectedModel.name, this.props.queryObj.selectedModelExplore.name)
      ] &&
      this.props.apiInfo['model_explore'][
        utils.modelExploreId(this.props.queryObj.selectedModel.name, this.props.queryObj.selectedModelExplore.name)
      ] instanceof Array
    ) {
      fields =
        this.props.apiInfo['model_explore'][
          utils.modelExploreId(this.props.queryObj.selectedModel.name, this.props.queryObj.selectedModelExplore.name)
        ];
    }
    const models = this.props.apiInfo['models']
      ? Object.keys(this.props.apiInfo['models']).map((modelName) => {
          const model = this.props.apiInfo['models'][modelName];
          return { value: model.name, label: model.label ? model.label : model.name };
        })
      : [];
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Model</Form.Label>
          <Form.Help>Select Looker Model</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Model"
              placeholder="Select a Model"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={
                this.props.queryObj.selectedModel
                  ? {
                      value: this.props.queryObj.selectedModel.name,
                      label: this.props.queryObj.selectedModel.label
                        ? this.props.queryObj.selectedModel.label
                        : this.props.queryObj.selectedModel.name,
                    }
                  : null
              }
              name="selected-model"
              onChange={this.selectModel}
              options={models}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
        </Form.Field>
        {this.props.queryObj.selectedModel && this.props.apiInfo['models'] && this.renderModelExplores()}
        {this.props.queryObj.selectedModelExplore && (
          <LookerQueryForm
            queryObj={this.props.queryObj}
            apiInfo={this.props.apiInfo}
            fields={fields}
            inputsInQueryString={this.props.inputsInQueryString}
            entityType={this.props.entityType}
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
          />
        )}
      </React.Fragment>
    );
  }

  renderModelExplores() {
    const explores =
      this.props.apiInfo['models'] &&
      this.props.queryObj.selectedModel &&
      this.props.apiInfo['models'][this.props.queryObj.selectedModel.name]
        ? this.props.apiInfo['models'][this.props.queryObj.selectedModel.name].explores
        : [];
    const errorMessage =
      this.props.apiErrors &&
      this.props.queryObj.selectedModel &&
      this.props.apiErrors['model_explore'] &&
      this.props.apiErrors['model_explore'][
        utils.modelExploreId(this.props.queryObj.selectedModel.name, this.props.queryObj.selectedModelExplore?.name)
      ] &&
      'This view is inaccessible. Please check your configuration in Looker.';
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Model View</Form.Label>
          <Form.Help>Select Looker Model View</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Looker Model View"
              placeholder="Select a Model View"
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={
                this.props.queryObj.selectedModelExplore
                  ? {
                      value: this.props.queryObj.selectedModelExplore.name,
                      label: this.props.queryObj.selectedModelExplore.name,
                    }
                  : null
              }
              name="selectedModelExplore"
              onChange={this.selectModelExplore}
              options={explores.map((explore) => {
                return { value: explore.name, label: explore.label ? explore.label : explore.name };
              })}
              color={errorMessage ? 'danger' : null}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
          <Form.Help color="danger">{errorMessage}</Form.Help>
        </Form.Field>
      </React.Fragment>
    );
  }

  _createDefaultQuery = () => {
    // If you change this, you might also want to change it in LookerDashboardForm
    return { output_snapshot: true };
  };

  selectModel = (obj, action) => {
    if (action.action === 'select-option') {
      const modelName = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj.selectedModel = this.props.apiInfo['models'][modelName];
      updatedQueryObj.selectedModelExplore = undefined;
      updatedQueryObj.query = this._createDefaultQuery();
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  selectModelExplore = (obj, action) => {
    if (action.action === 'select-option') {
      const modelExploreName = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const selectedModelExploreArray = this.props.apiInfo['models'][this.props.queryObj.selectedModel.name][
        'explores'
      ].filter((explore) => explore.name === modelExploreName);
      const selectedModelExplore = selectedModelExploreArray.length > 0 ? selectedModelExploreArray[0] : null;
      if (selectedModelExplore) {
        updatedQueryObj.selectedModelExplore = selectedModelExplore;
        updatedQueryObj.query = {
          ...this._createDefaultQuery(),
          model: updatedQueryObj.selectedModel.name,
          view: selectedModelExplore.name,
        };
        this.props.fetchApiInfoIfNeeded(
          this.props.dataSourceId,
          'model_explore',
          utils.modelExploreId(this.props.queryObj.selectedModel.name, modelExploreName),
        );
        this.context.onQueryObjectUpdate(updatedQueryObj);
      }
    }
  };
}

LookerModelForm.contextType = DynamicContentContext;

LookerModelForm.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  formRef: PropTypes.func,
};

export default LookerModelForm;
