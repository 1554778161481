import { debounce } from 'lodash';

let batchers = {}; // global so we batch up every call across components

/** Debounce and batch all calls executed within 10ms of each other. Use like so:
 *
 * ```
 * const doItBatch = (batch) => {
 *    // batch = [1, 2]
 * };
 * const doIt = useBatcher('doIt', doItBatch);
 * doIt(1);
 * doIt(2);
 * ```
 *
 * @param {*} debouncedCall Note that this callback should only do stuff that's globally
 * relevant since there's no guarantee the component calling this hook will be the debounced
 * winner. Any one of `debouncedCall()` might be executed. So, don't do anything specifically
 * related to the current component in here.
 *
 * The arguments will be an array of all the debounced argument calls to be included
 * in the batch.
 *
 * @returns a function whose arguments will be added to the debounced batch
 */
const useBatcher = (key, debouncedCall) => {
  return (...args) => {
    if (!batchers[key]) {
      batchers[key] = {
        calls: [],
        eventuallyCall: debounce(
          () => {
            if (batchers[key]?.calls?.length > 0) {
              const calls = batchers[key].calls;
              delete batchers[key];
              debouncedCall(calls);
            }
          },
          10,
          { maxWait: 100, trailing: true, leading: false },
        ),
      };
    }
    const batcher = batchers[key];

    batcher.calls.push(args);
    batcher.eventuallyCall();
  };
};

export default useBatcher;
