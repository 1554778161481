import React from 'react';
import Constants from 'components/Constants';
import AsyncLoadImage from './AsyncLoadImage';
import PropTypes from 'prop-types';

function PopUpThumbnail(props) {
  const getFetchUrl = () =>
    props.template?.slides?.length > 0 && props.template?.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL
      ? '/templates/' + props.template.id + '/slides/' + props.template.slides[0].id + '/thumbnail/'
      : '';

  return (
    <div
      className="popup-thumbnail-wrap"
      onClick={props.templateOnClick ? () => props.templateOnClick(props.template.id) : null}
    >
      <AsyncLoadImage
        key={props.template.id}
        height="136px"
        template={props.template}
        fetchUrl={getFetchUrl()}
        imageClass={'popup-thumbnail'}
      />
    </div>
  );
}

PopUpThumbnail.propTypes = {
  template: PropTypes.object,
  templateOnClick: PropTypes.func,
};

export default PopUpThumbnail;
