import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { each, reduce } from 'lodash';

function ProgressBar(props) {
  const { entitiesMapping, length, progressBarOrder, statusVerbiage, runError } = props;
  const [entities, setEntities] = useState([]);
  const total = Math.max(
    reduce(
      entitiesMapping,
      (total, value, key) => {
        if (key !== 'total') {
          total += value;
        }
        return total;
      },
      0,
    ),
    length,
  );

  useEffect(() => {
    let mod = 0;
    const progressBarEntities = [];
    if (!entitiesMapping || Object.values(entitiesMapping).every((value) => value === 0)) {
      progressBarEntities.push(<div key="not-started" className="not-started"></div>);
    } else if (runError) {
      progressBarEntities.push(<div key="run-error" className="run-error"></div>);
    } else {
      let generatedCount = 0;
      each(progressBarOrder, (status, idx) => {
        const statusCount = entitiesMapping[status];
        generatedCount += statusCount;
        const statusLength = (statusCount / total) * 100;
        const width = statusCount && mod ? `${statusLength + 3}%` : `${statusLength}%`;
        const right = `${mod}%`;
        if (statusCount) {
          mod += 3;
        }
        const zIndex = progressBarOrder.length - idx;
        let className = status;
        progressBarEntities.push(
          <div
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={`${statusVerbiage[status]}: ${statusCount}`}
            key={status}
            className={className}
            style={{ right, width, zIndex }}
          ></div>,
        );
      });
      const remaining = total - generatedCount;

      if (remaining) {
        const statusLength = (remaining / total) * 100;
        const width = remaining && mod ? `${statusLength + 3}%` : `${statusLength}%`;
        const right = `${mod}%`;
        progressBarEntities.push(
          <div
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={`Remaining: ${remaining}`}
            key="remaining"
            className="not-started"
            style={{ right, width }}
          ></div>,
        );
      }
    }
    setEntities(progressBarEntities);
  }, [entitiesMapping]);

  const errorClass = runError ? 'run-error' : '';

  return <div className={`progress-bar ${errorClass}`}>{entities.length > 0 && entities}</div>;
}

ProgressBar.propTypes = {
  entitiesMapping: PropTypes.object,
  length: PropTypes.number,
  progressBarOrder: PropTypes.array,
  statusVerbiage: PropTypes.object,
  runError: PropTypes.bool,
};

export default ProgressBar;
