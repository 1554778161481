import React from 'react';
import PropTypes from 'prop-types';
import DataSourcesList from './DataSourcesList';

const DataSourcesSidebar = (props) => {
  return (
    <div className="sidebar">
      <DataSourcesList
        dataSources={props.dataSources}
        newEntityUrl="/data_sources/new"
        selectedDataSource={props.selectedDataSource}
        type="data_source"
        isPaginating={props.isPaginating}
        newEntityButton="Add Data Source"
        filterPlaceholder="Search Data Sources"
        pagination={props.pagination}
        fetchItems={props.fetchItems}
        searchState={props.searchState}
        searchAttributes={props.searchAttributes}
        isSearching={props.isSearching}
      />
    </div>
  );
};

DataSourcesSidebar.propTypes = {
  dataSources: PropTypes.array,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  onNewDataSourceAdd: PropTypes.func,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedDataSource: PropTypes.object,
};

export default DataSourcesSidebar;
