import React from 'react';
import ButtonWithDropdown from 'components/shared/ButtonWithDropdown';
import Icon from 'components/lib/Icon';
import PropTypes from 'prop-types';

function EmailSendDropdown({
  isSendingDisabled,
  submitTestEmail,
  onSendEmailClick,
  isSchedulingEnabled,
  tooltip,
  onScheduleOpen,
  hideScheduleOption,
}) {
  const className = hideScheduleOption ? '' : 'absolute right-36';
  const dropdownDirection = hideScheduleOption ? 'up' : 'down';
  return (
    <ButtonWithDropdown
      buttonText="Send Email"
      className="is-primary"
      icon={<Icon name="send" size={20} theme="regular" />}
      dropdownDirection={dropdownDirection}
      isDropdownOnly={true}
    >
      <div className={`dropdown-content ${className} w-72`}>
        <div data-tooltip-id="matik-tooltip" data-tooltip-content={tooltip} data-tooltip-place="left">
          <div
            className={`pam vertical-center dropdown-item ${isSendingDisabled ? 'is-disabled' : ''}`}
            onClick={submitTestEmail}
          >
            <Icon name="play_circle" size={20} theme="regular" />
            <div className="flex flex-col items-start ml-2">
              <span>Test Email</span>
              <span className="help">Preview & send to yourself</span>
            </div>
          </div>
        </div>
        <div data-tooltip-id="matik-tooltip" data-tooltip-content={tooltip} data-tooltip-place="left">
          <div
            onClick={onSendEmailClick}
            className={`pam vertical-center dropdown-item ${isSendingDisabled ? 'is-disabled' : ''}`}
          >
            <Icon name="send" size={20} theme="regular" />
            <div className="flex flex-col items-start ml-2">
              <span>Send Email</span>
              <span className="help">Preview & send to recipients</span>
            </div>
          </div>
        </div>
        {isSchedulingEnabled && !hideScheduleOption && (
          <div data-tooltip-id="matik-tooltip" data-tooltip-content={tooltip} data-tooltip-place="left">
            <div
              onClick={onScheduleOpen}
              className={`pam vertical-center dropdown-item ${isSendingDisabled ? 'is-disabled' : ''}`}
            >
              <Icon name="calendar" size={20} theme="regular" />
              <div className="flex flex-col items-start ml-2">
                <span>Schedule Email</span>
                <span className="help">Preview & schedule send to recipients</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </ButtonWithDropdown>
  );
}

EmailSendDropdown.propTypes = {
  isSendingDisabled: PropTypes.bool,
  submitTestEmail: PropTypes.func,
  onSendEmailClick: PropTypes.func,
  isSchedulingEnabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onScheduleOpen: PropTypes.func,
  hideScheduleOption: PropTypes.bool,
};

export default EmailSendDropdown;
