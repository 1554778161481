import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/shared/modal/Modal';
import PresentationInputsForm from '../../shared/presentations/PresentationInputsForm';
import utils from '../../../lib/utils';
import inputs from '../../../lib/inputs';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import Constants from '../../Constants';
import { useTemplateContent } from 'lib/hooks/useTemplate';

function TestPresentationModal(props) {
  const { data: templateContent, isPending: isContentFetching } = useTemplateContent(
    props.template?.id,
    props.template?.deleted,
  );

  const tagTree = utils.getDynamicContentTags(props.template, templateContent, props.allDynamicContent);
  const tags = tagTree.getTagNodes();
  const matchingInputs = inputs.getAllInputs(tags, props.allDynamicContent, props.template, templateContent);
  const matchingContent = tags.map((tag) => utils.getDynamicContentFromTag(tag.name));

  let modalTitle;
  if (props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
    modalTitle = 'Preview Dynamic Content';
  } else if (Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(props.template.source_type)) {
    modalTitle = 'Generate Document';
  } else {
    modalTitle = 'Generate Presentation';
  }

  return (
    <Modal onClose={props.onClose} show={props.show} title={modalTitle}>
      <PresentationInputsForm
        allDynamicContent={props.allDynamicContent}
        entityId={props.template.id}
        entityType="template"
        existingInputValues={props.inputValues}
        inModal={true}
        inputs={matchingInputs}
        isProducer={true}
        onClose={props.onClose}
        onPresentationCreate={props.onPresentationCreate}
        template={props.template}
        templateContent={matchingContent}
        allTemplateContent={templateContent}
        isContentFetching={isContentFetching}
        test={true}
        onEmailPreview={props.onEmailPreview}
        onEmailPreviewError={props.onEmailPreviewError}
        isTemplateTest={props.isTemplateTest}
        isEmailVisualBuilder={props.isEmailVisualBuilder}
        setVisualBuilderFetching={props.setVisualBuilderFetching}
        source_type={props.template.source_type}
      />
    </Modal>
  );
}

TestPresentationModal.propTypes = {
  allDynamicContent: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  template: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  inputValues: PropTypes.object,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  onEmailPreview: PropTypes.func,
  isTemplateTest: PropTypes.bool,
  onEmailPreviewError: PropTypes.func,
  isEmailVisualBuilder: PropTypes.bool,
  setVisualBuilderFetching: PropTypes.func,
};

function mapStateToProps(state) {
  return Object.assign({}, mapUiStateToProps(state));
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPresentationModal);
