import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tile, Heading } from 'react-bulma-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import teams from 'lib/teams';
import { UserContext } from 'components/UserContext';
import Constants from 'components/Constants';
import EnterpriseUpsellTag from 'components/shared/EnterpriseUpsellTag';

function ContentTypeForm({ onContentTypeSelect }) {
  const userContext = useContext(UserContext);
  const { openAiIntegration } = useFlags() || {};
  let contentTypes = Object.values(Constants.ContentType);
  // TODO (Tyler): Remove LaunchDarkly flag once enterprises that currently have access to AI via the LaunchDarkly
  // flag are granted access to AI via the soup_dumpling toggle.
  if (!userContext?.user?.enterprise?.enterprise_settings?.ai_enabled && !openAiIntegration) {
    contentTypes = contentTypes.filter((content_type) => content_type !== Constants.ContentType.INSIGHTS);
  }

  const renderFourOptions = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      {contentTypes.map((contentType) => {
        const contentTypeDisplay = Constants.CONTENT_TYPE_DISPLAY[contentType];
        return (
          <div
            key={contentType}
            role="button"
            aria-label={`Select ${contentTypeDisplay.text}`}
            onClick={() => onContentTypeSelect(contentType)}
            className="flex flex-col p-5 content-type"
          >
            <p>
              <img src={contentTypeDisplay.icon} alt="Content Type" className="pal h-17" />
            </p>
            <Heading size={5} className="mbs">
              {contentTypeDisplay.text}
              {teams.isTeamsUser(
                userContext.user && userContext.user.enterprise && userContext.user.enterprise.plan_id,
              ) &&
                contentType === Constants.ContentType.CONDITIONAL && (
                  <EnterpriseUpsellTag feature="conditional_content" />
                )}
            </Heading>
            <p>{contentTypeDisplay.description}</p>
            <p className="ptm">
              <button className="button is-secondary">Create</button>
            </p>
          </div>
        );
      })}
    </div>
  );

  const renderThreeOptions = () => (
    <Tile kind="ancestor" className="box is-flex is-paddingless is-shadowless mtxs">
      {contentTypes.map((contentType) => {
        const contentTypeDisplay = Constants.CONTENT_TYPE_DISPLAY[contentType];
        return (
          <Tile
            key={contentType}
            kind="parent"
            role="button"
            aria-label={`Select ${contentTypeDisplay.text}`}
            onClick={() => onContentTypeSelect(contentType)}
          >
            <Tile kind="child" className="pal is-flex content-type">
              <p>
                <img src={contentTypeDisplay.icon} height="32px" alt="Content Type" className="pal" />
              </p>
              <Heading size={5} className="mbs">
                {contentTypeDisplay.text}
                {teams.isTeamsUser(
                  userContext.user && userContext.user.enterprise && userContext.user.enterprise.plan_id,
                ) &&
                  contentType === Constants.ContentType.CONDITIONAL && (
                    <EnterpriseUpsellTag feature="conditional_content" />
                  )}
              </Heading>
              <p>{contentTypeDisplay.description}</p>

              <p className="ptm">
                <button className="button is-secondary">Create</button>
              </p>
            </Tile>
          </Tile>
        );
      })}
    </Tile>
  );
  return (
    <>
      {contentTypes.length === 3 && renderThreeOptions()}
      {contentTypes.length === 4 && renderFourOptions()}
    </>
  );
}

ContentTypeForm.propTypes = {
  onContentTypeSelect: PropTypes.func,
};

export default ContentTypeForm;
