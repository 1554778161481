import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import EnterpriseSettingsContent from './EnterpriseSettingsContent';
import utils from '../../lib/utils';
import WithNotifications from '../shared/WithNotifications';
import DomainAuthModal from './DomainAuthModal';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import AddEmailsModal from 'components/producer/email/AddEmailsModal';
import { UserContext } from '../UserContext';

class EnterpriseSettingsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enterprise: null,
      roles: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    API.get(
      '/enterprises/current/',
      (response) => {
        this.setState({ enterprise: response.data, isLoading: false });
      },
      API.defaultError,
    );
    API.get('/roles/', (response) => {
      this.setState({ roles: response.data });
    });
  }

  render() {
    return (
      <div className="tab-content">
        <EnterpriseSettingsContent
          isLoading={this.state.isLoading}
          elements={[this.state.enterprise]}
          emptyInfo={{}}
          roles={this.state.roles}
          enterprise={this.state.enterprise}
          onEnterpriseUpdate={this.onEnterpriseUpdate}
          match={this.props.match}
          updateEnterpriseCounts={this.updateEnterpriseCounts}
        />
        <DomainAuthModal
          show={this.props.ui.fullScreenModal?.name === 'domainAuthModal'}
          onClose={this.props.closeFullScreenModal}
          enterprise={this.state.enterprise}
          onEnterpriseUpdate={this.onEnterpriseUpdate}
        />
        <AddEmailsModal
          show={this.props.ui.modal?.name === 'addEmailsModal'}
          onClose={this.props.closeModal}
          enterprise={this.state.enterprise}
          onEnterpriseUpdate={this.onEnterpriseUpdate}
          updateUser={this.context.updateUser}
        />
      </div>
    );
  }

  refreshUserEnterprise = () => {
    API.get(
      '/users/me/',
      (response) => {
        const user = response.data;
        this.context.updateUser(user);
      },
      API.defaultError,
    );
  };

  onEnterpriseUpdate = (updatedEnterprise, notify = true) => {
    this.refreshUserEnterprise();
    if (notify) {
      utils.notify('Enterprise updated');
    }
    this.setState({ enterprise: updatedEnterprise });
  };

  updateEnterpriseCounts = (updatedUser, oldUser) => {
    const updatedEnterprise = Object.assign({}, this.state.enterprise);
    if (updatedUser == null) {
      // delete user
      if (oldUser.active) {
        updatedEnterprise.users_count -= 1;
        if (oldUser.roles.indexOf('producer') >= 0 || oldUser.roles.indexOf('producer_admin') >= 0) {
          updatedEnterprise.producer_users_count = updatedEnterprise.producer_users_count
            ? updatedEnterprise.producer_users_count - 1
            : 0;
        } else if (oldUser.roles.indexOf('consumer') >= 0) {
          updatedEnterprise.consumer_users_count = updatedEnterprise.consumer_users_count
            ? updatedEnterprise.consumer_users_count - 1
            : 0;
        }
      }
    } else if (oldUser == null) {
      // new user
      if (updatedUser.active) {
        updatedEnterprise.users_count += 1;
        if (updatedUser.roles.indexOf('producer') >= 0 || updatedUser.roles.indexOf('producer_admin') >= 0) {
          updatedEnterprise.producer_users_count = updatedEnterprise.producer_users_count
            ? updatedEnterprise.producer_users_count + 1
            : 0;
        } else if (updatedUser.roles.indexOf('consumer') >= 0) {
          updatedEnterprise.consumer_users_count = updatedEnterprise.consumer_users_count
            ? updatedEnterprise.consumer_users_count + 1
            : 0;
        }
      }
    } else if (updatedUser && oldUser) {
      let updatedProducerRole =
        updatedUser.roles.indexOf('producer') >= 0 || updatedUser.roles.indexOf('producer_admin') >= 0 ? 1 : 0;
      let updatedConsumerRole = updatedUser.roles.indexOf('consumer') >= 0 ? 1 : 0;
      let oldProducerRole =
        oldUser.roles.indexOf('producer') >= 0 || oldUser.roles.indexOf('producer_admin') >= 0 ? 1 : 0;
      let oldConsumerRole = oldUser.roles.indexOf('consumer') >= 0 ? 1 : 0;
      if (updatedUser.active && oldUser.active) {
        // check if the role changed, but user staus remained the same
        updatedEnterprise.producer_users_count =
          updatedEnterprise.producer_users_count + updatedProducerRole - oldProducerRole;
        updatedEnterprise.consumer_users_count =
          updatedEnterprise.consumer_users_count + updatedConsumerRole - oldConsumerRole;
      } else {
        // role was inactivated or activated
        if (updatedUser.active && !oldUser.active) {
          updatedEnterprise.users_count += 1;
          updatedEnterprise.producer_users_count += updatedProducerRole;
          updatedEnterprise.consumer_users_count += updatedConsumerRole;
        } else if (!updatedUser.active && oldUser.active) {
          updatedEnterprise.users_count -= 1;
          updatedEnterprise.producer_users_count -= updatedProducerRole;
          updatedEnterprise.consumer_users_count -= updatedConsumerRole;
        }
      }
    }

    this.setState({ enterprise: updatedEnterprise });
  };
}

EnterpriseSettingsTab.propTypes = {
  match: PropTypes.any,
  ui: PropTypes.object,
  closeFullScreenModal: PropTypes.func,
  closeModal: PropTypes.func,
};

EnterpriseSettingsTab.contextType = UserContext;

export default connect(mapUiStateToProps, mapDispatchToProps)(WithNotifications(EnterpriseSettingsTab));
