import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bulma-components';
import Pluralize from 'pluralize';
import Constants from '../../Constants';
import API from '../../../lib/api';
import { withRouter } from 'react-router-dom';
import teams from '../../../lib/teams';
import withUserContext from '../../shared/WithUserContext';
import Button from '../../lib/Button';
import Tag from '../../lib/Tag';

class TrialExpirationTag extends Component {
  render() {
    let tagColor = 'white';
    if (this.props.daysLeft >= 7) {
      tagColor = 'green';
    } else {
      tagColor = 'red';
    }

    return (
      <Navbar.Item className="is-hoverable">
        <Navbar.Link className="navbar-white-hover" arrowless={true}>
          <Tag
            color={tagColor}
            label={`${Pluralize('trial day', this.props.daysLeft, true)} left`}
            rightIconName="chevron_down"
          />
        </Navbar.Link>
        <Navbar.Dropdown>
          <Navbar.Item className="trial-days-dropdown">
            <Tag color={tagColor} label={`${this.props.daysLeft / Constants.FREE_TRIAL_DAYS}`} />
            <span className="trial-dropdown-header pts pbs">
              Your free trial ends in {Pluralize('day', this.props.daysLeft, true)}
            </span>
            <span className="trial-dropdown-content pbs">
              Upgrade your plan now to keep all the benefits that Matik is offering.
            </span>
            <Button category="primary" onClick={this.pickPlanClick}>
              Discover Matik Enterprise
            </Button>
          </Navbar.Item>
        </Navbar.Dropdown>
      </Navbar.Item>
    );
  }

  pickPlanClick = () => {
    API.track('discover_matik_enterprise_click', { from: 'trial_expiration_tag' });
    const demoUrl = teams.buildRequestDemoUrl(
      this.props.userContext.user,
      'general_contact',
      this.props.userContext.user.enterprise.trial_days_remaining,
    );
    window.open(demoUrl, '_blank');
  };
}

TrialExpirationTag.propTypes = {
  daysLeft: PropTypes.number,
  history: PropTypes.object,
  userContext: PropTypes.object,
};

export default withRouter(withUserContext(TrialExpirationTag));
