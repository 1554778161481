import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import AuthTabs from '../AuthTabs';

function AthenaAuth({ auth, centered, onAuthChange }) {
  const region = auth.region ?? '';
  const key_id = auth.key_id ?? '';
  const secret_key = auth.secret_key ?? '';
  const external_id = auth.external_id;
  const role_arn = auth.role_arn ?? '';
  const activeTabIndex = role_arn ? 1 : 0;
  const tabs = [
    { name: 'login-tab', title: 'Access via Secret Key' },
    { name: 'role-tab', title: 'Access via role' },
  ];
  const [activeAuthTab, setActiveAuthTab] = useState(tabs[activeTabIndex].name);
  const onTabChange = (tabName) => {
    if (tabName === 'role-tab' && !auth.external_id) {
      const arr = new Uint32Array(2);
      crypto.getRandomValues(arr);
      const new_external_id = arr.join('');
      onAuthChange({ target: { name: 'external_id', value: new_external_id } });
    }
    setActiveAuthTab(tabName);
  };
  return (
    <>
      <AuthTabs activeAuthTab={activeAuthTab} tabs={tabs} setActiveAuthTab={onTabChange} centered={centered} />
      <Box className="data-source-box" style={centered ? { margin: '0 auto' } : null}>
        {activeAuthTab === 'login-tab' ? (
          <>
            <Form.Field>
              <Form.Label htmlFor="region">AWS Region</Form.Label>
              <Form.Help>The AWS region your Athena installation is in</Form.Help>
              <Form.Control>
                <Form.Input
                  placeholder="AWS Region"
                  type="text"
                  name="region"
                  onChange={onAuthChange}
                  value={region}
                  id="region"
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="key-id">AWS Access Key Id</Form.Label>
              <Form.Help>Your AWS Access Key Id</Form.Help>
              <Form.Control>
                <Form.Input
                  placeholder="AWS Access Key Id"
                  type="text"
                  name="key_id"
                  onChange={onAuthChange}
                  value={key_id}
                  id="key-id"
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="secret-key">AWS Secret Key</Form.Label>
              <Form.Help>Your AWS Secret Key</Form.Help>
              <Form.Control>
                <Form.Input
                  placeholder="AWS Secret Key"
                  type="password"
                  name="secret_key"
                  onChange={onAuthChange}
                  value={secret_key}
                  id="secret-key"
                />
              </Form.Control>
            </Form.Field>
          </>
        ) : (
          <>
            <Form.Field>
              <Form.Label htmlFor="region">AWS Region</Form.Label>
              <Form.Help>The AWS region your Athena installation is in</Form.Help>
              <Form.Control>
                <Form.Input
                  placeholder="AWS Region"
                  type="text"
                  name="region"
                  onChange={onAuthChange}
                  value={region}
                  id="region"
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="region">Matik&apos;s Account ID</Form.Label>
              <Form.Help>Enter this for the account ID associated with the AWS role</Form.Help>
              <Form.Control>
                <p>970823545067</p>
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="region">External ID</Form.Label>
              <Form.Help>Enter this for the role Matik uses to access your account</Form.Help>
              <Form.Control>
                <p>{external_id}</p>
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="key-id">AWS Role ARN</Form.Label>
              <Form.Help>The ARN of the role Matik will be assuming</Form.Help>
              <Form.Control>
                <Form.Input
                  placeholder="AWS Role ARN"
                  type="text"
                  name="role_arn"
                  onChange={onAuthChange}
                  value={role_arn}
                  id="role-arn"
                />
              </Form.Control>
            </Form.Field>
          </>
        )}
      </Box>
    </>
  );
}

AthenaAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  onAuthChange: PropTypes.func,
};

export default AthenaAuth;
