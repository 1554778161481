import React, { useContext } from 'react';
import { Form } from 'react-bulma-components';
import QueryEditor from '../DynamicContentQueryEditor';
import PropTypes from 'prop-types';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

function QueryForm({ dataSource }) {
  const dynamicContentContext = useContext(DynamicContentContext);

  return (
    <React.Fragment>
      <Form.Field className="query-parent">
        <Form.Control className="flex-1">
          <QueryEditor mode="sql" inputs={dynamicContentContext.existingInputs} dataSource={dataSource} />
          {dynamicContentContext.queryStringError && (
            <Form.Help color="danger">{dynamicContentContext.queryStringError}</Form.Help>
          )}
        </Form.Control>
      </Form.Field>
    </React.Fragment>
  );
}

QueryForm.propTypes = {
  dataSource: PropTypes.object,
};

export default QueryForm;
