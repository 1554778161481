import React from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/lib/ListItem';
import UserAvatar from 'react-user-avatar';
import Constants from 'components/Constants';

function ListItemPerson({ size = 'm', person, metadata, onClick }) {
  if (!person) {
    return null;
  }

  const icon = (
    <UserAvatar
      colors={Constants.AVATAR_COLORS}
      src={person.photo_url}
      name={person.name ?? person.email}
      size={size === 'm' ? 28 : 24}
      className="text-matik-white"
    />
  );

  return (
    <ListItem
      size={size}
      icon={icon}
      title={person.name}
      subtitle={person.email}
      metadata={metadata}
      onClick={onClick}
    />
  );
}
ListItemPerson.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    photo_url: PropTypes.string,
  }).isRequired,
  size: ListItem.propTypes.size,
  metadata: PropTypes.node,
  onClick: PropTypes.func,
};

export default ListItemPerson;
