export function mapAnalyticsUsersStateToProps(state) {
  const { fetchedAnalyticsUsers } = state;

  const { isFetching, users, pagination } = fetchedAnalyticsUsers;

  return {
    isFetching,
    pagination,
    users,
  };
}

export function mapEnterpriseUsersStateToProps(state) {
  const { fetchedEnterpriseUsers } = state;

  const { isFetching, isWorking, users, pagination, selectedUser } = fetchedEnterpriseUsers;

  return {
    isFetching,
    isWorking,
    pagination,
    selectedUser,
    users,
  };
}
