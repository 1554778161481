import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useEditor } from '@craftjs/core';
import Icon from 'components/lib/Icon';
import loading_animation from 'images/loading.gif';
import { v4 as uuidv4 } from 'uuid';
import { HtmlBuilderContext } from '../../../producer/email/EmailBodyHtml';

function Indicator({
  borderWidth,
  drag,
  isActive,
  nodeId,
  tabText,
  onPreviewDc,
  isPreviewed,
  placeholder,
  isDisconnected,
}) {
  const { isFetchingDc } = useContext(HtmlBuilderContext);
  const { currentNodeName, parentNodeId, indexToAdd, actions, query } = useEditor((state, query) => {
    let currentNodeName = {};
    let parentNodeId = {};
    let indexToAdd = 0;

    currentNodeName = state.nodes[nodeId]?.data?.name;
    parentNodeId = query.node(nodeId).ancestors()[0];
    indexToAdd = query.node(parentNodeId).descendants().indexOf(nodeId) + 1;

    return {
      currentNodeName,
      parentNodeId,
      indexToAdd,
    };
  });

  const estimatedCharWidth = 8;
  const paddingWidth = 16;
  const computedTabText = `${tabText}${
    isDisconnected ? ' Disconnected' : isPreviewed ? ' Preview' : placeholder ? ' Placeholder' : ''
  }`;

  const indicatorTabStyle = {
    position: 'absolute',
    top: `${-30 - parseInt(borderWidth)}px`,
    left: `${0 - parseInt(borderWidth)}px`,
    padding: '8px',
    width: computedTabText.length * estimatedCharWidth + paddingWidth,
    height: '30px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    color: '#FFF',
    backgroundColor: `${isDisconnected ? '#F69D00' : isPreviewed ? '#2ACCAF' : '#3279EF'}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    userSelect: 'none',
  };

  const buttonContainerStyle = {
    position: 'absolute',
    top: `${0 - parseInt(borderWidth)}px`,
    right: `${-40 - parseInt(borderWidth)}px`,
    paddingLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    zIndex: 50,
  };

  const indicatorBorderStyle = {
    position: 'absolute',
    top: `${-parseInt(borderWidth)}px`,
    right: `${-parseInt(borderWidth)}px`,
    bottom: `${-parseInt(borderWidth)}px`,
    left: `${-parseInt(borderWidth)}px`,
    border: `1px solid ${isDisconnected ? '#F69D00' : isPreviewed ? '#20DFAC' : '#3279EF'}`,
    borderTopLeftRadius: 0,
    zIndex: 9,
    pointerEvents: 'none',
  };

  const getCloneNodeTree = (idToClone) => {
    const tree = query.node(idToClone).toNodeTree();
    const newNodes = {};

    const changeNodeId = (node, newParentId) => {
      const newGeneratedNodeId = String(uuidv4());
      const childNodes = node.data.nodes.map((childId) => changeNodeId(tree.nodes[childId], newGeneratedNodeId));
      const linkedNodes = Object.keys(node.data.linkedNodes).reduce((accum, id) => {
        const newRecursiveNodeId = changeNodeId(tree.nodes[node.data.linkedNodes[id]], newGeneratedNodeId);
        return {
          ...accum,
          [id]: newRecursiveNodeId,
        };
      }, {});

      let tempNode = {
        ...node,
        id: newGeneratedNodeId,
        data: {
          ...node.data,
          parent: newParentId || node.data.parent,
          nodes: childNodes,
          linkedNodes,
        },
      };
      let freshnode = query.parseFreshNode(tempNode).toNode();
      newNodes[newGeneratedNodeId] = freshnode;
      return newGeneratedNodeId;
    };

    const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId]);
    return {
      rootNodeId,
      nodes: newNodes,
    };
  };

  const handleDuplicateNode = (e, id, parentId, index) => {
    e.preventDefault();
    const tree = getCloneNodeTree(id);
    actions.addNodeTree(tree, parentId, index);
  };

  const deleteNode = () => {
    actions.delete(nodeId);
  };

  return (
    <>
      {isActive && (
        <div style={buttonContainerStyle}>
          <div
            className="indicator-button move-button"
            ref={drag}
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Move"
          >
            <Icon name="arrow_move" size={16} theme="regular" />
          </div>
          <div
            className="indicator-button duplicate-button"
            onClick={(e) => handleDuplicateNode(e, nodeId, parentNodeId, indexToAdd)}
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Duplicate"
          >
            <Icon name="copy" size={16} theme="regular" />
          </div>
          <div
            className="indicator-button delete-button"
            onClick={() => deleteNode()}
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Delete"
          >
            <Icon name="trash_can" size={16} theme="regular" />
          </div>
          {onPreviewDc != null &&
            (isFetchingDc ? (
              <div
                className="indicator-button loading"
                onClick={() => onPreviewDc()}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Fetching Dynamic Content..."
              >
                <img className="w-4 h-4 m-0 p-0 border-0 bg-transparent" src={loading_animation}></img>
              </div>
            ) : (
              <div
                className={`indicator-button ${isDisconnected ? 'disabled' : 'preview-button'}`}
                onClick={() => !isDisconnected && onPreviewDc()}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Preview Dynamic Content"
              >
                <Icon name="play" size={16} theme="regular" />
              </div>
            ))}
        </div>
      )}
      {(currentNodeName !== 'DraggableText' || !isActive) && (
        <span className="html-builder-indicator-tab" style={indicatorTabStyle}>
          {computedTabText}
        </span>
      )}
      <span className="html-builder-indicator-border" style={indicatorBorderStyle}></span>
    </>
  );
}

Indicator.propTypes = {
  borderWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  tabText: PropTypes.string,
  isActive: PropTypes.bool,
  nodeId: PropTypes.string,
  drag: PropTypes.func,
  onPreviewDc: PropTypes.func,
  isLoading: PropTypes.bool,
  isPreviewed: PropTypes.bool,
  placeholder: PropTypes.bool,
  isDisconnected: PropTypes.bool,
};

export default Indicator;
