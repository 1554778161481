import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import moment from 'moment';
import { Form } from 'react-bulma-components';
import localeDateFormatterUtils from '../../../lib/localeDate';
import Icon from '../../lib/Icon';

class AnalyticsDateRangePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      dateError: '',
    };
  }

  render() {
    return (
      <div>
        <DateRangePicker
          displayFormat={localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
            localeDateFormatterUtils.getUserLocale(),
          )}
          id="analytics-date-range-picker"
          startDatePlaceholderText="From"
          endDatePlaceholderText="To"
          customArrowIcon={<ChevronRight />}
          startDate={this.props.startDate}
          startDateId="startDate"
          endDate={this.props.endDate}
          endDateId="endDate"
          onDatesChange={this.onDatesChange}
          navPrev={
            <div role="button" style={{ left: '22px', position: 'absolute', top: '18px' }}>
              <Icon name="chevron_left" size={16} theme="regular" />
            </div>
          }
          navNext={
            <div style={{ right: '22px', position: 'absolute', top: '18px' }}>
              <ChevronRight />
            </div>
          }
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          horizontalMargin={0}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={this.isAfterToday}
          initialVisibleMonth={this.initialVisibleMonth}
          anchorDirection="right"
        />
        {this.state.dateError && (
          <Form.Help className="help is-danger analytics" color="danger">
            {this.state.dateError}
          </Form.Help>
        )}
      </div>
    );
  }

  onDatesChange = (dateObj) => {
    this.props.updateQueryParams({ startDate: dateObj['startDate'], endDate: dateObj['endDate'] });
    if (!dateObj['startDate'] || !dateObj['endDate']) {
      this.setState({ dateError: 'Please enter date in MM/DD/YYYY format' });
    } else {
      this.setState({ dateError: '' });
    }
  };

  isAfterToday = (date) => {
    return date > moment();
  };

  initialVisibleMonth = () => {
    return moment().subtract(1, 'months');
  };
}

AnalyticsDateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  updateQueryParams: PropTypes.func,
};

export default AnalyticsDateRangePicker;
