import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../lib/Icon';

function IconWithDropdown({ children, tooltip }) {
  return (
    <div
      className="flex border border-grey-300 rounded justify-center items-center p-2 text-matik-black"
      data-tooltip-id="matik-tooltip"
      data-tooltip-content={tooltip}
    >
      {children}
      <div className="ml-1">
        <Icon name="chevron_down" size={12} theme="regular" />
      </div>
    </div>
  );
}

IconWithDropdown.propTypes = {
  children: PropTypes.object,
  tooltip: PropTypes.string,
};

export default IconWithDropdown;
