import React, { useState, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { find, difference } from 'lodash';
import utils from '../../../lib/utils';
import { ContentContext } from './ContentContext';
import { useDebouncedEffect } from 'lib/hooks/useDebouncedEffect';
import SlideElementOverlay from './SlideElementOverlay';

const SlideOverlay = (props) => {
  const [matchingEls, setMatchingEls] = useState([]);
  const ref = useRef(null);
  const [slideOverlays, setSlideOverlays] = useState([]);
  const context = useContext(ContentContext);

  const triggerMouseEffects = (newMatchingEls) => {
    const mouseLeaveEls = difference(matchingEls, newMatchingEls);
    triggerMouseLeave(mouseLeaveEls);
    triggerMouseOver(newMatchingEls);

    setMatchingEls(newMatchingEls);
  };

  // props.children.props is from react-pdf library <Page> component, which frequently asynchrously updates
  useDebouncedEffect(
    () => {
      renderSlideOverlays();
    },
    [props.children.props],
    100,
  );

  const getRatio = (pageSize) => {
    const { width: widthEMU, height: heightEMU } = pageSize;
    if (props.pdfWidth) {
      const pageWidth = widthEMU / 9525;
      return props.pdfWidth / pageWidth;
    }

    if (props.pdfHeight) {
      const pageHeight = heightEMU / 9525;
      return props.pdfHeight / pageHeight;
    }

    return 0;
  };

  const renderSlideOverlays = () => {
    setSlideOverlays([]);
    const tempSlideOverlays = [];
    const locations = props.selectedSlide.locations || [];

    let foundTextLocation = false;

    locations.forEach((location) => {
      const contentByTag = {};
      const { top: topEMU, left: leftEMU, bottom: bottomEMU, right: rightEMU } = location.dimensions;
      const ratio = getRatio(location.page_size);
      const top = (topEMU / 9525) * ratio;
      const bottom = (bottomEMU / 9525) * ratio;
      const left = (leftEMU / 9525) * ratio;
      const right = (rightEMU / 9525) * ratio;
      const width = right - left;
      const height = bottom - top;
      if (location.tags && location.tags.length > 0) {
        location.tags.forEach((tag) => {
          const [tagName, content] = utils.splitTag(tag, location.id);
          contentByTag[tagName] = {
            contentName: content,
            matchingContent: props.dynamicContentTags.find(
              (dct) => dct.matchingContent && dct.matchesContentWithoutTagNum(content),
            ),
          };
        });
      }
      const textElements = document.querySelectorAll('.react-pdf__Page__textContent span[role=presentation]');
      if (contentByTag && Object.keys(contentByTag).length > 0) {
        const tagNames = Object.keys(contentByTag);
        for (let tagName of tagNames) {
          const tagNameInBrackets = `{{${tagName}}}`;
          const element = find(textElements, (el) => el.innerText.indexOf(tagNameInBrackets) >= 0);
          // for generating rectangle over text tags
          if (element) {
            if (ref && props.textItems) {
              const [textWidth /* textHeight */] = utils.textWidth(
                tagNameInBrackets,
                window.getComputedStyle(element, null).getPropertyValue('font-size'),
                element.style.fontFamily,
                element.style.transform,
              );
              const range = document.createRange();
              const start = element.innerText.substring(0, element.innerText.indexOf(tagNameInBrackets)).length;
              range.setStart(element.firstChild, start);
              range.setEnd(element.firstChild, start + tagNameInBrackets.length - 1);
              const rangeRect = range.getBoundingClientRect();
              const overlayRect = ref.current.getBoundingClientRect();
              const textTop = rangeRect.top - overlayRect.top;
              const offset = rangeRect.left - overlayRect.left;
              const textHeight = rangeRect.height;
              tempSlideOverlays.push({
                key: `${location.id}_${tagName}`,
                id: location.id,
                contentName: contentByTag[tagName].contentName,
                matchingContent: contentByTag[tagName].matchingContent,
                left: offset,
                top: textTop,
                width: textWidth,
                height: textHeight,
                selection: tagName,
              });
            }
          } else {
            // for generating rectangle over non-text tags
            tempSlideOverlays.push({
              key: `${location.id}_${tagName}`,
              id: location.id,
              contentName: contentByTag[tagName].contentName,
              matchingContent: contentByTag[tagName].matchingContent,
              top,
              left,
              width,
              height,
            });
          }
        }
      }
      // for creating text selection to be added as tag
      if (props.addTextOverlay && ref && location.is_text) {
        const overlayRect = ref.current.getBoundingClientRect();
        const textTop = props.addTextOverlay.top - overlayRect.top;
        const textLeft = props.addTextOverlay.left - overlayRect.left;
        if (textTop >= top && textTop <= bottom && textLeft >= left) {
          foundTextLocation = true;
          tempSlideOverlays.push({
            id: location.id,
            left: textLeft,
            top: textTop,
            selection: props.addTextOverlay.selection,
            isText: location.is_text,
          });
        }
      } else if (!(contentByTag && Object.keys(contentByTag).length > 0) && location.is_text === false) {
        // all other overlays
        tempSlideOverlays.push({
          top,
          left,
          width,
          height,
          id: location.id,
          isText: location.is_text,
        });
      }
    });

    // Handle the case where text overflows the page element boundaries. In this case, the text will not be in
    // the vertical bounds of the page element BUT must be within the horizontal bounds and be contained in the
    // text_content of the overlay
    if (!foundTextLocation && props.addTextOverlay) {
      locations.forEach((location) => {
        if (props.addTextOverlay && ref && location.is_text) {
          const { left: leftEMU, right: rightEMU } = location.dimensions;
          const ratio = getRatio(location.page_size);
          const left = (leftEMU / 9525) * ratio;
          const right = (rightEMU / 9525) * ratio;
          const overlayRect = ref.current.getBoundingClientRect();
          const textTop = props.addTextOverlay.top - overlayRect.top;
          const textLeft = props.addTextOverlay.left - overlayRect.left;
          if (
            textLeft >= left &&
            textLeft <= right &&
            location.text_content &&
            location.text_content.indexOf(props.addTextOverlay.selection)
          ) {
            foundTextLocation = true;
            tempSlideOverlays.push({
              id: location.id,
              left: textLeft,
              top: textTop,
              selection: props.addTextOverlay.selection,
              isText: location.is_text,
            });
          }
        }
      });
    }

    setSlideOverlays(tempSlideOverlays);
  };

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      ref.current = node;
    }
  }, []);

  const triggerMouseOver = (els) => {
    let smallest = Number.MAX_VALUE;
    els.forEach((el) => {
      if (el.classList.contains('page-element-wrapper')) {
        const { width, height } = el.getBoundingClientRect();
        const area = width * height;
        if (el.dataset.content && area < smallest) {
          smallest = area;
          context.highlightContentName(el.dataset.content);
        }
      }
    });
  };

  const triggerMouseLeave = (els) => {
    els.forEach((el) => {
      el.classList.remove('hover');
      if (el.classList.contains('page-element-wrapper')) {
        context.highlightContentName(null);
      }
    });
  };

  const onOverlayMouseMove = (e) => {
    if (document.elementsFromPoint) {
      const underEls = document.elementsFromPoint(e.clientX, e.clientY);
      const matchingElsUnderMouse = underEls.filter((el) => {
        const containsPageEl = el.classList.contains('page-element-wrapper');
        const containsTextEl = el.parentElement
          ? el.parentElement.classList.contains('react-pdf__Page__textContent')
          : false;
        return containsPageEl || containsTextEl;
      });
      triggerMouseEffects(matchingElsUnderMouse);
    }
  };

  const onOverlayMouseLeave = () => {
    triggerMouseEffects([]);
  };

  const overlayClick = (e) => {
    e.preventDefault();
    const underEls = document.elementsFromPoint(e.clientX, e.clientY);
    const add = find(
      underEls,
      (el) => el.classList.contains('page-element-overlay-icon') && el.classList.contains('add'),
    );
    if (add) {
      if (add.dataset.selection) {
        return addTextTag(add.dataset.element_id, add.dataset.selection);
      }
      return addTag(add.dataset.element_id);
    }

    let edit = null;
    let smallest = Number.MAX_VALUE;
    underEls.forEach((el) => {
      if (el.classList.contains('page-element-wrapper')) {
        const { width, height } = el.getBoundingClientRect();
        const area = width * height;
        if (el.dataset.content && area < smallest) {
          smallest = area;
          edit = el;
        }
      }
    });
    if (edit) {
      editTag(edit.dataset.element_id, edit.dataset.content);
    }
  };

  const addTag = (id) => {
    context.selectSlideTag(null, id, null);
  };

  const addTextTag = (id, selection) => {
    context.selectSlideTag(null, id, selection);
  };

  const editTag = (id, existingTag) => {
    context.selectSlideTag(existingTag, id, null);
  };

  return (
    <>
      <div
        className="overlay-wrapper"
        onMouseMove={onOverlayMouseMove}
        onMouseLeave={onOverlayMouseLeave}
        onClick={overlayClick}
        ref={onRefChange}
      >
        {slideOverlays.map((overlay, idx) => (
          <SlideElementOverlay key={idx} overlay={overlay} idx={idx} matchingEls={matchingEls} />
        ))}
        {props.children}
      </div>
    </>
  );
};

SlideOverlay.propTypes = {
  addTextOverlay: PropTypes.object,
  children: PropTypes.any,
  dynamicContentTags: PropTypes.array,
  pdfHeight: PropTypes.number,
  pdfWidth: PropTypes.number,
  selectedSlide: PropTypes.object,
  textItems: PropTypes.object,
};

export default SlideOverlay;
