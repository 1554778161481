import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';

import Modal from 'components/shared/modal/Modal';
import SlideConditionsForm from './SlideConditionsForm';
import API from 'lib/api';
import utils from 'lib/utils';
import ConditionIcon from './ConditionIcon';
import { MConfirm } from 'components/shared/Alerts';

const SlideConditionsModal = ({ slides, currentTemplate, status, onConditionsUpdated, onClose, show }) => {
  const condition = slides[0].conditions?.[0];
  const propConditions = condition?.conditions;

  const [conditionsInProgress, setConditionsInProgress] = useState(propConditions || {});

  const [selectedSlides, setSelectedSlides] = useState(slides);

  useEffect(() => {
    if (propConditions) {
      setConditionsInProgress(propConditions);
    }
  }, [JSON.stringify(propConditions)]);

  const onError = (err) => {
    API.defaultError(err);
  };

  const onFormSubmit = () => {
    const data = {
      conditions: conditionsInProgress,
      template_id: currentTemplate.id,
      target_ids: selectedSlides.map((slide) => slide.id),
      target_type: 'slide',
    };
    if (condition) {
      API.put(
        `/conditions/${condition.id}/`,
        data,
        () => {
          utils.notify('Condition successfully updated');
          onConditionsUpdated();
          handleClose();
        },
        onError,
      );
    } else {
      API.post(
        '/conditions/',
        data,
        () => {
          utils.notify('Condition successfully added');
          onConditionsUpdated();
          handleClose();
        },
        onError,
      );
    }
  };

  const handleConditionsDelete = () => {
    if (condition) {
      MConfirm(
        'Delete Conditions',
        'Are you sure? Deleting conditions will remove them from all associated slides.',
        'warning',
        (confirmed) => {
          if (confirmed) {
            API.delete(`/conditions/${condition.id}/`, () => {
              utils.notify('Condition successfully removed');
              onConditionsUpdated();
              handleClose();
            });
          }
        },
      );
    }
  };

  const handleClose = () => {
    setConditionsInProgress({});
    onClose();
  };

  let primaryButtonDisabled;
  let tertiaryButtonDisabled;
  if (Object.keys(conditionsInProgress).length > 0) {
    primaryButtonDisabled = false;
    tertiaryButtonDisabled = false;
  } else {
    primaryButtonDisabled = true;
    tertiaryButtonDisabled = true;
  }

  return (
    <Modal
      show={show}
      onClose={handleClose}
      title={<SlideConditionsTitle currentTemplate={currentTemplate} condition={condition} slides={selectedSlides} />}
      width={715}
      showDefaultFooter={true}
      primaryButtonText="Submit"
      primaryButtonOnClick={onFormSubmit}
      primaryButtonDisabled={primaryButtonDisabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={handleClose}
      tertiaryButtonText="Delete Conditions"
      tertiaryButtonOnClick={condition ? handleConditionsDelete : null} // don't show "delete" if nothing has been created
      tertiaryButtonDisabled={tertiaryButtonDisabled}
    >
      <SlideConditionsForm
        currentTemplate={currentTemplate}
        slides={selectedSlides}
        status={status}
        onSlidesSelection={setSelectedSlides}
        onConditionsUpdate={setConditionsInProgress}
        onConditionsDelete={handleConditionsDelete}
        slideConditions={conditionsInProgress}
      />
    </Modal>
  );
};

SlideConditionsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  slides: PropTypes.array,
  currentTemplate: PropTypes.object,
  status: PropTypes.object,
  /** Called after conditions are added, updated, or removed and the change persisted on the backend */
  onConditionsUpdated: PropTypes.func.isRequired,
};

export default SlideConditionsModal;

const SlideConditionsTitle = ({ currentTemplate, condition, slides }) => {
  return (
    <div className="is-flex gap-medium is-align-items-center">
      <ConditionIcon currentTemplate={currentTemplate} condition={condition} />
      <div>
        <div>Add Slide Conditions</div>
        <div className="subtitle">
          Condition Set for {slides.length} {Pluralize('Slide', slides.length)}
        </div>
      </div>
    </div>
  );
};
SlideConditionsTitle.propTypes = {
  currentTemplate: PropTypes.object,
  condition: PropTypes.object,
  slides: PropTypes.array,
};
