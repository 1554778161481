import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconSelect } from '../FormSelect';
import { components } from 'react-select';
import { ReactComponent as ChevronIcon } from 'svg/chevron_down.svg';
import { isEmpty } from 'lodash';

function HtmlBuilderChartTypeDropdown({ chartTypeOptions, onChartTypeChange, selectedChartType }) {
  useEffect(() => {
    if (isEmpty(selectedChartType)) {
      onChartTypeChange(chartTypeOptions[0]);
    }
  }, []);

  const getChartIcon = (chartType) => {
    if (chartType) {
      return <>{chartType.icon}</>;
    }
  };

  const IconOption = (optionProps) => {
    return (
      <components.Option {...optionProps}>
        <div className="html-builder-dropdown-start-group">
          <div className="html-builder-dropdown-icon-group">{getChartIcon(optionProps.data)}</div>
          <p className="dc-font">{optionProps.data.label}</p>
        </div>
      </components.Option>
    );
  };

  const IconControl = ({ ...controlProps }) => {
    return (
      <components.Control {...controlProps}>
        <div
          className="html-builder-dropdown-select"
          onClick={() =>
            controlProps.selectProps.menuIsOpen
              ? controlProps.selectProps.onMenuClose()
              : controlProps.selectProps.onMenuOpen()
          }
        >
          {selectedChartType ? (
            <div className="html-builder-dropdown-start-group">
              <div className="html-builder-dropdown-icon-group">{getChartIcon(selectedChartType)}</div>
              <p className="dc-font">{selectedChartType.label}</p>
            </div>
          ) : (
            <p className="dc-font placeholder">Select Chart Type</p>
          )}
          <div className="html-builder-dropdown-end-group">
            <ChevronIcon />
          </div>
        </div>
      </components.Control>
    );
  };

  const onSelect = (chartType) => {
    onChartTypeChange(chartType);
  };

  return (
    <IconSelect
      classNamePrefix="matik-select"
      componentsToAdd={{ Option: IconOption, Control: IconControl }}
      onChange={onSelect}
      options={chartTypeOptions}
      placeholder="Select Chart Type"
      value={selectedChartType}
      isSearchable={false}
    />
  );
}

HtmlBuilderChartTypeDropdown.propTypes = {
  onChartTypeChange: PropTypes.func,
  selectedChartType: PropTypes.object,
  chartTypeOptions: PropTypes.object,
};

export default HtmlBuilderChartTypeDropdown;
