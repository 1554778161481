import React, { useContext, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import { UserContext } from 'components/UserContext';
import microsoft365_drive_logo from 'images/microsoft365_drive_logo.png';
import { MInsufficientPermissions, MConnect } from 'components/shared/Alerts';
import { combine, getTokens } from 'components/shared/MicrosoftAuthInterface';
import Modal from 'components/shared/modal/Modal';
import Button from 'components/lib/Button';

const MicrosoftPicker = ({
  className,
  onPicked,
  pickerMode = 'files',
  filters = [],
  buttonRef = null,
  isLoading = false,
}) => {
  const NEED_AUTH = 'User needs to authenticate first';
  const [shouldShowPicker, setShowPicker] = useState(false);
  const [isPickerLoading, setPickerLoading] = useState(false);
  const [showPicker, setShow] = useState(false);
  const [srcDoc, setSrcDoc] = useState(<></>);
  const { user, updateUser } = useContext(UserContext);

  // Ensure pre-requisites are met and open the picker when requested.
  useEffect(() => {
    if (shouldShowPicker) {
      shouldBuildPicker();
      setShowPicker(false);
    }
  }, [shouldShowPicker]);

  // the options we pass to the picker page through the querystring
  const params = {
    // Currently considering downgrading to 7.2 so we can use the graph api access token we already possess and get rid of the msal-browser library
    sdk: '8.0',
    entry: {
      oneDrive: {
        files: {},
      },
    },
    authentication: {},
    messaging: {
      origin: window.location.origin,
      channelId: '27',
    },
    typesAndSources: {
      mode: pickerMode,
      filters,
      pivots: {
        oneDrive: true,
        recent: true,
        sharedLibraries: true,
      },
    },
    selection: {
      mode: 'single',
    },
  };
  let win = null;
  let port = null;

  const getBaseURL = () => {
    const microsoftIntegration = utils.microsoftIntegration(user);
    if (!microsoftIntegration) {
      return NEED_AUTH;
    }
    return microsoftIntegration?.base_url;
  };

  const shouldBuildPicker = () => {
    if (needsToConnect()) {
      return connectIntegration();
    }
    setPickerLoading(true);
    let baseUrl = getBaseURL();
    if (baseUrl !== NEED_AUTH) {
      buildPicker(baseUrl);
      return;
    }
    showMSPermsAlert();
  };

  const needsToConnect = () => {
    const microsoftIntegration = utils.microsoftIntegration(user);
    return !microsoftIntegration || !microsoftIntegration.has_necessary_scopes;
  };

  const connectIntegration = () => {
    const title = 'Connect to Microsoft 365',
      message = 'You must grant Matik Microsoft permissions to be able to connect to Microsoft 365.',
      type = 'microsoft',
      confirm = 'Connect to Microsoft 365';
    const callback = (confirm) => {
      confirm && utils.connectMicrosoft(user, updateUser, () => setShowPicker(true), showMSPermsAlert);
    };
    MConnect(title, message, type, callback, confirm);
  };

  const reconnectIntegration = () => {
    utils.reconnectMicrosoft(user, updateUser, () => setShowPicker(true), showMSPermsAlert);
  };

  const showMSPermsAlert = () => {
    const title = 'Grant Microsoft permissions to Matik';
    const message = 'To add files from Office 365, you must grant Matik the permissions requested on the next screen.';
    MInsufficientPermissions(title, message, 'microsoft', reconnectIntegration);
  };

  const buildPicker = async (baseUrl) => {
    const accessToken = await getTokens({
      resource: baseUrl,
      command: 'authenticate',
      type: 'SharePoint',
    });

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(params),
    });

    const url = baseUrl
      ? combine(baseUrl, `_layouts/15/FilePicker.aspx?${queryString}`)
      : `${Constants.MICROSOFT.personal_base_url}/picker?${queryString}`;
    const form = (
      <form action={url} method="POST" id="ms-file-picker-form">
        <input type="hidden" name="access_token" value={accessToken} />
      </form>
    );

    setSrcDoc(renderToString(form));

    setPickerLoading(false);
    setShow(true);

    win = window.document.getElementById('matik-ms-picker').contentWindow;
    win.onload = () => win.document.forms['ms-file-picker-form'].submit();

    window.addEventListener('message', (event) => {
      if (event.source && event.source === win) {
        const message = event.data;
        if (message.type === 'initialize' && message.channelId === params.messaging.channelId) {
          port = event.ports[0];
          port.addEventListener('message', messageListener);
          port.start();
          port.postMessage({
            type: 'activate',
          });
        }
      }
    });
  };

  const messageListener = async (message) => {
    switch (message.data.type) {
      case 'command':
        port.postMessage({
          type: 'acknowledge',
          id: message.data.id,
        });
        messageCommandListener(message.data.data, message.data.id);
        break;
    }
  };

  const messageCommandListener = async (command, id) => {
    switch (command.command) {
      case 'authenticate': {
        const accessToken = await getTokens(command);
        if (typeof accessToken !== 'undefined' && accessToken !== null) {
          port.postMessage({
            type: 'result',
            id: id,
            data: {
              result: 'token',
              token: accessToken,
            },
          });
        } else {
          // eslint-disable-next-line no-console
          console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
        }
        break;
      }
      case 'close':
        setShow(false);
        break;
      case 'pick':
        onPicked(command);
        port.postMessage({
          type: 'result',
          id: id,
          data: {
            result: 'success',
          },
        });
        setShow(false);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn(`Unsupported command: ${JSON.stringify(command)}`, 2);
        port.postMessage({
          result: 'error',
          error: {
            code: 'unsupportedCommand',
            message: command.command,
          },
          isExpected: true,
        });
        break;
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setShowPicker(true);
  };

  const buttonStatus = isPickerLoading || isLoading ? 'loading' : 'default';

  return (
    <span className={`min-w-fit ${className}`}>
      <Button category="secondary" size="small" status={buttonStatus} onClick={handleButtonClick} buttonRef={buttonRef}>
        <img src={microsoft365_drive_logo} className="import-icon mr-2"></img>
        Select from Microsoft 365
      </Button>
      <Modal
        show={showPicker}
        title="Select Microsoft 365 File"
        additionalModalClass="modal-iframe modal-ms-picker !z-[1001]"
        onClose={() => setShow(false)}
        height={686}
      >
        <iframe
          sandbox="allow-same-origin allow-scripts allow-forms"
          width="100%"
          height="680px"
          id="matik-ms-picker"
          srcDoc={srcDoc}
        />
      </Modal>
    </span>
  );
};

MicrosoftPicker.propTypes = {
  className: PropTypes.string,
  onPicked: PropTypes.func.isRequired,
  pickerMode: PropTypes.string,
  filters: PropTypes.array,
  buttonRef: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MicrosoftPicker;
