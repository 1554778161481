import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AvatarSelect } from '../../shared/FormSelect';
import API from '../../../lib/api';

export function EmailRecipientSelector(props) {
  const [options, setOptions] = useState([]);
  const [existingRecipients, setExistingRecipients] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    API.get('/enterprises/users/', (users) => {
      setUserList(users.data);
      let existingRecipientsList = [];
      let optionsList = [];
      for (const user of users.data) {
        if (props.ccRecipients.indexOf(user.email) > -1) {
          existingRecipientsList.push({
            value: user.email,
            label: user.name ? user.name : user.email,
            photo_url: user.photo_url,
          });
        } else {
          optionsList.push({
            value: user.email,
            label: user.name ? user.name : user.email,
            photo_url: user.photo_url,
          });
        }
      }
      setExistingRecipients(existingRecipientsList);
      setOptions(optionsList);
    });
  }, []);

  useEffect(() => {
    let existingRecipientsList = [];
    let optionsList = [];
    for (const user of userList) {
      if (props.ccRecipients.indexOf(user.email) > -1) {
        existingRecipientsList.push({
          value: user.email,
          label: user.name ? user.name : user.email,
          photo_url: user.photo_url,
        });
      } else {
        optionsList.push({
          value: user.email,
          label: user.name ? user.name : user.email,
          photo_url: user.photo_url,
        });
      }
    }
    setExistingRecipients(existingRecipientsList);
    setOptions(optionsList);
  }, [props.ccRecipients]);

  return (
    <React.Fragment>
      <AvatarSelect
        options={options}
        onChange={props.onCcRecipientChange}
        isMulti={true}
        placeholder="Add email"
        classNamePrefix="recipient-select"
        value={existingRecipients}
        styles={{ backgroundColor: 'white' }}
      />
    </React.Fragment>
  );
}

EmailRecipientSelector.propTypes = {
  ccRecipients: PropTypes.array,
  onCcRecipientChange: PropTypes.func,
};
