import { API, AssistantAPI } from './api';

function longRequestFactory(serviceAPI) {
  return class LongRequest {
    constructor(base_url, reportCachedValue = false) {
      this.base_url = base_url;
      this.statusInterval = null;
      this.requestTimeout = null;
      this.isWaitingOnResponse = false;
      this.timingInfo = {};
      this.reportCachedValue = reportCachedValue;
    }

    getStatus(entity_id, onSuccess, onError) {
      this.statusInterval = setInterval(() => this._getStatus(entity_id, onSuccess, onError), 1500);
    }

    put(entity_id, data, onSuccess, onError, file, onProgress) {
      this._request('put', `${entity_id}`, data, onSuccess, onError, file, onProgress);
    }

    post(data, onSuccess, onError, file, onProgress, apiEndpoint = null) {
      this._request('post', '', data, onSuccess, onError, file, onProgress, apiEndpoint);
    }

    get(entity_id, data, onSuccess, onError, file, onProgress, apiEndpoint = null) {
      this._request('get', entity_id, data, onSuccess, onError, file, onProgress, apiEndpoint);
    }

    _request(type, entity_id, data, onSuccess, onError, file, onProgress, apiEndpoint = null) {
      const startTimeMs = Date.now();
      this.requestTimeout = setTimeout(() => this._onError({ message: 'The request timed out.' }, onError), 660000);

      const endpointUrl = apiEndpoint === null ? `${this.base_url}` : `${this.base_url}/${apiEndpoint}`;
      let entityUrl = entity_id !== '' ? `${endpointUrl}/${entity_id}/` : `${endpointUrl}/`;
      serviceAPI[type](
        entityUrl,
        data,
        (response) => {
          const status_entity_id = entity_id || response.data?.task_id || `${response.data.new_entity.id}`;
          this.timingInfo[status_entity_id] = {
            startTimeMs: startTimeMs,
            method: type,
            endpointUrl: endpointUrl,
            baseUrl: this.base_url,
            apiEndpoint: apiEndpoint,
            entityId: entity_id,
            statusEntityId: status_entity_id,
          };
          this.statusInterval = setInterval(() => this._getStatus(status_entity_id, onSuccess, onError), 1500);
          if (this.reportCachedValue && !!response.data.cached_result) {
            onSuccess(response, () => this._onComplete(status_entity_id));
          }
        },
        (err) => this._onError(err, onError),
        file,
        onProgress,
      );
    }

    _getStatusURL(entity_id) {
      return `${this.base_url}/${entity_id}/get_status/`;
    }

    _getStatus(entity_id, onSuccess, onError) {
      if (!this.isWaitingOnResponse) {
        this.isWaitingOnResponse = true;
        serviceAPI.get(
          this._getStatusURL(entity_id),
          (response) => {
            this.isWaitingOnResponse = false;
            return onSuccess(response, () => this._onComplete(entity_id));
          },
          (err) => this._onError(err, onError, entity_id),
        );
      }
    }

    _onComplete = (entity_id) => {
      clearInterval(this.statusInterval);
      clearTimeout(this.requestTimeout);
      this._trackTiming(entity_id, 'onComplete');
    };

    _onError = (err, onError, entity_id = null) => {
      if (this.statusInterval) {
        clearInterval(this.statusInterval);
      }
      if (this.requestTimeout) {
        clearTimeout(this.requestTimeout);
      }
      if (onError) {
        onError(err);
      }
      if (entity_id) {
        this._trackTiming(entity_id, 'onError');
      }
    };

    _trackTiming = (entity_id, origin) => {
      if (this.timingInfo[entity_id]) {
        const endTimeMs = Date.now();
        const durationMs = endTimeMs - this.timingInfo[entity_id].startTimeMs;
        API.track('longRequestTiming', { ...this.timingInfo[entity_id], endTimeMs, durationMs, origin });
        delete this.timingInfo[entity_id];
      }
    };
  };
}

export const LongRequest = longRequestFactory(API);
export class AssistantLongRequest extends longRequestFactory(AssistantAPI) {
  _getStatusURL(entity_id) {
    return `/task/get_status/${entity_id}/`;
  }
}

export default LongRequest;
