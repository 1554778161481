import React, { Component } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';

const WithUserlessLDProvider = (WrappedComponent) => {
  return class Inner extends Component {
    render() {
      return (
        <LDProvider
          clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID} // eslint-disable-line no-undef
          deferInitialization={false}
        >
          <WrappedComponent {...this.props} />
        </LDProvider>
      );
    }
  };
};

export default WithUserlessLDProvider;
