import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import AnalyticsContent from './AnalyticsContent';
import analytics_empty from '../../../images/analytics_empty.png';

class AnalyticsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      analyticsData: [],
    };
  }

  componentDidMount() {
    API.get(
      '/analytics/enterprise/',
      (response) => {
        this.setState({ analyticsData: response.data, isLoading: false });
      },
      API.defaultError,
    );

    API.track('analytics_tab_navigation');
  }

  render() {
    const elementsArray = this.state.analyticsData.presentation_count > 0 ? [1] : [];

    const emptyInfo = {
      emptyImageUrl: analytics_empty,
      emptyTitle: 'Your Team Analytics',
      emptyPrompt:
        'Once users start generating presentations, Matik will provide you with usage data to help you identify which templates are being used the most and which users are generating the most presentations',
    };
    return (
      <div className="tab-content">
        <AnalyticsContent
          isLoading={this.state.isLoading}
          analyticsData={this.state.analyticsData}
          elements={elementsArray}
          emptyInfo={emptyInfo}
          match={this.props.match}
        />
      </div>
    );
  }
}

AnalyticsTab.propTypes = {
  match: PropTypes.any,
};

export default AnalyticsTab;
