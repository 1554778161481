import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ContentContext } from './ContentContext';
import IconPill from '../../lib/IconPill';

function SlideElementOverlay({ idx, matchingEls, overlay }) {
  const context = useContext(ContentContext);
  const { id, top, left, width, height, contentName, matchingContent, selection } = overlay;

  if (selection && !contentName) {
    return (
      <div
        className="page-element-add-text-wrapper"
        key={`${id}_${idx}_add_text`}
        style={{
          position: 'absolute',
          zIndex: 10,
          top: top,
          left: left,
        }}
      >
        <div
          id={`${id}_logo`}
          data-element_id={id}
          data-selection={selection}
          className="page-element-overlay-icon add"
        >
          <IconPill iconName="plus" size="s" color="green" />
        </div>
      </div>
    );
  } else {
    let className = 'page-element-wrapper';
    if (contentName) {
      className += ' with-tags';
    }
    const fullContent = selection ? `${selection}:${contentName}` : contentName;
    const mouseIsOverElement = matchingEls
      ? matchingEls.find((matchingEl) => matchingEl.id === id.toString() && matchingEl.dataset.content === fullContent)
      : false;
    if (
      (context.highlightedContentName && context.highlightedContentName === fullContent) ||
      mouseIsOverElement ||
      context.selectedSlideTag?.elementId === id.toString()
    ) {
      className += ' highlighted';
    }
    const tooltip = contentName ? contentName : null;
    const contentLogo =
      contentName && matchingContent ? (
        <IconPill iconName="dynamic_content" size="s" color="green" />
      ) : (
        <IconPill iconName="error_circle" color="red" theme="circle" size="s" />
      );
    return (
      <div
        key={`${id}_${idx}_${contentName}`}
        id={id}
        className={className}
        data-tooltip-id="matik-tooltip"
        data-tooltip-content={tooltip}
        data-content={fullContent}
        data-element_id={id}
        style={{
          position: 'absolute',
          zIndex: overlay.selection ? 7 : 5,
          top: top,
          left: left,
          width: width,
          height: height,
        }}
      >
        {contentName ? (
          <div id={`${id}_logo`} data-element_id={id} className="page-element-overlay-icon">
            {contentLogo}
          </div>
        ) : (
          <div id={`${id}_logo`} data-element_id={id} className="page-element-overlay-icon add">
            <IconPill iconName="plus" size="s" color="green" />
          </div>
        )}
        <div
          className="page-element-overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: width,
            height: height,
          }}
        ></div>
      </div>
    );
  }
}

SlideElementOverlay.propTypes = {
  idx: PropTypes.number,
  matchingEls: PropTypes.arrayOf(PropTypes.node),
  overlay: PropTypes.shape({
    id: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    contentName: PropTypes.string,
    matchingContent: PropTypes.object,
    selection: PropTypes.string,
  }).isRequired,
};

export default SlideElementOverlay;
