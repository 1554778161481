import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import teams from '../../lib/teams';
import WithFetchUser from '../shared/WithFetchUser';

class ContactSalesLink extends Component {
  render() {
    return (
      <a href="#contact-sales" className={this.props.className} onClick={this.onClick}>
        Contact sales
      </a>
    );
  }

  onClick = () => {
    API.track('contact_sales_click', { from: this.props.from });
    const demoUrl = teams.buildRequestDemoUrl(
      this.props.user,
      'general_contact',
      this.props.user.enterprise.trial_days_remaining,
    );
    window.open(demoUrl, '_blank');
  };
}

ContactSalesLink.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string,
  user: PropTypes.object,
};

export default WithFetchUser(ContactSalesLink);
