import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Plus } from '../../svg/plus.svg';
import trash_icon from '../../images/trash.svg';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';

function AddRemoveButtons(props) {
  return (
    <React.Fragment>
      {props.children}
      <ButtonGroup width="hug">
        <Button
          type="button"
          onClick={(e) => props.removeEntity(e, props.idx)}
          category="secondary"
          size="small"
          aria-label="Remove Entity"
          disabled={props.isReadOnly}
        >
          <span className="icon">
            <img src={trash_icon} alt="Remove Entity" />
          </span>
        </Button>
        {props.includeAdd && (
          <Button
            type="button"
            onClick={props.addEntity}
            category="secondary"
            size="small"
            aria-label="Add Entity"
            disabled={props.isReadOnly}
          >
            <span className="icon">
              <Plus />
            </span>
          </Button>
        )}
      </ButtonGroup>
    </React.Fragment>
  );
}

AddRemoveButtons.propTypes = {
  children: PropTypes.any,
  includeAdd: PropTypes.bool,
  idx: PropTypes.number,
  isReadOnly: PropTypes.bool,
  removeEntity: PropTypes.func,
  addEntity: PropTypes.func,
};

export default AddRemoveButtons;
