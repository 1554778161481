import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Pluralize from 'pluralize';
import { each, find, map } from 'lodash';
import { Level } from 'react-bulma-components';

import { ReactComponent as EllipsesIcon } from 'images/icons/more-16x16-regular.svg';
import scheduler from 'lib/scheduler';
import API from 'lib/api';
import { closeSidepane } from 'redux/ui/action';
import Icon from 'components/lib/Icon';
import Sidepane from 'components/shared/sidepane/Sidepane';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { flowTypes, runStatus, runStatusHeaders } from './Constants';
import FlowsSidepaneRun from './FlowsSidepaneRun';
import FlowActionDropdown from './FlowActionDropdown';
import TemplateIcon from 'components/shared/TemplateIcon';
import Button from '../../lib/Button';
import FormSkeletonLoader from '../../producer/dynamicContent/FormSkeletonLoader';

function FlowsSidepane({ flow, onUpdateClick, onFlowDelete }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ui = useSelector((state) => state.ui);

  useEffect(() => {
    if (flow && !ui.sidepane && !ui.modal) {
      history.replace(`${history.location.pathname}`);
    }
  }, [ui.sidepane]);

  const initialRunsByType = () => {
    return Object.values(runStatus).reduce((obj, status) => ((obj[status] = []), obj), {});
  };

  const [isFetchingContent, setIsFetchingContent] = useState(false);
  const [runsByType, setRunsByType] = useState(initialRunsByType());
  const [recurrences, setRecurrences] = useState([]);

  const fetchRuns = () => {
    if (flow.flow_type === flowTypes.SCHEDULED) {
      API.get(
        `/scheduled_tasks/${flow.id}/bulk_presentations/`,
        (response) => {
          updateRunsByType(response.data);
        },
        API.defaultError,
      ).then(() => {
        setIsFetchingContent(false);
      });
    } else {
      // get_all_flows does not return presentation or sendgrid data manual flows (performance reasons), so we'll get it here on an as needed basis
      API.get(
        `/bulk_presentations/${flow.id}/`,
        (response) => {
          updateRunsByType(response.data);
        },
        API.defaultError,
      );
      setIsFetchingContent(false);
    }
  };

  const updateRunsByType = (runs) => {
    const updatedRunsByType = initialRunsByType();
    const nextRecurrence = find(recurrences, (r) => r.isAfter(moment()));
    if (nextRecurrence) {
      updatedRunsByType[runStatus.NOT_STARTED].push({
        name: `${flow.name} ${flow.runs.length + 1}`,
        completed_on: nextRecurrence,
        status: runStatus.NOT_STARTED,
        template: flow.template,
      });
    }
    each(runs, (run) => {
      updatedRunsByType[run.status].push(run);
    });

    setRunsByType(updatedRunsByType);
  };

  useEffect(() => {
    if (flow) {
      if (flow.flow_type === flowTypes.SCHEDULED) {
        setIsFetchingContent(true);
        API.get(
          `/scheduled_tasks/${flow.id}/recurrence/`,
          (response) => {
            if (response.data.length) {
              const formattedRecurrences = map(response.data, (runDate) => moment.utc(runDate).local());
              setRecurrences(formattedRecurrences);
            }
          },
          API.defaultError,
        );
      } else {
        setRecurrences([moment.utc(flow.completed_on ?? flow.created_on).local()]);
      }
    }
  }, [flow?.id]);

  useEffect(() => {
    if (flow && recurrences.length) {
      fetchRuns();
    }
  }, [recurrences]);

  const renderRuns = () => {
    const runsToRender = [];
    runStatusHeaders.forEach((header, key) => {
      const status = runStatus[key];
      if (runsByType[status].length > 0) {
        runsToRender.push(
          <div key={status}>
            <div className="run-status helper-text">{header}</div>
            {map(runsByType[status], (run, idx) => (
              <FlowsSidepaneRun key={idx} flow={flow} run={run} fetchRuns={fetchRuns} />
            ))}
          </div>,
        );
      }
    });
    return runsToRender;
  };

  const onClose = (e) => {
    e.preventDefault();
    dispatch(closeSidepane());
    history.replace(`${history.location.pathname}`);
  };

  let scheduleSentence = 'Runs one time';
  if (flow && flow.flow_type === flowTypes.SCHEDULED) {
    scheduleSentence = `${scheduler.getSentenceFromSchedule(flow, true)}`;
    if (moment().isBefore(flow.effective_end)) {
      scheduleSentence += ` Ends ${flow.end_type} `;
    } else {
      scheduleSentence += ` Ended ${flow.end_type} `;
    }
    if (flow.end_type === 'on') {
      scheduleSentence += moment.utc(flow.effective_end).local().format('lll');
    } else {
      scheduleSentence += Pluralize('occurrence', flow.end_after, true);
    }
  }

  let templateType;
  switch (flow?.template?.source_type) {
    case 'email':
      templateType = 'email';
      break;
    case 'google_docs':
      templateType = 'document';
      break;
    default:
      templateType = 'presentation';
      break;
  }

  const dropdownTrigger = (
    <Button category="secondary">
      <EllipsesIcon className="my-1" />
    </Button>
  );

  let sidepaneBody = null;

  if (isFetchingContent) {
    sidepaneBody = (
      <div className="sidepane-loading">
        <FormSkeletonLoader />
      </div>
    );
  } else {
    sidepaneBody = flow ? (
      <>
        <h1>
          <Level>
            <Level.Side align="left" className="mrm">
              <Level.Item className="mrm">
                <div className="icon-wrapper">
                  <TemplateIcon templateType={templateType} />
                </div>
              </Level.Item>
              <Level.Item className="flow-name text-overflow-ellipsis">
                <TextOverflowWithTooltip className="text-overflow-ellipsis is-fullwidth" text={flow.name} />
                <div className="flow-recurrence helper-text mtxs">
                  <div className="flex mr-2">
                    {flow.flow_type === flowTypes.SCHEDULED ? (
                      <Icon name="arrow_repeat_all" size={16} theme="regular" />
                    ) : (
                      <Icon name="arrow_repeat_all_off" size={16} theme="regular" />
                    )}
                  </div>
                  {scheduleSentence}
                </div>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <FlowActionDropdown
                  flow={flow}
                  onFlowDelete={onFlowDelete}
                  dropdownTrigger={dropdownTrigger}
                  isHoverable={true}
                  onUpdateClick={onUpdateClick}
                />
              </Level.Item>
              <Level.Item>
                <Button category="tertiary" onClick={onClose}>
                  <Icon name="dismiss" size={20} theme="regular" />
                </Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </h1>
        <section>{renderRuns()}</section>
      </>
    ) : null;
  }

  return (
    <Sidepane sidepaneType="flows" name="flows">
      {sidepaneBody}
    </Sidepane>
  );
}

FlowsSidepane.propTypes = {
  flow: PropTypes.object,
  onFlowDelete: PropTypes.func,
  onUpdateClick: PropTypes.func,
  setCurrentFlow: PropTypes.func,
};

export default FlowsSidepane;
