import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Level, Media } from 'react-bulma-components';
import UserAvatar from 'react-user-avatar';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../svg/plus.svg';
import { ReactComponent as EditIcon } from '../../svg/edit_filled.svg';
import default_profile_icon from '../../images/default_profile.svg';

class UserProfileWrapper extends Component {
  render() {
    return (
      <div className="wrapper with-navbar">
        <Container>
          {this.renderTopInfo()}
          {this.renderTabs()}
          {this.props.children}
        </Container>
      </div>
    );
  }

  renderTopInfo = () => {
    const imgSrc = this.props.user && this.props.user.photo_url ? this.props.user.photo_url : default_profile_icon;
    return (
      <Level>
        <Level.Side className="pll">
          <Level.Item className="has-text-centered ptm">
            <Media>
              {this.props.user && (
                <UserAvatar colors={['#db504a', '#0caca7']} size={200} name={this.props.user.email} src={imgSrc} />
              )}
              <Button className="is-secondary user-avatar-action" onClick={this.props.updateAvatar}>
                {this.props.user && this.props.user.photo_url ? (
                  <EditIcon alt="Update image" title="Update image" />
                ) : (
                  <PlusIcon alt="Add image" title="Add image" />
                )}
              </Button>
            </Media>
          </Level.Item>
        </Level.Side>
        <Level.Side align="right" className="prl">
          <div>{this.renderStats()}</div>
        </Level.Side>
      </Level>
    );
  };

  renderStats() {
    if (this.props.userStats) {
      if (this.props.consumerProfile) {
        return (
          <div className="is-flex">
            <Level.Item>
              <Link to="/create/presentations">
                <p className="title">{this.props.userStats.presentation_count}</p>
                <p className="has-text-grey-dark">Presentations</p>
              </Link>
            </Level.Item>
            <Level.Item>
              <Link to="/create/templates">
                <p className="title">{this.props.userStats.template_count}</p>
                <p className="has-text-grey-dark">Templates</p>
              </Link>
            </Level.Item>
          </div>
        );
      } else {
        return (
          <div className="is-flex">
            <Level.Item>
              <Link to="/data_sources">
                <p className="title">{this.props.userStats.data_source_count}</p>
                <p className="has-text-grey-dark">Data Sources</p>
              </Link>
            </Level.Item>
            <Level.Item>
              <Link to="/dynamic_content">
                <p className="title">{this.props.userStats.dynamic_content_count}</p>
                <p className="has-text-grey-dark">Dynamic Content</p>
              </Link>
            </Level.Item>
            <Level.Item>
              <Link to="/presentations">
                <p className="title">{this.props.userStats.presentation_count}</p>
                <p className="has-text-grey-dark">Presentations</p>
              </Link>
            </Level.Item>
            <Level.Item>
              <Link to="/templates">
                <p className="title">{this.props.userStats.template_count}</p>
                <p className="has-text-grey-dark">Templates</p>
              </Link>
            </Level.Item>
          </div>
        );
      }
    }
  }

  renderTabs() {
    const profileUrl = this.props.consumerProfile ? '/create/user_profile' : '/user_profile';
    const connectionsUrl = this.props.consumerProfile
      ? '/create/user_profile/connections'
      : '/user_profile/connections';
    const securityUrl = this.props.consumerProfile ? '/create/user_profile/security' : '/user_profile/security';
    const notificationsUrl = this.props.consumerProfile
      ? '/create/user_profile/notifications'
      : '/user_profile/notifications';
    return (
      <div className="tabs is-medium pll">
        <ul>
          <li className={this.props.currentUrl === profileUrl ? 'is-active' : ''}>
            <Link to={profileUrl}>Personal Information</Link>
          </li>
          <li className={this.props.currentUrl === connectionsUrl ? 'is-active' : ''}>
            <Link to={connectionsUrl}>Connections</Link>
          </li>
          <li className={this.props.currentUrl === securityUrl ? 'is-active' : ''}>
            <Link to={securityUrl}>Security</Link>
          </li>
          <li className={this.props.currentUrl === notificationsUrl ? 'is-active' : ''}>
            <Link to={notificationsUrl}>Notifications</Link>
          </li>
        </ul>
      </div>
    );
  }
}

UserProfileWrapper.propTypes = {
  children: PropTypes.any,
  currentUrl: PropTypes.string,
  consumerProfile: PropTypes.bool,
  user: PropTypes.object,
  userStats: PropTypes.object,
  updateUser: PropTypes.func,
  updateAvatar: PropTypes.func,
};

export default UserProfileWrapper;
