import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { find } from 'lodash';
import Constants from 'components/Constants';
import Modal from 'components/shared/modal/Modal';
import InputWithError from 'components/shared/InputWithError';
import { Select } from 'components/shared/FormSelect';
import AddRemoveButtons from 'components/shared/AddRemoveButtons';
import { MAlert, MConfirm } from 'components/shared/Alerts';
import API from 'lib/api';
import { UserContext } from 'components/UserContext';
import { mapUiStateToProps } from 'redux/ui/stateMappers';

class ClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.selectedClient?.client_name ?? '',
      nameError: '',
      redirectUris: this.props.selectedClient?.redirect_uris ?? [''],
      redirectUrisError: '',
      scope: this.props.selectedClient?.scopes ?? [],
      scopeError: '',
      userId: this.props.selectedClient?.user_id ?? '',
      userError: '',
    };
  }

  render() {
    const modalTitle = this.props.selectedClient ? 'Update Client' : 'Add Client';
    const scopeValue = this.state.scope.map((s) => ({ label: Constants.ROLE_DISPLAY_MAPPING[s], value: s }));
    const scopeOptions = [
      { label: Constants.ROLE_DISPLAY_MAPPING.producer_admin, value: Constants.PRODUCER_ADMIN_ROLE },
      { label: Constants.ROLE_DISPLAY_MAPPING.producer, value: Constants.PRODUCER_ROLE },
      { label: Constants.ROLE_DISPLAY_MAPPING.consumer, value: Constants.CONSUMER_ROLE },
    ];
    const currentUser = find(this.props.users, (u) => u.id === this.state.userId) ?? this.context.user;
    const primaryButtonText = this.props.selectedClient ? 'Update Client' : 'Add Client';

    return (
      <Modal
        onClose={this.props.onClose}
        show={this.props.ui.modal?.name === 'clientModal'}
        title={modalTitle}
        showDefaultFooter={true}
        primaryButtonText={primaryButtonText}
        primaryButtonOnClick={this.onFormSubmit}
        secondaryButtonText="Delete Client"
        secondaryButtonOnClick={this.deleteClient}
        tertiaryButtonText="Cancel"
        tertiaryButtonOnClick={this.props.onClose}
        footerIsSticky={true}
      >
        <Form.Field>
          <Form.Label>Name</Form.Label>
          <Form.Control>
            <InputWithError
              error={this.state.nameError}
              type="text"
              value={this.state.name}
              onChange={this.onNameChange}
              name="name"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Scope</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              onChange={this.onScopeChange}
              value={scopeValue}
              options={scopeOptions}
              isMulti={true}
              name="scope"
            />
            {this.state.scopeError && <Form.Help color="danger">{this.state.scopeError}</Form.Help>}
          </Form.Control>
        </Form.Field>
        {this.props.selectedClient && (
          <Form.Field>
            <Form.Label>Client ID</Form.Label>
            <Form.Control>
              <Form.Input value={this.props.selectedClient.client_id} readOnly />
            </Form.Control>
          </Form.Field>
        )}
        {this.props.selectedClient && (
          <Form.Field>
            <Form.Label>Client Secret</Form.Label>
            <Form.Control>
              <span>Permanently hidden. Create a new client if you&apos;ve forgotten the secret.</span>
            </Form.Control>
          </Form.Field>
        )}
        <Form.Field>
          <Form.Label>User</Form.Label>
          <Form.Control>
            <span>{currentUser.name ? currentUser.name : currentUser.email}</span>
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Redirect URIs</Form.Label>
          {this.renderRedirectUris()}
        </Form.Field>
      </Modal>
    );
  }

  renderRedirectUris() {
    return this.state.redirectUris.map((redirectUri, idx) => {
      return (
        <Form.Field kind="group" key={idx}>
          <AddRemoveButtons
            idx={idx}
            addEntity={this.addRedirectUri}
            removeEntity={this.removeRedirectUri}
            includeAdd={idx === this.state.redirectUris.length - 1}
          >
            <Form.Control style={{ flex: '10 1' }}>
              <Form.Input
                type="text"
                value={redirectUri}
                name="redirect_uri"
                onChange={(e) => this.onRedirectUriChange(e, idx)}
              />
            </Form.Control>
          </AddRemoveButtons>
        </Form.Field>
      );
    });
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.name) {
      this.setState({ nameError: 'Name is required' });
      return null;
    }
    if (!this.state.redirectUris) {
      this.setState({ redirectUrisError: 'Redirect URIs are required' });
      return null;
    }
    if (!this.state.scope || this.state.scope.length === 0) {
      this.setState({ scopeError: 'Scope is required' });
      return null;
    }

    const data = {
      client_name: this.state.name,
      redirect_uris: this.state.redirectUris,
      scopes: this.state.scope,
    };
    this.setState({ isLoading: true });
    if (this.props.selectedClient) {
      API.put(
        `/clients/${this.props.selectedClient.id}/`,
        data,
        (response) => {
          this.props.onUpdate(response.data);
          this.clearState();
          this.props.onClose();
        },
        API.defaultError,
      );
    } else {
      API.post(
        '/clients/',
        data,
        (response) => {
          this.clearState();
          this.props.onClose();
          this.props.onCreate(response.data);
        },
        API.defaultError,
      );
    }
  };

  deleteClient = (e) => {
    e.preventDefault();
    if (!this.props.selectedClient) {
      return MAlert('No client selected!', 'Error', 'error');
    }

    MConfirm('Delete Client?', 'Are you sure you want to delete this client?', 'warning', (confirmed) => {
      if (confirmed) {
        API.delete(
          `/clients/${this.props.selectedClient.id}/`,
          () => {
            this.props.onDelete(this.props.selectedClient.id);
            this.clearState();
            this.props.onClose();
          },
          API.defaultError,
        );
      }
    });
  };

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  onScopeChange = (obj, action) => {
    const updatedScope = this.state.scope;
    if (action.action === 'select-option') {
      updatedScope.push(action.option.value);
      this.setState({ scope: updatedScope });
    } else if (action.action === 'select-all-options') {
      const entries = action.option.map((option) => option.value);
      updatedScope.push(...entries);
      this.setState({ scope: updatedScope });
    } else if (action.action === 'remove-value') {
      const scope = action.removedValue.value;
      const updatedScope = [];
      for (let existingScope of this.state.scope) {
        if (existingScope !== scope) {
          updatedScope.push(existingScope);
        }
      }
      this.setState({ scope: updatedScope });
    } else if (action.action === 'clear') {
      this.setState({ scope: [] });
    }
  };

  addRedirectUri = (e) => {
    e.preventDefault();
    const updatedRedirectUris = this.state.redirectUris;
    updatedRedirectUris.push('');
    this.setState({ redirectUris: updatedRedirectUris });
  };

  removeRedirectUri = (e, idx) => {
    e.preventDefault();
    const updatedRedirectUris = this.state.redirectUris;
    updatedRedirectUris.splice(idx, 1);
    this.setState({ redirectUris: updatedRedirectUris });
  };

  onRedirectUriChange = (e, idx) => {
    const updatedRedirectUris = this.state.redirectUris.slice();
    updatedRedirectUris[idx] = e.target.value;
    this.setState({ redirectUris: updatedRedirectUris });
  };

  clearState = () => {
    this.setState({
      name: '',
      nameError: '',
      redirectUris: [''],
      redirectUrisError: '',
      scope: [],
      scopeError: '',
      userId: '',
      userError: '',
    });
  };
}

ClientModal.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  selectedClient: PropTypes.object,
  show: PropTypes.bool,
  users: PropTypes.array,
  ui: PropTypes.object,
};

ClientModal.contextType = UserContext;

export default connect(mapUiStateToProps)(ClientModal);
