import React from 'react';
import { Heading } from 'react-bulma-components';
import eoy_email_metrics from 'images/eoy_email_metrics.png';
import default_company_logo from 'images/default_company_logo.svg';
import loading_animation from 'images/loading.gif';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

function MatikAuthPlugTrial({ companyName, companyLogoUrl, descriptionText, isBrandingPage }) {
  let formattedCompanyName = '{{ Company }}';
  if (companyName) {
    formattedCompanyName = companyName;
  }

  const thisYear = new Date().getFullYear();
  let imgSrc;
  if (companyLogoUrl?.startsWith('http')) {
    imgSrc = companyLogoUrl;
  } else {
    imgSrc = `data:image;base64,${companyLogoUrl}`;
  }

  const brandingPageDesignLogo = () => {
    return companyLogoUrl ? <img src={imgSrc} /> : <Skeleton count={1} width="36" />;
  };

  return (
    <div className="matik-auth-plug-trial">
      <div className="default-company-logo">
        {isBrandingPage ? brandingPageDesignLogo() : <img src={default_company_logo} />}
      </div>
      <Heading size={2} className="matik-signup-text">
        Your {thisYear} in review
        <br /> with {formattedCompanyName}
      </Heading>
      <p className="is-secondary-description mtxl mbxl">
        Thank you for using {formattedCompanyName}! Let&apos;s look back on what we accomplished together in {thisYear}.
      </p>
      <div className="image-container">
        {isBrandingPage ? (
          <div>
            <p className="scanning-metrics is-secondary-description mtxxxl mbxl">{descriptionText}</p>
            <img className="gif-styling" src={loading_animation}></img>
          </div>
        ) : (
          <img src={eoy_email_metrics} />
        )}
      </div>
    </div>
  );
}

MatikAuthPlugTrial.propTypes = {
  companyName: PropTypes.string,
  companyLogoUrl: PropTypes.string,
  descriptionText: PropTypes.string,
  isBrandingPage: PropTypes.bool,
};

export default MatikAuthPlugTrial;
