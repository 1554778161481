import { Form } from 'react-bulma-components';
import InputWithError from 'components/shared/InputWithError';
import React from 'react';
import PropTypes from 'prop-types';

export default function CompanyInfoForm(props) {
  return (
    <div className="modal-body-center ptn company-info-form">
      <Form.Field className="is-horizontal">
        <Form.Label
          className="is-normal"
          data-tooltip-content="Matik will use your company information to personalize the template to your business."
          data-tooltip-id="matik-tooltip"
        >
          <span>Company Name</span>
        </Form.Label>
        <Form.Control className="is-expanded is-fullwidth">
          <InputWithError name="name" error={props.nameError} onChange={props.onNameChange} value={props.name} />
        </Form.Control>
      </Form.Field>
      <Form.Field className="is-horizontal">
        <Form.Label
          className="is-normal"
          data-tooltip-id="matik-tooltip"
          data-tooltip-content="Matik will use your company’s marketing website to analyze your business and personalize branding."
        >
          <span>Company Website</span>
        </Form.Label>
        <Form.Control className="is-expanded is-fullwidth">
          <InputWithError
            name="website"
            error={props.websiteError}
            onChange={props.onWebsiteChange}
            value={props.website}
          />
        </Form.Control>
      </Form.Field>
      <button className="button is-primary is-fullwidth" onClick={props.onSubmit}>
        {props.buttonText}
      </button>
    </div>
  );
}

CompanyInfoForm.propTypes = {
  nameError: PropTypes.string,
  onNameChange: PropTypes.func,
  name: PropTypes.string,
  websiteError: PropTypes.string,
  onWebsiteChange: PropTypes.func,
  website: PropTypes.string,
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
};
