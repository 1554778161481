import FullScreenModal from 'components/shared/modal/FullScreenModal';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Heading } from 'react-bulma-components';
import API from 'lib/api';
import { CopyIcon } from '../../images/copy_icon';
import utils from 'lib/utils';
import Banner, { toastBanner } from '../shared/Banner';
import HowTo from '../shared/HowTo';

function DomainAuthModal(props) {
  const [subdomain, setSubdomain] = useState('');
  const [jsonRecord, setJsonRecord] = useState('');

  useEffect(() => {
    const sendgridInfo = props.enterprise?.integrations?.find((i) => i.name === 'sendgrid');
    if (sendgridInfo) {
      setSubdomain(sendgridInfo.auth?.subdomain);
      setJsonRecord(sendgridInfo.auth?.dns);
    }
  }, [props.enterprise]);

  const onChange = (e) => {
    setSubdomain(e.target.value);
  };

  const onGenerateSuccess = (response) => {
    props.onEnterpriseUpdate(response.data, false);
    toastBanner(
      <Banner
        bannerType="success"
        text="Success! Your domain is authenticated."
        sublineText="Please add the DNS records to your domain host"
      />,
    );
  };

  const onValidateSuccess = (response) => {
    props.onEnterpriseUpdate(response.data, false);
    const newSendgridInfo = response.data.integrations?.find((i) => i.name === 'sendgrid');
    if (props.inTemplatePage) {
      props.onClose();
    }
    if (newSendgridInfo.auth?.domain_validated) {
      toastBanner(
        <Banner
          bannerType="success"
          text="Success! Your domain is authenticated."
          sublineText="Go to your Enterprise Settings to view and make edits."
        />,
      );
    } else if (props.inTemplatePage) {
      props.templateToastBanner();
    } else {
      toastBanner(
        <Banner
          bannerType="error"
          text="Validation not sucessful!"
          sublineText="It can take up to 48 hours for the records to be verified."
        />,
      );
    }
  };

  const generateRecord = () => {
    const data = { subdomain: subdomain };
    API.post(`/enterprises/${props.enterprise.id}/configure_mail/`, data, onGenerateSuccess, API.defaultError);
  };

  const validateRecord = () => {
    const data = {};
    API.post(`/enterprises/${props.enterprise.id}/validate_mail/`, data, onValidateSuccess, API.defaultError);
  };

  const copyJson = () => {
    navigator.clipboard.writeText(JSON.stringify(jsonRecord));
    utils.notify('Copied to clipboard');
  };

  const headerCenter = <Heading className="full-screen-modal-title">Authenticate Your Domain</Heading>;

  let primaryButtonText;
  let primaryButtonOnClick;
  let secondaryButtonText;
  let secondaryButtonOnClick;
  let tertiaryButtonText;
  let tertiaryButtonOnClick;
  if (jsonRecord) {
    primaryButtonText = 'Validate Records';
    primaryButtonOnClick = validateRecord;
    secondaryButtonText = 'Regenerate Record';
    secondaryButtonOnClick = generateRecord;
    tertiaryButtonText = 'Cancel';
    tertiaryButtonOnClick = props.onClose;
  } else {
    primaryButtonText = 'Generate Record';
    primaryButtonOnClick = generateRecord;
    secondaryButtonText = 'Cancel';
    secondaryButtonOnClick = props.onClose;
  }

  return (
    <FullScreenModal
      show={props.show}
      headerCenter={headerCenter}
      showDefaultFooter={true}
      backgroundColor="white"
      onClose={props.onClose}
      primaryButtonText={primaryButtonText}
      primaryButtonOnClick={primaryButtonOnClick}
      primaryButtonDisabled={false}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonOnClick={secondaryButtonOnClick}
      tertiaryButtonText={tertiaryButtonText}
      tertiaryButtonOnClick={tertiaryButtonOnClick}
    >
      <div className="three-column-modal-body">
        <div className="modal-sidebar invisible"></div>
        <div className="modal-body-center ptn">
          <div className="email-from-modal-form">
            <Form.Field>
              <Form.Label>Enter a Subdomain</Form.Label>
              <p className="form-info-text">Choose a new subdomain that isn&lsquo;t already being used for email</p>
              <div className="subdomain-form">
                <Form.Input
                  name="subdomain"
                  className="subdomain-input"
                  type="text"
                  placeholder="Add subdomain"
                  value={subdomain}
                  onChange={onChange}
                ></Form.Input>
                <div className="domain-suffix">
                  <p>.{props.enterprise?.domain ? props.enterprise.domain : '<yourdomain>'}</p>
                </div>
              </div>
            </Form.Field>
          </div>
          {jsonRecord ? (
            <div className="json-section">
              <div className="json-heading">
                <Form.Label>JSON Record</Form.Label>
                <div className="json-copy" onClick={copyJson}>
                  <CopyIcon />
                  <Form.Label className="json-copy-text">Copy JSON</Form.Label>
                </div>
              </div>
              <pre className="json-box">{JSON.stringify(jsonRecord, null, 2)}</pre>
            </div>
          ) : null}
        </div>
        <div className="modal-sidebar">
          <HowTo>
            <p className="how-to-heading">HOW TO</p>
            <ol>
              <li>
                <span className="how-to-text subheader">Generate JSON Records</span>
              </li>
              <p className="how-to-text">
                Generate your JSON authentication records by entering a subdomain that isn&lsquo;t already being used
                for email
              </p>
              <li>
                <span className="how-to-text subheader">Embed Record</span>
              </li>
              <p className="how-to-text">Upload the JSON authentication records to your domain host</p>
              <li>
                <span className="how-to-text subheader">Validate Record</span>
              </li>
              <p className="how-to-text">
                Please note that this can take up to <b>48 hours</b> - you can come back any time to check
              </p>
            </ol>
            <p className="how-to-text">Need more information?</p>
            <a
              href="https://help.matik.io/hc/en-us/articles/13090747609627"
              target="_blank"
              rel="noreferrer"
              className="how-to-text link"
            >
              Help Center Article
            </a>
          </HowTo>
        </div>
      </div>
    </FullScreenModal>
  );
}

DomainAuthModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  enterprise: PropTypes.object,
  onEnterpriseUpdate: PropTypes.func,
  templateToastBanner: PropTypes.func,
  inTemplatePage: PropTypes.bool,
};

export default DomainAuthModal;
