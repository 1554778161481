import React from 'react';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import PropTypes from 'prop-types';
import unknown_icon from '../../../images/unknown.svg';
import Icon from '../../lib/Icon';

function EmailRecipientDcCard(props) {
  const getDataSourceCell = () => {
    let dataSourceIcon;
    let dataSourceName = '';
    if (
      props.content?.query_obj?.data_source?.type &&
      props.content?.query_obj?.data_source?.type !== Constants.DATA_SOURCE_TYPES.matik_content
    ) {
      dataSourceIcon = (
        <img
          src={Constants.DATA_SOURCE_TYPE_DISPLAY[props.content.query_obj.data_source.type].icon}
          width="32px"
          className="mrm"
          alt="Data Source Type"
        />
      );
      dataSourceName = props.content.query_obj.data_source.name;
    } else if (props.content?.dynamic_content_method === 'static') {
      dataSourceIcon = <span>--</span>;
    } else {
      dataSourceIcon = <img src={unknown_icon} width="32px" className="mrm" alt="Data Source Type" />;
    }
    return (
      <span className="is-flex min-width-0 is-vertical-centered">
        {dataSourceIcon}
        <TextOverflowWithTooltip className="text-overflow-ellipsis" text={dataSourceName} />
      </span>
    );
  };

  return (
    <tr className="email-recipients-dc-row" onClick={props.onRowClick} id={props.content?.id}>
      <td className="dc-row-cell icon">
        <img src={Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[props.content?.dynamic_content_type]?.icon} />
      </td>
      <td className="dc-row-cell name">
        <TextOverflowWithTooltip className="text-overflow-ellipsis" text={props.content?.name} />
      </td>
      <td className="dc-row-cell method">{utils.capitalizeString(props.content?.dynamic_content_method)}</td>
      <td className="dc-row-cell source">{getDataSourceCell()}</td>
      {props.primary ? (
        <>
          <td className="dc-row-cell" onClick={() => props.editOnClick(props.contentTag)}>
            <Icon name="pencil" />
          </td>
          <td className="dc-row-cell">
            <Icon name="chevron_down" />
          </td>
        </>
      ) : (
        <>
          <td>
            <span style={{ marginLeft: '32px' }}></span>
          </td>
          <td>
            <span></span>
          </td>
        </>
      )}
    </tr>
  );
}

EmailRecipientDcCard.propTypes = {
  content: PropTypes.object,
  onRowClick: PropTypes.func,
  primary: PropTypes.bool,
  contentTag: PropTypes.object,
  editOnClick: PropTypes.func,
};

export default EmailRecipientDcCard;
