import React, { useEffect, useState, useContext } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import PropTypes from 'prop-types';
import Indicator from './Indicator';
import { PickColor } from 'components/shared/PickColor';
import { CreatableSelect, Select } from 'components/shared/FormSelect';
import HtmlBuilderCreateTable from '../HtmlBuilderCreateTable';
import utils from 'lib/utils';
import { find } from 'lodash';
import htmlUtils from '../../../../lib/html';
import { useDispatch } from 'react-redux';
import { openSidepane } from 'redux/ui/action';
import Constants from 'components/Constants';
import PaddingInput from 'components/shared/PaddingInput';
import FormInput from '../../FormInput';
import BorderInput from 'components/shared/BorderInput';
import Icon from 'components/lib/Icon';
import ToggleSwitchWithLabel from '../../../lib/ToggleSwitchWithLabel';
import { HtmlBuilderContext } from '../../../producer/email/EmailBodyHtml';
import { MAlert } from '../../Alerts';
import HtmlBuilderVisualizeEmptyState from './HtmlBuilderVisualizeEmptyState';
import InputDynamicContent from 'components/producer/dynamicContent/InputDynamicContent';
import { useAllDynamicContentById } from '../../../../lib/hooks/useDynamicContent';
import { addNotificationSuccess } from 'redux/notifications/action';
import { Link } from 'react-router-dom';

function Table({ tableDc, previewDc, tableSettings, style, tableKey, isNewNode }) {
  const [isReceivingData, setIsReceivingData] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(Object.keys(previewDc).length > 0);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const { dynamicContentById } = useAllDynamicContentById();
  const { testResult, setAllDynamicContent, allDynamicContent, setTestResult, setIsFetchingDc, onPreviewDcModal } =
    useContext(HtmlBuilderContext);
  const dispatch = useDispatch();

  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    isHover: query.getEvent('hovered').contains(id),
  }));

  const {
    actions: { setProp },
    connectors: { connect, drag },
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
  }));

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  // check if connected dynamic content has been renamed or deleted
  useEffect(() => {
    if (!dynamicContentById || !tableDc?.id) {
      return;
    }

    const matchingDc = dynamicContentById[tableDc.id];

    if (!matchingDc) {
      // dc has been deleted
      setIsDisconnected(true);
      return;
    }

    if (matchingDc.name !== tableDc.name) {
      const oldDcName = tableDc.name;

      // if ids match but names don't (dc has been renamed), update tableDc
      setProp((props) => {
        props.tableDc = matchingDc;
      });

      const LinkComponent = () => <Link to={`/dynamic_content/${matchingDc.id}`}>{matchingDc.name}</Link>;

      setIsDisconnected(false);
      dispatch(
        addNotificationSuccess(
          <>
            {oldDcName} was renamed to <LinkComponent />. Save your template to update its connections to this dynamic
            content.
          </>,
        ),
      );
    } else {
      setIsDisconnected(false);
    }
  }, [dynamicContentById, tableDc]);

  useEffect(() => {
    if (!tableKey) {
      setProp((props) => (props.tableKey = id));
    }
  }, []);

  // on preview dynamic content
  function onPreviewDc() {
    if (isReceivingData) {
      setIsReceivingData(false);
      setIsFetchingDc(false);
    } else {
      setIsReceivingData(true);
      const previewTableDc = {
        [tableDc.name]: tableDc,
      };
      setAllDynamicContent(previewTableDc);
    }
  }

  // when allDynamicContent is set open inputs modal
  useEffect(() => {
    if (isReceivingData && Object.keys(allDynamicContent).length > 0) {
      onPreviewDcModal();
    }
  }, [allDynamicContent]);

  // on test result return
  useEffect(() => {
    if (isReceivingData && Object.keys(testResult).length > 0) {
      if (testResult?.status === 'error') {
        MAlert(`${testResult.message}`, 'Error', 'error');
        setIsFetchingDc(false);
      } else {
        setProp((props) => (props.previewDc = testResult[tableDc.name]));
        setIsPreviewed(true);
        setIsFetchingDc(false);
      }
      setIsReceivingData(false);
      setTestResult({});
    }
  }, [testResult]);

  return (
    <div
      id={`react-table-${tableKey}`}
      className="is-relative is-transparent"
      ref={(dom) => connect(dom)}
      style={style}
    >
      {(isActive || isHover) && (
        <Indicator
          borderWidth="0px"
          borderRadius="4px"
          tabText="Table"
          isActive={isActive}
          nodeId={id}
          drag={drag}
          onPreviewDc={Object.keys(tableDc).length > 0 ? onPreviewDc : null}
          isPreviewed={isPreviewed}
          placeholder={Object.keys(tableDc).length > 0 && !isPreviewed}
          isDisconnected={isDisconnected}
        />
      )}
      {Object.keys(tableDc).length > 0 ? (
        <HtmlBuilderCreateTable
          previewDc={previewDc}
          tableSettings={tableSettings}
          updateTableHTML={(value) => {
            if (
              Object.keys(previewDc).length === 0 ||
              (Object.keys(previewDc).length > 0 && value !== '<table></table>')
            ) {
              setProp((props) => {
                props.tableHTML = value;
              });
            }
          }}
          updateInlineStyles={(value) => {
            setProp((props) => {
              props.inlineStyles = value;
            });
          }}
          tableBorderState={tableSettings.tableBorderState}
        />
      ) : (
        <HtmlBuilderVisualizeEmptyState dataType="table" />
      )}
    </div>
  );
}

Table.craft = {
  props: {
    tableDc: {},
    previewDc: [],
    tableHTML: '',
    inlineStyles: '',
    tableKey: '',
    isNewNode: true,
    tableSettings: {
      percentWidth: '100%',
      pxWidth: '530px',
      tableFontFamily: 'arial',
      responsiveTable: true,
      headerRowStyling: true,
      headerRowBackgroundColor: '#E5E7EC',
      headerRowBorderColor: '#CACED6',
      headerRowFontColor: '#000000',
      headerRowFontWeight: 'bold',
      headerRowFontSize: '14px',
      headerRowBorderThickness: '1px',
      headerRowCellPaddingBottom: '5px',
      headerRowCellPaddingLeft: '5px',
      headerRowCellPaddingRight: '5px',
      headerRowCellPaddingTop: '5px',
      headerRowTextTransform: 'none',
      headerRowHorizontalAlign: 'left',
      headerRowVerticalAlign: 'middle',
      headerColumnStyling: true,
      headerColumnBackgroundColor: '#F0F2F7',
      headerColumnBorderColor: '#CACED6',
      headerColumnFontColor: '#000000',
      headerColumnFontWeight: 'bold',
      headerColumnFontSize: '14px',
      headerColumnBorderThickness: '1px',
      headerColumnCellPaddingBottom: '5px',
      headerColumnCellPaddingLeft: '5px',
      headerColumnCellPaddingRight: '5px',
      headerColumnCellPaddingTop: '5px',
      headerColumnTextTransform: 'none',
      headerColumnHorizontalAlign: 'left',
      headerColumnVerticalAlign: 'middle',
      tableCellPaddingBottom: '5px',
      tableCellPaddingLeft: '5px',
      tableCellPaddingRight: '5px',
      tableCellPaddingTop: '5px',
      tableFontSize: '14px',
      tableFontColor: '#000000',
      tableFontWeight: 'normal',
      tableStripedBackground: true,
      tableBackgroundColor: '#FFFFFF',
      tableSecondaryBackgroundColor: '#F9FAFC',
      tableBorderColor: '#CACED6',
      tableBorderThickness: '1px',
      tableTextTransform: 'none',
      tableHorizontalAlign: 'left',
      tableVerticalAlign: 'middle',
      tableBorderState: {
        headerRow: {
          all: true,
          inside: false,
          outside: false,
          horizontal: false,
          vertical: false,
          top: false,
          bottom: false,
          left: false,
          right: false,
        },
        headerColumn: {
          all: false,
          inside: false,
          outside: false,
          horizontal: true,
          vertical: false,
          top: false,
          bottom: true,
          left: true,
          right: true,
        },
        table: {
          all: false,
          inside: true,
          outside: false,
          horizontal: false,
          vertical: false,
          top: false,
          bottom: true,
          left: false,
          right: true,
        },
      },
    },
    style: {
      backgroundColor: '#FFFFFF',
      borderColor: '#FFFFFF',
      borderWidth: '1px',
      borderStyle: 'solid',
      paddingBottom: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
    },
  },
  related: {
    settings: TableSettings,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
};

Table.propTypes = {
  tableDc: PropTypes.object,
  previewDc: PropTypes.array,
  tableHTML: PropTypes.string,
  inlineStyles: PropTypes.string,
  tableKey: PropTypes.string,
  tableSettings: PropTypes.object,
  style: PropTypes.object,
  testQuery: PropTypes.func,
  isNewNode: PropTypes.bool,
};

function TableSettings() {
  const {
    actions: { setProp },
    tableDc,
    tableSettings,
    style,
  } = useNode((node) => ({
    id: node.id,
    tableDc: node.data.props.tableDc,
    style: node.data.props.style,
    tableSettings: node.data.props.tableSettings,
  }));

  const dispatch = useDispatch();
  const fontFamilyOptions = htmlUtils.constants.fontFamilyOptions;
  const fontSizeOptions = htmlUtils.constants.fontSizeOptions;
  const [tableHeadersExpanded, setTableHeadersExpanded] = useState(true);
  const [tableBodyExpanded, setTableBodyExpanded] = useState(true);

  // *******************************************************
  // ********************** FUNCTIONS **********************
  // *******************************************************

  const toggleTableHeadersExpanded = () => {
    setTableHeadersExpanded((current) => !current);
  };

  const toggleTableBodyExpanded = () => {
    setTableBodyExpanded((current) => !current);
  };

  const onDynamicContentChange = async (dynamicContent) => {
    if (dynamicContent.dynamic_content_type === Constants.DynamicContentTypes.TABLE) {
      setProp((props) => {
        props.tableDc = dynamicContent;
      });
    }
    return dynamicContent;
  };

  const onTextTransformChange = (e, textTransformProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[textTransformProp] = e;
    });
  };

  const onAlignmentChange = (e, alignmentProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[alignmentProp] = e;
    });
  };

  const onNumberChange = (num, incomingProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[incomingProp] = `${num}px`;
    });
  };

  const onUpdatePixelInputValue = (e, propName) => {
    const value = e.target.value;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[propName] = `${value}px`;
    });
  };

  const onColorChange = (propName) => (color) => {
    const hex = color.hex;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[propName] = hex;
    });
  };

  const onTableColorChange = (propName) => (color) => {
    const hex = color.hex;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[propName] = hex;
    });
  };

  const onUpdateTableFontFamily = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      setProp((props) => {
        // eslint-disable-next-line react/prop-types
        props.tableSettings.tableFontFamily = value;
      });
    }
  };

  const onUpdateTableFontSize = (obj, incomingProp) => {
    const value = obj.value;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[incomingProp] = `${value}px`;
    });
  };

  const onUpdateTableValue = (obj, incomingProp) => {
    const value = obj.value;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[incomingProp] = value;
    });
  };

  const onUpdateWidthValue = (value) => {
    setProp((props) => {
      if (tableSettings.responsiveTable) {
        // eslint-disable-next-line react/prop-types
        props.tableSettings.percentWidth = `${value}%`;
      } else {
        // eslint-disable-next-line react/prop-types
        props.tableSettings.pxWidth = `${value}px`;
      }
    });
  };

  const onTableNumberChange = (num, incomingProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[incomingProp] = `${num}px`;
    });
  };

  const onUpdateTablePixelInputValue = (e, propName) => {
    const value = e.target.value;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings[propName] = `${value}px`;
    });
  };

  const onUpdateBorderProps = (newState, section) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.tableSettings.tableBorderState[section] = newState;
    });
  };

  const editOnClick = () => {
    dispatch(openSidepane(utils.getTagFromSingleDynamicContent(tableDc), 'template-sidepane'));
  };

  const { dynamicContentById } = useAllDynamicContentById();

  // ********************************************************
  // *********************** SETTINGS ***********************
  // ********************************************************

  return (
    <div className="module-settings-container">
      <label>Select Data</label>
      <div>
        <div className="pts">
          <InputDynamicContent
            onEditClick={editOnClick}
            onChange={onDynamicContentChange}
            content={tableDc}
            acceptedContentTypes={[Constants.DynamicContentTypes.TABLE]}
            allDynamicContentNamesById={dynamicContentById}
            showSubtitle={true}
          />
        </div>
        <div className="module-setting">
          <p className="sidebar-description">
            {Object.keys(tableDc).length > 0
              ? 'Preview your Dynamic Content using the play button.'
              : 'Select the Dynamic Content that will populate your table.'}
          </p>
        </div>
      </div>
      {Object.keys(tableDc).length > 0 && (
        <>
          <hr />
          <div className="module-setting">
            <label>Padding</label>
            <div>
              <PaddingInput
                onPaddingChange={onNumberChange}
                paddingBottom={parseInt(style.paddingBottom)}
                paddingLeft={parseInt(style.paddingLeft)}
                paddingRight={parseInt(style.paddingRight)}
                paddingTop={parseInt(style.paddingTop)}
              />
            </div>
          </div>
          <div className="module-setting set-height">
            <ToggleSwitchWithLabel
              label="Responsive Table"
              onClick={() => onUpdateTableValue({ value: !tableSettings.responsiveTable }, 'responsiveTable')}
              switchState={tableSettings.responsiveTable === true}
              tooltipText="Toggle on to make table responsive, with width set as a percentage of the container. Toggle off to set width in pixels."
            />
          </div>
          {tableSettings.responsiveTable ? (
            <div className="module-setting">
              <label>Width</label>
              <FormInput
                inputClass="module-settings-input pixel-input"
                onChange={(e) => onUpdateWidthValue(e.target.value)}
                suffix={<span>%</span>}
                type="number"
                value={parseInt(tableSettings.percentWidth)}
              />
            </div>
          ) : (
            <div className="module-setting">
              <label>Width</label>
              <FormInput
                inputClass="module-settings-input pixel-input"
                onChange={(e) => onUpdateWidthValue(e.target.value)}
                suffix={<span>px</span>}
                type="number"
                value={parseInt(tableSettings.pxWidth)}
              />
            </div>
          )}
          <div className="module-setting">
            <label>Background Color</label>
            <PickColor
              onChange={onColorChange('backgroundColor')}
              hexColor={style.backgroundColor}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>
          <div className="module-setting">
            <label>Border Color</label>
            <PickColor
              onChange={onColorChange('borderColor')}
              hexColor={style.borderColor}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>
          <div className="module-setting">
            <label>Border Thickness</label>
            <FormInput
              inputClass="module-settings-input pixel-input"
              onChange={(e) => onUpdatePixelInputValue(e, 'borderWidth')}
              suffix={<span>px</span>}
              type="number"
              value={parseInt(style.borderWidth)}
            />
          </div>
          <div className="module-setting">
            <label>Font</label>
            <div className="module-settings-select">
              <Select
                aria-label="Select Font"
                classNamePrefix="custom-select"
                id="font-family-select-dropdown"
                name="table-font-family"
                onChange={onUpdateTableFontFamily}
                options={fontFamilyOptions}
                value={find(fontFamilyOptions, { value: tableSettings.tableFontFamily })}
              />
            </div>
          </div>
          <hr />
          {/*********************************************************/}
          {/******************* TABLE HEADERS ROW *******************/}
          {/*********************************************************/}
          <div className="module-setting">
            <p className="modules-header">Table Headers</p>
            <div className="chevron-icon" onClick={toggleTableHeadersExpanded}>
              <Icon name={tableHeadersExpanded ? 'chevron_down' : 'chevron_right'} size={12} theme="regular" />
            </div>
          </div>
          {tableHeadersExpanded && (
            <>
              <ToggleSwitchWithLabel
                label={
                  <div className="flex gap-2">
                    <Icon name="table_header_row" size={20} theme="filled" />
                    <label>Header Row</label>
                  </div>
                }
                switchState={tableSettings.headerRowStyling === true}
                onClick={() => onUpdateTableValue({ value: !tableSettings.headerRowStyling }, 'headerRowStyling')}
              />
              {tableSettings.headerRowStyling && (
                <>
                  <div className="module-setting">
                    <label>Background Color</label>
                    <PickColor
                      onChange={onTableColorChange('headerRowBackgroundColor')}
                      hexColor={tableSettings.headerRowBackgroundColor}
                      ancestorEl={document.querySelector('.template-components')}
                    />
                  </div>
                  <div className="module-setting border-label">
                    <label>
                      <p className="mt-2">Border Style</p>
                    </label>
                    <BorderInput
                      section="headerRow"
                      borderState={tableSettings.tableBorderState}
                      onUpdateBorderProps={onUpdateBorderProps}
                      disabledButtons={['horizontal']}
                      disabledTooltip="Horizontal border is disabled for header row"
                    />
                  </div>
                  <div className="module-setting">
                    <label>Border Thickness</label>
                    <FormInput
                      inputClass="module-settings-input pixel-input"
                      onChange={(e) => onUpdateTablePixelInputValue(e, 'headerRowBorderThickness')}
                      suffix={<span>px</span>}
                      type="number"
                      value={parseInt(tableSettings.headerRowBorderThickness)}
                    />
                  </div>
                  <div className="module-setting">
                    <label>Border Color</label>
                    <PickColor
                      onChange={onTableColorChange('headerRowBorderColor')}
                      hexColor={tableSettings.headerRowBorderColor}
                      ancestorEl={document.querySelector('.template-components')}
                    />
                  </div>
                  <div className="module-setting">
                    <label>Font size</label>
                    <div className="module-settings-select">
                      <CreatableSelect
                        aria-label="Select Font Size"
                        classNamePrefix="custom-select"
                        id="font-size-select-dropdown"
                        name="table-font-weight"
                        onChange={(e) => onUpdateTableFontSize(e, 'headerRowFontSize')}
                        options={fontSizeOptions}
                        value={find(fontSizeOptions, { value: parseInt(tableSettings.headerRowFontSize) })}
                      />
                    </div>
                  </div>
                  <div className="module-setting">
                    <label>Font Color</label>
                    <PickColor
                      onChange={onTableColorChange('headerRowFontColor')}
                      hexColor={tableSettings.headerRowFontColor}
                      ancestorEl={document.querySelector('.template-components')}
                    />
                  </div>
                  <div className="module-setting">
                    <label>Font weight</label>
                    <div className="module-settings-select">
                      <CreatableSelect
                        aria-label="Select Font Weight"
                        classNamePrefix="custom-select"
                        id="font-weight-select-dropdown"
                        name="font-weight"
                        onChange={(e) => onUpdateTableValue(e, 'headerRowFontWeight')}
                        options={[
                          { label: 'Normal', value: 'normal' },
                          { label: 'Bold', value: 'bold' },
                        ]}
                        value={find(
                          [
                            { label: 'Normal', value: 'normal' },
                            { label: 'Bold', value: 'bold' },
                          ],
                          { value: tableSettings.headerRowFontWeight },
                        )}
                      />
                    </div>
                  </div>
                  <div className="module-setting">
                    <label>Cell Padding</label>
                    <div>
                      <PaddingInput
                        onPaddingChange={onTableNumberChange}
                        paddingBottom={parseInt(tableSettings.headerRowCellPaddingBottom)}
                        paddingLeft={parseInt(tableSettings.headerRowCellPaddingLeft)}
                        paddingRight={parseInt(tableSettings.headerRowCellPaddingRight)}
                        paddingTop={parseInt(tableSettings.headerRowCellPaddingTop)}
                        paddingTopName="headerRowCellPaddingTop"
                        paddingRightName="headerRowCellPaddingRight"
                        paddingBottomName="headerRowCellPaddingBottom"
                        paddingLeftName="headerRowCellPaddingLeft"
                      />
                    </div>
                  </div>
                  <div className="module-setting ptxs pbxs">
                    <label>Text Transform</label>
                    <div className="html-builder-settings-icons">
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowTextTransform === 'none' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('none', 'headerRowTextTransform')}
                      >
                        <Icon name="text_transform_none" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowTextTransform === 'uppercase' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('uppercase', 'headerRowTextTransform')}
                      >
                        <Icon name="text_transform_uppercase" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowTextTransform === 'capitalize' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('capitalize', 'headerRowTextTransform')}
                      >
                        <Icon name="text_transform_capitalize" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowTextTransform === 'lowercase' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('lowercase', 'headerRowTextTransform')}
                      >
                        <Icon name="text_transform_lowercase" size={16} theme="regular" />
                      </div>
                    </div>
                  </div>
                  <div className="module-setting ptxs pbxs">
                    <label>Horizontal Align</label>
                    <div className="html-builder-settings-icons">
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowHorizontalAlign === 'left' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('left', 'headerRowHorizontalAlign')}
                      >
                        <Icon name="textbox_default" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowHorizontalAlign === 'center' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('center', 'headerRowHorizontalAlign')}
                      >
                        <Icon name="textbox_center" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowHorizontalAlign === 'right' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('right', 'headerRowHorizontalAlign')}
                      >
                        <Icon name="textbox_right" size={16} theme="regular" />
                      </div>
                    </div>
                  </div>
                  <div className="module-setting ptxs pbxs">
                    <label>Vertical Align</label>
                    <div className="html-builder-settings-icons">
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowVerticalAlign === 'top' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('top', 'headerRowVerticalAlign')}
                      >
                        <Icon name="textbox_align_top" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowVerticalAlign === 'middle' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('middle', 'headerRowVerticalAlign')}
                      >
                        <Icon name="textbox_align_center" size={16} theme="regular" />
                      </div>
                      {/* TODO (Emily): Figure out how to display vertical align bottom on editor */}
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerRowVerticalAlign === 'bottom' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('bottom', 'headerRowVerticalAlign')}
                      >
                        <Icon name="textbox_align_bottom" size={16} theme="regular" />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <hr />
              {/**********************************************************/}
              {/****************** TABLE HEADERS COLUMN ******************/}
              {/**********************************************************/}
              <div className="mt-3">
                <ToggleSwitchWithLabel
                  label={
                    <div className="flex gap-2">
                      <Icon name="table_header_column" size={20} theme="filled" />
                      <label>Header Column</label>
                    </div>
                  }
                  onClick={() =>
                    onUpdateTableValue({ value: !tableSettings.headerColumnStyling }, 'headerColumnStyling')
                  }
                  switchState={tableSettings.headerColumnStyling === true}
                />
              </div>
              {tableSettings.headerColumnStyling && (
                <>
                  <div className="module-setting">
                    <label>Background Color</label>
                    <PickColor
                      onChange={onTableColorChange('headerColumnBackgroundColor')}
                      hexColor={tableSettings.headerColumnBackgroundColor}
                      ancestorEl={document.querySelector('.template-components')}
                    />
                  </div>
                  <div className="module-setting border-label">
                    <label>
                      <p className="mt-2">Border Style</p>
                    </label>
                    <BorderInput
                      section="headerColumn"
                      borderState={tableSettings.tableBorderState}
                      onUpdateBorderProps={onUpdateBorderProps}
                      disabledButtons={['vertical']}
                      disabledTooltip="Vertical border is disabled for header column"
                    />
                  </div>
                  <div className="module-setting">
                    <label>Border Thickness</label>
                    <FormInput
                      inputClass="module-settings-input pixel-input"
                      onChange={(e) => onUpdateTablePixelInputValue(e, 'headerColumnBorderThickness')}
                      suffix={<span>px</span>}
                      type="number"
                      value={parseInt(tableSettings.headerColumnBorderThickness)}
                    />
                  </div>
                  <div className="module-setting">
                    <label>Border Color</label>
                    <PickColor
                      onChange={onTableColorChange('headerColumnBorderColor')}
                      hexColor={tableSettings.headerColumnBorderColor}
                      ancestorEl={document.querySelector('.template-components')}
                    />
                  </div>
                  <div className="module-setting">
                    <label>Font size</label>
                    <div className="module-settings-select">
                      <CreatableSelect
                        aria-label="Select Font Size"
                        classNamePrefix="custom-select"
                        id="font-size-select-dropdown"
                        name="table-font-weight"
                        onChange={(e) => onUpdateTableFontSize(e, 'headerColumnFontSize')}
                        options={fontSizeOptions}
                        value={find(fontSizeOptions, { value: parseInt(tableSettings.headerColumnFontSize) })}
                      />
                    </div>
                  </div>
                  <div className="module-setting">
                    <label>Font Color</label>
                    <PickColor
                      onChange={onTableColorChange('headerColumnFontColor')}
                      hexColor={tableSettings.headerColumnFontColor}
                      ancestorEl={document.querySelector('.template-components')}
                    />
                  </div>
                  <div className="module-setting">
                    <label>Font weight</label>
                    <div className="module-settings-select">
                      <CreatableSelect
                        aria-label="Select Font Weight"
                        classNamePrefix="custom-select"
                        id="font-weight-select-dropdown"
                        name="font-weight"
                        onChange={(e) => onUpdateTableValue(e, 'headerColumnFontWeight')}
                        options={[
                          { label: 'Normal', value: 'normal' },
                          { label: 'Bold', value: 'bold' },
                        ]}
                        value={find(
                          [
                            { label: 'Normal', value: 'normal' },
                            { label: 'Bold', value: 'bold' },
                          ],
                          { value: tableSettings.headerColumnFontWeight },
                        )}
                      />
                    </div>
                  </div>
                  <div className="module-setting">
                    <label>Cell Padding</label>
                    <div>
                      <PaddingInput
                        onPaddingChange={onTableNumberChange}
                        paddingBottom={parseInt(tableSettings.headerColumnCellPaddingBottom)}
                        paddingLeft={parseInt(tableSettings.headerColumnCellPaddingLeft)}
                        paddingRight={parseInt(tableSettings.headerColumnCellPaddingRight)}
                        paddingTop={parseInt(tableSettings.headerColumnCellPaddingTop)}
                        paddingTopName="headerColumnCellPaddingTop"
                        paddingRightName="headerColumnCellPaddingRight"
                        paddingBottomName="headerColumnCellPaddingBottom"
                        paddingLeftName="headerColumnCellPaddingLeft"
                      />
                    </div>
                  </div>
                  <div className="module-setting ptxs pbxs">
                    <label>Text Transform</label>
                    <div className="html-builder-settings-icons">
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnTextTransform === 'none' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('none', 'headerColumnTextTransform')}
                      >
                        <Icon name="text_transform_none" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnTextTransform === 'uppercase' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('uppercase', 'headerColumnTextTransform')}
                      >
                        <Icon name="text_transform_uppercase" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnTextTransform === 'capitalize' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('capitalize', 'headerColumnTextTransform')}
                      >
                        <Icon name="text_transform_capitalize" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnTextTransform === 'lowercase' ? 'selected' : ''
                        }`}
                        onClick={() => onTextTransformChange('lowercase', 'headerColumnTextTransform')}
                      >
                        <Icon name="text_transform_lowercase" size={16} theme="regular" />
                      </div>
                    </div>
                  </div>
                  <div className="module-setting ptxs pbxs">
                    <label>Horizontal Align</label>
                    <div className="html-builder-settings-icons">
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnHorizontalAlign === 'left' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('left', 'headerColumnHorizontalAlign')}
                      >
                        <Icon name="textbox_default" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnHorizontalAlign === 'center' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('center', 'headerColumnHorizontalAlign')}
                      >
                        <Icon name="textbox_center" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnHorizontalAlign === 'right' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('right', 'headerColumnHorizontalAlign')}
                      >
                        <Icon name="textbox_right" size={16} theme="regular" />
                      </div>
                    </div>
                  </div>
                  <div className="module-setting ptxs pbxs">
                    <label>Vertical Align</label>
                    <div className="html-builder-settings-icons">
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnVerticalAlign === 'top' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('top', 'headerColumnVerticalAlign')}
                      >
                        <Icon name="textbox_align_top" size={16} theme="regular" />
                      </div>
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnVerticalAlign === 'middle' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('middle', 'headerColumnVerticalAlign')}
                      >
                        <Icon name="textbox_align_center" size={16} theme="regular" />
                      </div>
                      {/* TODO (Emily): Figure out how to display vertical align bottom on editor */}
                      <div
                        className={`html-builder-settings-button ${
                          tableSettings.headerColumnVerticalAlign === 'bottom' ? 'selected' : ''
                        }`}
                        onClick={() => onAlignmentChange('bottom', 'headerColumnVerticalAlign')}
                      >
                        <Icon name="textbox_align_bottom" size={16} theme="regular" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <hr />
          {/*****************************************************/}
          {/**************** TABLE BODY SETTINGS ****************/}
          {/*****************************************************/}
          <div className="module-setting">
            <p className="modules-header">Table Body</p>
            <div className="chevron-icon" onClick={toggleTableBodyExpanded}>
              <Icon name={tableBodyExpanded ? 'chevron_down' : 'chevron_right'} size={12} theme="regular" />
            </div>
          </div>
          {tableBodyExpanded && (
            <>
              <div className="module-setting set-height">
                <ToggleSwitchWithLabel
                  label="Striped Background"
                  onClick={() =>
                    onUpdateTableValue({ value: !tableSettings.tableStripedBackground }, 'tableStripedBackground')
                  }
                  switchState={tableSettings.tableStripedBackground === true}
                />
              </div>
              <div className="module-setting">
                <label>{tableSettings.tableStripedBackground ? '1. ' : ''}Background Color</label>
                <PickColor
                  onChange={onTableColorChange('tableBackgroundColor')}
                  hexColor={tableSettings.tableBackgroundColor}
                  ancestorEl={document.querySelector('.template-components')}
                />
              </div>
              {tableSettings.tableStripedBackground && (
                <div className="module-setting">
                  <label>2. Background Color</label>
                  <PickColor
                    onChange={onTableColorChange('tableSecondaryBackgroundColor')}
                    hexColor={tableSettings.tableSecondaryBackgroundColor}
                    ancestorEl={document.querySelector('.template-components')}
                  />
                </div>
              )}
              <div className="module-setting border-label">
                <label>
                  <p className="mt-2">Border Style</p>
                </label>
                <BorderInput
                  section="table"
                  borderState={tableSettings.tableBorderState}
                  onUpdateBorderProps={onUpdateBorderProps}
                  disabledButtons={[]}
                />
              </div>
              <div className="module-setting">
                <label>Border Thickness</label>
                <FormInput
                  inputClass="module-settings-input pixel-input"
                  onChange={(e) => onUpdateTablePixelInputValue(e, 'tableBorderThickness')}
                  suffix={<span>px</span>}
                  type="number"
                  value={parseInt(tableSettings.tableBorderThickness)}
                />
              </div>
              <div className="module-setting">
                <label>Border Color</label>
                <PickColor
                  onChange={onTableColorChange('tableBorderColor')}
                  hexColor={tableSettings.tableBorderColor}
                  ancestorEl={document.querySelector('.template-components')}
                />
              </div>
              <div className="module-setting">
                <label>Font size</label>
                <div className="module-settings-select">
                  <CreatableSelect
                    aria-label="Select Font Size"
                    classNamePrefix="custom-select"
                    id="font-size-select-dropdown"
                    name="number"
                    onChange={(e) => onUpdateTableFontSize(e, 'tableFontSize')}
                    options={fontSizeOptions}
                    value={find(fontSizeOptions, { value: parseInt(tableSettings.tableFontSize) })}
                  />
                </div>
              </div>
              <div className="module-setting">
                <label>Font Color</label>
                <PickColor
                  onChange={onTableColorChange('tableFontColor')}
                  hexColor={tableSettings.tableFontColor}
                  ancestorEl={document.querySelector('.template-components')}
                />
              </div>
              <div className="module-setting">
                <label>Font weight</label>
                <div className="module-settings-select">
                  <CreatableSelect
                    aria-label="Select Font Weight"
                    classNamePrefix="custom-select"
                    id="font-weight-select-dropdown"
                    name="font-weight"
                    onChange={(e) => onUpdateTableValue(e, 'tableFontWeight')}
                    options={[
                      { label: 'Normal', value: 'normal' },
                      { label: 'Bold', value: 'bold' },
                    ]}
                    value={find(
                      [
                        { label: 'Normal', value: 'normal' },
                        { label: 'Bold', value: 'bold' },
                      ],
                      { value: tableSettings.tableFontWeight },
                    )}
                  />
                </div>
              </div>
              <div className="module-setting">
                <label>Cell Padding</label>
                <div>
                  <PaddingInput
                    onPaddingChange={onTableNumberChange}
                    paddingBottom={parseInt(tableSettings.tableCellPaddingBottom)}
                    paddingLeft={parseInt(tableSettings.tableCellPaddingLeft)}
                    paddingRight={parseInt(tableSettings.tableCellPaddingRight)}
                    paddingTop={parseInt(tableSettings.tableCellPaddingTop)}
                    paddingTopName="tableCellPaddingTop"
                    paddingRightName="tableCellPaddingRight"
                    paddingBottomName="tableCellPaddingBottom"
                    paddingLeftName="tableCellPaddingLeft"
                  />
                </div>
              </div>
              <div className="module-setting ptxs pbxs">
                <label>Text Transform</label>
                <div className="html-builder-settings-icons">
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableTextTransform === 'none' ? 'selected' : ''
                    }`}
                    onClick={() => onTextTransformChange('none', 'tableTextTransform')}
                  >
                    <Icon name="text_transform_none" size={16} theme="regular" />
                  </div>
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableTextTransform === 'uppercase' ? 'selected' : ''
                    }`}
                    onClick={() => onTextTransformChange('uppercase', 'tableTextTransform')}
                  >
                    <Icon name="text_transform_uppercase" size={16} theme="regular" />
                  </div>
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableTextTransform === 'capitalize' ? 'selected' : ''
                    }`}
                    onClick={() => onTextTransformChange('capitalize', 'tableTextTransform')}
                  >
                    <Icon name="text_transform_capitalize" size={16} theme="regular" />
                  </div>
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableTextTransform === 'lowercase' ? 'selected' : ''
                    }`}
                    onClick={() => onTextTransformChange('lowercase', 'tableTextTransform')}
                  >
                    <Icon name="text_transform_lowercase" size={16} theme="regular" />
                  </div>
                </div>
              </div>
              <div className="module-setting ptxs pbxs">
                <label>Horizontal Align</label>
                <div className="html-builder-settings-icons">
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableHorizontalAlign === 'left' ? 'selected' : ''
                    }`}
                    onClick={() => onAlignmentChange('left', 'tableHorizontalAlign')}
                  >
                    <Icon name="textbox_default" size={16} theme="regular" />
                  </div>
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableHorizontalAlign === 'center' ? 'selected' : ''
                    }`}
                    onClick={() => onAlignmentChange('center', 'tableHorizontalAlign')}
                  >
                    <Icon name="textbox_center" size={16} theme="regular" />
                  </div>
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableHorizontalAlign === 'right' ? 'selected' : ''
                    }`}
                    onClick={() => onAlignmentChange('right', 'tableHorizontalAlign')}
                  >
                    <Icon name="textbox_right" size={16} theme="regular" />
                  </div>
                </div>
              </div>
              <div className="module-setting ptxs pbxs">
                <label>Vertical Align</label>
                <div className="html-builder-settings-icons">
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableVerticalAlign === 'top' ? 'selected' : ''
                    }`}
                    onClick={() => onAlignmentChange('top', 'tableVerticalAlign')}
                  >
                    <Icon name="textbox_align_top" size={16} theme="regular" />
                  </div>
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableVerticalAlign === 'middle' ? 'selected' : ''
                    }`}
                    onClick={() => onAlignmentChange('middle', 'tableVerticalAlign')}
                  >
                    <Icon name="textbox_align_center" size={16} theme="regular" />
                  </div>
                  {/* TODO (Emily): Figure out how to display vertical align bottom on editor */}
                  <div
                    className={`html-builder-settings-button ${
                      tableSettings.tableVerticalAlign === 'bottom' ? 'selected' : ''
                    }`}
                    onClick={() => onAlignmentChange('bottom', 'tableVerticalAlign')}
                  >
                    <Icon name="textbox_align_bottom" size={16} theme="regular" />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Table;
