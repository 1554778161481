import React from 'react';
import { cloneDeep, find, isEmpty } from 'lodash';
import moment from 'moment';
import Constants from '../components/Constants';
import QueryProcessor from './queryProcessor';
import StringInputField from '../components/shared/paramFields/StringInputField';
import BooleanInputField from '../components/shared/paramFields/BooleanInputField';
import DateInputField from '../components/shared/paramFields/DateInputField';
import DateRangeInputField from '../components/shared/paramFields/DateRangeInputField';
import ListInputField from '../components/shared/paramFields/ListInputField';
import LongRequest from './longRequest';
import DateListInputField from '../components/shared/paramFields/DateListInputField';
import RelativeDate from './RelativeDate';
import utils from './utils';
import { getInputNamesFromQueryString } from './hooks/useInputList';

const inputs = {
  getAllInputs: (dynamicContentTags, allDynamicContent, template, templateContent, slides) => {
    let inputsInMatchingContent = inputs.getInputsInMatchingContent(dynamicContentTags);
    if (!slides || slides.length === 0) {
      slides = template?.slides ?? [];
    }
    for (let slide of slides) {
      inputs.getConditionInputsForSlide(slide, allDynamicContent, inputsInMatchingContent);
    }
    if (Object.keys(inputsInMatchingContent).length) {
      let slideNumsByInputName = inputs.slideNumsByInputName(
        templateContent,
        template?.slides ?? [],
        allDynamicContent,
      );
      inputsInMatchingContent = inputs.addSlideNumsToInputs(slideNumsByInputName, inputsInMatchingContent);
    }
    return inputsInMatchingContent;
  },

  getAllInputsForSelectedSlide: (
    dynamicContentTags,
    allDynamicContent,
    template,
    templateContent,
    slides,
    selectedSlidesBySlideNum,
  ) => {
    const inputsInMatchingContent = inputs.getAllInputs(
      dynamicContentTags,
      allDynamicContent,
      template,
      templateContent,
      slides,
    );
    const slideNumsByInputName = inputs.slideNumsByInputName(templateContent, slides, allDynamicContent);
    const filteredInputs = {};
    for (const inputName in inputsInMatchingContent) {
      if (inputs.isInSelectedSlides(selectedSlidesBySlideNum, slideNumsByInputName[inputName])) {
        filteredInputs[inputName] = inputsInMatchingContent[inputName];
      }
    }
    return filteredInputs;
  },

  isInSelectedSlides: (selectedSlidesBySlideNum, slideNums) => {
    if (slideNums) {
      for (let slideNum of slideNums) {
        if (selectedSlidesBySlideNum[slideNum]) {
          return true;
        }
      }
    }

    return false;
  },

  getConditionInputsForSlide: function (slide, allDynamicContent, inputsInMatchingContent) {
    if (slide.conditions && slide.conditions.length > 0) {
      const slideConditions = slide.conditions[0].conditions;
      inputs.getInputsFromConditions(slideConditions, allDynamicContent, inputsInMatchingContent);
    }
  },

  getInputsFromConditions: function (slideConditions, allDynamicContent, inputsInMatchingContent) {
    for (let condition of slideConditions.conditions) {
      for (let clause of condition.clauses) {
        const content = find(allDynamicContent, (content) => content.id === clause.dynamicContent);
        if (content) {
          inputs.getInputsFromContent(content, inputsInMatchingContent);
        }
      }
    }
  },

  getInputsInMatchingContent: (dynamicContentTags) => {
    let inputsInMatchingContent = {};
    for (let tag of dynamicContentTags) {
      if (tag.matchingContent && tag.matchingContent.parameters) {
        inputs.getInputsFromContent(tag.matchingContent, inputsInMatchingContent);
      }
    }
    return inputsInMatchingContent;
  },

  getInputsFromContent: (content, existingInputs = {}) => {
    if (!content) {
      return;
    }
    const contentInputs = inputs.inputsIncludingNested(content.parameters);
    for (let inputName in contentInputs) {
      const contentInput = contentInputs[inputName];

      let dcIdsInput;
      if (existingInputs[inputName] && existingInputs[inputName].dynamicContent) {
        dcIdsInput = existingInputs[inputName].dynamicContent.map((dc) => dc['id']);
      }
      if (existingInputs[inputName] && existingInputs[inputName].dynamicContent) {
        if (dcIdsInput.indexOf(content.id) === -1) {
          let obj = Object.assign({}, existingInputs[inputName]);
          obj.dynamicContent.push(content);
          existingInputs[inputName] = obj;
        }
      } else {
        // Content objects are not editable. They come through being set with Object.preventExtensions() from redux
        let obj = Object.assign({}, contentInput);
        obj.dynamicContent = [content];
        existingInputs[inputName] = obj;
      }

      // Ensure we have the most complete version of input data, since nested references may not have
      // parameters/nested_parameters populated.
      const existingInput = existingInputs[inputName];
      if (
        Object.keys(contentInput.parameters || {}).length > 0 &&
        Object.keys(existingInput.parameters || {}).length === 0
      ) {
        existingInput.parameters = Object.assign({}, contentInput.parameters);
      }
      if (
        Object.keys(contentInput.nested_parameters || {}).length > 0 &&
        Object.keys(existingInput.nested_parameters || {}).length === 0
      ) {
        existingInput.nested_parameters = Object.assign({}, contentInput.nested_parameters);
      }
    }
  },

  inputsIncludingNested: (inputInputs) => {
    let returnInputs = {};
    // TODO (zak): This is an ugly hack. Will update with my small inputs refactor. The issue is that
    // two different backend endpoints return slightly different parameters fields.
    let inputsByName = inputInputs;
    if (Array.isArray(inputInputs)) {
      inputsByName = {};
      inputInputs.forEach((input) => (inputsByName[input.name] = input));
    }
    for (let inputName in inputsByName) {
      let input = inputsByName[inputName];
      if (!input) {
        continue;
      }
      // note: nested input references may not contain fully populated nested_parameters/parameters values
      // so always prefer higher-level inputs when available
      if (input.nested_parameters) {
        returnInputs = Object.assign(returnInputs, inputs.inputsIncludingNested(input.nested_parameters));
      }
      returnInputs[inputName] = input;
    }
    return returnInputs;
  },

  areInputsFilledOut: (inputValuesByName, inputs) => {
    const inputValuesWithValue = Object.keys(inputValuesByName).filter((inputName) => {
      let currentInput = inputs[inputName];
      if (!currentInput) {
        return false;
      }

      // Mapped values must have populated options or else we don't know what to map the value to.
      if (Object.keys(inputValuesByName[inputName].inputMapping || {}).length > 0) {
        if (!(inputValuesByName[inputName].options?.length > 0)) {
          return false;
        }
      }

      if (currentInput.type === Constants.InputTypes.DATE_RANGE) {
        return (
          inputValuesByName[inputName] &&
          inputValuesByName[inputName].value &&
          inputValuesByName[inputName].value.length === 2 &&
          inputValuesByName[inputName].value[0] &&
          inputValuesByName[inputName].value[1] &&
          !inputValuesByName[inputName].error
        );
      } else if (currentInput.type === Constants.InputTypes.BOOLEAN) {
        return true;
      } else if (
        currentInput.type === Constants.InputTypes.LIST ||
        inputValuesByName[inputName].value instanceof Array
      ) {
        return (
          inputValuesByName[inputName] &&
          inputValuesByName[inputName].value &&
          inputValuesByName[inputName].value.length > 0
        );
      } else if (currentInput.type === Constants.InputTypes.DATE) {
        return (
          inputValuesByName[inputName] && inputValuesByName[inputName].value && !inputValuesByName[inputName].error
        );
      } else {
        // special handling for value of number 0 because JS considers it falsy
        if (
          inputValuesByName[inputName].value === 0 &&
          typeof inputValuesByName[inputName].value == 'number' &&
          !isNaN(inputValuesByName[inputName].value)
        ) {
          return true;
        }
        return !!inputValuesByName[inputName].value;
      }
    });
    return inputValuesWithValue.length === Object.values(inputs).length;
  },

  loadOptionsFromQuery: (
    input,
    inputValues,
    onSuccess,
    onError,
    fallback,
    beforeLoad,
    formQueryObj,
    bulkInputName = null,
  ) => {
    // Using formQueryObj allows us to load options without having to save the input first
    let queryObj = formQueryObj || input.query_obj;
    if (!queryObj || !queryObj.id) {
      return onError('Could not run query for input', input.name);
    }
    const nestedInputs = input.nested_parameters;
    let inputValuesWithBulkInput = cloneDeep(inputValues);
    // If we have a bulk input with dependent inputs, we want to only load the dependent inputs for the preview
    // presentation, which is the first bulked input value.
    if (bulkInputName && inputs.inputIsDependentOnBulkInput(input, bulkInputName)) {
      if (inputValuesWithBulkInput[bulkInputName].value && inputValuesWithBulkInput[bulkInputName].value.length > 0) {
        const firstVal = inputValuesWithBulkInput[bulkInputName].value[0];
        inputValuesWithBulkInput[bulkInputName].value = firstVal;
      }
    }
    const queryProcessor = new QueryProcessor(
      queryObj.query_string,
      nestedInputs,
      inputValuesWithBulkInput,
      queryObj.data_source,
      input.source_type,
    );
    const inputsStillRequired = queryProcessor.inputsStillRequired();
    if (inputsStillRequired.length > 0) {
      return fallback(inputsStillRequired);
    }
    let queryData = queryObj;
    if (!queryData.query_string || !queryData.soql_string) {
      queryData = {
        query_string: queryProcessor.parameterizeQueryString(),
        soql_string: queryProcessor.parameterizeQueryString(),
      };
    }
    const nestedInputValues = inputs._getNestedInputValues(nestedInputs, inputValues);
    if (beforeLoad) {
      beforeLoad(input.name);
    }
    const onResponse = (response, onComplete) => {
      if (response.data.status === 'error' || response.data?.result?.status === 'error') {
        let err = 'An unexpected error occurred';
        if (response.data.message) {
          err = response.data.message;
          onError(err, input.name);
          onComplete();
        } else if (response.data?.result?.message) {
          err = response.data.result.message;
          onError(err, input.name);
          onComplete();
        }
      } else if (response.data.status === 'success') {
        const isCached = !!response.data.cached_result;
        const result = isCached ? response.data.cached_result.result : response.data.result;
        const resultTS = isCached ? moment(response.data.cached_result.ts) : moment();
        let options = [];
        let mappedOptions = {};
        if (!isEmpty(input.input_mapping) && result?.[0]?.[0] && result?.[0]?.[1]) {
          const trimmedColumnNames = result[0].map((v) => v.toString()?.trim());
          const keyIndex = trimmedColumnNames.indexOf(Object.keys(input.input_mapping)[0].toString()?.trim());
          const valueIndex = trimmedColumnNames.indexOf(Object.values(input.input_mapping)[0].toString()?.trim());
          result.forEach((optionArray) => {
            if (optionArray[keyIndex] && optionArray[valueIndex]) {
              mappedOptions[optionArray[keyIndex].toString()] = optionArray[valueIndex].toString();
            }
          });
          options = result.map((optionArray) => {
            if (optionArray[keyIndex]) {
              return optionArray[keyIndex].toString();
            } else if (optionArray[keyIndex] === null && input.include_null_values) {
              mappedOptions[Constants.NULL_VALUE_INPUT_LABEL] = optionArray[valueIndex].toString();
              return Constants.NULL_VALUE_INPUT_LABEL;
            } else {
              return '';
            }
          });
        } else {
          options = result?.map((optionArray) => {
            if (optionArray[0] === null && input.include_null_values) {
              return Constants.NULL_VALUE_INPUT_LABEL;
            } else {
              return optionArray[0] !== null && optionArray[0] !== undefined ? optionArray[0].toString() : '';
            }
          });
        }
        options = utils.dedupeArray(options);
        if (isEmpty(nestedInputValues)) {
          if (options.includes('false') && !options.includes('true')) {
            options.push('true');
          } else if (options.includes('true') && !options.includes('false')) {
            options.push('false');
          }
        }
        options = utils.dedupeArray(options);
        if (input.include_first_row) {
          options.unshift('HEADER');
        }
        const filtered_options = options.filter((a) => a);
        onSuccess(
          input.name,
          filtered_options.slice(1),
          input.type,
          nestedInputValues,
          mappedOptions,
          result?.[0], // field names for input mapping selects
          isCached,
          resultTS,
        );
        if (!isCached) {
          onComplete();
        }
      }
    };
    const longRequest = new LongRequest('/queries', true);
    longRequest.post(
      queryData,
      onResponse,
      (err) => onError(err, input.name),
      undefined,
      undefined,
      `${queryObj.id}/run`,
    );

    return 'Loading...';
  },

  _getNestedInputValues: (nestedInputs, inputValues) => {
    const nestedInputValues = {};
    if (!isEmpty(inputValues)) {
      for (let nestedInputName in nestedInputs) {
        nestedInputValues[nestedInputName] = cloneDeep(inputValues[nestedInputName].value);
      }
    }
    return nestedInputValues;
  },

  _getUserAttributeFromInput: (inputName, user) => {
    const attributeMap = {
      MatikUser_Email: 'email',
      MatikUser_Name: 'name',
      MatikUser_Phone: 'phone',
      'MatikUser_Profile-Image': 'photo_url',
      MatikUser_Title: 'title',
    };

    const userAttributeKey = attributeMap[inputName];
    const attributeValue = user[userAttributeKey];

    return attributeValue;
  },

  getFieldFromInputType: (
    input,
    inputValues,
    inputColor,
    onChange,
    onLoadSuccess,
    onLoadFailure,
    fallback,
    beforeLoad,
    isReadOnly,
    allowRelativeDates,
    currentBulkInputName,
    isForConditionalDC,
    formRef,
  ) => {
    if (input.type === Constants.InputTypes.STRING) {
      return (
        <StringInputField
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          isReadOnly={isReadOnly}
          onLoadFailure={onLoadFailure}
          onLoadSuccess={onLoadSuccess}
          fallback={fallback}
          beforeLoad={beforeLoad}
          currentBulkInputName={currentBulkInputName}
          isForConditionalDC={isForConditionalDC}
          formRef={formRef}
          isClearable={true}
        />
      );
    }
    if (input.type === Constants.InputTypes.BOOLEAN) {
      return (
        <BooleanInputField
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          isReadOnly={isReadOnly}
          formRef={formRef}
        />
      );
    }
    if (input.type === Constants.InputTypes.DATE) {
      return (
        <DateInputField
          allowRelative={allowRelativeDates}
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          isReadOnly={isReadOnly}
          formRef={formRef}
        />
      );
    }
    if (input.type === Constants.InputTypes.DATE_RANGE) {
      return (
        <DateRangeInputField
          allowRelative={allowRelativeDates}
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          isReadOnly={isReadOnly}
          formRef={formRef}
        />
      );
    }
    if (input.type === Constants.InputTypes.LIST) {
      return (
        <ListInputField
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          onLoadFailure={onLoadFailure}
          onLoadSuccess={onLoadSuccess}
          fallback={fallback}
          beforeLoad={beforeLoad}
          isReadOnly={isReadOnly}
          currentBulkInputName={currentBulkInputName}
          isForConditionalDC={isForConditionalDC}
          formRef={formRef}
          isClearable={true}
        />
      );
    }
  },

  canInputRefreshSelectAll: (input, inputValue, currentBulkInputName = '') => {
    return (
      (input.source_type === Constants.InputSources.API || input.source_type === Constants.InputSources.QUERY) &&
      (input.type === Constants.InputTypes.LIST || input.name === currentBulkInputName) &&
      inputValue?.options?.length === inputValue?.value?.length
    );
  },

  inputIsDependentOnBulkInput: (input, bulkInputName) => {
    if (!bulkInputName) {
      return false;
    }

    let isDependent = false;
    if (input.nested_parameters && Object.keys(input.nested_parameters).length > 0) {
      for (let inputName of Object.keys(input.nested_parameters)) {
        if (inputName === bulkInputName) {
          isDependent = true;
          break;
        }
      }
    }
    return isDependent;
  },

  inputSupportsBulk: (input) => {
    return (
      input.type === Constants.InputTypes.STRING ||
      input.type === Constants.InputTypes.DATE ||
      input.type === Constants.InputTypes.LIST
    );
  },

  getBulkFieldFromInputType: (
    input,
    inputValues,
    inputColor,
    onChange,
    onLoadSuccess,
    onLoadFailure,
    fallback,
    beforeLoad,
    isReadOnly,
    currentBulkInputName,
  ) => {
    if (input.type === Constants.InputTypes.STRING || input.type === Constants.InputTypes.LIST) {
      return (
        <ListInputField
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          onLoadFailure={onLoadFailure}
          onLoadSuccess={onLoadSuccess}
          fallback={fallback}
          beforeLoad={beforeLoad}
          isReadOnly={isReadOnly}
          isBulk={true}
          currentBulkInputName={currentBulkInputName}
          isClearable={true}
        />
      );
    }
    if (input.type === Constants.InputTypes.DATE) {
      return (
        <DateListInputField
          inputColor={inputColor}
          input={input}
          inputValues={inputValues}
          onChange={onChange}
          isReadOnly={isReadOnly}
        />
      );
    }
  },

  parseDefaultValue: (defaultVal, inputType) => {
    let parsedDefaultVal = defaultVal;
    if (!parsedDefaultVal) {
      return parsedDefaultVal;
    }
    try {
      parsedDefaultVal = JSON.parse(parsedDefaultVal);
    } catch (error) {
      parsedDefaultVal = parsedDefaultVal.trim();
    }

    if (
      inputType === Constants.InputTypes.DATE_RANGE &&
      parsedDefaultVal instanceof Array &&
      !RelativeDate.isRelativeDate(parsedDefaultVal[0]) &&
      moment(parsedDefaultVal[0]).isValid() &&
      !RelativeDate.isRelativeDate(parsedDefaultVal[1]) &&
      moment(parsedDefaultVal[1]).isValid()
    ) {
      parsedDefaultVal = [moment(parsedDefaultVal[0]), moment(parsedDefaultVal[1])];
    }

    if (
      inputType === Constants.InputTypes.DATE &&
      !RelativeDate.isRelativeDate(parsedDefaultVal) &&
      moment(parsedDefaultVal).isValid()
    ) {
      parsedDefaultVal = moment(parsedDefaultVal);
    }

    if (inputType === Constants.InputTypes.LIST && parsedDefaultVal instanceof Array) {
      parsedDefaultVal = parsedDefaultVal.map((val) => {
        return val.toString();
      });
    }

    return parsedDefaultVal;
  },

  slideNumsByInputName: (templateContent, slides, allDynamicContent, offset = 0) => {
    if (!templateContent) {
      return {};
    }
    const slideNumsByInputName = {};
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      const slideNum = i + 1 + offset;
      const contentObj = templateContent.content_by_slide.filter((content) => content.slide_ids.indexOf(slide.id) > -1);
      const inputsInMatchingContent = {};
      for (const contentName in contentObj) {
        const content = contentObj[contentName];
        inputs.getInputsFromContent(content, inputsInMatchingContent);
      }
      inputs.getConditionInputsForSlide(slide, allDynamicContent, inputsInMatchingContent);
      for (const inputName in inputsInMatchingContent) {
        const input = inputsInMatchingContent[inputName];
        if (!slideNumsByInputName[input.name]) {
          slideNumsByInputName[input.name] = [];
        }
        if (slideNumsByInputName[input.name].indexOf(slideNum) < 0) {
          slideNumsByInputName[input.name].push(slideNum);
        }
      }
    }

    return slideNumsByInputName;
  },

  initInputValue: (input) => {
    let initialValue = inputs.initialValueWithDefault(input);
    return {
      value: initialValue,
      error: '',
      isLoading: false,
      options: input.source_type === Constants.InputSources.LIST ? input.source_list : undefined,
      id: input.id,
      inputMapping: input.input_mapping,
    };
  },

  initInputNullValue: (input) => {
    return {
      value: null,
      error: '',
      isLoading: false,
      options: input.source_type === Constants.InputSources.LIST ? input.source_list : undefined,
      id: input.id,
      inputMapping: input.input_mapping,
    };
  },

  initialValueWithDefault: (input) => {
    let initValue = inputs.initialValue(input);
    if (input.default_value) {
      initValue = inputs.parseDefaultValue(input.default_value, input.type);
    }
    return initValue;
  },

  initialValue: (input) => {
    let initValue =
      input.type === Constants.InputTypes.STRING && input.source_type === Constants.InputSources.LIST
        ? input.source_list[0]
        : Constants.INITIAL_VALUES_FOR_INPUT_TYPES[input.type];
    return cloneDeep(initValue);
  },

  addSlideNumsToInputs: (slideNumsByInputName, inputsInMatchingContent) => {
    for (const input in inputsInMatchingContent) {
      inputsInMatchingContent[input]['slideNums'] = slideNumsByInputName[input];
    }
    return inputsInMatchingContent;
  },

  inputsInQueryWithoutNested: (query, inputsByName, contentType = null) => {
    const inputNames = getInputNamesFromQueryString(query, contentType) || [];
    const inputs = [];
    for (const inputName of inputNames) {
      if (inputsByName[inputName]) {
        inputs.push(inputsByName[inputName]);
      }
    }
    return inputs;
  },

  updateInputMappingWithAlias: (inputMapping, returnFieldMapping, returnField, newAlias) => {
    const newInputMapping = {};
    const prevAliasedFieldName = returnFieldMapping?.[returnField] || returnField;

    Object.keys(inputMapping).forEach((mappingSourceField) => {
      const mappingDestinationField = inputMapping[mappingSourceField];

      if (prevAliasedFieldName === mappingSourceField) {
        if (newAlias) {
          newInputMapping[newAlias] = mappingDestinationField;
        } else {
          newInputMapping[returnField] = mappingDestinationField;
        }
      }
      if (prevAliasedFieldName === mappingDestinationField) {
        if (newAlias) {
          newInputMapping[mappingSourceField] = newAlias;
        } else {
          newInputMapping[mappingSourceField] = returnField;
        }
      }
    });
    return newInputMapping;
  },
};

export default inputs;
