import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { READ_STATE_VALUES } from './Constants';
import Button from '../../lib/Button';

function Notification(props) {
  const history = useHistory();
  const { renderData } = props;
  const readStateClassName =
    renderData.head.event.read_state === READ_STATE_VALUES.unread ? 'unread-state' : 'read-state';
  return (
    <div className={`item ${readStateClassName}`}>
      <div className={`icon-container ${renderData.eventType}`}>{renderData.icon}</div>
      <div className={readStateClassName}></div>
      <div className="notification-details">
        <div className="notification-message">
          <h2>{renderData.title}</h2>
          {renderData.messageBody}
        </div>
        <div>
          <Button category="secondary" onClick={(e) => renderData.primaryAction(e, history)}>
            {renderData.primaryActionText}
          </Button>
          <div
            className="notification-timestamp pts"
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={moment.unix(renderData.head.event.timestamp).format('MMM Do, YY [at] hh:mm:ss A')}
          >
            {moment.unix(renderData.head.event.timestamp).fromNow()}
          </div>
        </div>
      </div>
    </div>
  );
}

Notification.propTypes = {
  renderData: PropTypes.object,
};

export default Notification;
