import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Section, Level } from 'react-bulma-components';
import recipient_icon_small from 'images/dynamic_content_contact_small.svg';
import success_check from 'images/success_check.svg';
import unknown_icon from 'images/unknown.svg';
import TextOverflowWithTooltip from '../../shared/TextOverflowWithTooltip';
import HtmlPreview from '../../shared/htmlBuilder/HtmlPreview';
import EmailAttachmentCard from './EmailAttachmentCard';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import EmailFromModal from './EmailFromModal';
import Button from '../../lib/Button';
import Constants from 'components/Constants';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import IconPill from 'components/lib/IconPill';
import Indicator from 'components/lib/Indicator';
import Icon from 'components/lib/Icon';
function EmailSendBody(props) {
  const source = props.template.source;
  const isSubjectAdded = source.subject.length > 0;
  const areRecipientsAdded = Object.values(source.recipients).some((ele) => ele.length > 0);
  const isToFieldComplete = Boolean(source.recipients.dynamicRecipients);
  const isCcFieldComplete = Boolean(source.recipients.cc.length || typeof source.recipients.cc === 'number');
  const isBccFieldComplete = Boolean(source.recipients.bcc?.length || typeof source.recipients.bcc === 'number');
  const isFromAdded =
    [source.from.fromEmail, source.from.replyEmail, source.from.name].every((ele) => ele !== '') ||
    Boolean(source.from.senderContent);
  const [fromName, setFromName] = useState(source.from.name);
  const [fromEmail, setFromEmail] = useState(source.from.fromEmail);
  const [replyEmail, setReplyEmail] = useState(source.from.replyEmail);
  const isEndUser = props.match.path.indexOf('create') > -1;
  const ldClient = useLDClient();
  const enableDynamicSenderFeatures = ldClient?.variation('enable-dynamic-sender-features', false);

  const renderRecipientField = () => {
    const dynamicRecipientsName = props.template.slides?.[0].dynamic_content_tags?.[0];
    let ccList = null;
    if (Array.isArray(source.recipients.cc)) {
      ccList = source.recipients.cc.map((email) => (
        <span className="recipient-pill" key={email}>
          cc:&nbsp;{email}
        </span>
      ));
    }
    return (
      <div className="recipient-input-field">
        <span className="recipient-pill dynamic">
          <img src={recipient_icon_small} alt="Email recipient" />
          &nbsp;
          {`{{${dynamicRecipientsName}}}`}
        </span>
        {ccList}
      </div>
    );
  };

  const renderToField = () => {
    const dynamicRecipients = Object.values(props.allDynamicContent).filter(
      (dc) => dc.id === parseInt(source.recipients.dynamicRecipients),
    );
    const dynamicRecipientsName = dynamicRecipients.length ? dynamicRecipients[0].name : '';
    return (
      <div className="recipient-input-field">
        <IconPill iconName="recipient" iconTheme="regular" size="s" color="white" theme="square" />
        <span className="mls">{dynamicRecipientsName}</span>
      </div>
    );
  };

  const renderCopyField = (type) => {
    const fieldType = type === 'cc' ? source.recipients.ccType : source.recipients.bccType;
    const fieldValue = source.recipients[type];
    if (
      [Constants.RecipientFieldTypes.DYNAMIC_BULKED, Constants.RecipientFieldTypes.DYNAMIC_NOT_BULKED].includes(
        fieldType,
      ) &&
      fieldValue
    ) {
      const matchingContent = Object.values(props.allDynamicContent).filter((dc) => dc.id === fieldValue);
      const dynamicContentName = matchingContent.length ? matchingContent[0].name : '';
      return (
        <div className="recipient-input-field">
          <IconPill iconName="recipient" iconTheme="regular" size="s" color="white" theme="square" />
          <span className="mls">{dynamicContentName}</span>
        </div>
      );
    } else if (Array.isArray(fieldValue) && fieldValue.length) {
      return (
        <div className="recipient-input-field overflow-x-auto">
          {fieldValue.map((email) => (
            <span className="recipient-pill" key={email}>
              {email}
            </span>
          ))}
        </div>
      );
    }
  };

  const onFromFieldChange = (fieldName, fieldValue) => {
    if (fieldName === 'name') {
      setFromName(fieldValue);
    } else if (fieldName === 'replyEmail') {
      setReplyEmail(fieldValue);
    } else if (fieldName === 'fromEmail') {
      setFromEmail(fieldValue);
    }
  };

  const onFromClose = () => {
    props.closeFullScreenModal();
    setFromName(source.from.name);
    setFromEmail(source.from.fromEmail);
    setReplyEmail(source.from.replyEmail);
  };

  const sendgridInfo = props.enterprise?.integrations?.find((i) => i.name === 'sendgrid');

  let fromNameDisplay = '';
  let fromEmailDisplay = '';
  let replyEmailDisplay = '';
  const isStaticSender = !source.from.senderType || source.from.senderType === Constants.RecipientFieldTypes.STATIC;
  if (isStaticSender) {
    fromNameDisplay = fromName ? `${fromName} · ` : '';
    fromEmailDisplay = fromEmail ? `${fromEmail}` : '';
    replyEmailDisplay = replyEmail ? ` · Reply to ${replyEmail}` : '';
  } else if (source.from.senderContent) {
    const senderContent = Object.values(props.allDynamicContent).filter(
      (content) => content.id === source.from.senderContent,
    );
    fromNameDisplay = senderContent.length ? senderContent[0].name : '';
  }

  const oldSendFields = (
    <div className="email-template-body send">
      <Section className="email-form-container">
        <Level className="email-form-field">
          <Level.Side align="left" className="email-form-label">
            <img src={isSubjectAdded ? success_check : unknown_icon}></img>
            <p>Subject</p>
          </Level.Side>
          <Level.Item className="email-send-field">
            {isSubjectAdded ? (
              <TextOverflowWithTooltip className="email-form-input text-overflow-ellipsis" text={source.subject} />
            ) : (
              <div className="email-form-input">
                <span>No subject has been added yet</span>
              </div>
            )}
          </Level.Item>
        </Level>
        <Level className="email-form-field">
          <Level.Side align="left" className="email-form-label">
            <img src={areRecipientsAdded ? success_check : unknown_icon}></img>
            <p>To</p>
          </Level.Side>
          <Level.Item className="email-send-field">
            {areRecipientsAdded ? (
              renderRecipientField()
            ) : (
              <div className="email-form-input">
                <span>No recipients have been added yet</span>
              </div>
            )}
          </Level.Item>
        </Level>
        <Level className="email-form-field">
          <Level.Side align="left" className="email-form-label">
            <img src={isFromAdded ? success_check : unknown_icon}></img>
            <p>From</p>
          </Level.Side>
          <Level.Item className="email-send-field">
            {fromNameDisplay || fromEmailDisplay || replyEmailDisplay ? (
              <TextOverflowWithTooltip
                className="email-form-input text-overflow-ellipsis"
                text={fromNameDisplay + fromEmailDisplay + replyEmailDisplay}
              />
            ) : (
              <div className="email-form-input">
                <span>No from details have been added yet</span>
              </div>
            )}
          </Level.Item>
          {isEndUser && props.canEdit && isStaticSender ? (
            <Level.Side align="right">
              <Button category="category" onClick={() => props.openFullScreenModal('emailFromModal')}>
                {isFromAdded ? 'Edit From' : 'Add From'}
              </Button>
            </Level.Side>
          ) : null}
        </Level>
      </Section>
      <Section className="email-html-container">
        <HtmlPreview emailHtml={props.template.slides[0].slide_xml} hideTabs={true} previewHeight="60vh" />
        {props.attachedTemplate && (
          <Level className="email-attachments-list">
            <div>
              <Icon name="attach" size={20} />
              <span>Attachments</span>
            </div>
            <EmailAttachmentCard
              currentTemplate={props.template}
              attachedTemplate={props.attachedTemplate}
              onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
              tab="send"
            />
          </Level>
        )}
      </Section>
      <EmailFromModal
        show={props.ui.fullScreenModal?.name === 'emailFromModal'}
        onClose={onFromClose}
        onChange={onFromFieldChange}
        currentTemplate={props.template}
        name={fromName}
        fromEmail={fromEmail}
        replyEmail={replyEmail}
        onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
        isEndUser={isEndUser}
        enterprise={props.enterprise}
        sendgridInfo={sendgridInfo}
      />
    </div>
  );

  const newSendFields = (
    <div className="email-template-body send">
      <Section className="email-form-container">
        <Level className="email-form-field">
          <Level.Side align="left" className="email-form-label">
            <Indicator color={isToFieldComplete ? 'green-500' : 'red-500'} />
            <p>To</p>
          </Level.Side>
          <Level.Item className="email-send-field">
            {isToFieldComplete ? (
              renderToField()
            ) : (
              <div className="email-form-input">
                <span>No recipients have been added yet</span>
              </div>
            )}
          </Level.Item>
        </Level>
        {isCcFieldComplete && (
          <Level className="email-form-field">
            <Level.Side align="left" className="email-form-label">
              <Indicator color={isToFieldComplete ? 'green-500' : 'red-500'} />
              <p>CC</p>
            </Level.Side>
            <Level.Item className="email-send-field w-80">{renderCopyField('cc')}</Level.Item>
          </Level>
        )}
        {isBccFieldComplete && (
          <Level className="email-form-field">
            <Level.Side align="left" className="email-form-label">
              <Indicator color={isBccFieldComplete ? 'green-500' : 'red-500'} />
              <p>BCC</p>
            </Level.Side>
            <Level.Item className="email-send-field w-80">{renderCopyField('bcc')}</Level.Item>
          </Level>
        )}
        <Level className="email-form-field">
          <Level.Side align="left" className="email-form-label">
            <Indicator color={isSubjectAdded ? 'green-500' : 'red-500'} />
            <p>Subject</p>
          </Level.Side>
          <Level.Item className="email-send-field">
            {isSubjectAdded ? (
              <TextOverflowWithTooltip className="email-form-input text-overflow-ellipsis" text={source.subject} />
            ) : (
              <div className="email-form-input">
                <span>No subject has been added yet</span>
              </div>
            )}
          </Level.Item>
        </Level>
        <Level className="email-form-field">
          <Level.Side align="left" className="email-form-label">
            <Indicator color={isFromAdded ? 'green-500' : 'red-500'} />
            <p>From</p>
          </Level.Side>
          <Level.Item className="email-send-field">
            {fromNameDisplay || fromEmailDisplay || replyEmailDisplay ? (
              <TextOverflowWithTooltip
                className="email-form-input text-overflow-ellipsis"
                text={fromNameDisplay + fromEmailDisplay + replyEmailDisplay}
              />
            ) : (
              <div className="email-form-input">
                <span>No from details have been added yet</span>
              </div>
            )}
          </Level.Item>
          {isEndUser && props.canEdit ? (
            <Level.Side align="right">
              <Button category="category" onClick={() => props.openFullScreenModal('emailFromModal')}>
                {isFromAdded ? 'Edit From' : 'Add From'}
              </Button>
            </Level.Side>
          ) : null}
        </Level>
      </Section>
      <Section className="email-html-container">
        <HtmlPreview emailHtml={props.template.slides[0].slide_xml} hideTabs={true} previewHeight="60vh" />
        {props.attachedTemplate && (
          <Level className="email-attachments-list">
            <div>
              <Icon name="attach" size={20} />
              <span>Attachments</span>
            </div>
            <EmailAttachmentCard
              currentTemplate={props.template}
              attachedTemplate={props.attachedTemplate}
              onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
              tab="send"
            />
          </Level>
        )}
      </Section>
      <EmailFromModal
        show={props.ui.fullScreenModal?.name === 'emailFromModal'}
        onClose={onFromClose}
        onChange={onFromFieldChange}
        currentTemplate={props.template}
        name={fromName}
        fromEmail={fromEmail}
        replyEmail={replyEmail}
        onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
        isEndUser={isEndUser}
        enterprise={props.enterprise}
        sendgridInfo={sendgridInfo}
      />
    </div>
  );
  return <>{enableDynamicSenderFeatures ? newSendFields : oldSendFields}</>;
}

EmailSendBody.propTypes = {
  template: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  attachedTemplate: PropTypes.object,
  allDynamicContent: PropTypes.object,
  enterprise: PropTypes.object,
  match: PropTypes.object,
  closeFullScreenModal: PropTypes.func,
  openFullScreenModal: PropTypes.func,
  ui: PropTypes.object,
  canEdit: PropTypes.bool,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(withRouter(EmailSendBody));
