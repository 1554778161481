import React from 'react';
import PropTypes from 'prop-types';
import { useEditor } from '@craftjs/core';
import Icon from 'components/lib/Icon';

function HtmlBuilderToolbox({ htmlBuilderComponents }) {
  const { connectors } = useEditor();

  return (
    <div>
      <div className="toolbox">
        {htmlBuilderComponents?.map((builderComponent) => (
          <div
            key={builderComponent.label}
            ref={(ref) => connectors.create(ref, builderComponent.component)}
            className="toolbox-button"
          >
            <div className="toolbox-button-icons">
              <div className="flex">
                <Icon name="reorder_dots" size={16} theme="regular" />
              </div>
              {builderComponent.icon ? builderComponent.icon : null}
              <div className="toolbox-button-text">{builderComponent.label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

HtmlBuilderToolbox.propTypes = {
  htmlBuilderComponents: PropTypes.arrayOf(PropTypes.object),
};

export default HtmlBuilderToolbox;
