import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { cloneDeep, filter } from 'lodash';

import Constants from 'components/Constants';
import TemplateConditionGroup from './TemplateConditionGroup';
import Banner from 'components/shared/Banner';
import AsyncLoadImage from 'components/shared/AsyncLoadImage';
import { ReactComponent as Plus } from 'svg/plus.svg';
import { ReactComponent as DismissIcon } from 'images/dismiss_icon.svg';
import Button from '../../lib/Button';
import { useTemplateThumbnails } from 'lib/hooks/useTemplate';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
import SmallLoader from '../../shared/SmallLoader';

const SlideConditionsForm = ({
  currentTemplate,
  slides,
  status,
  onSlidesSelection,
  slideConditions,
  onConditionsUpdate,
  onConditionsDelete,
}) => {
  const { dynamicContentById: allDynamicContentNamesById, isLoading } = useAllDynamicContentById();

  if (isLoading) {
    return (
      <div>
        <SmallLoader />
      </div>
    );
  }

  const generateEmptyClause = () => {
    const allDynamicContent = filter(allDynamicContentNamesById, {
      dynamic_content_type: Constants.DynamicContentTypes.TEXT,
    });
    const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditions;
    return {
      dynamicContent: allDynamicContent?.[0]?.id,
      operator: supported_operators[0],
      operand: '',
    };
  };

  if (!(slideConditions?.conditions?.length > 0)) {
    slideConditions = {
      conditions: [
        {
          clauses: [generateEmptyClause()],
        },
      ],
    };
  }

  const addEmptyCondition = (e, slideConditions) => {
    e.preventDefault();
    const updatedSlideConditions = cloneDeep(slideConditions);
    if (!updatedSlideConditions.conditions) {
      updatedSlideConditions.conditions = [];
    }
    updatedSlideConditions.conditions.push({
      clauses: [generateEmptyClause()],
    });
    onConditionsUpdate(updatedSlideConditions);
  };

  const handleDeselectSlide = (slideToRemove) => {
    onSlidesSelection(slides.filter((slide) => slide.id !== slideToRemove.id));
  };

  return (
    <div>
      <SlideSelection
        className="mbm"
        slides={slides}
        currentTemplate={currentTemplate}
        status={status}
        onDeselect={handleDeselectSlide}
      />
      <ConditionsHelpInfo className="mbm" />
      {slideConditions.conditions &&
        slideConditions.conditions.map((condition, idx) => (
          <TemplateConditionGroup
            key={idx}
            condition={condition}
            conditionIndex={idx}
            conditions={slideConditions}
            deleteAllConditions={onConditionsDelete}
            onConditionsUpdate={onConditionsUpdate}
            generateEmptyClause={generateEmptyClause}
          />
        ))}
      <Form.Field className="pvm">
        <Form.Control>
          <Button category="secondary" onClick={(e) => addEmptyCondition(e, slideConditions)}>
            <Plus className="mrs" />
            Add Condition Group
          </Button>
        </Form.Control>
      </Form.Field>
    </div>
  );
};
SlideConditionsForm.propTypes = {
  currentTemplate: PropTypes.object,
  slides: PropTypes.array,
  status: PropTypes.object,
  onSlidesSelection: PropTypes.func,
  slideConditions: PropTypes.object,
  onConditionsUpdate: PropTypes.func,
  onConditionsDelete: PropTypes.func,
};
export default SlideConditionsForm;

const ConditionsHelpInfo = ({ className }) => {
  const [visible, setVisible] = useState(true);
  if (!visible) {
    return null;
  }
  return (
    <Banner
      className={className}
      bannerType="info"
      text="Your slides will be kept if any of the condition groups return true."
      onDismiss={() => setVisible(false)}
    />
  );
};
ConditionsHelpInfo.propTypes = {
  className: PropTypes.string,
};

const SlideSelection = ({ currentTemplate, status, slides, className, onDeselect }) => {
  const { data: thumbnails } = useTemplateThumbnails(currentTemplate?.id, currentTemplate?.deleted);

  const getFetchStatus = (slide) => {
    const thumbData = thumbnails?.[parseInt(slide.id)];
    return (
      thumbData && {
        data: thumbData,
        fetchState: 'fetched',
        errorMessage: '',
      }
    );
  };

  // Order slides appropriately
  slides = slides.toSorted((a, b) => a.slide_idx - b.slide_idx);

  return (
    <div className={`${className} slide-selector`}>
      <div>
        <span>Selected Slides</span>{' '}
        <span className="mlxs tag has-light-gray-border is-rounded has-background-white">{slides.length}</span>
      </div>
      <div className="is-flex gap-medium overflow-h-auto pvs">
        {slides.map((slide) => (
          <div key={slide.id}>
            <div className="is-relative">
              <AsyncLoadImage
                fetchUrl={'unsupported'}
                fetchStatus={getFetchStatus(slide)}
                isBulk={true}
                imageClass="async-rounded has-light-gray-border"
                height="76px"
                width="136px"
                status={status}
              />
              {slides.length > 1 && ( // don't let the user de-select all the slides
                <a
                  onClick={() => onDeselect(slide)}
                  role="button"
                  className="overlay-button is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <div>
                    <DismissIcon className="w-5 h-5" />
                  </div>
                  <div className="is-size-7">Remove from set</div>
                </a>
              )}
            </div>
            <div className="has-text-centered">{slide.slide_idx + 1}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
SlideSelection.propTypes = {
  className: PropTypes.string,
  currentTemplate: PropTypes.object,
  status: PropTypes.object,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slide_idx: PropTypes.number,
    }),
  ).isRequired,
  onDeselect: PropTypes.func.isRequired,
};
