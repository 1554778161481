import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../lib/Icon';

const chartSubTypes = {
  column: {
    stacked: {
      id: 0,
      name: 'Stacked',
      icon: 'chart_column_stacked',
      value: 'stacked',
    },
    stacked100: {
      id: 1,
      name: 'Stacked 100%',
      icon: 'chart_column_stacked_100',
      value: 'stacked100',
    },
    notStacked: {
      id: 2,
      name: 'Not Stacked',
      icon: 'chart_column_not_stacked',
      value: 'notstacked',
    },
  },
  bar: {
    stacked: {
      id: 0,
      name: 'Stacked',
      icon: 'chart_bar_stacked',
      value: 'stacked',
    },
    stacked100: {
      id: 1,
      name: 'Stacked 100%',
      icon: 'chart_bar_stacked_100',
      value: 'stacked100',
    },
    notStacked: {
      id: 2,
      name: 'Not Stacked',
      icon: 'chart_bar_not_stacked',
      value: 'notstacked',
    },
  },
  line: {
    normal: {
      id: 0,
      name: 'Normal',
      icon: 'chart_line',
    },
  },
  area: {
    stacked: {
      id: 0,
      name: 'Stacked',
      icon: 'chart_area_stacked',
      value: 'stacked',
    },
    stacked100: {
      id: 1,
      name: 'Stacked 100%',
      icon: 'chart_area_stacked_100',
      value: 'stacked100',
    },
    notstacked: {
      id: 2,
      name: 'Normal',
      icon: 'chart_area_not_stacked',
      value: 'notstacked',
    },
  },
  pie: {
    normal: {
      id: 0,
      name: 'Normal',
      icon: 'chart_pie',
    },
  },
  donut: {
    normal: {
      id: 0,
      name: 'Normal',
      icon: 'chart_donut',
    },
  },
};

function ChartSubTypeSelect({ chartOptions, chartType, onTypeSelect }) {
  const [activeId, setActiveId] = useState(0);

  useEffect(() => {
    if (chartType === 'pie' || chartType === 'donut' || chartType === 'line') {
      // not applicable
      setActiveId(0);
    } else if (chartOptions?.scales?.x?.stacked) {
      if (chartOptions?.plugins?.stacked100?.enable) {
        // stacked100
        setActiveId(1);
      } else {
        // stacked
        setActiveId(0);
      }
    } else {
      // notstacked
      setActiveId(2);
    }
  }, [chartOptions, chartType]);

  const onSubTypeSelect = (e, subType) => {
    e.preventDefault();
    setActiveId(subType.id);
    onTypeSelect(subType);
  };

  let subTypesToRender = [];
  for (let key in chartSubTypes[chartType]) {
    subTypesToRender.push(chartSubTypes[chartType][key]);
  }

  return (
    <div className="mt-2 mb-2">
      {subTypesToRender.map((subType) => {
        return (
          <div
            key={subType.icon}
            className={`rounded-md border ${
              activeId === subType.id ? 'border-matik-green' : 'border-grey-300'
            } inline-block cursor-pointer mr-2 pt-1 pr-1 pl-1`}
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={subType.name}
            onClick={(e) => onSubTypeSelect(e, subType)}
          >
            <Icon name={subType.icon} size={48} theme="regular" />
          </div>
        );
      })}
    </div>
  );
}

ChartSubTypeSelect.propTypes = {
  chartOptions: PropTypes.object,
  chartType: PropTypes.string,
  onTypeSelect: PropTypes.func,
};

export default ChartSubTypeSelect;
