import React, { Component } from 'react';
import AuthPage from './AuthPage';
import { Auth } from '../../lib/auth';
import API from '../../lib/api';
import LoginForm from './LoginForm';
import { Card, Form, Heading } from 'react-bulma-components';
import SmallLoader from '../shared/SmallLoader';
import matik_logo_black from '../../images/matik_logo_black.svg';
import Button from '../lib/Button';

class OAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      grant: null,
      client: null,
      oauthErrorMessage: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    const isLoggedIn = Auth.isLoggedIn();
    const urlParams = new URLSearchParams(window.location.search);
    if (isLoggedIn) {
      this.loggedInRequests();
    } else {
      const clientId = urlParams.get('client_id');
      API.get(`/clients/${clientId}/restricted/`, (client) => {
        this.setState({ isLoading: false, client: client.data });
      });
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <AuthPage>
          <Card className="auth-card">
            <SmallLoader />
          </Card>
        </AuthPage>
      );
    }

    const isLoggedIn = Auth.isLoggedIn();
    let body = '';
    if (this.state.oauthErrorMessage) {
      body = this.renderError();
    } else if (isLoggedIn) {
      body = this.renderGrant();
    } else {
      body = this.renderLogin();
    }
    return <AuthPage>{body}</AuthPage>;
  }

  renderError() {
    return (
      <Card className="auth-card">
        <Form.Field>
          <Heading size={4} className="has-text-centered">
            Error: {this.state.oauthErrorMessage}
          </Heading>
        </Form.Field>
      </Card>
    );
  }

  renderLogin() {
    return (
      <LoginForm
        onLoginSuccess={this.onLoginSuccess}
        loginSubtitle={`Grant Access to ${this.state.client.client_name} by logging in to Matik`}
        relayState={window.location.href}
      />
    );
  }

  renderGrant() {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id');
    const redirectUri = urlParams.get('redirect_uri');
    const scope = urlParams.get('scope');
    const state = urlParams.get('state');
    return (
      <React.Fragment>
        <div className="auth-logo">
          <img src={matik_logo_black} alt="matik logo" height="40px" />
        </div>
        {this.state.grant && (
          <Card className="auth-card">
            <form method="POST" action={API._generateExternalUrlFromRelativeUrl('/oauth/authorize/')} className="mtl">
              <Form.Field className="phl">
                <Heading size={4} className="has-text-centered">
                  Allow {this.state.grant.client.client_name} to access Matik as a {this.state.grant.request.scope} on
                  your behalf
                </Heading>
              </Form.Field>
              <Form.Field>
                <Heading size={5} className="has-text-centered">
                  Logged in as {this.state.currentUser.name}
                </Heading>
              </Form.Field>
              <Form.Field kind="group" className="mtl is-grouped-centered">
                <Form.Control>
                  <Button type="submit" name="approve">
                    Grant Access
                  </Button>
                </Form.Control>
                <Form.Control>
                  <Button category="secondary" type="submit" name="deny">
                    Deny Access
                  </Button>
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <input type="hidden" name="client_id" value={clientId} />
                <input type="hidden" name="redirect_uri" value={redirectUri} />
                <input type="hidden" name="scope" value={scope} />
                <input type="hidden" name="response_type" value="code" />
                <input type="hidden" name="state" value={state} />
                <input type="hidden" name="t" value={this.state.grant.t} />
              </Form.Field>
            </form>
          </Card>
        )}
      </React.Fragment>
    );
  }

  loggedInRequests = () => {
    this.setState({ isLoading: true });
    API.getMultiple(
      ['/users/me/', `/clients/validate/${window.location.search}`],
      (usersResponse, clientsResponse) => {
        this.setState({ isLoading: false, currentUser: usersResponse.data, grant: clientsResponse.data });
      },
      (err) => {
        if (err.response && err.response.data) {
          if (err.response.data.message === 'Invalid scope') {
            const urlParams = new URLSearchParams(window.location.search);
            this.setState({ oauthErrorMessage: `Invalid scope ${urlParams.get('scope')}`, isLoading: false });
          } else if (err.response.data.message === 'Client not found') {
            this.setState({ oauthErrorMessage: 'Client not found', isLoading: false });
          } else if (err.response.data.status === 'error') {
            this.setState({ oauthErrorMessage: err.response.data.message, isLoading: false });
          } else {
            this.setState({ oauthErrorMessage: 'Something went wrong', isLoading: false });
          }
        } else {
          this.setState({ oauthErrorMessage: 'Something went wrong', isLoading: false });
        }
      },
    );
  };

  onLoginSuccess = (user) => {
    API.track('successful_login');
    this.loggedInRequests();
    this.setState({ currentUser: user });
  };
}

OAuth.propTypes = {};

export default OAuth;
