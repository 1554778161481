import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';

function BorderInput({ section, borderState, onUpdateBorderProps, disabledButtons, disabledTooltip }) {
  const calculateNewBorderState = (borderType, sectionState) => {
    let newState = { ...sectionState };

    const resetBorders = {
      all: false,
      inside: false,
      outside: false,
      horizontal: false,
      vertical: false,
      top: false,
      bottom: false,
      left: false,
      right: false,
    };

    switch (borderType) {
      case 'all':
        if (sectionState.all) {
          newState = { ...sectionState, all: false };
        } else {
          newState = { ...resetBorders, all: true };
        }
        break;
      case 'inside':
        newState = {
          ...resetBorders,
          inside: !sectionState.inside,
          horizontal: false,
          vertical: false,
          outside: sectionState.outside,
          top: sectionState.top,
          right: sectionState.right,
          bottom: sectionState.bottom,
          left: sectionState.left,
        };
        break;
      case 'outside':
        newState = {
          ...resetBorders,
          outside: !sectionState.outside,
          top: false,
          bottom: false,
          left: false,
          right: false,
          inside: sectionState.inside,
          horizontal: sectionState.horizontal,
          vertical: sectionState.vertical,
        };
        break;
      case 'horizontal':
        newState = {
          ...sectionState,
          horizontal: !sectionState.horizontal,
          inside: false,
          all: false,
          vertical: sectionState.vertical,
          outside: sectionState.outside,
          top: sectionState.top,
          right: sectionState.right,
          bottom: sectionState.bottom,
          left: sectionState.left,
        };
        break;
      case 'vertical':
        newState = {
          ...resetBorders,
          vertical: !sectionState.vertical,
          inside: false,
          all: false,
          horizontal: sectionState.horizontal,
          outside: sectionState.outside,
          top: sectionState.top,
          right: sectionState.right,
          bottom: sectionState.bottom,
          left: sectionState.left,
        };
        break;
      case 'top':
      case 'bottom':
      case 'left':
      case 'right':
        newState = { ...sectionState, [borderType]: !sectionState[borderType], outside: false, all: false };
        break;
      default:
        newState = { ...sectionState };
    }

    return newState;
  };

  const onUpdateBorderState = (borderType, section) => {
    const sectionState = borderState[section];
    const newState = calculateNewBorderState(borderType, sectionState);

    onUpdateBorderProps(newState, section);
  };

  const isDisabled = (buttonName) => {
    return disabledButtons.includes(buttonName);
  };

  return (
    <>
      <div className="border-icons">
        <div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].all ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('all', section)}
            >
              <Icon name="table_border_all" size={16} theme="regular" />
            </div>
          </div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].top ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('top', section)}
            >
              <Icon name="table_border_top" size={16} theme="regular" />
            </div>
          </div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].left ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('left', section)}
            >
              <Icon name="table_border_left" size={16} theme="regular" />
            </div>
          </div>
        </div>
        <div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].inside ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('inside', section)}
            >
              <Icon name="table_border_inside" size={16} theme="regular" />
            </div>
          </div>
          <div data-tooltip-id="matik-tooltip" data-tooltip-content={isDisabled('horizontal') ? disabledTooltip : ''}>
            <div className="border-button-container">
              <div
                className={`border-button ${borderState[section].horizontal ? 'selected' : ''} ${
                  isDisabled('horizontal') ? 'disabled' : ''
                }`}
                onClick={() => (isDisabled('horizontal') ? null : onUpdateBorderState('horizontal', section))}
              >
                <Icon name="table_border_horizontal" size={16} theme="regular" />
              </div>
            </div>
          </div>
          <div data-tooltip-id="matik-tooltip" data-tooltip-content={isDisabled('vertical') ? disabledTooltip : ''}>
            <div className="border-button-container">
              <div
                className={`border-button ${borderState[section].vertical ? 'selected' : ''} ${
                  isDisabled('vertical') ? 'disabled' : ''
                }`}
                onClick={() => (isDisabled('vertical') ? null : onUpdateBorderState('vertical', section))}
              >
                <Icon name="table_border_vertical" size={16} theme="regular" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].outside ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('outside', section)}
            >
              <Icon name="table_border_outside" size={16} theme="regular" />
            </div>
          </div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].bottom ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('bottom', section)}
            >
              <Icon name="table_border_bottom" size={16} theme="regular" />
            </div>
          </div>
          <div className="border-button-container">
            <div
              className={`border-button ${borderState[section].right ? 'selected' : ''}`}
              onClick={() => onUpdateBorderState('right', section)}
            >
              <Icon name="table_border_right" size={16} theme="regular" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

BorderInput.propTypes = {
  section: PropTypes.string,
  borderState: PropTypes.object,
  onUpdateBorderProps: PropTypes.func,
  disabledButtons: PropTypes.array,
  disabledTooltip: PropTypes.string,
};

export default BorderInput;
