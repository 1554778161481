import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../shared/modal/Modal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';

class ClientSecretModal extends Component {
  render() {
    return (
      <Modal
        onClose={this.props.onClose}
        show={this.props.ui.modal?.name === 'clientSecretModal'}
        title="Client Secret"
        showDefaultFooter={true}
        primaryButtonText="Close"
        primaryButtonOnClick={this.props.onClose}
      >
        <div>
          <p>Here is your client secret. Copy it to a secure location because we won&apos;t show it again</p>
          <p className="ptm">{this.props.secret}</p>
        </div>
      </Modal>
    );
  }
}

ClientSecretModal.propTypes = {
  onClose: PropTypes.func,
  secret: PropTypes.string,
  show: PropTypes.bool,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps)(ClientSecretModal);
