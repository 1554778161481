import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import { Select } from '../../shared/FormSelect';
import { remove } from 'lodash';

class AnalyticsUserSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      users: [],
    };
  }

  componentDidMount() {
    API.get(
      '/enterprises/users/',
      (response) => {
        this.setState({ users: response.data, isLoading: false });
      },
      API.defaultError,
    );
  }

  render() {
    return (
      <Select
        id="analytics-user-selector"
        className="analytics-dropdown"
        isMulti={true}
        isSearchable={true}
        onChange={this.onUserSelect}
        name="user"
        value={this.props.selectedUsers}
        classNamePrefix="matik-select"
        options={this.state.users.map((user) => ({ label: user.name, value: user.id }))}
        isLoading={this.state.isLoading}
        placeholder="Choose a User"
      />
    );
  }

  onUserSelect = (obj, action) => {
    const updatedUsers = this.props.selectedUsers.slice();
    if (action.action === 'select-option') {
      updatedUsers.push(action.option);
      this.props.updateQueryParams({ users: updatedUsers });
    } else if (action.action === 'select-all-options') {
      updatedUsers.push(...action.option);
      this.props.updateQueryParams({ users: updatedUsers });
    } else if (action.action === 'remove-value') {
      remove(updatedUsers, (el) => {
        return action.removedValue.value === el.value;
      });
      this.props.updateQueryParams({ users: updatedUsers });
    } else if (action.action === 'clear') {
      this.props.updateQueryParams({ users: [] });
    }
  };
}

AnalyticsUserSelector.propTypes = {
  selectedUsers: PropTypes.array,
  updateQueryParams: PropTypes.func,
};

export default AnalyticsUserSelector;
