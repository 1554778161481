import React from 'react';
import { PresentationContext } from './PresentationContext';

export default function withPresentationContextConsumer(Component) {
  return function contextComponent(props) {
    return (
      <PresentationContext.Consumer>
        {(presentationContext) => <Component {...props} presentationContext={presentationContext} />}
      </PresentationContext.Consumer>
    );
  };
}
