import React from 'react';

export const PresentationContext = React.createContext({
  regenerate: false,
  presentationToRegenerate: {},
  regenerateTemplateChanged: false,
  overrideConditions: false,
  setRegenerate: () => {},
  setPresentationToRegenerate: () => {},
  setRegenerateTemplateChanged: () => {},
  getInputValues: () => {},
  getSelectedSlides: () => {},
  clearPresentationContext: () => {},
  setOverrideConditions: () => {},
});
