import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NameTemplateModalBody from 'components/shared/templates/NameTemplateModalBody';
import Modal from 'components/shared/modal/Modal';

function NameTemplateModal(props) {
  const [primaryButtonOnClick, setPrimaryButtonOnClick] = useState();
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(true);

  return (
    <Modal
      show={props.show}
      title="Generated Presentation Name"
      onClose={props.onClose}
      showDefaultFooter={true}
      footerIsSticky={false}
      primaryButtonText="Save"
      primaryButtonOnClick={primaryButtonOnClick}
      primaryButtonDisabled={primaryButtonDisabled}
      width={700}
    >
      <NameTemplateModalBody
        orderedInputs={props.orderedInputs}
        template={props.template}
        onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
        setPrimaryButtonDisabled={setPrimaryButtonDisabled}
        setPrimaryButtonOnClick={setPrimaryButtonOnClick}
      />
    </Modal>
  );
}

export default NameTemplateModal;

NameTemplateModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  orderedInputs: PropTypes.array,
  template: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
};
