import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';

function getScrollableParents(element) {
  const scrollable = [];
  if (element instanceof HTMLElement) {
    const getStyle = (el, prop) => window.getComputedStyle(el, null).getPropertyValue(prop);
    const isScrollable = (el) => {
      let overflow = [getStyle(el, 'overflow'), getStyle(el, 'overflow-y'), getStyle(el, 'overflow-x')];
      return overflow.indexOf('auto') > -1 || overflow.indexOf('scroll') > -1;
    };
    if (isScrollable(element)) {
      scrollable.push(element);
    }
    scrollable.push(...getScrollableParents(element.parentElement));
  }
  return scrollable;
}

const stopPropagation = (e) => e.stopPropagation();

/**
 * Low-level component that pops up its child components positioned around a given anchor/trigger.
 * It will attempt to automatically position the pop up such that it doesn't overflow the viewport.
 */
const PopupMenu = React.forwardRef(
  (
    { className, trigger, anchorRef: externalAnchorRef, children, position = 'y', offset = 4, isManualClose },
    forwardedRef,
  ) => {
    const [isVisible, setVisible] = useState(false);

    const menuRef = useRef();
    const internalTriggerRef = useRef();

    const anchorRef = externalAnchorRef || internalTriggerRef;

    // Parents of this component can pass in a ref if they want to explicitly control the menu.
    // e.g. a menu item was selected -> close the menu
    useImperativeHandle(
      forwardedRef,
      () => ({
        open: () => setVisible(true),
        close: () => setVisible(false),
        toggle: () => setVisible(!isVisible),
        isOpen: isVisible,
      }),
      [isVisible, setVisible],
    );

    // Since we're menu is positioned as a "fixed" element, we have to move it if a parent element scrolled or resized.
    useEffect(() => {
      const scrollListener = () => {
        relocateMenu();
      };
      const resizeObserver = new ResizeObserver(relocateMenu);

      const scrollers = getScrollableParents(anchorRef.current);
      scrollers.forEach((element) => {
        element.addEventListener('scroll', scrollListener);
        resizeObserver.observe(element);
      });
      return () => {
        scrollers.forEach((element) => {
          element.removeEventListener('scroll', scrollListener);
          resizeObserver.unobserve(element);
        });
      };
    }, [offset]);

    useEffect(() => {
      const menu = menuRef.current;
      if (!menu) {
        return;
      }

      if (isVisible) {
        menu.style.display = 'inline-block';
      } else {
        menu.style.display = 'none';
      }
      relocateMenu();

      // Reposition the menu any time the menu itself changes size
      const observer = new ResizeObserver(relocateMenu);
      observer.observe(menu);
      return () => {
        observer.unobserve(menu);
      };
    }, [position, isVisible, offset]);

    // Close the menu if the user clicks on something else
    useOnClickOutside(
      [menuRef, anchorRef],
      () => {
        if (!isManualClose) {
          setVisible(false);
        }
      },
      [setVisible, isManualClose],
    );

    const relocateMenu = () => {
      const menu = menuRef.current;
      if (!menu) {
        return;
      }

      const anchor = anchorRef.current;

      const anchorBounds = anchor.getBoundingClientRect();
      if (anchorBounds.top === 0 && anchorBounds.left === 0 && anchorBounds.width === 0 && anchorBounds.width === 0) {
        // The anchor is "display: none" so we don't really know where to reposition the menu.
        // Better to do nothing than position it top/left = 0.
        return;
      }

      if (position === 'x') {
        // Menu should appear to the left or right of the trigger. Prefer right, flowing down.
        menu.style.top = anchorBounds.top + 'px';
        menu.style.bottom = 'auto';
        menu.style.left = anchorBounds.right + offset + 'px';
        menu.style.right = 'auto';

        let menuBounds = menu.getBoundingClientRect();
        if (menuBounds.bottom > window.visualViewport.height) {
          // Menu overflows the page bottom, position at the bottom of the trigger and flow up, instead
          if (anchorBounds.bottom - menuBounds.height < 0) {
            // Flowing up, we'd overflow the top, so lock it to the top
            menu.style.top = '0px';
            menu.style.bottom = 'auto';
          } else {
            menu.style.top = 'auto';
            menu.style.bottom = window.visualViewport.height - anchorBounds.bottom + 'px';
          }
        }

        if (menuBounds.right > window.visualViewport.width) {
          // Menu overflows the container to the right, position to the left of the trigger
          menu.style.left = 'auto';
          menu.style.right = window.visualViewport.width - anchorBounds.left + offset + 'px';

          if (anchorBounds.left - offset - menuBounds.width < 0) {
            // Positioning to the left would overflow, so lock it to the left edge
            menu.style.left = '0px';
            menu.style.right = 'auto';
          } else {
            menu.style.left = 'auto';
            menu.style.right = window.visualViewport.width - anchorBounds.left + offset + 'px';
          }
        }
      } else {
        // Menu should appear to the top or bottom of the trigger. Prefer bottom, flowing right
        menu.style.top = anchorBounds.bottom + offset + 'px';
        menu.style.bottom = 'auto';
        menu.style.left = anchorBounds.left + 'px';
        menu.style.right = 'auto';

        let menuBounds = menu.getBoundingClientRect();
        if (menuBounds.bottom > window.visualViewport.height) {
          // Menu overflows the page bottom, position at the top of the trigger and flow up, instead
          if (anchorBounds.top - offset - menuBounds.height < 0) {
            // Flowing up, we'd overflow the top, so lock it to the top
            menu.style.top = '0px';
            menu.style.bottom = 'auto';
          } else {
            menu.style.top = 'auto';
            menu.style.bottom = window.visualViewport.height - anchorBounds.top + offset + 'px';
          }
        }

        if (menuBounds.right > window.visualViewport.width) {
          // Menu overflows the container to the right, position to the right of the trigger and flow left
          if (anchorBounds.right - menuBounds.width < 0) {
            // Positioning to the left would overflow, so lock it to the left edge
            menu.style.left = '0px';
            menu.style.right = 'auto';
          } else {
            menu.style.left = 'auto';
            menu.style.right = window.visualViewport.width - anchorBounds.right + 'px';
          }
        }
      }
    };

    const handleTriggerClick = (e) => {
      e.stopPropagation();
      if (isVisible) {
        if (!isManualClose) {
          setVisible(false);
        }
      } else {
        setVisible(true);
      }
    };

    return (
      <div>
        {!externalAnchorRef && (
          <button
            ref={internalTriggerRef}
            className={className || 'inline-block text-left'}
            onClick={handleTriggerClick}
            type="button"
          >
            {trigger}
          </button>
        )}
        {isVisible &&
          createPortal(
            <div
              // don't let mouse clickish events on popups bubble up outside of the popup
              onClick={stopPropagation}
              onMouseDown={stopPropagation}
              onMouseUp={stopPropagation}
              onTouchStart={stopPropagation}
              ref={menuRef}
              className="fixed z-[110] max-h-screen"
              /* Start with "display: none" otherwise we see the menu move from the initial 
              render position to its calculated position after `relocateMenu()` runs. */
              style={{ display: 'none' }}
            >
              {children}
            </div>,
            document.getElementById('popup-portal'),
          )}
      </div>
    );
  },
);
PopupMenu.displayName = 'PopupMenu';
PopupMenu.propTypes = {
  className: PropTypes.string,
  /** Element that will serve as the anchor and trigger. If passing an element in here,
   * do not use the `anchorRef` prop.
   */
  trigger: PropTypes.node,
  /** If an another item (rendered outside of this component) is triggering the menu,
   * this ref'd element will be the anchor used to position the menu. If this is specified,
   * the `trigger` prop will be ignored. Note this anchor ref can be shared between multiple
   * menus if, for example, a single anchor component wants to trigger multiple menus.
   */
  anchorRef: PropTypes.any,
  children: PropTypes.any,
  /** `"x"` to position the menu to the left/right of the anchor. `"y"` to position it above/below. */
  position: PropTypes.oneOf(['x', 'y']),
  /** Number of pixels to offset the position of the menu from the edge of the anchor. */
  offset: PropTypes.number,
  /** When false/missing, clicking outside of the menu will close it automatically.
   * When true, it must be closed via ref.close();
   */
  isManualClose: PropTypes.bool,
};

export default PopupMenu;
