import React, { Component } from 'react';
import { Section } from 'react-bulma-components';
import PropTypes from 'prop-types';
import Modal from './modal/Modal';
import Avatar from 'react-avatar-edit';
import API from '../../lib/api';
import utils from '../../lib/utils';
import { MAlert } from './Alerts';
class UpdateAvatarModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      preview: null,
      src: '',
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.onModalClose}
        title="Update User Avatar"
        width={715}
        showDefaultFooter={true}
        footerIsSticky={false}
        primaryButtonText="Update"
        primaryButtonOnClick={this.onAvatarUpdate}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={this.onModalClose}
      >
        <Section className="has-text-centered" style={{ minHeight: '423px' }}>
          <Avatar width={571} height={295} onCrop={this.onCrop} onClose={this.onClose} src={this.state.src} />
        </Section>
      </Modal>
    );
  }

  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = (preview) => {
    this.setState({ preview });
  };

  onModalClose = (e) => {
    e.preventDefault();
    this.setState({ preview: null, src: '' });
    this.props.onClose();
  };

  onAvatarUpdate = (e) => {
    e.preventDefault();
    if (!this.state.preview) {
      MAlert('Select an image first.', 'Error', 'error');
      return;
    }

    this.setState({ isLoading: true });

    API.put(
      '/users/me/',
      {},
      (response) => {
        this.setState({ isLoading: false, preview: null, src: '' });
        this.props.onUserAvatarUpdate(response.data);
      },
      this.onUpdateError,
      utils.b64ToBlob(this.state.preview),
    );
  };

  onUpdateError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };
}

UpdateAvatarModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onUserAvatarUpdate: PropTypes.func,
  ui: PropTypes.object,
};

export default UpdateAvatarModal;
