import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import WithFilterListHeader from 'components/shared/WithFilterListHeader';
import FullPageList from 'components/shared/FullPageList';
import edit_icon from 'svg/edit.svg';
import Icon from 'components/lib/Icon';
import { mapUiStateToProps } from 'redux/ui/stateMappers';

const SearchableListWithFilterHeader = WithInstantSearch(WithFilterListHeader(FullPageList));

function CollapsibleDynamicContentSelect({
  isReadOnly,
  collapsedComponent,
  currentEntity,
  columns,
  onRowClick,
  isExpanded,
  onExpanded,
  entities,
  ...props
}) {
  const dispatch = useDispatch();
  const { closeSidepane } = mapDispatchToProps(dispatch);

  useEffect(() => {
    return () => {
      closeSidepane('conditional-content-sidepane');
    };
  }, []);

  if (isExpanded && !isReadOnly) {
    return (
      <SearchableListWithFilterHeader
        {...props}
        entities={entities}
        columns={columns}
        onRowClick={onRowClick}
        isSearchable={true}
      />
    );
  } else if (collapsedComponent) {
    return collapsedComponent;
  } else {
    return (
      <CollapsedDynamicContentSelect
        currentEntity={currentEntity}
        isReadOnly={isReadOnly}
        columns={columns}
        onExpand={() => onExpanded(true)}
      />
    );
  }
}
CollapsibleDynamicContentSelect.propTypes = {
  isReadOnly: PropTypes.bool,
  collapsedComponent: PropTypes.node,
  currentEntity: PropTypes.object,
  entities: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  isExpanded: PropTypes.bool,
  onExpanded: PropTypes.func,
};

export default WithLoadingAndEmpty(CollapsibleDynamicContentSelect);

function CollapsedDynamicContentSelect({ currentEntity, isReadOnly, columns, onExpand }) {
  const ref = useRef();

  const { ui } = useSelector((state) => mapUiStateToProps(state));
  const dispatch = useDispatch();
  const { openSidepane } = mapDispatchToProps(dispatch);

  const handleHeaderClick = (e) => {
    e.preventDefault();
    if (!isReadOnly) {
      onExpand();
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (ui.sidepane) {
      window.open(`/dynamic_content/${currentEntity.id}`, '_blank').focus();
    } else {
      openSidepane({ ...currentEntity, entityType: 'dynamic_content' }, 'conditional-content-sidepane');
    }
  };

  const columnHelper = createColumnHelper();

  const createCollapsedColumns = () => {
    const downChevronCell = isReadOnly
      ? () => <span />
      : () => (
          <>
            <button type="button" className="is-flex is-vertical-centered icon-button" onClick={handleEditClick}>
              <img
                src={edit_icon}
                width="16px"
                alt="Parameter Type"
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Edit"
                className="edit-condition mrm"
                ref={ref}
              />
            </button>
            <button
              type="button"
              className="is-flex is-vertical-centered icon-button cusror-pointer"
              data-tooltip-content="Select"
              data-tooltip-id="matik-tooltip"
            >
              <Icon name="chevron_down" />
            </button>
          </>
        );

    const collapsedColumns = columns
      .filter((column) => column.accessorKey !== 'updated_on')
      .map((column) => {
        const { ...rest } = column;
        return columnHelper.accessor(column.accessorKey, {
          ...rest,
          header: () => null, // Remove header
        });
      });

    collapsedColumns.push(
      columnHelper.display({
        id: 'down_chevron',
        cell: downChevronCell,
        meta: { justifyContent: 'flex-end' },
      }),
    );

    return collapsedColumns;
  };
  const collapsedColumns = createCollapsedColumns();

  return (
    <FullPageList entitiesToRender={[currentEntity]} columns={collapsedColumns} onHeaderClick={handleHeaderClick} />
  );
}
CollapsedDynamicContentSelect.propTypes = {
  isReadOnly: PropTypes.bool,
  currentEntity: PropTypes.object,
  columns: PropTypes.array,
  onExpand: PropTypes.func,
};
