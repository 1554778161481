import React, { Component } from 'react';
import PropTypes from 'prop-types';
import presentation_illustration from '../../../images/presentation_illustration_80x80.svg';

class PresentationNarrativeCard extends Component {
  render() {
    return (
      <div className="narrative-card">
        <img src={presentation_illustration} className="narrative-card-icon" />
        <div className="narrative-card-center">
          <p className="narrative-card-title">Presentation</p>
          <p className="narrative-card-subtitle">Add a Google Slides or Microsoft PowerPoint presentation</p>
        </div>
        <button className="button is-secondary is-fullwidth" onClick={this.props.onPresentationNarrativeClick}>
          Add Presentation Template
        </button>
      </div>
    );
  }
}

PresentationNarrativeCard.propTypes = {
  onPresentationNarrativeClick: PropTypes.func,
};

export default PresentationNarrativeCard;
