import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Modal from '../shared/modal/Modal';
import API from '../../lib/api';
import { closeModal } from '../../redux/ui/action';

function HighspotIntegrationModal(props) {
  const [{ api_url, key, secret }, setState] = useState(props.highspotIntegration?.auth || {});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateIntegration = (e) => {
    e.preventDefault();
    const data = {
      api_url,
      key,
      secret,
    };
    const api_method = props.highspotIntegration ? 'put' : 'post';
    setIsLoading(true);
    API[api_method](
      '/enterprise_integrations/highspot/',
      data,
      (response) => {
        setIsLoading(false);
        props.onUpdateIntegration(response.data);
        dispatch(closeModal());
      },
      (err) => {
        setIsLoading(false);
        API.defaultError(err);
      },
    );
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      show={ui.modal?.name === props.modal_name}
      title="Manage Highspot Integration"
      showDefaultFooter={true}
      onClose={onClose}
      primaryButtonText="Save"
      primaryButtonOnClick={updateIntegration}
      primaryButtonLoading={!!isLoading}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonLoading={!!isLoading}
    >
      <Form.Field>
        <Form.Label>API URL</Form.Label>
        <Form.Control>
          <input
            className="input"
            type="text"
            name="api_url"
            id="highspot-api-url"
            value={api_url || ''}
            onChange={onChange}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>API Key</Form.Label>
        <Form.Control>
          <input className="input" type="text" name="key" id="highspot-api-url" value={key || ''} onChange={onChange} />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>API Secret</Form.Label>
        <Form.Control>
          <input
            className="input"
            type="text"
            name="secret"
            id="highspot-api-url"
            value={secret || ''}
            onChange={onChange}
          />
        </Form.Control>
      </Form.Field>
    </Modal>
  );
}

HighspotIntegrationModal.propTypes = {
  highspotIntegration: PropTypes.object,
  modal_name: PropTypes.string,
  onUpdateIntegration: PropTypes.func,
};

export default HighspotIntegrationModal;
