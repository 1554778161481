import { React } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Hero, Heading, Form } from 'react-bulma-components';
import { isEmpty } from 'lodash';
import utils from 'lib/utils';
import data_source_login_icon from 'images/data_source_login.svg';
import { openModal } from 'redux/ui/action';
import DataSourceLoginModal from 'components/consumer/DataSourceLoginModal';
import Button from '../lib/Button';
import { useTemplateContent } from 'lib/hooks/useTemplate';

const DataSourceLoginMessage = () => {
  const dispatch = useDispatch();

  return (
    <Hero>
      <Hero.Body className="has-text-centered">
        <Heading size={5} subtitle>
          <img width="180px" src={data_source_login_icon} alt="Data Source Login" />
          <p className="missing-data-source-title">Missing Data Source Log In</p>
        </Heading>
        <p>
          In order to generate this presentation, your admin requires you to log in to the data sources powering it.
        </p>
        <Form.Field className="pam">
          <Form.Control className="centered-button">
            <Button
              onClick={() => {
                dispatch(openModal('dataSourceLoginModal'));
              }}
            >
              Log In to Data Sources
            </Button>
          </Form.Control>
        </Form.Field>
      </Hero.Body>
    </Hero>
  );
};

const getDataSourcesLoginRequired = (
  template,
  templateContent,
  attachedTemplate,
  attachedTemplateContent,
  userDataSources,
) => {
  const _getDataSourcesLoginRequired = (template) => {
    let dataSourcesWithLoginRequiredList = [];
    if (!isEmpty(template)) {
      const dataSourcesById = utils.getDataSourcesForTemplate(template, templateContent);
      if (userDataSources && dataSourcesById) {
        for (let dataSourceId in dataSourcesById) {
          const dataSource = dataSourcesById[dataSourceId];
          if (dataSource.requires_consumer_login && !userDataSources[dataSourceId]) {
            dataSourcesWithLoginRequiredList.push(dataSource);
          }
        }
      }
    }
    return dataSourcesWithLoginRequiredList;
  };

  const isTemplateContentFetched = template && templateContent && (!attachedTemplate || attachedTemplateContent);

  let dataSourcesWithLoginRequired = [];
  if (isTemplateContentFetched) {
    dataSourcesWithLoginRequired = _getDataSourcesLoginRequired(template).concat(
      _getDataSourcesLoginRequired(attachedTemplate),
    );
  }

  return {
    isTemplateContentFetched,
    dataSourcesWithLoginRequired,
  };
};

/** When the template has data sources which require a login, this component will show the user
 * a message and allow them to login via modal. If no login is required, it will simply render
 * the children.
 */
const DataSourceLoggedIn = ({ template, attachedTemplate, userDataSources, onUserDataSourceAdd, children }) => {
  const ui = useSelector((state) => state.ui);
  const { data: templateContent } = useTemplateContent(template?.id, template?.deleted);
  const { data: attachedTemplateContent } = useTemplateContent(attachedTemplate?.id, attachedTemplate?.deleted);

  const { isTemplateContentFetched, dataSourcesWithLoginRequired } = getDataSourcesLoginRequired(
    template,
    templateContent,
    attachedTemplate,
    attachedTemplateContent,
    userDataSources,
  );

  if (!isTemplateContentFetched) {
    return <div />;
  }

  if (dataSourcesWithLoginRequired.length > 0) {
    return (
      <>
        <DataSourceLoginMessage />
        <DataSourceLoginModal
          dataSourcesWithLoginRequired={dataSourcesWithLoginRequired}
          onUserDataSourceAdd={onUserDataSourceAdd}
          show={ui.modal?.name === 'dataSourceLoginModal'}
        />
      </>
    );
  }

  return children;
};
DataSourceLoggedIn.propTypes = {
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  userDataSources: PropTypes.object,
  onUserDataSourceAdd: PropTypes.func,
  children: PropTypes.any,
};

export default DataSourceLoggedIn;
