import React, { useEffect, useRef } from 'react';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
import Constants from 'components/Constants';

import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-searchbox';

// Note that workers aren't necessary if ace editor's 'useWorker: false' option is set.
const ace = require('ace-builds/src-noconflict/ace');
import htmlWorkerUrl from 'file-loader!ace-builds/src-noconflict/worker-html';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
ace.config.setModuleUrl('ace/mode/html_worker', htmlWorkerUrl);

function HtmlEditor({ emailHtml, updateHtml, canEdit }) {
  const editor = useRef();
  const { dynamicContentById } = useAllDynamicContentById();
  const dynamicContent = Object.values(dynamicContentById || {});

  const onEditorChange = (newValue) => {
    updateHtml(newValue);
  };

  useEffect(() => {
    if (dynamicContent && dynamicContent.length > 0) {
      addDynamicContentAutocomplete();
    }
  }, [dynamicContent]);

  const addDynamicContentAutocomplete = () => {
    const validDynamicContent = dynamicContent.filter(
      (dc) =>
        dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT ||
        dc.dynamic_content_type === Constants.DynamicContentTypes.IMAGE,
    );

    const dynamicContentCompleter = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        callback(
          null,
          validDynamicContent.map((content) => {
            return {
              caption: `{{${content.name}}}`,
              value: `${content.name}}}`,
              score: 1000,
              meta: 'dynamic content',
            };
          }),
        );
      },
    };
    if (editor.current && editor.current.editor) {
      editor.current.editor.completers.push(dynamicContentCompleter);
    }
  };

  return (
    <div>
      <AceEditor
        className="html-editor-container"
        ref={editor}
        mode="html"
        readOnly={!canEdit}
        theme="monokai"
        name="html-code"
        height={'385px'}
        width={'auto'}
        onChange={onEditorChange}
        fontSize={14}
        showPrintMargin={false}
        focus={true}
        editorProps={{ $blockScrolling: true }}
        wrapEnabled={true}
        highlightActiveLine={true}
        autoScrollEditorIntoView={true}
        value={emailHtml}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
          showGutter: true,
          useWorker: false,
        }}
      />
    </div>
  );
}

HtmlEditor.propTypes = {
  emailHtml: PropTypes.string,
  updateHtml: PropTypes.func,
  dynamicContent: PropTypes.array,
  canEdit: PropTypes.bool,
};

export default HtmlEditor;
