import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import TextboxWithEmbeddedIcons from 'components/lib/TextboxWithEmbeddedIcons';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import Icon from 'components/lib/Icon';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';

// THIS PAGE SHOULD BE HIDDEN FOR PPT AND EMAIL TEMPLATES
function FolderSettings({
  currentTemplate,
  setPrimaryButtonDisabled,
  setPrimaryButtonOnClick,
  onTemplateUpdateWithServerCall,
}) {
  const [logoName, setLogoName] = useState(null);
  const [logoLabel, setLogoLabel] = useState(null);
  const microsoftRef = useRef(null);
  const googleRef = useRef(null);
  const [currentIntegrationFolder, setCurrentIntegrationFolder] = useState(null);
  const [defaultIntegrationFolder, setDefaultIntegrationFolder] = useState(null);
  const [currentIntegrationFolderName, setCurrentIntegrationFolderName] = useState(null); // only used to display the path
  const [initialTemplateIntegrationFolders, setInitialTemplateIntegrationFolders] = useState(null);
  const [rightIcon, setRightIcon] = useState(<Icon name="chevron_right" size={16} />);

  useEffect(() => {
    getLogo();
    getDefaultIntegrationFolders();
    setInitialTemplateIntegrationFolders(currentTemplate?.integration_folders || []);
  }, [currentTemplate]);

  useEffect(() => {
    handleSettingCurrentIntegrationFolder();
  }, [defaultIntegrationFolder, initialTemplateIntegrationFolders]);

  useEffect(() => {
    setPrimaryButtonDisabled(saveIsDisabled());
    const rightIconName = calculateRightIconName();
    setRightIcon(<Icon name={rightIconName} size={16} />);
  }, [currentTemplate, currentIntegrationFolder]);

  const saveTemplate = async () => {
    await handleCreatingNewIntegrationFolderIfNeeded();
    const newTemplate = { ...currentTemplate };

    await onTemplateUpdateWithServerCall(newTemplate);
  };
  setPrimaryButtonOnClick(() => saveTemplate);

  const saveIsDisabled = () => {
    const initiaIntegrationFolder = initialTemplateIntegrationFolders?.find(
      (folder) => folder.template_id === currentTemplate.id,
    );

    const bothAreNullish = currentIntegrationFolder == null && initiaIntegrationFolder == null;

    if (bothAreNullish || currentIntegrationFolder === initiaIntegrationFolder) {
      return true;
    }

    return false;
  };

  const getDefaultIntegrationFolders = async () => {
    if (currentTemplate.id) {
      const defaultIntegrationFolderNamePromise = new Promise((resolve) => {
        utils.getDefaultIntegrationFolder(currentTemplate.id, (res) => {
          setDefaultIntegrationFolder(res?.folder_id ? res : null);
          resolve();
        });
      });

      await defaultIntegrationFolderNamePromise;
    }
  };

  const handleSettingCurrentIntegrationFolder = () => {
    const defaultName = '/Matik Presentations/';
    if (initialTemplateIntegrationFolders?.length === 0 && defaultIntegrationFolder !== null) {
      setCurrentIntegrationFolder(defaultIntegrationFolder);
      setCurrentIntegrationFolderName(defaultIntegrationFolder?.folder_name || defaultName);
    } else if (initialTemplateIntegrationFolders?.length > 0) {
      const currentFolder =
        initialTemplateIntegrationFolders.find((folder) => folder.template_id === currentTemplate.id) || null;
      setCurrentIntegrationFolder(currentFolder);
      setCurrentIntegrationFolderName(currentFolder?.folder_name || defaultName);
    } else {
      setCurrentIntegrationFolder(null);
      setCurrentIntegrationFolderName(defaultName);
    }
  };

  const handleCreatingNewIntegrationFolderIfNeeded = async () => {
    const sourceType =
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
        ? 'google'
        : 'microsoft';
    if (initialTemplateIntegrationFolders?.length === 0) {
      const createNewIntegrationFolder = new Promise((resolve) => {
        utils.createIntegrationFolder(
          {
            source_type: sourceType,
            template_id: currentTemplate.id,
            folder_id: currentIntegrationFolder.folder_id,
            drive_id: currentIntegrationFolder?.drive_id || null,
            folder_name: currentIntegrationFolder.folder_name,
          },
          Constants.IntegrationFolderTypes.TEMPLATE,
          () => {
            resolve();
          },
        );
      });
      await createNewIntegrationFolder;
    } else {
      const initialFolderSetOnTemplate =
        initialTemplateIntegrationFolders.find((folder) => folder.template_id === currentTemplate.id) || null;
      if (currentIntegrationFolder) {
        const updateInitialFolderOnTemplate = new Promise((resolve) => {
          utils.updateIntegrationFolder(
            initialFolderSetOnTemplate.id,
            {
              source_type: sourceType,
              template_id: currentTemplate.id,
              folder_id: currentIntegrationFolder?.folder_id,
              drive_id: currentIntegrationFolder?.drive_id || null,
              folder_name: currentIntegrationFolder?.folder_name,
            },
            () => {
              resolve();
            },
          );
        });
        await updateInitialFolderOnTemplate;
      } else {
        const deleteFolderOnTemplate = new Promise((resolve) => {
          utils.deleteIntegrationFolder(initialFolderSetOnTemplate.id, () => {
            resolve();
          });
        });
        await deleteFolderOnTemplate;
      }
    }
  };

  const getLogo = () => {
    if (
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
    ) {
      setLogoName('office_365_logo');
      setLogoLabel('Office 365');
    }

    if (
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
    ) {
      setLogoName('google_drive_logo');
      setLogoLabel('Google Drive');
    }
  };

  const handleRightIconClick = () => {
    const rightIconName = calculateRightIconName();
    if (rightIconName === 'dismiss') {
      handleRemoveTemplateIntegrationFolder();
    } else {
      handleOpenFolderModal();
    }
  };

  const handleRemoveTemplateIntegrationFolder = () => {
    setCurrentIntegrationFolder(null);
    setCurrentIntegrationFolderName('/Matik Presentations/');
  };

  const handleOpenFolderModal = () => {
    if (
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
    ) {
      microsoftRef.current?.click();
    }

    if (
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
      currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
    ) {
      googleRef.current?.click();
    }
  };

  const onMicrosoftFolderSelect = (e) => {
    const result = e.items[0];
    const folder = {
      source_type: 'microsoft',
      folder_id: result?.id,
      drive_id: result?.parentReference?.driveId,
      folder_name: result?.name,
      template_id: currentTemplate.id,
    };
    setCurrentIntegrationFolder(folder);
    setCurrentIntegrationFolderName(folder.folder_name);
  };

  const onGoogleFolderSelect = (e) => {
    const result = e[0];
    const folder = {
      source_type: 'google',
      folder_id: result?.id,
      folder_name: result?.name,
      template_id: currentTemplate.id,
    };
    setCurrentIntegrationFolder(folder);
    setCurrentIntegrationFolderName(folder.folder_name);
  };

  const calculateRightIconName = () => {
    return currentIntegrationFolder ? 'dismiss' : 'chevron_right';
  };

  return (
    <div className="border border-grey-300 rounded-md">
      <div className="p-5 border-b border-grey-300">
        <p>Folder</p>
        <p className="help">Specify the folder where content generated from this template should be saved</p>
      </div>
      <div className="p-5 border-b border-grey-300">
        <p className="help mb-5">
          If no custom folder is set for this template, Matik will use your enterprise&apos;s default export settings.
        </p>
        <p>Customize your presentation&apos;s folder</p>
        <div className="flex w-full gap-x-3 mb-4">
          <TextboxWithEmbeddedIcons iconName={logoName} readOnly={true} textboxValue={logoLabel} />
          <TextboxWithEmbeddedIcons
            readOnly={true}
            textboxValue={currentIntegrationFolderName}
            rightIconClickHandler={handleRightIconClick}
            rightIcon={rightIcon}
          />

          <MicrosoftPicker
            className="hidden"
            onPicked={onMicrosoftFolderSelect}
            pickerMode="folders"
            buttonRef={microsoftRef}
          />
          <GoogleDrivePicker
            className="hidden"
            onPicked={onGoogleFolderSelect}
            view="folders"
            permissionsMessage="In order to set a custom folder, you will need to select allow in the authentication window."
            buttonRef={googleRef}
          />
        </div>
      </div>
    </div>
  );
}

FolderSettings.propTypes = {
  currentTemplate: PropTypes.object,
  setPrimaryButtonDisabled: PropTypes.func,
  setPrimaryButtonOnClick: PropTypes.func,
  onTemplateUpdateWithServerCall: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(FolderSettings);
