import { Card, Columns } from 'react-bulma-components';
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const TemplateLibraryModalPreview = (props) => {
  const skeletonLoaders = [1, 2, 3];
  return (
    <Card className="template-library-card-basic is-shadowless" id={props.templateId}>
      <Columns className="is-1 is-variable template-card-content">
        <Columns.Column size="three-fifths">
          {props.thumbnailLoading ? (
            <div className="large-template-library-thumbnail">
              <Skeleton height="100%" />
            </div>
          ) : (
            <div className="large-template-library-thumbnail has-light-gray-border">
              <img className="image-wrapper" src={props.thumbnails?.[0]?.contentUrl} referrerPolicy="no-referrer" />
            </div>
          )}
        </Columns.Column>
        <Columns.Column className="right-template-library-column" size="one-fifth">
          {props.thumbnailLoading ? (
            skeletonLoaders.map((idx) => {
              return (
                <div key={idx} className="right-template-library-column-thumbnail-image">
                  <Skeleton height="100%" />
                </div>
              );
            })
          ) : (
            <div>
              {props.thumbnails &&
                props.thumbnails.map((thumbnail, index) => {
                  if (index == 0 || index == 2 || index == 4) {
                    return (
                      <div key={index} className="right-template-library-column-thumbnail-image has-light-gray-border">
                        <img className="image-wrapper" src={thumbnail.contentUrl} referrerPolicy="no-referrer" />
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </Columns.Column>
        <Columns.Column className="right-template-library-column" size="one-fifth">
          {props.thumbnailLoading ? (
            skeletonLoaders.map((idx) => {
              return (
                <div key={idx} className="right-template-library-column-thumbnail-image">
                  <Skeleton height="100%" width="172px" />
                </div>
              );
            })
          ) : (
            <div>
              {props.thumbnails &&
                props.thumbnails.map((thumbnail, index) => {
                  if (index == 1 || index == 3 || index == 5) {
                    return (
                      <div key={index} className="right-template-library-column-thumbnail-image has-light-gray-border">
                        <img className="image-wrapper" src={thumbnail.contentUrl} referrerPolicy="no-referrer" />
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </Columns.Column>
      </Columns>
    </Card>
  );
};

TemplateLibraryModalPreview.propTypes = {
  templateId: PropTypes.any,
  thumbnails: PropTypes.array,
  thumbnailLoading: PropTypes.bool,
};

export default TemplateLibraryModalPreview;
