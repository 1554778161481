import React, { useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import PropTypes from 'prop-types';
import { ReactComponent as ColorIcon } from '../../../../images/wysiwyg/color.svg';

function RichTextColorPicker({ currentState, expanded, onChange, onExpandEvent }) {
  const [activeTab, setActiveTab] = useState('textColor');
  const [color, setColor] = useColor('hex', currentState?.color ?? '#000000');
  const [bgcolor, setBgColor] = useColor('hex', currentState?.bgcolor ?? '#ffffff');

  const onColorChange = (color) => {
    setColor(color);
  };

  const onBgColorChange = (bgcolor) => {
    setBgColor(bgcolor);
  };

  return (
    <div aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker">
      <div onClick={onExpandEvent}>
        <button className={`wysiwyg-button-color ${expanded ? 'active' : ''}`}>
          <ColorIcon />
        </button>
      </div>
      {expanded ? (
        <>
          <div className="color-tab-popover">
            <div className="color-picker-tabs">
              <button
                className={`color-picker-tab-buttons ${activeTab === 'textColor' ? 'active-tab' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab('textColor');
                }}
              >
                Text Color
              </button>
              <button
                className={`color-picker-tab-buttons ${activeTab === 'highlight' ? 'active-tab' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab('highlight');
                }}
              >
                Highlight
              </button>
            </div>
            <div className="color-picker" onClick={(e) => e.stopPropagation()}>
              <ColorPicker
                color={activeTab === 'textColor' ? color : bgcolor}
                height={150}
                hideHSV
                hideRGB
                light
                onChange={activeTab === 'textColor' ? onColorChange : onBgColorChange}
                width={320}
              />
            </div>
          </div>
          <div
            className="color-picker-backdrop"
            onClick={() => {
              if (activeTab === 'textColor') {
                if (currentState?.color != color.hex) {
                  onChange('color', color.hex);
                }
              } else {
                if (currentState?.bgcolor != bgcolor.hex) {
                  onChange('bgcolor', bgcolor.hex);
                }
              }
            }}
            onMouseUp={() => {
              if (activeTab === 'textColor') {
                if (currentState?.color != color.hex) {
                  onChange('color', color.hex);
                }
              } else {
                if (currentState?.bgcolor != bgcolor.hex) {
                  onChange('bgcolor', bgcolor.hex);
                }
              }
            }}
          />
        </>
      ) : undefined}
    </div>
  );
}

RichTextColorPicker.propTypes = {
  currentState: PropTypes.object,
  doCollapse: PropTypes.func,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  onExpandEvent: PropTypes.func,
};

export default RichTextColorPicker;
