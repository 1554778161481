import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';

export const getConditionCount = (conditionClauses) => {
  let count = 0;
  conditionClauses?.or?.forEach((or) => (count += or.and?.length));
  return count;
};

const ConditionIconPill = React.forwardRef(
  ({ conditionClauses, conditionsById = {}, onIndicatorClick, onMenuClick }, ref) => {
    return (
      <span
        ref={ref}
        className="inline-flex items-center rounded-full border border-grey-300 bg-matik-white group hover:bg-grey-100"
      >
        <button
          type="button"
          onClick={onIndicatorClick}
          className="flex gap-1 items-center py-1.5 pl-2 pr-2 group-hover:pr-1.5"
        >
          <Icon size={16} name="condition_arrow" />
          <div className="leading-none">{getConditionCount(conditionClauses)}</div>
          <div>
            <ConditionDots conditionClauses={conditionClauses} conditionsById={conditionsById} />
          </div>
        </button>
        <button
          type="button"
          onClick={onMenuClick}
          className="border-l border-grey-300 items-center py-1.5 pl-1.5 pr-2 hidden group-hover:flex"
        >
          <Icon size={16} name="chevron_down" />
        </button>
      </span>
    );
  },
);
ConditionIconPill.displayName = 'ConditionIconPill';
ConditionIconPill.propTypes = {
  conditionClauses: PropTypes.shape({
    or: PropTypes.arrayOf(
      PropTypes.shape({
        and: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
  }),
  conditionsById: PropTypes.object,
  onIndicatorClick: PropTypes.func,
  onMenuClick: PropTypes.func,
};

export default ConditionIconPill;

const Row = ({ children }) => {
  return <div className="flex gap-0.5 justify-center">{children}</div>;
};
Row.propTypes = {
  children: PropTypes.any,
};

const ConditionDots = ({ conditionClauses, conditionsById }) => {
  const colors = [];
  conditionClauses?.or?.forEach((or, orIdx) => {
    or.and?.forEach((conditionId, andIdx) => {
      const color = conditionsById[conditionId]?.icon_color || 'grey-600';
      colors.push(<div key={`${orIdx}_${andIdx}`} className={`inline-block rounded-full bg-${color} w-1 h-1`} />);
    });
  });

  if (colors.length <= 2) {
    return <div className="flex flex-col gap-0.5">{colors}</div>;
  } else if (colors.length <= 4) {
    return (
      <div className="flex flex-col gap-0.5">
        <Row>{colors.slice(0, 2)}</Row>
        <Row>{colors.slice(2)}</Row>
      </div>
    );
  } else if (colors.length <= 6) {
    return (
      <div className="flex flex-col gap-0.5">
        <Row>{colors.slice(0, 3)}</Row>
        <Row>{colors.slice(3)}</Row>
      </div>
    );
  } else {
    return null;
  }
};
ConditionDots.propTypes = {
  conditionClauses: ConditionIconPill.propTypes.conditionClauses,
  conditionsById: PropTypes.object,
};
