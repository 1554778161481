import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthPage from './AuthPage';
import API from '../../lib/api';
import LoginForm from './LoginForm';
import { Auth } from '../../lib/auth';

function Login({ location }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const onLoginSuccess = (user) => {
    // Technically, we should already have all this information, but we add it again just in case
    API.setUserProfile(true);
    API.track('successful_login');

    setIsAuthenticated(true);
    setUser(user);
  };

  if (isAuthenticated) {
    let toRedirect = Auth.getHomeForAuthenticatedRole();

    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('flow');
    const companyName = urlParams.get('company_name') || '';
    const companyUrl = urlParams.get('company_url') || '';
    if (!flow && location.state && location.state.from.pathname !== '/') {
      toRedirect = location.state.from.pathname;
    }
    if (flow) {
      toRedirect += `templates/?start_flow=${flow}&company_name=${encodeURIComponent(
        companyName,
      )}&company_url=${encodeURIComponent(companyUrl)}`;
      API.track('enterprise_user_logged_in_from_trial_flow', { companyName, companyUrl, templateType: flow }, user?.id);
    }
    window.location.assign(toRedirect);
    return null;
  }

  return (
    <AuthPage>
      <LoginForm onLoginSuccess={onLoginSuccess} />
    </AuthPage>
  );
}

Login.propTypes = {
  location: PropTypes.object,
};

export default Login;
