import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Checkmark } from 'images/icons/checkmark-12x12-regular.svg';
import { ReactComponent as Error } from 'images/icons/error-12x12-regular.svg';
import './IconWithPill.sass';

function IconWithPill({ children, className = '', toolTip = '', pill }) {
  const pillIcon = getPillIcon(pill);
  return (
    <div className="icon-with-pill" data-tooltip-content={toolTip} data-tooltip-id="matik-tooltip">
      <div className={`is-flex ${className}`}>{children}</div>
      {pillIcon}
    </div>
  );
}

function getPillIcon(pill) {
  if (pill == 'none') {
    return <></>;
  }
  const fillerIcon = pill === 'success' ? <Checkmark /> : pill === 'error' ? <Error /> : <></>;
  return <div className={`icon-pill ${pill}`}>{fillerIcon}</div>;
}

IconWithPill.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  pill: PropTypes.oneOf(['none', 'success', 'error', 'warn']),
};

export default IconWithPill;
