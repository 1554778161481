import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';

class LookerForm extends Component {
  render() {
    const baseUrl = this.props.auth && this.props.auth['base_url'] ? this.props.auth['base_url'] : '';
    return (
      <React.Fragment>
        <Form.Field>
          <Form.Label htmlFor="base-url">Looker Base URL</Form.Label>
          <Form.Help>
            This is based on the URL you see when you go to your looker instance. Example: https://matik.api.looker.com
            or https://matik.looker.com:19999
          </Form.Help>
          <Form.Control>
            <Form.Input
              placeholder="Looker Base URL"
              type="text"
              name="base_url"
              onChange={this.props.onAuthChange}
              value={baseUrl}
              id="base-url"
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  }
}

LookerForm.propTypes = {
  auth: PropTypes.object,
  selectedDataSource: PropTypes.object,
  type: PropTypes.string,
  onAuthChange: PropTypes.func,
  onPasswordAuthChange: PropTypes.func,
  isLoading: PropTypes.bool,
  testIsLoading: PropTypes.bool,
  testResult: PropTypes.object,
  testDataSource: PropTypes.func,
  deleteDataSource: PropTypes.func,
};

export default LookerForm;
