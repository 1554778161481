import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../../lib/utils';
import { NOTIFY_ERROR, NOTIFY_SUCCESS } from '../../redux/notifications/actionTypes';
import { toast } from 'react-toastify';
import { mapDispatchToProps } from '../../redux/notifications/dispatchers';
import { mapNotificationStateToProps } from '../../redux/notifications/stateMappers';

const WithNotifications = (WrappedComponent) => {
  return class Inner extends Component {
    static propTypes = {
      clearNotification: PropTypes.func,
      failureNotifications: PropTypes.array,
      successNotifications: PropTypes.array,
    };

    componentDidUpdate() {
      if (this.props.successNotifications && this.props.successNotifications.length > 0) {
        for (let notificationMessage of this.props.successNotifications) {
          utils.notify(notificationMessage);
          this.props.clearNotification(notificationMessage, NOTIFY_SUCCESS);
        }
      }
      if (this.props.failureNotifications && this.props.failureNotifications.length > 0) {
        for (let notificationMessage of this.props.failureNotifications) {
          utils.notify(notificationMessage, toast.TYPE.ERROR);
          this.props.clearNotification(notificationMessage, NOTIFY_ERROR);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default compose(connect(mapNotificationStateToProps, mapDispatchToProps), WithNotifications);
