import React from 'react';
import { components } from 'react-select';
import { MenuNoSearch, Select } from './FormSelect';
import PropTypes from 'prop-types';

function SourceTypeDropdown(props) {
  const IconOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="template-source-dropdown">
          <img src={props.data.icon} alt={props.data.label} />
          <span>{props.data.label}</span>
        </div>
      </components.Option>
    );
  };

  const IconControl = ({ ...props }) => {
    const icon = props.options.find((option) => option.value === props.getValue()?.[0]?.value)?.icon;
    return (
      <components.Control {...props}>
        <div className="template-source-dropdown-select">{icon && <img src={icon} alt="icon" />}</div>
        {props.children}
      </components.Control>
    );
  };

  return (
    <Select
      key={props.selectedDropdownOption.label}
      value={{ label: props.selectedDropdownOption.label, value: props.selectedDropdownOption.value }}
      classNamePrefix="matik-select"
      options={props.dropdownOptions}
      onChange={props.onDropdownChange}
      menuPortalTarget={null}
      isSearchable={false}
      className="template-builder-modal-dropdown-box"
      componentsToAdd={{ Menu: MenuNoSearch, Option: IconOption, Control: IconControl }}
      menuPosition="absolute"
    />
  );
}
SourceTypeDropdown.propTypes = {
  selectedDropdownOption: PropTypes.object,
  onDropdownChange: PropTypes.func,
  data: PropTypes.object,
  options: PropTypes.array,
  children: PropTypes.any,
  getValue: PropTypes.func,
  supportedSourceTypes: PropTypes.object,
  dropdownOptions: PropTypes.array,
};
export default SourceTypeDropdown;
