import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import { CreatableBulkSelect } from '../../shared/FormSelect';
import API from '../../../lib/api';
import { find, isEqual } from 'lodash';
import { Form } from 'react-bulma-components';
import Banner, { toastBanner } from '../../shared/Banner';
import Pluralize from 'pluralize';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class BulkItemTagModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingOptions: true,
      isUpdatingTags: true,
      availableItemTags: [],
      selectedTags: new Set(),
      partiallySelectedTags: new Set(),
      removedTags: new Set(),
      saveEnabled: false,
    };
  }

  componentDidMount() {
    this.getAvailableItemTags();
  }

  componentDidUpdate(prevProps) {
    if (this.props.ui.modal?.name === 'bulkItemTagModal' && prevProps.ui.modal?.name !== 'bulkItemTagModal') {
      this.getExistingItemTags();
    }
  }

  render() {
    const title = `Manage tags for ${Pluralize('item', this.props.itemIds.length, true)}`;
    return (
      <Modal
        show={this.props.ui.modal?.name === 'bulkItemTagModal'}
        title={title}
        onClose={this.onClose}
        showDefaultFooter={true}
        footerIsSticky={false}
        primaryButtonText="Save"
        primaryButtonOnClick={this.updateTags}
        primaryButtonDisabled={!this.state.saveEnabled}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={this.props.onClose}
      >
        <Form.Field className="bulk-item-tag-modal-body">
          <Form.Control>
            <CreatableBulkSelect
              placeholder={this.state.isLoadingOptions || this.state.isUpdatingTags ? 'Loading...' : 'Select Tags'}
              options={this.getItemTagOptions()}
              value={this.getSelectedValues()}
              isMulti={true}
              onChange={this.onTagSelect}
              classNamePrefix="matik-select"
              aria-label="Tags"
              isSearchable={true}
              isDisabled={this.state.isLoadingOptions || this.state.isUpdatingTags}
              isLoading={this.state.isLoadingOptions}
              onMultiValueClick={this.onMultiValueClick}
              hideSelectedOptions={false}
            />
          </Form.Control>
        </Form.Field>
      </Modal>
    );
  }

  getAvailableItemTags = () => {
    this.setState({ isLoadingOptions: true });
    API.get(
      '/item_tags/enterprise/',
      (response) => {
        this.setState({ availableItemTags: response.data.tags, isLoadingOptions: false });
      },
      API.defaultError,
    );
  };

  getExistingItemTags = () => {
    this.setState({ isUpdatingTags: true });
    const queryString = new URLSearchParams({
      item_ids: this.props.itemIds.join(','),
      item_type: this.props.entityType,
    });
    API.get(
      `/item_tags/bulk/?${queryString}`,
      (response) => {
        const itemIdsByTag = {};
        for (let tag of response.data.tags) {
          if (!itemIdsByTag[tag.tag]) {
            itemIdsByTag[tag.tag] = new Set();
          }
          itemIdsByTag[tag.tag].add(tag.item_id);
        }
        const allItemIds = new Set(this.props.itemIds);
        let selectedTags = new Set();
        let partiallySelectedTags = new Set();
        for (let tag of Object.keys(itemIdsByTag)) {
          if (isEqual(itemIdsByTag[tag], allItemIds)) {
            selectedTags.add(tag);
          } else {
            partiallySelectedTags.add(tag);
          }
        }
        this.setState({
          isUpdatingTags: false,
          selectedTags: selectedTags,
          partiallySelectedTags: partiallySelectedTags,
          removedTags: new Set(),
          saveEnabled: false,
        });
      },
      API.defaultError,
    );
  };

  updateTags = () => {
    const data = {
      item_tags_to_add: [...this.state.selectedTags].map((tag) => ({ tag: tag })),
      item_tags_to_remove: [...this.state.removedTags].map((tag) => ({ tag: tag })),
      item_ids: this.props.itemIds,
      item_type: this.props.entityType,
    };
    API.post(
      '/item_tags/bulk/',
      data,
      () => {
        toastBanner(
          <Banner
            bannerType="success"
            text={`Successfully changed tags for ${Pluralize('item', this.props.itemIds.length, true)}`}
          />,
        );
        this.setState({ saveEnabled: false, removedTags: new Set() });
      },
      API.defaultError,
    );
  };

  onMultiValueClick = (tag) => {
    this.setState((prevState) => {
      let selectedTags = new Set(prevState.selectedTags);
      let partiallySelectedTags = new Set(prevState.partiallySelectedTags);
      let removedTags = new Set(prevState.removedTags);
      selectedTags.add(tag);
      partiallySelectedTags.delete(tag);
      removedTags.delete(tag);
      return { selectedTags, partiallySelectedTags, removedTags, saveEnabled: true };
    });
  };

  onTagSelect = (obj, action) => {
    this.setState((prevState) => {
      let selectedTags = new Set(prevState.selectedTags);
      let partiallySelectedTags = new Set(prevState.partiallySelectedTags);
      let removedTags = new Set(prevState.removedTags);
      if (action.action === 'select-option' || action.action === 'deselect-option') {
        selectedTags.add(action.option.value);
        partiallySelectedTags.delete(action.option.value);
        removedTags.delete(action.option.value);
      } else if (action.action === 'select-all-options') {
        action.option.forEach((option) => {
          selectedTags.add(option.value);
          partiallySelectedTags.delete(option.value);
          removedTags.delete(option.value);
        });
      } else if (action.action === 'remove-value') {
        selectedTags.delete(action.removedValue.value);
        partiallySelectedTags.delete(action.removedValue.value);
        removedTags.add(action.removedValue.value);
      } else if (action.action === 'clear') {
        const allSelectedTags = [...selectedTags, ...partiallySelectedTags];
        selectedTags = new Set();
        partiallySelectedTags = new Set();
        for (let tag of allSelectedTags) {
          removedTags.add(tag);
        }
      } else if (action.action === 'create-option') {
        const newTag = find(obj, (el) => !!el.__isNew__).value;
        selectedTags.add(newTag);
        partiallySelectedTags.delete(newTag);
        removedTags.delete(newTag);
      }
      return { selectedTags, partiallySelectedTags, removedTags, saveEnabled: true };
    });
  };

  getSelectedValues = () => {
    if (this.state.isUpdatingTags) {
      return [];
    }
    let values = [];
    for (let tag of this.state.selectedTags) {
      values.push({ label: tag, value: tag, fullySelected: true });
    }
    for (let tag of this.state.partiallySelectedTags) {
      values.push({ label: tag, value: tag, fullySelected: false });
    }
    return values;
  };

  getItemTagOptions = () => {
    const itemTags = this.state.availableItemTags;
    if (!itemTags) {
      return [];
    }
    return itemTags
      .filter((tag) => !this.state.selectedTags.has(tag.tag))
      .map((tag) => ({ label: tag.tag, value: tag.tag }));
  };

  onClose = () => {
    this.props.closeModal();
  };
}

BulkItemTagModal.propTypes = {
  itemIds: PropTypes.array.isRequired,
  entityType: PropTypes.string.isRequired,
  ui: PropTypes.object,
  onClose: PropTypes.func,
  closeModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(BulkItemTagModal);
