import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import AceEditor from 'react-ace';
import { addCompleters } from 'aceeditor/ace-matik.js';

import InputPopover from 'components/producer/dynamicContent/InputPopover';
import { useAllDynamicContentById } from '../../lib/hooks/useDynamicContent';

let _key = 1;

export const InputWithOptionalInputs = ({
  value,
  onChange,
  inputs,
  isReadOnly,
  placeholder,
  theme = 'input',
  isDynamicContentSuggested,
  allDynamicContentNamesById,
  className,
  style,
  isInputPopoverDisabled,
  inputPopoverTrigger = 'cursor',
  enterEnabled,
  wrapEnabled,
}) => {
  // Ace editor needs a unique key per mounted instance.
  const key = useRef(_key);
  useEffect(() => {
    _key++;
  }, []);

  const [ace, setAce] = useState();

  // Ignore any "enter" keypresses and flatten any copy/pasted text to a single line
  useEffect(() => {
    if (ace) {
      ace.on('paste', (e) => {
        e.text = e.text.replaceAll('\n', ' ');
      });
      ace.keyBinding.setKeyboardHandler((data, hashId, keyString, keyCode) => {
        if (keyCode === 13 && !enterEnabled) {
          // enter
          return { command: 'null' };
        } else if (keyCode === 9) {
          // tab
          ace.blur();
          return { command: 'null' };
        }
      });
    }
  }, [ace]);

  // Enable syntax highlighting for custom matik elements and (optionally) SQL
  useEffect(() => {
    if (ace) {
      ace.session.getMode().setHighlightDynamicContent(isDynamicContentSuggested);
    }
  }, [ace, isDynamicContentSuggested]);

  // Set up input and dynamic content auto-complete suggestions
  useEffect(() => {
    if (ace) {
      addCompleters(ace, inputs, isDynamicContentSuggested ? allDynamicContentNamesById : null);
    }
  }, [ace, JSON.stringify(inputs), isDynamicContentSuggested ? JSON.stringify(allDynamicContentNamesById) : null]);

  const handleChange = (val) => {
    if (onChange) {
      onChange(val);
    }
  };

  if (value && !enterEnabled) {
    value = value.replaceAll('\n', ' ');
  }

  return (
    <InputPopover
      ace={ace}
      inputs={inputs}
      isInputPopoverDisabled={isInputPopoverDisabled}
      inputPopoverTrigger={inputPopoverTrigger}
      adjustPopoverTop={(top) => top + 22 + (theme === 'input' ? 9 : 0)} // directly below the input
    >
      <div className={`${className || ''} ${theme === 'input' ? 'input-like' : ''}`}>
        <AceEditor
          onLoad={setAce}
          placeholder={placeholder || ''}
          mode="matik-sql"
          value={value}
          onChange={handleChange}
          theme="matik-one-line"
          name={`input_${key.current}`}
          editorProps={{ $blockScrolling: true }}
          fontSize={style?.fontSize || '1em'}
          style={style}
          width="auto"
          height="1.5em"
          readOnly={isReadOnly}
          wrapEnabled={wrapEnabled}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: false,
            showGutter: false,
            highlightActiveLine: false,
            cursorStyle: isReadOnly ? 'slim' : 'ace', // "slim" gets styled as hidden in our custom css
          }}
        />
      </div>
    </InputPopover>
  );
};
InputWithOptionalInputs.propTypes = {
  allDynamicContentNamesById: PropTypes.object,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  isDynamicContentSuggested: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['input', 'minimal']),
  value: PropTypes.string,
  className: PropTypes.string,
  /** True to suppress the input navigation popover. False will show the popover when the input name
   * is associated with a known input.
   */
  isInputPopoverDisabled: PropTypes.bool,
  inputPopoverTrigger: PropTypes.oneOf(['cursor', 'mouse']),
  style: PropTypes.object,
  enterEnabled: PropTypes.bool,
  wrapEnabled: PropTypes.bool,
};

const ConnectedInputWithOptionalInputs = (props) => {
  const { dynamicContentById: allDynamicContentNamesById } = useAllDynamicContentById();
  return (
    <InputWithOptionalInputs
      {...props}
      allDynamicContentNamesById={allDynamicContentNamesById}
      inputPopoverTrigger="mouse"
    />
  );
};
ConnectedInputWithOptionalInputs.propTypes = {
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  isDynamicContentSuggested: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['input', 'minimal']),
  value: PropTypes.string,
  className: PropTypes.string,
  isInputPopoverDisabled: PropTypes.bool,
};

const MemoInputWithOptionalInputs = memo(ConnectedInputWithOptionalInputs);

const MemoizedInputWithOptionalInputs = ({ onChange, ...others }) => {
  const ref = useRef();
  ref.current = onChange;
  const callback = useCallback((...args) => ref.current(...args), [ref]);

  return <MemoInputWithOptionalInputs {...others} onChange={callback} />;
};
MemoizedInputWithOptionalInputs.propTypes = {
  onChange: PropTypes.func,
};
export default MemoizedInputWithOptionalInputs;
