import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioInput extends Component {
  render() {
    const { checked, name, label, disabled, className, value } = this.props;
    let fullClassName = 'green-radio-button ';
    if (className) {
      fullClassName += className;
    }
    if (disabled) {
      fullClassName += ' disabled';
    }
    return (
      <label className={fullClassName} htmlFor={label}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={this.onChange}
          disabled={disabled}
          id={label}
        />
        <span className="radio-check"></span>
        {label}
      </label>
    );
  }
  onChange = (e) => {
    this.props.onChange(e);
  };
}

RadioInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default RadioInput;
