import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useMultiFetch from './useMultiFetch';
import { useInputs } from './useInput';

/** Fetch a single presentation */
const usePresentation = (id) => {
  const queryKey = ['presentation', parseInt(id)];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/presentations/${id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
    cacheTime: 5 * 60 * 1000,
  });

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

export default usePresentation;

export const usePresentations = (ids) => {
  const getQueryKey = (id) => ['presentation', parseInt(id)];
  const getFetchUri = (ids) => {
    return API.generate_paginated_url('/presentations/', 0, ids.length, null, `{"id":[${ids.join(',')}]}`);
  };
  const getEntityId = (presentation) => presentation.id;
  return useMultiFetch(ids, getQueryKey, getFetchUri, getEntityId, 5 * 60 * 1000);
};

/** Fetch the inputs referenced by the given presentation */
export const usePresentationInputs = (id) => {
  const queryKey = ['presentation', parseInt(id), 'content'];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/presentations/${id}/content/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
  });

  // Get full input data to flesh out the input refs
  const inputIds = data?.parameters?.map((param) => param.id);
  const inputResultsById = useInputs(inputIds || []);
  const hydratedParameters = data?.parameters?.map((ref) => {
    let { data: fullInput } = inputResultsById[ref.id];
    return fullInput || ref;
  });
  const isInputsFetching = Object.values(inputResultsById).findIndex((result) => result.isPending) > -1;

  return {
    isPending: isLoading || isInputsFetching,
    isError,
    data: hydratedParameters,
    error,
  };
};

export const usePresentationMutator = () => {
  const queryClient = useQueryClient();

  const getPresentationStatus = (presentationId) => {
    return API.get(
      `/presentations/${presentationId}/get_status/`,
      (response) => {
        if (
          response.data.presentation_status === 'done' ||
          response.data.presentation_status === 'non_email_failed_condition'
        ) {
          return {
            status: 'done',
            presentationId,
            isNonEmailFailedCondition: response.data.presentation_status === 'non_email_failed_condition',
            presentation: response.data.new_entity,
            attachedPresentation: response.data.new_attachment_entity,
          };
        } else if (response.data.presentation_status === 'unknown') {
          // This may happen if there is a race condition: another user loads the templates page in between status checks
          window.location.reload();
        } else if (response.data.presentation_status === 'error' || response.data.attachment_status === 'error') {
          return {
            status: 'generation_error',
            presentationId,
          };
        } else {
          return {
            status: response.data.presentation_status,
          };
        }
      },
      (err) => {
        throw err;
      },
    );
  };

  const create = (data, attachmentData = {}, onStatusUpdate) => {
    onStatusUpdate('Processing');
    let cancelled = false;
    const result = new Promise((resolve, reject) => {
      const checkStatus = (presentationId) => {
        if (cancelled) {
          return;
        }
        getPresentationStatus(presentationId)
          .then((result) => {
            if (result.status === 'done' || result.status === 'generation_error') {
              // We've got the new presentation data so let's cache it
              if (result.presentation) {
                queryClient.setQueryData(['presentation', parseInt(result.presentation.id)]);
              }
              if (result.attachedPresentation) {
                queryClient.setQueryData(['presentation', parseInt(result.attachedPresentation.id)]);
              }

              resolve(result);
            } else {
              onStatusUpdate(result.status);
              setTimeout(() => checkStatus(presentationId), 1500);
            }
          })
          .catch(reject);
      };

      const allData = {
        ...data,
        ...attachmentData,
      };
      API.post(
        '/presentations/',
        allData,
        (response) => {
          setTimeout(() => {
            const presentationId = response.data.new_entity.id;
            checkStatus(presentationId);
          }, 1500);
        },
        reject,
      );
    });

    return {
      cancel: () => {
        cancelled = true;
      },
      result,
    };
  };

  return {
    /** Return a promise that resolves after the presentation has been generated
     * or errors out.
     * @returns
     * `{status: "done|generation_error", presentationId, presentation, attachedPresentation, isNonEmailFailedCondition}`
     */
    create,
  };
};
