import React, { Component } from 'react';
import { Heading } from 'react-bulma-components';

class MatikAuthPlug extends Component {
  render() {
    return (
      <div className="matik-auth-plug">
        <Heading size={2} className="matik-signup-text">
          Automate the
          <br /> creation of
          <br /> data-driven content
        </Heading>
        <div className="footer-links">
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://matik.io/privacy/">
            Privacy
          </a>
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://matik.io/product/">
            About
          </a>
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://blog.matik.io/">
            Blog
          </a>
          <a className="footer-link" target="_blank" rel="noreferrer noopener" href="https://matik.io/terms/">
            Terms & Conditions
          </a>
        </div>
      </div>
    );
  }
}

export default MatikAuthPlug;
