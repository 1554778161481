import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../shared/FormSelect';

class AnalyticsPeriodSelector extends Component {
  render() {
    const options = [
      { label: 'Daily', value: 'day' },
      { label: 'Weekly', value: 'week' },
      { label: 'Monthly', value: 'month' },
    ];

    return (
      <Select
        id="analytics-period-selector"
        className="analytics-dropdown"
        onChange={this.onPeriodSelect}
        name="period"
        value={this.props.selectedPeriod}
        classNamePrefix="matik-select"
        options={options}
        isMulti={false}
        isSearchable={false}
      />
    );
  }

  onPeriodSelect = (obj) => {
    this.props.updateQueryParams({ selectedPeriod: obj, period: obj.value });
  };
}

AnalyticsPeriodSelector.propTypes = {
  selectedPeriod: PropTypes.object,
  updateQueryParams: PropTypes.func,
};

export default AnalyticsPeriodSelector;
