import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import { Navbar } from 'react-bulma-components';
import Constants from '../../Constants';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import API from '../../../lib/api';
import teams from '../../../lib/teams';
import withUserContext from '../../shared/WithUserContext';
import Button from '../../lib/Button';
import Tag from '../../lib/Tag';

class PresentationsRemainingTag extends Component {
  render() {
    let tagColor = 'white';
    if (this.props.presentationsRemaining >= 3) {
      tagColor = 'green';
    } else if (this.props.presentationsRemaining === 2) {
      tagColor = 'yellow';
    } else {
      tagColor = 'red';
    }

    let refreshDate = moment().add(1, 'M').format('MMM 1, YYYY');

    return (
      <Navbar.Item renderAs="span" className="is-hoverable">
        <Navbar.Link className="navbar-white-hover" arrowless={true}>
          <Tag
            color={tagColor}
            label={`${Pluralize('presentations', this.props.presentationsRemaining, true)} left`}
            rightIconName="chevron_down"
          />
        </Navbar.Link>
        <Navbar.Dropdown>
          <Navbar.Item renderAs="span" className="trial-days-dropdown">
            <Tag color={tagColor} label={`${this.props.presentationsRemaining / Constants.MONTHLY_PRESENTATIONS}`} />
            <span className="trial-dropdown-header pts pbs">
              Your plan has {Pluralize('presentation', this.props.presentationsRemaining, true)} left this month
            </span>
            <span className="trial-dropdown-content pbs">
              Your number of presentations will refresh on {refreshDate}. Need more?
            </span>
            <Button category="primary" onClick={this.updatePlanClick}>
              Discover Matik Enterprise
            </Button>
          </Navbar.Item>
        </Navbar.Dropdown>
      </Navbar.Item>
    );
  }

  updatePlanClick = () => {
    API.track('discover_matik_enterprise_click', { from: 'presentations_remaining_tag' });
    const demoUrl = teams.buildRequestDemoUrl(
      this.props.userContext.user,
      'presentation_limit',
      this.props.userContext.user.enterprise.trial_days_remaining,
    );
    window.open(demoUrl, '_blank');
  };
}

PresentationsRemainingTag.propTypes = {
  presentationsRemaining: PropTypes.number,
  history: PropTypes.object,
  userContext: PropTypes.object,
};

export default withRouter(withUserContext(PresentationsRemainingTag));
