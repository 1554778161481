import React, { useRef, useEffect, useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import AceEditor from 'react-ace';
import { addCompleters, scrollParentToFollowCursor } from 'aceeditor/ace-matik.js';

import InputPopover from 'components/producer/dynamicContent/InputPopover';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';

let _key = 1;

export const QueryEditor = ({
  mode = 'plaintext',
  isDynamicContentSuggested,
  isInputPopoverDisabled,
  inputPopoverTrigger = 'mouse',
  inputs,
  queryString,
  onQueryStringUpdate,
  isReadOnly,
}) => {
  const { dynamicContentById: allDynamicContentNamesById } = useAllDynamicContentById();
  // Ace editor needs a unique key per mounted instance.
  const key = useRef(_key);
  useEffect(() => {
    _key++;
  }, []);

  const [ace, setAce] = useState();

  // Enable syntax highlighting for dynamic-content tags
  useEffect(() => {
    if (ace) {
      ace.session.getMode().setHighlightDynamicContent(isDynamicContentSuggested);
    }
  }, [ace, mode, isDynamicContentSuggested]);

  // Set up input and dynamic content auto-complete suggestions
  useEffect(() => {
    if (ace) {
      addCompleters(ace, inputs, isDynamicContentSuggested ? allDynamicContentNamesById : null);
    }
  }, [ace, JSON.stringify(inputs), isDynamicContentSuggested ? JSON.stringify(allDynamicContentNamesById) : null]);

  const adjustPopoverTop = (top) => {
    // The popover is shown/hidden based on whether or not the editor senses the mouse pointer is over an
    // input token. We want to position the popover div so that it at least slightly overlaps with the input token
    // div. That way the popover captures mouse events and the editor won't know the mouse is no longer over the token
    // and so it won't hide the popover.

    // top == the top pixel of the input token

    // popover is 54px tall, this adjustment places it above the token
    let popoverTop = top - 53;
    if (popoverTop < 0) {
      // we're above of the container, let's drop it below the token
      popoverTop = top + 22;
    }
    return popoverTop;
  };

  let height = 'auto';
  const lineCount = queryString?.split('\n')?.length || 0;
  if (lineCount >= 10) {
    height = lineCount * 22 + 'px';
  }
  useEffect(() => {
    if (ace) {
      ace.resize();
    }
  }, [ace, height]);

  useEffect(() => {
    if (ace && !isReadOnly) {
      return scrollParentToFollowCursor(ace);
    }
  }, [ace, isReadOnly]);

  return (
    <InputPopover
      ace={ace}
      inputs={inputs}
      isInputPopoverDisabled={isInputPopoverDisabled}
      inputPopoverTrigger={inputPopoverTrigger}
      adjustPopoverTop={adjustPopoverTop}
    >
      <StableOnUpdateEditor
        setAce={setAce}
        mode={mode}
        queryString={queryString}
        onQueryStringUpdate={onQueryStringUpdate}
        name={`editor_${key.current}`}
        height={height}
        isReadOnly={isReadOnly}
      />
    </InputPopover>
  );
};
QueryEditor.propTypes = {
  allDynamicContentNamesById: PropTypes.object,
  /** Highlights dynamic content tags and provides autocomplete suggestions, when enabled */
  isDynamicContentSuggested: PropTypes.bool,
  mode: PropTypes.oneOf(['sql', 'plaintext', 'json']),
  /** True to suppress the input navigation popover. False will show the popover when the input name
   * is associated with a known input.
   */
  isInputPopoverDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  inputPopoverTrigger: PropTypes.oneOf(['cursor', 'mouse']),
  inputs: PropTypes.object,
  queryString: PropTypes.string,
  onQueryStringUpdate: PropTypes.func,
};

export default QueryEditor;

// Minimize AceEditor re-renders to prevent odd behavior where AceEditor internally clears and then
// restores editor content when re-rendering with the same value. (see MPD-5263)

const MemoizedEditor = memo(function AceEditorWrapper({
  setAce,
  mode,
  queryString,
  onQueryStringUpdate,
  name,
  height,
  isReadOnly,
}) {
  return (
    <AceEditor
      onLoad={setAce}
      mode={mode === 'sql' ? 'matik-sql' : 'matik-text'}
      value={queryString}
      onChange={onQueryStringUpdate}
      theme="matik"
      name={name}
      wrapEnabled
      editorProps={{ $blockScrolling: true }}
      fontSize="1em"
      width="auto"
      height={height}
      readOnly={isReadOnly}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        showLineNumbers: true,
        tabSize: 2,
        showGutter: true,
        highlightActiveLine: !isReadOnly,
        cursorStyle: isReadOnly ? 'slim' : 'ace', // "slim" gets styled as hidden in our custom css
      }}
    />
  );
});
MemoizedEditor.propTypes = {
  setAce: PropTypes.func,
  mode: QueryEditor.propTypes.mode,
  queryString: QueryEditor.propTypes.queryString,
  onQueryStringUpdate: PropTypes.func,
  name: PropTypes.string,
  height: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

function StableOnUpdateEditor({ onQueryStringUpdate, ...props }) {
  // Prevent changing onQueryStringUpdate references from breaking memoization
  const ref = useRef();
  ref.current = onQueryStringUpdate;
  const callback = useCallback((...args) => ref.current(...args), [ref]);

  return <MemoizedEditor onQueryStringUpdate={callback} {...props} />;
}
StableOnUpdateEditor.propTypes = {
  onQueryStringUpdate: PropTypes.func,
};
