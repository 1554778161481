import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Level } from 'react-bulma-components';
import RefinementList from './RefinementList';
import Constants from '../../Constants';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.htmlElement = React.createRef();
  }

  componentDidMount() {
    if (!this.props.sidebar || !this.props.templateTags) {
      document.addEventListener('keydown', this.onEscPress);
      document.addEventListener('click', this.onOutsideClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscPress);
    document.removeEventListener('click', this.onOutsideClick);
  }

  render() {
    const filterClass = this.props.show ? '' : 'is-hidden';
    const searchAttributes = this.props.attributes ? this.props.attributes : [];
    let filters;
    if (this.props.sidebar) {
      filters = (
        <Box className="is-shadowless is-paddingless is-fullheight">
          <Container className="pbl ptl is-fluid">
            {Object.keys(searchAttributes).map((refinement) => {
              if (!refinement.includes('params')) {
                return (
                  <div key={refinement} id={`filter-${refinement}`}>
                    <div className="phs mbm" style={{ flex: '1 1' }}>
                      <RefinementList
                        attribute={refinement}
                        label={this.props.attributes[refinement]}
                        searchState={this.props.searchState}
                        onSearchStateChange={this.props.onSearchStateChange}
                        itemTags={this.props.itemTags}
                        templateTags={this.props.templateTags}
                        updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
                        showMore={true}
                        showMoreLimit={Constants.MAX_VALUES_PER_FACET}
                      />
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Container>
          {'params' in this.props.attributes ? (
            <Container className="light-top-border pbl ptl is-fluid">
              <div key={'params'} id="filter-params">
                <div className="phs mbm" style={{ flex: '1 1' }}>
                  <RefinementList
                    attribute={'params'}
                    label={this.props.attributes['params']}
                    inputs={this.props.inputs}
                    searchState={this.props.searchState}
                    onSearchStateChange={this.props.onSearchStateChange}
                    updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
                    showMore={true}
                    showMoreLimit={Constants.MAX_VALUES_PER_FACET}
                  />
                </div>
              </div>
            </Container>
          ) : (
            ''
          )}
        </Box>
      );
    } else {
      filters = (
        <Level>
          {Object.keys(searchAttributes).map((refinement) => {
            return (
              <Level.Item key={refinement}>
                <div className="phs" style={{ flex: '1 1' }} id={`filter-${refinement}`}>
                  <RefinementList
                    attribute={refinement}
                    label={this.props.attributes[refinement]}
                    searchState={this.props.searchState}
                    onSearchStateChange={this.props.onSearchStateChange}
                    itemTags={this.props.itemTags}
                    templateTags={this.props.templateTags}
                    updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
                    showMore={true}
                    showMoreLimit={Constants.MAX_VALUES_PER_FACET}
                    filtersAreShownInSidepane={this.props.filtersAreShownInSidepane}
                  />
                </div>
              </Level.Item>
            );
          })}
        </Level>
      );
    }

    let className = '';
    if (this.props.sidebar) {
      className = 'is-fullheight';
    } else if (this.props.templateTags) {
      className = 'template-tags';
    } else {
      className = `filters-overlay box ${filterClass}`;
    }

    return (
      <div className={className} ref={this.htmlElement}>
        {filters}
      </div>
    );
  }

  onEscPress = (e) => {
    if (this.props.show && e.keyCode === 27 && this.props.close) {
      this.props.close(e);
    }
  };

  onOutsideClick = (e) => {
    if (this.htmlElement.current && this.htmlElement.current.contains(e.target)) {
      return;
    }
    const isFilterButtonClick = !!e.target.closest('.search-filter');
    const isRefinementClick = !!e.target.closest('.matik-select__menu');
    const isFilterClick = !!e.target.closest('.matik-select__multi-value');
    if (
      this.props.show &&
      !isFilterButtonClick &&
      !isRefinementClick &&
      !isFilterClick &&
      !this.props.templateTags &&
      !this.props.sidebar &&
      this.props.close
    ) {
      this.props.close(e);
    }
  };
}

Filters.propTypes = {
  attributes: PropTypes.object,
  close: PropTypes.func,
  filterList: PropTypes.object,
  sidebar: PropTypes.bool,
  show: PropTypes.bool,
  inputs: PropTypes.object,
  searchState: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  itemTags: PropTypes.array,
  templateTags: PropTypes.bool,
  updateIsFetchingPresentation: PropTypes.func,
  filtersAreShownInSidepane: PropTypes.bool,
};

export default Filters;
