import React from 'react';
import PropTypes from 'prop-types';

const InputDisplayName = ({ className, input }) => {
  return <span className={className}>{InputDisplayName.text(input)}</span>;
};
InputDisplayName.propTypes = {
  input: PropTypes.shape({
    display_name: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

InputDisplayName.text = (input) => {
  let displayName = input.name;
  if (input.display_name) {
    displayName = input.display_name;
  }
  return displayName || '';
};

export default InputDisplayName;
