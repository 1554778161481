import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../lib/Button';

export const FullScreenModalFooter = (props) => (
  <div className="full-screen-modal-footer">
    {props.tertiaryButtonOnClick && (
      <Button category="tertiary" onClick={props.tertiaryButtonOnClick}>
        {props.tertiaryButtonText ? props.tertiaryButtonText : 'Cancel'}
      </Button>
    )}
    {props.secondaryButtonOnClick && (
      <Button
        category="secondary"
        onClick={props.secondaryButtonOnClick}
        status={props.secondaryButtonDisabled ? 'disabled' : props.secondaryButtonLoading ? 'loading' : 'default'}
      >
        {props.secondaryButtonText ? props.secondaryButtonText : 'Cancel'}
      </Button>
    )}
    <Button
      onClick={props.primaryButtonOnClick}
      status={props.primaryButtonDisabled ? 'disabled' : props.primaryButtonLoading ? 'loading' : 'default'}
    >
      {props.primaryButtonText ? props.primaryButtonText : 'Submit'}
    </Button>
  </div>
);

FullScreenModalFooter.propTypes = {
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonLoading: PropTypes.bool,
  secondaryButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonLoading: PropTypes.bool,
  tertiaryButtonText: PropTypes.string,
  tertiaryButtonOnClick: PropTypes.func,
};
