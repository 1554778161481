import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { closeSidepane, openSidepane } from 'redux/ui/action';
import Constants from 'components/Constants';
import useAllConditions, { useSlideConditionTargets, useConditionsInTemplate } from 'lib/hooks/useCondition';
import AssetItemCondition from '../conditions/AssetItemCondition';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';
import Pagination from 'components/shared/Pagination';
import no_conditions_svg from 'images/no_conditions.png';
import ConnectedSearchBox from 'components/shared/search/SearchBoxAndFilters';
import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import ComponentWithHitsAndPagination from 'components/shared/search/ComponentWithHitsAndPagination';

const TemplateConditionsList = ({
  slides,
  filtering = [],
  onFilteringChange,
  isSearching,
  searchState,
  searchAttributes,
}) => {
  const dispatch = useDispatch();
  const { sidepane } = useSelector((state) => state.ui);

  const conditionsInTemplate = useConditionsInTemplate({ slides });

  const conditionTargetsBySlide = useSlideConditionTargets(slides?.map((slide) => slide.id));
  const getSlideReferenceCount = (condition) => {
    return Object.values(conditionTargetsBySlide).filter(({ data }) => {
      return data?.condition_clauses?.or?.some((or) => or.and.includes(condition.id));
    }).length;
  };

  const handleFilteringChange = (conditionId, isFiltering) => {
    if (isFiltering) {
      if (filtering.indexOf(conditionId) < 0) {
        onFilteringChange([...filtering, conditionId]);
      }
    } else {
      onFilteringChange(filtering.filter((id) => id !== conditionId));
    }
  };

  const handleConditionClick = (condition) => {
    if (sidepane?.content?.entityType === 'condition' && sidepane.content.conditionId === condition.id) {
      dispatch(closeSidepane());
    } else {
      dispatch(openSidepane({ entityType: 'condition', conditionId: condition.id }, 'template-sidepane'));
    }
  };

  const includeCondition = (condition) => {
    if (!isSearching) {
      return true;
    }
    const q = searchState?.query?.toLowerCase();
    for (const field of [condition.name, condition.description]) {
      if (field?.toLowerCase()?.indexOf(q) > -1) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <ConnectedSearchBox
          showFilterButton={false}
          searchState={searchState}
          searchAttributes={searchAttributes}
          filterPlaceholder="Search"
        />
      </div>
      {conditionsInTemplate.length === 0 ? (
        <div className="self-center m-4">
          <img src={no_conditions_svg} alt="No conditions are set yet" />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="text-grey-600 pt-3">Conditions in this Template</div>
          {conditionsInTemplate.filter(includeCondition).map((condition) => (
            <div key={condition.id}>
              <AssetItemCondition
                condition={condition}
                targetCount={getSlideReferenceCount(condition)}
                isFiltering={filtering.indexOf(condition.id) > -1}
                onFilteringChange={(isFiltering) => handleFilteringChange(condition.id, isFiltering)}
                onClick={() => handleConditionClick(condition)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="text-grey-600 pt-3">Global Conditions</div>
        {isSearching ? (
          <SearchedConditionsList onConditionClick={handleConditionClick} />
        ) : (
          <AllConditionsList onConditionClick={handleConditionClick} />
        )}
      </div>
      {filtering.length > 0 && (
        <div className="fixed w-[440px] left-0 bottom-7">
          <div className="flex justify-center">
            <ShowConditionsTag filtering={filtering} onClear={() => onFilteringChange([])} />
          </div>
        </div>
      )}
    </div>
  );
};
TemplateConditionsList.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  filtering: PropTypes.arrayOf(PropTypes.number),
  onFilteringChange: PropTypes.func,

  // WithInstantSearch
  isSearching: PropTypes.bool,
  searchState: PropTypes.object,
  searchAttributes: PropTypes.object,
};

export default WithInstantSearch(TemplateConditionsList);

const ShowConditionsTag = ({ filtering, onClear }) => {
  return (
    <Button category="secondary" size="small" isRounded onClick={onClear}>
      <span className="flex items-center gap-3">
        <span className="flex items-center gap-2">
          <Icon size={16} name="eye" theme="filled" />
          <span>Show Conditions</span>
          <span className="rounded-full border border-matik-green w-8 h-8 flex items-center justify-center grow-1 bg-matik-green/20">
            {filtering?.length}
          </span>
        </span>
        <Icon size={12} name="dismiss" />
      </span>
    </Button>
  );
};
ShowConditionsTag.propTypes = {
  filtering: PropTypes.arrayOf(PropTypes.number),
  onClear: PropTypes.func,
};

const ConditionsList = ({ entitiesToRender, fetchItems, pagination, onConditionClick }) => {
  return (
    <>
      {entitiesToRender?.map((condition) => (
        <div key={condition.id}>
          <AssetItemCondition condition={condition} onClick={() => onConditionClick(condition)} />
        </div>
      ))}
      {pagination?.numberOfPages > 1 && (
        <div className="self-end">
          <Pagination page={pagination.currentPage} pages={pagination.numberOfPages} onPageChange={fetchItems} />
        </div>
      )}
    </>
  );
};
ConditionsList.propTypes = {
  onConditionClick: PropTypes.func,

  // these are injected by ComponentWithHitsAndPagination
  entitiesToRender: PropTypes.arrayOf(AssetItemCondition.propTypes.condition),
  fetchItems: PropTypes.func,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    numberOfPages: PropTypes.number,
  }),
};

const SearchedConditionsList = ({ onConditionClick }) => {
  return <ComponentWithHitsAndPagination onConditionClick={onConditionClick} WrappedComponent={ConditionsList} />;
};
SearchedConditionsList.propTypes = {
  onConditionClick: PropTypes.func,
};

const AllConditionsList = ({ onConditionClick }) => {
  const [globalPage, setGlobalPage] = useState(0);
  const { elements, pagination } = useAllConditions(globalPage * Constants.PAGE_SIZE, Constants.PAGE_SIZE);

  return (
    <ConditionsList
      entitiesToRender={elements}
      onConditionClick={onConditionClick}
      pagination={pagination}
      fetchItems={setGlobalPage}
    />
  );
};
AllConditionsList.propTypes = {
  onConditionClick: PropTypes.func,
};
