import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'react-bulma-components';
import AuthPage from './AuthPage';
import { Auth } from '../../lib/auth';
import API from '../../lib/api';
import { MAlert } from '../shared/Alerts';
import matik_logo_black from '../../images/matik_logo_black.svg';

function LoginRedirect() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (!code) {
      return history.push('/login');
    }

    API.get(
      `/auth/google_login_callback/?code=${code}&state=${state}`,
      (response) => {
        Auth.storeAuthToken(response.data.access_token);
        API.setUserProfile(true);
        API.track('successful_login');
        history.push(Auth.getHomeForAuthenticatedRole());
      },
      (err) => {
        MAlert(`There was an error logging you in: ${err}`, 'error', 'error', () => {
          history.push('/login');
        });
      },
    );
  }, []);
  return (
    <AuthPage>
      <div className="auth-logo">
        <img src={matik_logo_black} alt="matik logo" height="40px" />
      </div>
      <Card className="auth-card">
        <h2 className="is-size-3 has-text-centered pal">Logging you in...</h2>
      </Card>
    </AuthPage>
  );
}

export default LoginRedirect;
