import React, { useState } from 'react';
import { isEqual } from 'lodash';
import { connectHits, connectPagination } from 'react-instantsearch-dom';
import moment from 'moment';
import Constants from '../../Constants';

const ComponentWithHitsAndPagination = connectHits(
  connectPagination((props) => {
    // eslint-disable-next-line no-unused-vars
    const { hits, WrappedComponent, currentRefinement, nbPages, refine, ...rest } = props;
    const [mostRecentHits, setMostRecentHits] = useState(hits);
    const updatedHits = hits
      .filter((hit) => hit.type != 'user' || hit.id != null)
      .map((hit) => {
        const objectArray = hit.objectID.split('_');
        hit.id = objectArray[0];
        if (hit.data_source) {
          hit.query_obj = {};
          hit.query_obj.data_source = hit.data_source;
        }
        if (hit.data_source_type) {
          hit.type = hit.data_source_type;
        }
        if (hit.updated_on) {
          // timestamps from algolia are in epoch time, convert to datetime string
          if (typeof hit.created_on === 'number') {
            hit.updated_on = moment.unix(hit.updated_on).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
          }
        }
        if (hit.created_on) {
          // timestamps from algolia are in epoch time, convert to datetime string
          if (typeof hit.created_on === 'number') {
            hit.created_on = moment.unix(hit.created_on).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
          }
        }
        if (rest?.entities?.find((entity) => entity.id == hit.id)) {
          // seems like some of the data gets lost when algoria returns the search hits
          // this is a workaround to get the lost data back
          // TODO: update ComopnentWithHits.js? Unsure if we also need to make a similar change.
          hit = { ...hit, ...rest.entities.find((entity) => entity.id == hit.id) };
        }
        return hit;
      });
    // Only invoke the setter when hits are updated.
    if (!isEqual(updatedHits, mostRecentHits)) {
      setMostRecentHits(updatedHits);
      props.setEntities?.(updatedHits);
    }
    const paginationObjWithSearch = {
      currentPage: currentRefinement - 1,
      numberOfPages: nbPages,
      limit: Constants.PAGE_SIZE,
      sort: null,
    };
    const fetchItems = (page) => {
      refine(page + 1);
    };
    return (
      <WrappedComponent
        // Note that we're overriding 'pagination', 'fetchItems', and 'isSearching'
        // which are in 'rest', so the 'rest' spread has to be before those overrides.
        {...rest}
        entitiesToRender={updatedHits}
        pagination={paginationObjWithSearch}
        fetchItems={fetchItems}
        isSearching={true}
      />
    );
  }),
);

export default ComponentWithHitsAndPagination;
