import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';

import utils from 'lib/utils';
import API from 'lib/api';
import localeDateFormatterUtils from 'lib/localeDate';

import dynamic_content_empty_icon from 'images/dynamic_content_empty.png';

import Constants from 'components/Constants';
import {
  ContentNameCell,
  ContentShortTypeCell,
  DataSourceCell,
  getDataSourceName,
} from 'components/shared/FullPageListCells';
import CollapsibleDynamicContentSelect from 'components/producer/dynamicContent/forms/CollapsibleDynamicContentSelect';
import { useOneDynamicContent } from 'lib/hooks/useDynamicContent';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';
import Banner from 'components/lib/Banner';

const NO_CONDITIONAL_CONTENT_FILTER = {
  dynamic_content_type: Object.values(Constants.DynamicContentTypes).filter(
    (type) => type !== Constants.DynamicContentTypes.CONDITIONAL,
  ),
};
function useDynamicContentWithoutConditional({ page, sort }) {
  const offset = page * Constants.PAGE_SIZE;
  let fullUrl = API.generate_paginated_url('/dynamic_content/', offset, Constants.PAGE_SIZE, sort);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['dynamic_content', 'content_conditional_select', page, sort],
    queryFn: () => {
      return API.get(
        `${fullUrl}&lite=true&filter=${JSON.stringify(NO_CONDITIONAL_CONTENT_FILTER)}`,
        (response) => {
          return {
            count: API.getCountFromResponse(response),
            dynamicContent: response.data,
          };
        },
        (err) => {
          throw err;
        },
      );
    },
  });

  const pagination = data ? utils.getPaginationFromRequest(data?.count, Constants.PAGE_SIZE, offset, sort) : undefined;

  return {
    isLoading,
    count: data?.count,
    dynamicContent: data?.dynamicContent,
    pagination,
    isError,
    error,
  };
}

function DynamicContentSelect(props) {
  const [queryParams, setQueryParams] = useState({ page: 0, sort: null });

  useEffect(() => {
    // reset the sort/paging whenever the list put in "expanded" mode
    if (props.isExpanded) {
      setQueryParams({ page: 0, sort: null });
    }
  }, [props.isExpanded]);

  const {
    dynamicContent: currentContent,
    isLoading: isSelectedContentLoading,
    error: selectedFetchError,
  } = useOneDynamicContent(props.selectedDynamicContentId);

  const {
    isLoading: isPageLoading,
    dynamicContent: pageOfContent,
    pagination,
  } = useDynamicContentWithoutConditional(queryParams);

  const onRowClick = (id) => {
    props.onExpanded(false);
    props.onDynamicContentSelect(parseInt(id));
  };

  const fetchItems = (page, sort) => {
    if (sort === undefined) {
      sort = queryParams.sort;
    }
    if (sort !== queryParams.sort) {
      page = 0;
    }
    setQueryParams({ page, sort });
  };

  // We need this getMethod for sorting, because React Table sorts by row.value. If we just have a cell for this
  // column, then React Table doesn't know how to sort it.
  const getMethod = (content) => {
    if (content.dynamic_content_type === 'conditional') {
      return '--';
    }

    return Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[content.dynamic_content_method]?.text;
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('dynamic_content_type', {
      header: 'Type',
      cell: ContentShortTypeCell,
      meta: {
        width: '70px',
      },
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ContentNameCell,
    }),
    columnHelper.accessor(getMethod, {
      id: 'dynamic_content_method',
      header: 'Method',
    }),
    columnHelper.accessor(getDataSourceName, {
      id: 'data_source_name',
      header: 'Data Source',
      cell: DataSourceCell,
    }),
    columnHelper.accessor(
      (d) => {
        const locale = localeDateFormatterUtils.getUserLocale();
        const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
        return moment(d.updated_on).format(localeDateFormat);
      },
      {
        id: 'updated_on',
        header: 'Last Updated',
      },
    ),
  ];

  const emptyInfo = {
    emptyImageUrl: dynamic_content_empty_icon,
    emptyPrompt: "You haven't created any dynamic content yet.",
  };
  const overrideCollapsed = selectedFetchError ? (
    <InaccessibleContentWarning fetchError={selectedFetchError} onExpanded={props.onExpanded} />
  ) : null;
  return (
    <div className="has-background-white dynamic-content-list">
      <CollapsibleDynamicContentSelect
        collapsedComponent={overrideCollapsed}
        currentEntity={currentContent}
        isReadOnly={props.isReadOnly}
        context="dynamic_content"
        columns={columns}
        onRowClick={onRowClick}
        pagination={pagination}
        fetchItems={fetchItems}
        hideTopPagination={true}
        entityType="dynamic_content"
        filterPlaceholder="Search Dynamic Content"
        isLoading={isPageLoading || isSelectedContentLoading}
        elements={pageOfContent} // see WithLoadingAndEmpty
        entities={pageOfContent} // see WithFilterListHeader
        emptyInfo={emptyInfo}
        isExpanded={props.isExpanded}
        onExpanded={props.onExpanded}
      />
    </div>
  );
}
DynamicContentSelect.propTypes = {
  dynamicContentArr: PropTypes.array,
  isReadOnly: PropTypes.bool,
  onDynamicContentSelect: PropTypes.func,
  selectedDynamicContentId: PropTypes.number,
  onExpanded: PropTypes.func,
  isExpanded: PropTypes.bool,
};

export default DynamicContentSelect;

function InaccessibleContentWarning({ fetchError, onExpanded }) {
  let message;
  switch (fetchError?.response?.status) {
    case 403:
      message = 'You are missing read access to this content.';
      break;
    case 404:
      message = 'This content has been removed.';
      break;
    default:
      message = 'There was a problem loading this content.';
  }
  return (
    <div className="w-full mt-8 border-t border-grey-300 !px-6 py-2 flex items-center">
      <div className="grow">
        <Banner size="s" theme="error" content={message} />
      </div>
      <Button category="tertiary" onClick={() => onExpanded(true)}>
        <Icon name="chevron_down" />
      </Button>
    </div>
  );
}
InaccessibleContentWarning.propTypes = {
  fetchError: PropTypes.shape({
    response: PropTypes.shape({ status: PropTypes.number }),
  }),
  onExpanded: PropTypes.func,
};
