import React from 'react';
import PopUpThumbnail from 'components/shared/PopUpThumbnail';
import SourceTypeImage from 'components/shared/SourceTypeImage';
import Pluralize from 'pluralize';
import Constants from 'components/Constants';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { ReactComponent as CloseIcon } from '../../../svg/close_14.svg';
import PropTypes from 'prop-types';
import Icon from '../../lib/Icon';

function EmailAttachmentCard(props) {
  const templateType =
    props.attachedTemplate?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'email' : 'presentation';

  const onCloseClick = () => {
    const updatedTemplate = { ...props.currentTemplate };
    updatedTemplate.attached_template_id = null;
    props.onTemplateUpdateWithServerCall(updatedTemplate);
  };

  if (props.attachedTemplate) {
    return (
      <div className="email-attachment-card">
        <PopUpThumbnail template={props.attachedTemplate} />

        <div className="attachment-card-right">
          <div className={`template-type-icon-bg ${templateType}`}>
            {templateType === 'email' ? (
              <Icon name="email" size={20} theme="regular" />
            ) : (
              <Icon name="presentation" size={20} theme="filled" />
            )}
          </div>
          <div className="attachment-card-text">
            <TextOverflowWithTooltip
              className="title is-4 attachment-card-title text-overflow-ellipsis"
              text={props.attachedTemplate.name}
            />
            <div className="attachment-card-details">
              <span>{templateType === 'email' ? 'Email' : 'Presentation'}</span>
              <span className="tertiary-card-text">|</span>
              <SourceTypeImage
                cssClassName="source-type-img"
                sourceType={props.attachedTemplate.source_type}
                size="sm"
              />
              {templateType !== 'email' ? (
                <span className="tertiary-card-text">
                  {Pluralize('Slide', props.attachedTemplate.slides ? props.attachedTemplate.slides.length : 0, true)}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="attachment-close">{props.tab !== 'send' ? <CloseIcon onClick={onCloseClick} /> : null}</div>
      </div>
    );
  } else {
    return null;
  }
}

EmailAttachmentCard.propTypes = {
  currentTemplate: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  attachedTemplate: PropTypes.object,
  tab: PropTypes.string,
};

export default EmailAttachmentCard;
