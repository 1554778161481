import React from 'react';
import HtmlBuilderToolbox from 'components/shared/htmlBuilder/HtmlBuilderToolbox';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EmailBodySettingsRender from 'components/shared/htmlBuilder/html_builder_components/EmailBodySettingsRender';
import EmailContainerSettingsRender from 'components/shared/htmlBuilder/html_builder_components/EmailContainerSettingsRender';
import ColumnModule from 'components/shared/htmlBuilder/html_builder_components/ColumnModule';
import DraggableButton from 'components/shared/htmlBuilder/html_builder_components/DraggableButton';
import DraggableText from 'components/shared/htmlBuilder/html_builder_components/DraggableText';
import Divider from 'components/shared/htmlBuilder/html_builder_components/Divider';
import Image from 'components/shared/htmlBuilder/html_builder_components/Image';
import Chart from 'components/shared/htmlBuilder/html_builder_components/Chart';
import Table from 'components/shared/htmlBuilder/html_builder_components/Table';
import Icon from 'components/lib/Icon';

function TemplateModules() {
  const { enableVisualBuilderCharts, enableVisualBuilderTables } = useFlags();

  const htmlBuilderComponents = [
    {
      component: <ColumnModule />,
      label: 'Columns',
      icon: (
        <div className="flex">
          <Icon name="grid" size={20} theme="regular" />
        </div>
      ),
    },
    {
      component: <DraggableText />,
      label: 'Text',
      icon: (
        <div className="flex">
          <Icon name="textbox" size={20} theme="regular" />
        </div>
      ),
    },
    {
      component: <Image />,
      label: 'Image',
      icon: (
        <div className="flex">
          <Icon name="image" size={20} theme="regular" />
        </div>
      ),
    },
    {
      component: <DraggableButton />,
      label: 'Button',
      icon: (
        <div className="flex">
          <Icon name="button" size={20} theme="regular" />
        </div>
      ),
    },
    {
      component: <Divider />,
      label: 'Divider',
      icon: (
        <div className="flex">
          <Icon name="arrow_maximize_vertical" size={20} theme="regular" />
        </div>
      ),
    },
  ];

  if (enableVisualBuilderTables) {
    htmlBuilderComponents.push({
      component: <Table />,
      label: 'Table',
      icon: (
        <div className="flex">
          <Icon name="table" size={20} theme="regular" />
        </div>
      ),
    });
  }

  if (enableVisualBuilderCharts) {
    htmlBuilderComponents.push({
      component: <Chart />,
      label: 'Chart',
      icon: (
        <div className="flex">
          <Icon name="data" size={20} theme="regular" />
        </div>
      ),
    });
  }

  return (
    <div>
      <p className="modules-header">Modules</p>
      <div className="module-settings-container">
        <HtmlBuilderToolbox htmlBuilderComponents={htmlBuilderComponents} />
      </div>
      <EmailBodySettingsRender />
      <EmailContainerSettingsRender />
    </div>
  );
}

export default TemplateModules;
