import React from 'react';

export const SpreadSheetContext = React.createContext({
  dataSource: {},
  placement: '',
  dockOnLeft: () => {},
  dockOnBottom: () => {},
  worksheetIndex: 0,
  setCurrentWorksheetIndex: () => {},
  sheets: [],
  setSheets: () => {},
  onCellUpdate: () => {},
  isOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  changeInput: () => {},
});
