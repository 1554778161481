import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import SlideOverlay from './SlideOverlay';
import { ContentContext } from './ContentContext';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useTemplatePdf } from 'lib/hooks/useTemplate';
import SmallLoader from '../../shared/SmallLoader';
import Banner from '../../shared/Banner';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DOCUMENT_PADDING = 24;

function SlideView({ selectedSlide, currentTemplate, dynamicContentTags }) {
  const [textItems, setTextItems] = useState(null);
  const [pdfWidth, setPdfWidth] = useState(null);
  const [pdfHeight, setPdfHeight] = useState(null);
  const [addTextOverlay, setAddTextOverlay] = useState(null);
  const pdfWrapper = useRef(null);
  const documentRef = useRef(null);
  const context = useContext(ContentContext);

  const { data } = useTemplatePdf(selectedSlide?.is_library ? selectedSlide?.library?.id : currentTemplate?.id);
  const pdfUrl = data?.url;

  const setDivSize = throttle(() => {
    if (pdfWrapper.current && documentRef.current) {
      const boundingRect = pdfWrapper.current.getBoundingClientRect();
      const docBoundingRect = documentRef.current.getBoundingClientRect();
      if (docBoundingRect.width > boundingRect.width && docBoundingRect.height > boundingRect.height) {
        if (docBoundingRect.height - boundingRect.height > docBoundingRect.width - boundingRect.width) {
          setPdfHeight(boundingRect.height - DOCUMENT_PADDING);
          setPdfWidth(undefined);
        } else {
          setPdfWidth(boundingRect.width - DOCUMENT_PADDING);
          setPdfHeight(undefined);
        }
      } else if (docBoundingRect.height > boundingRect.height) {
        setPdfHeight(boundingRect.height - DOCUMENT_PADDING);
        setPdfWidth(undefined);
      } else if (docBoundingRect.width > boundingRect.width) {
        setPdfWidth(boundingRect.width - DOCUMENT_PADDING);
        setPdfHeight(undefined);
      } else {
        if (boundingRect.height - docBoundingRect.height < boundingRect.width - docBoundingRect.width) {
          setPdfHeight(boundingRect.height - DOCUMENT_PADDING);
          setPdfWidth(undefined);
        } else {
          setPdfWidth(boundingRect.width - DOCUMENT_PADDING);
          setPdfHeight(undefined);
        }
      }
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', setDivSize);
    return () => {
      window.removeEventListener('resize', setDivSize);
    };
  }, []);

  useEffect(() => {
    if (addTextOverlay && context.selectedSlideTag) {
      setAddTextOverlay(null);
    }
  }, [selectedSlide]);

  const onLoadSuccess = (items) => {
    // This seems useless but it's not: it updates the state once the text layer is loaded and allows us to draw
    // shapes around that text.
    setDivSize();
    setTextItems(items);
  };

  const onMouseUp = (e) => {
    // PowerPoint and Google Slides generate PDFs slightly differently: pptx PDFs are generated with
    // the text wrapped in markedContent spans, so we need to get the grandparent class here.
    if (
      e.target.tagName === 'SPAN' &&
      (e.target.parentElement.classList.contains('react-pdf__Page__textContent') ||
        e.target.parentElement.parentElement.classList.contains('react-pdf__Page__textContent'))
    ) {
      // See https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection
      const selectionObj = window.getSelection();
      const selection = selectionObj.toString();
      if (selectionObj.type === 'Range' && selection) {
        const selectionRange = selectionObj.getRangeAt(0);
        const elementRect = e.target.getBoundingClientRect();
        const getRect = selectionRange.getBoundingClientRect();
        setAddTextOverlay({
          top: elementRect.top,
          left: getRect.left + getRect.width,
          el: e.target,
          selection: selection,
        });
      } else {
        setAddTextOverlay(null);
      }
    } else {
      setAddTextOverlay(null);
    }
  };

  const loadingEl = <SmallLoader />;
  const errorState = (
    <Banner bannerType="error" text="Failed to load slide. Reupload or sync template to load this slide." />
  );

  return (
    <div className="hero-body flex justify-center">
      <div className="pdf-container mll mtl" ref={pdfWrapper}>
        <Document
          // note that react-pdf crashes when file={some url} -> file={null} -> file={some url} after the component is mounted
          // if we use the url as the key, any time it changes the component will be re-mounted and avoid that crash.
          key={pdfUrl || 'emptypdf'}
          file={pdfUrl}
          loading={loadingEl}
          error={errorState}
        >
          <SlideOverlay
            selectedSlide={selectedSlide}
            pdfWidth={pdfWidth}
            pdfHeight={pdfHeight}
            textItems={textItems}
            addTextOverlay={addTextOverlay}
            dynamicContentTags={dynamicContentTags}
          >
            <Page
              canvasRef={documentRef}
              pageNumber={selectedSlide.pdf_index}
              onMouseUp={onMouseUp}
              width={pdfWidth}
              height={pdfHeight}
              onGetTextSuccess={onLoadSuccess}
              renderAnnotationLayer={false}
            />
          </SlideOverlay>
        </Document>
      </div>
    </div>
  );
}

SlideView.propTypes = {
  currentTemplate: PropTypes.object,
  dynamicContentTags: PropTypes.array,
  selectedSlide: PropTypes.object,
  flags: PropTypes.object,
};

export default withLDConsumer()(SlideView);
