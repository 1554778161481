import React from 'react';
import { Form, Box } from 'react-bulma-components';
import PropTypes from 'prop-types';
import AuthTabs from '../AuthTabs';

function PasswordAuth({
  auth,
  centered,
  checkboxes,
  disclaimer,
  onAuthChange,
  passwordHelp,
  passwordLabel = 'Password',
  passwordPlaceholder = 'Enter Datasource Password',
  usernameHelp,
  usernameLabel = 'Username',
  usernamePlaceholder = 'Enter Datasource Username',
}) {
  const username = auth?.username ?? '';
  const password = auth?.password ?? '';
  return (
    <Box className="data-source-box" style={centered ? { margin: '0 auto' } : null}>
      {checkboxes?.[1]}
      {disclaimer && <p className="auth-disclaimer">{disclaimer}</p>}
      <Form.Field>
        <Form.Label htmlFor="db-username">{usernameLabel}</Form.Label>
        {usernameHelp && <Form.Help>{usernameHelp}</Form.Help>}
        <Form.Control>
          <Form.Input
            placeholder={usernamePlaceholder}
            type="text"
            name="username"
            onChange={onAuthChange}
            value={username}
            autoComplete="off"
            id="db-username"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="db-password">{passwordLabel}</Form.Label>
        {passwordHelp && <Form.Help>{passwordHelp}</Form.Help>}
        <Form.Control>
          <Form.Input
            placeholder={passwordPlaceholder}
            type="password"
            name="password"
            onChange={onAuthChange}
            value={password}
            autoComplete="off"
            id="db-password"
          />
        </Form.Control>
      </Form.Field>
    </Box>
  );
}

PasswordAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  checkboxes: PropTypes.array,
  disclaimer: PropTypes.string,
  onAuthChange: PropTypes.func,
  passwordHelp: PropTypes.string,
  passwordLabel: PropTypes.string,
  passwordPlaceholder: PropTypes.string,
  usernameHelp: PropTypes.string,
  usernameLabel: PropTypes.string,
  usernamePlaceholder: PropTypes.string,
};

export function PasswordAuthWithLoginTab(props) {
  const tab = { name: 'login-tab', title: 'Access via Login' };
  return (
    <>
      <AuthTabs activeAuthTab={tab.name} tabs={[tab]} setActiveAuthTab={() => {}} centered={props.centered} />
      <PasswordAuth {...props} />
    </>
  );
}

PasswordAuthWithLoginTab.propTypes = {
  centered: PropTypes.bool,
};

export default PasswordAuth;
