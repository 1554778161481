import Pagination from './Pagination';
import PropTypes from 'prop-types';
import React from 'react';

const ReactTablePagination = ({ pages, onPageChange, page }) => {
  return (
    <div className="react-table-pagination-container phl pvs">
      <Pagination page={page} pages={pages} onPageChange={onPageChange} />
    </div>
  );
};

ReactTablePagination.propTypes = {
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
};

export default ReactTablePagination;
