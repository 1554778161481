import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as Arrow } from '../../images/empty_state_banner_arrow.svg';
import { ReactComponent as Play } from '../../images/empty_state_banner_play_icon.svg';
import { ReactComponent as PurpleLogoBlurry } from '../../images/template_empty_state_banner_bg_blurry_logo.svg';
import { ReactComponent as PurpleBannerBackground } from '../../images/template_empty_state_banner_bg_empty.svg';
import { ReactComponent as TemplateBannerBackground } from '../../images/empty_state_bg_templates.svg';
import template_empty_state_banner_video_thumbnail from '../../images/template_empty_state_banner_video_thumbnail.png';
import { ReactComponent as DynamicContentPageIcon } from '../../images/dynamic_content_empty_state_banner_icon.svg';
import dynamic_content_empty_state_banner_video_thumbnail from '../../images/dynamic_content_empty_state_banner_video_thumbnail.png';
import input_empty_state_banner_video_thumbnail from '../../images/input_empty_state_banner_video_thumbnail.png';
import { ReactComponent as InputPageIcon } from '../../images/input_empty_state_banner_icon.svg';
import { ReactComponent as DataSourcePageIcon } from '../../images/data_source_empty_state_banner_icon.svg';
import data_source_empty_state_banner_video_thumbnail from '../../images/data_source_empty_state_banner_video_thumbnail.png';
import { ReactComponent as BlueBannerBackground } from '../../images/data_source_empty_state_banner_bg_empty.svg';
import { ReactComponent as BlueLogoBlurry } from '../../images/data_source_empty_state_banner_bg_blurry_logo.svg';
import { ReactComponent as GreenLogoBlurry } from '../../images/home_empty_state_banner_bg_blurry_logo.svg';
import { ReactComponent as GreenBannerBackground } from '../../images/home_empty_state_banner_bg_empty.svg';
import home_empty_state_banner_video_thumbnail from '../../images/home_empty_state_banner_video_thumbnail.png';
import { ReactComponent as HomePageIcon } from '../../images/home_empty_state_banner_icon.svg';
import { ReactComponent as LockIcon } from '../../images/lock.svg';
import { Container, Heading, Columns, Image } from 'react-bulma-components';
import Modal from './modal/Modal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class EmptyStateBanner extends Component {
  constructor(props) {
    super(props);
  }

  bannerSpecs = {
    templates: {
      videoLink: 'https://www.youtube.com/embed/kNJtNHnWSNk',
      background: <TemplateBannerBackground className="full-width banner-background" preserveAspectRatio="none" />,
      blurryLogo: <BlueLogoBlurry className="full-width banner-blurry-logo" />,
      icon: null,
      videoThumbnail: template_empty_state_banner_video_thumbnail,
      videoName: 'Uploading a Template',
      bannerText: 'Start automating data-driven narratives by creating your first template.',
      bannerTitle: 'Creating Template',
      disclaimer: '',
    },
    dynamic_content: {
      videoLink: 'https://www.youtube.com/embed/xfZm9W_4vXM',
      background: <PurpleBannerBackground className="full-width banner-background" preserveAspectRatio="none" />,
      blurryLogo: <PurpleLogoBlurry className="full-width banner-blurry-logo" />,
      icon: (
        <div className="purple banner-icon-container mbs">
          <DynamicContentPageIcon className="banner-icon" />
        </div>
      ),
      videoThumbnail: dynamic_content_empty_state_banner_video_thumbnail,
      videoName: 'How to use Dynamic Content',
      bannerText: 'How to use Dynamic Content',
      bannerTitle: 'Add Dynamic Content',
      disclaimer: '',
    },
    inputs: {
      videoLink: 'https://www.youtube.com/embed/xfZm9W_4vXM?start=370',
      background: <PurpleBannerBackground className="full-width banner-background" preserveAspectRatio="none" />,
      blurryLogo: <PurpleLogoBlurry className="full-width banner-blurry-logo" />,
      icon: (
        <div className="purple banner-icon-container mbs">
          <InputPageIcon className="banner-icon" />
        </div>
      ),
      videoThumbnail: input_empty_state_banner_video_thumbnail,
      videoName: 'Creating Inputs',
      bannerText: 'How to create Inputs',
      bannerTitle: 'Creating Inputs',
      disclaimer: '',
    },
    data_sources: {
      videoLink: 'https://www.youtube.com/embed/bNqqXKIHp2s',
      background: <BlueBannerBackground className="full-width banner-background" preserveAspectRatio="none" />,
      blurryLogo: <BlueLogoBlurry className="full-width banner-blurry-logo" />,
      icon: (
        <div className="blue banner-icon-container mbs">
          <DataSourcePageIcon className="banner-icon" />
        </div>
      ),
      videoThumbnail: data_source_empty_state_banner_video_thumbnail,
      videoName: 'Creating Data Sources',
      bannerText: 'How to create Data Sources',
      bannerTitle: 'Creating Data Sources',
      disclaimer: (
        <div className="lock-icon">
          <LockIcon />
          &nbsp; We do not save any customer data on our servers.&nbsp;
          <a href="https://www.matik.io/security" target="_blank" rel="noreferrer" className="disclaimer-link">
            Learn More
          </a>
        </div>
      ),
    },
    home: {
      videoLink: 'https://www.youtube.com/embed/Zi47b7Mi8g8',
      background: <GreenBannerBackground className="full-width banner-background" preserveAspectRatio="none" />,
      blurryLogo: <GreenLogoBlurry className="full-width banner-blurry-logo" />,
      icon: (
        <div className="green banner-icon-container mbs">
          <HomePageIcon className="banner-icon" />
        </div>
      ),
      videoThumbnail: home_empty_state_banner_video_thumbnail,
      videoName: 'Welcome to Matik',
      bannerText:
        'This is your setup guide. It has everything you need to set up Matik and start automating data-driven stories. You can revisit these steps at anytime.',
      bannerTitle: this.props.firstName
        ? 'Welcome back to Matik, ' + this.props.firstName + '!'
        : 'Welcome back to Matik!',
      disclaimer: '',
    },
  };

  currentBannerSpecs = () => {
    return this.bannerSpecs[this.props.bannerToLoad] || {};
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };

  displayVideo = () => {
    if (this.currentBannerSpecs().videoLink) {
      this.props.openModal('emptyStateBannerModal');
    }
  };

  render() {
    const { buttons, ui } = this.props;
    const currentBannerSpecs = this.currentBannerSpecs();
    const isDisclaimerEmpty = currentBannerSpecs.disclaimer === '';
    const templatesVideoClass = this.props.bannerToLoad === 'templates' ? 'video-link-templates' : '';

    return (
      <Container className="is-fluid pan empty-state-banner-container">
        <Modal
          show={ui?.modal?.name === 'emptyStateBannerModal'}
          title={currentBannerSpecs.videoName}
          onClose={this.onClose}
          width={850}
        >
          <div className="card-content">
            <iframe
              width="750"
              height="400"
              src={currentBannerSpecs.videoLink}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen // eslint-disable-line react/no-unknown-property
            ></iframe>
          </div>
        </Modal>

        {currentBannerSpecs.background}
        {currentBannerSpecs.blurryLogo}
        {currentBannerSpecs.videoLink && (
          <div className={templatesVideoClass}>
            {this.props.bannerToLoad != 'templates' && <Arrow className="full-width banner-arrow" />}
            <Play className="full-width banner-play-icon" onClick={this.displayVideo} />
          </div>
        )}
        <Columns className={isDisclaimerEmpty ? 'banner-content' : 'banner-content-disclaimer'}>
          <Columns.Column size={6}>
            {currentBannerSpecs.icon}
            <Heading className="mbm" size={3}>
              {currentBannerSpecs.bannerTitle}
            </Heading>
            <Heading size={6} weight="light" className="mbl grey">
              {currentBannerSpecs.bannerText}
            </Heading>
            <div className="mbl">{buttons}</div>
            <Heading size={6} weight="light" className="disclaimer">
              {currentBannerSpecs.disclaimer}
            </Heading>
          </Columns.Column>
          <Columns.Column size={6} align="right">
            {currentBannerSpecs.videoLink && (
              <Image
                className={isDisclaimerEmpty ? 'video-thumbnail' : 'video-thumbnail-disclaimer'}
                src={currentBannerSpecs.videoThumbnail}
                fallback="https://via.placeholder.com/250x150"
              />
            )}
          </Columns.Column>
        </Columns>
      </Container>
    );
  }
}

EmptyStateBanner.propTypes = {
  bannerToLoad: PropTypes.string,
  buttons: PropTypes.element,
  firstName: PropTypes.string,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(EmptyStateBanner);
