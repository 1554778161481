import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { ReactComponent as ErrorCircleX } from '../../images/error_circle_x.svg';
import { ReactComponent as SuccessCheck } from '../../images/success_check.svg';

class InputWithError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputColor: null,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setInputColor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error || this.props.success !== prevProps.success) {
      this.setInputColor();
    }
  }

  render() {
    let className = 'input';
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }
    if (this.state.inputColor) {
      className += ` ${this.state.inputColor}`;
    }
    const { isAuthTokenTest, ...passThroughProps } = this.props;
    return (
      <React.Fragment>
        {this.props.help && <Form.Help>{this.props.help}</Form.Help>}
        <input
          {...passThroughProps}
          className={className}
          ref={this.inputRef}
          onChange={(e) => this.props.onChange(e, this.inputRef, this.props.name)}
          onBlur={() => (isAuthTokenTest ? this.setState({ inputColor: null }) : false)}
          onFocus={(e) => {
            this.props.onFocus ? this.props.onFocus(e, this.props.idx, this.props.value) : null;
          }}
        />
        {this.props.error && (
          <React.Fragment>
            {isAuthTokenTest && <ErrorCircleX className="auth-token-error-icon" />}
            {!this.props.noHelp && <Form.Help color="danger">{this.props.error}</Form.Help>}
          </React.Fragment>
        )}
        {this.props.success && isAuthTokenTest && <SuccessCheck className="auth-token-success-icon" />}
      </React.Fragment>
    );
  }

  setInputColor = () => {
    if (this.props.error) {
      this.setState({ inputColor: 'danger' });
    } else if (this.props.success) {
      this.setState({ inputColor: 'success' });
    } else {
      this.setState({ inputColor: null });
    }
  };
}

InputWithError.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  help: PropTypes.string,
  noHelp: PropTypes.bool,
  isAuthTokenTest: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  idx: PropTypes.number,
  value: PropTypes.string,
};

export default InputWithError;
