import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import EnterpriseUpsellTag from '../shared/EnterpriseUpsellTag';

class UpsellDetailedModal extends Component {
  render() {
    return (
      <div className="product-upsell-modal detailed">
        <span className={`product-upsell-image-container mbl ${this.props.featureImageClassName || ''}`}>
          <img
            width={this.props.featureImageWidth || 500}
            src={this.props.featureImage}
            alt={this.props.featureImageAlt}
          />
        </span>
        <BrowserRouter>
          <EnterpriseUpsellTag feature={this.props.feature} />
        </BrowserRouter>
        <span className="product-upsell-header mtl mbm">{this.props.featureHeader}</span>
        <span className="product-upsell-content mbl">{this.props.featureDescription}</span>
      </div>
    );
  }
}

UpsellDetailedModal.propTypes = {
  featureImage: PropTypes.string,
  featureImageAlt: PropTypes.string,
  featureImageWidth: PropTypes.number,
  featureImageClassName: PropTypes.string,
  featureHeader: PropTypes.string,
  featureName: PropTypes.string,
  featureDescription: PropTypes.string,
  feature: PropTypes.string,
};

export default UpsellDetailedModal;
