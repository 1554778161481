import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';

const LookerOutputScreenshot = (props) => {
  const outputScreenshot = !!props.queryObj.query.output_snapshot;
  const onChange = (e) => {
    const value = e.target.checked;
    const updatedQueryObj = { ...props.queryObj };
    updatedQueryObj.query = { ...updatedQueryObj.query, output_snapshot: value };
    props.updateQueryObj(updatedQueryObj);
  };
  return (
    <div className="mbl">
      <CheckboxWithLabel
        checked={outputScreenshot}
        id="looker-output-screenshot"
        name="looker-output-screenshot"
        disabled={props.isReadOnly}
        label="Output as screenshot of Looker chart"
        onChange={onChange}
      />
    </div>
  );
};

LookerOutputScreenshot.propTypes = {
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
};

export default LookerOutputScreenshot;
