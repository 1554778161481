import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as TableIcon } from '../../../images/table_icon.svg';
import { ReactComponent as DismissIcon } from '../../../images/dismiss_icon.svg';
import { ReactComponent as CheckmarkIcon } from '../../../images/checkmark_icon.svg';
import Button from '../../lib/Button';

export default function SidepaneListItem(props) {
  let url = `https://docs.google.com/${props.type}/d/${props.id}/edit`;
  let button = null;
  let info = `Google ${props.type}`;
  let icon = <CheckmarkIcon />;
  let docIcon = <TableIcon />;
  if (props.status_code !== 200) {
    icon = <DismissIcon />;

    button = (
      <Button
        category="secondary"
        onClick={(e) => {
          e.preventDefault();
          window.open(url, '_blank');
        }}
      >
        Request Permission
      </Button>
    );
    if (props.status_code === 403) {
      info += ' • View Only Access is insufficient';
    } else {
      info += ' • No Access';
    }
  }
  return (
    <li className="has-light-gray-border" key={`${props.type}_${props.id}`}>
      <div className="list-item-inner">
        <div className="flex items-center">
          <div className="list-item-icon">
            <span className="list-item-icon-inner check-icon">{icon}</span>
            <span className="list-item-icon-inner">{docIcon}</span>
          </div>
          <div className="info">
            <h2>{props.name}</h2>
            <p>{info}</p>
          </div>
        </div>
        {button}
      </div>
    </li>
  );
}

SidepaneListItem.propTypes = {
  name: PropTypes.string,
  response: PropTypes.number,
  id: PropTypes.string,
  status_code: PropTypes.number,
  type: PropTypes.string,
};
