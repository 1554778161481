import moment from 'moment';
import React from 'react';
import Constants from '../components/Constants';

// Dates in general can be a massive rabbit hole of complexity when dealing with locale + display formatting
// Even the locale short code can differ between browsers and OSes (eg 'en-US' vs 'en_US', 'en-us' vs 'en-US')
// MDN refers to BCP 47 for the locale codes: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
// An example list of BCP 47 codes can be found here: https://www.techonthenet.com/js/language_tags.php
// We are not taking advantage of MomentJS to handle the locale heavy lifting because we'd have to import all the supported locale modules separately, which will probably blow up the app size
// In an effort to contain the scope, we will be making a few assumptions
//   Only USA goes by MM/DD/YYYY format
//   Only East Asian Countries (China, Japan, Korea) goes by YYYY/MM/DD format
//   Everyone else goes by DD/MM/YYYY format

/* eslint-disable react/display-name */
const localeDateFormatterUtils = {
  getLocaleDateFormatStringFromLocale: (localeString) => {
    const formattedLocaleString = localeString.toLowerCase().replace(/_/g, '-');

    switch (formattedLocaleString) {
      case 'en-us':
        return Constants.DATE_FORMATS.usa;

      case 'zh-cn':
      case 'ja-jp':
      case 'ko-kr':
      case 'zh-hk':
      case 'zh-mo':
      case 'zh-sh':
      case 'zh-tw':
      case 'zh-hans-cn':
        return Constants.DATE_FORMATS.east_asia;

      default:
        return Constants.DATE_FORMATS.default;
    }
  },

  getUserLocale: () => {
    return window.navigator.userLanguage || window.navigator.language || 'en-US';
  },

  getDateStringFromMomentObject: (momentDateObject) => {
    // https://momentjs.com/docs/#/displaying/
    const locale = localeDateFormatterUtils.getUserLocale();
    const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);

    if (!momentDateObject) {
      const today = new Date();
      const todayMoment = moment(today);
      return todayMoment.format('Do MMM, YYYY');
    }

    switch (localeDateFormat) {
      case Constants.DATE_FORMATS.usa:
        return momentDateObject.format('MMM Do, YYYY');

      case Constants.DATE_FORMATS.east_asia:
        return momentDateObject.format('YYYY, MMM Do');

      default:
        return momentDateObject.format('Do MMM, YYYY');
    }
  },

  formatDateToLocaleFormat: (column) => {
    const locale = localeDateFormatterUtils.getUserLocale();
    const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
    const shouldReformatDate = column.id === 'updated_on' || column.id === 'created_on'; // the id key here determines which value gets rendered in <ReactTable>

    if (shouldReformatDate && !column.Cell) {
      return {
        ...column,
        Cell: (row) => {
          const formattedDate = moment(row.value).format(localeDateFormat);
          return <span>{formattedDate}</span>;
        },
      };
    } else {
      return column;
    }
  },

  formatDatesInArrayOfObjects: (arrayOfDataWithDates) =>
    arrayOfDataWithDates.map((column) => localeDateFormatterUtils.formatDateToLocaleFormat(column)),
};

export default localeDateFormatterUtils;
