import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Spreadsheet from 'x-data-spreadsheet';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import WithSpreadSheetContext from './WithSpreadSheetContext';

const MemoSpreadsheet = (props) => {
  const [spreadsheet, setSpreadsheet] = useState(null);
  const spreadsheetContainer = useRef(null);
  const { cellSelection } = props.SpreadSheetContext;

  useEffect(() => {
    if (!spreadsheet && props.rowData) {
      const rows = {
        len: props.rowData.length,
      };
      props.rowData.forEach((r, idx) => {
        const cells = {};
        if (Array.isArray(r)) {
          r.forEach((c, cidx) => {
            cells[cidx] = { text: c.value };
          });
          rows[idx] = { cells: cells };
        }
      });

      const spreadsheet = new Spreadsheet('#x-spreadsheet-div', {
        mode: 'read',
        settings: { autoFocus: false },
        showToolbar: false,
        showBottomBar: false,
        font: { name: 'RM Neue' },
        view: {
          height: () => (spreadsheetContainer.current ? spreadsheetContainer.current.clientHeight : '292px'),
        },
      });
      spreadsheet.loadData([
        {
          cols: {
            len: props.rowData.length,
          },
          rows,
        },
      ]);
      spreadsheet.on('cell-selected', (_, rowIndex, columnIndex) => {
        props.onCellSelect({
          startRowIndex: rowIndex,
          startColumnIndex: columnIndex,
          endColumnIndex: columnIndex,
          endRowIndex: rowIndex,
        });
      });
      spreadsheet.on(
        'cells-selected',
        (_, { sri: startRowIndex, sci: startColumnIndex, eri: endRowIndex, eci: endColumnIndex }) => {
          props.onCellSelect({ startRowIndex, startColumnIndex, endColumnIndex, endRowIndex });
        },
      );
      setSpreadsheet(spreadsheet);
    }
  }, [props.rowData]);

  useEffect(() => {
    if (spreadsheet && cellSelection) {
      spreadsheet.sheet.selector.set(cellSelection.startRowIndex, cellSelection.startColumnIndex, true);
      spreadsheet.sheet.selector.setEnd(cellSelection.endRowIndex, cellSelection.endColumnIndex);
      spreadsheet.sheet.table.render();
      if (cellSelection.focusEl) {
        cellSelection.focusEl.focus();
      }
    }
  }, [cellSelection, spreadsheet]);

  return <div id="x-spreadsheet-div" ref={spreadsheetContainer}></div>;
};

MemoSpreadsheet.propTypes = {
  rowData: PropTypes.array,
  onCellSelect: PropTypes.func,
  SpreadSheetContext: PropTypes.object,
};

export default React.memo(WithSpreadSheetContext(MemoSpreadsheet));
