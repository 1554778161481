import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import TemplateConditionsForm from './TemplateConditionsForm';
import { Heading } from 'react-bulma-components';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import useTemplate from 'lib/hooks/useTemplate';
import { useDispatch } from 'react-redux';
import { closeFullScreenModal } from 'redux/ui/action';
function TemplateConditionsModal({ currentTemplate, show }) {
  const [primaryButtonOnClick, setPrimaryButtonOnClick] = useState();
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(true);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeFullScreenModal());
  };

  const { invalidate: invalidateTemplate } = useTemplate();

  const headerCenter = <Heading className="full-screen-modal-title">Add Send Conditions</Heading>;

  return (
    <FullScreenModal
      show={show}
      onClose={onClose}
      headerCenter={headerCenter}
      showDefaultFooter={true}
      backgroundColor="white"
      primaryButtonText={'Add Condition'}
      primaryButtonOnClick={primaryButtonOnClick}
      primaryButtonDisabled={primaryButtonDisabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onClose}
    >
      <TemplateConditionsForm
        currentTemplate={currentTemplate}
        onConditionsUpdated={() => {
          invalidateTemplate(currentTemplate.id);
        }}
        setPrimaryButtonOnClick={setPrimaryButtonOnClick}
        setPrimaryButtonDisabled={setPrimaryButtonDisabled}
        onClose={onClose}
        width="full"
      />
    </FullScreenModal>
  );
}

TemplateConditionsModal.propTypes = {
  show: PropTypes.bool,
  currentTemplate: PropTypes.object,
};

export default TemplateConditionsModal;
