import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast, Slide } from 'react-toastify';
import moment from 'moment';

import { MConfirm } from './components/shared/Alerts';

import 'react-dates/initialize';
import './App.sass';
import CoreApp from './CoreApp';
import Updater from './Updater';

// Update moment calendar formatting for brevity
moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday at] LT',
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: 'L',
    nextWeek: 'dddd [at] LT',
    sameElse: 'L',
  },
});

class App extends Component {
  render() {
    return (
      <BrowserRouter getUserConfirmation={this.getConfirmation}>
        <div className="body">
          <CoreApp />
          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            transition={Slide}
            hideProgressBar={true}
            autoClose={5000}
            closeButton={false}
            className="matik-toast"
            enableMultiContainer
            containerId="default"
          />
          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            transition={Slide}
            hideProgressBar={true}
            autoClose={5000}
            closeButton={false}
            className="banner-toast"
            enableMultiContainer
            containerId="banner"
            draggable={false}
          />
          <Tooltip className="matik-tooltip" noArrow={true} id="matik-tooltip" />
          <Updater />
        </div>
      </BrowserRouter>
    );
  }

  getConfirmation = (message, callback) => {
    MConfirm('Navigate away?', message, 'warning', callback, 'Leave Page', 'Continue Editing');
  };
}

App.propTypes = {
  location: PropTypes.object,
  setShouldReload: PropTypes.func,
};

export default App;
