import React from 'react';
import { ReactComponent as CloseIcon } from '../../../svg/close_14.svg';
import PropTypes from 'prop-types';
import Icon from '../../lib/Icon';

export const FullScreenModalHeader = (props) => (
  <header className="full-screen-modal-header">
    <button
      onClick={props.onBackClick}
      className="button is-tertiary"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Icon name="chevron_left" size={16} theme="regular" />
      Back
    </button>
    {props.headerCenter}
    <button
      className="button is-close"
      aria-label="close"
      onClick={props.onClose}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <CloseIcon />
    </button>
  </header>
);

FullScreenModalHeader.propTypes = {
  onClose: PropTypes.func,
  headerCenter: PropTypes.any,
  backgroundColor: PropTypes.string,
  onBackClick: PropTypes.func,
};
