import React from 'react';
import PropTypes from 'prop-types';
import { Hero, Heading, Container } from 'react-bulma-components';

const DefaultEmptyState = ({ emptyInfo, ...props }) => {
  const showContainer = !!emptyInfo.emptyImageUrl || !!emptyInfo.emptyTitle || !!emptyInfo.emptyPrompt;
  return (
    <Hero className="is-fullheight-with-navbar">
      <Hero.Body>
        {showContainer && (
          <Container className="has-text-centered narrow-container">
            {!!emptyInfo.emptyImageUrl && (
              <Heading size={5} subtitle>
                <div className="empty-image-container">
                  <img width="256px" src={emptyInfo.emptyImageUrl} alt="Empty indication" />
                  {emptyInfo.emptyImageIcon}
                </div>
              </Heading>
            )}
            <Heading size={4} className="has-text-grey-dark">
              {emptyInfo.emptyTitle}
            </Heading>
            <Heading size={5} subtitle className="has-text-grey-dark">
              {emptyInfo.emptyPrompt}
            </Heading>
            {!props.isBannerDisplayed && props.buttons}
          </Container>
        )}
        {emptyInfo.emptyTemplateChild}
      </Hero.Body>
    </Hero>
  );
};

DefaultEmptyState.propTypes = {
  isBannerDisplayed: PropTypes.bool,
  emptyInfo: PropTypes.object,
  buttons: PropTypes.object,
};

export default DefaultEmptyState;
