import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Tile, Message } from 'react-bulma-components';
import Constants from '../../../Constants';
import InputsList from '../InputsList';
import SampleData from '../SampleData';
import TestResult from '../../../shared/TestResult';
import ConditionalContentCondition from '../../../shared/ConditionalContentCondition';
import { ReactComponent as AddCircleFilled } from '../../../../svg/add_circle_filled.svg';
import Button from '../../../lib/Button';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

function ConditionalContentForm(props) {
  const context = useContext(DynamicContentContext);

  const generateEmptyClause = () => {
    const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditional;
    const inputName = Object.keys(context.existingInputs)[0];
    return { param: inputName, operator: supported_operators[0], operand: '' };
  };

  const addEmptyCondition = (e, queryObj) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, queryObj);
    if (!updatedQueryObj.conditions) {
      updatedQueryObj.conditions = [];
    }
    updatedQueryObj.conditions.push({
      clauses: [generateEmptyClause()],
      dynamicContent: props.dynamicContentArr ? props.dynamicContentArr[0].id : 0,
    });
    context.onQueryObjectUpdate(updatedQueryObj);
  };

  const updateQuery = (updatedQueryObj) => {
    context.onQueryObjectUpdate(updatedQueryObj);
  };

  const queryObj = context.query ? JSON.parse(context.query) : {};
  const body = (
    <React.Fragment>
      {queryObj.conditions &&
        queryObj.conditions.map((condition, idx) => (
          <ConditionalContentCondition
            key={idx}
            condition={condition}
            conditionIndex={idx}
            formRef={props.formRef}
            inputs={context.existingInputs}
            queryObj={queryObj}
            isReadOnly={context.isReadOnly}
            updateQuery={updateQuery}
            dynamicContentArr={props.dynamicContentArr}
            generateEmptyClause={generateEmptyClause}
          />
        ))}
      <Form.Field className="ptm">
        <Form.Control>
          <Button
            onClick={(e) => addEmptyCondition(e, queryObj)}
            status={
              context.isReadOnly || Object.keys(context.existingInputs || {}).length === 0 ? 'disabled' : 'default'
            }
          >
            <AddCircleFilled className="has-text-white mrs" />
            Add Condition Group
          </Button>
        </Form.Control>
      </Form.Field>
    </React.Fragment>
  );

  return (
    <Tile kind="parent" className="is-paddingless" vertical>
      <Tile kind="parent" className="is-paddingless">
        <Tile kind="child" className="is-paddingless is-shadowless query-grandparent" size={12}>
          <Message className="mtn mbl" color="message-info">
            <Message.Body>
              This dynamic content will return the dynamic content from the first condition group that returns true
            </Message.Body>
          </Message>
          <Form.Field className="box is-paddingless is-shadowless has-light-gray-border mbl pbm">
            <Form.Label className="plm ptm">Included Inputs</Form.Label>
            <InputsList
              inputsInQueryString={props.inputsInQueryString}
              key={props.selectedInputName}
              isReadOnly={context.isReadOnly}
              selectedInputName={props.selectedInputName}
              toggleCurrentInput={props.toggleCurrentInput}
            />
          </Form.Field>
          {body}
          <TestResult testResult={props.testResult} />
        </Tile>
      </Tile>
      <SampleData dynamicContentType={props.testResult?.dynamicContentType} testResult={props.testResult} />
    </Tile>
  );
}
ConditionalContentForm.propTypes = {
  dynamicContentArr: PropTypes.array,
  selectedInputName: PropTypes.string,
  inputsInQueryString: PropTypes.object,
  toggleCurrentInput: PropTypes.func,
  testResult: PropTypes.object,
  formRef: PropTypes.object,
};

export default ConditionalContentForm;
