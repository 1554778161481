import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import Button from '../../../lib/Button';
import ApiInputField from '../../../shared/ApiInputField';
import { Form } from 'react-bulma-components';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import Constants from 'components/Constants';
import utils from 'lib/utils';
class RestResults extends Component {
  render() {
    const results = this.props.queryObj.results ? this.props.queryObj.results : [];
    let body = (
      <Button
        category="secondary"
        onClick={this.addResultColumn}
        status={this.context.isReadOnly ? 'disabled' : 'default'}
      >
        Add Result Columns
      </Button>
    );
    if (results.length > 0) {
      if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
        const senderFieldNames = ['from_name', 'from_email', 'reply_email'];
        body = (
          <React.Fragment>
            {senderFieldNames.map((senderField, idx) => {
              const columnName = this.context.multiFieldMapping[senderField] || '';
              const matchingResults = results.filter((result) => result.colName === columnName);
              const matchingResult = matchingResults.length ? matchingResults[0] : {};
              return this.renderResult(matchingResult, idx, results, senderField);
            })}
            {this.context.queryStringError && <p className="help is-danger">{this.context.queryStringError}</p>}
          </React.Fragment>
        );
      } else {
        body = (
          <React.Fragment>
            {results.map((result, idx) => {
              return this.renderResult(result, idx, results);
            })}
          </React.Fragment>
        );
      }
    }
    return body;
  }

  renderResult(result, idx, results, senderField = '') {
    let helpText = '';
    helpText = utils.toTitleCase(senderField.replace('_', ' '));
    return (
      <>
        <Form.Help className="mt-2">{helpText}</Form.Help>
        <div className="flex gap-2">
          {this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER ? (
            <>{this.renderResultRow(result, idx, senderField)}</>
          ) : (
            <AddRemoveButtons
              addEntity={this.addResultColumn}
              includeAdd={idx === results.length - 1}
              idx={idx}
              removeEntity={(e) => this.removeResultColumn(e, idx)}
              key={idx}
              isReadOnly={this.context.isReadOnly}
            >
              {this.renderResultRow(result, idx)}
            </AddRemoveButtons>
          )}
        </div>
      </>
    );
  }

  renderResultRow = (result, idx, fieldName) => {
    return (
      <>
        <ApiInputField
          method="Column Name"
          apiVal={result.colName}
          inputs={this.context.existingInputs}
          isReadOnly={this.context.isReadOnly}
          includeBaseUrl={false}
          allowInputs={true}
          onChangeWithInputs={(newValue) => this.onColNameChange(newValue, idx, fieldName)}
          isInputPopoverDisabled={this.props.entityType === 'input'}
        />
        <ApiInputField
          method="Result Source"
          apiVal={result.val}
          inputs={this.context.existingInputs}
          isReadOnly={this.context.isReadOnly}
          includeBaseUrl={false}
          allowInputs={true}
          onChangeWithInputs={(newValue) => this.onResultSourceChange(newValue, idx)}
          isInputPopoverDisabled={this.props.entityType === 'input'}
        />
      </>
    );
  };

  addResultColumn = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (!updatedQueryObj.results) {
      updatedQueryObj.results = [];
    }
    updatedQueryObj.results.push({ colName: '', val: '' });
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  removeResultColumn = (e, idx, fieldName) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    let results = [];
    for (let i = 0; i < updatedQueryObj.results.length; i++) {
      if (i !== idx) {
        results.push(updatedQueryObj.results[i]);
      }
    }

    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      this.context.onSenderFieldUpdate(fieldName, '');
    }

    updatedQueryObj.results = results;
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onColNameChange = (newValue, idx, fieldName) => {
    const colName = newValue;
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.results[idx]['colName'] = colName;
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      this.context.onSenderFieldUpdate(fieldName, colName);
    }
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onResultSourceChange = (newValue, idx) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.results[idx]['val'] = newValue;
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };
}

RestResults.contextType = DynamicContentContext;

RestResults.propTypes = {
  entityType: PropTypes.string,
  queryObj: PropTypes.object,
};

export default RestResults;
