import React from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../../images/close.svg';

function UploadedFileBanner(props) {
  return (
    <div className="uploaded-file-banner">
      <div>
        <img src={props.uploadedIconSrc} alt="uploaded file icon" className="uploaded-file-icon" />
        <span>{props.uploadedFileName}</span>
      </div>
      <div>
        <img onClick={props.onCancel} src={closeIcon} alt="cancel upload" className="clear-uploaded-file" />
      </div>
    </div>
  );
}

UploadedFileBanner.propTypes = {
  onCancel: PropTypes.func.isRequired,
  uploadedFileName: PropTypes.string.isRequired,
  uploadedIconSrc: PropTypes.string.isRequired,
};

export default UploadedFileBanner;
