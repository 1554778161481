import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { find, findIndex, forEach, isEqual, map, pull, pullAt } from 'lodash';
import moment from 'moment';
import { Select } from '../FormSelect';
import { connectRefinementList } from 'react-instantsearch-dom';
import DateRangeRefinement from './DateRangeRefinement';
import InputNameAndValueRefinement from './InputNameAndValueRefinement';
import ItemTagsRefinement from './ItemTagsRefinement';

class RefinementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      paramInputs: ['input-0'],
      inputKeys: { 'input-0': '' },
      inputValues: { 'input-0': [] },
      selectedInputValues: [],
      startDate: null,
      endDate: null,
      activeTags: [],
    };
  }

  componentDidMount() {
    if (this.props.label === 'Template') {
      const urlParams = new URLSearchParams(window.location.search);
      const templateNames = urlParams.getAll('template');
      if (templateNames.length) {
        this.props.refine(templateNames);
      }
    }
  }

  componentDidUpdate() {
    if (this.props.searchState && this.props.searchState.cancelledFilter) {
      if (this.props.searchState.cancelledFilter === 'updated_on') {
        if (this.state.startDate || this.state.endDate) {
          this.setState({ startDate: null, endDate: null });
        }
      }
      if (
        this.props.searchState.cancelledFilter.originalKey &&
        this.props.searchState.cancelledFilter.originalKey.includes('params.')
      ) {
        let currentInput;
        forEach(this.state.inputKeys, (v, k) => {
          const paramKey = this.props.searchState.cancelledFilter.originalKey.replace('params.', '');
          if (v.value === paramKey) {
            currentInput = k;
          }
        });
        if (currentInput) {
          const newInputValues = { ...this.state.inputValues };
          const updatedKeys = { ...this.state.inputKeys };
          if (newInputValues[currentInput]) {
            let updatedInput;
            let updatedKey = updatedKeys[currentInput];
            if (Array.isArray(newInputValues[currentInput])) {
              updatedInput = [...newInputValues[currentInput]];
            } else {
              updatedInput = [newInputValues[currentInput]];
            }
            const matchingItem = find(updatedInput, { label: this.props.searchState.cancelledFilter.value });
            if (matchingItem) {
              pull(updatedInput, matchingItem);
              if (updatedInput.length === 1) {
                updatedInput = updatedInput[0];
              } else if (updatedInput.length < 1) {
                updatedInput = null;
                updatedKey = '';
              }
            }
            this.setState({
              inputValues: { ...this.state.inputValues, [currentInput]: updatedInput },
              inputKeys: { ...this.state.inputKeys, [currentInput]: updatedKey },
            });
          }
        }
      }
      let updatedSearchState = { ...this.props.searchState };
      delete updatedSearchState.cancelledFilter;
      this.props.onSearchStateChange(updatedSearchState);
    }
  }

  render() {
    const { items, label } = this.props;
    const selectedItems = items
      .filter((item) => item.isRefined)
      .map((item) => {
        return { label: `${item.label} (${item.count})`, value: item.label, original_label: item.label };
      });
    const options = items.map((item) => ({ label: `${item.label} (${item.count})`, value: item.label }));

    if (this.props.searchState && this.props.searchState.cancelledFilter) {
      if (
        this.props.searchState.cancelledFilter.originalKey &&
        !this.props.searchState.cancelledFilter.originalKey.includes('params.')
      ) {
        const matchingItem = findIndex(selectedItems, { original_label: this.props.searchState.cancelledFilter.value });
        if (matchingItem > -1) {
          pullAt(selectedItems, matchingItem);
        }
      }
    }

    if (this.props.templateTags) {
      const activeTags = map(selectedItems, (item) => item.original_label);
      if (!isEqual(this.state.activeTags, activeTags)) {
        this.setState({ activeTags });
      }
    }

    let body = '';
    if (label === 'Date Generated') {
      body = (
        <DateRangeRefinement
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDaterangeSelect={this.onDaterangeSelect}
          focusedInput={this.state.focusedInput}
          setFocusedInput={this.setFocusedInput}
          renderDatePresets={this.renderDatePresets}
        />
      );
    } else if (this.props.attribute === 'params' && this.props.inputs) {
      body = (
        <InputNameAndValueRefinement
          paramInputs={this.state.paramInputs}
          inputKeys={this.state.inputKeys}
          inputValues={this.state.inputValues}
          onInputKeySelect={this.onInputKeySelect}
          onInputValueSelect={this.onInputValueSelect}
          selectedInputValues={this.state.selectedInputValues}
          appendInput={this.appendInput}
          deleteInput={this.deleteInput}
          inputs={this.props.inputs}
        />
      );
    } else if (this.props.templateTags) {
      body = (
        <ItemTagsRefinement
          itemTags={this.props.itemTags}
          activeTags={this.state.activeTags}
          onTemplateTagFilterToggle={this.onTemplateTagFilterToggle}
        />
      );
    } else {
      body = (
        <Select
          classNamePrefix="matik-select"
          isMulti={true}
          placeholder={this.props.attribute !== 'params' ? `Filter by ${label}` : ''}
          value={selectedItems}
          isClearable={false}
          className={`matik-refinement ${this.props.filtersAreShownInSidepane ? 'add-extra-height' : ''}`}
          options={options}
          onChange={(obj, action) => this.refinementChange(obj, action, null)}
          menuPortalTarget={null}
        />
      );
    }

    return (
      <React.Fragment>
        <Form.Label>{this.props.attribute !== 'params' ? label : ''}</Form.Label>
        {body}
      </React.Fragment>
    );
  }

  refinementChange = (obj, action, input) => {
    let inputValues = [];
    if (obj.length) {
      inputValues = map(obj, (option) => option.value);
    } else if (action.option) {
      inputValues = action.option.value;
    }

    if (action.action === 'select-option') {
      if (input) {
        this.props.refine([{ [this.state.inputKeys[input].value]: inputValues }]);
      } else {
        this.props.refine(inputValues);
      }
    } else if (action.action === 'select-all-options') {
      if (input) {
        this.props.refine([{ [this.state.inputKeys[input].value]: inputValues }]);
      } else {
        this.props.refine(inputValues);
      }
    } else if (action.action === 'remove-value') {
      if (input) {
        this.props.refine([{ [this.state.inputKeys[input].value]: inputValues }]);
      } else {
        this.props.refine(inputValues);
      }
    } else if (action.action === 'clear') {
      if (input) {
        this.props.refine([{ [this.state.inputKeys[input].value]: inputValues }]);
      } else {
        this.props.refine(action.option);
      }
    } else if (action.action === 'daterange') {
      this.props.refine(action.value);
    } else if (action.action === 'create-option') {
      this.props.refine([{ [this.state.inputKeys[input].value]: inputValues }]);
    }
    if (this.props.updateIsFetchingPresentation) {
      this.props.updateIsFetchingPresentation();
    }
  };

  onTemplateTagFilterToggle = (tag) => {
    let action = 'select-option';
    const updatedActiveTags = [...this.state.activeTags];
    if (this.state.activeTags.includes(tag)) {
      action = 'remove-value';
      pull(updatedActiveTags, tag);
    } else {
      updatedActiveTags.push(tag);
    }

    const refinement = {
      action: action,
      option: {
        value: updatedActiveTags,
      },
    };
    if (action === 'remove-value') {
      refinement['removedValue'] = {
        value: updatedActiveTags,
      };
    }
    this.refinementChange([], refinement);
  };

  onDaterangeSelect = (dates) => {
    this.setState({ startDate: dates.startDate, endDate: dates.endDate });
    if (dates.startDate && dates.endDate) {
      this.refinementChange(
        {},
        {
          action: 'daterange',
          value: [
            moment(dates.startDate).isValid() ? dates.startDate.startOf('day').unix() : null,
            moment(dates.endDate).isValid() ? dates.endDate.endOf('day').unix() : null,
          ],
        },
        null,
      );
    } else if (!dates.startDate && !dates.endDate) {
      this.refinementChange(
        {},
        {
          action: 'daterange',
          value: [null, null],
        },
        null,
      );
    }
  };

  onInputKeySelect = (input, obj) => {
    this.setState({
      inputKeys: { ...this.state.inputKeys, [input]: obj },
      selectedInputValues: map(this.props.inputs.params[obj.value], (v) => ({ label: v, value: v })),
    });
  };

  onInputValueSelect = (input, obj, action) => {
    if (!this.state.inputKeys[input]) {
      return false;
    }
    this.setState({ inputValues: { ...this.state.inputValues, [input]: obj } });
    if (action.action === 'clear') {
      obj = [];
    }
    this.refinementChange(obj, action, input);
  };

  appendInput = () => {
    const newInput = `input-${this.state.paramInputs.length}`;
    this.setState((prevState) => ({ paramInputs: prevState.paramInputs.concat([newInput]) }));
  };

  deleteInput = (input) => {
    let paramInputs = this.state.paramInputs;
    let inputKeys = this.state.inputKeys;
    let inputValues = this.state.inputValues;
    if (inputValues[input]) {
      this.refinementChange(null, { action: 'clear' }, input);
    }
    inputKeys[input] = '';
    inputValues[input] = '';
    pull(paramInputs, input);
    this.setState({ paramInputs, inputKeys, inputValues });
  };

  renderDatePresets = () => {
    const today = moment();
    const presets = [
      {
        text: 'Last Week',
        start: moment().subtract(1, 'week'),
        end: today,
      },
      {
        text: 'Last Month',
        start: moment().subtract(1, 'month'),
        end: today,
      },
      {
        text: 'Last Three Months',
        start: moment().subtract(3, 'month'),
        end: today,
      },
      {
        text: 'Last Six Months',
        start: moment().subtract(6, 'month'),
        end: today,
      },
      {
        text: 'Last Year',
        start: moment().subtract(1, 'year'),
        end: today,
      },
    ];

    return (
      <div>
        {presets.map(({ text, start, end }) => {
          let className = 'button is-small is-secondary mls mbs';
          const isSelected = start.isSame(this.state.startDate, 'day') && end.isSame(this.state.endDate, 'day');
          if (isSelected) {
            className = className + ' is-active';
          }

          return (
            <button className={className} key={text} type="button" onClick={(e) => this.onPresetClick(e, start, end)}>
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  onPresetClick = (e, start, end) => {
    this.onDaterangeSelect({ startDate: start, endDate: end });
    // Close the date range picker.
    this.setState({ focusedInput: null });
    // Fix sidebar scrolling once date range picker is closed
    const sidebar = document.querySelector('.sidebar');
    sidebar.classList.add('overflow-y-auto');
  };

  setFocusedInput = (focusedInput) => {
    this.setState({ focusedInput });
  };
}

RefinementList.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func,
  label: PropTypes.string,
  initialValues: PropTypes.object,
  inputs: PropTypes.object,
  attribute: PropTypes.string,
  searchState: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  itemTags: PropTypes.array,
  activeTags: PropTypes.array,
  templateTags: PropTypes.bool,
  updateIsFetchingPresentation: PropTypes.func,
  filtersAreShownInSidepane: PropTypes.bool,
};

export default connectRefinementList(RefinementList);
