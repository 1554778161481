import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'react-bulma-components';
import Icon from 'components/lib/Icon';
import { flowMenuItems } from './Constants';

function FlowsSidebar({ activeSidebarMenuItem, baseUrl, counts, isAdmin }) {
  const history = useHistory();

  const handleClick = (item) => {
    history.push(`${baseUrl}/flows/menu/${item}`);
  };

  return (
    <div className="flows-sidebar narrow-sidebar right-border">
      <Menu>
        <Menu.List>
          <Menu.List.Item
            className="sidebar-item"
            active={activeSidebarMenuItem === flowMenuItems.PROCESSING_FLOWS}
            onClick={() => handleClick(flowMenuItems.PROCESSING_FLOWS)}
          >
            <div className="flex items-center justify-center">
              <Icon name="arrow_clockwise" size={20} theme="regular" />
              <span className="ml-2">Processing Flows</span>
            </div>
            <div className="count">{counts.processing_flows.toLocaleString('en', { useGrouping: true })}</div>
          </Menu.List.Item>
          <Menu.List.Item
            className="sidebar-item"
            active={activeSidebarMenuItem === flowMenuItems.SCHEDULED_FLOWS}
            onClick={() => handleClick(flowMenuItems.SCHEDULED_FLOWS)}
          >
            <div className="flex items-center justify-center">
              <Icon name="arrow_repeat_all" size={20} theme="regular" />
              <span className="ml-2">Scheduled Flows</span>
            </div>
            <div className="count">{counts.scheduled_flows.toLocaleString('en', { useGrouping: true })}</div>
          </Menu.List.Item>
          <Menu.List.Item
            className="sidebar-item"
            active={activeSidebarMenuItem === flowMenuItems.MANUAL_FLOWS}
            onClick={() => handleClick(flowMenuItems.MANUAL_FLOWS)}
          >
            <div className="flex items-center justify-center">
              <Icon name="arrow_repeat_all_off" size={20} theme="regular" />
              <span className="ml-2">Manual Flows</span>
            </div>
            <div className="count">{counts.manual_flows.toLocaleString('en', { useGrouping: true })}</div>
          </Menu.List.Item>
          {isAdmin && (
            <Menu.List.Item
              className="sidebar-item"
              active={activeSidebarMenuItem === flowMenuItems.MY_FLOWS}
              onClick={() => handleClick(flowMenuItems.MY_FLOWS)}
            >
              <div className="is-flex content-centered">
                <Icon name="person" size={20} theme="regular" />
                <span className="ml-2">My Flows</span>
              </div>
              <div className="count">{counts.my_flows.toLocaleString('en', { useGrouping: true })}</div>
            </Menu.List.Item>
          )}
        </Menu.List>
      </Menu>
    </div>
  );
}

FlowsSidebar.propTypes = {
  activeSidebarMenuItem: PropTypes.string,
  baseUrl: PropTypes.string,
  counts: PropTypes.shape({
    processing_flows: PropTypes.number,
    scheduled_flows: PropTypes.number,
    my_flows: PropTypes.number,
    manual_flows: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool,
};

export default FlowsSidebar;
