import { cloneDeep } from 'lodash';

const dynamicContent = {
  getDynamicContentNameById: (id, dynamicContent) => {
    let dynamicContentName = '';
    for (let contentObj of Object.values(dynamicContent)) {
      if (contentObj['id'] === id) {
        dynamicContentName = contentObj['name'];
      }
    }
    return dynamicContentName;
  },
  setAllDynamicContentByName: (templateContent, allDynamicContent = {}) => {
    templateContent?.content_by_slide?.forEach((content) => {
      if (!(content.name in allDynamicContent)) {
        allDynamicContent[content.name] = cloneDeep(content);
      }
    });
    return allDynamicContent;
  },
};

export default dynamicContent;
