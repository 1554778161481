import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';

const CheckboxWithLabel = (props) => {
  const checkRef = useRef();
  useEffect(() => {
    if (checkRef && checkRef.current) {
      checkRef.current.indeterminate = props.indeterminate;
    }
  });

  return (
    <Form.Control className={props.className || ''}>
      <input
        id={props.id}
        ref={checkRef}
        type="checkbox"
        disabled={props.disabled}
        name={props.name}
        onChange={props.onChange}
        checked={props.checked}
      />
      <Form.Label
        htmlFor={props.id}
        data-tooltip-content={props.tooltip}
        data-tooltip-id="matik-tooltip"
        data-tooltip-place={props.dataPlace || 'right'}
      >
        <span className="mls">{props.label}</span>
      </Form.Label>
    </Form.Control>
  );
};

CheckboxWithLabel.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  dataPlace: PropTypes.string,
};

export default CheckboxWithLabel;
