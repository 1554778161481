import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import API from 'lib/api';
import withUserContext from 'components/shared/WithUserContext';
import InputLoading from 'components/shared/InputLoading';
import { Select } from 'components/shared/FormSelect';

function InputFormMatikUser({ input, userContext }) {
  const [matikUserInputOptions, setMatikUserInputOptions] = useState([]);
  const [matikUserInputSelectedUser, setMatikUserInputSelectedUser] = useState(null);

  useEffect(() => {
    API.get(
      '/enterprises/users/?all=true',
      (response) => {
        if (response.data && Array.isArray(response.data)) {
          const options = response.data.map((user) => ({
            label: user.name,
            value: user,
          }));
          const currentUser = options.find((option) => option.value.id === userContext.user.id);
          setMatikUserInputOptions(options);
          setMatikUserInputSelectedUser(currentUser);
        } else {
          setMatikUserInputOptions([]);
        }
      },
      (error) => {
        API.defaultError(error);
        setMatikUserInputOptions([]);
      },
    );
  }, [userContext.user.id]);

  const getUserAttributeFromInput = (inputName, user) => {
    const attributeMap = {
      MatikUser_Email: 'email',
      MatikUser_Name: 'name',
      MatikUser_Phone: 'phone',
      'MatikUser_Profile-Image': 'photo_url',
      MatikUser_Title: 'title',
    };

    const userAttributeKey = attributeMap[inputName];
    return user[userAttributeKey] || '(blank)';
  };

  const onMatikUserInputChange = (selectedOption) => {
    setMatikUserInputSelectedUser(selectedOption);
  };

  return (
    <>
      <Form.Field key="Matik User Inputs Field" className="input-field">
        <div className="is-flex is-vertical-centered gap-2">
          <Form.Label>When the current user is...</Form.Label>
        </div>
        <Form.Control>
          {matikUserInputOptions.length > 0 ? (
            <Select
              classNamePrefix="matik-select"
              value={matikUserInputSelectedUser}
              onChange={onMatikUserInputChange}
              options={matikUserInputOptions}
              isDisabled={false}
              isClearable={false}
            />
          ) : (
            <InputLoading />
          )}
        </Form.Control>
      </Form.Field>
      <Form.Field key="Matik User Inputs Test Value" className="input-field">
        <div className="is-flex is-vertical-centered gap-2">
          <Form.Label>{input.name} will return:</Form.Label>
        </div>
        {input.name === 'MatikUser_Profile-Image' ? (
          matikUserInputSelectedUser ? (
            <div className="mtl">
              {getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value) !== '(blank)' ? (
                <img
                  src={getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value)}
                  alt={`Matik User Profile Image for ${matikUserInputSelectedUser.value.name}`}
                />
              ) : (
                <p>{getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value)}</p>
              )}
            </div>
          ) : (
            <InputLoading />
          )
        ) : (
          <Form.Control>
            {matikUserInputSelectedUser ? (
              <Select
                classNamePrefix="matik-select"
                value={{
                  label: getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value),
                  value: getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value),
                }}
                options={[
                  {
                    label: getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value),
                    value: getUserAttributeFromInput(input.name, matikUserInputSelectedUser.value),
                  },
                ]}
                isDisabled={true}
                isClearable={false}
              />
            ) : (
              <InputLoading />
            )}
          </Form.Control>
        )}
      </Form.Field>
    </>
  );
}

InputFormMatikUser.propTypes = {
  input: PropTypes.object,
  userContext: PropTypes.object,
};

export default withUserContext(InputFormMatikUser);
