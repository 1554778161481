class InputsList {
  constructor(inputs, inputsOrder = []) {
    this.inputs = inputs;
    this.inputsOrder = inputsOrder;
  }

  getSortedList() {
    let sortedInputList = [];
    let sortedInputNames = {};
    if (this.inputsOrder && this.inputsOrder.length > 0) {
      for (let inputName of this.inputsOrder) {
        const input = this.inputs[inputName];
        if (input && !sortedInputNames[inputName]) {
          const res = this._handleNestedInputs(input, sortedInputList, sortedInputNames);
          sortedInputList = res[0];
          sortedInputNames = res[1];
          sortedInputList.push(input);
          sortedInputNames[inputName] = true;
        }
      }
    }

    const inputArray = Object.values(this.inputs);
    if (sortedInputList.length < inputArray.length) {
      Object.values(this.inputs)
        .filter((input) => !sortedInputNames[input.name])
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .forEach((input) => {
          if (!sortedInputNames[input.name]) {
            const res = this._handleNestedInputs(input, sortedInputList, sortedInputNames);
            sortedInputList = res[0];
            sortedInputNames = res[1];
            sortedInputList.push(input);
            sortedInputNames[input.name] = true;
          }
        });
    }
    return sortedInputList;
  }

  _handleNestedInputs(input, sortedList, addedInputNames) {
    if (input.nested_parameters) {
      for (let dependentInputName in input.nested_parameters) {
        if (!addedInputNames[dependentInputName]) {
          const res = this._handleNestedInputs(input.nested_parameters, sortedList, addedInputNames);
          sortedList = res[0];
          addedInputNames = res[1];
          sortedList.push(input.nested_parameters[dependentInputName]);
          addedInputNames[dependentInputName] = true;
        }
      }
    }

    return [sortedList, addedInputNames];
  }
}

export default InputsList;
