import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Modal from '../../shared/modal/Modal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import utils from 'lib/utils';

function RenamableModal(props) {
  const { initialInput, fieldName, fieldLabel, show, ui } = props;
  let mappedInput;
  if (ui.modal?.content?.fieldName) {
    if (utils.isValidJSON(ui.modal.content.input?.query_obj.query_string)) {
      const queryStringObj = JSON.parse(ui.modal?.content?.input.query_obj.query_string);
      mappedInput = queryStringObj.return_field_mapping[fieldName];
    }
  }
  const [input, setInput] = useState(ui.modal?.content?.fieldName ? mappedInput : initialInput);

  const onInputChange = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const onSave = (e) => {
    e.preventDefault();
    props.toggleIsChanged();
    props.onSave(fieldName, input, fieldLabel);
  };

  const onClose = (e) => {
    e.preventDefault();
    if (props.isSubModal) {
      props.openModal('inputModal', props.ui.modal?.content?.input);
    } else {
      props.closeModal();
    }
  };

  return (
    <Modal
      show={show}
      title={`${fieldLabel || fieldName} Display Name`}
      onClose={onClose}
      showDefaultFooter={true}
      footerIsSticky={false}
      primaryButtonText="Save"
      primaryButtonOnClick={onSave}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onClose}
    >
      <Form.Field>
        <Form.Control>
          <Form.Input
            autoFocus
            placeholder="Display Name"
            value={input}
            onChange={onInputChange}
            onKeyDown={(e) => (e.key === 'Enter' ? onSave(e) : false)}
            className="editable-input-input"
          />
        </Form.Control>
      </Form.Field>
    </Modal>
  );
}

RenamableModal.propTypes = {
  initialInput: PropTypes.string,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.bool,
  ui: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  toggleIsChanged: PropTypes.func,
  isSubModal: PropTypes.bool,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(RenamableModal);
