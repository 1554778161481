import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import API from '../../../lib/api';
import withUserContext from '../../shared/WithUserContext';
import 'react-color-palette/lib/css/styles.css';
import TemplateLibraryModalTabs from './TemplateLibraryModalTabs';
import { withRouter } from 'react-router-dom';
import Constants from '../../Constants';
import TemplateLibraryModalPreview from './TemplateLibraryModalPreview';
import utils from '../../../lib/utils';
import { MInsufficientPerms } from '../../shared/Alerts';
import { UserContext } from '../../UserContext';
import SourceTypeDropdown from '../../shared/SourceTypeDropdown';

class TemplateLibraryModal extends Component {
  constructor(props) {
    super(props);

    const oldName = this.props.oldName || '';
    this.state = {
      name: 'Copy_of_' + oldName,
      showGoogleModal: false,
      selectedOption: {},
    };
  }

  componentDidMount() {
    const googleIntegration = utils.googleIntegration(this.context.user);
    if (googleIntegration) {
      this.setState({
        selectedOption: Constants.TemplateLibrarySourceLabels.googleSlides.dropdown,
        showGoogleModal: true,
      });
    } else {
      this.setState({
        selectedOption: Constants.TemplateLibrarySourceLabels.powerPoint.dropdown,
      });
    }
  }

  componentDidUpdate(prevState) {
    if (!prevState.showGoogleModal && this.state.showGoogleModal) {
      this.connectGoogle();
    }
  }

  render() {
    let generateTemplate = '';
    let templateId = '';
    let thumbnails;
    let thumbnailLoading;
    let sourceTypeDropdownOptions = Object.values(Constants.TemplateLibrarySourceLabels).map(
      (sourceType) => sourceType.dropdown,
    );

    if (this.state.showGoogleModal) {
      generateTemplate = this.props.onGoogleClick;
      templateId = this.props.templateData.template_id;
      thumbnails = this.props.googleTemplate?.thumbnails;
      thumbnailLoading = this.props.googleThumbnailLoading;
    } else {
      generateTemplate = this.props.onPowerpointClick;
      templateId = this.props.templateData.powerpoint_template_id;
      thumbnails = this.props.powerpointTemplate?.thumbnails;
      thumbnailLoading = this.props.powerpointThumbnailLoading;
    }

    return (
      <Modal
        modalContentClass="template-library-modal-content"
        width={920}
        show={this.props.show}
        title={"Matik's " + this.props.templateData.template_title}
        onClose={this.onClose}
        showDefaultFooter={true}
        primaryButtonText="Use Template"
        primaryButtonOnClick={generateTemplate}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={this.onClose}
        additionalModalClass="template-library-modal"
      >
        <TemplateLibraryModalPreview
          templateId={templateId}
          thumbnails={thumbnails}
          thumbnailLoading={thumbnailLoading}
        ></TemplateLibraryModalPreview>
        <TemplateLibraryModalTabs
          templateDescription={this.props.templateData.description}
          accentOneDesc={this.props.templateData.accent_one}
          accentTwoDesc={this.props.templateData.accent_two}
        ></TemplateLibraryModalTabs>
        <div className="one-third-dropdown-box">
          <SourceTypeDropdown
            onDropdownChange={this.onChange}
            selectedDropdownOption={this.state.selectedOption}
            dropdownOptions={sourceTypeDropdownOptions}
          />
        </div>
      </Modal>
    );
  }

  onClose = (e) => {
    this.props.onClose(e);
  };

  onChange = (e) => {
    this.setState({ selectedOption: e });
    if (e.label === Constants.TemplateLibrarySourceLabels.googleSlides.dropdown.label) {
      this.setState({ showGoogleModal: true });
      this.connectGoogle();
    } else {
      this.setState({ showGoogleModal: false });
    }
  };

  connectGoogle = () => {
    utils.connectGoogle(this.context.user, this.context.updateUser, this.showGoogleModal, this.showPermsAlert);
  };

  showGoogleModal = () => {
    if (!this.state.showGoogleModal) {
      this.setState({ showGoogleModal: true });
    }
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message =
      'In order to copy a sample template into your Google Drive, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectGoogle);
  };

  reconnectGoogle = () => {
    utils.reconnectGoogle(this.context.user, this.context.updateUser, this.reopenModal(), this.showPermsAlert);
  };

  submitError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };
}

TemplateLibraryModal.propTypes = {
  show: PropTypes.bool,
  oldName: PropTypes.string,
  onClose: PropTypes.func,
  onCopySubmit: PropTypes.func,
  template: PropTypes.object,
  history: PropTypes.object,
  userContext: PropTypes.object,
  onClick: PropTypes.func,
  flags: PropTypes.object,
  onTemplateAdd: PropTypes.func,
  templateData: PropTypes.object,
  onGoogleClick: PropTypes.func,
  onPowerpointClick: PropTypes.func,
  googleTemplate: PropTypes.object,
  powerpointTemplate: PropTypes.object,
  googleThumbnailLoading: PropTypes.bool,
  powerpointThumbnailLoading: PropTypes.bool,
};

TemplateLibraryModal.contextType = UserContext;

export default withRouter(withUserContext(TemplateLibraryModal));
