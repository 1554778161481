import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Form } from 'react-bulma-components';
import info_icon from '../../images/info_icon.svg';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

const EditableField = ({
  ui,
  id,
  value,
  children,
  className,
  canEdit,
  editableTextClassName,
  placeholder,
  tooltip,
  onSave,
  closeEditableField,
  openEditableField,
}) => {
  const [input, setInput] = useState(value);
  const editableFieldRef = useRef(null);

  const onInputChange = (e) => {
    e.preventDefault();

    setInput(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    onSave(input);
    handleClose(e);
  };

  const handleClose = (e) => {
    e.preventDefault();

    closeEditableField();
  };

  const handleOpen = (e) => {
    e.preventDefault();

    openEditableField(id);
  };

  const { editableField } = ui;
  let editablePart = null;
  if (editableField && editableField.content === id && editableFieldRef.current) {
    const boundingRect = editableFieldRef.current.getBoundingClientRect();
    const top = boundingRect.y - 20;
    const left = boundingRect.x - 20;
    editablePart = (
      <Box className="editable-field-editor" style={{ top, left }}>
        <Form.Field>
          <Form.Control>
            <Form.Input autoFocus value={input} onChange={onInputChange} className="editable-input-input" />
          </Form.Control>
        </Form.Field>
        {children}
        <Form.Field kind="group" align="right">
          <Form.Control>
            <button className="button is-secondary" onClick={handleClose}>
              Cancel
            </button>
          </Form.Control>
          <Form.Control>
            <button className="button is-primary" onClick={handleSave}>
              Save
            </button>
          </Form.Control>
        </Form.Field>
      </Box>
    );
  }

  let finalClassName = 'editable-input';
  if (className) {
    finalClassName += ` ${className}`;
  }

  let finalEditableTextClassName = 'text-overflow-ellipsis ';
  if (canEdit) {
    finalEditableTextClassName += 'editable-text';
  }
  if (editableTextClassName) {
    finalEditableTextClassName += ` ${editableTextClassName}`;
  }

  let inputVal = value;
  if (!value && placeholder) {
    inputVal = placeholder;
  }

  const tooltipElement = tooltip && (
    <span
      className="is-flex mls is-flex-shrink-0"
      data-tooltip-id="matik-tooltip"
      data-tooltip-html={tooltip}
      data-tooltip-place="bottom"
    >
      <img src={info_icon} alt="Description" height="15px" />
    </span>
  );

  return (
    <div className="text-overflow-ellipsis" ref={editableFieldRef}>
      {canEdit && editablePart}
      <div className={finalClassName} onClick={canEdit ? handleOpen : undefined}>
        <span className={finalEditableTextClassName}>{inputVal}</span>
        {tooltipElement}
      </div>
    </div>
  );
};

EditableField.propTypes = {
  className: PropTypes.string,
  editableTextClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func,
  value: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.any,
  canEdit: PropTypes.bool,
  ui: PropTypes.object,
  id: PropTypes.string,
  closeEditableField: PropTypes.func,
  openEditableField: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(EditableField);
