import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { isLoopable, getLoopedSlideInfo, hasSlidesWithLoops } from 'lib/looping';

import FullScreenModal from 'components/shared/modal/FullScreenModal';
import HowTo from 'components/shared/HowTo';
import AsyncLoadImage from 'components/shared/AsyncLoadImage';

import InputSelect from 'components/producer/templates/InputSelect';
import IconPill from '../../lib/IconPill';
import { useTemplateThumbnails } from 'lib/hooks/useTemplate';
import API from 'lib/api';

function SlideLoopModal({ onLoopUpdate, onClose, input, show, template, onLoopDelete, status, inputsByName }) {
  const [selectedInput, setSelectedInput] = useState(null);
  useEffect(() => {
    if (input) {
      // use populated input record here, when possible. input might just be { id: 123 }
      const fullInput = Object.values(inputsByName).find((i) => i.id === input.id) || input;
      setSelectedInput(fullInput);
    }
  }, [input?.id]);

  const loop = template.slide_loops.find((loop) => loop.parameter === selectedInput?.id);

  let slideHasOtherLoop = false;
  if (!loop && selectedInput) {
    // Since a slide can only have a single loop on it, the user cannot create a new loop
    // on the input.
    slideHasOtherLoop = hasSlidesWithLoops(selectedInput, template);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    onLoopDelete(loop).then(() => onClose());
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const slides = selectedInput.slideNums.map((idx) => template.slides[idx - 1]);
    const data = {
      slides: slides,
      template_id: template.id,
      parameter_id: selectedInput.id,
    };
    onLoopUpdate(data, loop)
      .then(() => onClose())
      .catch(API.defaultError);
  };

  return (
    <FullScreenModal
      show={show}
      onClose={onClose}
      showDefaultFooter={false}
      backgroundColor="white"
      headerCenter={<div className="font-l">{loop ? 'Update' : 'Create'} Loop</div>}
    >
      <SlideLoopForm
        selectedInput={selectedInput}
        template={template}
        onChange={setSelectedInput}
        status={status}
        inputsByName={inputsByName}
      ></SlideLoopForm>

      <div className="full-screen-modal-footer">
        <button role="button" className="button is-secondary mr-auto" onClick={onClose}>
          Cancel
        </button>
        {loop ? (
          <button role="button" className="button is-secondary" onClick={handleDelete}>
            Delete Loop
          </button>
        ) : (
          <button
            data-tooltip-content={
              slideHasOtherLoop ? 'A slide referencing this input is already included in another loop' : null
            }
            data-tooltip-id="matik-tooltip"
            role="button"
            disabled={slideHasOtherLoop}
            className="button is-primary"
            onClick={handleUpdate}
          >
            Create Loop
          </button>
        )}
      </div>
    </FullScreenModal>
  );
}

SlideLoopModal.propTypes = {
  show: PropTypes.bool,
  input: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  template: PropTypes.object,
  onLoopDelete: PropTypes.func,
  onLoopUpdate: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.object,
  inputsByName: PropTypes.object,
};

export default SlideLoopModal;

const SlideLoopForm = ({ template, status, selectedInput, inputsByName, onChange }) => {
  const { data: thumbnails } = useTemplateThumbnails(template?.id, template?.deleted);

  const loopableInputs = Object.values(inputsByName).filter(isLoopable);
  return (
    <div className="three-column-modal-body">
      <div className="modal-sidebar invisible"></div>
      <div className="modal-body-center">
        <div className="is-fullwidth mbl">
          <div
            className="mbs is-inline-block"
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Select an input. Matik will loop through all values provided for that input to customize your content."
          >
            Select Input
          </div>
          <div>
            <InputSelect inputs={loopableInputs} value={selectedInput} onChange={onChange} />
          </div>
        </div>

        {selectedInput && (
          <div className="is-fullwidth">
            <div className="mbs">Looped Slides</div>
            <div className="is-flex is-wrapped space-between gap-medium">
              {selectedInput.slideNums?.map((slideNum) => (
                <LoopedSlide
                  width={345}
                  key={slideNum}
                  selectedInput={selectedInput}
                  template={template}
                  status={status}
                  slideNumber={slideNum}
                  thumbnails={thumbnails}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="modal-sidebar">
        <HowTo helpLink="https://help.matik.io/hc/en-us/articles/7994561590555-Slide-Looping">
          <div className="how-to-heading is-uppercase">Create Loops</div>
          <p className="mbs">Use loops to automatically generate content for all values provided for a given input.</p>
          <p>Want to learn more? Check out the Help Center documentation or reach out to Support.</p>
        </HowTo>
      </div>
    </div>
  );
};
SlideLoopForm.propTypes = {
  template: PropTypes.object,
  status: PropTypes.object,
  inputsByName: PropTypes.object,
  selectedInput: PropTypes.shape({
    id: PropTypes.number.isRequired,
    display_name: PropTypes.string,
    name: PropTypes.string,
    source_type: PropTypes.string,
    slideNums: PropTypes.arrayOf(PropTypes.number),
  }),
  onChange: PropTypes.func,
};

const LoopedSlide = ({ width, selectedInput, slideNumber, template, status, thumbnails }) => {
  const slide = template.slides[slideNumber - 1];
  if (!slide) {
    return <div />;
  }

  const thumbData = thumbnails?.[parseInt(slide.id)];
  const slideFetchStatus = thumbData && {
    data: thumbData,
    fetchState: 'fetched',
    errorMessage: '',
  };

  let loop = getLoopedSlideInfo(slide, template.slide_loops)?.loop;
  if (loop && loop.parameter === selectedInput.id) {
    // The loop is for the selected input, so no need to warn the user about it
    loop = null;
  }

  const height = (width * 9) / 16;
  return (
    <div className="is-relative">
      {loop && (
        <div
          data-tooltip-id="matik-tooltip"
          data-tooltip-content="This slide references another looped input"
          className="is-absolute top-0 right-0 mrs mts"
        >
          <IconPill iconName="loop" color="grey" size="m" theme="circle" />
        </div>
      )}
      <AsyncLoadImage
        fetchUrl={'unsupported'}
        fetchStatus={slideFetchStatus}
        isBulk={true}
        imageWrapperClass="async-rounded has-light-gray-border"
        imageClass="async-rounded"
        height={`${height}px`}
        width={`${width}px`}
        status={status}
      />
      <div className="has-text-centered">{slideNumber}</div>
    </div>
  );
};
LoopedSlide.propTypes = {
  className: PropTypes.string,
  /** Width, in pixels, of the slide image */
  width: PropTypes.number.isRequired,
  /** The position of the slide in the template. 1-based */
  slideNumber: PropTypes.number,
  selectedInput: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  template: PropTypes.object,
  status: PropTypes.object,
  thumbnails: PropTypes.object,
};
