import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Constants from '../../components/Constants';

export const useApiInfo = (dataSource = {}, resourceName = null, resourceId = null) => {
  // TODO(Matt): Add subResourceName to params for searching

  const queryClient = useQueryClient();

  const queryKey = ['api_info', dataSource.id, { resource_name: resourceName, resource_id: resourceId }];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      let doFetchPromise;
      if (dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce) {
        doFetchPromise = getSalesforceDataCount(resourceName, dataSource.id).then(
          (salesforceDataCount) => salesforceDataCount < Constants.MAX_SALESFORCE_REPORTS,
        );
      } else {
        doFetchPromise = Promise.resolve(true);
      }
      return doFetchPromise.then((doIt) => {
        if (doIt) {
          return API.get(
            buildFetchUrl(dataSource.id, resourceName, resourceId),
            (result) => result.data,
            (err) => {
              API.defaultError(err);
            },
          );
        } else {
          return {};
        }
      });
    },
    enabled: !!dataSource.id,
  });

  const invalidate = (id) => {
    const queryKey = ['api_info'];
    if (id ?? dataSource.id) {
      queryKey.push(id ?? dataSource.id);
    }
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    isPending: isLoading,
    isError,
    data,
    error,
    invalidate,
  };
};

const buildFetchUrl = (dataSourceId, resourceName, resourceId) => {
  let url = `/data_sources/${dataSourceId}/info/`;
  if (resourceName) {
    url += `?resource_name=${resourceName}`;
    if (resourceId) {
      url += `&resource_id=${resourceId}`;
    }
  }

  return url;
};

const getSalesforceDataCount = (resourceName, dataSourceId) => {
  return API.get(
    `/data_sources/${dataSourceId}/count/${resourceName}/`,
    (result) => result.data,
    (err) => {
      API.defaultError(err);
    },
  );
};
