import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Tag from '../../lib/Tag';

const ItemTagsRefinement = (props) => (
  <div>
    {map(props.itemTags, (tagArray) => (
      <Tag
        color={props.activeTags.includes(tagArray[0]) ? 'matik-black' : 'white'}
        onClick={() => props.onTemplateTagFilterToggle(tagArray[0])}
        key={tagArray[0]}
        label={tagArray[0]}
      />
    ))}
  </div>
);

ItemTagsRefinement.propTypes = {
  itemTags: PropTypes.array,
  activeTags: PropTypes.array,
  onTemplateTagFilterToggle: PropTypes.func,
};

export default ItemTagsRefinement;
