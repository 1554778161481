import React, { Component } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

class ModalDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.inputRef = React.createRef();
  }

  renderDropdownMenu() {
    const boundingRect = this.inputRef.current.getBoundingClientRect();
    const top = boundingRect.y + 35;
    const left = boundingRect.x;
    return ReactDom.createPortal(
      <div className="modal-dropdown dropdown-menu" role="menu" style={{ top, left }}>
        <div className="dropdown-content" onClick={() => this.setState({ active: false })}>
          {this.props.children}
        </div>
      </div>,
      document.getElementById('dropdown-portal'),
    );
  }

  render() {
    const hoverableClassName = this.props.isHoverable ? 'is-hoverable' : '';
    const activeClassName = this.props.active ? 'is-active' : '';
    const passedClassName = this.props.className ? this.props.className : '';
    const orientationClass = this.props.isRight ? 'is-right' : '';
    const upDownClass = this.props.isUp ? 'is-up' : '';
    let dropdownMenu = null;
    if (this.state.active) {
      dropdownMenu = this.renderDropdownMenu();
    }
    return (
      <div
        className={`dropdown ${orientationClass} ${upDownClass} ${hoverableClassName} ${activeClassName} ${passedClassName}`}
        onMouseEnter={() => this.setState({ active: true })}
        onMouseLeave={() => this.setState({ active: false })}
      >
        <div className="dropdown-trigger" ref={this.inputRef}>
          {this.props.dropdownTrigger}
        </div>
        {dropdownMenu}
      </div>
    );
  }
}

ModalDropDown.propTypes = {
  isHoverable: PropTypes.bool,
  active: PropTypes.bool,
  dropdownTrigger: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  isRight: PropTypes.bool,
  isUp: PropTypes.bool,
};

export default ModalDropDown;
