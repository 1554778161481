import React, { Component } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

class PresentationsInsightRechart extends Component {
  render() {
    return (
      <ResponsiveContainer height={82} width="100%">
        <AreaChart data={this.props.presentationData}>
          <CartesianGrid vertical={false} className="analytics-cartesian-grid" />
          <Area
            dataKey="presentation_count"
            dot={false}
            strokeWidth={2}
            stroke="#20DFAC"
            fill="#20DFAC"
            fillOpacity={0.25}
            type="monotoneX"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

PresentationsInsightRechart.propTypes = {
  presentationData: PropTypes.array,
};

export default PresentationsInsightRechart;
