import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { mapLibrariesStateToProps } from '../../../redux/libraries/stateMappers';
import { mapLibraryDispatchToProps } from '../../../redux/libraries/dispatchers';
import InsertTemplatePopupBody from '../../shared/InsertTemplatePopupBody';

class InsertLibrarySlidePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLibrary: null,
    };
  }

  componentDidMount() {
    if (!this.state.selectedLibrary && this.props.librariesById && Object.values(this.props.librariesById).length > 0) {
      this.setState({ selectedLibrary: Object.values(this.props.librariesById)[0] });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.librariesById, this.props.librariesById)) {
      if (
        !this.state.selectedLibrary &&
        this.props.librariesById &&
        Object.values(this.props.librariesById).length > 0
      ) {
        this.setState({ selectedLibrary: Object.values(this.props.librariesById)[0] });
      } else if (
        this.state.selectedLibrary &&
        (!this.props.librariesById || Object.values(this.props.librariesById).length === 0)
      ) {
        this.setState({ selectedLibrary: null });
      }
    }
  }

  render() {
    return (
      <InsertTemplatePopupBody
        currentTemplate={this.props.currentTemplate}
        insertionIdx={this.props.insertionIdx}
        selectedTemplate={this.state.selectedLibrary}
        selectTemplate={this.selectLibrary}
        templatesById={this.props.librariesById}
        insertLibrarySlidesIntoTemplate={this.props.insertLibrarySlidesIntoTemplate}
      />
    );
  }

  selectLibrary = (obj, action) => {
    if (action.action === 'select-option') {
      this.setState({ selectedLibrary: this.props.librariesById[obj.value] });
    }
  };
}

InsertLibrarySlidePopUp.propTypes = {
  currentTemplate: PropTypes.object,
  insertionIdx: PropTypes.number,
  librariesById: PropTypes.object,
  templates: PropTypes.array,
  insertLibrarySlidesIntoTemplate: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const props = Object.assign({}, ownProps, {
    entityType: 'template',
    source_type: ownProps.currentTemplate.source_type,
  });
  return Object.assign({}, mapLibrariesStateToProps(state, props));
}

function mapDispatchToProps(dispatch) {
  return Object.assign({}, mapLibraryDispatchToProps(dispatch));
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertLibrarySlidePopUp);
