import React from 'react';
import { each, map } from 'lodash';
import Pluralize from 'pluralize';
import { EVENT_TYPE_VALUES, READ_STATE_VALUES } from './Constants';
import Constants from '../../Constants';
import TextOverflowWithTooltip from '../TextOverflowWithTooltip';
import { ReactComponent as TemplateIcon } from '../../../svg/templates.svg';
import { ReactComponent as PresentationIcon } from '../../../svg/presentations.svg';
import { ReactComponent as TemplateErrorIcon } from '../../../svg/template_with_error.svg';
import { ReactComponent as TemplateWarningIcon } from '../../../svg/template_with_warning.svg';
import Icon from '../../lib/Icon';

class BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    if (this.constructor === BaseNotification) {
      throw new Error('Abstract classes cannot be instantiated.');
    }
    this.bundle = bundle;
    this.head = bundle.at(0);
    this.isOne = bundle.length === 1;
    this.role = role;
    this.markRead = bulkMarkRead;
    this.setIsActive = setIsActive;
  }

  get icon() {
    throw new Error("Accessor field 'get icon()' must be implemented.");
  }

  get title() {
    throw new Error("Accessor field 'get title()' must be implemented.");
  }

  get messageBody() {
    throw new Error("Accessor field 'get messageBody()' must be implemented.");
  }

  get primaryActionText() {
    throw new Error("Accessor field 'get primaryActionText()' must be implemented.");
  }

  primaryAction = () => {
    throw new Error("Method 'primaryAction()' must be implemented.");
  };
}

class AccessChangedNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
    this.eventType = 'access';
  }

  get icon() {
    switch (this.head.event.event_parameters.item_type) {
      case Constants.ItemTypes.TEMPLATE:
        return <TemplateIcon className="icon" />;
      case Constants.ItemTypes.PRESENTATION:
        return <PresentationIcon className="icon" />;
      default:
        return <Icon name="dynamic_content" />;
    }
  }

  get title() {
    if (this.isOne) {
      return `A ${this.head.event.event_parameters.item_type} was shared with you!`;
    }
    return `${Pluralize(
      `new ${this.head.event.event_parameters.item_type}`,
      this.bundle.length,
      true,
    )} were shared with you!`;
  }

  get messageBody() {
    if (this.isOne) {
      return (
        <>
          {`${this.head.templateData.grantor_name} invited you to ${this.head.event.event_parameters.permission} `}
          <TextOverflowWithTooltip className="text-overflow-ellipsis" text={this.head.templateData.item_name} />
        </>
      );
    }
    return <div>Check them out to save even more time!</div>;
  }

  get primaryActionText() {
    return 'View';
  }

  primaryAction = (e, history) => {
    e.preventDefault();
    let url =
      this.role === Constants.PRODUCER_ROLE ? '/templates/menu/new_for_me' : '/create/templates/menu/new_for_me';
    if (this.isOne) {
      url =
        this.role === Constants.PRODUCER_ROLE
          ? `/templates/${this.head.event.event_parameters.item_id}`
          : `/create/templates/${this.head.event.event_parameters.item_id}`;
    }
    history.push(url);
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead(map(this.bundle, (notification) => notification.event.id));
    }
  };
}

class PresentationErrorsNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
    this.isWarning = this.head.templateData.level === Constants.LOG_LEVEL.warning;
    this.eventType = this.isWarning ? 'warning' : 'failure ';
  }

  get icon() {
    if (this.head.templateData.level === Constants.LOG_LEVEL.warning) {
      return <TemplateWarningIcon className="icon" />;
    } else {
      return <TemplateErrorIcon className="icon" />;
    }
  }

  get title() {
    if (this.isOne) {
      if (this.isWarning) {
        return `Oh, ${Pluralize('warning', this.head.templateData.count, true)} need your attention!`;
      } else {
        return 'Ooops, there is trouble!';
      }
    } else {
      if (this.isWarning) {
        return `${Pluralize('presentation', this.bundle.length, true)} with warnings!`;
      } else {
        return `${Pluralize('failed presentation', this.bundle.length, true)}!`;
      }
    }
  }

  get messageBody() {
    const yourTemplate = (
      <div className="text-overflow-ellipsis">
        Your template{' '}
        <u data-tooltip-id="matik-tooltip" data-tooltip-content={this.head.templateData.template_name}>
          {this.head.templateData.template_name}
        </u>
      </div>
    );
    if (this.isOne) {
      if (this.isWarning) {
        return (
          <>
            {yourTemplate}
            caused {Pluralize('warning', this.head.templateData.count, true)} while generating
          </>
        );
      } else {
        return (
          <>
            {yourTemplate}
            failed to generate and needs your attention
          </>
        );
      }
    }
    const templateNames = {};
    each(this.bundle, (notification) => {
      if (templateNames[notification.templateData.template_name]) {
        templateNames[notification.templateData.template_name] += notification.templateData.count;
      } else {
        templateNames[notification.templateData.template_name] = notification.templateData.count;
      }
    });
    if (Object.keys(templateNames).length === 1) {
      if (this.isWarning) {
        return (
          <>
            {yourTemplate}
            caused {Pluralize('warning', templateNames[this.head.templateData.template_name], true)} in{' '}
            {Pluralize('presentation', this.bundle.length, true)}
          </>
        );
      } else {
        return (
          <>
            {yourTemplate}
            caused {Pluralize('failed presentation', this.bundle.length, true)}
          </>
        );
      }
    } else {
      if (this.isWarning) {
        return <>{Pluralize('presentation', this.bundle.length, true)} contain warnings</>;
      } else {
        return <>{Pluralize('presentation', this.bundle.length, true)} failed to generate</>;
      }
    }
  }

  get primaryActionText() {
    return 'Review';
  }

  primaryAction = (e, history) => {
    e.preventDefault();
    let url = this.role === Constants.PRODUCER_ROLE ? '/presentations/' : '/create/presentations/';
    if (this.isOne) {
      url += `${this.head.event.event_parameters.presentation_id}?logs=true`;
    } else {
      const templateNames = new Set();
      each(this.bundle, (notification) => {
        templateNames.add(notification.templateData.template_name);
      });
      templateNames.forEach((name) => {
        if (url.at(-1) === '/') {
          url += `?template=${encodeURI(name)}`;
        } else {
          url += `&template=${encodeURI(name)}`;
        }
      });
    }
    history.push(url);
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead(map(this.bundle, (notification) => notification.event.id));
    }
  };
}

export const Renderer = {
  [EVENT_TYPE_VALUES.accessChanged]: AccessChangedNotification,
  [EVENT_TYPE_VALUES.presentationErrors]: PresentationErrorsNotification,
};
