import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import API from '../../../../lib/api';
import Skeleton from 'react-loading-skeleton';
import ToggleSwitchWithLabel from '../../../lib/ToggleSwitchWithLabel';
import { ListItemSelect } from '../../../shared/FormSelect';
import IconPill from '../../../lib/IconPill';
import AgentActionButtons from './AgentActionButtons';
import { MConfirm } from '../../../shared/Alerts';
import utils from '../../../../lib/utils';

function AgentForm({ onSelectAgent, onDeselectAgent, selectedAgentId }) {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAgentDropdown, setShowAgentDropdown] = useState(!!selectedAgentId);

  const flags = useFlags();

  function loadAgents() {
    API.get(
      '/agents/',
      (response) => {
        setAgents(response.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        API.defaultError(err);
      },
    );
  }

  useEffect(() => {
    loadAgents();
  }, []);

  const onDeleteClick = (e, agentId) => {
    e.preventDefault();
    MConfirm('Delete Agent?', 'Are you sure you want to delete this agent', 'warning', (confirm) => {
      if (confirm) {
        API.delete(`/agents/${agentId}/`, () => {
          const updatedAgents = agents.filter((agent) => agent.id !== agentId);
          setAgents(updatedAgents);
        });
      }
    });
  };

  const downloadConfig = (e, agent) => {
    if (e) {
      e.preventDefault();
    }
    const config = `matik_agent_key=${agent.key}\nmatik_agent_server=herbert1.matik.io\n`;
    utils.downloadAsEnvFile(config, 'matik_agent_config');
  };

  const addAgent = () => {
    API.post('/agents/', { hostname: `Agent ${agents.length + 1}` }, (response) => {
      const updatedAgents = [...agents];
      updatedAgents.push(response.data);
      setAgents(updatedAgents);
      downloadConfig(null, response.data);
      onSelectAgent(response.data.id);
      setLoading(false);
    });
  };

  const selectAgent = (value) => {
    if (value.value === 0) {
      // 0 signifies that we want to create a new agent
      setLoading(true);
      addAgent();
    } else {
      onSelectAgent(value.value);
    }
  };

  const onToggleClick = () => {
    if (showAgentDropdown) {
      onDeselectAgent();
      setShowAgentDropdown(false);
    } else {
      setShowAgentDropdown(true);
    }
  };

  let body = '';
  let value = null;

  if (loading) {
    body = <Skeleton height="3rem" />;
  } else {
    let selectedAgent = agents.find((a) => a.id === selectedAgentId);
    const agentOptions = agents.map((agent) => {
      return {
        value: agent.id,
        label: agent.hostname,
        indicatorColor: agent.is_registered ? 'green-500' : 'red-500',
        subtitle: agent.is_registered ? 'Registered' : 'Unregistered',
        icon: <IconPill iconName="link" size="s" theme="square" />,
        metadata: (
          <AgentActionButtons
            onDelete={(e) => onDeleteClick(e, agent.id)}
            onDownload={(e) => downloadConfig(e, agent)}
          />
        ),
      };
    });
    agentOptions.push({
      label: ' ',
      options: [
        {
          value: 0,
          label: 'Create agent and download config',
          icon: <IconPill iconName="plus" size="s" theme="square" />,
        },
      ],
    });
    if (selectedAgent) {
      value = agentOptions.find((agentOption) => agentOption.value === selectedAgent.id);
    }
    body = (
      <>
        <div className="mb-3">
          <ToggleSwitchWithLabel onClick={onToggleClick} label="Connect via agent" switchState={showAgentDropdown} />
        </div>
        {showAgentDropdown && (
          <ListItemSelect
            classNamePrefix="matik-select"
            value={value}
            options={agentOptions}
            onChange={selectAgent}
            styles={{
              // TODO (Zak): Upgrade to version 5.7.0 to enable classNames instead of styles
              group: (base) => ({
                ...base,
                borderTop: '1px solid #E0E5EE', // $grey-300
                marginTop: '0.6rem',
              }),
            }}
          />
        )}
      </>
    );
  }

  if (!flags.enableMatikAgent) {
    return null;
  }

  return <div className="my-4">{body}</div>;
}

AgentForm.propTypes = {
  onDeselectAgent: PropTypes.func,
  onSelectAgent: PropTypes.func.isRequired,
  toggleAgent: PropTypes.func,
  selectedAgentId: PropTypes.number,
};

export default AgentForm;
