import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../lib/Button';
import ButtonGroup from '../../lib/ButtonGroup';

class ModalFooter extends Component {
  render() {
    const tertiaryButtonDisabled = !!this.props.tertiaryButtonDisabled;
    const isSticky = this.props.isSticky ? 'sticky' : '';
    let secondaryButtonStatus = 'default';
    if (this.props.secondaryButtonLoading) {
      secondaryButtonStatus = 'loading';
    } else if (this.props.secondaryButtonDisabled) {
      secondaryButtonStatus = 'disabled';
    }

    let primaryButtonStatus = 'default';
    if (this.props.primaryButtonLoading) {
      primaryButtonStatus = 'loading';
    } else if (this.props.primaryButtonDisabled) {
      primaryButtonStatus = 'disabled';
    }
    return (
      <div className={`modal-footer ${isSticky}`}>
        <ButtonGroup align="right">
          {this.props.tertiaryButtonOnClick && this.props.tertiaryButtonText ? (
            <Button
              onClick={this.props.tertiaryButtonOnClick}
              category="tertiary"
              status={tertiaryButtonDisabled ? 'disabled' : 'default'}
            >
              {this.props.tertiaryButtonText}
            </Button>
          ) : null}
          {this.props.secondaryButtonOnClick && this.props.secondaryButtonText ? (
            <Button category="secondary" onClick={this.props.secondaryButtonOnClick} status={secondaryButtonStatus}>
              {this.props.secondaryButtonText}
            </Button>
          ) : null}
          {this.props.primaryButtonOnClick && this.props.primaryButtonText ? (
            <Button onClick={this.props.primaryButtonOnClick} status={primaryButtonStatus}>
              {this.props.primaryButtonText}
            </Button>
          ) : null}
        </ButtonGroup>
      </div>
    );
  }
}

ModalFooter.propTypes = {
  isSticky: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonLoading: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonLoading: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  tertiaryButtonText: PropTypes.string,
  tertiaryButtonOnClick: PropTypes.func,
  tertiaryButtonDisabled: PropTypes.bool,
};

export default ModalFooter;
