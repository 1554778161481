import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DayPickerSingleDateController } from 'react-dates';
import { Box, Columns, Level, Form } from 'react-bulma-components';
import { Select } from '../FormSelect';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../../svg/info_icon_blue.svg';
import DaySelector from '../../shared/DaySelector';
import DateSelector from '../../shared/DateSelector';
import OutsideHandler from '../OutsideHandler';
import RadioInput from '../RadioInput';
import RelativeDate from '../../../lib/RelativeDate';
import API from '../../../lib/api';
import Pluralize from 'pluralize';
import localeDateFormatterUtils from '../../../lib/localeDate';
import Constants from '../../../components/Constants';
import Icon from '../../lib/Icon';

const RELATIVE_OPTIONS = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

class DateInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      dateError: '',
      dateFormat: Constants.DATE_FORMATS.usa,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      dateFormat: localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
        localeDateFormatterUtils.getUserLocale(),
      ),
    });
  }

  renderAbsolute(fetchedDate) {
    const leftNav = (
      <div role="button" className="left-navigation-button">
        <Icon name="chevron_left" size={16} theme="regular" />
      </div>
    );
    const rightNav = (
      <div role="button" className="right-navigation-button">
        <ChevronRight />
      </div>
    );
    return (
      <React.Fragment>
        <DayPickerSingleDateController
          date={fetchedDate.isValid() ? fetchedDate : null}
          onDateChange={(date) => this.onChange(date)}
          navPrev={leftNav}
          navNext={rightNav}
          isOutsideRange={() => {}}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={2}
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          disabled={this.props.isReadOnly}
        />
      </React.Fragment>
    );
  }

  renderRelative(relativeDate) {
    const { dateError } = this.state;
    const timeValue = dateError ? '' : relativeDate.value;
    const units = relativeDate.units;
    const relativeTimeUnits = RELATIVE_OPTIONS.filter((option) => option.value === units)[0];
    return (
      <div className="pll prl pbl light-top-border">
        <Columns className="man">
          <Columns.Column className="ptm prn pbn pln is-2">
            <Form.Input
              type="text"
              value={timeValue}
              onChange={(e) => this.onRelativeChange(units, e.target.value, relativeDate)}
              disabled={this.props.isReadOnly}
            />
          </Columns.Column>
          <Columns.Column className="ptm prn pbn plm is-8">
            <Select
              classNamePrefix="matik-select"
              className="matik-select-container has-addon"
              options={RELATIVE_OPTIONS}
              value={relativeTimeUnits}
              onChange={(choice) => this.onRelativeChange(choice.value, timeValue, relativeDate)}
              menuPortalTarget={null}
              menuPosition="absolute"
              isDisabled={this.props.isReadOnly}
            />
          </Columns.Column>
          <Columns.Column className="ptm phn pbn is-2">
            <a className="button is-static right">ago</a>
          </Columns.Column>
        </Columns>
        {dateError && <Form.Help color="danger">{dateError}</Form.Help>}
        {this.renderDayDateSelector(relativeDate)}
        {this.renderInfo(relativeDate)}
      </div>
    );
  }

  renderDayDateSelector(relativeDate) {
    const units = relativeDate.units;
    if (this.state.dateError !== '') {
      return;
    }

    let dayDateSelector;
    if (units === RELATIVE_OPTIONS[1].value) {
      const date = relativeDate.dateValue();
      let selectedValue = !date ? null : date.day() === 0 ? date.day() + 7 : date.day();
      dayDateSelector = (
        <React.Fragment>
          <Level className="man">
            <Level.Side align="left ptm">
              <Level.Item className="info-text">on</Level.Item>
              <Level.Item className="day-date-selector">
                <DaySelector
                  selectedValue={selectedValue}
                  onChange={(value) => this.onDayDateChange(units, value % 7, relativeDate)}
                />
              </Level.Item>
            </Level.Side>
          </Level>
        </React.Fragment>
      );
    } else if (units === RELATIVE_OPTIONS[2].value) {
      dayDateSelector = (
        <React.Fragment>
          <Columns className="man">
            <Columns.Column className="ptm prn pbn pln is-1">
              <Form.Label className="info-text pts">on the</Form.Label>
            </Columns.Column>
            <Columns.Column className="ptm prn pbn plm is-8">
              <DateSelector
                relativeDate={relativeDate}
                onChange={(selected) => this.onDayDateChange(units, selected, relativeDate)}
              />
            </Columns.Column>
            <Columns.Column className="ptm phn pbn is-3">
              <a className="button is-static right">of the month</a>
            </Columns.Column>
          </Columns>
        </React.Fragment>
      );
    }
    return <React.Fragment>{dayDateSelector}</React.Fragment>;
  }

  renderCaptionText(value) {
    const dateString = localeDateFormatterUtils.getDateStringFromMomentObject(value);
    const caption = 'If you generate this today, the date of data included would be ';
    return (
      <React.Fragment>
        {caption} <b>{dateString}</b>
      </React.Fragment>
    );
  }

  renderInfo(relativeDate) {
    // renders the blue info box in the popup component
    const value = relativeDate.dateValue();
    const { dateError } = this.state;
    const units = relativeDate.units;
    if (dateError) {
      return;
    }
    let info;

    if (units === RELATIVE_OPTIONS[1].value || units === RELATIVE_OPTIONS[2].value) {
      info = (
        <Level className="mtm day-date-caption">
          <InfoIcon />
          <div className="caption">{this.renderCaptionText(value)}</div>
        </Level>
      );
    }
    return <React.Fragment>{info}</React.Fragment>;
  }

  renderFormHelp(relativeDate) {
    // renders the form help under the input
    const { dateError } = this.state;
    if (!relativeDate || dateError) {
      return;
    }

    return <Form.Help className="caption">{this.renderCaptionText(relativeDate.dateValue())}</Form.Help>;
  }

  renderRelativeCaption(relativeDate) {
    if (this.state.dateError) {
      return '';
    }
    const units = relativeDate.units;
    const timeValue = relativeDate.value;
    const valueAndUnit = Pluralize(Pluralize.singular(units), timeValue, true);
    const operator = relativeDate.operator === '-' ? 'ago' : 'from now';
    let mod = '';
    if (relativeDate.mod !== null) {
      if (units === RELATIVE_OPTIONS[1].value) {
        mod = `on ${relativeDate.dateValue().format('dddd')}`;
      } else if (units === RELATIVE_OPTIONS[2].value) {
        if (relativeDate.mod === -1) {
          mod = 'on the Last Day';
        } else {
          mod = `on the ${relativeDate.dateValue().format('Do')}`;
        }
      }
    }
    return `${valueAndUnit} ${operator} ${mod}`;
  }

  render() {
    const inputValue = this.props.inputValues[this.props.input.name].value;
    const isRelativeDate = RelativeDate.isRelativeDate(inputValue);
    const fetchedDate = this.fetchDate(isRelativeDate);
    const body = isRelativeDate ? this.renderRelative(fetchedDate) : this.renderAbsolute(fetchedDate);
    const absoluteRelativeSelect = (
      <Level className="mbn pal light-bottom-border">
        <Level.Side align="left">
          <Level.Item>
            <RadioInput
              value="absolute"
              label="Absolute"
              name="absoluteRelativeSelect"
              checked={!isRelativeDate}
              onChange={() => this.onAbsoluteSelect(fetchedDate)}
              disabled={this.props.isReadOnly}
            />
          </Level.Item>
          <Level.Item>
            <RadioInput
              value="relative"
              label="Relative to generation date"
              name="absoluteRelativeSelect"
              checked={isRelativeDate}
              onChange={this.onRelativeSelect}
              disabled={this.props.isReadOnly}
            />
          </Level.Item>
        </Level.Side>
      </Level>
    );

    let popup = null;
    if (this.state.focused) {
      const transform = `translate3d(${this.boundingRect.x}px, ${this.boundingRect.y}px, 0px)`;
      popup = (
        <OutsideHandler onFocusOut={this.onFocusOut}>
          <Box className="day-picker-popup is-paddingless" style={{ transform }}>
            {this.props.allowRelative && absoluteRelativeSelect}
            {body}
          </Box>
        </OutsideHandler>
      );
    }

    let inputText;
    if (isRelativeDate) {
      inputText = this.renderRelativeCaption(fetchedDate);
      if (!RelativeDate.isRelativeDate(fetchedDate.relativeValue())) {
        inputText = '';
      }
    } else {
      if (fetchedDate.isValid()) {
        inputText = fetchedDate.format(this.state.dateFormat);
      } else {
        inputText = fetchedDate._i ? fetchedDate._i : undefined;
      }
    }
    return (
      <React.Fragment>
        <Level className="mbn">
          <div className="date-input-field" ref={this.inputRef}>
            <Form.Input
              placeholder="Date"
              value={inputText}
              onClick={this.onFocusIn}
              onChange={isRelativeDate ? null : this.onInputChange}
              readOnly={isRelativeDate}
              disabled={this.props.isReadOnly}
            />
          </div>
          {popup}
        </Level>
        {isRelativeDate && this.renderFormHelp(fetchedDate)}
      </React.Fragment>
    );
  }

  fetchDate = (isRelativeDate) => {
    const { inputValues, input } = this.props;
    const inputValue = inputValues[input.name].value;
    if (isRelativeDate) {
      return RelativeDate.createFromString(inputValue);
    } else {
      if (moment.isMoment(inputValue) && inputValue.isValid()) {
        return inputValue;
      } else if (moment(inputValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
        return moment(inputValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
      }
      return moment(inputValue, this.state.dateFormat, true);
    }
  };

  onFocusOut = () => {
    this.setState({ focused: false });
  };

  onFocusIn = () => {
    if (!this.state.focused) {
      this.boundingRect = this.inputRef.current.getBoundingClientRect();
      if (window.innerHeight < this.boundingRect.bottom + 410 && this.boundingRect.bottom - 410 > 0) {
        this.boundingRect.y -= 414;
      } else {
        this.boundingRect.y += this.boundingRect.bottom - this.boundingRect.top + 4;
      }
      this.setState({ focused: true });
    }
  };

  onAbsoluteSelect = (fetchedDate) => {
    if (fetchedDate instanceof RelativeDate) {
      this.onChange(fetchedDate.dateValue());
    }
  };

  onRelativeSelect = () => {
    // reset to 1 day ago
    const relativeDate = new RelativeDate(1, 'days');
    this.onChange(relativeDate.relativeValue());
    API.track('selected_relative_date');
  };

  onRelativeChange = (units, value, relativeDate) => {
    let dateError;
    const timeValue = parseInt(value);
    if (timeValue >= 0) {
      dateError = '';
      let prevMod = relativeDate.mod;
      relativeDate.update(timeValue, units);
      if (prevMod !== null) {
        relativeDate.updateMod(prevMod);
      }
      if (relativeDate.dateValue().year() <= 0) {
        dateError = 'Please enter a valid value';
      }
    } else if (value === '') {
      dateError = 'Please enter a value';
    } else {
      dateError = 'Please enter a valid value';
    }
    this.onChange(relativeDate.relativeValue(), dateError);
  };

  onDayDateChange = (units, selected, relativeDate) => {
    if (units === RELATIVE_OPTIONS[1].value) {
      // subtracting one to this.mod to account for datetime day of week indices
      relativeDate.updateMod(selected - 1);
    } else if (selected.label === 'Last Day') {
      relativeDate.updateMod(-1);
    } else {
      relativeDate.updateMod(selected.value);
    }
    this.onChange(relativeDate.relativeValue());
  };

  onInputChange = (e) => {
    const value = e.target.value;
    this.setState({ focused: false });

    let dateError;
    if (moment(value, this.state.dateFormat, true).isValid()) {
      dateError = '';
      this.onChange(moment(value, this.state.dateFormat, true), dateError);
    } else if (value === '') {
      dateError = 'Please enter a value';
      this.onChange('', dateError);
    } else {
      dateError = `Please enter date in ${this.state.dateFormat} format`;
      this.onChange(value, dateError);
    }
  };

  onChange = (value, dateError = '') => {
    const inputName = this.props.input.name;
    if (value === null) {
      dateError = `Please enter date in ${this.state.dateFormat} format`;
    }
    this.setState({ dateError });
    const updatedInputValue = {
      value,
      error: dateError,
    };
    this.props.onChange(inputName, { [inputName]: updatedInputValue });
  };
}

DateInputField.propTypes = {
  allowRelative: PropTypes.bool,
  input: PropTypes.object,
  inputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  inputColor: PropTypes.string,
  onChange: PropTypes.func,
  match: PropTypes.object,
};

export default withRouter(DateInputField);
