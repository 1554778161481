import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithLoadingAndEmpty from '../../shared/WithLoadingAndEmpty';
import SortableEntityGrid from '../../shared/SortableEntityGrid';

class HomeContent extends Component {
  render() {
    // we return attachments along with parent templates but still want to only render 6 templates on the home page
    const templatesToRender = this.props.templates.filter((t) => !t.deleted).slice(0, 6);
    return (
      <div>
        <div className="pll pbl">
          <span className="text-grey">Jump back into one of your recent Templates:</span>
        </div>
        <SortableEntityGrid
          entityType={this.props.entityType}
          entitiesToRender={templatesToRender}
          fetchItems={this.props.fetchItems}
          pagination={this.props.pagination}
          loading={this.props.isLoading}
          hideTopPagination={true}
          templateBaseUrl="/templates/"
          showFavorites={false}
          showLibrary={true}
          isAdminView={true}
        />
      </div>
    );
  }
}

HomeContent.propTypes = {
  templates: PropTypes.array,
  fetchItems: PropTypes.func,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  entityType: PropTypes.string,
  match: PropTypes.object,
};

export default WithLoadingAndEmpty(HomeContent);
