import React from 'react';
import AsyncLoadImage from './AsyncLoadImage';
import PropTypes from 'prop-types';
import LinkHeading from './LinkHeading';
import { useHistory } from 'react-router-dom';
import MultiAvatar from './MultiAvatar';
import Skeleton from 'react-loading-skeleton';

function TemplatePreview(props) {
  const { accesses, isLoading, showAccessModal, template, thumbnails } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/templates/${template.id}`);
  };

  const slides = thumbnails ? thumbnails : template.templates_slides;

  let hiddenSlideCount;
  if (template.templates_slides.length > 4) {
    hiddenSlideCount = template.templates_slides.length - 4;
  }

  const renderShareSection = () => {
    let body = null;
    if (accesses === undefined) {
      body = <span>Loading...</span>;
    } else {
      body = (
        <a href="#dummy" onClick={showAccessModal} className="mll pts avatars-with-grey-border" id="form-share">
          <MultiAvatar avatars={accesses.bigListSharedColumnAvatars()} limit={4} size={30} />
        </a>
      );
    }
    return body;
  };

  const renderSkeletons = () => {
    if (slides.length <= 1) {
      return (
        <div className="osteo-sub-quad">
          <Skeleton className="mbm mtxs" width="6rem" height="3.5rem" />
        </div>
      );
    } else {
      return (
        <div className={`${slides.length < 4 ? 'osteo-sub-quad' : 'osteo-quad'}`}>
          {slides.map((slide, idx) => (
            <Skeleton className="mbm mtxs mrm" width="6rem" height="3.5rem" key={idx} />
          ))}
        </div>
      );
    }
  };

  const renderThumbnails = () => {
    if (isLoading) {
      return renderSkeletons();
    } else {
      if (slides.length > 0) {
        return (
          <div className={`slides-thumbnails ${slides.length === 4 ? 'is-full' : ''}`}>
            {slides.map((slide, idx) => {
              let fetchStatus = { fetchState: 'fetching' };
              if (slides && slides.length > idx) {
                fetchStatus = {
                  data: slides[idx],
                  fetchState: 'fetched',
                  errorMessage: '',
                };
              }
              return (
                <div onClick={handleClick} key={`t${template.id}-s${idx}`}>
                  <AsyncLoadImage
                    isBulk={true}
                    imageClass={`async-rounded has-light-gray-border connected-item-slide ${
                      slides.length < 4 ? 'sub-quad' : ''
                    }`}
                    height="30px"
                    fetchUrl={slides && slides[idx].url}
                    fetchStatus={fetchStatus}
                  />
                </div>
              );
            })}
          </div>
        );
      } else {
        return <Skeleton width="6rem" height="3.5rem" className="mbm mts" />;
      }
    }
  };

  return (
    <div className="template-preview">
      <div className="template-preview-header">
        <LinkHeading name={template.name} url={`/templates/${template.id}`} />
        {hiddenSlideCount && (
          <div className="slide-count" onClick={handleClick}>
            +{hiddenSlideCount}
          </div>
        )}
      </div>
      {renderThumbnails()}
      <div className="connection-affection">
        <span>{template.presentation_count?.toLocaleString('en-US')} presentations generated.</span>
        <div className="template-preview-accesses">{renderShareSection()}</div>
      </div>
    </div>
  );
}

TemplatePreview.propTypes = {
  accesses: PropTypes.object,
  isLoading: PropTypes.bool,
  showAccessModal: PropTypes.func,
  template: PropTypes.object,
  thumbnails: PropTypes.array,
};

export default TemplatePreview;
