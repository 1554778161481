import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../Constants';
import Tabs from 'components/lib/Tabs';
import TabItem from 'components/lib/TabItem';

function OutputTypeTabs(props) {
  const onTabClick = (dynamicContentType) => {
    props.onOutputTypeSelect(dynamicContentType);
  };

  return (
    <Tabs>
      {props.outputTypes.map((dynamicContentType) => (
        <TabItem
          key={dynamicContentType}
          label={Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[dynamicContentType].text}
          isActive={dynamicContentType === props.selectedOutputType}
          iconName={Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[dynamicContentType].iconName}
          onClick={props.isReadOnly ? undefined : onTabClick}
        />
      ))}
    </Tabs>
  );
}

OutputTypeTabs.propTypes = {
  selectedOutputType: PropTypes.string,
  onOutputTypeSelect: PropTypes.func,
  outputTypes: PropTypes.array,
  isReadOnly: PropTypes.bool,
};

export default OutputTypeTabs;
