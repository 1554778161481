import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import { Form } from 'react-bulma-components';
import localeDateFormatterUtils from '../../../lib/localeDate';
import Constants from '../../../components/Constants';
import Icon from '../../lib/Icon';

class DateRangeRefinement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateError: '',
      dateFormat: Constants.DATE_FORMATS.usa,
    };
  }

  componentDidMount() {
    this.setState({
      dateFormat: localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
        localeDateFormatterUtils.getUserLocale(),
      ),
    });
  }

  render() {
    return (
      <div>
        <DateRangePicker
          displayFormat={this.state.dateFormat}
          block={true}
          startDatePlaceholderText="From"
          endDatePlaceholderText="To"
          customArrowIcon={<ChevronRight />}
          showClearDates={true}
          startDate={this.props.startDate}
          startDateId="startDate"
          endDate={this.props.endDate}
          endDateId="endDate"
          daySize={42}
          onDatesChange={this.onDatesChange}
          navPrev={
            <div role="button" className="daterange-picker-icon-left">
              <Icon name="chevron_left" size={16} theme="regular" />
            </div>
          }
          navNext={
            <div role="button" className="daterange-picker-icon-right">
              <ChevronRight />
            </div>
          }
          focusedInput={this.props.focusedInput}
          onFocusChange={this.onFocusChange}
          horizontalMargin={0}
          isOutsideRange={() => {}}
          hideKeyboardShortcutsPanel={true}
          renderCalendarInfo={this.props.renderDatePresets}
        />
        {this.state.dateError && <Form.Help color="danger">{this.state.dateError}</Form.Help>}
      </div>
    );
  }

  onDatesChange = (dateObj) => {
    this.props.onDaterangeSelect({ startDate: dateObj['startDate'], endDate: dateObj['endDate'] });
    if (!dateObj['startDate'] || !dateObj['endDate']) {
      this.setState({ dateError: `Please enter date in ${this.state.dateFormat} format` });
    } else {
      this.setState({ dateError: '' });
    }
  };

  onFocusChange = (focusedInput) => {
    // Allow sidebar overflow to be visible when date range picker is open
    const sidebar = document.querySelector('.sidebar');
    if (focusedInput) {
      sidebar.classList.remove('overflow-y-auto');
    } else {
      sidebar.classList.add('overflow-y-auto');
      this.setState({ dateError: '' });
    }
    this.props.setFocusedInput(focusedInput);
  };
}

DateRangeRefinement.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onDaterangeSelect: PropTypes.func,
  focusedInput: PropTypes.any,
  setFocusedInput: PropTypes.func,
  renderDatePresets: PropTypes.func,
};

export default DateRangeRefinement;
