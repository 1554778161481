import React from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import { MConfirm } from '../../shared/Alerts';
import API from '../../../lib/api';
import { useTemplateContent } from 'lib/hooks/useTemplate';
import Banner, { toastBanner } from 'components/shared/Banner';
import { useBulkDynamicContentMutator } from '../../../lib/hooks/useDynamicContent';
import Button from '../../lib/Button';
import Icon from '../../lib/Icon';

function BulkDeleteButton({ bulkDeletePresentations, selectedItemIds }) {
  const { invalidateAll: invalidateTemplateContent } = useTemplateContent();
  const { bulkDelete } = useBulkDynamicContentMutator();

  const onBulkDelete = () => {
    if (bulkDeletePresentations) {
      bulkDeletePresentations(selectedItemIds);
    } else {
      bulkDelete(selectedItemIds)
        .then((response) => {
          API.track('dynamic_content_bulk_delete');
          bulkRemoveContentToast(response.data.deleted, response.data.failures);
          invalidateTemplateContent();
        })
        .catch(API.defaultError);
    }
  };

  const warningMessage = bulkDeletePresentations
    ? `Are you sure you want to delete ${Pluralize('presentation', selectedItemIds.length, true)}?`
    : `Are you sure you want to delete ${Pluralize('piece', selectedItemIds.length, true)} of Dynamic Content?`;

  const onClick = () => {
    MConfirm(
      'Delete',
      warningMessage,
      'warning',
      (ok) => {
        ok && onBulkDelete();
      },
      'Delete',
    );
  };

  const bulkRemoveContentToast = (deleted, failures) => {
    if (!failures.length) {
      toastBanner(
        <Banner bannerType="success" text={`Successfully deleted ${Pluralize('item', deleted.length, true)}`} />,
      );
    } else {
      const subline = (
        <div>
          {failures.map((failure) => (
            <div key={failure.dc_id}>{`${failure.dc_name}: ${failure.failures.join(', ')}`}</div>
          ))}
        </div>
      );
      toastBanner(
        <Banner
          bannerType="error"
          text={`The following ${Pluralize('piece', failures.length, true)} of dynamic content could not be deleted:`}
          sublineText={subline}
        />,
      );
    }
  };

  return (
    <Button category="secondary" onClick={onClick}>
      <Icon name="trash_can" size={20} />
    </Button>
  );
}

BulkDeleteButton.propTypes = {
  selectedItemIds: PropTypes.array,
  bulkDeletePresentations: PropTypes.func,
};

export default BulkDeleteButton;
