import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import Constants from 'components/Constants';
import API from 'lib/api';
import utils from 'lib/utils';
import { FullScreenModalFooter } from 'components/shared/modal/FullScreenModalFooter';
import useTemplate from 'lib/hooks/useTemplate';

function ScheduledPresentationPreview({ children, presentationId, schedule, selectPresentation, templateId, urlBase }) {
  const { data: template } = useTemplate(templateId);

  const history = useHistory();
  const presentation = selectPresentation(presentationId);
  const templateText = utils.toTitleCase(utils.getTemplateText(template, presentation));
  const buttonAction = template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'Send ' : 'Generate ';

  const onSubmitSuccess = () => {
    history.push(`${urlBase}${templateId}/presentation/${presentationId}/schedule-complete`);
  };

  const onClose = (e) => {
    e.preventDefault();
    history.push(`${urlBase}${templateId}`);
  };

  const onTestEmail = (e) => {
    e.preventDefault();
    API.post(
      `/bulk_presentations/${presentation.bulk_presentation_id}/start/`,
      {},
      () => utils.notify(`${templateText} Sent`),
      API.defaultError,
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    API.post('/scheduled_tasks/', schedule, onSubmitSuccess, API.defaultError);
  };

  let secondaryButtonText = 'Cancel';
  let secondaryButtonOnClick = onClose;
  let tertiaryButtonText;
  let tertiaryButtonOnClick;
  if (template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
    secondaryButtonText = buttonAction + utils.toTitleCase(templateText);
    secondaryButtonOnClick = onTestEmail;
    tertiaryButtonText = 'Cancel';
    tertiaryButtonOnClick = onClose;
  }

  return (
    <Container className="is-fluid has-text-centered">
      {children}
      <FullScreenModalFooter
        primaryButtonText="Confirm Schedule"
        primaryButtonOnClick={onSubmit}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonOnClick={secondaryButtonOnClick}
        tertiaryButtonText={tertiaryButtonText}
        tertiaryButtonOnClick={tertiaryButtonOnClick}
      />
    </Container>
  );
}

ScheduledPresentationPreview.propTypes = {
  children: PropTypes.any,
  presentationId: PropTypes.string,
  schedule: PropTypes.object,
  selectPresentation: PropTypes.func,
  templateId: PropTypes.string,
  urlBase: PropTypes.string,
};

export default ScheduledPresentationPreview;
