import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Heading, Section } from 'react-bulma-components';
import Constants from '../Constants';
import info_icon from '../../images/info_icon.svg';
import API from '../../lib/api';
import teams from '../../lib/teams';
import WithFetchUser from '../shared/WithFetchUser';
import Tag from '../lib/Tag';

class TierCard extends Component {
  render() {
    const name = Constants.MATIK_TIERS[this.props.tierId].display_name;
    let price = '';
    let priceInterval = '';
    let priceDescription = '';
    let { buttonText, buttonColor, buttonDisabled, buttonOnClick } = this.fetchButtonAttributes();
    if (this.props.tierId === Constants.MATIK_TIERS.matik_team.tier_id) {
      price = `$${Constants.MATIK_TIERS[this.props.tierId].monthly_price}`;
      priceInterval = 'per user/month';
      priceDescription = `${Constants.MATIK_TIERS.matik_team.userLimit} seats included, billed annually`;
    } else {
      price = 'Custom pricing';
    }

    return (
      <Card className="tier-card">
        <Card.Header className="pll tier-card-header">
          <Heading size={4} className="tier-title">
            <span className="f-underline f-underline-green">{name}</span>
          </Heading>
          {this.props.tierId === Constants.MATIK_TIERS.matik_team.tier_id && (
            <div className="tier-description">
              For small teams looking to automate the creation of data-driven presentations
            </div>
          )}
          {this.props.tierId === Constants.MATIK_TIERS.matik_enterprise.tier_id && (
            <div className="tier-description">
              For large organizations looking to automate data-driven presentations & workflows at scale
            </div>
          )}
          <div>
            <div className="tier-cost">
              <Heading size={3} className="tier-price">
                {price}
              </Heading>
              <div className="tier-price-interval">{priceInterval}</div>
            </div>
            <span className="tier-price-description">{priceDescription}</span>
          </div>
          <div className="tier-select">
            <Button.Group>
              <Button color={buttonColor} disabled={buttonDisabled} onClick={buttonOnClick} tierid={this.props.tierId}>
                {buttonText}
              </Button>
              <a href="#tier-comparison-list" className="tier-compare-link">
                Compare
              </a>
            </Button.Group>
          </div>
        </Card.Header>
        <Card.Content className="tier-card-content">
          <Section className="tier-content-header">{this.fetchContentHeader()}</Section>
          <Section className="ptl mll pbs">{this.fetchTierFeatures()}</Section>
          <a href="#tier-comparison-list" className="tier-compare-link">
            Compare features
          </a>
        </Card.Content>
      </Card>
    );
  }

  fetchContentHeader = () => {
    let contentHeader = '';
    switch (this.props.tierId) {
      case Constants.MATIK_TIERS.matik_enterprise.tier_id:
        contentHeader = 'Everything in ' + Constants.MATIK_TIERS.matik_team.display_name + ', plus';
        break;
      default:
        contentHeader = 'Includes';
    }
    return contentHeader;
  };

  fetchTierFeatures = () => {
    let tierFeatureList = [];
    switch (this.props.tierId) {
      case Constants.MATIK_TIERS.matik_team.tier_id:
        tierFeatureList = [
          {
            label: Constants.MONTHLY_PRESENTATIONS + ' presentation generations/month',
            isBeta: false,
            isComingSoon: false,
            moreInfo: null,
          },
          {
            label: Constants.MATIK_TIERS.matik_team.userLimit + ' seats',
            isBeta: false,
            isComingSoon: false,
            moreInfo: null,
          },
          { label: 'Unlimited data source connections', isBeta: false, isComingSoon: false, moreInfo: null },
          { label: 'Unlimited templates', isBeta: false, isComingSoon: false, moreInfo: null },
          { label: 'Conditional slides', isBeta: false, isComingSoon: false, moreInfo: null },
        ];
        break;
      case Constants.MATIK_TIERS.matik_enterprise.tier_id:
        tierFeatureList = [
          { label: 'Custom presentation generations/month', isBeta: false, isComingSoon: false, moreInfo: null },
          { label: 'Custom seats', isBeta: false, isComingSoon: false, moreInfo: null },
          {
            label: 'Advanced presentation automation capabilities',
            isBeta: false,
            isComingSoon: false,
            moreInfo: null,
          },
          { label: 'Workflow automation features', isBeta: false, isComingSoon: false, moreInfo: null },
          { label: 'Implementation support & dedicated CSM', isBeta: false, isComingSoon: false, moreInfo: null },
        ];
        break;
      default:
        tierFeatureList = [];
    }

    return (
      <ul className="feature-ul">
        {tierFeatureList.map((feature, i) => (
          <FeatureListItem key={i} feature={feature} />
        ))}
      </ul>
    );
  };

  fetchButtonAttributes = () => {
    let buttonText = 'Contact Sales';
    let buttonColor = '';
    let buttonDisabled = false;
    let buttonOnClick = null;

    if (
      this.props.userTier === this.props.tierId &&
      this.props.subscriptionStatus === Constants.SUBSCRIPTION_STATUSES.active
    ) {
      buttonText = 'Current plan';
      buttonColor = 'light';
      buttonDisabled = true;
    } else if (this.props.tierId === Constants.MATIK_TIERS.matik_team.tier_id) {
      buttonText = 'Select';
      buttonColor = 'primary';
      buttonOnClick = this.props.onTierSelection;
    } else {
      buttonText = 'Contact Sales';
      buttonColor = '';
      buttonDisabled = false;
      buttonOnClick = () => {
        API.track('tier_comparison_contact_click', { from: 'tier_comparison' });
        const demoUrl = teams.buildRequestDemoUrl(
          this.props.user,
          'tier_comparison',
          this.props.user.enterprise.trial_days_remaining,
        );
        window.open(demoUrl, '_blank');
      };
    }

    return { buttonText, buttonColor, buttonDisabled, buttonOnClick };
  };
}

const FeatureListItem = ({ feature }) => (
  <li className="feature-li">
    <span className="feature-li-span">{feature.label}</span>
    <div className="feature-extra-info">
      {feature.isBeta && <Tag label="Beta" />}
      {feature.isComingSoon && <Tag label="Coming Soon" color="green" />}
      {feature.moreInfo && (
        <img
          src={info_icon}
          data-tooltip-id="matik-tooltip"
          data-tooltip-content={feature.moreInfo}
          alt="Info"
          className="mls more-info"
        />
      )}
    </div>
  </li>
);

FeatureListItem.propTypes = {
  feature: PropTypes.object,
};

TierCard.propTypes = {
  tierId: PropTypes.string,
  onTierSelection: PropTypes.func,
  userTier: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  user: PropTypes.object,
};

export default WithFetchUser(TierCard);
