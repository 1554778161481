import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Level } from 'react-bulma-components';
import { ReactComponent as TrashIcon } from '../../svg/trash.svg';
import Button from '../lib/Button';

class ConditionGroup extends Component {
  render() {
    return (
      <React.Fragment key={this.props.conditionIndex}>
        <Box className="is-shadowless has-light-gray-border is-paddingless">
          <Level className="mhl mtm">
            <Level.Side align="left">
              <Level.Item>{this.props.conditionIntro}</Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Button
                category="tertiary"
                size="small"
                aria-label="close"
                status={this.props.isReadOnly ? 'disabled' : 'default'}
                onClick={this.deleteCondition}
              >
                <TrashIcon data-tooltip-content="Delete" data-tooltip-id="matik-tooltip" />
              </Button>
            </Level.Side>
          </Level>
          {this.props.children}
        </Box>
      </React.Fragment>
    );
  }

  deleteCondition = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const updatedConditions = [];
    for (let i = 0; i < this.props.queryObj.conditions.length; i++) {
      if (i !== this.props.conditionIndex) {
        updatedConditions.push(this.props.queryObj.conditions[i]);
      }
    }
    // deleteAllConditions is only used for slide conditions, not conditional content, so check if it exists first
    if (updatedQueryObj.conditions.length > 0 && updatedConditions.length === 0 && this.props.deleteAllConditions) {
      this.props.deleteAllConditions();
    }
    updatedQueryObj.conditions = updatedConditions;
    this.props.updateQuery(updatedQueryObj, true);
  };
}

ConditionGroup.propTypes = {
  children: PropTypes.any,
  conditionIndex: PropTypes.any,
  conditionIntro: PropTypes.object,
  deleteAllConditions: PropTypes.func,
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  updateQuery: PropTypes.func,
};

export default ConditionGroup;
