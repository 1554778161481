import React, { Component } from 'react';
import { Level } from 'react-bulma-components';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import CheckboxWithLabel from '../../shared/CheckboxWithLabel';

class BulkActionBar extends Component {
  render() {
    const itemIdsOnPage = this.props.itemsOnPage.map((entity) => entity.id);
    const numSelectedOnPage = this.getNumSelectedOnPage(itemIdsOnPage);
    const numSelectableOnPage = this.getNumSelectableOnPage(itemIdsOnPage);
    const allSelectedOnPage = numSelectedOnPage === numSelectableOnPage;
    const noneSelectedOnPage = numSelectedOnPage === 0;
    const indeterminate = !noneSelectedOnPage && !allSelectedOnPage;
    const checked = allSelectedOnPage && numSelectableOnPage > 0;

    return (
      <div className={`bulk-action-bar ${this.props.entityType === 'presentation' ? 'presentations' : ''}`}>
        <Level>
          <Level.Side align="left">
            {numSelectableOnPage > 0 && (
              <Level.Item className="mrl">
                <CheckboxWithLabel
                  checked={checked}
                  indeterminate={indeterminate}
                  id="master"
                  name={'master-checkbox'}
                  label={
                    (!noneSelectedOnPage ? `${numSelectedOnPage} of ` : ' ') +
                    Pluralize('editable item', numSelectableOnPage, true)
                  }
                  onChange={this.onCheckboxChangeFactory(checked, indeterminate, itemIdsOnPage)}
                />
              </Level.Item>
            )}
            {numSelectedOnPage > 0 && (
              <React.Fragment>
                {this.props.bulkActionElements.map((element, index) => (
                  <Level.Item key={index}>{element}</Level.Item>
                ))}
              </React.Fragment>
            )}
          </Level.Side>
        </Level>
      </div>
    );
  }

  getNumSelectedOnPage = (itemIdsOnPage) => {
    let total = 0;
    for (let itemId of itemIdsOnPage) {
      const checkbox = this.props.checkboxes[itemId.toString()];
      if (checkbox && checkbox.checked) {
        total++;
      }
    }
    return total;
  };

  getNumSelectableOnPage = (itemIdsOnPage) => {
    let total = 0;
    for (let itemId of itemIdsOnPage) {
      const checkbox = this.props.checkboxes[itemId.toString()];
      if (checkbox && !checkbox.disabled) {
        total++;
      }
    }
    return total;
  };

  onCheckboxChangeFactory = (checked, indeterminate, itemIdsOnPage) => {
    const onCheckboxChange = (e) => {
      e.persist();
      const newCheckedVal = !checked || indeterminate;
      this.props.onMasterCheckboxChange(newCheckedVal, itemIdsOnPage);
    };
    return onCheckboxChange;
  };
}

BulkActionBar.propTypes = {
  itemsOnPage: PropTypes.array,
  checkboxes: PropTypes.object,
  onMasterCheckboxChange: PropTypes.func,
  bulkActionElements: PropTypes.array,
  entityType: PropTypes.string,
};

export default BulkActionBar;
