import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TierCard from './TierCard';
import Constants from '../Constants';
import UserAvatar from 'react-user-avatar';
import { Redirect } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import FeatureComparison from './FeatureComparison';
import Pluralize from 'pluralize';
import API from '../../lib/api';

class TierComparison extends Component {
  constructor(props) {
    super(props);
    API.track('tier_comparison_in_app_navigation');
  }

  render() {
    window.scrollTo(0, 0);
    if (this.props.flags.enablePricingPage === false) {
      return <Redirect to={'/'} />;
    }

    const isTrialing =
      this.props.user &&
      this.props.user.enterprise.plan_id &&
      this.props.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_team.tier_id &&
      this.props.user.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.trialing;

    const planName =
      this.props.user && this.props.user.enterprise.plan_id
        ? Constants.MATIK_TIERS[this.props.user.enterprise.plan_id].display_name
        : null;
    const planExpiration =
      this.props.user && this.props.user.enterprise.plan_id
        ? moment(this.props.user.enterprise.subscription_expiration).format('MMMM Do, YYYY')
        : '';
    const planSubheader = isTrialing
      ? 'You have ' +
        Pluralize('day', this.props.user.enterprise.trial_days_remaining, true) +
        ' of your free trial left'
      : 'Currently on ' + planName + ' plan (Next invoice will be issued on ' + planExpiration + ').';

    return (
      <div className="tier-comparison">
        {this.props.user && (
          <div className="enterprise-info">
            <UserAvatar
              name={this.props.user.enterprise.name}
              className="enterprise-avatar"
              colors={['#2687D0']}
              size={72}
              borderRadius={8}
            />
            <div className="title enterprise-info-inner">{this.props.user.enterprise.name}</div>
            {(isTrialing || planName) && (
              <div className="enterprise-info-inner enterprise-info-subtitle">{planSubheader}</div>
            )}
          </div>
        )}
        <div className="tier-card-grid">
          {Object.keys(Constants.MATIK_TIERS).map((tierId, i) => (
            <TierCard
              key={i}
              tierId={tierId}
              onTierSelection={this.props.onTierSelection}
              userTier={this.props.user && this.props.user.enterprise.plan_id}
              subscriptionStatus={this.props.user && this.props.user.enterprise.subscription_status}
            />
          ))}
        </div>
        <a href="#feature-comparison" id="tier-comparison-list" className="feature-comparison">
          <FeatureComparison
            onTierSelection={this.props.onTierSelection}
            userTier={this.props.user && this.props.user.enterprise.plan_id}
            isTrialing={isTrialing}
          />
        </a>
      </div>
    );
  }
}

TierComparison.propTypes = {
  history: PropTypes.object,
  flags: PropTypes.object,
  onTierSelection: PropTypes.func,
  user: PropTypes.object,
};

export default withLDConsumer()(TierComparison);
