import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from './CardHeader';

const Card = ({
  size,
  title,
  subLine,
  rightInstance,
  showLeftInstance = true,
  pillLuminosity,
  pillColor,
  pillTheme,
  iconName,
  iconColor,
  iconTheme,
}) => {
  return (
    <div className="w-[640px] bg-matik-white rounded-md border border-grey-300 flex-col justify-start items-start inline-flex">
      <div className="self-stretch flex-col justify-start items-start gap-2.5 flex">
        <CardHeader
          size={size}
          title={title}
          subLine={subLine}
          rightInstance={rightInstance}
          showLeftInstance={showLeftInstance}
          pillLuminosity={pillLuminosity}
          pillColor={pillColor}
          pillTheme={pillTheme}
          iconName={iconName}
          iconColor={iconColor}
          iconTheme={iconTheme}
        />
      </div>
      {/* TODO: Whenever someone needs the CardFooter or CardBody, they'll have to implement that here. */}
    </div>
  );
};

export default Card;

Card.propTypes = {
  size: PropTypes.oneOf(['m', 's']),
  title: CardHeader.propTypes.title,
  subLine: CardHeader.propTypes.subLine,
  rightInstance: CardHeader.propTypes.rightInstance,
  showLeftInstance: CardHeader.propTypes.showLeftInstance,
  pillLuminosity: CardHeader.propTypes.pillLuminosity,
  pillColor: CardHeader.propTypes.pillColor,
  pillTheme: CardHeader.propTypes.pillTheme,
  iconName: CardHeader.propTypes.iconName,
  iconColor: CardHeader.propTypes.iconColor,
  iconTheme: CardHeader.propTypes.iconTheme,
};
