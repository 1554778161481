import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setOverflowAsHiddenOrDefault } from 'redux/ui/action';
import { FullScreenModalHeader } from './FullScreenModalHeader';
import { FullScreenModalFooter } from './FullScreenModalFooter';

function FullScreenModal({
  children,
  show,
  onClose,
  onBackClick,
  backgroundColor,
  showDefaultFooter,
  headerCenter,
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonDisabled,
  secondaryButtonText,
  secondaryButtonOnClick,
  secondaryButtonLoading,
  tertiaryButtonText,
  tertiaryButtonOnClick,
}) {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  useEffect(() => {
    dispatch(setOverflowAsHiddenOrDefault(show));
  }, [show]);

  const activeClass = show ? 'is-active' : '';
  const backgroundColorHex = backgroundColor === 'white' ? '#FFFFFF' : '#F7F9FB';
  const backHandler = onBackClick ? onBackClick : onClose;

  return show ? (
    <div
      id="full-screen-modal"
      className={`${ui.googleSheetDrawer ? 'full-screen-modal-with-sheet' : 'full-screen-modal'} ${activeClass}`}
      style={{ backgroundColor: backgroundColorHex }}
    >
      <div className="full-screen-modal-content">
        <FullScreenModalHeader
          headerCenter={headerCenter}
          onClose={onClose}
          backgroundColor={backgroundColorHex}
          onBackClick={backHandler}
        />
        {show && children}
        {showDefaultFooter ? (
          <FullScreenModalFooter
            primaryButtonText={primaryButtonText}
            primaryButtonOnClick={primaryButtonOnClick}
            primaryButtonDisabled={primaryButtonDisabled ?? false}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonOnClick={secondaryButtonOnClick}
            secondaryButtonLoading={secondaryButtonLoading}
            tertiaryButtonText={tertiaryButtonText}
            tertiaryButtonOnClick={tertiaryButtonOnClick}
          />
        ) : null}
      </div>
    </div>
  ) : null;
}

FullScreenModal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  showDefaultFooter: PropTypes.bool,
  headerCenter: PropTypes.any,
  backgroundColor: PropTypes.string,
  onBackClick: PropTypes.func,
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  secondaryButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonLoading: PropTypes.bool,
  tertiaryButtonText: PropTypes.string,
  tertiaryButtonOnClick: PropTypes.func,
};

export default FullScreenModal;
