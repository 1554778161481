import React, { Component } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

class OutsideHandler extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.props.onFocusOut();
    }
  };

  render() {
    return ReactDom.createPortal(
      <div className="outside-handler" ref={this.wrapperRef}>
        {this.props.children}
      </div>,
      document.getElementById('calendar-portal'),
    );
  }
}

OutsideHandler.propTypes = {
  children: PropTypes.any,
  onFocusOut: PropTypes.func,
};

export default OutsideHandler;
