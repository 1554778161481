import React from 'react';
import PropTypes from 'prop-types';
import { Form, Box } from 'react-bulma-components';
import AuthTabs from '../AuthTabs';

function GainsightAuth({ auth, centered, checkboxes, onAuthChange }) {
  const accessKey = auth.access_key ?? '';
  const tab = { name: 'login-tab', title: 'Access Via Access Key' };
  return (
    <React.Fragment>
      <AuthTabs activeAuthTab={tab.name} tabs={[tab]} setActiveAuthTab={() => {}} centered={centered} />
      <Box className="data-source-box" style={centered ? { margin: '0 auto' } : null}>
        {checkboxes?.[1]}
        <Form.Field>
          <Form.Label htmlFor="access-key">API Access Key</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="Gainsight API Access Key"
              type="password"
              name="access_key"
              onChange={onAuthChange}
              value={accessKey}
              id="access-key"
            />
          </Form.Control>
        </Form.Field>
      </Box>
    </React.Fragment>
  );
}

GainsightAuth.propTypes = {
  auth: PropTypes.object,
  centered: PropTypes.bool,
  checkboxes: PropTypes.array,
  onAuthChange: PropTypes.func,
};

export default GainsightAuth;
