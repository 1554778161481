import {
  ADD_TEMPLATE_TO_LIBRARIES,
  RECEIVE_FETCH_LIBRARIES,
  REMOVE_TEMPLATE_FROM_LIBRARIES,
  REQUEST_FETCH_LIBRARIES,
} from './actionTypes';

export function libraries(state = { librariesById: {}, isFetching: false }, action) {
  switch (action.type) {
    case REQUEST_FETCH_LIBRARIES:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVE_FETCH_LIBRARIES:
      return Object.assign({}, state, { librariesById: librariesById(action), isFetching: false });
    case ADD_TEMPLATE_TO_LIBRARIES:
      return Object.assign({}, state, { librariesById: addTemplateToLibraries(state.librariesById, action) });
    case REMOVE_TEMPLATE_FROM_LIBRARIES:
      return Object.assign({}, state, { librariesById: removeTemplateFromLibraries(state.librariesById, action) });
    default:
      return state;
  }
}

function librariesById(action) {
  if (!action.libraries || action.libraries.length === 0) {
    return {};
  }

  const libraries = {};
  for (const template of action.libraries) {
    libraries[template.id] = template;
  }

  return libraries;
}

function addTemplateToLibraries(librariesById, action) {
  const updatedLibrariesById = Object.assign({}, librariesById);
  updatedLibrariesById[action.template.id] = action.template;

  return updatedLibrariesById;
}

function removeTemplateFromLibraries(librariesById, action) {
  const updatedLibrariesById = {};
  for (let libraryId in librariesById) {
    if (parseInt(libraryId) !== action.libraryId) {
      updatedLibrariesById[libraryId] = librariesById[libraryId];
    }
  }

  return updatedLibrariesById;
}
