import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import spinnerData from 'lottie/spinning';
import spinningTransformCheckData from 'lottie/spinning-transform-check';
import Lottie from 'react-lottie';
import { ReactComponent as SyncIcon } from 'svg/sync_trigger.svg';
import { Tooltip } from 'react-tooltip';

function QueryLoading({ isLoading, dataTS, hideText, onSyncButtonClick }) {
  const loadingStates = {
    LOADING: 'LOADING',
    WAITING_FOR_ANIMATION_TO_END: 'WAITING_FOR_ANIMATION_TO_END',
    FINISHED_LOADING: 'FINISHED_LOADING',
    NOT_LOADING: 'NOT_LOADING',
  };
  const [currentLoadState, setCurrentLoadState] = useState(
    isLoading ? loadingStates.LOADING : loadingStates.NOT_LOADING,
  );

  useEffect(() => {
    if (!isLoading && currentLoadState === loadingStates.LOADING) {
      setCurrentLoadState(loadingStates.WAITING_FOR_ANIMATION_TO_END);
    } else if (
      isLoading &&
      (currentLoadState === loadingStates.WAITING_FOR_ANIMATION_TO_END ||
        currentLoadState === loadingStates.FINISHED_LOADING ||
        currentLoadState === loadingStates.NOT_LOADING)
    ) {
      setCurrentLoadState(loadingStates.LOADING);
    }
  }, [isLoading]);
  const currentLoadStateRef = useRef(currentLoadState);
  currentLoadStateRef.current = currentLoadState;
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  const onLoadingAnimationLoopEnd = () => {
    const currentLoadStateValue = currentLoadStateRef.current;
    if (currentLoadStateValue === loadingStates.WAITING_FOR_ANIMATION_TO_END) {
      setCurrentLoadState(loadingStates.FINISHED_LOADING);
      setTimeout(
        () => setCurrentLoadState(isLoadingRef.current ? loadingStates.LOADING : loadingStates.NOT_LOADING),
        2500,
      );
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: spinnerData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin',
    },
  };

  const finishedLoadingOptions = {
    loop: false,
    autoplay: true,
    animationData: spinningTransformCheckData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMin',
    },
  };
  let body;
  if (currentLoadState === loadingStates.LOADING || currentLoadState === loadingStates.WAITING_FOR_ANIMATION_TO_END) {
    body = (
      <div className="LottieContainer">
        <Lottie
          options={loadingOptions}
          height={20}
          width={20}
          eventListeners={[{ eventName: 'loopComplete', callback: onLoadingAnimationLoopEnd }]}
        />
        {!hideText && <span className="mlxs">Syncing inputs &hellip;</span>}
      </div>
    );
  } else if (currentLoadState === loadingStates.FINISHED_LOADING) {
    body = (
      <div className="LottieContainer">
        <Lottie options={finishedLoadingOptions} height={20} width={20} />
        {!hideText && <span className="pls">Sync done</span>}
      </div>
    );
  } else if (currentLoadState === loadingStates.NOT_LOADING && dataTS) {
    body = !!dataTS && (
      <button type="button" className="button icon-button pan" id="lastSynced" onClick={onSyncButtonClick}>
        <SyncIcon />
      </button>
    );
  } else {
    return null;
  }
  return (
    <div id="queryLoader">
      {body}
      <Tooltip anchorSelect="#queryLoader" className="matik-tooltip" noArrow={true} place="top">
        <span>
          Last synced:&nbsp;
          <Moment interval={10000} fromNow>
            {dataTS}
          </Moment>
        </span>
      </Tooltip>
    </div>
  );
}

QueryLoading.propTypes = {
  isLoading: PropTypes.bool,
  dataTS: PropTypes.object, // Should be a Moment
  hideText: PropTypes.bool,
  onSyncButtonClick: PropTypes.func,
};

export default QueryLoading;
