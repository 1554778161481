import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import utils from 'lib/utils';
import { closeSidepane } from 'redux/ui/action';
import { UserContext } from 'components/UserContext';
import DropdownMenu from 'components/shared/DropdownMenu';
import { MConfirm } from 'components/shared/Alerts';
import { flowTypes } from './Constants';

function FlowActionDropdown({ dropdownTrigger, isHoverable, flow, onFlowDelete, onUpdateClick }) {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const match = useRouteMatch();
  const isAdminView = match.path.indexOf('create') === -1;
  const baseUrl = isAdminView ? '' : '/create';

  const onFlowUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateClick(flow);
  };

  const onFlowDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm('Delete', `Are you sure you want to delete this ${flow.flow_type} flow?`, 'warning', (confirmed) => {
      if (confirmed) {
        onFlowDelete(flow);
        if (ui.sidepane) {
          dispatch(closeSidepane());
        }
      }
    });
  };

  return (
    <DropdownMenu dropdownTrigger={dropdownTrigger} isHoverable={isHoverable} isRight={true}>
      {flow?.template?.id && !flow?.template?.deleted && (
        <Link className="dropdown-item" to={`${baseUrl}/templates/${flow?.template?.id}`}>
          View Template
        </Link>
      )}
      {flow.user.id === user?.id && (
        <a className="dropdown-item" onClick={onFlowDeleteClick}>
          {`Delete ${utils.capitalizeString(flow.flow_type)} Flow`}
        </a>
      )}
      {flow.flow_type === flowTypes.SCHEDULED && (
        <a className="dropdown-item" onClick={onFlowUpdate}>
          {`Update ${utils.capitalizeString(flow.flow_type)} Flow`}
        </a>
      )}
    </DropdownMenu>
  );
}

FlowActionDropdown.propTypes = {
  dropdownTrigger: PropTypes.any,
  isHoverable: PropTypes.bool,
  flow: PropTypes.object,
  onFlowDelete: PropTypes.func,
  onUpdateClick: PropTypes.func,
};

export default FlowActionDropdown;
