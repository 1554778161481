import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Heading } from 'react-bulma-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { Auth } from '../../lib/auth';
import { useHistory } from 'react-router-dom';
import API from '../../lib/api';
import utils from '../../lib/utils';
import Button from '../lib/Button';

function SignUpForm({ flags }) {
  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameErrorMsg, setNameErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isTermsValid, setIsTermsValid] = useState(true);
  const [termsErrorMsg, setTermsErrorMsg] = useState('');
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);
  const [isPrivacyValid, setIsPrivacyValid] = useState(true);
  const [privacyErrorMsg, setPrivacyErrorMsg] = useState('');
  const [isLoginValid, setIsLoginValid] = useState(true);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');

  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const flow = urlParams.get('flow');
  const companyName = urlParams.get('company_name') || '';
  const companyUrl = urlParams.get('company_url') || '';
  const nameParam = urlParams.get('name');
  const emailParam = urlParams.get('email');

  useEffect(() => {
    API.track('signup_page_navigation', { companyName, companyUrl, templateType: flow }, -1);
    setName(nameParam);
    setEmail(emailParam);
  }, []);

  const onNameUpdate = (e) => {
    setName(e.target.value);
    setNameErrorMsg('');
    setIsNameValid(true);
  };

  const onEmailUpdate = (e) => {
    setEmail(e.target.value);
    setEmailErrorMsg('');
    setIsEmailValid(true);
  };

  const onPasswordUpdate = (e) => {
    setPassword(e.target.value);
    setPasswordErrorMsg('');
    setIsPasswordValid(true);
  };

  const onTermsCheck = (e) => {
    setAgreeToTerms(e.target.value);
    setTermsErrorMsg('');
    setIsTermsValid(true);
  };

  const onPrivacyCheck = (e) => {
    setAgreeToPrivacy(e.target.checked);
    setPrivacyErrorMsg('');
    setIsPrivacyValid(true);
  };

  const validateForm = () => {
    const emailIsValid = checkValidEmail();

    if (!name) {
      setIsNameValid(false);
      setNameErrorMsg('Name is required');
    }

    if (!password) {
      setIsPasswordValid(false);
      setPasswordErrorMsg('Password is required');
    }

    if (!agreeToTerms) {
      setIsTermsValid(false);
      setTermsErrorMsg('You must agree in order to sign up');
    }

    if (!agreeToPrivacy) {
      setIsPrivacyValid(false);
      setPrivacyErrorMsg('You must agree in order to sign up');
    }

    return name && emailIsValid && password && agreeToTerms && agreeToPrivacy;
  };

  const checkValidEmail = () => {
    const emailIsValid = utils.isEmailValid(email);

    if (!emailIsValid) {
      setIsEmailValid(false);
      setEmailErrorMsg('Invalid email address');
    }

    let emailContainsPlus = false;
    if (flags.preventPlusSignEmails) {
      emailContainsPlus = email.indexOf('+') > 0;
    }

    if (emailContainsPlus) {
      setIsEmailValid(false);
      setEmailErrorMsg('Email address cannot contain "+"');
    }

    return emailIsValid && !emailContainsPlus;
  };

  const onSignUpSubmit = (e) => {
    e.preventDefault();
    setIsNameValid(true);
    setIsEmailValid(true);
    setIsPasswordValid(true);
    setIsLoginValid(true);

    if (validateForm()) {
      Auth.createUser(name, email, password, onCreateAccountSuccess, onCreateAccountError);
    }
  };

  const onCreateAccountSuccess = () => {
    API.setUserProfile(true).then((user) => {
      if (flow) {
        API.track('signup_page_create_user_success', { companyName, companyUrl, templateType: flow }, user.id);
        history.push({
          pathname: '/templates',
          search: `?start_flow=${flow}&company_name=${encodeURIComponent(companyName)}&company_url=${encodeURIComponent(
            companyUrl,
          )}`,
        });
      } else {
        API.track('successful_signup');
        history.push('/about_me');
      }
    });
  };

  const onCreateAccountError = (err) => {
    if (err && err.data) {
      const errorType = err.data.error;
      if (errorType === 'MalformedUserDataError') {
        setIsEmailValid(false);
        setEmailErrorMsg(err.data.message);
      } else {
        setIsLoginValid(false);
        setLoginErrorMsg(err.data.message);
      }
    }
  };

  let nameInputClass = 'input signup-input';
  if (!isNameValid) {
    nameInputClass += ' is-danger';
  }

  let emailInputClass = 'input signup-input';
  if (!isEmailValid) {
    emailInputClass += ' is-danger';
  }

  let passwordInputClass = 'input signup-input';
  if (!isPasswordValid) {
    passwordInputClass += ' is-danger';
  }

  return (
    <React.Fragment>
      <Heading size={4} className="signup-header">
        Get started for free
      </Heading>
      <form onSubmit={onSignUpSubmit} className="card-content signup-form" noValidate>
        {/*NAME*/}
        <Form.Field>
          <Form.Control>
            <Form.Input
              className={nameInputClass}
              type="text"
              placeholder="Name"
              value={name}
              onChange={onNameUpdate}
            />
          </Form.Control>
          {!isNameValid && <p className="help is-danger">{nameErrorMsg}</p>}
        </Form.Field>

        {/*EMAIL*/}
        <Form.Field className="mtm">
          <Form.Control>
            <Form.Input
              className={emailInputClass}
              type="email"
              placeholder="Email"
              value={email}
              onChange={onEmailUpdate}
            />
          </Form.Control>
          {!isEmailValid && <p className="help is-danger">{emailErrorMsg}</p>}
        </Form.Field>

        {/*PASSWORD*/}
        <Form.Field className="mtm">
          <Form.Control>
            <Form.Input
              className={passwordInputClass}
              type="password"
              placeholder="Password"
              value={password}
              onChange={onPasswordUpdate}
            />
          </Form.Control>
          {!isPasswordValid && <p className="help is-danger">{passwordErrorMsg}</p>}
        </Form.Field>

        {/*AGREE TO TERMS OF SERVICE*/}
        <Form.Field className="mtm">
          <Form.Control>
            <input
              id="agree_to_terms"
              type="checkbox"
              name="agree_to_terms"
              onChange={onTermsCheck}
              checked={agreeToTerms}
            />
            <Form.Label htmlFor="agree_to_terms">
              <span className="mls agree-to-terms">
                Creating an account means you agree to our&nbsp;
                <a
                  className="agree-to-terms underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://matik.io/terms/"
                >
                  Terms of Service
                </a>
              </span>
            </Form.Label>
          </Form.Control>
          {!isTermsValid && <p className="help is-danger">{termsErrorMsg}</p>}
        </Form.Field>

        {/*AGREE TO PRIVACY POLICY*/}
        <Form.Field className="mtm">
          <Form.Control>
            <input
              id="agree_to_privacy"
              type="checkbox"
              name="agree_to_privacy"
              onChange={onPrivacyCheck}
              checked={agreeToPrivacy}
            />
            <Form.Label htmlFor="agree_to_privacy">
              <span className="mls agree-to-terms">
                Agree to our&nbsp;
                <a
                  className="agree-to-terms underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://matik.io/privacy/"
                >
                  Privacy Policy
                </a>
              </span>
            </Form.Label>
          </Form.Control>
          {!isPrivacyValid && <p className="help is-danger">{privacyErrorMsg}</p>}
        </Form.Field>

        {!isLoginValid && <p className="help is-danger">{loginErrorMsg}</p>}

        {/*SUBMIT*/}
        <Form.Field className="mvm ptl">
          <Form.Control>
            <Button type="submit" width="fullWidth">
              Sign Up
            </Button>
          </Form.Control>
        </Form.Field>
        <div className="have-account">
          Already have an account?{' '}
          <Link
            to={
              flow
                ? `/login?flow=${flow}&company_name=${encodeURIComponent(companyName)}&company_url=${encodeURIComponent(
                    companyUrl,
                  )}`
                : '/login'
            }
            className="signup-login"
          >
            Log in
          </Link>
        </div>
      </form>
    </React.Fragment>
  );
}

SignUpForm.propTypes = {
  flags: PropTypes.object.isRequired,
};

export default withLDConsumer()(SignUpForm);
